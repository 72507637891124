import { Box, Divider, Flex, Spacer, Spinner } from '@chakra-ui/react';
import React, { FC, ReactNode, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useFormatDate } from '../../../../../hooks/useFormatDate';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { getDevice, Types } from '../../../../../store/device';
import { hasError, hasLoading } from '../../../../../store/ui';
import { OperationalSystemEnum } from '../../../../../types/device';
import { ID } from '../../../../../types/util';
import Heading from '../../../../Heading';
import RefreshIcon from '../../../../Icons/Refresh';
import Modal, { ModalBody } from '../../../../Modal';
import Toaster from '../../../../Toaster';
import ModalAndroidInformation from './ModalAndroidInformation';
import ModalIOSInformation from './ModalIOSInformation';

interface ModalMoreInformationProps {
  isOpen: boolean;
  closeModal?: () => void;
  deviceId: ID;
}

type TBox = {
  children: ReactNode;
  title: string;
};

export const ModalSection: FC<TBox> = ({ children, title }) => {
  return (
    <Box m="2" p="2">
      <Heading fontSize="larger" p="2">
        {title}
      </Heading>
      <Divider />
      <Flex px="2" py="4" flexDirection="column">
        {children}
      </Flex>
    </Box>
  );
};

const ModalMoreInformation = ({
  isOpen,
  closeModal,
  deviceId,
}: ModalMoreInformationProps) => {
  const { handleFormatDateByUserLogged } = useFormatDate();
  const dispatch = useAppDispatch();
  const { device } = useAppSelector((state) => state.device);
  const { portalSettings } = useAppSelector((state) => state.portalSettings);
  const isLoading = hasLoading(Types.GET);
  const error = hasError(Types.GET);
  const intl = useIntl();

  const isIOS = device?.operationalSystem === OperationalSystemEnum.IOS;

  const refresh = () => {
    dispatch(getDevice(deviceId));
  };

  useEffect(() => {
    refresh();
  }, [deviceId]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      modalWidth="1100px"
      isCentered={false}
    >
      <ModalBody>
        <Box w="full" p="2.5">
          <Heading>
            <FormattedMessage id="devices.action.device_information" />
          </Heading>
        </Box>
        {isLoading ? (
          <Spinner />
        ) : error ? (
          <Box w="full" mt="3%">
            <Toaster open={true} onClose={closeModal} type={'error'}>
              {error?.keyIntl
                ? intl.formatMessage({ id: error.keyIntl })
                : error.message}
            </Toaster>
          </Box>
        ) : (
          <>
            <Flex
              w="full"
              p="2.5"
              justifyContent="center"
              verticalAlign="middle"
              alignItems="center"
            >
              <Spacer />
              <Flex marginRight="5">
                <Heading fontWeight="bold" fontSize="medium" marginRight="2">
                  <FormattedMessage id="global.last_update" />:
                </Heading>
                <Heading fontWeight="bold" fontSize="medium">
                  {handleFormatDateByUserLogged(device.enterpriseUpdatedAt) ||
                    '-'}
                </Heading>
              </Flex>
              <Flex cursor="pointer" onClick={refresh}>
                <span
                  title={intl.formatMessage({
                    id: 'global.update',
                  })}
                >
                  <RefreshIcon boxSize={8}></RefreshIcon>
                </span>
              </Flex>
            </Flex>

            {isIOS ? (
              <ModalIOSInformation
                device={device}
                handleFormatDateByUserLogged={handleFormatDateByUserLogged}
                portalSettings={portalSettings}
              ></ModalIOSInformation>
            ) : (
              <ModalAndroidInformation
                device={device}
                handleFormatDateByUserLogged={handleFormatDateByUserLogged}
                portalSettings={portalSettings}
              />
            )}
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ModalMoreInformation;
