import { Flex, Tooltip, useTheme } from '@chakra-ui/react';
import { truncate } from 'lodash';
import { formatBytesTo } from 'portal-lib';
import { FormattedMessage } from 'react-intl';

import { useAppSelector } from '../../../hooks/useRedux';
import { Types } from '../../../store/dashboard';
import { selectorLoading } from '../../../store/ui';
import ChartBar from '../../ChartBar';
import ChartLoading from '../../ChartLoading';
import Text from '../../Text';

const CONSUMPTION_LEGEND_LIMITER = 16;

const DashboardApplicationConsumptionBarChart = () => {
  const { dataConsumptionApp } = useAppSelector((state) => state.dashboard);
  const { user } = useAppSelector((state) => state.auth);

  const isLoading = useAppSelector(
    selectorLoading(Types.LIST_DEVICES_LOCATION)
  );

  const { colors } = useTheme();

  const hasData = dataConsumptionApp?.data.length > 0;

  const higherValue = dataConsumptionApp?.total;

  const dataChart = dataConsumptionApp?.data
    ?.map((dataBarChart) => {
      return {
        indexBy: dataBarChart?.name,
        consumptionPercentage: (
          ((Number(dataBarChart?.total) || 0) * 100) / (higherValue || 0) || 0
        ).toFixed(1),
        consumption: dataBarChart?.total,
      };
    })
    .reverse();

  if (isLoading) {
    return <ChartLoading color="primary.500" />;
  }

  return (
    <Flex align="center" justify="center" h="400px">
      {!hasData ? (
        <Text>
          <FormattedMessage id="dashboard.usage_time_by_application.empty_chart" />
        </Text>
      ) : (
        <ChartBar
          data={dataChart}
          color={colors['chart']['50']}
          keys={['consumptionPercentage']}
          formatRight={(chartValue) => {
            const dataConsumptionLegend = dataChart?.find(
              (consumption) => consumption?.indexBy === chartValue
            );
            return (
              <>
                <tspan x="0" dy="-0.7em" fill={colors['gray']['900']}>
                  {formatBytesTo({
                    bytes: Number(dataConsumptionLegend?.consumption),
                  })}
                </tspan>
                <tspan x="0" dy="1.2em" fill={colors['chart']['300']}>
                  {parseFloat(
                    dataConsumptionLegend?.consumptionPercentage
                  ).toLocaleString(user?.language)}
                  %
                </tspan>
              </>
            );
          }}
          formatTop={(data) => {
            return `${data}%`;
          }}
          formatLeft={(chatValue) => {
            const dataConsumptionLegend = dataChart?.find(
              (consumption) => consumption?.indexBy === chatValue
            );
            return (
              <Tooltip label={dataConsumptionLegend?.indexBy}>
                <tspan x="0" dy="0em" fill={colors['chart']['300']}>
                  {truncate(dataConsumptionLegend?.indexBy, {
                    length: CONSUMPTION_LEGEND_LIMITER,
                  })}
                </tspan>
              </Tooltip>
            );
          }}
        />
      )}
    </Flex>
  );
};

export default DashboardApplicationConsumptionBarChart;
