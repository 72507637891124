import { Icon } from '@chakra-ui/react';
import React from 'react';

const StopIcon = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="warning.600" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 256 256"
      >
        <path d="M200.73 40H55.27A15.29 15.29 0 0 0 40 55.27v145.46A15.29 15.29 0 0 0 55.27 216h145.46A15.29 15.29 0 0 0 216 200.73V55.27A15.29 15.29 0 0 0 200.73 40ZM200 200H56V56h144Z" />
      </svg>
    </Icon>
  );
};

export default StopIcon;
