export enum ImportErrorEnum {
  INVALID_XML = 'INVALID_XML',
  INVALID_FORMAT = 'INVALID_FORMAT',
}

export const importErrorsToIntl = {
  [ImportErrorEnum.INVALID_XML]:
    'modal_import_invoice.error_message.invalid_xml',
  [ImportErrorEnum.INVALID_FORMAT]:
    'modal_import_invoice.error_message.invalid_format',
  default: 'modal_import_invoice.error_message.default',
};
