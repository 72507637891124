import { Flex } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import XMLParser from 'react-xml-parser';

import { routeWithParameters } from '../../../../helper';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import routes from '../../../../routes';
import { importInvoice, Types } from '../../../../store/invoice';
import {
  hasError,
  hasLoading,
  hasSuccess,
  uiRemoveSuccess,
} from '../../../../store/ui';
import { InvoiceType } from '../../../../types/invoice';
import FormContainer from '../../../FormContainer';
import FormControl from '../../../FormControl';
import Input from '../../../Input';
import Modal from '../../../Modal';
import PageShowToaster from '../../../PageShowToaster';
import PageTitle from '../../../PageTitle';
import { apiErrorMessagesByResponse } from '../zeroTouchErros';
import { ImportErrorEnum, importErrorsToIntl } from './importInvoiceErrors';
import ImportInvoiceUploadFile from './ImportInvoiceUploadFile';

interface ModalImportInvoiceProps {
  isOpen: boolean;
  closeModal: () => void;
}

const ModalImportInvoice = ({
  isOpen,
  closeModal,
}: ModalImportInvoiceProps) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const history = useHistory();

  const { invoice } = useAppSelector((state) => state.invoice);

  const [accessKey, setAccessKey] = useState<InvoiceType['accessKey']>('');
  const [error, setError] = useState<ImportErrorEnum>(null);

  const ACCESS_KEY_LENGTH = 44;
  const importApiError = hasError(Types.IMPORT_INVOICE);
  const importApiSuccess = hasSuccess(Types.IMPORT_INVOICE);
  const isLoading = hasLoading(Types.IMPORT_INVOICE);
  const ACCEPTED_FORMAT_TYPE = 'text/xml';
  const xmlTags = ['chNFe', 'infNFe'];

  useEffect(() => {
    if (importApiSuccess) {
      closeModal();
      history.push(
        routeWithParameters(routes.zeroTouch.invoices.edit, {
          id: invoice.id,
        })
      );
    }

    return () => {
      dispatch(uiRemoveSuccess(Types.IMPORT_INVOICE));
    };
  }, [importApiSuccess]);

  useEffect(() => {
    const hasError = error !== null;
    if (!!accessKey && hasError) {
      setError(null);
    }
  }, [accessKey, error]);

  const handleSubmit = () => {
    dispatch(importInvoice({ accessKey }));
  };

  const handleUploadInvoiceXml = async (e) => {
    const file = e.target.files[0];

    if (!file) return;

    if (file.type === ACCEPTED_FORMAT_TYPE) {
      const reader = new FileReader();
      await reader.readAsText(file);

      reader.onload = (e) => {
        try {
          const renderData = e.target.result;

          if (renderData) {
            const xml = new XMLParser().parseFromString(renderData as string);

            const actionByXmlTag = {
              ['chNFe']: xml?.getElementsByTagName('chNFe')[0]?.value,
              ['infNFe']: xml
                ?.getElementsByTagName('infNFe')[0]
                ?.attributes.Id?.replace('NFe', ''),
            };

            const xmlTag = xmlTags.find(
              (tag) => xml?.getElementsByTagName(tag)[0]
            );

            const xmlAccessKey = actionByXmlTag[xmlTag] || null;

            if (!!xmlAccessKey) {
              return setAccessKey(xmlAccessKey);
            }
            setAccessKey('');
            return setError(ImportErrorEnum.INVALID_XML);
          }
        } catch {
          setAccessKey('');
          return setError(ImportErrorEnum.INVALID_XML);
        }
      };
    } else {
      setAccessKey('');
      return setError(ImportErrorEnum.INVALID_FORMAT);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} size="xl">
      <Flex align="center" w="full" p="5%">
        <Flex h="100%" flexDirection="column" justify="space-around" w="100%">
          <PageTitle
            title={<FormattedMessage id={'modal_import_invoice.title'} />}
          />
          <PageShowToaster
            toasterKeys={[Types.IMPORT_INVOICE]}
            messageError={
              <FormattedMessage
                id={
                  apiErrorMessagesByResponse[importApiError?.message] ||
                  apiErrorMessagesByResponse.default
                }
              />
            }
            disabledSuccess
          />
          <FormContainer
            labelPrimary={
              <FormattedMessage id={'modal_import_invoice.import_button'} />
            }
            handlePrimary={handleSubmit}
            labelSecondary={<FormattedMessage id="global.cancel" />}
            handleSecondary={closeModal}
            disabledPrimary={
              !accessKey || accessKey?.length < ACCESS_KEY_LENGTH
            }
            divider={false}
            loadingPrimary={isLoading}
          >
            <FormControl
              textLabel={
                <FormattedMessage id={'modal_import_invoice.input.label'} />
              }
              mb="5%"
            >
              <Input
                inputProps={{
                  name: 'accessKey',
                  value: accessKey,
                  onChange: (e) => setAccessKey(e.target.value),
                  placeholder: intl.formatMessage({
                    id: 'modal_import_invoice.input.placeholder',
                  }),
                  type: 'text',
                }}
              />
            </FormControl>
            <ImportInvoiceUploadFile
              mb={6}
              inputProps={{
                name: 'invoiceAccessKey',
                onChange: handleUploadInvoiceXml,
                accept: ACCEPTED_FORMAT_TYPE,
              }}
              uploadTitle={
                <FormattedMessage id="modal_import_invoice.label.uploda_button" />
              }
              errorMessage={
                error && (
                  <FormattedMessage
                    id={importErrorsToIntl[error] || importErrorsToIntl.default}
                  />
                )
              }
            />
          </FormContainer>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default ModalImportInvoice;
