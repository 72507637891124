import { Box, Checkbox, Flex } from '@chakra-ui/react';
import React, { useEffect, useState, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams, useHistory } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

import FormContainer from '../../../components/FormContainer';
import FormControl from '../../../components/FormControl';
import FormSubtitle from '../../../components/FormSubtitle';
import OperationalSystemIcon from '../../../components/Icons/OperationalSystemIcon';
import Input from '../../../components/Input';
import PageErrors from '../../../components/PageErrors';
import PageTitle from '../../../components/PageTitle';
import Select from '../../../components/Select';
import Table from '../../../components/Table';
import { GROUP_PAGE_SIZE_FIXED } from '../../../helper';
import { inFirstPage } from '../../../helper/metadata';
import { ModeObject, getMode } from '../../../helper/mode';
import { useSorting } from '../../../helper/sort';
import { validatorDefaultMessages } from '../../../helper/validador';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import routes from '../../../routes';
import { generateExcel } from '../../../store/event';
import {
  createGroup,
  getGroup,
  groupSelected,
  groupSelectedClear,
  updateGroup,
  listLinkedDevice,
  groupDevicesClean,
  Types,
} from '../../../store/group';
import { selectorMetadata, selectorSuccess } from '../../../store/ui';
import { DeviceUserType } from '../../../types/deviceUser';
import { ListMetadata } from '../../../types/generic_list';
import { operationalSystemToIntl } from '../../Policies/EditPolicies';

interface UpdateAttachDeviceType {
  serverAttach: boolean;
  chanceAttach: boolean;
  device: DeviceUserType;
}

const EditGroup = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { group, linkedDevices } = useAppSelector((state) => state.group);
  const {
    user: { company },
  } = useAppSelector((state) => state.auth);
  const successCreate = useAppSelector(selectorSuccess(Types.CREATE));
  const successUpdate = useAppSelector(selectorSuccess(Types.UPDATE));
  const metadataLinkedDevices = useAppSelector(
    selectorMetadata(Types.LIST_LINKED_USER)
  );

  const [search, setSearch] = useState('');
  const [updateAttachedDevices, setUpdateAttachedDevices] = useState<{
    [K in number]?: UpdateAttachDeviceType;
  }>({});
  const allFilters = {
    userId: updateAttachedDevices,
    search,
    groupId: id,
  };

  const CRUDMode = getMode(id);
  const intlKeyTitle =
    CRUDMode === ModeObject.CREATE
      ? 'edit_group.title_new'
      : 'edit_group.title_edit';

  const intl = useIntl();

  const isAttachServer = (linkedDevice: DeviceUserType) => !!linkedDevice.group;

  const simpleValidator = useRef(
    new SimpleReactValidator({
      messages: {
        ...validatorDefaultMessages(intl),
      },
    })
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(groupSelected({ [e.target.name]: e.target.value }));
    simpleValidator.current.showMessages();
  };

  useEffect(() => {
    dispatch(
      listLinkedDevice(
        inFirstPage({
          ...metadataLinkedDevices,
          pageSize: GROUP_PAGE_SIZE_FIXED,
        }),
        parseInt(id),
        {
          search,
        }
      )
    );
    return () => {
      dispatch(groupDevicesClean());
    };
  }, [id, search]);

  useEffect(() => {
    if (id) {
      dispatch(getGroup(parseInt(id)));
    }
    return () => {
      dispatch(groupSelectedClear());
    };
  }, [id]);

  useEffect(() => {
    if (successCreate || successUpdate) {
      history.push(routes.groups.manage);
    }
  }, [successCreate, successUpdate]);

  useEffect(() => {
    dispatch(groupSelected({ companyId: parseInt(company?.id.toString()) }));
  }, [company?.id]);

  const handlePrimary = () => {
    const groupToDispatch = {
      ...group,
      name: group?.name?.trim() || '',
      addDeviceUserIds: Object.keys(updateAttachedDevices)
        .map((keyUpdate) => updateAttachedDevices[+keyUpdate])
        .filter(
          (deviceInfo) => !deviceInfo.serverAttach && deviceInfo.chanceAttach
        )
        .map((deviceInfo) => deviceInfo.device.id),
      deleteDeviceUserIds:
        (CRUDMode === ModeObject.UPDATE &&
          Object.keys(updateAttachedDevices)
            .map((keyUpdate) => updateAttachedDevices[+keyUpdate])
            .filter(
              (deviceInfo) => deviceInfo.serverAttach && deviceInfo.chanceAttach
            )
            .map((deviceInfo) => deviceInfo.device.id)) ||
        [],
    };

    if (CRUDMode === ModeObject.CREATE) {
      dispatch(createGroup(groupToDispatch));
    } else {
      dispatch(updateGroup(groupToDispatch));
    }
  };

  const handleSecondary = () => {
    history.push(routes.groups.manage);
  };

  const handleLinkedMetadata = (newMetadata: ListMetadata) => {
    dispatch(
      listLinkedDevice(
        {
          ...metadataLinkedDevices,
          ...newMetadata,
        },
        parseInt(id)
      )
    );
  };

  const handleCheboxChange = (linkedDevice: DeviceUserType) => {
    const updateDevice = {
      serverAttach: isAttachServer(linkedDevice),
      chanceAttach: !updateAttachedDevices?.[linkedDevice.id]?.chanceAttach,
      device: linkedDevice,
    };
    setUpdateAttachedDevices({
      ...updateAttachedDevices,
      [linkedDevice.id]: updateDevice.chanceAttach ? updateDevice : null,
    });
  };

  const isAtiveCheckboxDevice = (
    linked: DeviceUserType,
    update: UpdateAttachDeviceType
  ) =>
    !update
      ? isAttachServer(linked)
      : update.serverAttach !== update.chanceAttach; // operator !== is equal to xor (exclusive or)

  const columns = useSorting(
    [
      {
        header: '',
        accessor: 'attach',
        canSort: false,
        isExportHidden: true,
      },
      {
        accessor: 'systemIcon',
        header: '',
        isExportHidden: true,
      },
      {
        accessor: 'operationalSystem',
        header: intl.formatMessage({
          id: 'global.operational_system',
        }),
        isExportField: true,
      },
      {
        header: intl.formatMessage({
          id: 'edit_group.column.user',
        }),
        accessor: 'name',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: 'edit_group.column.phone',
        }),
        accessor: 'phoneNumber',
        canSort: true,
      },
    ],
    metadataLinkedDevices
  );

  const rowsDevices = linkedDevices.map((linkedDevice) => ({
    cells: [
      {
        field: 'actions',
        value: '',
        isExportHidden: true,
        transform: () => {
          return (
            <Flex>
              <Checkbox
                fontSize="sm"
                color="gray.500"
                isChecked={isAtiveCheckboxDevice(
                  linkedDevice,
                  updateAttachedDevices[linkedDevice.id]
                )}
                onChange={() => handleCheboxChange(linkedDevice)}
              />
            </Flex>
          );
        },
      },
      {
        field: 'systemIcon',
        value: (
          <OperationalSystemIcon
            operationalSystem={linkedDevice?.device?.operationalSystem}
          />
        ),
        isExportHidden: true,
        chackraProps: { px: 0 },
      },
      {
        field: 'operationalSystem',
        value: intl.formatMessage({
          id: `${
            operationalSystemToIntl[linkedDevice?.device?.operationalSystem]
          }`,
        }),
        isExportField: true,
      },
      {
        field: 'name',
        value: linkedDevice.name,
      },
      {
        field: 'phoneNumber',
        value: linkedDevice.phoneNumber,
      },
    ],
  }));

  const handleExportAllToExcel = () => {
    dispatch(
      generateExcel({
        type: Types.LIST_LINKED_USER,
        metadata: metadataLinkedDevices,
        filters: allFilters,
      })
    );
  };

  return (
    <>
      <PageTitle
        showManualIcon
        title={
          CRUDMode === ModeObject.CREATE ? (
            <FormattedMessage id="edit_group.title_new" />
          ) : (
            <FormattedMessage id="edit_group.title_edit" />
          )
        }
        description={
          CRUDMode === ModeObject.CREATE ? (
            <FormattedMessage id="edit_group.description_new" />
          ) : (
            <FormattedMessage id="edit_group.description_edit" />
          )
        }
      />
      <PageErrors
        toasterKeys={[Types.CREATE, Types.UPDATE]}
        translateKey="edit_group"
      />
      <FormContainer
        labelPrimary={
          CRUDMode === ModeObject.CREATE ? (
            <FormattedMessage id="global.register" />
          ) : (
            <FormattedMessage id="global.update" />
          )
        }
        handlePrimary={handlePrimary}
        disabledPrimary={!group.name}
        labelSecondary={<FormattedMessage id="global.cancel" />}
        handleSecondary={handleSecondary}
      >
        <Box d="flex" flexDirection="row" mb="8">
          <FormControl
            w="376px"
            mr="24px"
            textLabel={<FormattedMessage id="global.company" />}
          >
            <Select disabled value={company?.id}>
              <option value={company?.id}>{company?.name}</option>
            </Select>
          </FormControl>
          <FormControl
            w="376px"
            textLabel={<FormattedMessage id="edit_group.name" />}
          >
            <Input
              inputProps={{
                name: 'name',
                value: group.name || '',
                onChange: handleInputChange,
              }}
            />
          </FormControl>
        </Box>

        <FormSubtitle
          subtitle={<FormattedMessage id="edit_group.attach_user" />}
        >
          <Table
            headerColumns={columns}
            rows={rowsDevices}
            handleSort={handleLinkedMetadata}
            metadata={metadataLinkedDevices}
            keyProp={Types.LIST_LINKED_USER}
            pageSizeFixed
            pageActionsProps={{
              initialSearch: search,
              handleExportAllToExcel,
              onSearch: setSearch,
            }}
            nameTable={`${intl.formatMessage({ id: intlKeyTitle })}`}
          />
        </FormSubtitle>
      </FormContainer>
    </>
  );
};

export default EditGroup;
