import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Box,
  Flex,
} from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { usePlan } from '../../hooks/usePlan';
import { useAppSelector } from '../../hooks/useRedux';
import PersonIcon from '../Icons/Person';
import Text from '../Text';

interface SettingsProps {
  onCloseMenu?: () => void;
  labelLogout?: string;
  logoutClick?: () => void;
  changeCompanyClick?: () => void;
  versionClick?: () => void;
}

const Settings: React.FC<SettingsProps> = ({
  onCloseMenu,
  labelLogout,
  logoutClick,
  changeCompanyClick,
  versionClick,
}: SettingsProps) => {
  const { productVersion } = useAppSelector(
    (state) => state.portalSettings.portalSettings
  );
  const { isHelpDeskSession } = useAppSelector((state) => state.auth);
  const { planName, hasPlan } = usePlan();

  return (
    <Menu placement="bottom" onClose={onCloseMenu}>
      <MenuButton
        as={Button}
        borderRadius="100%"
        w="48px"
        h="48px"
        backgroundColor="gray.400"
        m="0px 10px 0px 14px"
      >
        <Box m="0px 0px 0px -5px">
          <PersonIcon boxSize={7} color="primary.500" />
        </Box>
      </MenuButton>

      <MenuList>
        {isHelpDeskSession && (
          <Link to="#" onClick={changeCompanyClick}>
            <MenuItem color="primary.500" fontSize="sm">
              <ExternalLinkIcon boxSize={5} mr="5px" color="primary.500" />

              <FormattedMessage id="header.settings.help_desk.option_change_company" />
            </MenuItem>
          </Link>
        )}

        <Link to="#" onClick={logoutClick}>
          <MenuItem color="primary.500" fontSize="sm">
            <ExternalLinkIcon boxSize={5} mr="5px" color="primary.500" />

            <FormattedMessage id={labelLogout} />
          </MenuItem>
        </Link>

        <MenuItem color="gray.900" fontSize="sm" onClick={versionClick}>
          <Flex align="center">
            <FormattedMessage id="global.version" />

            <Text ml="5px" color="gray.900">
              {productVersion}
            </Text>
          </Flex>
        </MenuItem>

        {hasPlan && (
          <MenuItem color="gray.900" fontSize="sm" pointerEvents="none">
            <Flex align="center">
              <FormattedMessage id="plan.plane_name" />

              <Text ml="5px" color="gray.900">
                {planName}
              </Text>
            </Flex>
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
};

export default Settings;
