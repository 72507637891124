import { ListItem, Stack, UnorderedList } from '@chakra-ui/layout';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useAppDispatch } from '../../../hooks/useRedux';
import { Types } from '../../../store/enterpriseApps';
import { hasError, uiRemoveError } from '../../../store/ui';
import Alert from '../../Alert';
import Text from '../../Text';
import {
  RemoveAppErrorEnum,
  ThereArePoliciesWithAppErrorResponse,
} from './utils';

export const RemoveAppsErrorModal = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const selectedError = hasError<ThereArePoliciesWithAppErrorResponse>(
    Types.DELETE
  );

  useEffect(() => {
    !!selectedError && triggerError();
  }, [selectedError]);

  function cleanError() {
    dispatch(uiRemoveError(Types.DELETE));
  }

  function triggerError() {
    const alertByErrorEnum = {
      [RemoveAppErrorEnum.THERE_ARE_POLICIES_WITH_APP]: () =>
        Alert({
          html: ThereArePoliciesWithAppHtml(),
          width: '40em',
          disabledCancel: true,
          onConfirm: cleanError,
          confirmButtonText: intl.formatMessage({
            id: 'enterprise_applications.error.there_are_policies_with_app.confirm_button',
          }),
        }),
    };

    alertByErrorEnum[selectedError.message]?.();
  }

  function ThereArePoliciesWithAppHtml() {
    return (
      <Stack textAlign="left" gridGap="10px">
        <Text m={0}>
          {intl.formatMessage(
            {
              id: 'enterprise_applications.error.there_are_policies_with_app',
            },
            {
              appName: selectedError?.appName,
            }
          )}
        </Text>

        <UnorderedList ml="20px !important" spacing="5px">
          {selectedError?.policies.map((policy) => (
            <ListItem>{policy}</ListItem>
          ))}
        </UnorderedList>
      </Stack>
    );
  }

  return null;
};
