import {
  CertificateTypeEnum,
  EAPInnerEnum,
  EAPOuterEnum,
  NetworkSecurityEnum,
} from '../../../../../types/network';

export const safetyTypeOptions: NetworkSecurityEnum[] = [
  NetworkSecurityEnum.NONE,
  NetworkSecurityEnum.WEP_PSK,
  NetworkSecurityEnum.WPA_PSK,
  NetworkSecurityEnum.WPA_EAP,
];

export const eapInnerOptions: EAPInnerEnum[] = [
  EAPInnerEnum.MSCHAPv2,
  EAPInnerEnum.PAP,
];

export const eapOuterOptions: EAPOuterEnum[] = [
  EAPOuterEnum.EAP_AKA,
  EAPOuterEnum.EAP_TLS,
  EAPOuterEnum.EAP_TTLS,
  EAPOuterEnum.EAP_SIM,
  EAPOuterEnum.PEAP,
];

export const certificateTypeOptions: CertificateTypeEnum[] = [
  CertificateTypeEnum.PKCS12,
  CertificateTypeEnum.X509,
];

export const acceptedFiles = '.p12,.pfx,.cer,.crt';
export const certificateAcceptedFileTypes = [
  'application/x-pkcs12',
  'application/x-x509-ca-cert',
];

export const certificateAcceptedTypeFile = {
  [CertificateTypeEnum.PKCS12]: 'application/x-pkcs12',
  [CertificateTypeEnum.X509]: 'application/x-x509-ca-cert',
};

export enum ErrorCertificateEnum {
  INVALID_FORMAT = 'INVALID_FORMAT',
}

export const certificateErrorMessageToIntl = {
  [ErrorCertificateEnum.INVALID_FORMAT]:
    'network_settings_details.error_message.invalid_format',
};
