import { Box, Flex } from '@chakra-ui/layout';
import { CloseButton, Tooltip } from '@chakra-ui/react';
import { debounce } from 'lodash';
import React, { ChangeEvent, ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';

import ButtonFileUpload from '../ButtonFileUpload';
import Input from '../Input';

interface FileUploadProps {
  labelError?: ReactNode;
  labelSuccess?: ReactNode;
  name: string;
  value: string;
  placeholder?: ReactNode;
  validateFile?: (file: File, name: string) => boolean;
  onChange: (file: File, name: string) => void;
  buttonTitle?: string;
  acceptedFiles?: string;
  isDisabled?: boolean;
}

const FileUpload = ({
  labelError,
  labelSuccess,
  name,
  value,
  placeholder,
  buttonTitle,
  onChange,
  validateFile,
  acceptedFiles,
  isDisabled,
}: FileUploadProps) => {
  const intl = useIntl();

  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const handleLoadFile = (file: File) => {
    if (!file) return;

    if (validateFile && !validateFile(file, name)) {
      setError(true);
      return;
    }

    try {
      onChange(file, name);
      setError(false);
      setSuccess(true);
      debounce(() => setSuccess(false), 2000)();
    } catch (e) {
      setError(true);
    }
  };

  return (
    <Flex direction="column" gridGap={2} w="full">
      <Tooltip
        hasArrow
        arrowSize={8}
        bg="success.500"
        padding="2"
        borderRadius="5"
        placement="bottom-start"
        isOpen={success}
        label={
          labelSuccess ||
          intl.formatMessage({ id: 'file_upload.message.success' })
        }
      >
        <Flex gridGap={6} w="full">
          <Input
            inputProps={{
              isDisabled: true,
              value: value,
              placeholder:
                placeholder ||
                intl.formatMessage({ id: 'file_upload.placeholder' }),
            }}
            rightElement={
              <CloseButton
                isDisabled={!value}
                onClick={() => onChange(null, name)}
              />
            }
          />

          <ButtonFileUpload
            uploadTitle={
              buttonTitle || intl.formatMessage({ id: 'file_upload.button' })
            }
            buttonId={name}
            inputProps={{
              name: name,
              onChange: (event: ChangeEvent<HTMLInputElement>) =>
                handleLoadFile(event?.target?.files[0]),
              accept: acceptedFiles,
              value: '',
            }}
            isDisabled={isDisabled}
          />
        </Flex>
      </Tooltip>

      <Box color="warning.500" fontSize="sm" fontWeight="bold" h="21px" m="0">
        {error &&
          (labelError ||
            intl.formatMessage({ id: 'file_upload.message.error' }))}
      </Box>
    </Flex>
  );
};

export default FileUpload;
