import { ChakraProvider, extendTheme } from '@chakra-ui/react';

import themeOption from '.';
import claroChTheme from './claro-ch/index';
import claroCoTheme from './claro-co/index';
import claroEcTheme from './claro-ec/index';
import claroRdTheme from './claro-rd/index';
import claroTheme from './claro/index';
import datamobTheme from './datamob/index';
import telcelTheme from './telcel/index';
import { ThemeType } from './theme';
import timTheme from './tim/index';
import vivoTheme from './vivo/index';

export const AppThemeProvider: React.FC = ({ children }) => {
  const customTheme: { [s: string]: ThemeType } = {
    telcel: telcelTheme,
    vivo: vivoTheme,
    datamob: datamobTheme,
    claro: claroTheme,
    claroch: claroChTheme,
    claroco: claroCoTheme,
    claroec: claroEcTheme,
    clarord: claroRdTheme,
    tim: timTheme,
  };

  const themeSelected: ThemeType =
    customTheme[process.env.REACT_APP_THEME_NAME || 'datamob'] || datamobTheme;

  const theme = extendTheme(themeOption, themeSelected);

  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
};
