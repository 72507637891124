import React, { useState, useEffect, ChangeEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Alert from '../../../components/Alert';
import FormContainer from '../../../components/FormContainer';
import FormControl from '../../../components/FormControl';
import Download from '../../../components/Icons/Download';
import TemplateIcon from '../../../components/Icons/Template';
import MenuItemFileUpload from '../../../components/MenuItemFileUpload';
import PageFilter from '../../../components/PageFilter';
import PageShowToaster from '../../../components/PageShowToaster';
import PageTitle from '../../../components/PageTitle';
import SelectAutocomplete from '../../../components/SelectAutocomplete';
import Table from '../../../components/Table';
import TableActions from '../../../components/TableActions';
import { routeWithParameters } from '../../../helper';
import { exportInExcel } from '../../../helper/exports';
import { fileToFormData } from '../../../helper/file';
import { inFirstPage } from '../../../helper/metadata';
import { useSorting } from '../../../helper/sort';
import { batchTemplateToDownload } from '../../../helper/template';
import { importErrorToIntl, useImport } from '../../../hooks/useImport';
import { usePrivilege } from '../../../hooks/usePrivilege';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import routes from '../../../routes';
import { listDeviceUserToFilter } from '../../../store/deviceUser';
import { generateExcel } from '../../../store/event';
import {
  deleteGroup,
  importGroups,
  listGroups,
  Types,
} from '../../../store/group';
import {
  hasError,
  hasSuccess,
  selectorMetadata,
  uiRemoveSuccess,
} from '../../../store/ui';
import { DeviceUserType } from '../../../types/deviceUser';
import { ListMetadata } from '../../../types/generic_list';
import { ImportGroupExceptionEnum } from '../../../types/group';
import { ID } from '../../../types/util';

export const batchGroupsImportErrorToIntl = {
  ...importErrorToIntl,
  [ImportGroupExceptionEnum.DEVICE_IN_OTHER_GROUP]:
    'manage_groups.error.import.device_in_other_group',
  [ImportGroupExceptionEnum.NUMBER_NOT_FOUND]:
    'manage_groups.error.import.number_not_found',
  default: 'manage_groups.error.import',
};

const ManageGroups = () => {
  const dispatch = useAppDispatch();
  const { accessFilterCompany } = usePrivilege();

  const { groups } = useAppSelector((state) => state.group);
  const { devicesUsersToFilter } = useAppSelector((state) => state.deviceUser);
  const metadata = useAppSelector(selectorMetadata(Types.LIST));
  const importError = hasError(Types.IMPORT_GROUP);
  const isImportSuccess = hasSuccess(Types.IMPORT_GROUP);

  const [filterSearch, setFilterSearch] = useState(null);
  const [filterPhone, setFilterPhone] = useState<DeviceUserType>();

  const intl = useIntl();

  const ACCEPTED_IMPORT_GROUPS_FORMAT_TYPE =
    '.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  const MAX_IMPORT_GROUP_FILE_SIZE = 1024 * 1024 * 5; // 5MB

  const { handleValidateFile } = useImport({
    key: Types.IMPORT_GROUP,
    maxFileSize: MAX_IMPORT_GROUP_FILE_SIZE,
  });

  const allFilters = {
    search: filterSearch,
    userId: filterPhone?.id,
  };

  useEffect(() => {
    dispatch(listDeviceUserToFilter());
  }, []);

  useEffect(() => {
    dispatch(listGroups(inFirstPage(metadata), allFilters));
  }, [filterPhone, filterSearch]);

  useEffect(() => {
    if (isImportSuccess) {
      Alert({
        icon: 'info',
        html: intl.formatMessage({ id: 'manage_groups.success.import' }),
        showCancelButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onConfirm: () => dispatch(uiRemoveSuccess(Types.IMPORT_GROUP)),
      });
    }
  }, [isImportSuccess]);

  const handleMetadata = (newMetadata: Partial<ListMetadata>) => {
    dispatch(listGroups({ ...metadata, ...newMetadata }, allFilters));
  };

  const handleFilterPhone = (newFilter: DeviceUserType) => {
    setFilterPhone(newFilter);
    dispatch(listDeviceUserToFilter());
  };

  const handleFilterPhoneChange = (value) => {
    dispatch(listDeviceUserToFilter({ search: value }));
  };

  const columns = useSorting(
    [
      {
        header: intl.formatMessage({
          id: 'manage_groups.column.name',
        }),
        accessor: 'name',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: 'manage_groups.column.userCount',
        }),
        accessor: 'userCount',
        canSort: true,
      },
    ],
    metadata
  );

  const handleDestroy = (id: ID) => {
    dispatch(deleteGroup(id));
  };

  const data = groups.map((group) => ({
    cells: [
      {
        field: 'name',
        value: group.name,
      },
      {
        field: 'userCount',
        value: group.userCount,
      },
      {
        field: 'actions',
        value: '',
        transform: () => {
          return (
            <TableActions
              entityIntlLabel={<FormattedMessage id="manage_groups.entity" />}
              openDestroy={() => {
                handleDestroy(group.id);
              }}
              linkEdit={routeWithParameters(routes.groups.edit, {
                id: group.id,
              })}
            />
          );
        },
      },
    ],
  }));

  const handleExportAllToExcel = () => {
    dispatch(
      generateExcel({
        type: Types.LIST,
        metadata: metadata,
        filters: allFilters,
      })
    );
  };

  const handleImportGroups = (event: ChangeEvent<HTMLInputElement>) => {
    const file: File = event.target.files[0];

    if (!file) return;
    handleValidateFile(file, () =>
      dispatch(importGroups(fileToFormData('file', file)))
    );
  };

  const handleDownloadTemplate = () => {
    return exportInExcel(batchTemplateToDownload(intl).groups);
  };

  return (
    <>
      <PageTitle
        showManualIcon
        title={<FormattedMessage id="manage_groups.title" />}
        description={<FormattedMessage id="manage_groups.description" />}
      />
      <PageShowToaster
        toasterKeys={[Types.CREATE]}
        messageSuccess={<FormattedMessage id="manage_groups.success.create" />}
        disabledError
      />
      <PageShowToaster
        toasterKeys={[Types.UPDATE]}
        messageSuccess={<FormattedMessage id="manage_groups.success.update" />}
      />
      <PageShowToaster
        toasterKeys={[Types.DELETE]}
        messageSuccess={<FormattedMessage id="manage_groups.success.delete" />}
      />
      <PageShowToaster
        toasterKeys={[Types.IMPORT_GROUP]}
        disabledSuccess
        messageError={
          <FormattedMessage
            id={
              batchGroupsImportErrorToIntl[importError?.message] ||
              batchGroupsImportErrorToIntl['default']
            }
          />
        }
      />
      <FormContainer>
        <PageFilter>
          <FormControl textLabel={<FormattedMessage id="global.phone" />}>
            <SelectAutocomplete
              options={devicesUsersToFilter}
              value={filterPhone}
              onChange={handleFilterPhone}
              onInputChange={handleFilterPhoneChange}
              getOptionLabel={(option) =>
                `${option.name || ''} ${option.device.phoneNumber || ''}`
              }
              placeholder={<FormattedMessage id="global.phone" />}
            />
          </FormControl>
        </PageFilter>
      </FormContainer>
      <Table
        headerColumns={columns}
        rows={data}
        handleSort={handleMetadata}
        metadata={metadata}
        keyProp={Types.LIST}
        pageActionsProps={{
          onSearch: setFilterSearch,
          linkNew: accessFilterCompany ? routes.groups.register : '',
          handleExportAllToExcel,
          menuActionButton: {
            title: `manage_groups.menu.button_title`,
            items: [
              {
                customMenuItem: (
                  <MenuItemFileUpload
                    uploadTitle={
                      <FormattedMessage id="manage_groups.import_groups.button_title" />
                    }
                    inputProps={{
                      name: 'uploadGroups',
                      value: '',
                      onChange: handleImportGroups,
                      accept: ACCEPTED_IMPORT_GROUPS_FORMAT_TYPE,
                    }}
                    icon={<Download boxSize={4} />}
                    key="uploadGroups"
                  />
                ),
              },
              {
                title: `manage_groups.template_model.button_title`,
                onClick: handleDownloadTemplate,
                icon: <TemplateIcon boxSize={4} />,
              },
            ],
          },
        }}
        nameTable={`${intl.formatMessage({ id: 'manage_groups.title' })}`}
      />
    </>
  );
};

export default ManageGroups;
