import { IntlShape } from 'react-intl';

export const batchTemplateToDownload = (intl: IntlShape) => {
  const formattedColumn = (idToTranslate: string) =>
    intl.formatMessage({ id: idToTranslate });

  return {
    groups: {
      name: intl.formatMessage(
        { id: 'batch_template_import.file_name' },
        { entity: intl.formatMessage({ id: 'batch_template_import.groups' }) }
      ),
      columns: [
        formattedColumn('batch_template_import.groups.columns.name'),
        formattedColumn('batch_template_import.groups.columns.device'),
      ],
    },
  };
};
