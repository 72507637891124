import { TabPanel, TabPanelProps } from '@chakra-ui/react';
import React from 'react';

import Card from '../../../../Card';

interface InvoiceTabPanelProps extends TabPanelProps {
  children: React.ReactNode;
}

const InvoiceTabPanel = ({ children, ...rest }: InvoiceTabPanelProps) => {
  return (
    <TabPanel {...rest}>
      <Card px={4}>{children}</Card>
    </TabPanel>
  );
};

export default InvoiceTabPanel;
