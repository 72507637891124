import { Box } from '@chakra-ui/react';
import { convertBytesTo, convertCompositionToBytes, Size } from 'portal-lib';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

import FormContainer from '../../../../components/FormContainer';
import FormControl from '../../../../components/FormControl';
import FormSubtitle from '../../../../components/FormSubtitle';
import PageErrors from '../../../../components/PageErrors';
import FormRule from '../../../../components/pages/Configurations/ConsumptionProfile/FormRule';
import PageTitle from '../../../../components/PageTitle';
import SelectAutocomplete from '../../../../components/SelectAutocomplete';
import { EnableToEnum } from '../../../../helper';
import { getMode, ModeEnum } from '../../../../helper/mode';
import { validatorDefaultMessages } from '../../../../helper/validador';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import routes from '../../../../routes';
import {
  actionsCreatorsConsumptionProfile,
  TypesConsumptionProfile,
} from '../../../../store/consumptionProfile';
import { listDeviceUserToFilter } from '../../../../store/deviceUser';
import { listGroupsToFilter } from '../../../../store/group';
import { hasSomeLoading, hasSomeSuccess } from '../../../../store/ui';
import {
  ConsumptionProfileMode,
  ConsumptionProfileTab,
  GeneralConsumptionType,
  GroupConsumptionType,
  RuleType,
  UserConsumptionType,
} from '../../../../types/consumptionProfile';
import { OperationalSystemEnum } from '../../../../types/device';
import { ID } from '../../../../types/util';

export interface LimitConsumptionType extends RuleType {
  limitValue?: number;
  limitUnit?: Size;
}

interface OptionSelectType
  extends GeneralConsumptionType,
    GroupConsumptionType,
    UserConsumptionType {
  id?: ID;
  name?: string;
}

const ConsumptionProfileEdit = () => {
  const { id, type: typeWithoutType } =
    useParams<{ id: string; type: string }>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const type: ConsumptionProfileTab = typeWithoutType as any;
  const history = useHistory();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const tabSelected = Object.values(ConsumptionProfileTab).includes(type)
    ? type
    : ConsumptionProfileTab.GENERAL;

  const mode =
    tabSelected === ConsumptionProfileTab.GENERAL
      ? ModeEnum.UPDATE
      : getMode(id);
  const optionsRadiosGeneral = [
    {
      id: ConsumptionProfileMode.UNLIMITED,
      label: intl.formatMessage({
        id: 'consumption_profile.radio_button_ilimited',
      }),
    },
    {
      id: ConsumptionProfileMode.ASSIGNED,
      label: intl.formatMessage({
        id: 'consumption_profile.radio_button_assigned',
      }),
    },
  ];
  const optionsRadiosEdit = [
    {
      id: ConsumptionProfileMode.LEVEL_UP,
      label: intl.formatMessage({
        id: 'consumption_profile.radio_button_defined',
      }),
    },
    ...optionsRadiosGeneral,
  ];

  const optionsRadios =
    tabSelected == ConsumptionProfileTab.GENERAL
      ? optionsRadiosGeneral
      : optionsRadiosEdit;

  const success = hasSomeSuccess([
    TypesConsumptionProfile.create[tabSelected],
    TypesConsumptionProfile.update[tabSelected],
    TypesConsumptionProfile.delete[tabSelected],
  ]);

  const { portalSettings } = useAppSelector((state) => state.portalSettings);
  const { company } = useAppSelector((state) => state.auth.user);
  const { groupsToFilter } = useAppSelector((state) => state.group);
  const { devicesUsersToFilter } = useAppSelector((state) => state.deviceUser);
  const isLoading = hasSomeLoading([
    TypesConsumptionProfile.create[tabSelected],
    TypesConsumptionProfile.update[tabSelected],
  ]);
  const { selected } = useAppSelector(
    (state) => state.consumptionProfile[tabSelected]
  );

  const limitEmpty: LimitConsumptionType = {
    id: 0,
    mode: ConsumptionProfileMode.LEVEL_UP,
    limitValue: 0,
    limitUnit: 'Bytes',
  };

  const emptyState = {
    data: limitEmpty,
    dataRoaming: limitEmpty,
    sms: limitEmpty,
    smsRoaming: limitEmpty,
  };

  const [selectedState, setSelectedState] = useState<
    GeneralConsumptionType & GroupConsumptionType & UserConsumptionType
  >(emptyState);

  const isDeviceIOS =
    selectedState?.deviceUser?.device?.operationalSystem ===
    OperationalSystemEnum.IOS;

  useEffect(() => {
    if (success && tabSelected !== ConsumptionProfileTab.GENERAL) {
      history.push(routes.profileConsumption.manage);
    }
  }, [success]);

  useEffect(() => {
    if (mode === ModeEnum.UPDATE) {
      dispatch(
        actionsCreatorsConsumptionProfile.get[tabSelected](parseInt(id))
      );
    }
    return () => {
      dispatch(
        actionsCreatorsConsumptionProfile.clear[tabSelected](parseInt(id))
      );
    };
  }, []);

  useEffect(() => {
    if (mode === ModeEnum.UPDATE) {
      const ruleToScreen = (
        rule: LimitConsumptionType,
        byteConversion?: boolean
      ) => ({
        ...rule,
        limitValue: byteConversion
          ? convertLimitToValueInput(rule?.limit, rule?.unit)
          : rule?.limit,
        limitUnit: rule?.unit,
      });
      setSelectedState({
        ...selected,
        data: ruleToScreen(selected?.data, true),
        dataRoaming: ruleToScreen(selected?.dataRoaming, true),
        sms: ruleToScreen(selected?.sms),
        smsRoaming: ruleToScreen(selected?.smsRoaming),
      });
    }
  }, [selected]);

  const convertToLimit = (quantity: number, unitByte: Size) => {
    const newLimit = convertCompositionToBytes({
      bytes: quantity,
      unit: unitByte,
    });
    return newLimit;
  };

  const convertLimitToValueInput = (
    selectedState: RuleType['limit'],
    unitRule: RuleType['unit']
  ) => {
    return (
      selectedState &&
      convertBytesTo({
        bytes: selectedState,
        decimals: 0,
        to: unitRule || 'Bytes',
      }).bytes
    );
  };

  const setConsumptionRule = (
    rule: RuleType,
    nameRule: 'data' | 'sms' | 'smsRoaming' | 'dataRoaming'
  ) => {
    setSelectedState({ ...selectedState, [nameRule]: rule });
  };

  const handlePrimary = () => {
    const actionsCreatorsMode =
      mode === ModeEnum.CREATE
        ? actionsCreatorsConsumptionProfile.create
        : actionsCreatorsConsumptionProfile.update;
    const screenToRule = (
      rule: LimitConsumptionType,
      byteConversion?: boolean
    ) => ({
      ...rule,
      limit: byteConversion
        ? convertToLimit(rule?.limitValue, rule?.limitUnit || 'Bytes')
        : rule?.limitValue,
      unit: rule?.limitUnit,
    });
    dispatch(
      actionsCreatorsMode[tabSelected]({
        ...selectedState,
        data: screenToRule(selectedState.data, true),
        dataRoaming: screenToRule(selectedState.dataRoaming, true),
        sms: screenToRule(selectedState.sms),
        smsRoaming: screenToRule(selectedState.smsRoaming),
      })
    );
  };

  const handleSecondary = () => {
    history.push(routes.profileConsumption.manage);
  };

  const simpleValidator = useRef(
    new SimpleReactValidator({
      messages: {
        ...validatorDefaultMessages(intl),
      },
    })
  );
  const [inputLevelField, setInputLevelField] = useState<string>();

  useEffect(() => {
    if (mode === ModeEnum.CREATE) {
      const enableTo = EnableToEnum.CONSUMPTION;
      if (tabSelected === ConsumptionProfileTab.GROUP) {
        dispatch(
          listGroupsToFilter({ search: inputLevelField, enableTo: enableTo })
        );
      } else if (tabSelected === ConsumptionProfileTab.USER) {
        dispatch(
          listDeviceUserToFilter({
            search: inputLevelField,
            enableTo: enableTo,
          })
        );
      }
    }
  }, [tabSelected, inputLevelField]);

  const renderSelectLevelField = () => {
    const optionsLevelField: {
      [k in ConsumptionProfileTab]: { id?: ID; name?: string }[];
    } = {
      [ConsumptionProfileTab.GENERAL]: [company],
      [ConsumptionProfileTab.GROUP]: groupsToFilter,
      [ConsumptionProfileTab.USER]: devicesUsersToFilter,
    };
    const textLabelLevelField: {
      [k in ConsumptionProfileTab]: string;
    } = {
      [ConsumptionProfileTab.GENERAL]: intl.formatMessage({
        id: 'global.company',
      }),
      [ConsumptionProfileTab.GROUP]: intl.formatMessage({ id: 'global.group' }),
      [ConsumptionProfileTab.USER]: intl.formatMessage({ id: 'global.user' }),
    };
    const fieldOfType = {
      [ConsumptionProfileTab.GROUP]: 'groups',
      [ConsumptionProfileTab.USER]: 'deviceUser',
    };
    const valueSelected = {
      [ConsumptionProfileTab.GENERAL]: company,
      [ConsumptionProfileTab.GROUP]: selectedState?.['group'],
      [ConsumptionProfileTab.USER]: selectedState?.['deviceUser'],
    };
    const idFieldOfType = {
      [ConsumptionProfileTab.GROUP]: 'groupId',
      [ConsumptionProfileTab.USER]: 'userId',
    };
    const disabled =
      tabSelected === ConsumptionProfileTab.GENERAL || mode !== ModeEnum.CREATE;

    const handleOptionSelected = (value: OptionSelectType) => {
      setSelectedState({
        ...emptyState,
        [fieldOfType?.[tabSelected]]: value,
        [idFieldOfType?.[tabSelected]]: value?.id,
      });
    };

    return (
      <FormControl textLabel={textLabelLevelField[tabSelected]}>
        <SelectAutocomplete
          name={
            tabSelected === ConsumptionProfileTab.GROUP ? 'groupId' : 'userId'
          }
          options={optionsLevelField[tabSelected]}
          value={valueSelected[tabSelected]}
          disabled={disabled}
          onInputChange={setInputLevelField}
          onChange={handleOptionSelected}
          getOptionLabel={(option: OptionSelectType) =>
            `${option.name || ''} ${option?.device?.phoneNumber || ''}`
          }
        />
        {tabSelected !== ConsumptionProfileTab.GENERAL &&
          simpleValidator.current.message(
            'levelField',
            selected?.[fieldOfType[tabSelected]],
            'required'
          )}
      </FormControl>
    );
  };

  return (
    <>
      {tabSelected !== ConsumptionProfileTab.GENERAL && (
        <PageTitle
          showManualIcon
          title={
            mode === ModeEnum.CREATE ? (
              <FormattedMessage id="consumption_profile.group_register.title" />
            ) : (
              <FormattedMessage id="consumption_profile.group_edit.title" />
            )
          }
          description={
            <FormattedMessage id="consumption_profile.edit.description" />
          }
        />
      )}
      <PageErrors
        toasterKeys={[
          TypesConsumptionProfile.update[ConsumptionProfileTab.GENERAL],
          TypesConsumptionProfile.update[ConsumptionProfileTab.GROUP],
          TypesConsumptionProfile.update[ConsumptionProfileTab.USER],
          TypesConsumptionProfile.create[ConsumptionProfileTab.GENERAL],
          TypesConsumptionProfile.create[ConsumptionProfileTab.GROUP],
          TypesConsumptionProfile.create[ConsumptionProfileTab.USER],
        ]}
        translateKey="consumption_profile"
      />
      <FormContainer
        labelPrimary={
          mode === ModeEnum.CREATE ? (
            <FormattedMessage id="global.register" />
          ) : (
            <FormattedMessage id="global.update" />
          )
        }
        labelSecondary={
          tabSelected == ConsumptionProfileTab.GENERAL ? undefined : (
            <FormattedMessage id="global.cancel" />
          )
        }
        handlePrimary={handlePrimary}
        handleSecondary={
          tabSelected == ConsumptionProfileTab.GENERAL
            ? undefined
            : handleSecondary
        }
        loadingPrimary={isLoading}
      >
        <Box d="flex" flexDirection="column">
          {renderSelectLevelField()}
          <FormSubtitle
            description={intl.formatMessage({
              id: 'consumption_profile.group.title',
            })}
          />
          <Box d="flex" flexDirection="row">
            <Box w="50%" pr="5px">
              <FormRule
                idRule="data"
                rule={selectedState.data}
                setRule={(value) => setConsumptionRule(value, 'data')}
                titleGroupRadios="consumption_profile.data"
                optionsRadios={optionsRadios}
                showOperationalSystemIcons={portalSettings.allowDeviceIOS}
              />
              <FormRule
                idRule="dataRoaming"
                rule={selectedState.dataRoaming}
                setRule={(value) => setConsumptionRule(value, 'dataRoaming')}
                titleGroupRadios="consumption_profile.data_roaming"
                optionsRadios={optionsRadios}
                showOperationalSystemIcons={portalSettings.allowDeviceIOS}
              />
            </Box>
            <Box w="50%" pl="5px">
              <FormRule
                idRule="sms"
                hideIOS
                isDisable={isDeviceIOS}
                rule={selectedState.sms}
                setRule={(value) => setConsumptionRule(value, 'sms')}
                titleGroupRadios="consumption_profile.sms"
                optionsRadios={optionsRadios}
                showOperationalSystemIcons={portalSettings.allowDeviceIOS}
              />
              <FormRule
                idRule="smsRoaming"
                hideIOS
                isDisable={isDeviceIOS}
                rule={selectedState.smsRoaming}
                setRule={(value) => setConsumptionRule(value, 'smsRoaming')}
                titleGroupRadios="consumption_profile.sms_roaming"
                optionsRadios={optionsRadios}
                showOperationalSystemIcons={portalSettings.allowDeviceIOS}
              />
            </Box>
          </Box>
        </Box>
      </FormContainer>
    </>
  );
};

export default ConsumptionProfileEdit;
