export type ID = number;

export type enterpriseId = string;

export type Mode = 'create' | 'edit' | 'update' | 'delete';

export type Bytes = number;

export type COLOR = string;

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends Record<string, unknown>
    ? RecursivePartial<T[P]>
    : T[P];
};

export type Autocomplete = { id?: number; name?: string };

export enum LevelTab {
  GENERAL = 'general',
  GROUP = 'groups',
  USER = 'users',
}
