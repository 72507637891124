import { Box, Tooltip } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

import MenuItem, { MenuItemProps } from '../TableActions/MenuItem';

interface MenuItemWithTooltipProps extends MenuItemProps {
  disabledTooltip?: boolean;
  labelTooltip?: string | ReactNode;
}

const MenuItemWithTooltip = ({
  disabledTooltip,
  labelTooltip,
  ...menuItemRest
}: MenuItemWithTooltipProps) => {
  return (
    <Tooltip isDisabled={disabledTooltip || !labelTooltip} label={labelTooltip}>
      <Box>
        <MenuItem {...menuItemRest} />
      </Box>
    </Tooltip>
  );
};

export default MenuItemWithTooltip;
