import { Flex, useRadioGroup } from '@chakra-ui/react';
import _ from 'lodash';
import React, { useEffect, useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';

import FormContainer from '../../../components/FormContainer';
import FormControl from '../../../components/FormControl';
import Input from '../../../components/Input';
import PageFilter from '../../../components/PageFilter';
import PageTitle from '../../../components/PageTitle';
import RadioButton from '../../../components/RadioButton';
import Select from '../../../components/Select';
import Skeleton from '../../../components/Skeleton';
import Table from '../../../components/Table';
import Text from '../../../components/Text';
import { DEBOUNCE_TIME, listStatesObject, StateEnum } from '../../../helper';
import { inFirstPage } from '../../../helper/metadata';
import { useSorting } from '../../../helper/sort';
import { useFormatDate } from '../../../hooks/useFormatDate';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import routes from '../../../routes';
import {
  listCompanyLicenses,
  listLicensesStatesOptions,
  Types,
} from '../../../store/company';
import { generateExcel } from '../../../store/event';
import { listPlans } from '../../../store/plan';
import { selectorLoading, selectorMetadata } from '../../../store/ui';
import { ListMetadata } from '../../../types/generic_list';
import { PlanType } from '../../../types/plan';
import { StateType } from '../../../types/state';

interface filterCompanyLicense {
  status: 'all' | 'yes' | 'no';
  state: StateType['key'] | null;
  plan: PlanType['name'] | null;
  phoneNumber: string;
}

const CompanyLicense = () => {
  const dispatch = useAppDispatch();
  const { handleFormatDateByUserLogged } = useFormatDate();
  const isNotActivationFilter = !!useRouteMatch(
    routes.company.licenseNotActivated
  );

  const { plans } = useAppSelector((state) => state.plan);
  const { licenses, licensesStatesOptions } = useAppSelector(
    (state) => state.company
  );
  const licensesMetadata = useAppSelector(selectorMetadata(Types.LICENSE_LIST));
  const [filterPhoneUndebounced, setFilterPhoneUndebonced] = useState<string>();
  const [filter, setFilter] = useState<filterCompanyLicense>({
    status: isNotActivationFilter ? 'no' : 'all',
    state: null,
    plan: null,
    phoneNumber: '',
  });

  const setSearchFilterDebounced = useCallback(
    _.debounce((value) => {
      setFilter({ ...filter, phoneNumber: value });
    }, DEBOUNCE_TIME),
    []
  );

  const isOptionsLoading = useAppSelector(
    selectorLoading(Types.LICENSE_STATES_OPTIONS)
  );

  const allFilters = {
    phoneNumber: filter.phoneNumber,
    status:
      filter.status === 'all' ? undefined : filter.status === 'yes' ? '1' : '0',
    state: filter.state,
    planName: filter.plan,
  };

  useEffect(() => {
    dispatch(listPlans());
    dispatch(listLicensesStatesOptions());
  }, []);

  useEffect(() => {
    dispatch(listCompanyLicenses(inFirstPage(licensesMetadata), allFilters));
  }, [filter]);

  const renderStatesOptions = () => {
    const licensesStatesFilter = licensesStatesOptions
      .map((state) => {
        return {
          key: listStatesObject?.[state]?.key,
          value:
            listStatesObject?.[state]?.value &&
            intl.formatMessage({ id: listStatesObject?.[state]?.value }),
        };
      })
      .sort((stateA, stateB) =>
        stateA.value && stateA.value > stateB.value ? 1 : -1
      );

    return licensesStatesFilter?.map((stateOption) => {
      return (
        <option value={stateOption.key} key={stateOption.key}>
          {stateOption.value}
        </option>
      );
    });
  };

  const renderPlansOptions = () => {
    return plans?.map((planOption) => {
      return (
        <option value={planOption.name} key={planOption.name}>
          {planOption.description || planOption.name}
        </option>
      );
    });
  };

  const handleStateSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilter({ ...filter, state: StateEnum[event.target.value] });
  };

  const handlePlanSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilter({ ...filter, plan: event.target.value });
  };

  const handleNewMetadata = (newMetadata: Partial<ListMetadata>) => {
    dispatch(
      listCompanyLicenses({ ...licensesMetadata, ...newMetadata }, allFilters)
    );
  };

  const intl = useIntl();

  const enrolledDeviceValues = [
    {
      value: 'all',
      text: intl.formatMessage({ id: 'global.all' }),
    },
    {
      value: 'yes',
      text: intl.formatMessage({ id: 'global.yes' }),
    },
    {
      value: 'no',
      text: intl.formatMessage({ id: 'global.no' }),
    },
  ];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'framework',
    defaultValue: isNotActivationFilter ? 'no' : 'all',
    onChange: (nextValue: filterCompanyLicense['status']) =>
      setFilter({ ...filter, status: nextValue }),
  });
  const groupRadio = getRootProps();

  const columnsTable = useSorting(
    [
      {
        header: intl.formatMessage({ id: 'company_license.column.phone' }),
        accessor: 'phoneNumber',
        canSort: true,
      },
      {
        header: intl.formatMessage({ id: 'company_license.column.plan' }),
        accessor: 'planName',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: 'company_license.column.last_update',
        }),
        accessor: 'updatedAt',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: 'company_license.column.enrolled_device',
        }),
        accessor: 'status',
        canSort: false,
      },
      {
        header: intl.formatMessage({ id: 'company_license.column.state' }),
        accessor: 'state',
        canSort: true,
      },
    ],
    licensesMetadata
  );

  const dataTable = licenses.map((license) => ({
    cells: [
      {
        field: 'phoneNumber',
        value: license.phoneNumber,
      },
      {
        field: 'planName',
        value: license?.plan?.description || license.planName,
      },
      {
        field: 'updatedAt',
        value: handleFormatDateByUserLogged(license.updatedAt),
      },
      {
        field: 'status',
        value: license.status
          ? intl.formatMessage({ id: 'global.yes' })
          : intl.formatMessage({ id: 'global.no' }),
      },
      {
        field: 'state',
        value:
          listStatesObject?.[license.state]?.value &&
          intl.formatMessage({
            id: listStatesObject?.[license.state]?.value,
          }),
        transform: () => {
          return (
            <Text m="0" fontWeight="400" color="success.500">
              {listStatesObject?.[license.state]?.value &&
                intl.formatMessage({
                  id: listStatesObject?.[license.state]?.value,
                })}
            </Text>
          );
        },
      },
    ],
  }));

  const handleExportAllToExcel = () => {
    dispatch(
      generateExcel({
        type: Types.LICENSE_LIST,
        metadata: licensesMetadata,
        filters: allFilters,
      })
    );
  };

  return (
    <>
      <PageTitle
        showManualIcon
        title={<FormattedMessage id="company_license.title" />}
        description={<FormattedMessage id="company_license.description" />}
      />
      <FormContainer>
        <PageFilter>
          <FormControl textLabel={<FormattedMessage id="global.phone" />}>
            <Input
              inputProps={{
                value: filterPhoneUndebounced || '',
                placeholder: intl.formatMessage({ id: 'global.phone' }),
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setFilterPhoneUndebonced(e.target.value);
                  setSearchFilterDebounced(e.target.value);
                },
              }}
            />
          </FormControl>
          <FormControl
            textLabel={
              <FormattedMessage id="company_license.filter.enrolled_device" />
            }
            w="225px"
          >
            <Flex {...groupRadio}>
              {enrolledDeviceValues.map((device) => {
                const radio = getRadioProps({ value: device.value });
                return (
                  <RadioButton key={device.value} {...radio}>
                    {device.text}
                  </RadioButton>
                );
              })}
            </Flex>
          </FormControl>

          <FormControl
            textLabel={<FormattedMessage id="company_license.filter.state" />}
          >
            {isOptionsLoading ? (
              <Skeleton w="100%" h="40px" />
            ) : (
              <Select
                placeholder={intl.formatMessage({
                  id: 'company_license.select_state',
                })}
                name="state"
                onChange={handleStateSelect}
                value={filter.state}
              >
                {renderStatesOptions()}
              </Select>
            )}
          </FormControl>
          <FormControl textLabel={<FormattedMessage id="global.plan" />}>
            {isOptionsLoading ? (
              <Skeleton w="100%" h="40px" />
            ) : (
              <Select
                placeholder={intl.formatMessage({
                  id: 'company_license.select_plan',
                })}
                name="plan"
                onChange={handlePlanSelect}
                value={filter.plan}
              >
                {renderPlansOptions()}
              </Select>
            )}
          </FormControl>
        </PageFilter>
      </FormContainer>
      <Table
        headerColumns={columnsTable}
        rows={dataTable}
        handleSort={handleNewMetadata}
        metadata={licensesMetadata}
        keyProp={Types.LICENSE_LIST}
        pageActionsProps={{
          handleExportAllToExcel,
        }}
        nameTable={`${intl.formatMessage({ id: 'company_license.title' })}`}
      />
    </>
  );
};

export default CompanyLicense;
