import { Box } from '@chakra-ui/react';

import Skeleton from '../Skeleton';

const NotificationSkeleton = () => {
  return (
    <Box mb="4" py={2} px={12}>
      <Skeleton mb="4" h="4" w="150px" />
      <Skeleton type={'TEXT'} noOfLines={4} />
    </Box>
  );
};

export default NotificationSkeleton;
