import {
  Badge as ChakraBadge,
  BadgeProps,
  Box,
  BoxProps,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

interface BadgeComponentsProps extends BadgeProps {
  text: string;
  containerProps?: BoxProps;
}

const Badge = ({ text, containerProps, ...rest }: BadgeComponentsProps) => {
  return (
    <Box w="150px" {...containerProps}>
      <ChakraBadge variant="subtle" colorScheme="purple" ml={10} {...rest}>
        <FormattedMessage id={text} />
      </ChakraBadge>
    </Box>
  );
};

export default Badge;
