import { Box, Flex } from '@chakra-ui/react';
import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import withCommunicationPositionMap from '../../../../assets/Images/with-communication-position-map.svg';
import withoutCommunicationPositionMap from '../../../../assets/Images/without-communication-position-map.svg';
import { ErrorEnum } from '../../../../helper/error';
import { useAppSelector } from '../../../../hooks/useRedux';
import { Types } from '../../../../store/dashboard';
import { hasError, selectorLoading } from '../../../../store/ui';
import Card from '../../../Card';
import Heading from '../../../Heading';
import { MarkerMapProps } from '../../../Map';
import OperationalSystemIcons from '../../../OperationalSystemIcons';
import Text from '../../../Text';
import DashboardMap from './DashboardMap';
import { MarkerInfosProps } from './MapInfoWindow';
interface DeviceLocationsProps {
  showOperationalSystems?: boolean;
}
function DevicesLocationMapComponent({
  showOperationalSystems,
}: DeviceLocationsProps) {
  const { devicesLocation } = useAppSelector((state) => state.dashboard);
  const isLoading = useAppSelector(
    selectorLoading(Types.LIST_DEVICES_LOCATION)
  );
  const hasData = devicesLocation.length > 0;
  const devicesLocationError = hasError(Types.LIST_DEVICES_LOCATION);
  const isUnauthorized = devicesLocationError?.error === ErrorEnum.FORBIDDEN;

  const devicesMarkers = useMemo(() => {
    return devicesLocation?.map<MarkerMapProps<MarkerInfosProps>>(
      ([phoneNumber, latitude, longitude, createdAt, online], deviceIndex) => {
        return {
          id: deviceIndex,
          lat: parseFloat(latitude),
          lng: parseFloat(longitude),
          icon: online
            ? withCommunicationPositionMap
            : withoutCommunicationPositionMap,
          infos: {
            phoneNumber: phoneNumber,
            dateAndTime: createdAt,
          },
        };
      }
    );
  }, [devicesLocation]);

  return (
    <Box w="100%">
      <Flex h="30px" mb="20px">
        <Heading
          as="h2"
          alignSelf="flex-end"
          fontSize="2xl"
          lineHeight="1.38"
          color="gray.900"
          fontWeight="400"
        >
          <FormattedMessage id="dashboard.devices_location" />
        </Heading>
        {showOperationalSystems && (
          <>
            <OperationalSystemIcons
              size="sm"
              iconProps={{
                color: 'android.50',
              }}
            />
          </>
        )}
      </Flex>

      <Card
        as={Flex}
        alignItems="center"
        justifyContent="center"
        height={hasData || isLoading ? '485px' : '300px'}
        margin={'20px 0 0 0'}
        w="100%"
        p={0}
      >
        {isUnauthorized ? (
          <Text>
            <FormattedMessage id="global.unauthorized.message" />
          </Text>
        ) : (
          <DashboardMap
            devicesMarkers={devicesMarkers}
            isEmpty={!hasData}
            loading={isLoading}
          />
        )}
      </Card>
    </Box>
  );
}

export const DevicesLocationMap = memo(DevicesLocationMapComponent);
