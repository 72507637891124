import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import PageShowToaster from '../../../../components/PageShowToaster';
import PageTitle from '../../../../components/PageTitle';
import Table from '../../../../components/Table';
import TableActions from '../../../../components/TableActions';
import { routeWithParameters } from '../../../../helper';
import { inFirstPage } from '../../../../helper/metadata';
import { featurePlanKeys } from '../../../../helper/plan';
import { useSorting } from '../../../../helper/sort';
import { usePrivilege } from '../../../../hooks/usePrivilege';
import {
  useAppDispatchByPlan,
  useAppSelector,
} from '../../../../hooks/useRedux';
import routes from '../../../../routes';
import { generateExcel } from '../../../../store/event';
import {
  deleteNetworkSetting,
  listNetworkSettings,
  Types,
} from '../../../../store/network';
import { selectorMetadata } from '../../../../store/ui';
import { ListMetadata } from '../../../../types/generic_list';
import { NetworkSecurityEnum } from '../../../../types/network';

const ManageNetworks = () => {
  const dispatch = useAppDispatchByPlan(featurePlanKeys.allowNetworkSettings);
  const { networkSettings } = useAppSelector((state) => state.network);
  const metadata = useAppSelector(selectorMetadata(Types.LIST));

  const [filterSearch, setFilterSearch] = useState(null);
  const { accessFilterCompany } = usePrivilege();

  const intl = useIntl();

  const allFilters = {
    search: filterSearch,
  };

  useEffect(() => {
    dispatch(listNetworkSettings(inFirstPage(metadata), allFilters));
  }, [filterSearch]);

  const handleMetadata = (newMetadata: Partial<ListMetadata>) => {
    dispatch(listNetworkSettings({ ...metadata, ...newMetadata }, allFilters));
  };

  const columns = useSorting(
    [
      {
        header: intl.formatMessage({
          id: 'manage_networks.column.name',
        }),
        accessor: 'name',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: 'manage_networks.column.security',
        }),
        accessor: 'security',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: 'manage_networks.column.ssid',
        }),
        accessor: 'ssid',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: 'manage_networks.column.automatic_connection',
        }),
        accessor: 'autoConnect',
        canSort: true,
      },
    ],
    metadata
  );

  const data = networkSettings.map((network) => ({
    cells: [
      {
        field: 'name',
        value: network.name,
      },
      {
        field: 'security',
        value:
          network.security === NetworkSecurityEnum.NONE
            ? intl.formatMessage({
                id: 'network_settings_details.security.none',
              })
            : network.security,
      },
      {
        field: 'ssid',
        value: network.ssid,
      },
      {
        field: 'autoConnect',
        value: intl.formatMessage({
          id: `manage_networks.column.automatic_connection.${
            network.autoConnect ? 'activated' : 'disabled'
          }`,
        }),
      },
      {
        field: 'actions',
        value: '',
        transform: () => {
          return (
            <TableActions
              entityIntlLabel={<FormattedMessage id="manage_networks.entity" />}
              openDestroy={() => dispatch(deleteNetworkSetting(network.id))}
              textToConfirm={
                network?.policies?.length > 0
                  ? 'manage_networks.open_destroy_message.network_settings_bound_text'
                  : 'manage_networks.open_destroy_message.text_default'
              }
              linkEdit={routeWithParameters(
                routes.generalConfig.networkSettings.edit,
                {
                  id: network.id,
                }
              )}
            />
          );
        },
      },
    ],
  }));

  const handleExportAllToExcel = () => {
    dispatch(
      generateExcel({
        type: Types.LIST,
        metadata: metadata,
        filters: allFilters,
      })
    );
  };

  return (
    <>
      <PageTitle
        showManualIcon
        title={<FormattedMessage id="manage_networks.title" />}
        description={<FormattedMessage id="manage_networks.description" />}
      />

      <PageShowToaster
        toasterKeys={[Types.CREATE, Types.UPDATE]}
        messageSuccess={
          <FormattedMessage id="network_settings_details.success_message" />
        }
        disabledError
      />

      <PageShowToaster toasterKeys={[Types.DELETE]} />
      <Table
        headerColumns={columns}
        rows={data}
        handleSort={handleMetadata}
        metadata={metadata}
        keyProp={Types.LIST}
        pageActionsProps={{
          onSearch: setFilterSearch,
          linkNew: accessFilterCompany
            ? routes.generalConfig.networkSettings.register
            : '',
          labelButtonNew: <FormattedMessage id="global.add" />,
          handleExportAllToExcel,
        }}
        nameTable={`${intl.formatMessage({ id: 'manage_networks.title' })}`}
      />
    </>
  );
};

export default ManageNetworks;
