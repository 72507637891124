import { Flex, Text, useTheme } from '@chakra-ui/react';
import { PieTooltipProps, ResponsivePie } from '@nivo/pie';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';
import routes from '../../../../routes';
import { Types } from '../../../../store/dashboard';
import { selectorLoading } from '../../../../store/ui';
import ChartLoading from '../../../ChartLoading';
import ChartTooltip from '../../../ChartTooltip';
import NotificationBadgeIcon from '../../../Icons/NotificationBadge';

type ChartDataProps = {
  id: string;
  label: string;
  value: number;
};

const MAX_LABEL_LENGTH = 13;

const DashboardDevicesInventoryChart = () => {
  const { devicesInventory } = useAppSelector((state) => state.dashboard);
  const isLoading = useAppSelector(
    selectorLoading(Types.LIST_DEVICES_LOCATION)
  );

  const devicesInventoryData = [...(devicesInventory.data || [])].sort(
    (inventoryA, inventoryB) => +inventoryB.qty - +inventoryA.qty
  );

  const { colors: colorsTheme, fontSizes } = useTheme();
  const history = useHistory();

  const colors = [
    colorsTheme['chart']['100'],
    colorsTheme['chart']['50'],
    colorsTheme['chart']['1150'],
    colorsTheme['chart']['1200'],
    colorsTheme['chart']['200'],
  ];

  const hasData = devicesInventoryData.length > 0;

  const handleCalculatePercentage = (qty: number) => {
    return Math.round((qty * 100) / devicesInventory?.total);
  };

  const handleSendToAnotherPage = (route: string) => {
    return history.push(route);
  };

  const handleFormatValueToPercentage = (inventoryValue: number) => {
    return String(inventoryValue).concat('%');
  };

  const ChartInventoryData: ChartDataProps[] =
    devicesInventoryData.length > 0 &&
    devicesInventoryData.map((inventory) => {
      return {
        id: inventory.label,
        label: _.truncate(inventory.label, { length: MAX_LABEL_LENGTH }),
        value: handleCalculatePercentage(inventory.qty),
      };
    });

  if (isLoading) {
    return <ChartLoading color={colors[0]} />;
  }

  return (
    <Flex align="center" justify="center" h="452px">
      {!hasData ? (
        <Text>
          <FormattedMessage id="dashboard.usage_time_by_application.empty_chart" />
        </Text>
      ) : (
        <ResponsivePie
          theme={{
            fontSize: fontSizes['xs'],
          }}
          data={ChartInventoryData}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          sortByValue={true}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          colors={colors}
          enableArcLinkLabels={false}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 0.2]],
          }}
          valueFormat={(inventoryValue) =>
            handleFormatValueToPercentage(inventoryValue)
          }
          onClick={() => handleSendToAnotherPage(routes.device.manage)}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="gray.900"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: 'color' }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{
            from: 'color',
            modifiers: [['darker', 2]],
          }}
          tooltip={({
            datum: { color, formattedValue, data },
          }: PieTooltipProps<ChartDataProps>) => {
            return ChartTooltip ? (
              <ChartTooltip color={color}>
                <Flex align="center" gridColumnGap={2}>
                  <NotificationBadgeIcon color={color} />
                  <Text>{data.id}</Text>
                  <strong>{formattedValue}</strong>
                </Flex>
              </ChartTooltip>
            ) : null;
          }}
          legends={[
            {
              anchor: 'bottom',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: 56,
              itemsSpacing: 0,
              itemWidth: 105,
              itemHeight: 18,
              itemTextColor: 'gray.900',
              itemDirection: 'left-to-right',
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: 'circle',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemTextColor: 'gray.900',
                  },
                },
              ],
            },
          ]}
        />
      )}
    </Flex>
  );
};

export default DashboardDevicesInventoryChart;
