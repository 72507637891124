import { CompanyType, LoginExpirationTimeEnum } from './company';
import { DeviceUserType } from './deviceUser';
import { GroupType } from './group';
import { ID } from './util';

interface LevelFields {
  company?: CompanyType;
  group?: GroupType;
  groupId?: ID;
  deviceUser?: DeviceUserType;
  userId?: ID;
}

export enum DayOfWeekCodeEnum {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}
export enum GpsFrequencyEnum {
  min3 = 180,
  min10 = 600,
  min30 = 1800,
  h1 = 3600,
  h6 = 21600,
  h12 = 43200,
  h24 = 86400,
}
export enum GpsPrecisionEnum {
  m200 = 200,
  m300 = 300,
  m400 = 400,
  m500 = 500,
}
export enum SyncTimeEnum {
  h1 = 3600,
  h6 = 21600,
  h12 = 43200,
  h24 = 86400,
}

export interface GeneralConfigurationType extends LevelFields {
  id?: ID;
  startDayOfWeek?: DayOfWeekCodeEnum;
  endDayOfWeek?: DayOfWeekCodeEnum;
  blockedOutSideWorkingHours?: boolean;
  startOfWorkingHours?: string;
  endOfWorkingHours?: string;
  startCycleDay?: number;
  gpsCapture?: boolean;
  gpsCaptureStartTime?: string;
  gpsCaptureEndTime?: string;
  gpsFrequency?: SyncTimeEnum;
  gpsPrecision?: GpsPrecisionEnum;
  syncTime?: SyncTimeEnum;
  blockedWifi?: boolean;
  blockedWebSites?: boolean;
  blockedApps?: boolean;
  notifyByEmail?: boolean;
  enabledHotspot?: boolean;
  enabledSafeBoot?: boolean;
  enabledAddUser?: boolean;
  enabledSDCard?: boolean;
  loginExpirationInSeconds?: LoginExpirationTimeEnum;
  hasTwoFactorAuthentication?: boolean;
}
