import { Box, Checkbox, SimpleGrid } from '@chakra-ui/react';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import FileUpload from '../../../../components/FileUpload';
import FormContainer from '../../../../components/FormContainer';
import FormControl from '../../../../components/FormControl';
import Input from '../../../../components/Input';
import InputPassword from '../../../../components/InputPassword';
import PageErrors from '../../../../components/PageErrors';
import {
  acceptedFiles,
  certificateAcceptedFileTypes,
  certificateAcceptedTypeFile,
  certificateErrorMessageToIntl,
  certificateTypeOptions,
  eapInnerOptions,
  eapOuterOptions,
  ErrorCertificateEnum,
  safetyTypeOptions,
} from '../../../../components/pages/Configurations/NetworkSettings/NetworkDetails/utils';
import PageTitle from '../../../../components/PageTitle';
import Select from '../../../../components/Select';
import { fileToBase64 } from '../../../../helper/file';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import {
  createNetworkSetting,
  getNetworkSetting,
  networkSettingsClear,
  setNetworkSettings,
  Types,
  updateNetworkSetting,
} from '../../../../store/network';
import { hasSomeLoading, hasSomeSuccess } from '../../../../store/ui';
import { NetworkSecurityEnum } from '../../../../types/network';

const NetworkDetails = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const intl = useIntl();
  const { networkSetting } = useAppSelector((state) => state.network);
  const { id } = useParams<{ id: string }>();
  const [errorCertificateType, setErrorCertificateType] =
    useState<ErrorCertificateEnum>();

  const isLoading = hasSomeLoading([Types.UPDATE, Types.CREATE]);
  const hasSuccess = hasSomeSuccess([Types.UPDATE, Types.CREATE]);

  const isEdit = !!id;

  useEffect(() => {
    if (isEdit) {
      dispatch(getNetworkSetting(Number(id)));
    } else {
      dispatch(
        setNetworkSettings({
          autoConnect: false,
          security: NetworkSecurityEnum.NONE,
        })
      );
    }

    return () => {
      dispatch(networkSettingsClear());
    };
  }, [id]);

  useEffect(() => {
    if (hasSuccess) {
      history.goBack();
    }
  }, [hasSuccess]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event?.target;

    dispatch(
      setNetworkSettings({
        ...networkSetting,
        [name]: value,
      })
    );
  };

  const handleCheckBoxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event?.target;

    dispatch(
      setNetworkSettings({
        ...networkSetting,
        [name]: checked,
      })
    );
  };

  const handleFileTypeChange = (newCertificateType: string, field: string) => {
    const certificateToFill = newCertificateType
      ? { type: newCertificateType }
      : null;
    setErrorCertificateType(null);
    dispatch(
      setNetworkSettings({
        ...networkSetting,
        [field]: certificateToFill,
      })
    );
  };

  const handleLoadFile = async (file: File, field: string) => {
    if (!file) {
      setErrorCertificateType(null);
      return dispatch(setNetworkSettings({ ...networkSetting, [field]: null }));
    }

    const base64File = await fileToBase64(file);
    setErrorCertificateType(null);
    dispatch(
      setNetworkSettings({
        ...networkSetting,
        [field]: {
          ...networkSetting[field],
          name: file?.name,
          certificate: base64File,
        },
      })
    );
  };

  const handleValidateFile = (file: File, field: string) => {
    if (
      certificateAcceptedTypeFile[networkSetting[field].type] !== file?.type
    ) {
      setErrorCertificateType(ErrorCertificateEnum.INVALID_FORMAT);
    }
    return certificateAcceptedFileTypes.includes(file.type);
  };

  const handleSubmit = () => {
    if (!isEdit) {
      dispatch(createNetworkSetting(networkSetting));
    } else {
      dispatch(
        updateNetworkSetting({
          id: Number(id),
          networkSetting,
        })
      );
    }
  };

  return (
    <>
      <PageTitle
        showManualIcon
        title={<FormattedMessage id={'network_settings_details.title'} />}
        description={
          <FormattedMessage id={'network_settings_details.description'} />
        }
      />

      <PageErrors
        toasterKeys={[Types.CREATE, Types.UPDATE]}
        translateKey="network_settings_details"
        w="full"
      />

      <FormContainer
        labelPrimary={
          <FormattedMessage id={'network_settings_details.button.save'} />
        }
        handlePrimary={handleSubmit}
        loadingPrimary={isLoading}
        disabledPrimary={
          !networkSetting?.name ||
          !networkSetting?.ssid ||
          (networkSetting?.security !== NetworkSecurityEnum.NONE &&
            !networkSetting?.passphrase)
        }
        disabledSecondary={false}
        labelSecondary={<FormattedMessage id="global.back" />}
        handleSecondary={() => history.goBack()}
      >
        <SimpleGrid columns={[1]} gap="6" p="2">
          <FormControl
            textLabel={<FormattedMessage id="network_settings_details.name" />}
          >
            <Input
              inputProps={{
                name: 'name',
                value: networkSetting?.name || '',
                onChange: handleChange,
              }}
            />
          </FormControl>
          <FormControl
            textLabel={<FormattedMessage id="network_settings_details.ssid" />}
          >
            <Input
              inputProps={{
                name: 'ssid',
                value: networkSetting?.ssid || '',
                onChange: handleChange,
              }}
            />
          </FormControl>
          <FormControl
            textLabel={
              <FormattedMessage id="network_settings_details.passphrase" />
            }
          >
            <InputPassword
              inputProps={{
                name: 'passphrase',
                value: networkSetting?.passphrase || '',
                onChange: handleChange,
                autoComplete: 'new-password',
              }}
            />
          </FormControl>

          <Checkbox
            name="autoConnect"
            maxW="max-content"
            isChecked={networkSetting?.autoConnect}
            onChange={handleCheckBoxChange}
          >
            <FormattedMessage id="network_settings_details.auto_connect" />
          </Checkbox>

          <FormControl
            textLabel={
              <FormattedMessage id="network_settings_details.security" />
            }
          >
            <Select
              name="security"
              value={networkSetting?.security || ''}
              onChange={handleChange}
            >
              {safetyTypeOptions.map((option) => (
                <option key={`${option}`} value={option}>
                  {option === NetworkSecurityEnum.NONE
                    ? intl.formatMessage({
                        id: 'network_settings_details.security.none',
                      })
                    : option}
                </option>
              ))}
            </Select>
          </FormControl>

          {networkSetting?.security === NetworkSecurityEnum.WPA_EAP && (
            <>
              <FormControl
                textLabel={
                  <FormattedMessage id="network_settings_details.eap_inner" />
                }
              >
                <Select
                  name="eapInner"
                  value={networkSetting?.eapInner || ''}
                  onChange={handleChange}
                  placeholder={intl.formatMessage({
                    id: 'network_settings_details.select_placeholder',
                  })}
                >
                  {eapInnerOptions.map((option) => (
                    <option key={`${option}`} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl
                textLabel={
                  <FormattedMessage id="network_settings_details.eap_outer" />
                }
              >
                <Select
                  name="eapOuter"
                  value={networkSetting?.eapOuter || ''}
                  onChange={handleChange}
                  placeholder={intl.formatMessage({
                    id: 'network_settings_details.select_placeholder',
                  })}
                >
                  {eapOuterOptions.map((option) => (
                    <option key={`${option}`} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <Box>
                <FormControl
                  textLabel={
                    <FormattedMessage id="network_settings_details.certificate_client.type" />
                  }
                  mb="6"
                >
                  <Select
                    name="certificateClientType"
                    value={networkSetting?.certificateClient?.type || ''}
                    placeholder={intl.formatMessage({
                      id: 'network_settings_details.select_placeholder',
                    })}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleFileTypeChange(
                        event?.target?.value,
                        'certificateClient'
                      )
                    }
                  >
                    {certificateTypeOptions.map((option) => (
                      <option key={`${option}`} value={option}>
                        {option}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl
                  textLabel={
                    <FormattedMessage id="network_settings_details.certificate_client.certificate" />
                  }
                >
                  <FileUpload
                    name="certificateClient"
                    value={networkSetting?.certificateClient?.name || ''}
                    onChange={handleLoadFile}
                    isDisabled={!networkSetting?.certificateClient?.type}
                    validateFile={handleValidateFile}
                    acceptedFiles={acceptedFiles}
                    labelError={
                      errorCertificateType &&
                      intl.formatMessage(
                        {
                          id: certificateErrorMessageToIntl[
                            errorCertificateType
                          ],
                        },
                        {
                          fileType: networkSetting?.certificateClient?.type,
                        }
                      )
                    }
                  />
                </FormControl>

                <FormControl
                  textLabel={
                    <FormattedMessage id="network_settings_details.certificate_server.type" />
                  }
                  mb="6"
                >
                  <Select
                    name="certificateServerType"
                    value={networkSetting?.certificateServer?.type || ''}
                    placeholder={intl.formatMessage({
                      id: 'network_settings_details.select_placeholder',
                    })}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleFileTypeChange(
                        event?.target?.value,
                        'certificateServer'
                      )
                    }
                  >
                    {certificateTypeOptions.map((option) => (
                      <option key={`${option}`} value={option}>
                        {option}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl
                  textLabel={
                    <FormattedMessage id="network_settings_details.certificate_server.certificate" />
                  }
                >
                  <FileUpload
                    name="certificateServer"
                    value={networkSetting?.certificateServer?.name || ''}
                    onChange={handleLoadFile}
                    validateFile={handleValidateFile}
                    acceptedFiles={acceptedFiles}
                    isDisabled={!networkSetting?.certificateServer?.type}
                    labelError={
                      errorCertificateType &&
                      intl.formatMessage(
                        {
                          id: certificateErrorMessageToIntl[
                            errorCertificateType
                          ],
                        },
                        {
                          fileType: networkSetting?.certificateServer?.type,
                        }
                      )
                    }
                  />
                </FormControl>
              </Box>
            </>
          )}
        </SimpleGrid>
      </FormContainer>
    </>
  );
};

export default NetworkDetails;
