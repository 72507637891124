import { Box } from '@chakra-ui/react';
import React, { useState, createContext } from 'react';

import MenuDrillDown from './components/MenuDrillDown';
import MenuSideIcons from './components/MenuSideIcons';
import getMenuItems, { MenuItem } from './menuItems';

interface MenuContext {
  selectedMenu: string;
  setSelectedMenu: (selectedMenu: string) => void;
  drillDownMenuOpened: boolean;
  setDrillDownMenuOpened: (selectedMenu: boolean) => void;
  menuItems: MenuItem[];
}

//
// Menu context creation
export const MenuContext = createContext({} as MenuContext);

function Menu() {
  const [drillDownMenuOpened, setDrillDownMenuOpened] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState('root');

  const menuItems = getMenuItems();

  const fewItems = menuItems.length < 4;

  return (
    <MenuContext.Provider
      value={{
        selectedMenu,
        setSelectedMenu,
        drillDownMenuOpened,
        setDrillDownMenuOpened,
        menuItems,
      }}
    >
      <Box w={65}>
        <MenuSideIcons fewItems={fewItems} />
        <MenuDrillDown fewItems={fewItems} />
      </Box>
    </MenuContext.Provider>
  );
}

export default Menu;
