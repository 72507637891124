import { Flex, FlexProps } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

interface MockupBarProps extends FlexProps {
  children?: ReactNode;
}

const MockupBar = ({ children, ...props }: MockupBarProps) => {
  return (
    <Flex
      h="40px"
      bgColor="gray.900"
      w="auto"
      alignItems="center"
      justifyContent="space-around"
      p={2}
      {...props}
    >
      {children}
    </Flex>
  );
};

export default MockupBar;
