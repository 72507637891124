import { Box, Flex, useTheme } from '@chakra-ui/react';
import { formatBytesTo } from 'portal-lib';
import { FormattedMessage } from 'react-intl';

import { useAppSelector } from '../../../hooks/useRedux';
import { Types } from '../../../store/dashboard';
import { selectorLoading } from '../../../store/ui';
import ChartBar from '../../ChartBar';
import ChartLoading from '../../ChartLoading';
import ChartTooltip from '../../ChartTooltip';
import NotificationBadgeIcon from '../../Icons/NotificationBadge';
import OperationalSystemIcons from '../../OperationalSystemIcons';
import Text from '../../Text';

enum LegendsEnum {
  DATA = 'data',
}
interface DashboardConsumptionHistoryProps {
  showOperationalSystems?: boolean;
}
const DashboardConsumptionHistory = ({
  showOperationalSystems,
}: DashboardConsumptionHistoryProps) => {
  const { consumptionSemester } = useAppSelector((state) => state.dashboard);
  const consumption = consumptionSemester?.consumption || [];
  const profile = consumptionSemester?.profile || null;

  const isLoading = useAppSelector(
    selectorLoading(Types.LIST_DEVICES_LOCATION)
  );
  const hasData = consumption.length > 0;

  const legends = [
    { label: 'data', color: 'chart.50', icon: 'showOperationalSystems' },
    { label: 'sms', color: 'chart.100', icon: 'hideIOS' },
  ];

  const currentDate = new Date();

  const lastMonth = (date, diff) => {
    const d = new Date(date);
    d.setMonth(d.getMonth() + diff + 1);
    return d;
  };

  const { colors } = useTheme();

  const lastSixMonths = (data) => {
    const dates = [];
    for (let i = 1; i < 7; i++) {
      dates.push(lastMonth(data, i * -1));
    }
    const monthAndYear = dates.map((date) => {
      return {
        month: date.getUTCMonth() + 1,
        year: date.getUTCFullYear(),
      };
    });
    const lastDates = monthAndYear.map((date) => {
      let formatMonth;
      if (date.month.toString().length === 1) {
        formatMonth = `${`0${date.month}`}`;
      } else {
        formatMonth = date.month;
      }
      return `${formatMonth}/${date.year}`;
    });
    return lastDates;
  };

  const dates = lastSixMonths(currentDate).reverse();

  const dataToUse = consumption?.map(({ data, sms }, index) => {
    return {
      indexBy: dates[index],
      dataPercent:
        profile?.data > 0 ? ((data / profile.data) * 100).toFixed(2) : 0,
      smsPercent: profile?.sms > 0 ? ((sms / profile.sms) * 100).toFixed(2) : 0,
      data,
      sms,
    };
  });

  if (isLoading) {
    return <ChartLoading color={legends[1].color} />;
  }

  return (
    <Box height="400px" pos="relative" paddingBottom="25px">
      {!hasData ? (
        <Flex align="center" justify="center" h="400px">
          <Text>
            <FormattedMessage id="dashboard.usage_time_by_application.empty_chart" />
          </Text>
        </Flex>
      ) : (
        <>
          <ChartBar
            data={dataToUse}
            keys={['dataPercent', 'smsPercent']}
            formatBottom={(value) => {
              return value;
            }}
            formatLeft={(value) => {
              return `${value}%`;
            }}
            layout="vertical"
            groupMode="grouped"
            margin={{ top: 50, right: 30, bottom: 50, left: 60 }}
            color={[colors['chart']['50'], colors['chart']['100']]}
            isInteractive={true}
            innerPadding={2}
            padding={0.25}
            tooltip={({ color, data, id }) => {
              const unformattedValue =
                +data[id.toString().replace('Percent', '')];
              return (
                <ChartTooltip color={color}>
                  <Text style={{ color: 'gray.500' }}>{data.indexBy}</Text>
                  <Box style={{ color: 'gray.500' }}>
                    <NotificationBadgeIcon color={color} />{' '}
                    {
                      <FormattedMessage
                        id={id === 'smsPercent' ? 'global.sms' : 'global.data'}
                      />
                    }
                    :{' '}
                    <strong>
                      {id === 'smsPercent'
                        ? unformattedValue.toFixed(0)
                        : formatBytesTo({ bytes: unformattedValue })}
                    </strong>
                  </Box>
                </ChartTooltip>
              );
            }}
          />
          <Flex paddingLeft="10px" justify="space-between">
            <Flex gridGap={10}>
              {legends.map((item, index) => (
                <Flex key={index} alignItems="center">
                  <NotificationBadgeIcon color={item.color} />
                  <Box lineHeight="1" ml="10px">
                    <FormattedMessage
                      id={
                        item.label === LegendsEnum.DATA
                          ? 'global.data'
                          : 'global.sms'
                      }
                    />
                  </Box>
                  {showOperationalSystems && (
                    <OperationalSystemIcons
                      size="sm"
                      hideIOS={item.icon === 'hideIOS'}
                      iconProps={{
                        color: 'android.50',
                      }}
                    />
                  )}
                </Flex>
              ))}
            </Flex>
          </Flex>
        </>
      )}
    </Box>
  );
};

export default DashboardConsumptionHistory;
