import { Redirect, Route, useRouteMatch } from 'react-router-dom';

import routes from '.';
import ErrorBoundary from '../components/ErrorBoundary';
import { usePrivilege } from '../hooks/usePrivilege';
import { useAppSelector } from '../hooks/useRedux';
import LayoutInternal from '../layouts/LayoutInternal';
import LayoutInternalClean from '../layouts/LayoutInternalClean';
import { CustomRouteProps } from './Routes';

type LayoutType = 'Default' | 'Clean';

interface PrivateRouteProps extends CustomRouteProps {
  layoutType?: LayoutType;
}

const PrivateRoute = ({
  isPrivate,
  path,
  component,
  layoutType = 'Default',
  ...rest
}: PrivateRouteProps) => {
  const isLogged = useAppSelector((state) => state.auth.isLogged);
  const { pages: privilegePages, isHelpDesk } = usePrivilege();

  return (
    <ErrorBoundary>
      <Route
        {...rest}
        path={path}
        component={() => {
          const pathArr: string[] = Array.isArray(path) ? path : [path];
          const { path: pathRoute } = useRouteMatch(pathArr);
          const enterpriseId = useAppSelector(
            (state) => state?.auth?.user?.company?.enterpriseId
          );

          if (isPrivate && (!isLogged || !privilegePages[pathRoute])) {
            return (
              <Redirect
                to={{ pathname: routes.login, state: { from: pathRoute } }}
              />
            );
          }

          if (!enterpriseId && !isHelpDesk) {
            return component.apply({});
          }

          if (layoutType === 'Clean') {
            return (
              <LayoutInternalClean>{component.apply({})}</LayoutInternalClean>
            );
          }

          return <LayoutInternal>{component.apply({})}</LayoutInternal>;
        }}
      />
    </ErrorBoundary>
  );
};

export default PrivateRoute;
