import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import PageTitle from '../../../components/PageTitle';
import Table from '../../../components/Table';
import { Body } from '../../../components/Table/TableInterfaces';
import { useSorting } from '../../../helper/sort';
import { useFormatDate } from '../../../hooks/useFormatDate';
import { useAppSelector } from '../../../hooks/useRedux';
import { Types, listDevicesWithoutCommunication } from '../../../store/device';
import { generateExcel } from '../../../store/event';
import { selectorMetadata } from '../../../store/ui';
import { ListMetadata } from '../../../types/generic_list';

const DevicesWithoutCommunication = () => {
  const dispatch = useDispatch();
  const { handleFormatDateByUserLogged } = useFormatDate();
  const { devicesWithoutCommunication } = useAppSelector(
    (state) => state.device
  );
  const metadata = useAppSelector(
    selectorMetadata(Types.LIST_NO_COMMUNICATION)
  );
  const [filterSearch, setFilterSearch] = useState('');
  const intl = useIntl();

  const allFilters = {
    search: filterSearch,
  };

  useEffect(() => {
    dispatch(listDevicesWithoutCommunication(metadata, allFilters));
  }, [filterSearch]);

  const handleMetadata = (newMetadata: Partial<ListMetadata>) => {
    dispatch(
      listDevicesWithoutCommunication(
        { ...metadata, ...newMetadata },
        allFilters
      )
    );
  };

  const columns = useSorting(
    [
      {
        header: intl.formatMessage({
          id: 'device_without_communication.column.user',
        }),
        accessor: 'name',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: 'device_without_communication.column.phoneNumber',
        }),
        accessor: 'phoneNumber',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: 'device_without_communication.column.imei',
        }),
        accessor: 'imei',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: 'device_without_communication.column.iccid',
        }),
        accessor: 'iccid',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: 'device_without_communication.column.manufacturer',
        }),
        accessor: 'manufacturer',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: 'device_without_communication.column.model',
        }),
        accessor: 'model',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: 'device_without_communication.column.updated_at',
        }),
        accessor: 'lastSyncAt',
        canSort: true,
      },
    ],
    metadata
  );

  const data: Body[] = devicesWithoutCommunication?.map((device) => ({
    cells: [
      {
        field: 'name',
        value: device.name,
      },
      {
        field: 'phoneNumber',
        value: device.phoneNumber,
      },
      {
        field: 'imei',
        value: device.imei,
      },
      {
        field: 'iccid',
        value: device.iccid,
      },
      {
        field: 'manufacturer',
        value: device.manufacturer,
      },
      {
        field: 'model',
        value: device.model,
      },
      {
        field: 'lastSyncAt',
        value: handleFormatDateByUserLogged(device.lastSyncAt),
      },
    ],
  }));

  const handleExportAllToExcel = () => {
    dispatch(
      generateExcel({
        type: Types.LIST_NO_COMMUNICATION,
        metadata: metadata,

        filters: allFilters,
      })
    );
  };

  return (
    <Box>
      <PageTitle
        showManualIcon
        title={<FormattedMessage id="device_without_communication.title" />}
        description={
          <FormattedMessage id="device_without_communication.title_description" />
        }
      />

      <Table
        headerColumns={columns}
        rows={data}
        handleSort={handleMetadata}
        metadata={metadata}
        keyProp={Types.LIST_NO_COMMUNICATION}
        pageActionsProps={{
          initialSearch: filterSearch,
          onSearch: setFilterSearch,
          handleExportAllToExcel,
        }}
        nameTable={intl.formatMessage({
          id: `device_without_communication.title`,
        })}
      />
    </Box>
  );
};
export default DevicesWithoutCommunication;
