import { formatBytesTo } from 'portal-lib';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Alert, { AlertHtml } from '../../../components/Alert';
import Delete from '../../../components/Icons/Delete';
import OperationalSystemIcon from '../../../components/Icons/OperationalSystemIcon';
import { calculateConsumptionProfileLabel } from '../../../components/pages/ProfileConsumption/CalculateConsumptionProfileLabel';
import TabPanel from '../../../components/pages/ProfileConsumption/TabPanel';
import TableActions from '../../../components/TableActions';
import MenuItem from '../../../components/TableActions/MenuItem';
import { routeWithParameters } from '../../../helper';
import { usePrivilege } from '../../../hooks/usePrivilege';
import { useAppDispatch } from '../../../hooks/useRedux';
import routes from '../../../routes';
import {
  deleteConsumptionUser,
  listConsumptionUser,
  Types,
} from '../../../store/consumptionProfile';
import { hasMetadata } from '../../../store/ui';
import { UserConsumptionType } from '../../../types/consumptionProfile';
import { ListMetadata } from '../../../types/generic_list';
import { ID } from '../../../types/util';
import { operationalSystemToIntl } from '../../Policies/EditPolicies';

interface UsersCardProps {
  users?: { list: UserConsumptionType[] };
}

const UsersCard = ({ users }: UsersCardProps) => {
  const intl = useIntl();
  const { isHelpDesk } = usePrivilege();

  const dispatch = useAppDispatch();

  const userMetada = hasMetadata(Types.LIST_USER);
  const [filterSearch, setFilterSearch] = useState('');

  const allFilters = {
    search: filterSearch,
  };

  const handleMetadata = (newMetadata: Partial<ListMetadata>) => {
    dispatch(
      listConsumptionUser(
        {
          ...userMetada,
          ...newMetadata,
        },
        allFilters
      )
    );
  };

  const handleSetLevelUp = (id: ID) => {
    dispatch(deleteConsumptionUser(id));
  };

  useEffect(() => {
    dispatch(
      listConsumptionUser({
        ...userMetada,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(listConsumptionUser(userMetada, allFilters));
  }, [filterSearch]);

  const dataUsers = users?.list.map((user) => ({
    cells: [
      {
        value: user?.operationalSystem && (
          <OperationalSystemIcon operationalSystem={user?.operationalSystem} />
        ),
        field: 'systemIcon',
        isExportHidden: true,
        chakraProps: { px: 0 },
      },
      {
        field: 'operationalSystem',
        value: intl.formatMessage({
          id: `${operationalSystemToIntl[user?.operationalSystem]}`,
        }),
        isExportField: true,
      },
      {
        field: 'name',
        value: user.deviceUser.name,
      },
      {
        field: 'phoneNumber',
        value: user.phoneNumber,
      },
      {
        field: 'data',
        value: calculateConsumptionProfileLabel(
          intl,
          user.data,
          (limitToFormater) =>
            formatBytesTo({
              bytes: limitToFormater,
              to: user.data.unit || 'Bytes',
            })
        ),
      },
      {
        field: 'data_roaming',
        value: calculateConsumptionProfileLabel(
          intl,
          user.dataRoaming,
          (limitToFormater) =>
            formatBytesTo({
              bytes: limitToFormater,
              to: user.dataRoaming.unit || 'Bytes',
            })
        ),
      },
      {
        field: 'sms',
        value: calculateConsumptionProfileLabel(
          intl,
          user.sms,
          undefined,
          user?.operationalSystem
        ),
      },
      {
        field: 'roaming_sms',
        value: calculateConsumptionProfileLabel(
          intl,
          user.smsRoaming,
          undefined,
          user?.operationalSystem
        ),
      },
      {
        field: 'actions',
        value: '',
        transform: () => {
          return (
            <TableActions
              entityIntlLabel={<FormattedMessage id="global.rule" />}
              linkEdit={routeWithParameters(
                routes.profileConsumption.users.edit,
                {
                  id: user.ruleId,
                }
              )}
              moreItems={
                <MenuItem
                  icon={<Delete boxSize={6} mr="5px" color="warning.500" />}
                  text={<FormattedMessage id="global.action.set_level_up" />}
                  isDisabled={isHelpDesk}
                  onClick={() =>
                    Alert({
                      onConfirm: () => handleSetLevelUp(user.ruleId),
                      html: (
                        <AlertHtml
                          text={intl.formatMessage({
                            id: 'consumption_profile.modal.apply_config',
                          })}
                        />
                      ),
                    })
                  }
                />
              }
            />
          );
        },
      },
    ],
  }));

  const usersColumn = [
    {
      header: '',
      accessor: 'systemIcon',
      isExportHidden: true,
    },
    {
      header: intl.formatMessage({
        id: 'global.operational_system',
      }),
      accessor: 'operationalSystem',
      isExportField: true,
    },
    {
      header: intl.formatMessage({
        id: 'consumption_profile.column.user',
      }),
      accessor: 'name',
      canSort: true,
    },
    {
      header: intl.formatMessage({
        id: 'consumption_profile.column.phoneNumber',
      }),
      accessor: 'phoneNumber',
    },
  ];

  return (
    <TabPanel
      type={Types.LIST_USER}
      columns={usersColumn}
      rows={dataUsers}
      handleSort={handleMetadata}
      linkNew={routes.profileConsumption.users.register}
      setFilterSearch={setFilterSearch}
      filterSearch={filterSearch}
      metaData={userMetada}
    />
  );
};

export default UsersCard;
