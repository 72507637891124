import { Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Swal from 'sweetalert2';

import Alert from '../../components/Alert';
import AlertEmptyData from '../../components/AlertEmptyData';
import DatePicker from '../../components/Datepicker';
import FormContainer from '../../components/FormContainer';
import FormControl from '../../components/FormControl';
import PageErrors from '../../components/PageErrors';
import PageFilter from '../../components/PageFilter';
import GeolocationMap from '../../components/pages/Geolocation/GeolocationMap';
import changeOptionsSelect, {
  changeOptionsSelectEnum,
} from '../../components/pages/Geolocation/SelectOptions';
import PageTitle from '../../components/PageTitle';
import Select from '../../components/Select';
import SelectAutocomplete from '../../components/SelectAutocomplete';
import WarningIfExitRoute from '../../components/WarningIfExitRoute';
import { featurePlanKeys } from '../../helper/plan';
import { usePlan } from '../../hooks/usePlan';
import { useAppDispatchByPlan, useAppSelector } from '../../hooks/useRedux';
import { listDeviceUserToFilter } from '../../store/deviceUser';
import {
  stopLocalizationPolling,
  Types as TypesEvent,
} from '../../store/event';
import {
  clearStoreGeolocation,
  clearStoreLocalization,
  createLocalizationRealTimeId,
  listGeolocation,
  listLocalizationRealTime,
  Types as TypesLocations,
} from '../../store/location';
import {
  hasLoading,
  hasSomeLoading,
  selectorMetadata,
  uiRemoveError,
  uiRemoveLoading,
} from '../../store/ui';
import { DeviceUserType } from '../../types/deviceUser';

const Geolocation = () => {
  const intl = useIntl();

  const dispatchByPlan = useAppDispatchByPlan(
    featurePlanKeys.allowLocalization
  );
  const { devicesUsersToFilter } = useAppSelector((state) => state.deviceUser);
  const { portalSettings } = useAppSelector((state) => state.portalSettings);
  const { geolocations, poolingId } = useAppSelector((state) => state.location);
  const metadata = useAppSelector(
    selectorMetadata(TypesLocations.LIST_HISTORIC)
  );
  const loadRealTime = !!hasLoading(TypesEvent.LOCALIZATION_START);

  const isLoading = hasSomeLoading([
    TypesEvent.LOCALIZATION_START,
    TypesLocations.CREATE_LOCALIZATION_REALTIME_ID,
    TypesLocations.LIST_LOCALIZATION,
  ]);

  const [form, setForm] = useState({ deviceUser: null });

  const [dateInput, setDateInput] = useState<Date>(new Date());
  const [selectLocaleBy, setSelectLocaleBy] = useState<'now' | 'byData'>(
    'byData'
  );
  const [selectTimezone, setSelectTimezone] = useState('user');
  const [selectPrecision, setSelectPrecision] = useState();
  const [showMap, setShowMap] = useState(false);

  const allFilters = {
    user: form?.deviceUser?.id,
    date: new Date(dateInput).toDateString(),
    timeZone: selectTimezone,
    precision: selectPrecision,
  };
  const { hasFeaturePlan } = usePlan({
    featurePlanKey: featurePlanKeys.allowLocalization,
  });

  useEffect(() => {
    if (geolocations.length > 0) {
      setShowMap(true);
    }
  }, [geolocations]);

  useEffect(() => {
    dispatchByPlan(listDeviceUserToFilter());

    return () => {
      dispatchByPlan(uiRemoveLoading(TypesEvent.LOCALIZATION_START));
      dispatchByPlan(stopLocalizationPolling());
      dispatchByPlan(clearStoreLocalization());
      dispatchByPlan(clearStoreGeolocation());
    };
  }, []);

  useEffect(() => {
    if (poolingId) {
      dispatchByPlan(listLocalizationRealTime(poolingId));
    }
  }, [poolingId]);

  useEffect(() => {
    if (loadRealTime) {
      Alert({
        icon: 'info',
        html: intl.formatMessage({ id: 'geolocation.loading' }),
        showCancelButton: false,
        onConfirm: () => false,
      });
    }
    if (hasFeaturePlan && !loadRealTime) {
      Swal.close();
    }
  }, [loadRealTime]);

  useEffect(() => {
    if (selectLocaleBy === 'byData') {
      dispatchByPlan(
        uiRemoveError(TypesLocations.CREATE_LOCALIZATION_REALTIME_ID)
      );
      dispatchByPlan(uiRemoveError(TypesEvent.LOCALIZATION_START));
    } else {
      dispatchByPlan(uiRemoveError(TypesLocations.LIST_LOCALIZATION));
    }
  }, [selectLocaleBy]);

  const handleDateInput = (date: Date) => {
    setDateInput(date);
  };

  const handleLocaleBy = (e) => {
    setSelectLocaleBy(e.target.value);
  };

  const handleTimezone = (e) => {
    setSelectTimezone(e.target.value);
  };

  const handlePrecision = (e) => {
    setSelectPrecision(e.target.value);
  };

  const submit = () => {
    if (selectLocaleBy === 'now') {
      return dispatchByPlan(createLocalizationRealTimeId(form.deviceUser?.id));
    } else {
      setShowMap(false);
      return dispatchByPlan(listGeolocation(metadata, allFilters));
    }
  };

  const handleFilterDeviceUser = (newFilter: DeviceUserType) => {
    setForm({ ...form, deviceUser: newFilter });
    dispatchByPlan(listDeviceUserToFilter());
  };

  const handleFilterDeviceUserInput = (search: string) => {
    dispatchByPlan(listDeviceUserToFilter({ search }));
  };

  return (
    <>
      <PageTitle
        showManualIcon
        title={<FormattedMessage id="geolocation.title" />}
        description={<FormattedMessage id="geolocation.title_text" />}
        showOperationalSystems={portalSettings.allowDeviceIOS}
      />
      <PageErrors
        toasterKeys={[
          TypesLocations.CREATE_LOCALIZATION_REALTIME_ID,
          TypesEvent.LOCALIZATION_START,
          TypesLocations.LIST_LOCALIZATION,
        ]}
        translateKey="geolocation"
      />
      <FormContainer
        labelFilter={<FormattedMessage id="geolocation.search" />}
        handleFilter={submit}
        disabledFilter={!form.deviceUser || isLoading}
        loadingFilter={isLoading}
      >
        <PageFilter>
          <FormControl
            flex="2"
            textLabel={<FormattedMessage id="geolocation.user" />}
          >
            <SelectAutocomplete
              name="deviceUser"
              options={devicesUsersToFilter}
              value={form.deviceUser}
              onChange={handleFilterDeviceUser}
              onInputChange={handleFilterDeviceUserInput}
              getOptionLabel={(option) =>
                `${option.name || ''} ${option.device.phoneNumber || ''}`
              }
            />
          </FormControl>
          <FormControl
            flex="1"
            minWidth="130px"
            textLabel={<FormattedMessage id="geolocation.findBy" />}
          >
            <Select
              name="locale"
              onChange={(e) => handleLocaleBy(e)}
              value={selectLocaleBy}
            >
              {changeOptionsSelect(changeOptionsSelectEnum.LOCALE).map((m) => (
                <option key={m.id} value={m.id}>
                  {m.label}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl
            flex="1"
            minWidth="170px"
            textLabel={<FormattedMessage id="geolocation.date" />}
          >
            <DatePicker
              selected={dateInput}
              onChange={(e) => {
                handleDateInput(e);
              }}
              readOnly={selectLocaleBy === 'now'}
              disabled={selectLocaleBy === 'now'}
              maxDate={new Date()}
            />
          </FormControl>
          <FormControl
            flex="1"
            minWidth="160px"
            textLabel={<FormattedMessage id="geolocation.timezone" />}
          >
            <Select
              name="timeZone"
              onChange={(e) => handleTimezone(e)}
              value={selectTimezone}
              disabled={selectLocaleBy === 'now'}
            >
              {changeOptionsSelect(changeOptionsSelectEnum.TIMEZONE).map(
                (m) => (
                  <option key={m.id} value={m.id}>
                    {m.label}
                  </option>
                )
              )}
            </Select>
          </FormControl>
          <FormControl
            flex="1"
            minWidth="130px"
            textLabel={<FormattedMessage id="geolocation.precision" />}
          >
            <Select
              name="precision"
              onChange={(e) => handlePrecision(e)}
              value={selectPrecision}
              disabled={selectLocaleBy === 'now'}
            >
              {changeOptionsSelect('precision').map((m) => (
                <option key={m.id} value={m.id}>
                  {m.label}
                </option>
              ))}
            </Select>
          </FormControl>
        </PageFilter>
      </FormContainer>
      <Box d="flex" flexDirection="column">
        {showMap && (
          <GeolocationMap
            locations={geolocations}
            showMap={showMap}
            convertTimezone={selectTimezone === 'user'}
          />
        )}
      </Box>
      <AlertEmptyData
        alertKey={
          selectLocaleBy === 'now'
            ? TypesLocations.CREATE_LOCALIZATION_REALTIME_ID
            : TypesLocations.LIST_LOCALIZATION
        }
      />
      <WarningIfExitRoute
        preventExit={loadRealTime}
        message={intl.formatMessage({ id: 'geolocation.warning_exit' })}
      />
    </>
  );
};

export default Geolocation;
