import { ReactNode } from 'react';
import { ErrorBoundary as ErrorBoundaryLib } from 'react-error-boundary';

import { handleDiscordLogger } from '../../helper/log';
import LayoutExternal from '../../layouts/LayoutExternal';
import LayoutInternal from '../../layouts/LayoutInternal';
import Fallback from './Fallback';

interface Props {
  children: ReactNode;
  isExternal?: boolean;
}

export default function ErrorBoundary({
  children,
  isExternal,
}: Props): JSX.Element {
  const Wrapper = isExternal ? LayoutExternal : LayoutInternal;

  return (
    <ErrorBoundaryLib
      FallbackComponent={(props) => (
        <Wrapper>
          <Fallback {...props} />
        </Wrapper>
      )}
      onError={handleDiscordLogger}
    >
      {children}
    </ErrorBoundaryLib>
  );
}
