import { Icon } from '@chakra-ui/react';
import React from 'react';

const MapLastPositionIcon = (props) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <svg
        id="pin"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="Caminho_211"
          d="M 12 2 C 7.613 2 4.044 5.533 4 9.92 C 4 15.4 11.05 21.5 11.35 21.76 C 11.724 22.08 12.276 22.08 12.65 21.76 C 13 21.5 20 15.4 20 9.92 C 19.956 5.533 16.387 2 12 2 Z"
          fill="#FF961E"
        />
      </svg>
    </Icon>
  );
};

export default MapLastPositionIcon;
