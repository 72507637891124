import { Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import Card from '../../../components/Card';
import { useAppSelector } from '../../../hooks/useRedux';
import Badge from '../../Badge';
import OperationalSystemIcons from '../../OperationalSystemIcons';
import Text from '../../Text';

interface DashboardCardProps {
  title: string | ReactNode;
  showOperationalSystems?: boolean;
  hideIOS?: boolean;
  children?: ReactNode;
  routeToLink?: string;
  showActiveRoaming?: boolean;
}

const DashboardCard = ({
  title,
  hideIOS,
  showOperationalSystems,
  children,
  routeToLink,
  showActiveRoaming,
  ...props
}: DashboardCardProps) => {
  const history = useHistory();
  const handleSendToAnotherPage = (route: string) => {
    return history.push(route);
  };
  const { filters } = useAppSelector((state) => state.dashboard);
  const hasOperationalSystemToShow = showOperationalSystems || hideIOS;

  const showRoaming = () => {
    if (showActiveRoaming && filters.roaming) {
      return (
        <Badge text="dashboard.roaming" containerProps={{ w: 'auto' }} ml="0" />
      );
    }
  };

  return (
    <Card p="24px" w="full" h="full" {...props}>
      <Flex
        mt="0"
        mb={
          showActiveRoaming && filters.roaming
            ? { base: '5px', xl: '30.5px' }
            : '30.5px'
        }
        gridGap={1}
        direction={{ base: 'column', xl: 'row' }}
        alignItems={{ base: 'flex-start', xl: 'center' }}
        justifyContent="space-between"
      >
        <Flex gridGap={1} alignItems="center">
          <Text
            fontSize="sm"
            letterSpacing="0.56px"
            color="gray.900"
            m="0"
            mr="4px"
          >
            {title}
          </Text>
          {hasOperationalSystemToShow && (
            <OperationalSystemIcons
              hideIOS={hideIOS}
              size="sm"
              iconProps={{
                color: 'android.50',
              }}
            />
          )}
        </Flex>

        {showRoaming()}
      </Flex>
      {children}
      {routeToLink && (
        <Flex
          onClick={() => handleSendToAnotherPage(routeToLink)}
          cursor={handleSendToAnotherPage && 'pointer'}
          justify={'center'}
        >
          <Text
            fontSize="md"
            lineHeight="1.36"
            letterSpacing="0.56px"
            color="primary.500"
          >
            <FormattedMessage id="dashboard.data_consumption.label.link" />
          </Text>
        </Flex>
      )}
    </Card>
  );
};
export default DashboardCard;
