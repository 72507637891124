import { Box, Flex, Text } from '@chakra-ui/layout';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import {
  getAcceptTermsDescription,
  replyAcceptTerms,
  Types,
} from '../../../../store/invoice';
import {
  hasError,
  hasLoading,
  hasSuccess,
  uiRemoveSuccess,
} from '../../../../store/ui';
import Button from '../../../Button';
import Modal from '../../../Modal';
import PageErrors from '../../../PageErrors';
import PageTitle from '../../../PageTitle';
import Spinner from '../../../Spinner';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
}

const getDescriptionErrors = {
  default: 'zero_touch.accept_terms.errors.get_description',
};

const replyErrors = {
  default: 'zero_touch.accept_terms.errors.reply',
};

export default function ModalAcceptTerms({ isOpen, onClose, onAccept }: Props) {
  const dispatch = useAppDispatch();
  const isLoading = hasLoading(Types.GET_ACCEPT_TERMS_DESCRIPTION);
  const isLoadingReply = hasLoading(Types.REPLY_ACCEPT_TERMS);
  const getDescriptionError = hasError(Types.GET_ACCEPT_TERMS_DESCRIPTION);
  const replyError = hasError(Types.REPLY_ACCEPT_TERMS);
  const successReply = hasSuccess(Types.REPLY_ACCEPT_TERMS);
  const intl = useIntl();
  const history = useHistory();
  const { acceptTermsDescription } = useAppSelector((state) => state.invoice);
  const [response, setResponse] = useState(null);

  useEffect(() => {
    dispatch(getAcceptTermsDescription());
  }, []);

  useEffect(() => {
    if (successReply) {
      if (response) {
        onClose();
        onAccept();
      } else {
        history.goBack();
      }
    }
  }, [successReply]);

  useEffect(() => {
    return () => {
      dispatch(uiRemoveSuccess(Types.REPLY_ACCEPT_TERMS));
    };
  }, []);

  const handleReply = (accepted: boolean) => () => {
    setResponse(accepted);
    dispatch(replyAcceptTerms(accepted));
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size="xl"
    >
      <Flex w="full" flexDirection="column" p="5%">
        <PageTitle
          title={intl.formatMessage({ id: 'zero_touch.accept_terms.title' })}
        />

        <PageErrors
          toasterKeys={[Types.GET_ACCEPT_TERMS_DESCRIPTION]}
          messageError={intl.formatMessage({
            id:
              getDescriptionErrors[getDescriptionError?.message] ||
              getDescriptionErrors.default,
          })}
          translateKey="zero_touch"
        />

        <PageErrors
          translateKey="zero_touch"
          toasterKeys={[Types.REPLY_ACCEPT_TERMS]}
          messageError={intl.formatMessage({
            id: replyErrors[replyError?.message] || replyErrors.default,
          })}
        />

        {isLoading && (
          <Flex
            w="full"
            direction="column"
            gridGap={4}
            justifyContent="center"
            align="center"
            my={8}
          >
            <Spinner size="xl" />

            <Text>
              {intl.formatMessage({
                id: 'zero_touch.accept_terms.loading_message',
              })}
            </Text>
          </Flex>
        )}

        {getDescriptionError && (
          <Flex justifyContent="flex-end" mt={4}>
            <Button onClick={() => history.goBack()}>
              {intl.formatMessage({ id: 'zero_touch.accept_terms.back' })}
            </Button>
          </Flex>
        )}

        {!isLoading && !getDescriptionError && (
          <>
            <Text letterSpacing="wide" my={3}>
              <Box
                dangerouslySetInnerHTML={{ __html: acceptTermsDescription }}
              />
            </Text>

            <Flex justifyContent="flex-end" gridGap={4} mt={4}>
              <Button
                onClick={handleReply(false)}
                variant="ghost"
                isLoading={response === false && isLoadingReply}
              >
                {intl.formatMessage({ id: 'zero_touch.accept_terms.decline' })}
              </Button>

              <Button
                onClick={handleReply(true)}
                isLoading={response === true && isLoadingReply}
              >
                {intl.formatMessage({ id: 'zero_touch.accept_terms.accept' })}
              </Button>
            </Flex>
          </>
        )}
      </Flex>
    </Modal>
  );
}
