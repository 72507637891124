import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  useStyleConfig,
} from '@chakra-ui/react';
import { useEffect, useState, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { MenuContext } from '../index';
import { handleItemsToRender, renderDrilldownMenu } from '../utils';

//
// context

interface Props {
  fewItems?: boolean;
}

function MenuDrillDown({ fewItems }: Props) {
  const [itemsToRender, setItemsToRender] = useState();
  const {
    drillDownMenuOpened,
    menuItems,
    selectedMenu,
    setSelectedMenu,
    setDrillDownMenuOpened,
  } = useContext(MenuContext);
  const history = useHistory();

  useEffect(() => {
    handleItemsToRender({ menuItems, setItemsToRender, selectedMenu });
  }, [selectedMenu]);

  const style = useStyleConfig('MenuDrillDown');

  return (
    <Drawer
      placement="left"
      onClose={() => setDrillDownMenuOpened(false)}
      isOpen={drillDownMenuOpened}
    >
      <DrawerOverlay />
      <DrawerContent>
        <Box
          width="fit-content"
          __css={{
            ...style,
          }}
        >
          <Flex
            p="0 32px 0 82px"
            width="fit-content"
            borderRadius="0 20px 20px 0"
            flexDirection="column"
            justifyContent="center"
            h="100vh"
            w="380px"
          >
            {selectedMenu === 'root' && (
              <Flex
                alignItems="center"
                fontSize="16px"
                lineHeight="1.25"
                letterSpacing="0.64px"
                color="gray.600"
                d="flex"
                h="80px"
              >
                <FormattedMessage id="menu.close" />
              </Flex>
            )}
            <Flex
              flex="1"
              flexDirection="column"
              justifyContent={fewItems ? 'start' : 'space-around'}
              padding={fewItems ? '36px 0' : '0'}
              gridGap={fewItems ? '36px 0' : '0'}
            >
              {itemsToRender &&
                renderDrilldownMenu({
                  itemsToRender,
                  setSelectedMenu,
                  setDrillDownMenuOpened,
                  history,
                })}
            </Flex>
          </Flex>
        </Box>
      </DrawerContent>
    </Drawer>
  );
}
export default MenuDrillDown;
