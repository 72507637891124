import { ExternalLinkIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Link,
  Stack,
  Tooltip,
} from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';
import { ChangeEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import FormContainer from '../../../../components/FormContainer';
import FormControl from '../../../../components/FormControl';
import Eye from '../../../../components/Icons/Eye';
import EyeDisabled from '../../../../components/Icons/EyeDisabled';
import LeftArrowCircleIcon from '../../../../components/Icons/LeftArrowCircle';
import Input from '../../../../components/Input';
import PageErrors from '../../../../components/PageErrors';
import PageShowToaster from '../../../../components/PageShowToaster';
import Text from '../../../../components/Text';
import { validatorUrl } from '../../../../helper/validador';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import LayoutCompanyInitial from '../../../../layouts/LayoutCompanyInitial';
import routes from '../../../../routes';
import {
  companyEditInfoSuccess,
  confirmInfoCompany,
  listCompanyInfo,
  Types,
  validateEmailSendCompany,
} from '../../../../store/company';
import {
  hasSomeLoading,
  hasSuccess,
  uiRemoveSuccess,
} from '../../../../store/ui';
import { CompanyRegisterFormType } from '../../../../types/companyRegister';

const CompanyConfirmInfo = () => {
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { company } = useAppSelector((state) => state.company);
  const { portalSettings } = useAppSelector((state) => state.portalSettings);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [form, setForm] = useState<CompanyRegisterFormType>({
    corporateName: '',
    email: '',
    password: '',
    confirmPassword: '',
    privacyPolicy: false,
    userTerms: false,
  });

  const companyCreateSuccess = hasSuccess(Types.CONFIRM_INFO);
  const companyValidateEmailSendSuccess = hasSuccess(Types.VALIDATE_EMAIL_SEND);
  const isLoading = hasSomeLoading([
    Types.CONFIRM_INFO,
    Types.VALIDATE_EMAIL_SEND,
  ]);

  const privacyPolicy = validatorUrl(
    process.env.REACT_APP_PORTAL_PRIVACY_POLICY
  )
    ? process.env.REACT_APP_PORTAL_PRIVACY_POLICY
    : routes.terms.privacyPolicy;

  const termsUsage = validatorUrl(process.env.REACT_APP_PORTAL_TERMS_OF_USAGE)
    ? process.env.REACT_APP_PORTAL_TERMS_OF_USAGE
    : routes.terms.termsOfUsage;

  useEffect(() => {
    if (companyValidateEmailSendSuccess) {
      dispatch(uiRemoveSuccess(Types.VALIDATE_EMAIL_SEND));
      history.push(routes.company.validEmail);
    }
  }, [companyValidateEmailSendSuccess]);

  useEffect(() => {
    if (companyCreateSuccess) {
      dispatch(validateEmailSendCompany());
    }
  }, [companyCreateSuccess]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value || e.target.checked });
  };

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  const submit = () => {
    dispatch(
      companyEditInfoSuccess({
        ...company,
        email: form.email,
        corporateName: form.corporateName,
      })
    );
    dispatch(confirmInfoCompany(form));
  };

  useEffect(() => {
    dispatch(listCompanyInfo());
  }, []);

  useEffect(() => {
    if (company) {
      setForm({
        ...form,
        corporateName: company.corporateName,
        phoneNumber: company.phoneNumber,
        email: company.email,
      });
    }
  }, [company]);

  const alertPassword = () => {
    if (
      form.password &&
      form.confirmPassword &&
      form.password !== form.confirmPassword
    ) {
      return (
        <Text color="warning.500" m="0 0 0 0" fontWeight="bold" fontSize="sm">
          <FormattedMessage id="register_company.password_alert" />
        </Text>
      );
    }
  };

  return (
    <LayoutCompanyInitial>
      <Box>
        <Button
          variant="link"
          leftIcon={<LeftArrowCircleIcon boxSize={8} color="primary.500" />}
          onClick={() => history.push(routes.login)}
        >
          <FormattedMessage id="global.back" />
        </Button>
      </Box>
      <Box pt="4" pb="4">
        <Text fontSize="4xl" fontWeight="300" color="gray.900" m="0">
          <FormattedMessage id="register_company.title1" />
        </Text>
        <Text fontSize="4xl" as="b" color="gray.900" fontWeight="700">
          <FormattedMessage id="register_company.title2" />
        </Text>
      </Box>
      <PageShowToaster
        disabledSuccess
        toasterKeys={[Types.VALIDATE_EMAIL_SEND]}
      />
      <PageErrors
        toasterKeys={[Types.CONFIRM_INFO]}
        translateKey="register_company"
        w="100%"
      />
      <FormContainer
        pl="0"
        labelPrimary={<FormattedMessage id="register_company.button.next" />}
        handlePrimary={submit}
        divider={false}
        primaryWidth="auto"
        disabledPrimary={
          !form.password ||
          !form.corporateName ||
          !form.email ||
          !form.userTerms ||
          !form.privacyPolicy ||
          form.password !== form.confirmPassword
        }
        loadingPrimary={isLoading}
      >
        <Flex flexDirection="column" gridGap="6">
          <FormControl
            w="300px"
            textLabel={
              <Tooltip
                label={<FormattedMessage id="register_company.corporateName" />}
                hasArrow
              >
                {intl.formatMessage({
                  id: 'register_company.corporateName',
                })}
              </Tooltip>
            }
          >
            <Input
              inputProps={{
                name: 'corporateName',
                value: form.corporateName || '',
                onChange: handleInputChange,
              }}
            />
          </FormControl>
          <FormControl
            w="300px"
            textLabel={
              <Tooltip
                label={<FormattedMessage id="register_company.email" />}
                hasArrow
              >
                {intl.formatMessage({
                  id: 'register_company.email',
                })}
              </Tooltip>
            }
          >
            <Input
              inputProps={{
                name: 'email',
                value: form.email || '',
                onChange: handleInputChange,
              }}
            />
          </FormControl>

          <Flex align="center" pt="1%">
            <Icon
              w="25px"
              h="25px"
              as={InfoOutlineIcon}
              mr="10px"
              color="gray.300"
            />
            <Text m="0" color="gray.900" fontSize="sm" w="300px">
              <FormattedMessage id="register_company.info" />
            </Text>
          </Flex>
        </Flex>
        <Text color="gray.900" fontWeight="bold" pt="3" mb="2%">
          <FormattedMessage id="register_company.create_new_password" />
        </Text>
        <Divider />
        <Flex pt="3" mb="5%" flexDirection="column">
          <FormControl
            pr="2%"
            w="300px"
            textLabel={
              <Tooltip
                label={<FormattedMessage id="register_company.password" />}
                hasArrow
              >
                {intl.formatMessage({
                  id: 'register_company.password',
                })}
              </Tooltip>
            }
          >
            <Input
              inputProps={{
                name: 'password',
                type: passwordShown ? 'text' : 'password',
                value: form.password || '',
                onChange: handleInputChange,
              }}
              rightElement={
                <i onClick={togglePasswordVisibility}>
                  {passwordShown ? (
                    <EyeDisabled
                      boxSize={6}
                      cursor="pointer"
                      color="gray.300"
                    />
                  ) : (
                    <Eye boxSize={6} cursor="pointer" />
                  )}
                </i>
              }
            />
            <Box h="21px">
              {!form?.password && (
                <Text
                  color="warning.500"
                  fontSize="sm"
                  fontWeight="bold"
                  m="0 0 0 0"
                >
                  <FormattedMessage id="register_company.empty_password_alert" />
                </Text>
              )}
            </Box>
          </FormControl>
          <FormControl
            mt="2%"
            w="300px"
            textLabel={
              <Tooltip
                label={
                  <FormattedMessage id="register_company.confirm_password" />
                }
                hasArrow
              >
                {intl.formatMessage({
                  id: 'register_company.confirm_password',
                })}
              </Tooltip>
            }
          >
            <Input
              inputProps={{
                name: 'confirmPassword',
                type: confirmPasswordShown ? 'text' : 'password',
                value: form.confirmPassword || '',
                onChange: handleInputChange,
              }}
              rightElement={
                <i onClick={toggleConfirmPasswordVisibility}>
                  {confirmPasswordShown ? (
                    <EyeDisabled
                      boxSize={6}
                      cursor="pointer"
                      color="gray.300"
                    />
                  ) : (
                    <Eye boxSize={6} cursor="pointer" />
                  )}
                </i>
              }
            />
            <Box my="6">{alertPassword()}</Box>
          </FormControl>

          <Text color="gray.900" fontWeight="bold" pt="3 " mb="2%">
            <FormattedMessage id="register_company.term.title" />
          </Text>
          <Divider mb="2%" />
          <Text color="gray.900" fontSize="sm">
            <FormattedMessage
              id="register_company.term.description"
              values={{ productName: portalSettings?.name }}
            />
          </Text>
          <Stack spacing="5" w="max-content" mb="2">
            <Checkbox
              name="privacyPolicy"
              isChecked={form.privacyPolicy}
              onChange={handleInputChange}
            >
              <Flex>
                <FormattedMessage id="register_company.term.privacy_policy" />
                <Tooltip
                  label={intl.formatMessage({ id: 'global.privacy_policy' })}
                >
                  <Link
                    href={privacyPolicy}
                    isExternal
                    whiteSpace="nowrap"
                    d="block"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    mb="6px"
                    ml="5px"
                    textDecoration="underline"
                    _hover={{
                      textDecoration: 'none',
                    }}
                  >
                    <FormattedMessage id="global.privacy_policy" />
                    <ExternalLinkIcon mx="8px" w={5} h={5} />
                  </Link>
                </Tooltip>
              </Flex>
            </Checkbox>
            <Checkbox
              name="userTerms"
              isChecked={form.userTerms}
              onChange={handleInputChange}
            >
              <Flex>
                <FormattedMessage id="register_company.term.user_term" />
                <Tooltip
                  label={intl.formatMessage({ id: 'global.terms_of_use' })}
                >
                  <Link
                    href={termsUsage}
                    isExternal
                    whiteSpace="nowrap"
                    d="block"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    mb="6px"
                    ml="5px"
                    textDecoration="underline"
                    _hover={{
                      textDecoration: 'none',
                    }}
                  >
                    <FormattedMessage id="global.terms_of_use" />
                    <ExternalLinkIcon mx="8px" w={5} h={5} />
                  </Link>
                </Tooltip>
              </Flex>
            </Checkbox>
          </Stack>
        </Flex>
      </FormContainer>
    </LayoutCompanyInitial>
  );
};

export default CompanyConfirmInfo;
