import { ErrorGeneric } from '../helper/error';
import { uiAddError } from '../store/ui';
import { ImportExceptionEnum } from '../types/import';
import { useAppDispatch } from './useRedux';

export const importErrorToIntl = {
  [ImportExceptionEnum.FAIL_READ_FILE]: 'import.file.error.fail_read_file',
  [ImportExceptionEnum.FAIL_TO_PROCESS_FILE]:
    'import.file.error.fail_to_process_file',
  [ImportExceptionEnum.FAIL_EMPTY_FILE]: 'import.file.error.fail_empty_file',
  [ImportExceptionEnum.INVALID_FORMAT]: 'import.file.error.invalid_format',
  [ImportExceptionEnum.FILE_SIZE_EXCEEDED]: 'import.file.error.size',
};

interface ImportProps {
  key: string;
  maxFileSize?: number;
  acceptedFileFormat?: string;
}

interface ImportType {
  handleValidateFile?: (file: File, func?: () => void) => void;
}

export const useImport = ({
  key,
  maxFileSize,
  acceptedFileFormat,
}: ImportProps): ImportType => {
  const dispatch = useAppDispatch();

  const handleValidateFile = (file: File, func: () => void) => {
    if (!file) return;

    const acceptedFileFormatTypes: string[] =
      acceptedFileFormat?.split(',')?.map((extension) => extension.trim()) ||
      [];

    if (!!acceptedFileFormat && !acceptedFileFormatTypes.includes(file?.type)) {
      return dispatch(
        uiAddError({
          [key]: new ErrorGeneric(ImportExceptionEnum.INVALID_FORMAT),
        })
      );
    }

    if (!!maxFileSize && file?.size > maxFileSize) {
      return dispatch(
        uiAddError({
          [key]: new ErrorGeneric(ImportExceptionEnum.FILE_SIZE_EXCEEDED),
        })
      );
    }

    return func();
  };

  return {
    handleValidateFile,
  };
};
