import { Box, Flex, Spinner } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import Card from '../../Card';
import OperationalSystemIcons from './OperationalSystemIcons';

interface CardHeadingProps {
  qty: number;
  title: string;
  icon: JSX.Element;
  qtyColor?: string;
  link?: string;
  loading?: boolean;
  showOperationalSystems?: boolean;
}

function CardHeading({
  qty,
  icon,
  title,
  qtyColor = 'gray.900',
  link,
  loading,
  showOperationalSystems,
}: CardHeadingProps) {
  const history = useHistory();
  const onClick = () => {
    history.push(link);
  };
  return (
    <Card
      flex="1"
      p="24px"
      onClick={() => link && onClick()}
      cursor={link ? 'pointer' : 'default'}
    >
      <Flex justify="space-between">
        <Box fontSize="40px" lineHeight="1.38" color={qtyColor}>
          {loading ? <Spinner /> : qty || 0}
        </Box>
        <Box>{icon}</Box>
      </Flex>
      <Flex
        textTransform="uppercase"
        fontSize="14px"
        lineHeight="1.43"
        letterSpacing="0.56px"
        mt="7px"
        color="gray.500"
        alignItems="center"
      >
        <FormattedMessage id={title} />
        {showOperationalSystems && <OperationalSystemIcons />}
      </Flex>
    </Card>
  );
}

export default CardHeading;
