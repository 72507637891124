import { Box, Checkbox, SimpleGrid, Stack } from '@chakra-ui/react';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useAppDispatch } from '../../../../../hooks/useRedux';
import { changeDevicePassword, Types } from '../../../../../store/device';
import { hasLoading, hasSuccess } from '../../../../../store/ui';
import { ResetPasswordFlagEnum } from '../../../../../types/device';
import { ID } from '../../../../../types/util';
import FormContainer from '../../../../FormContainer';
import FormControl from '../../../../FormControl';
import Heading from '../../../../Heading';
import InputPassword from '../../../../InputPassword';
import Modal, { ModalBody } from '../../../../Modal';
import PageErrors from '../../../../PageErrors';
import Text from '../../../../Text';

interface ModalNewDevicePasswordProps {
  isOpen: boolean;
  closeModal?: () => void;
  deviceId: ID;
}

const ModalNewDevicePassword = ({
  isOpen,
  closeModal,
  deviceId,
}: ModalNewDevicePasswordProps) => {
  const dispatch = useAppDispatch();
  const [form, setForm] = useState({
    newPassword: '',
    confirmNewPassword: '',
  });
  const [resetPasswordFlags, setResetPasswordFlags] = useState<
    ResetPasswordFlagEnum[]
  >([]);

  const success = hasSuccess(Types.ACTION_CHANGE_DEVICE_PASSWORD);
  const isLoading = hasLoading(Types.ACTION_CHANGE_DEVICE_PASSWORD);
  const passwordNotMatch =
    form.newPassword &&
    form.confirmNewPassword &&
    form.newPassword !== form.confirmNewPassword;

  useEffect(() => {
    if (success) {
      closeModal();
    }
  }, [success]);

  const resetPasswordFlagToEntity = {
    requireEntry: ResetPasswordFlagEnum.REQUIRE_ENTRY,
    notAskCredentialsOnBoot:
      ResetPasswordFlagEnum.DO_NOT_ASK_CREDENTIALS_ON_BOOT,
    lockNow: ResetPasswordFlagEnum.LOCK_NOW,
  };

  const handleCheckBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;
    if (checked) {
      setResetPasswordFlags([
        ...resetPasswordFlags,
        resetPasswordFlagToEntity[name],
      ]);
    } else {
      const flagsFilter = resetPasswordFlags.filter(
        (flag) => flag !== resetPasswordFlagToEntity[name]
      );
      setResetPasswordFlags(flagsFilter);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = () => {
    dispatch(
      changeDevicePassword({
        deviceId,
        newPassword: form?.newPassword,
        resetPasswordFlags,
      })
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={() => closeModal()} size="xl">
      <ModalBody>
        <Box w="full" p="2.5">
          <Heading>
            <FormattedMessage id="devices.action.new_device_password" />
          </Heading>
          <Text>
            <FormattedMessage id="device.modal.new_device_password.description" />
          </Text>
          <PageErrors
            toasterKeys={[Types.ACTION_CHANGE_DEVICE_PASSWORD]}
            translateKey="device.modal.new_device_password"
          />
        </Box>

        <FormContainer
          labelPrimary={<FormattedMessage id={'global.confirm'} />}
          handlePrimary={handleSubmit}
          disabledPrimary={
            (form.newPassword && !form.confirmNewPassword) || passwordNotMatch
          }
          labelSecondary={<FormattedMessage id={'global.cancel'} />}
          handleSecondary={() => closeModal()}
          loadingPrimary={isLoading}
          w="full"
        >
          <form autoComplete="off">
            <SimpleGrid gridGap="6" mb="6">
              <FormControl
                textLabel={
                  <FormattedMessage id="device.modal.new_device_password.newPassword" />
                }
              >
                <InputPassword
                  inputProps={{
                    name: 'newPassword',
                    value: form.newPassword || '',
                    onChange: handleInputChange,
                  }}
                />
              </FormControl>
              <FormControl
                textLabel={
                  <FormattedMessage id="device.modal.new_device_password.new_password_confirm" />
                }
              >
                <InputPassword
                  inputProps={{
                    name: 'confirmNewPassword',
                    value: form.confirmNewPassword || '',
                    onChange: handleInputChange,
                  }}
                />
                <Box h="21px">
                  {passwordNotMatch ? (
                    <Text
                      color="warning.500"
                      m="0 0 0 0"
                      fontWeight="bold"
                      fontSize="sm"
                    >
                      <FormattedMessage id="register_company.password_alert" />
                    </Text>
                  ) : null}
                </Box>
              </FormControl>
            </SimpleGrid>
          </form>

          <Stack spacing="5" w="full" mb="2">
            <Checkbox
              name="notAskCredentialsOnBoot"
              onChange={handleCheckBoxChange}
            >
              <FormattedMessage id="device.modal.new_device_password.checkbox.not_ask_credentials_user" />
            </Checkbox>
            <Checkbox name="lockNow" onChange={handleCheckBoxChange}>
              <FormattedMessage id="device.modal.new_device_password.checkbox.device_block" />
            </Checkbox>
          </Stack>
        </FormContainer>
      </ModalBody>
    </Modal>
  );
};

export default ModalNewDevicePassword;
