import { FormattedMessage, useIntl } from 'react-intl';

import { formatDateByUserLogged } from '../../../../helper/date';
import { useAppSelector } from '../../../../hooks/useRedux';
import { DeviceInfoBatteryType } from '../../../../types/deviceInfo';
import ChartLine from '../../../ChartLine';
import ChartTooltip from '../../../ChartTooltip';
import Text from '../../../Text';

interface DeviceBatteryChartProps {
  batteryData?: DeviceInfoBatteryType[];
}

function DeviceBatteryChart({ batteryData }: DeviceBatteryChartProps) {
  const { user } = useAppSelector((state) => state.auth);
  const { portalSettings } = useAppSelector((state) => state.portalSettings);
  const intl = useIntl();

  const chartData = [
    {
      id: 'battery',
      data: batteryData.map((item) => ({
        ...item,
        x: formatDateByUserLogged(
          item.date,
          user,
          portalSettings,
          undefined,
          'short'
        ),
        y: item.percent,
      })),
    },
  ];

  return (
    <ChartLine
      chartData={chartData}
      yScale={{ type: 'linear', stacked: false, min: 0, max: 100 }}
      yFormat={(value) => `${value}%`}
      enablePointLabel={false}
      theme={{
        fontSize: 12,
      }}
      axisLeft={{
        format: (value) => `${value}%`,
        legend: intl.formatMessage({
          id: 'battery.chart_battery',
        }),
        legendPosition: 'middle',
        legendOffset: -50,
      }}
      axisBottom={{
        legend: intl.formatMessage({
          id: 'battery.chart_date',
        }),
        legendPosition: 'middle',
        legendOffset: 50,
        // tickSize: 12,
      }}
      tooltip={({ point }) => (
        <ChartTooltip color={'primary.500'}>
          <Text>
            <FormattedMessage id="battery.chart_battery" />:
            {point.data.yFormatted}
          </Text>
          <Text>
            <FormattedMessage id="battery.chart_date" />:{point.data.xFormatted}
          </Text>
        </ChartTooltip>
      )}
    />
  );
}

export default DeviceBatteryChart;
