import { Box } from '@chakra-ui/react';
import { isDate } from 'lodash';
import queryString from 'query-string';
import { useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

import DatePicker from '../../../components/Datepicker';
import FormContainer from '../../../components/FormContainer';
import FormControl from '../../../components/FormControl';
import PageActions from '../../../components/PageActions';
import PageFilter from '../../../components/PageFilter';
import {
  getScreenByPath,
  listColumnByScreen,
  listRowsByScreen,
  Screens,
} from '../../../components/pages/Messages/utils';
import PageShowToaster from '../../../components/PageShowToaster';
import PageTitle from '../../../components/PageTitle';
import Table from '../../../components/Table';
import { Body } from '../../../components/Table/TableInterfaces';
import TableActions from '../../../components/TableActions';
import { routeWithParameters } from '../../../helper';
import { inFirstPage } from '../../../helper/metadata';
import { featurePlanKeys } from '../../../helper/plan';
import { useSorting } from '../../../helper/sort';
import { useFormatDate } from '../../../hooks/useFormatDate';
import {
  useAppDispatch,
  useAppDispatchByPlan,
  useAppSelector,
} from '../../../hooks/useRedux';
import routes from '../../../routes';
import { listDocument, Types as DocumentTypes } from '../../../store/document';
import { generateExcel } from '../../../store/event';
import {
  listExternalApps,
  Types as ExternalAppsTypes,
} from '../../../store/externalApps';
import { listMessages, Types as MessageTypes } from '../../../store/message';
import {
  listRemoteApps,
  Types as RemoteAppTypes,
} from '../../../store/remoteApps';
import {
  hasTable,
  selectorMetadata,
  uiRemoveMetadata,
  uiRemoveTable,
  uiShowTable,
} from '../../../store/ui';
import { ListMetadata } from '../../../types/generic_list';
import { MessageTypeFilter } from '../../../types/message';

const MessagesList = () => {
  const history = useHistory();
  const currentScreen = getScreenByPath(history.location.pathname);
  const dispatchExternalApps = useAppDispatch();
  const dispatchRemoteApps = useAppDispatch();
  const dispatchMessage = useAppDispatchByPlan(featurePlanKeys.allowMessage);
  const dispatchDocument = useAppDispatchByPlan(featurePlanKeys.allowDocument);
  const dispatch = {
    [Screens.MESSAGES]: dispatchMessage,
    [Screens.DOCUMENTS]: dispatchDocument,
    [Screens.EXTERNAL_APPS]: dispatchExternalApps,
    [Screens.REMOTE_APPS]: dispatchRemoteApps,
  }[currentScreen];
  const { handleFormatDateByUserLogged } = useFormatDate();
  const Types = {
    [Screens.MESSAGES]: MessageTypes,
    [Screens.DOCUMENTS]: DocumentTypes,
    [Screens.EXTERNAL_APPS]: ExternalAppsTypes,
    [Screens.REMOTE_APPS]: RemoteAppTypes,
  }[currentScreen];
  const { messages } = useAppSelector((state) => state.message);
  const { documents } = useAppSelector((state) => state.document);
  const { externalApps } = useAppSelector((state) => state.externalApps);
  const { remoteApps } = useAppSelector((state) => state.remoteApps);
  const metadata = useAppSelector(selectorMetadata(Types.LIST));
  const { search } = useLocation();
  const querySearchObj = useMemo(() => queryString.parse(search), [search]);

  const filterMessage = useMemo<MessageTypeFilter>(
    () => ({
      endAt: querySearchObj?.endAt && new Date(querySearchObj?.endAt as string),
      startAt:
        querySearchObj?.startAt && new Date(querySearchObj?.startAt as string),
      search: querySearchObj?.search as string,
    }),
    [querySearchObj]
  );
  const TODAY = new Date();
  const showTable = hasTable(Types.LIST);

  const list = {
    [Screens.MESSAGES]: messages,
    [Screens.DOCUMENTS]: documents,
    [Screens.EXTERNAL_APPS]: externalApps,
    [Screens.REMOTE_APPS]: remoteApps,
  }[currentScreen];

  const listEntity = {
    [Screens.MESSAGES]: listMessages,
    [Screens.DOCUMENTS]: listDocument,
    [Screens.EXTERNAL_APPS]: listExternalApps,
    [Screens.REMOTE_APPS]: listRemoteApps,
  }[currentScreen];

  const keysIntlEntity = {
    [Screens.MESSAGES]: 'message',
    [Screens.DOCUMENTS]: 'document',
    [Screens.EXTERNAL_APPS]: 'external_apps',
    [Screens.REMOTE_APPS]: 'remoteApp',
  }[currentScreen];

  const linkNew = {
    [Screens.MESSAGES]: routes.messages.register,
    [Screens.DOCUMENTS]: routes.documents.register,
    [Screens.EXTERNAL_APPS]: routes.externalApps.register,
    [Screens.REMOTE_APPS]: routes.remoteApps.register,
  }[currentScreen];

  const linkView = {
    [Screens.MESSAGES]: routes.messages.details,
    [Screens.DOCUMENTS]: routes.documents.details,
    [Screens.REMOTE_APPS]: routes.remoteApps.details,
  }[currentScreen];

  const linkEdit = {
    [Screens.EXTERNAL_APPS]: routes.externalApps.details,
  }[currentScreen];

  const keysIntl = {
    entity: `${keysIntlEntity}.entity`,
    title: `${keysIntlEntity}.title`,
    description: `${keysIntlEntity}.description`,
    start_date: `${keysIntlEntity}.start_date`,
    end_date: `${keysIntlEntity}.end_date`,
    toaster: `${keysIntlEntity}.toaster_success`,
    column_date: `${keysIntlEntity}.column.date`,
    column_message: `${keysIntlEntity}.column.message`,
    new: `${keysIntlEntity}.new`,
  };

  const allFilters = {
    startAt: filterMessage?.startAt,
    endAt: filterMessage?.endAt,
    search: filterMessage?.search,
  };

  const intl = useIntl();

  useEffect(() => {
    dispatch(uiShowTable(Types.LIST));
    dispatch(listEntity(inFirstPage(metadata), allFilters));
    return () => {
      dispatch(uiRemoveMetadata(Types.LIST));
      dispatch(uiRemoveTable(Types.LIST));
    };
  }, [allFilters.startAt, allFilters.endAt, allFilters.search]);

  const handleFilterChange =
    (field: keyof MessageTypeFilter) => (value: Date | string) => {
      const queryObj = queryString.parse(search);

      history.replace({
        search: queryString.stringify({
          ...queryObj,
          [field]: isDate(value) ? value?.toISOString() : value,
        }),
      });
    };

  const handleMetadata = (newMetadata: Partial<ListMetadata>) => {
    dispatch(listEntity({ ...metadata, ...newMetadata }, allFilters));
  };

  const allColumns = listColumnByScreen(intl, keysIntl);

  const columns = useSorting(
    allColumns[currentScreen] || allColumns.default,
    metadata
  );

  const data = list.map((message): Body => {
    const allCells = listRowsByScreen(message, handleFormatDateByUserLogged);

    return {
      cells: [
        ...(allCells[currentScreen] || allCells.default),
        {
          field: 'actions',
          value: '',
          transform: () => {
            return (
              <TableActions
                entityIntlLabel={<FormattedMessage id={keysIntl.entity} />}
                linkEdit={
                  linkEdit &&
                  routeWithParameters(linkEdit, {
                    id: message?.id,
                  })
                }
                linkView={
                  linkView &&
                  routeWithParameters(linkView, {
                    id: message?.id,
                  })
                }
              />
            );
          },
        },
      ],
    };
  });

  const handleExportAllToExcel = () => {
    dispatch(
      generateExcel({
        type: Types.LIST,
        metadata: metadata,
        filters: allFilters,
      })
    );
  };

  return (
    <>
      <Box>
        <PageTitle
          showManualIcon
          title={<FormattedMessage id={keysIntl.title} />}
          description={<FormattedMessage id={keysIntl.description} />}
        />
        <PageShowToaster
          toasterKeys={[Types.CREATE, Types.UPDATE]}
          messageSuccess={intl.formatMessage({
            id: keysIntl.toaster,
          })}
          disabledError
        />
        <FormContainer>
          <PageFilter>
            <FormControl
              w="176px"
              mr="24px"
              textLabel={<FormattedMessage id={keysIntl.start_date} />}
            >
              <DatePicker
                maxDate={filterMessage.endAt || TODAY}
                hasValue={filterMessage.startAt}
                selected={filterMessage.startAt}
                onChange={handleFilterChange('startAt')}
              />
            </FormControl>
            <FormControl
              w="176px"
              textLabel={<FormattedMessage id={keysIntl.end_date} />}
            >
              <DatePicker
                minDate={filterMessage.startAt}
                maxDate={TODAY}
                hasValue={filterMessage.endAt}
                selected={filterMessage.endAt}
                onChange={handleFilterChange('endAt')}
              />
            </FormControl>
          </PageFilter>
        </FormContainer>
      </Box>
      {showTable ? (
        <Table
          headerColumns={columns}
          rows={data}
          handleSort={handleMetadata}
          keyProp={Types.LIST}
          metadata={metadata}
          pageActionsProps={{
            initialSearch: filterMessage?.search || '',
            onSearch: handleFilterChange('search'),
            handleExportAllToExcel,
            labelButtonNew: <FormattedMessage id={keysIntl.new} />,
            linkNew: linkNew,
          }}
          nameTable={intl.formatMessage({
            id: `${keysIntl.title}`,
          })}
        />
      ) : (
        <PageActions
          labelButtonNew={<FormattedMessage id={keysIntl.new} />}
          linkNew={linkNew}
          disabledAllExportsActions={!showTable}
        />
      )}
    </>
  );
};

export default MessagesList;
