import { i18n } from 'portal-lib';

const i18nConfig = {
  locale: 'es',
  messages: {
    ...i18n?.['es'],
    // errors
    'errors.generic_error': 'Error al realizar la solicitud',
    // success
    'success.generic_success': 'Solicitud realizada con éxito',
    // global buttons and text
    'global.add': 'Agregar',
    'global.cancel': 'Cancelar',
    'global.close': 'Cerrar',
    'global.confirm': 'Confirmar',
    'global.try_again': 'Intentar nuevamente',
    'global.remove': 'Eliminar',
    'global.update': 'Actualizar',
    'global.copy': 'Copiar',
    'global.excel': 'Excel',
    'global.history': 'Historial',
    'global.send': 'Enviar',
    'global.remove_record': 'Eliminar registro',
    'global.register': 'Registrar',
    'global.register_new': 'Registrar nuevo',
    'global.register_rule': 'Registrar regla',
    'global.rule': 'Regla',
    'global.load_more': 'Cargar más',
    'global.showing_x_of': 'Página {rowInit} - {rowEnd} de {totalRowa}',
    'global.filter': 'Filtrar',
    'global.radiogroup_all': 'Todo',
    'global.radiogroup_yes': 'Sí',
    'global.radiogroup_no': 'No',
    'global.general': 'General',
    'global.groups': 'Grupos',
    'global.gps': 'GPS',
    'global.subgroups': 'Subgrupos',
    'global.users': 'Usuarios',
    'global.select_group': 'Seleccione un grupo',
    'global.select_subgroup': 'Seleccione un subgrupo',
    'global.select_user': 'Seleccione un usuario',
    'global.edit_config': 'Editar configuración',
    'global.remove_config': 'Eliminar configuración',
    'global.message': 'Mensaje',
    'global.search': 'Buscar',
    'global.back': 'Volver',
    'global.no_data': 'Sin datos',
    // global form and table text
    'global.date': 'Fecha',
    'global.start_date': 'Fecha de inicio',
    'global.last_date': 'Fecha final',
    'global.company_name': 'Empresa',
    'global.identification': 'Número de Identificacíon',
    'global.password': 'Contraseña',
    'global.email': 'E-mail',
    'global.name': 'Nombre',
    'global.contact': 'Contacto',
    'global.phone': 'Teléfono',
    'global.zip_code': 'Código postal',
    'global.address_line': 'Dirección',
    'global.city': 'Ciudad',
    'global.state': 'Estado',
    'global.user': 'Usuario',
    'global.adress': 'Dirección',
    'global.group': 'Grupo',
    'global.new_group_name': 'Nuevo nombre de grupo',
    'global.consumption': 'Consumo',
    'global.roaming': 'Itinerancia',
    'global.provider': 'Operador',
    'global.company': 'Empresa',
    'global.data': 'Datos',
    'global.sms': 'SMS',
    'global.source_number': 'Número de origen',
    'global.target_number': 'Número de destino',
    'global.plan': 'Plan',
    'global.last_update': 'Última actualización',
    'global.Enrolled_device': 'Dispositivo registrado',
    'global.group_name': 'Nombre del grupo',
    'global.group_users_qtd': 'Número de usuarios en el grupo',
    'global.subgroup_name': 'Nombre del subgrupo',
    'global.subgroup_users_qtd': 'Número de usuarios en el subgrupo',
    'global.recipient': 'Receptor',
    'global.action.edit': 'Editar',
    'global.action.remove': 'Eliminar {entity}',
    'global.action.view': 'Ver {entity}',
    'global.newUsers': 'Usuarios nuevos',
    'global.totalUsers': 'Total de Usuarios',
    'global.conected': 'Conectado',
    'global.disconnected': 'Desconectado',

    'global.all': 'Todo',
    'global.yes': 'Sí',
    'global.no': 'No',
    'global.logoff': 'Salir',
    // alerts
    'alert.delete.text': 'Estás seguro que <br/> quiere borrar este registro?',
    'alert.button.remove': 'Eliminar',
    'alert.button.cancel': 'Cancelar',
    // login
    'login.welcome_1': 'Bienvenido.',
    'login.welcome_2': 'Ingrese al sistema.',
    'login.email': 'Email',
    'login.email_placeholder': 'Escriba su correo electrónico',
    'login.password': 'Contrasenña',
    'login.password_placeholder': 'Escriba su contraseña',
    'login.forgot_password': 'Olvido la contraseña?',
    'login.btn_submit': 'Iniciar sesión',
    'login.keep_connected': 'Quedarse conectado',
    'login.terms_and_conditions': 'Términos y Condiciones',
    'login.privacy_warning': 'Aviso de Privacidad',
    'login.modal.invalid': 'Correo electrónico o contraseña no válidos!',
    'login.modal.verify_login': 'Verifique el correo electrónico o la contraseña introducidos.',
    'login.modal.user_last_try': ' Solo tiene un intento más antes de que se bloquee su acceso. Revise la contraseña antes de hacer otro intento.',
    'login.modal.title.invalid_user_password': 'E-mail o contraseña no válidos!',
    'login.modal.invalid_user_password': 'Compruebe el correo electrónico o la contraseña introducidos.',
    'login.modal.unblock_user': 'Enviar instrucciones de desbloqueo',
    // Recovery password
    'password_recovery.welcome_1': 'Recuperar',
    'password_recovery.welcome_2': 'contraseña',
    'password_recovery.continue': 'Continuar',
    'password_recovery.modal.check_email': 'Verifique su correo electrónico!',
    'password_recovery.modal.email_sent': 'Hemos enviado instrucciones de desbloqueo a su correo electrónico.',
    'password_recovery.modal.support_text': 'Si el correo electrónico anterior no es correcto, comuníquese con soporte.',
    'password_recovery.modal.support_link': 'Solicitar ayuda del soporte técnico',
    // Change password
    'password_chance.welcome_1': 'Actualizar',
    'password_chance.welcome_2': 'contraseña',
    'password_chance.continue': 'Continuar',
    'password_chance.password': ' Contraseña',
    'password_chance.error': 'Token no válido o expirado',
    'password_chance.success': 'Tu contraseña de acceso al portal ha sido cambiada!',

    // Unblock user
    'unblock.welcome_1': 'Enviar instrucciones',
    'unblock.welcome_2': 'de desbloqueo',

    // company info
    'company_info.title': 'Información de la Empresa',
    'company_info.description': 'En esta pantalla, puede actualizar la información de registro de su empresa.',
    'company_info.toaster.edit_success': 'La información de la empresa ha sido actualizada con éxito!',
    'company_info.field.dataProtectionOfficerName': 'Nombre',
    'company_info.field.dataProtectionOfficerEmail': 'E-mail',
    'company_info.field.dataProtectionOfficerPhone': 'Teléfono',
    'company_info.field.euRepresentativeName': 'Nombre',
    'company_info.field.euRepresentativeEmail': 'E-mail',
    'company_info.field.euRepresentativePhone': 'Teléfono',
    'company_info.dataProtectionOfficerName': 'Protección de Datos - Nombre',
    'company_info.dataProtectionOfficerEmail': 'Protección de datos - E-mail',
    'company_info.dataProtectionOfficerPhone': 'Protección de datos - Teléfono',
    'company_info.euRepresentativeName': 'Representante en la UE - Nombre',
    'company_info.euRepresentativeEmail': 'Representante en la UE - E-mail',
    'company_info.euRepresentativePhone': 'Representante en la UE - Teléfono',
    'company_info.name': 'Empresa',
    'company_info.federalCode': 'Número de identificación',
    'company_info.phoneNumber': 'Teléfono',
    'company_info.zipCode': 'Código postal',
    'company_info.address': 'Dirección',
    'company_info.email': 'E-mail',
    'company_info.contact': 'Contacto',
    'company_info.city': 'Ciudad',
    'company_info.state': 'Estado',
    'company_info.delete_company': 'Eliminar empresa',
    'company_info.delete_alert': 'Al eliminar la empresa, todos los dispositivos registrados se eliminarán mediante el comando "Wipe" y se eliminarán todas las políticas y la información.',
    // company consumption
    'company_consumption.title': 'Consumos',
    'company_consumption.description': 'En esta pantalla es posible gestionar el consumo de datos y SMS de los dispositivos de la empresa.',
    'company_consumption.tabs_data': 'Datos',
    'company_consumption.tabs_sms': 'SMS',
    'company_consumption.company': 'Empresa',
    'company_consumption.package_label': 'Nombre del paquete de aplicación',
    'company_consumption.date_error': 'Ingrese una fecha válida',
    'company_consumption.start_date': 'Fecha de inicio',
    'company_consumption.end_date': 'Fecha final',
    'company_consumption.filter.group': 'Grupo',
    'company_consumption.filter.subgroup': 'Subgrupo',
    'company_consumption.filter.device_user': 'Usuario',
    // company license
    'company_license.title': 'Licencias Contratadas',
    'company_license.description': 'En esta pantalla, puede ver información sobre todas las licencias adquiridas.',
    // admin user
    'manage_admin.title': 'Gestionar Usuarios Administradores',
    'manage_admin.description': 'En esta pantalla es posible gestionar el registro de los usuarios administradores del Portal.',
    'manage_admin.filter.title': 'Privilegio',
    'manage_admin.filter.company': 'Responsables de la empresa',
    'manage_admin.filter.group_manager': 'Responsables del grupo',
    'manage_admin.filter.subgroup_manager': 'Responsables del subgrupo',
    'manage_admin.filter.title_group_manager': 'Grupo administrado',
    'manage_admin.filter.title_subgroup_manager': 'Subgrupo administrado',
    'manage_admin.filter.title_company': 'Empresa administrada',
    'manage_admin.toaster.success': 'Usuario creado con éxito, se ha enviado un correo electrónico al usuario con las instrucciones de acceso.',
    // edit admin user
    'edit_admin.user': 'Editar usuario administrador',
    'edit_admin.group': 'Administración de grupo',
    'edit_admin.subgroup': 'Gestión de subgrupos',
    'edit_admin.group_description': 'Seleccione grupos adicionales que pueda administrar',
    'edit_admin.subgroup_description': 'Seleccione subgrupos adicionales que pueda administrar',
    'edit_admin.register': 'Registrar Usuario Administrador',
    'edit_admin.description_new': 'En esta pantalla es posible administrar la información de registro, privilegios, grupos y permisos de este usuario. También es posible cambiar la contraseña de acceso al portal que gestiona el usuario.',
    'edit_admin.privilege_company': 'Responsable de la compañía',
    'edit_admin.privilege_group': 'Responsable del grupo',
    'edit_admin.privilege_subgroup': 'Responsable del subgrupo',
    'edit_admin.name': 'Nombre',
    'edit_admin.federalCode': 'Numero de Identificación',
    'edit_admin.email': 'E-mail',
    'edit_admin.company': 'Empresa',
    'edit_admin.timezone': 'Huso horario',
    'edit_admin.language': 'Idioma',
    'edit_admin.password': 'Nueva contraseña',
    'edit_admin.permissions.subtitle': 'Permiso',
    'edit_admin.groupIds': 'Administración de grupo',
    // manage groups
    'manage_groups.entity': 'Grupo',
    'manage_groups.title': 'Administrar Grupos',
    'manage_groups.description': 'En esta pantalla, es posible administrar el registro de grupos de dispositivos.',
    'manage_groups.actions_edit': 'Editar grupo',
    'manage_groups.actions_remove': 'Quitar grupo',
    'manage_groups.modal_title': 'Realmente quieres eliminar este grupo?',
    'manage_groups.modal_subtitle': 'Esta acción no se puede deshacer.',
    'manage_groups.success.create': 'Grupo creado con éxito!',
    'manage_groups.success.update': 'Grupo cambiado con éxito!',
    'manage_groups.success.delete': 'Grupo eliminado con éxito!',
    // edit group
    'edit_group.title_new': 'Registrar Nuevo Grupo',
    'edit_group.title_edit': 'Editar grupo',
    'edit_group.description_new': 'En esta pantalla es posible crear un nuevo grupo de dispositivos.',
    'edit_group.description_edit': 'En esta pantalla es posible gestionar la información y la conexión de los dispositivos de este grupo.',
    'edit_group.name': 'Nombre del grupo',
    'edit_group.attach_user': 'Vincular usuario',
    'edit_group.attach': 'Vincular  ',
    'edit_group.new_attached_users': 'Nuevos usuarios para vincular',
    'edit_group.attached_users': 'Usuarios vinculados',
    'edit_group.no_attached_user': 'No hay usuarios vinculados a este grupo',
    'edit_group.attach_user_label': 'Seleccionar usuario',
    // manage subgroups
    'manage_subgroups.title': 'Administrar subgrupos',
    'manage_subgroups.entity': 'Subgrupo',
    'manage_subgroups.actions_edit': 'Editar subgrupo',
    'manage_subgroups.actions_remove': 'Eliminar subgrupo',
    'manage_subgroups.modal_title': 'Realmente desea eliminar este subgrupo?',
    'manage_subgroups.modal_subtitle': 'Esta acción no se puede deshacer.',
    'manage_subgroups.success': 'Subgrupo cambiado con éxito!',
    // edit subgroup
    'edit_subgroup.title': 'Editar subgrupo',
    'menu.app_action': 'Acciones Android',
    'edit_subgroup.attach_user': 'Vincular usuario',
    'edit_subgroup.attach': 'Vincular  ',
    'edit_subgroup.attached_users': 'Usuarios vinculados al subgrupo',
    'edit_subgroup.no_attached_user': 'No hay usuarios vinculados a este subgrupo',
    'edit_subgroup.attach_user_label': 'Seleccione usuario',
    // register group
    'register_subgroup.title': 'Registrar nuevo subgrupo',
    'menu.users.admin_users': 'Administradores',
    'register_subgroup.success': 'Subgrupo registrado con éxito!',
    // Consumption Profile
    'consumption_profile.title': 'Perfil de Consumo',
    'consumption_profile.description': 'En esta pantalla es posible gestionar el perfil de consumo de los dispositivos.',
    'consumption_profile.success': 'Perfil de consumo actualizado con éxito!',
    'menu.help': 'Ayuda',
    'consumption_profile.radio_button_defined': 'Definido en el nivel anterior',
    'consumption_profile.radio_button_ilimited': 'Ilimitado',
    'consumption_profile.radio_button_assigned': 'Consumo Asignado',
    'consumption_profile.general.data': 'Datos',
    'consumption_profile.general.quantity': 'Cantidad',
    'consumption_profile.general.bytes': 'Bytes',
    'consumption_profile.group.register_success': 'Regla de perfil de consumo registrada con éxito!',
    'menu.device.application': 'Información de Aplicaciones',
    'consumption_profile.group.modal_title': 'Realmente desea eliminar esta configuración?',
    'consumption_profile.group.title': '* La configuración de los Perfiles de Consumo no refleja la naturaleza del servicio contratado con el operador. La información registrada en este formulario refleja únicamente las políticas de uso de su organización.',
    'menu.app_action.application_control.manage': 'Aplicaciones',
    'consumption_profile.group.subtitle': 'Esta acción no se puede deshacer.',
    'consumption_profile.group_register.title': 'Registrar regla de perfil de consumo',
    'consumption_profile.group_edit.title': 'Editar regla',
    'consumption_profile.group_remove.title': 'Quitar regla',
    'consumption_profile.level_up': 'Definido en el nivel anterior',
    'consumption_profile.ilimited': 'Ilimitado',
    'consumption_profile.data': 'Datos',
    'consumption_profile.data_roaming': 'Roaming de datos',
    'consumption_profile.sms': 'SMS',
    'consumption_profile.sms_roaming': 'SMS Roaming',

    // dashboard
    'dashboard.good_morning': 'Buen día',
    'dashboard.good_afternoon': 'Buenas tardes',
    'dashboard.good_evening': 'Buenas noches',
    'dashboard.roaming': 'Roaming Internacional',
    'dashboard.description': 'Consulte el resumen de la aplicación.',
    'dashboard.usage_time_by_application.title': 'Tiempo de uso por aplicaciones',
    'dashboard.usage_time_by_application.empty_chart': 'Datos no encontrados',
    'dashboard.most_visited_websites.title': 'Sitios mas visitados',
    'dashboard.most_visited_websites.empty_chart': 'No se registran visitas.',
    'dashboard.devices_inventory': 'Inventario de dispositivos',
    'dashboard.devices_location': 'Ubicación de Dispositivos',
    'dashboard.devices_inventory.empty_list': 'Ningún dispositivo encontrado',
    'dashboard.users_total_heading': 'Total de usuarios',
    'dashboard.uninstalled_applications': 'Aplicaciones Desinstaladas',
    'dashboard.uninstall_attempts': 'Intentos de Desinstalación',
    'dashboard.not_activated_licenses': 'Licencias sin uso',
    'dashboard.device_card.title': 'Dispositivos',
    'dashboard.device_card.status.active': 'Activos',
    'dashboard.device_card.status.total': 'Total',
    'dashboard.device_card.status.without_communication': 'Sin comunicacion',
    'dashboard.device_card.status.no_compilation': 'No conforme',
    'dashboard.device_card.status.mode_owner': 'En Device Owner ',
    'dashboard.device_card.status.mode_kiosk': 'En Modo Quiosco',
    'dashboard.device_card.status.outdated_app': 'Aplicación Desactualizada',
    'menu.settings.audit': 'Reporte de Movimientos',
    'dashboard.used': 'Usado',
    'dashboard.data_consumption_chart.app': 'Consumo de datos por aplicaciones',
    'dashboard.data_consumption_chart.user': 'Consumo de datos por usuario',
    'dashboard.total': 'Total',
    'dashboard.unlimited': 'Ilimitado',
    'dashboard.data_consumption': 'Consumo de datos',
    'dashboard.sms_consumption': 'Consumo de SMS',
    'dashboard.data_consumption.label.link': 'Ver lista completa',
    'menu.app_action.application_control': 'Bloqueo / Desbloqueo',

    // Android Block Application
    'block_application.title': 'Bloquear / Desbloquear Aplicaciones',
    'block_application.title_text': 'Consultar Reglas ya creadas para bloquear o liberar aplicaciones. Las acciones generadas en este formulario se refieren al acceso a internet. Las aplicaciones se pueden abrir normalmente. La excepción se produce en el modo de propietario del dispositivo, donde los iconos de las aplicaciones permanecerán ocultos.',
    'block_application.general': 'General',
    'block_application.groups': 'Grupos',
    'block_application.subgroups': 'Subgrupos',
    'block_application.users': 'Usuarios',
    // Android Block Application
    'block_application.general.edit.title': 'Editar regla de la aplicación',
    'block_application.general.register.title': 'Registrar regla de la aplicación',
    'block_application.general.register.title_text': 'En esta pantalla, se puede configurar una regla para bloquear o desbloquear sitios por URL o palabra clave en los dispositivos.',
    'block_application.success': 'Bloquear/desbloquear aplicaciones',
    'block_application.apply_to': 'Aplicar la regla sobre',
    'block_application.apply_rule': 'Aplicar reglas',
    'block_application.apply': 'Aplicar',
    'block_application.applied': 'Aplicado',
    'block_application.not_applied': 'No aplicado',
    'block_application.lock': 'Bloqueado',
    'block_application.unlock': 'Desbloqueado',
    'block_application.default_label': 'Estándar',
    'block_application.geofence_label': 'Geofence',
    'block_application.geofence.not_found': 'No se agregó ninguna geofence a esta regla',
    'block_application.time_label': 'Horarios',
    'block_application.add_rule': 'Registrar regla  ',
    'block_application.edit_config': 'Editar configuración',
    'block_application.modal.apply_config': 'Realmente desea aplicar todas las reglas de aplicación?',
    'menu.app_action.geofences.register': 'Registrar nueva geofence',
    'block_application.remove_config': 'Eliminar configuración',
    'block_application.modal.remove_config': 'Realmente desea eliminar esta regla de las aplicaciones?',
    'menu.groups_and_subgroups.groups.register': 'Registrar Nuevo Grupo',
    'block_application.alert.irreversible': 'Esta acción no se puede deshacer.',
    'block_application.url': 'URL/Palabra',
    'menu.groups_and_subgroups.subgroups.register': 'Registrar nuevo subgrupo',
    'block_application.config_rule': 'Configurar regla',
    'menu.users.admin_users.register': 'Registrar  Nuevo Usuario Administrador',
    'block_application.select_action': 'Seleccione la acción',
    'block_application.modal.confirm_rule': 'Regla registrada con éxito!',
    'block_application.modal.new_rule_question': 'Quiere registrar una nueva regla para <b>la misma empresa</b>?',
    'block_application.modal.new_rule': 'Crear nueva regla',
    'block_application.geofence.geofence': 'Geofence',
    'block_application.geofence.select': 'Seleccione una geofence',
    'block_application.schedule.time': 'Horarios',
    'block_application.schedule.select_time': 'Selecciona las veces que quieres realizar la acción.',
    'block_application.schedule.monday': 'Lun',
    'block_application.schedule.tuesday': 'Mar',
    'block_application.schedule.wednesday': 'Mie',
    'block_application.schedule.thursday': 'Jue',
    'block_application.schedule.friday': 'Vie',
    'block_application.schedule.saturday': 'Sab',
    'block_application.schedule.sunday': 'Dom',
    'block_application.packageName': 'Nombre del paquete',
    'block_application.application.placeholder': 'Seleccione la aplicación',
    // Sites
    'sites.block.radio_button.defined': 'Definido en el nivel anterior',
    'sites.block.radio_button.disabled': 'Inactivo',
    'sites.block.radio_button.active': 'Activo',
    'sites.kiosk.checkbox.block': 'Bloqueado por regla',
    'sites.kiosk.checkbox.tipe_rule': 'Revisar la regla de',
    'menu.documents': 'Compartir documento',
    'menu.app_action.settings': 'Configuraciones',
    'menu.settings': 'Configuraciones',
    'menu.settings.general_config': 'Configuraciones Generales',
    'sites.block.general.text': 'En esta pantalla, es posible administrar las reglas para bloquear y desbloquear sitios por URL o palabra clave en los dispositivos.',
    'sites.block.general.success.register': 'Regla creada con éxito!',
    'sites.block.general.success.edit': 'Regla actualizada con éxito!',
    'sites.block.general.success.delete': 'Regla eliminada con éxito!',
    'sites.kiosk.title': 'Habilitar el modo quiosco',
    'sites.kiosk.description': 'Transforma la pantalla de inicio del dispositivo en un ambiente de trabajo controlado, mostrando una lista de aplicaciones previamente definidas.',
    'sites.kiosk.function': 'Operación:',
    'sites.kiosk.general.permited_actions': 'Acciones permitidas en modo quiosco',
    'sites.kiosk.general.success': 'La configuración del modo quiosco se actualizó correctamente',
    'sites.kiosk.kiosk_state': 'Estado del modo quiosco',
    'sites_category.radio_button.defined': 'Definido en el nivel anterior',
    'sites_category.radio_button.active': 'Activo',
    'sites_category.general.form_label': 'Regla aplicada a la Empresa',
    'menu.company.consumption': 'Consumos',
    'sites_category.group.form_label': 'Regla aplicada al grupo',
    'sites_category.subgroup.form_label': 'Regla aplicada al subgrupo',
    'sites_category.user.form_label': 'Regla aplicada al usuario',
    'sites_category.general.block_content': 'Bloqueo de contenido (activa los contenidos que quieras bloquear)',
    'sites_category.general.notice': '* Ver líneas que están en plan básico o suspendidas',

    // Application Manage
    'application_manage.title': 'Aplicaciones',
    'application_manage.sub_title': 'En esta pantalla, puede ver el reporte de las aplicaciones del dispositivo.',
    'application_manage.group': 'Grupos',
    'application_manage.app': 'Aplicaciones',
    'application_manage.sub_group': 'Subgrupo',
    'menu.dashboard': 'Dashboard',
    'application_manage.users': 'Usuarios',
    'application_manage.table_action.storage': 'Historial de consumo',
    'application_manage.table_action.application': 'Usuarios con aplicaciones',
    'application_manage.toaster_success': 'Aplicación registrada con éxito!',
    'application_manage.toaster_sent_success': 'Solicitud enviada con éxito!',
    'application_manage.modal.send_title': 'Enviar mensajes',
    'application_manage.modal.send_text': 'Para instalar o actualizar aplicaciones, puede seleccionar por Empresa (se enviará a todos los equipos registrados), por Grupo (se enviará solo a los miembros del grupo) o por Usuario (puede elegir más de 1 usuario para enviar).',
    'application_manage.modal.destroy.title': 'Realmente desea eliminar esta aplicación?',
    'application_manage.modal.destroy.text': 'Esta acción no se puede deshacer.',

    // Application Register
    'application_register.title': 'Registrar y eliminar la aplicación para instalar',
    'application_register.title_text': 'En esta pantalla, se puede registrar una aplicación para instalarla en dispositivos de forma remota.',
    'application_register.url': 'URL del archivo:',
    'application_register.packageName': 'Nombre del paquete',
    'application_register.action.history': 'Historial',
    'application_register.title_tag': 'Habilitado solamente en el modo divice owner',
    'application_register.message_success': 'Aplicación creada con éxito!',

    // Application History
    'application_history.title': 'Historial de la aplicación',
    'application_history.title_text': 'En esta pantalla, puede ver el historial de todos los envíos realizados a esta aplicación.',
    'application_history.title_package': 'Nombre del paquete:',
    'application_history.sent_status': 'Estado del envío',
    'application_history.empresa': 'Estado del envío',
    'application_history.action.history': 'Estado del envío',
    // Application history Status
    'application_status.title': 'Detalles del envío de la aplicación',
    'application_status.sent': 'Enviado',
    'application_status.sending': 'En proceso de envío',
    'application_status.url': 'URL',
    'application_status.recipients': 'Destinatarios',
    // Application Send
    'application_send.title': 'Enviar las aplicaciones',
    'application_send.sub_title': 'Aplicaciones para Instalar/Desinstalar.',
    'application_send.send_application': 'Enviar Aplicaciones ',
    'application_send.message_success': 'Aplicación enviada con éxito!',
    'application_send.column.action': 'Acción',
    'application_send.table_title': 'Aplicaciones seleccionadas',
    'application_send.action.install': 'Instalar',
    'application_send.action.uninstall': 'Desinstalar',

    // Application device users
    'application_device_users.title': 'Usuarios con Aplicación',
    'application_device_users.description': 'En esta pantalla, se puede ver todos los dispositivos que tienen la aplicación instalada.',
    'application_device_users.filter.group': 'Grupo',
    'application_device_users.filter.subgroup': 'Subgrupo',
    'application_device_users.filter.device_user': 'Usuario',
    // deviceuser
    'manage_deviceuser.entity': 'Usuario del dispositivo',
    'manage_deviceuser.title': 'Administrar usuarios registrados',
    'manage_deviceuser.description': 'En esta pantalla es posible gestionar el registro de los usuarios de dispositivos.',
    'menu.devices': 'Dispositivos',
    'menu.device.devices': 'Lista de Dispositivos',
    'edit_deviceuser.deviceuser': 'Editar usuario',
    'edit_deviceuser.title': 'Editar usuario del dispositivo',
    'edit_deviceuser.description': 'En esta pantalla, se puede cambiar la información de usuario del dispositivo.',
    'edit_deviceuser.name': 'Nombre del usuario',
    'edit_deviceuser.federalCode': 'Número de Identificación (Opcional)',
    'edit_deviceuser.groupId': 'Grupos',
    'edit_deviceuser.subgroup': 'Subgrupos',
    'edit_deviceuser.success': 'El usuario ha sido actualizado con éxito!',
    'menu.help.documents': 'Documentos y Materiales de Apoyo',
    // GEOLOCATION
    'geolocation.title': 'Geolocalización',
    'geolocation.title_text': 'En esta pantalla se puede visualizar la geolocalización de los dispositivos.',
    'geolocation.filter': 'Filtrar',
    'geolocation.user': 'Usuario',
    'geolocation.findBy': 'Localizar',
    'geolocation.date': 'Fecha',
    'geolocation.timezone': 'Huso horario',
    'geolocation.precision': 'Precisión',
    'geolocation.search': 'Buscar',
    'geolocation.label': 'Leyenda',
    'geolocation.initial_position': 'Posición inicial de lectura GPS en el día seleccionado',
    'geolocation.current_position': 'Posición actual o última registrada del día',
    'geolocation.position_history': 'Lugar donde fue el usuario',
    'geolocation.moving': 'En movimiento',
    'geolocation.stopped': 'Interrumpido',
    'geolocation.currentUser': 'Usuario actual',
    'geolocation.device': 'Dispositivo',
    'geolocation.byData': 'Por fecha',
    'geolocation.now': 'Ahora',
    'geolocation.all': 'Todo',
    'geolocation.loading': 'Solicitar la ubicación actual del dispositivo. Espere por favor.',
    'geolocation.warning_exit': 'El sistema está esperando la ubicación actual del dispositivo solicitada! Al salir de la pantalla no recibirá la ubicación solicitada.',
    'geolocalization.realtime.not_found': 'No se encontró nada!',
    'geolocalization.realtime.time_exceeded': 'No se puede obtener la ubicación actual del dispositivo! Confirme que el dispositivo está encendido, el GPS está habilitado y está conectado a una red de Internet para enviar la ubicación.',
    'menu.company': 'Empresas',
    // LOCATION HISTORY
    'location_history.title': 'Historial de Ubicación',
    'location_history.subtitle': 'En esta pantalla, puede ver el historial de ubicaciones registradas por los dispositivos.',
    'location_history.month': 'Mes',
    'location_history.day': 'Día',
    'location_history.initial_time': 'Hora de inicio',
    'location_history.final_time': 'Hora de finalización',
    'location_history.precision': 'Precisión GPS (metros)',
    'location_history.user': 'Usuario',
    'location_history.select': 'Seleccionar',
    'location_history.select_option.all': 'Todo',
    // MESSAGE
    'message.title': 'Mensajes',
    'message.description': 'En esta pantalla es posible gestionar y enviar mensajes y comandos vía push a los dispositivos.',
    'message.title_text': 'Para enviar mensajes puedes seleccionar por Empresa (se enviará a todos los equipos registrados), por Grupo (se enviará solo a los miembros del grupo) o por Usuario (puedes elegir más de 1 usuario para enviar).',
    'message.filter': 'Filtrar',
    'message.start_date': 'Fecha inicial',
    'message.end_date': 'Fecha final',
    'message.new': 'Nuevo mensaje',
    'message.toaster_success': 'Mensaje enviado correctamente!',
    'message.entity': 'Mensaje',
    'message.filter_title': 'Enviar mensajes',
    // MESSAGE DETAILS
    'message_detail.title': 'Detalles del mensaje',
    'message_detail.title_text': 'En esta pantalla, puede ver los detalles del mensaje.',
    'message_detail.subtitle': 'Destinatarios',
    'message_detail.status.error_sending_message': 'Error al enviar mensaje',
    'message_detail.status.in_the_send_queue': 'En la cola de envío',
    'message_detail.status.sent': 'Enviado',
    'message_detail.status.received': 'Recibido',
    'message_detail.status.read': 'Leído',
    'message_detail.status.installed': 'Instalado',
    'message_detail.status.not_installed': 'No instalado',
    'message_detail.status.executed': 'Ejecutado',
    'message_detail.status.not_executed': 'No ejecutado',
    'message_detail.status.uninstalled': 'Desinstalado',
    'message_detail.status.not_uninstalled': 'No desinstalado',

    // MESSAGE TYPES
    'message_type.status.install_apk': 'Instalar / Actualizar APK',

    // MESSAGE REGISTER
    'message_register.title': 'Nuevo mensaje',
    'message_register.title_text': 'En esta pantalla es posible enviar mensajes a los dispositivos.',

    // REPOSTS
    // - GPS
    'reportsGps.title': 'GPS',
    'reportsGps.description': 'En esta pantalla es posible generar el reporte del estado del GPS de los dispositivos',
    //DEVICE LOCATION
    'device_location.title': 'Ubicación del Dispositivo',
    'device_location.sub_title': 'En esta pantalla es posible generar un reporte de la última ubicación registrada por los dispositivos',
    'device_location.start_date': 'La fecha de inicio',
    'menu.app_action.geofences': 'Geofences',
    'device_location.end_date': 'Fecha final',
    'menu.location.geolocation': 'Geolocalización',
    'device_location.toaster_success': 'Mensaje enviado correctamente!',
    // DEVICES
    'devices.title': 'Lista de Dispositivos',
    'devices.sub_title': 'En esta pantalla, puede administrar y realizar acciones en los dispositivos.',
    'devices.manufacturer': 'Fabricante',
    'devices.model': 'Modelo',
    'devices.phone': 'Teléfono',
    'devices.inventory.title': 'Distribución de dispositivos',
    'menu.app_action.geofences.manage': 'Gestionar geofences',
    'devices.action.disconnected': 'Restablezca la comunicación con este dispositivo para habilitar estas acciones.',
    'menu.groups_and_subgroups.groups.manage': 'Administrar Grupos',
    'devices.action.battery': 'Historial de la batería',
    'devices.action.storage': 'Historial de almacenamiento',
    'menu.groups_and_subgroups.subgroups.manage': 'Administrar subgrupos',
    'devices.action.remove': 'Eliminar dispositivo',
    'devices.action.change': 'Intercambio de equipo',
    'devices.action.disable': 'Deshabilitar dispositivo',
    'menu.users.admin_users.manage': 'Gestionar Usuarios Administradores',
    'devices.action.activate': 'Activar dispositivo',
    'devices.action.non_compliance': 'Reporte de No Conformidad',
    'menu.users.device_users.manage': 'Administrar usuarios de dispositivos',
    'devices.action.newpassword': 'Generar nueva contraseña de desbloqueo',
    'devices.action.passwordHistoric': 'Últimas contraseñas de desbloqueo',
    'devices.alert.irreversible': 'Esta acción no se puede deshacer.',
    'devices.alert.cancel': 'Cancelar',
    'devices.alert.remove.button': 'Eliminar',
    'devices.alert.remove.text': 'Realmente quieres eliminar este dispositivo?',
    'devices.alert.disable.button': 'Deshabilitar ',
    'devices.alert.disable.text': 'Realmente desea deshabilitar este dispositivo?',
    'devices.alert.activate.button': 'Activar',
    'devices.alert.activate.text': 'Realmente quieres desbloquear este dispositivo?',
    'devices.alert.newpassword.button': 'Generar contraseña',
    'devices.alert.newpassword.text': 'Realmente desea generar una nueva contraseña del dispositivo?',
    'devices.passwordHistoric.title': 'Últimas contraseñas del dispositivo',
    'devices.passwordHistoric.newpassword': 'Generar nueva contraseña del dispositivo',
    'devices.block.title': 'Bloquear/desbloquear Aplicaciones',
    'devices.block.subtitle': 'En esta pantalla es posible gestionar las reglas de bloqueo y desbloqueo de aplicaciones del dispositivo.',
    //  BATTERY
    'battery.title': 'Historial de la batería',
    'battery.sub_title': 'En esta pantalla se puede visualizar el gráfico de evolución de la batería en un día determinado.',
    'battery.label.filter': 'Fecha',
    'menu.groups_and_subgroups': 'Grupos',
    // STORAGE
    'menu.groups_and_subgroups.groups': 'Grupos',
    'storage.title': 'Historial de almacenamiento',
    'storage.sub_title': 'En esta pantalla se puede visualizar el gráfico de evolución del almacenamiento interno en un día determinado.',
    'storage.label.filter': 'Fecha',
    // QR CODE to Device Owner
    'qrcode.title': 'Generar código QR para el propietario del dispositivo',
    'menu.app_action.settings.kioskMode': 'Habilitar modo Quiosco',
    'qrcode.description': 'En esta pantalla, es posible generar Código QR para instalar el modo Device Owner en los dispositivos.',
    'qrcode.url': 'Dirección (URL) para descargar la aplicación',
    'qrcode.packageName': 'Nombre del paquete de la aplicación',
    'qrcode.language': 'Idioma',
    'qrcode.timezone': 'Huso horario',
    'qrcode.ssid': 'Nombre de la red WiFi (SSID) que se conectará',
    'qrcode.ssidPassword': 'Contraseña de la red Wifi que se conectará',
    'qrcode.enableAllApps': 'Habilitar todas las aplicaciones del sistema',
    'qrcode.button': 'Para generar',
    'qrcode.wifi_network_name_label': 'Definir el nombre de la red WiFi (SSID)',
    'qrcode.wifi_network_password_label': 'Definir tu contraseña WiFi',
    'menu.location.location_history': 'Historial de Ubicación',
    'qrcode.failure_generate': 'Sin datos',
    'qrcode.show.title': 'Código QR para el modo de propietario del dispositivo',
    'qrcode.show.description': 'Esta pantalla muestra el código QR para realizar la instalación del modo Device Owner. Después de realizar una restauración de fábrica de sus dispositivos, escanee este código para iniciar el proceso de instalación.',
    'qrcode.show.url': 'Dirección (URL) para descargar la aplicación',
    'qrcode.show.packageName': 'Nombre del paquete de la aplicación',
    'qrcode.show.language': 'Idioma',
    'qrcode.show.timezone': 'Huso horario',
    'qrcode.show.ssid': 'Nombre de la red Wi-Fi (SSID)',
    'qrcode.show.ssidPassword': 'Contraseña de la red WiFi',
    'qrcode.show.enabledApplication': 'Habilitar todas las aplicaciones del sistema',
    // GENERAL CONFIGURATION
    'general_config.title': 'Configuraciones Generales',
    'general_config.description': 'En esta pantalla es posible gestionar la configuración general.',
    'general_config.toaster': 'La configuración se ha actualizado correctamente!',
    'general_config.form.startCycleDay': 'Inicio del ciclo',
    'general_config.form.syncTime': 'Sincronizar cada',
    'general_config.form.work_config': 'Configuraciones de trabajo',
    'general_config.form.startDayOfWeek': 'Dias de la semana',
    'general_config.form.optional': '(opcional)',
    'general_config.form.endDayOfWeek': 'hasta',
    'general_config.form.work_hour': 'Horario de trabajo',
    'menu.company.info': 'Información de la Empresa',
    'general_config.form.lock_outside_work_hours': 'Bloque completo fuera del horario laboral',
    'general_config.form.gps_config': 'Configuración de GPS',
    'general_config.form.gps_enabled': 'Habilitar el monitoreo',
    'general_config.form.gps_hour': 'Horario de monitoreo GPS',
    'general_config.form.gps_precision': 'Precisión GPS (metros)',
    'general_config.form.locate_every': 'Localizar cada',
    'general_config.form.wifi_config': 'Configuración de Wi-Fi',
    'general_config.form.block_apps': 'Bloqueo de aplicaciones',
    'general_config.form.block_sites': 'Bloqueo de sitios',
    'general_config.form.get_usage_time': 'Obtener tiempo de uso',
    'general_config.form.distinct_configs': 'Otras Configuraciones',
    'menu.app_action.settings.manage_application_config': 'Instalar / Actualizar aplicación',
    'general_config.form.block_url': 'Bloqueo completo de URL',
    'general_config.form.total_block_apps': 'Bloqueo completo de aplicaciones',
    'general_config.form.hotspot': 'Permitir enrutamiento Wi-Fi (Hotspot)',
    'general_config.form.warning_email': 'Correo electrónico de aviso',
    'menu.company.license': 'Licencias Contratadas',
    'general_config.form.device_owner_allowed': 'Configuración permitida en Device Owner',
    'general_config.form.device_owner_allowed_description': 'Esta configuración solo se aplicará si el dispositivo está en modo Device Owner',
    'menu.location': 'Localización',
    'general_config.form.allow_safe_start': 'Permitir el arranque en modo seguro',
    'general_config.form.allow_add_user': 'Permitir agregar usuario',
    'general_config.form.week_start': 'Inicio de la semana',
    'general_config.form.week_end': 'Fin de la semana',
    'menu.location.device_location': 'Ubicación del Dispositivo',
    'general_config.form.hour_start': 'Hora de inicio',
    'general_config.form.hour_end': 'Hora de finalización',
    'general_config.form.total_block': 'Bloqueo completo fuera del horario',
    'general_config.form.gps_hour_start': 'GPS inicio',
    'general_config.form.gps_hour_end': 'GPS final',
    'general_config.form.sd_card_install': 'Permitir la instalación de una tarjeta SD externa',
    'general_config.form.cycle_start.tooltip': 'Cambiar la fecha de inicio del ciclo durante el ciclo puede generar inconsistencias en los datos, las cuales solo serán corregidas al iniciar un nuevo ciclo.',
    'general_config.form.week_days.tooltip': 'Definir los días de trabajo de la empresa. El sistema considera el lunes como el primer día de la semana.',
    'general_config.form.lock_outside_work_hours.tooltip': 'Habilita el bloqueo completo de aplicaciones y navegación de sitios web en datos móviles y Wi-Fi cuando el dispositivo está fuera del horario laboral configurado.',
    'general_config.form.gps_enabled.tooltip': 'Permite habilitar el monitoreo del dispositivo a través de GPS de acuerdo con el tiempo configurado.',
    'general_config.form.wifi_config.tooltip': 'Al activar una de las configuraciones en Wi-Fi, la regla se aplicará también cuando el dispositivo esté conectado a una red Wi-Fi.',
    'general_config.form.block_url.tooltip': 'Habilita el bloqueo total de la navegación por sitios web en datos móviles y Wi-Fi.',
    'general_config.form.total_block_apps.tooltip': 'Habilita el bloqueo total de aplicaciones de dispositivos, datos móviles y Wi-Fi.',
    'general_config.form.hotspot.tooltip': 'Permite habilitar el uso compartido de la red de datos móviles de su dispositivo con otros dispositivos.',
    'general_config.form.warning_email.tooltip': 'Habilita el envío de notificación por correo electrónico al administrador cuando el dispositivo alcanza el 80%, 90% y 100% del perfil de consumo configurado.',
    'general_config.form.allow_safe_start.tooltip': 'Permite iniciar el dispositivo en modo seguro.',
    'general_config.form.allow_add_user.tooltip': 'Permite agregar un nuevo usuario en el sistema operativo del dispositivo.',
    'general_config.form.sd_card_install.tooltip': 'Permite instalar una tarjeta de memoria (SD Card externa).',
    'general_config.register.title': 'Registrar configuración',
    'general_config.edit.title': 'Editar configuración',
    'general_config.edit.description': 'En esta pantalla, se puede administrar la configuración general y aplicarla a los dispositivos.',
    'general_config.group.toaster_create': 'La configuración del grupo se registró correctamente!',
    'general_config.group.toaster_edit': 'La configuración del grupo se ha actualizado correctamente!',
    'menu.messages': 'Mensajes',
    'general_config.subgroup.toaster_create': 'La configuración del subgrupo se registró correctamente!',
    'general_config.subgroup.toaster_edit': 'La configuración de los subgrupos se ha actualizado correctamente!',
    'general_config.user.toaster_create': 'La configuración del usuario se registró correctamente!',
    'general_config.user.toaster_edit': 'La configuración del usuario se ha actualizado con éxito!',
    'general_config.edit.group.track_gps': 'Monitorear GPS',
    'general_config.edit.group.title': 'Editar configuración del grupo',
    'general_config.register.group.title': 'Registrar configuración del grupo',
    'general_config.edit.subgroup.title': 'Editar configuración del subgrupo',
    'general_config.register.subgroup.title': 'Registrar configuración del subgrupo',
    'general_config.edit.user.title': 'Editar configuración del usuario',
    'general_config.register.user.title': 'Registrar configuración del usuario',
    // DOCUMENT
    'document.entity': 'Documento',
    'document.title': 'Compartir Documentos',
    'document.description': 'En esta pantalla es posible gestionar y compartir documentos vía push a los dispositivos',
    'document.start_date': 'Fecha Inicial',
    'document.end_date': 'Fecha Final',
    'document.new': 'Compartir nuevo',
    'document.view': 'Documento compartido con éxito!',
    'document.toaster_success': 'Link del documento',
    'document.content': 'Documento',
    'document.send_label': 'Enviar',
    'document.filter_title': 'Enviar documentos',
    // DOCUMENT DETAILS
    'document_details.title': 'Detalles de la compartición',
    'document_details.description': 'En esta pantalla, se puede ver los detalles del documento compartido.',
    'document_details.doc': 'Documento',
    'document_details.subtitle': 'Destinatarios',
    // DATE REPORT
    'date_report.title': 'Reporte de Sitios por Fecha',
    'date_report.title_text': 'En esta pantalla, se puede ver una línea de tiempo de los sitios a los que se accede en los dispositivos.',
    'date_report.select_user': 'Seleccione un usuario',
    // DATE REPORT
    'sites_url.title': 'Reporte de  Sitios por URL o Palabra Clave',
    'sites_url.description': 'En esta pantalla, es posible generar un reporte de los sitios accedidos en los dispositivos por una URL o palabra clave.',
    'sites_url.field_search': 'URL o palabra clave',
    'sites_url.field_user': 'Usuario',
    'sites_url.placeholder_search': 'Ingrese URL o palabra clave',

    // Validations
    'form.field_default': 'Error de validación',
    'form.field_required': 'Completar el campo',
    'form.field_email': 'Ingrese un correo electrónico válido',
    'form.field_alpha_num_dash_space': 'El campo debe tener letras, números, espacios o guiones',
    'form.integer': 'Debe ser un entero',
    // Permissions / Roles
    'role.viewUsersGps': 'Ver el GPS de los usuarios',
    'role.editUserRole': 'Editar rol de usuario',
    'role.editConfig': 'Editar configuración',
    'role.editLimits': 'Editar límites',
    'role.editActions': 'Editar acciones',
    // Map
    'map.state': 'Estado',
    'map.precision': 'Precisión GPS',
    'map.battery': 'Batería',
    'map.selected.infos.phone': 'Teléfono',
    'map.selected.infos.date_time': 'Fecha y hora',
    'map.empty': 'Ningún dispositivo encontrado',

    // Card Help
    'faq.card_one.manuals.title': 'Manuales para descargar',
    'faq.card_one.manuals.description': 'Consulte nuestros documentos y materiales de apoyo.',
    'faq.card_tow.tutorial_video_title': 'Vídeos tutoriales',
    'faq.card_tow.tutorial_video_description': 'Consulte nuestros videos explicativos',
    'faq.card_three.suport.title': 'Aún tienes preguntas?',
    'faq.card_three.suport.description': 'Nuestro equipo de soporte puede ayudarlo.',
    'faq.card_three.suport.button.link': 'Solicitud de soporte',
    'card_help.faq.title': 'Alguna duda?',
    'card_help.faq.description': 'Acceda a nuestra página de preguntas frecuentes y busque por su duda.',
    'card_help.faq.button.link': 'Preguntas Frecuentes',

    // Guides
    'guides.title': 'Documentos y Materiales de Apoyo',
    'guides.description': 'En esta pantalla, se puede descargar documentos y materiales de apoyo para usar la solución {portalName}',
    // FAQ
    'faq.all': 'Todo',
    'faq.title': 'Preguntas Frecuentes',
    'faq.description': 'En esta pantalla, se puede visualizar las preguntas más frecuentes realizadas por los usuarios.',
    'faq.input.placeholder': 'Como podemos ayudarte?',
    'faq.input.instruction': 'Filtre su pregunta en las categorías abajo, o busque en el campo de búsqueda arriba.',
    'faq.list.empty': 'Ninguna pregunta registrada',
    'faq.list.not_found': 'No se encontró ninguna pregunta',

    // Uninstalled Apllication
    'uninstalled_application.title': 'Aplicación Desinstalada',
    'uninstalled_application.title_description': 'En esta pantalla es posible visualizar la información de todos los dispositivos en los que se desinstaló la aplicación.',

    // Uninstall Attempts
    'uninstall_attempts.title': 'Intentos de desinstalación',
    'uninstall_attempts.title_description': 'En esta pantalla, puede ver información sobre todos los intentos de desinstalar la aplicación ${process.env.REACT_APP_PORTAL_NAME} de los dispositivos.',
    // Devices without communication
    'device_without_communication.title': 'Dispositivos sin Comunicación',
    'device_without_communication.title_description': 'En esta pantalla, se puede ver los dispositivos que no se están comunicando con el Portal.',

    // Notification Menu
    'notification.title': 'Notificaciones',
    'notification.list.empty': 'No tienes ninguna notificación',
    'notification.list.title': 'Reporte:',
    'notification.list.button.url': 'Haga clic para descargar',
    'menu.settings.profile_consumption': 'Perfil de Consumo',
    'notification.list.error': 'Error en la generación de reportes',

    // Generate Report Alert
    'generate_report_alert.title': 'Quieres exportar un reporte con la información que se muestra en la primera página o un reporte completo con el resultado de todas las páginas?',
    'generate_report_alert.sub_title': 'Si selecciona la opción Completo, el reporte se generará en segundo plano y puede tardar unos minutos en completarse.',
    'menu.help.faq': 'Preguntas Frecuentes',
    'generate_report_alert.button.first_page': 'Primera página',
    'generate_report_alert.button.complete': 'Completo',
    'generate_report_excel.legend.report': 'Reporte',
    'generate_report_excel.legend.current_date': 'Fecha',
    'generate_report_excel.legend.page': 'Página',
    'generate_report_excel.legend.total_pages': 'Total de Páginas',

    // New Keys
    'consumption_profile.toaster_success': '¡Regla registrada con éxito!',
    'consumption_profile.general.description': 'La configuración de los Perfiles de Consumo no refleja la naturaleza del servicio contratado con el operador. La información registrada en este formulario refleja únicamente las políticas de uso de su organización.',
    'consumption_profile.toaster_update': '¡Regla actualizada con éxito!',
    'consumption_profile.toaster_level_up': '¡Regla establecida para nivel superior con éxito!',

    'manage_admin.entity': 'Usuario',
    'manage_admin.toaster.success.edit': 'Administrador editado con éxito',
    'manage_admin.filter.placeholder': 'Seleccionar',
    'message.send_label': 'Enviar',

    'block_application.toaster.register': '¡Regla registrada con éxito!',
    'block_application.toaster.update': '¡Regla actualizada con éxito!',
    'block_application.toaster.delete': '¡Regla eliminada con éxito!',
    'menu.settings.qrcode': 'Código QR para Device Owner',

    'block_application.toaster.apply': '¡Reglas aplicadas con éxito!',
    'devices.message.remove_success': 'Eliminado con éxito',
    'menu.websites.report_date': 'Reporte por Fecha',
    'menu.websites.report_url': 'Reporte por URL / Palabra Clave',
    'menu.websites': 'Sitios',
    'menu.app_action.application_control.website_by_category': 'Sitios web por categoria',
    'menu.app_action.application_control.website_by_url': 'Sitios web por URL',
    'menu.help.suport': 'Solicitud de Soporte',
    'menu.groups_and_subgroups.subgroups': 'Subgrupos',
    'menu.users': 'Usuarios',
    'menu.users.device_users': 'Usuarios',
    // Date Report
    'date_report.header.user': 'Usuario',
    'date_report.header.phoneNumber': 'Teléfono',
    'date_report.header.domain': 'Dominio',
    'date_report.header.accessedAt': 'Fecha',

    'edit_group.company_name': 'Empresa',
    //MENU
    //dashboard
    //company
    // groups and subgroups
    //users
    'menu.users.device_users.manage_profile_access': 'Perfis de acceso',
    //devices
    // GPS
    'reportsGps.column.phone': 'Teléfono',
    //application
    'reportsGps.column.user': 'Usuario',
    //sites
    'reportsGps.column.gps': 'GPS',
    //messages
    'reportsGps.column.createdAt': 'Fecha',
    //shered docs
    //location
    // CONSUMPTION HISTORIC
    'company_consumption.column.tabs_date': 'Fecha',
    'company_consumption.column.user': 'Usuario',
    // adroid actions
    'company_consumption.column.package_consumption': 'Consumo',
    // settings
    'company_license.column.enrolled_device': 'Dispositivo registrado',
    // help
    'company_license.select_state': 'Seleccione un estado',
    'map.address': 'Direccióm',
    'map.speed': 'Velocidad',
    'consumption_profile.goup_company': 'Definido en el nivel superior',
    'consumption_profile.edit.description': 'En esta pantalla es posible registrar una nueva regla de perfil de consumo para usuarios que no tienen regla. Si el usuario no se muestra en el campo seleccionado, es posible que ya tenga una regla registrada previamente y se puede cambiarla. Revisa la pantalla anterior y encuentra el usuario que desea cambiar la regla del perfil de consumo.',
    'sites_category.block.general.text': 'En esta pantalla, se puede configurar una regla de perfil para bloquear o desbloquear sitios web por categoría en los dispositivos.',
    'consumption_profile.data.limit': 'Datos (Cantidad)',
    'consumption_profile.dataRoaming.limit': 'Datos en roaming (Cantidad)',
    'consumption_profile.sms.limit': 'SMS (Cantidad)',
    'consumption_profile.smsRoaming.limit': 'SMS en roaming (Cantidad)',
    'card_help.discover_more': 'Más información',
    'sites_category.block.title': 'Bloquear/desbloquear sitios web por categoría',
    'message.content': 'Mensaje',
    'consumption_profile.groupId': 'Grupo',
    'consumption_profile.userId': 'Usuario',
    'global.empty_results': 'Ningún resultado encontrado.',
    'menu.open': 'Abrir menú',
    'menu.close': 'Cerrar menú',
    'reportsGps.start_date': 'Fecha inicio',
    'reportsGps.end_date': 'Fecha final',

    'qrcode.valid_url': 'Debe ser una URL válida',
    'qrcode.lading_qrCode': 'Cargando código QR...',
    'qrcode.invalid_url': 'URL no válida para descargar',
    'global.generic_not_found': 'Nada para mostrar.',
    'messages.max_characters': 'Este campo debe tener un máximo de 255 caracteres.',
    'global.not_option_found': 'No se encontró ninguna opción',
    'global.select_placeholder': 'Seleccione una opción',
    'block_application.rule_type': 'Tipo de regla',
    'block_application.application': 'Aplicación',
    'sites.block.success_create': 'Creado con éxito',
    'sites.block.success_update': 'Actualizado exitosamente',
    'company_license.select_plan': 'Seleccione un plan',
    'global.action.set_level_up': 'Definir Nivel Superior',
    'devices.alert.change.text': '¿Quieres cambiar de equipo?',
    'devices.alert.change.button': 'Para reemplazar',
    'battery.chart_battery': 'Batería',
    'battery.chart_date': 'Tiempo',
    'consumption_profile.modal.apply_config': '¿Realmente desea definir el nivel superior?',
    'storage.chart_storage': 'Almacenamiento',
    'storage.chart_date': 'Horario',
    'generate_complete_report_alert.confirm_button': 'Ok',
    'generate_complete_report_alert.title': '¡Por favor, espere que se genere el reporte!',
    'generate_complete_report_alert.text': 'El sistema mostrará una notificación en la parte superior de la pantalla cuando se complete, donde podrá descargar el reporte.',
    'global.copy.success': 'Copiado en el portapapeles.',
    'register_policies.title': 'Registrar Nueva Política',
    'register_policies.description': 'En esta pantalla, puede registrar una nueva política.',
    'register_policies.name': 'Nombre de la política',
    'register_policies.policyType': 'Selecciona el tipo de política',
    'register_policies.select_policy': 'Seleccione',
    'register_policies.select.placeholder': 'Seleccione un modo',
    'menu.policy': 'Políticas',
    'menu.policy.manage': 'Administrar Políticas',
    'manage_policies.title': 'Administrar Políticas',
    'manage_policies.description': 'En esta página es posible gestionar políticas',
    'manage_policies.success.create': '¡Política creada con éxito!',
    'manage_policies.new': 'Nueva Política',
    'manage_policies.table_actions.enrollment_token': 'Token de registro',
    'manage_policies.table_actions.edit': 'Editar política',
    'manage_policies.table_actions.copy': 'Copiar Política',
    'manage_policies.table_actions.delete': 'Eliminar Política',
    'manage_policies.modal_enrollmentToken.title': 'Token de Registro',
    'manage_policies.modal_enrollmentToken.code': 'Código',
    'manage_policies.modal_enrollmentToken.confirm': 'Ok',
    'menu.enterprise_applications': 'Gestión de Aplicaciones',
    'menu.enterprise_applications.manage': 'Aplicaciones Administradas',
    'enterprise_applications.title': 'Aplicaciones Administradas',
    'enterprise_applications.description': 'En esta página es posible gestionar aplicaciones Enterprise',
    'enterprise_applications.table_actions.entity': 'Aplicación ',
    'enterprise_applications.column.icon': 'Icono',
    'enterprise_applications.column.name_app': 'Nombre de la aplicación',
    'enterprise_applications.column.origin': 'Fuente',
    'enterprise_applications.column.name_package': 'Nombre del paquete',
    'enterprise_applications.filter': 'Nombre de la aplicación',
    'register_policies.android': 'Android: Totalmente Administrado',
    'register_policies.android.description': 'Para dispositivos de propiedad de la empresa. Permite la gestión de una amplia gama de configuraciones de dispositivos y controles de políticas.',
    'register_policies.dedicated_mode': 'Dispositivos Dedicados',
    'register_policies.dedicated_mode.description': 'Para dispositivos dedicados, donde los dispositivos están bloqueados en un conjunto de aplicaciones (a través de una configuración de aplicación de quiosco). Se puede evitar que se inicien otras aplicaciones y que se realicen otras acciones en el dispositivo.',
    'enterprise_applications.success.delete': '¡Aplicación eliminada con éxito!',
    'manage_policies.success.delete': '¡Política eliminada con éxito!',
    'menu.enterprise_applications.google_play_managed': 'Google Play Administrada',
    'devices.groups': 'Grupos',
    'google_play_managed.toast.success.description': '¡Aplicación seleccionada con éxito!',
    'google_play_managed.toast.error.description': 'Ocurrió un error al seleccionar la aplicación.',
    'google_play_managed.toast.success.modal.text': '¿Quieres seleccionar esta aplicación?',
    'register_company.button.next': 'Siguiente',
    'manage_policies.confirm.text': '¿Está seguro de que desea eliminar esta política?',
    'register_policies.name.placeholder': 'Ingrese el nombre de la política',
    'global.report': 'Reporte',
    'message.recipient': 'Destinatario',
    'document.recipient': 'Destinatario',

    'devices.column.management_mode': 'Modelo',
    'devices.column.enterpriseId': 'Identificación en Android Enterprise',
    'devices.action.wipe_device': 'Remover dispositivo (WIPE)',
    'company_register.title': 'Crear empresa',
    'company_register.subtitle': 'En esta pantalla se puede registrar la información de su empresa.',
    'menu.policy.register': 'Registrar Nueva Política',
    'company_callback.success': '¡Felicidades! Su empresa se ha registrado correctamente en Android Enterprise.',
    'company_callback.failure': 'Hubo un error al crearlo, inténtalo de nuevo.',
    'global.ok': 'Ok',
    'global.confirm_password': 'Confirmar nueva contraseña',
    'register_company.info': 'Se enviará un código de confirmación al correo electrónico anterior.',
    'register_company.title1': 'Confirmar datos de la empresa',
    'register_company.title2': 'Para continuar',
    'register_company.create_new_password': 'Crear nueva contraseña para el usuario',
    'register_company.email': 'E-mail',
    'register_company.corporateName': 'Nombre de la empresa',
    'register_company.password': 'Contraseña',
    'register_company.confirm_password': 'Confirmación de contraseña',
    'register_company.optional': '(Opcional)',
    'register_company.send_confirm': 'Enviar código de confirmación',
    'register_company.eu.representative': 'Representante de la UE',
    'register_company.data.protection.officer': 'Responsable de protección de datos',
    'register_company.password_alert': 'Las contraseñas no coinciden. Es necesario rellenar el campo de confirmación con la misma contraseña para realizar el cambio de contraseña. Revise las contraseñas introducidas e inténtelo de nuevo.',
    'register_validate.title1': 'Compruebe el',
    'register_validate.title2': 'Su correo electrónico ',
    'register_validate.info': 'Complete con el código de 6 dígitos que se envió a su correo electrónico',
    'register_validate.resend': 'Reenviar codigo',
    'register_validate.send_confirm': 'Confirmar',
    'enterprise_applications.table_actions.remove': 'Eliminar',
    'policy_copy.title': 'Copiar política',
    'policy_copy.subtitle': 'Copiando política',
    'manage_policies.policy': 'Política',
    'register_company.empty_password_alert': 'Ingresa una contraseña',
    'update_policies.title': 'Editar política',
    'update_policies.description': 'En esta pantalla, se puede actualizar una política.',
    'update_policies.tab.configuration': 'Ajustes',
    'update_policies.tab.application': 'Aplicaciones',
    'update_policies.tab.kiosk': 'Modo Quiosco',
    'update_policies.tab.token': 'Token de registro',
    'update_policies.policyType': 'Modo de Gestión',
    'update_policies.name': 'Nombre de la política',
    'devices.column.policy': 'Política',
    'devices.column.accordion.management_mode': 'Modo de Gestión',
    'devices.column.accordion.management_mode.managed_android': 'Android',
    'devices.column.accordion.management_mode.block_sim_android': 'Android - Block Sim',
    'devices.column.accordion.management_mode.work_profile_android': 'Android - Work Profile',
    'devices.column.accordion.management_mode.managed_ios': 'IOS',
    'devices.column.accordion.management_mode.supervised_ios': 'IOS supervisado',
    'devices.column.status': 'Estado',
    'devices.column.appliedState': 'Estado aplicado',
    'devices.column.register_date': 'Fecha de Registro',
    'devices.column.accordeon.management_mode': 'Modo de Gestión',
    'devices.column.operation_system': 'Sistema operativo',
    'devices.column.last_sync': 'Última sincronización de política',
    'devices.column.serial_number': 'Numero de serie',
    'devices.column.battery_power': 'Nivel de bateria',
    'devices.column.storage': 'Almacenamiento interno',
    'devices.value.state_active': 'Activo',
    'devices.value.state_disabled': 'Desactivado',
    'devices.value.state_deleted': 'Eliminado',
    'devices.value.state_provisioning': 'Aprovisionando ',
    'edit_policy.kiosk.navigate_button': 'Botones de navegacion',
    'edit_policy.kiosk.power_button': 'Botón "Power"',
    'edit_policy.kiosk.message_error_exibition': 'Exhibir mensajes de error',
    'edit_policy.kiosk.status_bar_exibition': 'Informaciones mostradas en la barra de estado',
    'edit_policy.kiosk.config_access': 'Acceso a Configuraciónes',
    'edit_policy.kiosk.available': 'Disponible',
    'edit_policy.kiosk.blocked': 'Bloqueado',
    'edit_policy.kiosk.active': 'Activo',
    'edit_policy.kiosk.muted': 'Silenciado',
    'edit_policy.kiosk.notify_info_system': 'Notificaciones e Informaciones del Sistema',
    'edit_policy.kiosk.sytem_only': 'Solo información del sistema',
    'edit_policy.kiosk.none': 'Ninguna',
    'edit_policy.kiosk.released': 'Liberado',
    'edit_policy.kiosk.home_only': 'Solo el botón "Inicio"',
    'edit_policy.kiosk.kiosk_mode.title': 'Modo Quiosco',
    'edit_policy.kiosk.kiosk_mode.subtitle': 'Puedes controlar la configuración del modo Quiosco. Después de habilitar el Modo Quiosco, vaya a la pestaña "Aplicaciones" para definir cómo se mostrarán en los dispositivos inscritos en esta política.',
    'edit_policy.kiosk.modal.active': 'Activar',
    'edit_policy.kiosk.modal.title': '¿Estás seguro de que quieres habilitar el modo Quiosco?',
    'update_policies.button.save': 'Guardar',
    'register_company.term.title': 'Política y Términos',
    'register_company.term.description': 'Para usar {productName}, debe leer y aceptar la Política de Privacidad y los Términos de Uso del Usuario',
    'register_company.term.user_term': 'Estoy de acuerdo con los Términos de Uso del Usuario',
    'register_company.term.privacy_policy': 'Estoy de acuerdo con la Política de Privacidad',
    'update_policies.warning_exit': '¿Quiere dejar de editar la política sin guardarla? ¡Todos los cambios realizados serán descartados!',
    'update_policies.message_success': '¡Política guardada con éxito!',
    'history_consumption.title': 'Historial de Consumo',
    'history_consumption.description': 'En esta pantalla se puede visualizar el gráfico de evolución del consumo de datos de la aplicación en los dispositivos.',
    'update_policies.applications.card_mode.title': 'Modo Google Play del dispositivo',
    'update_policies.applications.card_mode.description': 'Aquí se puede controlar cómo se muestran sus aplicaciones en los dispositivos registrados en este perfil. Consulte la descripción del modo seleccionado para obtener más detalles. En el modo "Restringido", los usuarios solo pueden ver e instalar las aplicaciones que se enumeran a continuación desde Google Play. Las otras aplicaciones serán eliminadas.',
    'update_policies.applications.card_mode.whitelist': 'Restringida',
    'update_policies.applications.card_mode.blacklist': 'Abierta',
    'update_policies.applications.card_mode.kiosk': 'Modo Quiosco',
    'update_policies.applications.table.column.name_app': 'Nombre de la aplicación',
    'update_policies.applications.table.column.origin': 'Origen',
    'update_policies.applications.table.column.install_type': 'Tipo de instalación',
    'update_policies.applications.table.button_label': 'Agregar aplicaciones',
    'update_policies.applications.installation_type.preinstalled': 'Pre instalado',
    'update_policies.applications.installation_type.force_installed': 'Instalación forzada',
    'update_policies.applications.installation_type.blocked': 'Bloqueado',
    'update_policies.applications.installation_type.available': 'Disponible',
    'update_policies.applications.table_action.settings': 'Configuraciones Administradas',
    'update_policies.applications.table_action.permissions': 'Permisos',
    'update_policies.applications.table_action.advance_settings': 'Configuraciones Avanzadas',
    'update_policies.applications.table_action.remove_app': 'Eliminar Aplicación',
    'update_policies.modal_enterprise_apps.title': 'Agregar aplicaciones',
    'update_policies.modal_enterprise_apps.button_label': 'Añadir seleccionado',
    'update_policies.settings.accordion.network': 'Red',
    'update_policies.settings.accordion.network.vpn.title': 'Bloquear configuración de VPN',
    'update_policies.settings.accordion.network.vpn.description': 'La configuración de VPN está deshabilitada',
    'update_policies.settings.accordion.network.wifi.title': 'Bloquear la configuración de Wi-Fi',
    'update_policies.settings.accordion.network.wifi.description': 'La configuración del punto de acceso Wi-Fi está deshabilitada',
    'update_policies.settings.accordion.network.network_reset.title': 'Bloquear el restablecimiento de las configuraciones de red',
    'update_policies.settings.accordion.network.network_reset.description': 'El restablecimiento de la configuración de red está deshabilitado',
    'update_policies.settings.accordion.network.roaming_block.title': 'Bloquear datos en roaming',
    'update_policies.settings.accordion.network.roaming_block.description': 'La configuración de roaming de datos está deshabilitada',
    'update_policies.settings.accordion.network.mobile_network_block.title': 'Bloquear configuración de red móvil',
    'update_policies.settings.accordion.network.mobile_network_block.description': 'La configuración de redes móviles está deshabilitada',

    'update_policies.settings.accordion.hardware': 'Hardware',
    'update_policies.settings.accordion.hardware.disable_camera.title': 'Deshabilitar la cámara',
    'update_policies.settings.accordion.hardware.disable_camera.description': 'Todas las cámaras del dispositivo están deshabilitadas',
    'update_policies.settings.accordion.hardware.lock_volume.title': 'Bloquear ajustes de volumen',
    'update_policies.settings.accordion.hardware.lock_volume.description': 'El ajuste de volumen principal está deshabilitado. También silencia el dispositivo.',
    'update_policies.settings.accordion.hardware.disable_bluetooth.title': 'Deshabilitar bluetooth',
    'update_policies.settings.accordion.hardware.disable_bluetooth.description': 'Bluetooth está deshabilitado',
    'update_policies.settings.accordion.hardware.bluetooth_settings_block.title': 'Bloquear las configuraciones de bluetooth',
    'update_policies.settings.accordion.hardware.bluetooth_settings_block.description': 'La configuración de bluetooth está deshabilitada',
    'update_policies.settings.accordion.hardware.disable_hotspot.title': 'Deshabilitar la configuración del punto de acceso',
    'update_policies.settings.accordion.hardware.disable_hotspot.description': 'La configuración de puntos de acceso portátiles está deshabilitada',
    'update_policies.settings.accordion.hardware.disable_screenshots.title': 'Deshabilitar capturas de pantalla',
    'update_policies.settings.accordion.hardware.disable_screenshots.description': 'La captura de pantalla está deshabilitada',
    'update_policies.settings.accordion.hardware.microphone_access.title': 'Acceso al micrófono',
    'update_policies.settings.accordion.hardware.microphone_access.description': 'El micrófono está silenciado y el ajuste de volumen del micrófono está deshabilitado',
    'update_policies.settings.accordion.hardware.microphone_access.select.user_choice': 'Definido por el Usuario',
    'update_policies.settings.accordion.hardware.microphone_access.select.access_disabled': 'Desactivado',
    'update_policies.settings.accordion.hardware.microphone_access.select.access_enforced': 'Activado',
    'update_policies.settings.accordion.hardware.outgoing_calls_disabled.title': 'Deshabilitar la realización de llamadas',
    'update_policies.settings.accordion.hardware.outgoing_calls_disabled.description': 'Todas las llamadas salientes están desactivadas.',
    'update_policies.settings.accordion.hardware.bluetooth_contact_sharing_disabled.title': 'Bloquear el uso compartido de contactos a través de Bluetooth',
    'update_policies.settings.accordion.hardware.bluetooth_contact_sharing_disabled.description': 'Compartir contactos a través de Bluetooth está desactivado.',

    'update_policies.settings.accordion.safety': 'Seguridad',
    'update_policies.settings.accordion.safety.new_users_block.title': 'Bloquear la adición de nuevos usuarios',
    'update_policies.settings.accordion.safety.new_users_block.description': 'La adición de nuevos usuarios y perfiles está deshabilitada',
    'update_policies.settings.accordion.safety.new_users_block_remove.title': 'Bloquear eliminación de nuevos usuarios',
    'update_policies.settings.accordion.safety.new_users_block_remove.description': 'La eliminación de otros usuarios está deshabilitada',
    'update_policies.settings.accordion.safety.reset_block.title': 'Bloquear restablecimiento de fábrica',
    'update_policies.settings.accordion.safety.reset_block.description': 'El restablecimiento de configuraciones de fábrica está deshabilitado',
    'update_policies.settings.accordion.safety.sd_card_block.title': 'Bloquear tarjeta SD',
    'update_policies.settings.accordion.safety.sd_card_block.description': 'El montaje de medios físicos externos está deshabilitado',
    'update_policies.settings.accordion.safety.usb_sharing_block.title': 'Bloquear el uso compartido de archivos a través de USB',
    'update_policies.settings.accordion.safety.usb_sharing_block.description': 'La transferencia de archivos USB está deshabilitada',

    'update_policies.settings.accordion.localization': 'Ubicación',
    'update_policies.settings.accordion.localization.mode.title': 'Modo de ubicación',
    'update_policies.settings.accordion.localization.mode.description': 'Detección de ubicación habilitada',
    'update_policies.settings.accordion.localization.mode.select.user_choice': 'Definido por el usuario',
    'update_policies.settings.accordion.localization.mode.select.location_disabled': 'Desactivado',
    'update_policies.settings.accordion.localization.mode.select.location_enforced': 'Activado',
    'update_policies.settings.accordion.localization.location_sharing_disabled.title': 'Desactivar compartir ubicación',
    'update_policies.settings.accordion.localization.location_sharing_disabled.description': 'Compartir ubicación está deshabilitado',
    'update_policies.settings.accordion.localization.monitoring_hours.title': 'Horas de Monitoreo',
    'update_policies.settings.accordion.localization.monitoring_hours.description': 'Período en el que la detección de ubicación está habilitada',
    'update_policies.settings.accordion.localization.monitoring_hours.initial_time': 'Hora de inicio',
    'update_policies.settings.accordion.localization.monitoring_hours.final_time': 'Hora de finalización',
    'update_policies.settings.accordion.localization.monitoring_hours.select': 'Seleccionar',
    'update_policies.settings.accordion.localization.monitoring_hours.gps_frequency.min3': '3 minutos',
    'update_policies.settings.accordion.localization.monitoring_hours.gps_frequency.min10': '10 minutos',
    'update_policies.settings.accordion.localization.monitoring_hours.gps_frequency.min30': '30 minutos',
    'update_policies.settings.accordion.localization.monitoring_hours.gps_frequency.h1': '1 hora',
    'update_policies.settings.accordion.localization.monitoring_hours.gps_frequency.h6': '6 horas',
    'update_policies.settings.accordion.localization.monitoring_hours.gps_frequency.h12': '12 horas',
    'update_policies.settings.accordion.localization.monitoring_hours.gps_frequency.h24': '24 horas',
    'update_policies.settings.accordion.localization.find_every.title': 'Encontrar cada',
    'update_policies.settings.accordion.localization.find_every.description': 'Frecuencia de captura de ubicación',
    'update_policies.locationMode': 'Ubicación - Modo de ubicación',
    'update_policies.gpsCaptureStartTime': 'Ubicación - Hora inicio',
    'update_policies.gpsCaptureEndTime': 'Ubicación - Hora final',
    'update_policies.gpsFrequency': 'Ubicación - Ubiquar cada',
    'edit_policy.app_config.title': 'Configuraciones Administradas',
    'edit_policy.app_config.list_application': 'Lista de Aplicaciones',
    'update_policies.applications.app_advanced_config.title': 'Configuraciones avanzadas',
    'update_policies.applications.app_advanced_config.auto_update_mode.title': 'Prioridad de actualización',
    'update_policies.applications.app_advanced_config.auto_update_mode.description': 'Modo de actualización automática',
    'update_policies.applications.app_advanced_config.auto_update_mode.no_specify': 'No especificado',
    'update_policies.applications.app_advanced_config.auto_update_mode.default': 'Predefinido',
    'update_policies.applications.app_advanced_config.auto_update_mode.postponed': 'Pospuesto',
    'update_policies.applications.app_advanced_config.auto_update_mode.high_priority': 'Prioritaria',
    'update_policies.applications.app_advanced_config.minimum_version_code.title': 'Versión mínima',
    'update_policies.applications.app_advanced_config.minimum_version_code.description': 'Código de versión mínima',
    'edit_policy.app_permissions.title': 'Permisos',
    'edit_policy.app_permissions.select_label.unspecified': 'No especificado',
    'edit_policy.app_permissions.select_label.prompt': 'Solicitar al usuario',
    'edit_policy.app_permissions.select_label.grant': 'Activado',
    'edit_policy.app_permissions.select_label.deny': 'Negada',
    'edit_policy.applications.managed_config.empty_config': 'No hay ajustes disponibles para esta aplicación.',
    'edit_policy.applications.managed_config.empty_permission': 'No hay ajustes disponibles para esta aplicación.',
    'devices.column.federalCode': 'Identificación',

    'history_consumption.header.filter_by': 'Filtrado por:',
    'history_consumption.chart_legend.consumption': 'Consumo',
    'history_consumption.chart_legend.day': 'Día',
    'history_consumption.chart_legend.empty': 'No se encontró historial',
    'history_consumption_data.title': 'Historial de consumo de datos',
    'history_consumption_sms.title': 'Historial de consumo de SM',
    'history_consumption.history_user.description': 'En esta pantalla se puede visualizar el grafico de evolución del consumo de datos de la aplicación.',
    'notification.not_found_key': 'Clave no encontrada',

    'update_policies.applications': 'Aplicaciones',
    'update_policies.applications.minimumVersionCode': 'Versión mínima de la aplicación',

    'global.blocked': 'Bloqueado',
    'global.released': 'Liberado',

    'edit_policy.app_config.key_existing_alert': 'Palabra clave ya existente.',
    'consumption_limit_group.title': 'Perfil de Consumo - Grupo',
    'consumption_limit_user.title': 'Perfil de Consumo - Usuario',
    'devices.action.policy': 'Alterar Política',
    'devices.action.turn_off_screen': 'Apagar Pantalla',
    'devices.action.restart_device': 'Reiniciar Dipositivo',
    'devices.action.alert_reboot1': 'El dispositivo',
    'devices.action.alert_reboot2': 'Ha sido reiniciado.',
    'devices.action.alert_reboot': '¡Comando de reinicio del dispositivo enviado con éxito!',

    'non_compliance.title': 'No conformidades',
    'non_compliance.sub_title': 'En esta pantalla es posible identificar la configuración de la política que no fue aplicada por el usuario en el dispositivo y el motivo de la no conformidad.',
    'non_compliance.card_header.title': 'Dispositivo',
    'non_compliance.form.user': 'Usuario',
    'non_compliance.form.phoneNumber': 'Teléfono',
    'non_compliance.form.imei': 'IMEI',
    'non_compliance.form.manufacturer': 'Fabricante',
    'non_compliance.form.model': 'Modelo',
    'non_compliance.column.settingName': 'Configuración',
    'non_compliance.column.nonComplianceReason': 'Motivo del incumplimiento',
    'non_compliance.nonComplianceReason.reason_unspecified': 'Este valor no está permitido',
    'non_compliance.nonComplianceReason.api_level': 'La configuración no es compatible con el nivel de API de la versión de Android que se ejecuta en el dispositivo.',
    'non_compliance.nonComplianceReason.management_mode': 'El modo de administración (propietario del perfil, propietario del dispositivo, etc.) no es compatible con la configuración.',
    'non_compliance.nonComplianceReason.user_action': 'El usuario no ha tomado las medidas necesarias para cumplir con la configuración.',
    'non_compliance.nonComplianceReason.invalid_value': 'La configuración tiene un valor no válido.',
    'non_compliance.nonComplianceReason.app_not_installed': 'La aplicación requerida para implementar la política no está instalada.',
    'non_compliance.nonComplianceReason.unsupported': 'La política no es compatible con la versión de Android Device Policy en el dispositivo.',
    'non_compliance.nonComplianceReason.app_installed': 'Una aplicación bloqueada está instalada.',
    'non_compliance.nonComplianceReason.pending': 'La configuración no se ha aplicado en el momento del reporte, pero se espera que se aplique pronto.',
    'non_compliance.nonComplianceReason.app_incompatible': 'La configuración no se puede aplicar a la aplicación porque la aplicación no la admite, por ejemplo, porque la versión del SDK de destino no es lo suficientemente alta.',
    'non_compliance.nonComplianceReason.app_not_updated': 'La aplicación está instalada pero no se ha actualizado al código de versión mínimo especificado por la política.',
    'non_compliance.column.specificNonComplianceReason': 'Detalle de No Conformidad',
    'non_compliance.specificNonComplianceReason.unspecified': 'No se especifica el motivo específico de no conformidad.',
    'non_compliance.specificNonComplianceReason.credentials_confirmation_required': 'El usuario debe confirmar las credenciales ingresando el bloqueo de pantalla.',
    'non_compliance.specificNonComplianceReason.password_expired': 'La contraseña del dispositivo o perfil ha expirado.',
    'non_compliance.specificNonComplianceReason.password_not_sufficient': 'La contraseña del dispositivo no cumple con los requisitos de contraseña.',
    'non_compliance.specificNonComplianceReason.wifi_invalid_value': 'Hay un valor incorrecto en la configuración ONC Wi-Fi.',
    'non_compliance.specificNonComplianceReason.wifi_api_level': 'La configuración ONC Wi-Fi no es compatible con el nivel de API de la versión de Android que se ejecuta en el dispositivo.',
    'non_compliance.expander.installationFailureReason': 'Motivo de la falla en la instalación',
    'non_compliance.expander.installationFailureReason.unspecified': 'Este valor no está permitido.',
    'non_compliance.expander.installationFailureReason.unknown': 'Una condición desconocida impide que la aplicación se instale. Algunas razones posibles son que el dispositivo no tiene suficiente almacenamiento, la conexión de red del dispositivo no es confiable o la instalación está demorando más que lo esperado. La instalación se volverá a intentar automáticamente.',
    'non_compliance.expander.installationFailureReason.in_progress': 'La instalación aún está en progreso.',
    'non_compliance.expander.installationFailureReason.not_found': 'La aplicación no se encontró en Play.',
    'non_compliance.expander.installationFailureReason.not_compatible': 'La aplicación es incompatible con el dispositivo.',
    'non_compliance.expander.installationFailureReason.not_approved': 'La solicitud no ha sido aprobada por el administrador.',
    'non_compliance.expander.installationFailureReason.permissions_not_accepted': 'La aplicación tiene nuevos permisos que no han sido aceptados por el administrador.',
    'non_compliance.expander.installationFailureReason.not_available_in_country': 'La aplicación no está disponible en el país del usuario.',
    'non_compliance.expander.installationFailureReason.no_licenses_remaining': 'No hay licencias disponibles para atribuir al usuario.',
    'non_compliance.expander.installationFailureReason.not_enrolled': 'La empresa ya no está inscrita en Managed Google Play o el administrador no ha aceptado las últimas Condiciones de servicio de Managed Google Play.',
    'non_compliance.expander.installationFailureReason.user_invalid': 'El usuario ya no es válido. Es posible que el usuario haya sido eliminado o deshabilitado.',
    'non_compliance.expander.packageName': 'Nombre del paquete',
    'non_compliance.expander.fieldPath': 'Camino de campo',
    'non_compliance.expander.currentValue': 'Valor actual',
    'non_compliance.expander.wifi_context': 'Contexto Wi-Fi',
    'non_compliance.expander.password_context': 'Contexto de contraseña de la política',
    'non_compliance.expander.password_context.scope_unspecified': 'Alcance no especificado',
    'non_compliance.expander.password_context.scope_device': 'Alcance del dispositivo',
    'non_compliance.expander.password_context.scope_profile': 'Alcance del perfil',
    'update_policies.settings.accordion.password': 'Restricciones de contraseña',
    'update_policies.settings.accordion.password.select.something': 'Alguna',
    'update_policies.settings.accordion.password.select.numeric': 'Numérica',
    'update_policies.settings.accordion.password.select.numeric_complex': 'Numérica compleja',
    'update_policies.settings.accordion.password.select.alphabetic': 'Alfabética',
    'update_policies.settings.accordion.password.select.alphanumeric': 'Alfanumérica',
    'update_policies.settings.accordion.password.select.complex': 'Compleja',
    'update_policies.settings.accordion.password.select.biometric_weak': 'Biometría',
    'update_policies.settings.accordion.password.title': 'Calidad de la contraseña',
    'devices.action.new_device_password': 'Generar nueva contraseña del dispositivo',
    'device.modal.new_device_password.description': 'Ingrese la configuración para restablecer el código de acceso de bloqueo del dispositivo',
    'device.modal.new_device_password.newPassword': 'Nueva contraseña',
    'device.modal.new_device_password.new_password_confirm': 'Confirmar nueva contraseña',
    'device.modal.new_device_password.checkbox.not_allow_password_change': 'No permita que otros administradores cambien la contraseña nuevamente hasta que el usuario la ingrese en el dispositivo.',
    'device.modal.new_device_password.checkbox.not_ask_credentials_user': 'No solicite las credenciales de usuario al iniciar el dispositivo.',
    'device.modal.new_device_password.checkbox.device_block': 'Bloquear dispositivo después de restablecer la contraseña.',
    'update_policies.passwordPolicies.select.something': 'Alguna',
    'update_policies.passwordPolicies.select.numeric': 'Numérica',
    'update_policies.passwordPolicies.select.numeric_complex': 'Númerica Compleja',
    'update_policies.passwordPolicies.select.alphabetic': 'Alfabética',
    'update_policies.passwordPolicies.select.alphanumeric': 'Alfanumérica',
    'update_policies.passwordPolicies.select.complex': 'Compleja',
    'update_policies.passwordPolicies.select.biometric_weak': 'Biometría',
    'update_policies.passwordPolicies.title': 'Calidad de la contraseña',
    'update_policies.passwordPolicies.select.password_require.default': 'Patrón del Dispositivo',
    'update_policies.passwordPolicies.select.password_require.every_day': 'Todos los dias',
    'update_policies.passwordPolicies.passwordHistoryLength': 'Historial máximo de contraseñas que el usuario no podrá volver a utilizar',
    'update_policies.passwordPolicies.maximumFailedPasswordsForWipe': 'Máximo de contraseñas incorrectas antes de ejecutar wipe',
    'update_policies.passwordPolicies.passwordExpirationTimeout': 'Tiempo de espera de expiración de la contraseña (días)',
    'update_policies.passwordPolicies.requirePasswordUnlock': 'Requerir desbloqueo de contraseña',
    'update_policies.passwordPolicies.passwordMinimumLength': 'Longitud mínima de la contraseña',
    'update_policies.passwordPolicies.passwordMinimumLetters': 'Número mínimo de letras requeridas en la contraseña',
    'update_policies.passwordPolicies.passwordMinimumNonLetter': 'Número mínimo de caracteres que no son letras (dígitos numéricos o símbolos) requeridos en la contraseña',
    'update_policies.passwordPolicies.passwordMinimumNumeric': 'Número mínimo de dígitos numéricos requeridos en la contraseña',
    'update_policies.passwordPolicies.passwordMinimumSymbols': 'Número mínimo de símbolos requeridos en la contraseña',
    'update_policies.passwordPolicies.passwordMinimumUpperCase': 'Número mínimo de letras mayúsculas requeridas en la contraseña',
    'update_policies.passwordPolicies.passwordMinimumLowerCase': 'Número mínimo de letras minúsculas requeridas en la contraseña',
    'update_policies.passwordPolicies.description.biometric_weak': 'El dispositivo debe ser protegido con, al menos, una tecnología de reconocimiento biométrico de baja seguridad.',
    'update_policies.passwordPolicies.description.something': 'Se requiere una contraseña, pero no hay restricciones sobre lo que debe contener la contraseña.',
    'update_policies.passwordPolicies.description.complex': 'La contraseña debe contener al menos una letra, un carácter numérico y un símbolo.',
    'update_policies.passwordPolicies.description.numeric': 'La contraseña debe contener como mínimo caracteres numéricos.',
    'update_policies.passwordPolicies.description.numeric_complex': 'La contraseña debe contener como mínimo caracteres numéricos sin secuencias repetidas (4444) ni secuencias ordenadas (1234, 4321, 2468).',
    'update_policies.passwordPolicies.description.alphabetic': 'La contraseña debe contener como mínimo caracteres alfabéticos (o símbolos).',
    'update_policies.passwordPolicies.description.alphanumeric': 'La contraseña debe contener como mínimo caracteres numéricos y alfabéticos (o símbolos).',
    'device.modal.new_device_password.success': '¡Comando de restablecimiento de contraseña enviado con éxito!',
    'update_policies.applications.installation_type.required_for_setup': 'Requerido en el Registro',
    'update_policies.settings.accordion.safety.untrusted_app.title': 'Política de aplicaciones no confiables.',
    // System Update
    'update_policies.settings.accordion.operational_system': 'Sistema Operativo',
    'update_policies.systemUpdate.select.type': 'Política de actualización del sistema',
    'update_policies.systemUpdate.select.type.description': 'Esta política del sistema controla cómo se aplican las actualizaciones del sistema operativo. Si el tipo de actualización es "Automático dentro de una ventana", la ventana de actualización también se aplicará automáticamente a las actualizaciones de aplicaciones de Google Play.',
    'update_policies.systemUpdate.select.unspecified': 'Definido por el usuario',
    'update_policies.systemUpdate.select.automatic': 'Automático',
    'update_policies.systemUpdate.select.windowed': 'Automático dentro de una ventana',
    'update_policies.systemUpdate.select.postpone': 'Pospuesta',
    'update_policies.systemUpdate.time': 'Hora de la ventana de actualización',
    'update_policies.systemUpdate.time.description': 'Al configurar la política "Automático dentro de una ventana", las actualizaciones del sistema se realizarán dentro del tiempo de ventana especificado.',
    'update_policies.systemUpdate.time.select': 'Seleccionar',
    'update_policies.systemUpdate.startMinutes': 'Hora de inicio',
    'update_policies.systemUpdate.endMinutes': 'Hora de finalización',
    'update_policies.freezePeriods': 'Períodos de congelación de las actualizaciónes',
    'update_policies.systemUpdate.freeze_period.description': 'Todas las actualizaciones entrantes del sistema (incluidos los patches de seguridad) están bloqueadas y no se instalarán. Cuando un dispositivo está fuera del período de congelación, se aplica el comportamiento de actualización normal.',
    'update_policies.systemUpdate.add_freeze_period': '+ Añadir período de congelación',
    'update_policies.systemUpdate.freezePeriods.startDate': 'Mes y día de inicio del período de congelación',
    'update_policies.systemUpdate.freezePeriods.endDate': 'Mes y día de finalización del período de congelación',
    'update_policies.systemUpdate.start': 'Mes/Día de inicio',
    'update_policies.systemUpdate.end': 'Mes/Día de finalización',
    'update_policies.systemUpdate.month': 'Mes',
    'update_policies.systemUpdate.day': 'Día',
    'update_policies.settings.accordion.safety.untrusted_app.description': 'Instalación de aplicaciones por fuentes desconocidas',
    'update_policies.settings.accordion.safety.developer_settings.title': 'Configuraciones del desarrollador.',
    'update_policies.settings.accordion.safety.developer_settings.description': 'Controla el acceso a la configuración del desarrollador: opciones de desarrollo y inicialización segura',
    'update_policies.settings.accordion.safety.untrusted_apps.select.disallow_install': 'No permitir instalaciones de aplicaciones que no sean de confianza',
    'update_policies.settings.accordion.safety.untrusted_apps.select.allow_install': 'Permitir instalaciones de aplicaciones no confiables',
    'update_policies.settings.accordion.safety.developer_settings.select.developer_settings_disabled': 'Desactivar la configuración del desarrollador',
    'update_policies.settings.accordion.safety.developer_settings.select.developer_settings_allowed': 'Permitir configuraciones del desarrollador',
    'update_policies.applications.installation_type.kiosk': 'Quiosco',
    'password_recovery.modal.error': 'Error al enviar las instrucciones de desbloqueo a su correo electrónico',
    'global.privacy_policy': 'Política de privacidad',
    'global.terms_of_use': 'Términos de Uso del Usuario',
    'device.modal.update_policy': 'Seleccione una nueva política de dispositivo.',
    'menu.zero_touch': 'Zero Touch',
    'manage_policies.modal_enrollmentToken.policy': 'Política',
    'manage_policies.modal_enrollmentToken.register_token': 'Token de Registro',
    'manage_policies.modal_enrollmentToken.json_zero_touch': 'Configuración Zero Touch',
    'text_with_copy.success': '{value} ha sido copiado con éxito!',
    'register_validate.token.error': 'Token no válido o caducado',
    'edit_policy.applications.managed_config.block_categories': 'Bloqueo de Sitios por Categoria',
    'edit_policy.applications.managed_config.block_categories.adult_content': 'Contenido adulto',
    'edit_policy.applications.managed_config.block_categories.alcoholic': 'Contenido alcohólico',
    'edit_policy.applications.managed_config.block_categories.business': 'Negocio',
    'edit_policy.applications.managed_config.block_categories.chat': 'Chat',
    'edit_policy.applications.managed_config.block_categories.email': 'E-mail',
    'edit_policy.applications.managed_config.block_categories.educational': 'Educacional',
    'edit_policy.applications.managed_config.block_categories.entertainment': 'Entretenimiento',
    'edit_policy.applications.managed_config.block_categories.file_shares': 'Archivos compartidos',
    'edit_policy.applications.managed_config.block_categories.forum': 'Foro',
    'edit_policy.applications.managed_config.block_categories.gambling': 'Apuestas',
    'edit_policy.applications.managed_config.block_categories.games': 'Juegos',
    'edit_policy.applications.managed_config.block_categories.news': 'Noticias',
    'edit_policy.applications.managed_config.block_categories.proxies': 'Gobierno',
    'edit_policy.applications.managed_config.block_categories.search_engine': 'Medio de busca',
    'edit_policy.applications.managed_config.block_categories.shopping': 'Compras',
    'edit_policy.applications.managed_config.block_categories.social_media': 'Redes sociales',
    'edit_policy.applications.managed_config.block_categories.sports': 'Deportes',
    'edit_policy.applications.managed_config.block_categories.tabaco': 'Tabaco',
    'edit_policy.applications.managed_config.block_categories.technology': 'Tecnología',
    'edit_policy.applications.managed_config.block_categories.travel': 'Viajes',
    'edit_policy.applications.managed_config.block_categories.violence': 'Violencia',
    'edit_policy.applications.managed_config.block_categories.health': 'Salud',
    'edit_policy.applications.managed_config.block_categories.jobs': 'Empleo',
    'company_info.subtitle.dataProtection': 'Delegado de Protección de Datos',
    'company_info.subtitle.euRepresentative': 'Representante en la Unión Europea',
    'company_license.column.state': 'Estado',
    'company_license.filter.state': 'Estado',
    'global.version': 'Versión',
    'company_license.filter.enrolled_device': 'Dispositivo registrado',
    'edit_policy.kiosk.modal.disable': 'Desactivar',
    'edit_policy.kiosk.modal.disable.title': '¿Está seguro de que desea desactivar el Modo Quiosco?',
    'update_policies.passwordPolicies.select.unspecified': 'Ninguna',
    'update_policies.applications.alert.text.add': 'Ao agregar o {appName} en la política, el sistema eliminará las configuraciones de restricciones de contraseña. ¿Está seguro de que desea agregar la aplicación?',
    'update_policies.applications.alert.text.remove': 'Las configuraciones de restricciones de contraseña se han activado',
    'update_policies.passwordPolicies.description.app_block_sim': 'No es posible modificar estas configuraciones nientras la política cuenta con la aplicación de Bloqueo de tarjeta SIM. Quite la aplicación de la política para poder cambiar estas configuraciones.',
    'manage_policies.filter.policy_type.ios': 'Dispositivos Dedicados',
    'manage_policies.filter.policy_type.android_managed': 'Totalmente  Administrado',
    'manage_policies.filter.policy_type': 'Modo de Gestión',
    'manage_policies.filter.kiosk.true': 'Sí',
    'manage_policies.filter.kiosk.false': 'No',
    'manage_policies.filter.kiosk_custom_launcher_enabled': 'Modo Quiosco',
    'manage_policies.filter.play_store': 'Play Store',
    'manage_policies.filter.play_store.open': 'Abierto',
    'manage_policies.filter.play_store.restricted': 'Restringido',
    'update_policies.passwordPolicies.description.unspecified': 'No se requiere contraseña.',
    'manage_policies.filter.placeholder': 'Seleccione una opción',
    'menu.audit': 'Reportes de Movimientos',
    'audit.title': 'Reportes de Movimientos',
    'audit.description': 'En esta pantalla, se puede ver y generar reportes de los cambios realizados en el portal y el dispositivo.',
    'audit.start_date': 'Fecha inicio',
    'audit.last_date': 'Fecha final',
    'audit.users': 'Usuarios Administradores',
    'audit.activity': 'Actividad',
    'audit.category': 'Categoría',
    'audit.fields_modified': 'Modificaciones',
    'devices.value.state_register': 'Esperando Android Enterprise',
    'devices.value.state_android_enterprise': 'Android Enterprise',
    'company_info.modal.title': 'Consulte su correo electrónico',
    'company_info.modal.text': 'Rellene el código de 6 dígitos que se envió a su correo electrónico',
    'company_info.modal.send_code': 'Reenviar codigo',

    'consumption_profile.data.mode': 'Datos',
    'consumption_profile.dataRoaming.mode': 'Datos Roaming',
    'consumption_profile.dataRoaming.id': 'ID Datos Roaming',

    'consumption_profile.sms.mode': 'SMS',
    'consumption_profile.smsRoaming.id': 'ID SMS',
    'consumption_profile.smsRoaming.mode': 'SMS Roaming',
    'manage_groups.column.name': 'Grupo',
    'devices.infos.title': 'Administrar Dispositivo',
    'devices.infos.sub_title': 'En esta pantalla, se puede acceder a la información del dispositivo.',
    'devices.infos.card_header.title': 'Dispositivo',
    'devices.infos.imei': 'IMEI',
    'devices.infos.mode': 'Modo',
    'devices.infos.identification': 'Identificación',
    'devices.infos.serial_number': 'Número de serie',
    'devices.infos.management_mode': 'Modo de Gestión',
    'devices.infos.appliedState': 'Estado Aplicado',
    'devices.infos.in_accordance': 'En conformidad',
    'devices.infos.register_date': 'Fecha de Registro',
    'devices.infos.last_update': 'Última Fecha de Actualización',
    'devices.infos.last_communication': 'Fecha de la Última Comunicación',
    'devices.infos.date_sync': 'Fecha de Sincronización de Políticas',
    'devices.infos.info_software': 'Información del Software',
    'devices.infos.info_hardware': 'Información de Hardware',
    'devices.infos.android_version': 'Versión de Android',
    'devices.infos.policy_version_applied': 'Versión de la Política Aplicada en el Dispositivo',
    'devices.infos.policy_name_on_device': 'Nombre de la Política Aplicada en el Dispositivo',
    'devices.infos.policy_name_on_portal': 'Nombre de la Política en el Portal',
    'devices.infos.intern_storage': 'Almacenamiento Interno',
    'devices.infos.battery': 'Batería',
    'devices.infos.label_dedicated': 'Dispositivo Dedicado',
    'devices.infos.label_managed': 'Totalmente Administrado',
    'devices.infos.label_ios': 'IOS',
    'devices.infos.label_android': 'Android',
    'devices.infos.status': 'Estado',
    'devices.infos.compliance': 'Conforrme',
    'devices.infos.incompliance': 'No conforme',
    'devices.infos.status_active': 'Activado',
    'devices.infos.status_non_active': 'Desactivado',
    'devices.infos.last_update_tooltip': 'Esta fecha se refiere a la última vez que se actualizó la información del dispositivo.',
    'devices.infos.last_communication_tooltip': 'Esta fecha se refiere a la última vez que el dispositivo se comunicó con el Portal',
    'login.modal.block_user': 'Su acceso ha sido bloqueado por realizar muchos intentos incorrectos. Espere 15 minutos para realizar un nuevo intento.',
    'devices.action.manage_infos': 'Administrar',
    'devices.infos.user': 'Usuario',
    'devices.infos.manufacturer': 'Fabricante',
    'devices.infos.phone': 'Teléfono',
    'devices.infos.model': 'Modelo',
    'devices.infos.policy_sync': 'Fecha de Sincronización de Políticas',
    'devices.infos.non_accordance': 'No Conforme',
    'audit.company.email': 'E-mail',
    'audit.company.authenticatedEmail': 'E-mail Autenticado',
    'audit.company.privacyPolicy': 'Política de Privacidad',
    'audit.company.userTerms': 'Términos de Uso del Usuario',
    'audit.company.corporateName': 'Nombre de la Empresa',
    'audit.company.dataProtectionOfficerName': 'Protección de Datos - Nombre',
    'audit.company.dataProtectionOfficerEmail': 'Protección de Datos - E-mail',
    'audit.company.dataProtectionOfficerPhone': 'Protección de Datos - Teléfono',
    'audit.company.euRepresentativeName': 'Representante en la UE - Nombre',
    'audit.company.euRepresentativeEmail': 'Representante en la UE - Email',
    'audit.company.euRepresentativePhone': 'Representante en la UE - Teléfono',
    'audit.company.federalCode': 'Número de identificación',
    'audit.company.phoneNumber': 'Teléfono',
    'audit.company.contact': 'Contacto',
    'audit.company.address': 'Dirección',
    'audit.company.zipCode': 'Código postal',
    'audit.company.city': 'Ciudad',
    'audit.company.state': 'Estado',
    'audit.company.timezone': 'Zona Horaria',
    'audit.company.createdAt': 'Fecha de Registro',
    'audit.company.startDayOfWeek': 'Día de inicio de semana',
    'audit.company.endDayOfWeek': 'Día de fin de semana',
    'audit.company.startCycleDay': 'Inicio del ciclo',
    'audit.company.syncTime': 'Sincronizar cada',
    'audit.company.notifyByEmail': 'E-mail de Aviso',
    'audit.company.emailToken': 'Token para registro de correo electrónico',
    'audit.company.enterpriseId': 'Código en Google',
    'audit.admin_user.remove': 'Eliminar',
    'audit.accordion_empty': 'No hay cambios disponibles para el usuario',
    'register_policies.ios': 'IOS',
    'register_policies.ios.description': 'Para dispositivos de propiedad de la empresa. Permite la administración de configuraciones y sitios web de dispositivos IOS y controles de política.',
    'manage_policies.data.android_managed': 'Android',
    'manage_policies.data.ios': 'IOS',
    'register_policies.operationalSystem': 'Seleccione el modo de administración de directivas',
    'update_policies.tab.web_sites': 'Sitios',
    'devices.infos.federalCode': 'Identificación',
    'devices.infos.group': 'Grupo',
    'devices.infos.name': 'Usuario',
    'application_manage.column.name': 'Nombre',
    'application_manage.column.quantity': 'Cantidad de usuarios',
    'application_manage.column.consumption': 'Consumo',
    'application_manage.column.time': 'Tiempo de Uso',
    'devices.infos.udid': 'UDID',
    'global.operational_system': 'Sistema Operativo',
    'devices.infos.in_accordance.yes': 'Sí',
    'devices.infos.in_accordance.no': 'No',
    'policy_copy.name': 'Nombre de la nueva política',
    'policy_copy.name.placeholder': 'Ingrese el nombre de la nueva política',
    'update_policies.settings.accordion.sync_backup': 'Sincronización y copia de seguridad',
    'update_policies.settings.accordion.sync_backup.backup.title': 'Permitir copia de seguridad de iCloud',
    'update_policies.settings.accordion.sync_backup.backup.description': 'Esta configuración se eliminará',
    'update_policies.settings.accordion.sync_backup.keychain.title': 'Permitir iCloud Keychain',
    'update_policies.settings.accordion.sync_backup.keychain.description': 'Esta configuración se eliminará',
    'update_policies.settings.accordion.sync_backup.data.title': 'Permitir que las aplicaciones administradas almacenen datos en iCloud',
    'update_policies.settings.accordion.sync_backup.data.description': 'Los usuarios pueden almacenar datos de aplicaciones administradas en iCloud',
    'update_policies.settings.accordion.sync_backup.enterprise_books.title': 'Permitir copias de seguridad de libros corporativos',
    'update_policies.settings.accordion.sync_backup.enterprise_books.description': 'Los usuarios pueden hacer copias de seguridad de libros distribuidos por sus organizaciones',
    'update_policies.settings.accordion.sync_backup.sync_books.title': 'Permitir la sincronización de notas y destacados para libros corporativos',
    'update_policies.settings.accordion.sync_backup.sync_books.description': 'Los usuarios pueden sincronizar notas o puntos destacados con otros dispositivos a través de iCloud',
    'update_policies.settings.accordion.sync_backup.photo.title': 'Permitir fotos de iCloud',
    'update_policies.settings.accordion.sync_backup.photo.description': 'Los usuarios pueden acceder a las fotos de iCloud.',
    'update_policies.settings.accordion.sync_backup.photo_menu.title': 'Permitir Mi Compartir fotos (no permitir puede causar pérdida de datos)',
    'update_policies.settings.accordion.sync_backup.photo_menu.description': 'Las fotos del rollo de la cámarase envían a Mi Compartir y las fotos y videos de álbumes compartidos ya no se pueden ver en el dispositivo.',
    'update_policies.settings.accordion.sync_backup.encrypted_backup.title': 'Forzar copias de seguridad cifradas',
    'update_policies.settings.accordion.sync_backup.encrypted_backup.description': 'Los usuarios pueden elegir si las copias de seguridad del dispositivo se almacenan en formato cifrado. Si un perfil está cifrado y esta opción está desactivada, se requiere y se aplica el cifrado de copias de seguridad',
    'update_policies.settings.accordion.sharing': 'Cámara y compartir pantalla',
    'update_policies.settings.accordion.sharing.allow_remote_screen_observation.title': 'Permitir AirPlay, ver pantalla por aula y compartir pantalla',
    'update_policies.settings.accordion.sharing.allow_remote_screen_observation.description': 'Los estudiantes en clases administradas son avisados cuando el profesor usa AirPlay o Ver pantalla',
    'update_policies.settings.accordion.sharing.allow_screen_shot.title': 'Permitir captura y grabación de pantalla',
    'update_policies.settings.accordion.sharing.allow_screen_shot.description': 'Los usuarios pueden guardar una captura o grabación de la pantalla',
    'update_policies.settings.accordion.sharing.allow_camera.title': 'Permitir el uso de la cámara',
    'update_policies.settings.accordion.sharing.allow_camera.description': 'Todas las cámaras del dispositivo están habilitadas',
    'update_policies.settings.accordion.restriction': 'Restricciones Diversas',
    'update_policies.settings.accordion.restriction.force_limit_ad_tracking.title': 'Evitar que las aplicaciones soliciten el seguimiento',
    'update_policies.settings.accordion.restriction.force_limit_ad_tracking.description': 'Los servicios de ubicación con aplicaciones están bloqueados',
    'update_policies.settings.accordion.restriction.allow_apple_personalized_advertising.title': 'Permitir anuncios personalizados proporcionados por Apple',
    'update_policies.settings.accordion.restriction.allow_apple_personalized_advertising.description': 'La desactivación de los anuncios personalizados limitará la capacidad de Apple para mostrarte contenido relevante.',
    'update_policies.settings.accordion.restriction.allow_enterprise_app_trust.title': 'Permitir nuevos autores de aplicaciones empresariales de confianza',
    'update_policies.settings.accordion.restriction.allow_enterprise_app_trust.description': 'Este método es seguro y la aplicación se instala a través del portal de administración de dispositivos móviles',
    'update_policies.settings.accordion.restriction.allow_open_from_unmanaged_to_managed.title': 'Permitir documentos de orígenes administrados en destinos no administrados',
    'update_policies.settings.accordion.restriction.allow_open_from_unmanaged_to_managed.description': 'Los documentos creados o transferidos de fuentes administradas no se pueden abrir en destinos no administrados',
    'update_policies.settings.accordion.restriction.force_air_drop_unmanaged.title': 'Tratar AirDrop como destino no administrado',
    'update_policies.settings.accordion.restriction.force_air_drop_unmanaged.description': 'Los usuarios ven AirDrop como una opción en una aplicación administrada.   Para que esta restricción funcione cuando esté activada, también se desactivará la opción "Permitir documentos de orígenes administrados en destinos no administrados"',
    'update_policies.settings.accordion.restriction.allow_diagnostic_submission.title': 'Permitir el envío de datos de diagnóstico y uso a Apple',
    'update_policies.settings.accordion.restriction.allow_diagnostic_submission.description': 'Al habilitar, Apple puede compartir datos y estadísticas sobre el uso de estas aplicaciones',
    'update_policies.settings.accordion.restriction.force_watch_wrist_detection.title': 'Forzar la detección de pulso del Apple Watch',
    'update_policies.settings.accordion.restriction.force_watch_wrist_detection.description': 'Al sacar el reloj de la muñeca, se bloquea automáticamente para proteger sus datos',
    'update_policies.settings.accordion.restriction.allow_in_app_purchases.title': 'Permitir la compra en aplicaciones',
    'update_policies.settings.accordion.restriction.allow_in_app_purchases.description': '                Permitir a los usuarios realizar compras dentro de la aplicación',
    'update_policies.settings.accordion.restriction.force_itunes_store_password_entry.title': 'Requerir contraseña de iTunes Store para todas las compras',
    'update_policies.settings.accordion.restriction.force_itunes_store_password_entry.description': 'Las compras dentro de la aplicación y las compras de iTunes Store piden la contraseña de la cuenta',
    'update_policies.settings.accordion.assistant': 'Asistente',
    'update_policies.settings.accordion.assistant.allow_assistant.title': 'Permitir Siri',
    'update_policies.settings.accordion.assistant.allow_assistant.description': 'Permite usar Siri',
    'update_policies.settings.accordion.assistant.allow_assistant_while_locked.title': 'Permitir Siri mientras el dispositivo está bloqueado',
    'update_policies.settings.accordion.assistant.allow_assistant_while_locked.description': 'Siri responde incluso cuando el dispositivo está bloqueado',
    'update_policies.settings.accordion.assistant.allow_spotlight_internet_results.title': 'Permitir sugerencias de Siri',
    'update_policies.settings.accordion.assistant.allow_spotlight_internet_results.description': 'Durante la búsqueda, Siri puede ofrecer sugerencias de apps, personas, ubicaciones y otros',

    // Antigo Master
    'register_policies.managed_mode': 'Totalmente Administrado',
    'register_policies.managed_mode.description': 'Para dispositivos de propiedad de la empresa. Permite la gestión de una amplia gama de configuraciones de dispositivos y controles de políticas.',
    'manage_policies.filter.policy_type.dedicaded': 'Dispositivos Dedicados',
    'manage_policies.filter.policy_type.managed': 'Totalmente Administrado',
    'devices.action.more_infos': 'Administrar',

    // Novos
    'register_policies.android_block_sim': 'Android - Block SIM',
    'update_policies.settings.accordion.block_screen': 'Pantalla de Bloqueo',
    'register_policies.android_block_sim.description': 'Permite la gestión de la configuración de los dispositivos Android y controles de política. Agrega automáticamente la aplicación Block SIM, restringe los cambios de contraseña del dispositivo y garantiza el vínculo de la tarjeta SIM al dispositivo.',
    'update_policies.settings.accordion.show_control_center_while_locked.title': 'Mostrar el centro de control en la pantalla de bloqueo',
    'update_policies.settings.accordion.show_control_center_while_locked.description': 'Los usuarios pueden acceder al Centro de control con la pantalla bloqueada',
    'update_policies.settings.accordion.show_notification_center_while_locked.title': 'Mostrar el centro de notificaciones en la pantalla de bloqueo',
    'update_policies.settings.accordion.show_notification_center_while_locked.description': 'Los usuarios pueden ver el historial de notificaciones con la pantalla bloqueada',
    'update_policies.settings.accordion.allow_voice_dialing.title': 'Permitir marcar por voz cuando el dispositivo se encuentre bloqueado',
    'update_policies.settings.accordion.allow_voice_dialing.description': 'Los usuarios pueden usar comandos de voz para hacer llamadas cuando el dispositivo está con la pantalla bloqueada',
    'update_policies.settings.accordion.show_today_view_while_locked.title': 'Mostrar visualización hoy en la pantalla de bloqueo',
    'update_policies.settings.accordion.show_today_view_while_locked.description': 'Los usuarios pueden usar el gesto de deslizar el dedo hacia abajo para ver el Centro de notificaciones a través de la Visualización Hoy en la Pantalla Bloqueada.',
    'update_policies.settings.accordion.allow_touch_id_face_id.title': 'Permitir Touch ID/ Face ID para desbloquear dispositivo',
    'update_policies.settings.accordion.allow_touch_id_face_id.description': 'Los usuarios pueden usar la autenticación biométrica para completar automáticamente los datos de la aplicación',
    'device.modal.label': 'Seleccione la nueva política - {managedMode}',
    'update_policies.tab.sites': 'Sitios Web',
    'update_policies.sites.block_all_title': 'Bloquear Todos los Sitios',
    'update_policies.sites.block_all_subtitle': 'Aquí puede bloquear el acceso a todos los sitios web de su IOS.',
    'update_policies.sites.block_keyword_title': 'URLs y palabras clave Bloqueadas y Liberadas',
    'update_policies.sites.block_keyword_subtitle': 'Aquí puedes ver todos los sitios web y palabras clave bloqueados',
    'update_policies.sites.block_category_title': 'Bloqueo de Sitios por Categoría',
    'update_policies.sites.block_category_subtitle': 'Seleccione las categorías de sitios web a los que se bloqueará el acceso',
    'devices.operational_system': 'Sistema Operativo',
    'devices.value.active': 'Activo',
    'devices.value.disabled': 'Deshabilitado',
    'devices.value.deleted': 'Eliminado',
    'devices.value.provisioning': 'Aprovisionado',
    'devices.value.await_enterprise_enroll': 'Esperando Android Enterprise',
    'devices.infos.iccid': 'ICCID',
    'menu.settings.register_policy': 'Política de Registro IOS',
    'entity.policy.defaultName': 'Política de Registro',
    'error.iosPolicy.isInitial.delete': 'No es permitido eliminar política de registro. ',
    'exception.setting.allowDeviceIOS': 'Confguración para iOS está disactivada',
    'policy.block_sim': 'Block SIM',
    'devices.action.remove_device': 'Quitar Dispositivo',
    'manage_policies.filter.management_mode': 'Modo de Gestión',
    'manage_policies.filter.management_mode.android': 'Android',
    'manage_policies.filter.management_mode.ios': 'IOS',
    'manage_policies.filter.management_mode.block_sim': 'Android - Block SIM',
    'companies_list.title': 'Relación de las Empresas',
    'companies_list.description': 'Loren xxx',
    'companies_list.filter.terms_of_usage': 'Aceptado de Términos',
    'companies_list.filter.placeholder': 'Seleccione una opción',
    'companies_list.filter.start_at': 'Fecha de inicio',
    'companies_list.filter.end_at': 'Fecha final',
    'companies_list.button.select_company': 'Acceder al portal',
    'companies_list.alert.confirm': 'Acceder  ',
    'companies_list.alert.text': '¿Desea acceder a {companyName} ?',
    'header.settings.help_desk.option_change_company': 'Cambiar de Empresa',
    'global.unauthorized.message': 'El usuario No Tiene El Privilegio De Acceder A Esta Información',
    'devices.action.remove_screen_block': 'Quitar Bloqueo de Pantalla',
    'devices.alert.remove_screen_block.text': '¿Realmente desea eliminar el bloqueo de pantalla de este dispositivo?',
    'companies_list.filter.title': 'Fecha de creación de la Empresa',
    'menu.zero_touch.manage_notes': 'Gestionar Facturas',
    'menu.zero_touch.manage': 'Administrar Zero Touch',
    'manage_invoice.title': 'Gestionar Facturas',
    'manage_invoice.description': 'En esta pantalla es posible gestionar las facturas y acceder a los detalles para el registro en Zero Touch.',
    'manage_invoice.filter': 'Fecha de importación de la factura',
    'manage_invoice.start_date': 'Fecha de inicio',
    'manage_invoice.end_date': 'Fecha final',
    'manage_invoice.import': 'Importar Factura',
    'manage_invoice.menu_action.details': 'Detalles de la Factura',
    'manage_invoice.toaster.customer_email.success': 'ID Customer registrado con éxito. Acceda al correo electrónico informado para validar la solicitud',
    'invoice_details.warning_exit': 'Existen datos que no se han guardado y se perderán. ¿Desea continuar?',
    'invoice_details.title': 'Detalles de la Factura',
    'invoice_details.description': 'En esta pantalla puede ver los detalles de la factura',
    'invoice_details.access_key': 'Clave de Acceso',
    'invoice_details.filter.manufacturer': 'Fabricante',
    'invoice_details.filter.model': 'Modelo',
    'invoice_details.filter.imei': 'IMEI',
    'invoice_details.filter.place_holder': 'Seleccione una opción',
    'invoice_details.button.save': 'Guardar',
    'invoice_details.button.register': 'Solicitar Registro',
    'invoice.device_status.registered': 'Registrado',
    'invoice.device_status.not_registered': 'No registrado',
    'invoice_details.info_update.success': 'Información guardada con éxito',
    'manage_invoice.toaster.success': 'Factura importada con éxito.',
    'modal_customer_id.customerId_title': 'Registro de ID Customer',
    'modal_customer_id.customerId_description': 'Para registrar los dispositivos en Zero Touch, es necesario proporcionar el ID Custumer registrado con el revendedor {resellerName}.',
    'modal_customer_id.input.customerId.placeholder': 'Ingrese su ID Customer',
    'modal_customer_id.customerId.button': 'Acceder',
    'modal_customer_id.customerId.link_button': '¿No tienes un ID Customer?',
    'modal_customer_id.input.customerId': 'ID Customer',
    'modal_customer_id.customerEmail_title': 'Registro de ID Customer',
    'modal_customer_id.customerEmail_description': 'Para registrar los dispositivos en Zero Touch es necesario crear el ID Custumer. Indique el correo electrónico para recibir las pautas de registro de su ID Customer.',
    'modal_customer_id.input.customerEmail': 'Correo electrónico',
    'modal_customer_id.input.customerEmail.placeholder': 'Escriba su correo electrónico',
    'modal_customer_id.customerEmail.button': 'Enviar Correo electrónico',
    'modal_customer_id.customerEmail.link_button': '¿Tienes una identificación de cliente?',
    'modal_customer_id.button.do_later': 'Informar más tarde',
    'invoice.register_zero_touch_success': 'Solicitud de Registro Zero Touch enviada',
    'modal_customer_id.error_message.bad_request': 'La información introducida es incorrecta.',
    'modal_customer_id.error_message.connection_error': 'Fallo de comunicación del servidor Importa IMEI',
    'modal_customer_id.customerEmail.valid_gmail': 'Ingrese un Gmail válido',
    'global.copy.click_to_copy': 'Haga clic para copiar',
    'invoice_details.column.description': 'Descripción del producto',
    'company_info.accessCode': 'Código de Acceso',
    'dashboard.total_licenses': 'Total de licencias',
    'update_policies.settings.accordion.hardware.outgoing_beam_disabled.title': 'Bloquear el envío de datos a través de NFC',
    'update_policies.settings.accordion.hardware.outgoing_beam_disabled.description': 'El envío de datos a través de NFC está deshabilitado',
    'update_policies.settings.accordion.safety.frp_admin_emails_enable.title': 'Habilitar el correo electrónico de administrador para FRP',
    'update_policies.settings.accordion.safety.frp_admin_emails_enable.description': 'Requerirá el correo electrónico indicado para ejecutar factory reset',
    'update_policies.settings.accordion.safety.frp_admin_emails.title': 'Correo de protección de restablecimiento de fábrica (FRP)',
    'update_policies.settings.accordion.safety.frp_admin_emails.description': 'Define el correo electrónico que será solicitado en el dispositivo, después que se restaura a la configuración de fábrica',
    'global.valid_g_email': 'Ingrese un Gmail válido',
    'update_policies.settings.accordion.lockScreen.disable_all_features.title': 'Desactivar todas las configuraciones',
    'update_policies.settings.accordion.lockScreen.disable_all_features.description': 'Todas las personalizaciones del bloqueo de pantalla están desactivadas. ',
    'update_policies.settings.accordion.vpn': 'VPN',
    'update_policies.settings.accordion.vpn.vpn.title': 'Bloquear la configuración de VPN',
    'update_policies.settings.accordion.vpn.vpn.description': 'Configuración de VPN deshabilitada',
    'update_policies.settings.accordion.vpn.lockdown_enabled.title': 'Bloquear la red cuando la VPN no está conectada',
    'update_policies.settings.accordion.vpn.lockdown_enabled.description': 'Define el tráfico de red según la configuración de la VPN.',
    'update_policies.settings.accordion.vpn.application.title': 'Aplicación de VPN',
    'update_policies.settings.accordion.vpn.application.description': 'Ioren xx',
    'update_policies.frpAdminEmails': 'E-mail del administrador a FRP',
    'update_policies.settings.accordion.safety.modify_accounts_manager.title': 'Bloquear cambio de cuentas',
    'update_policies.settings.accordion.safety.modify_accounts_manager.description': 'La opción de agregar o quitar cuentas está desactivada.',
    'update_policies.settings.accordion.user': 'Usuario',
    'update_policies.settings.accordion.user.skip_first_use_hints_enabled.title': 'Deshabilitar consejos del primer uso',
    'update_policies.settings.accordion.user.skip_first_use_hints_enabled.description': 'Tutorial de usuario y otros consejos introductorios en el primer inicio aplicaciones está desactivada.',
    'update_policies.settings.accordion.user.set_wallpaper_disabled.title': 'Deshabilitar el cambio de fondo de pantalla',
    'update_policies.settings.accordion.user.set_wallpaper_disabled.description': 'Cambio de fondo de pantalla desactivado.',
    'update_policies.settings.accordion.user.set_user_icon_disabled.title': 'Desactivar el cambio de icono de usuario',
    'update_policies.settings.accordion.user.set_user_icon_disabled.description': 'Cambio de icono de usuario desactivado.',
    'update_policies.settings.accordion.user.credentials_config_disabled.title': 'Deshabilitar la configuración de credenciales de usuario',
    'update_policies.settings.accordion.user.credentials_config_disabled.description': 'Configuración de credenciales de usuario está deshabilitada.',
    'update_policies.minimumApiLevel': 'Nivel Mínimo de la API de Android',
    'update_policies.settings.accordion.network.sms_disabled.title': ' Bloquear la aplicación de mensajes SMS nativa',
    'update_policies.settings.accordion.network.sms_disabled.description': 'Aplicación nativa para envío y recepción de mensajes SMS está desactivada.',
    'update_policies.settings.accordion.network.cell_broadcasts_config_disabled.title': 'Deshabilitar la Configuración de Broadcasts de Celular',
    'update_policies.settings.accordion.network.cell_broadcasts_config_disabled.description': 'La configuración de transmisión celular está desactivada.',
    'update_policies.systemUpdate.minimum_api_level.title': 'Nivel mínimo de la API de Android',
    'update_policies.systemUpdate.minimum_api_level.description': 'Nivel mínimo de API de Android permitido en el dispositivo.',
    'update_policies.settings.accordion.safety.default_permission_policy.title': 'Política de permiso',
    'update_policies.settings.accordion.safety.default_permission_policy.description': 'Establece la política de permiso predeterminada para solicitudes de permiso en aplicaciones.',
    'update_policies.settings.accordion.safety.default_permission_policy.select.prompt': 'Solicitar permiso',
    'update_policies.settings.accordion.safety.default_permission_policy.select.grant': 'Permitir automáticamente',
    'update_policies.settings.accordion.safety.default_permission_policy.select.deny': 'Denegar automáticamente',
    'update_policies.systemUpdate.auto_date_and_time_zone.defined_by_user': 'Definido por el usuario',
    'update_policies.systemUpdate.auto_date_and_time_zone.automatic': 'Automático',
    'update_policies.systemUpdate.auto_date_and_time_zone.title': 'Configurar fecha y hora automáticas',
    'update_policies.systemUpdate.auto_date_and_time_zone.description': 'Indica si el horario automático es obligatorio, lo que impide que el usuario establezca manualmente la fecha y la hora.',
    'update_policies.settings.accordion.safety.encryption_policy.title': 'Política de cifrado',
    'update_policies.settings.accordion.safety.encryption_policy.description': 'Política de cifrado necesaria.',
    'update_policies.settings.accordion.safety.encryption_policy.select.disabled': 'Deshabilitado',
    'update_policies.settings.accordion.safety.encryption_policy.select.enabled_without_password': 'Activa sin contraseña',
    'update_policies.settings.accordion.safety.encryption_policy.select.enabled_with_password': 'Activa con contraseña',
    'menu.manage_zero_touch_register': 'Administrar registro Zero Touch',
    'modal_import_invoice.title': 'Importar Facturas',
    'modal_import_invoice.import_button': 'Importar',
    'modal_import_invoice.input.label': 'Clave de acceso',
    'modal_import_invoice.input.placeholder': 'Ingrese la clave de acceso',
    'modal_import_invoice.label.uploda_button': 'Importar XML',
    'manage_zero_touch_register.title': 'Administrar registro Zero Touch',
    'manage_zero_touch_register.description': 'En esta pantalla si puede gestionar las solicitudes de registro en Zero Touch a través de la función "Importar IMEI"',
    'manage_zero_touch_register.error_message.bad_request': 'No fue posible buscar los registros',
    'manage_zero_touch_register.column.company': 'Empresa',
    'manage_zero_touch_register.column.cnpj': 'CNPJ',
    'manage_zero_touch_register.column.invoice_number': 'Factura',
    'manage_zero_touch_register.column.pending_devices': 'Ítems Pendientes ',
    'manage_invoice.error_message.invalid_federal_code': 'No se pudo registrar la empresa, CNPJ no es válido',
    'manage_invoice.error_message.fail_create_company': 'Error al intentar registrar la empresa en Zero Touch',
    'manage_invoice.error_message.fail_create_invoice': 'Error al intentar importar la factura ',
    'manage_invoice.error_message.fail_create_invoice_duplicate': 'No fue posible registrar la empresa, factura duplicada',
    'manage_invoice.error_message.fail_create_invoice_cnpj': 'El CNPJ del remitente debe corresponder al CNPJ informado en el registro de la empresa',
    'manage_invoice.error_message.fail_create_invoice_cnpj_unauthorized': 'El CNPJ del remitente debe corresponder al CNPJ informado en el registro de la empresa',
    'manage_invoice.error_message.fail_create_invoice_not_found': 'Factura no localizada, revise la información e inténtelo de nuevo',
    'manage_invoice.error_message.unauthorized': 'Usuario sin privilegios para acceder a la funcionalidad Importar IMEI',

    'manage_zero_touch_register.start_date': 'Fecha de inicio',
    'manage_zero_touch_register.end_date': 'Fecha final',
    'manage_zero_touch_register.zero_touch_pending': 'Pendiente Zero Touch',
    'manage_zero_touch_register.zero_touch_pending.yes': 'Sí',
    'manage_zero_touch_register.zero_touch_pending.all': 'Todo',
    'invoice_details.device_status.waiting': 'Esperando Aprobación',
    'invoice_details.device_status.reject': 'Rechazado',
    'invoice_details.device_status.not_registered': 'No registrado',
    'invoice_details.device_status.disapprove': 'Reprobado Zero Touch',
    'invoice_details.device_status.registered': 'Registrado',
    'invoice_details.device_status.approved': 'Aprobado',
    'manage_zero_touch_register.menu_action.details': 'Detalles de la solicitud',
    'zero_touch_register_details.title': 'Detalles de la Solicitud',
    'zero_touch_register_details.description': 'En esta pantalla puede administrar los detalles de las solicitudes de registro en Zero Touch.',
    'zero_touch_register_details.error_message.bad_request': 'No fue posible buscar los datos de la factura',
    'zero_touch_register_details.company_name': 'Nombre de la empresa',
    'zero_touch_register_details.federal_code': 'CNPJ',
    'zero_touch_register_details.access_key': 'Clave de acceso',
    'zero_touch_register_details.column.model': 'Modelo',
    'zero_touch_register_details.column.manufacturer': 'Fabricante',
    'zero_touch_register_details.column.imei': 'IMEI',
    'zero_touch_register_details.column.status': 'Estatus',
    'zero_touch_register_details.column.observation': 'Observación',
    'zero_touch_register_details.actions.approve': 'Aprobar',
    'zero_touch_register_details.actions.reject': 'Rechazar',
    'invoice_details.tab.model_details': 'IMEI Modelo',
    'invoice_details.tab.devices_details': 'Todos los artículos',
    'invoice_details.model_details.title': 'Escriba el IMEI Modelo',
    'invoice_details.model_details.description': 'Es necesario informar un IMEI correspondiente a cada modelo para seguir con las validaciones y registro de los dispositivos identificados en la factura',
    'invoice_details.model_details.button.submit': 'Concluir',
    'invoice_details.model_details.input.imei': 'IMEI Modelo',
    'invoice_details.model_details.input.imei_error': 'Imei inválido',
    'invoice_details.model_details.input.description': 'Descripción del ítem',
    'invoice_details.model_details.input.model_and_manufacturer': 'Modelo y fabricante',
    'invoice_details.model_details.input.model_and_manufacturer.placeholder': 'Escriba un Imei modelo',
    'invoice_details.info_update.error': 'No se puede actualizar la información, compruebe los campos rellenados y vuelva a intentarlo',
    'invoice_details.devices_details.view_observation': 'Visualizar la observación',
    'invoice_details.devices_details.observation_modal.title': 'Observación del producto',
    'invoice_details.devices_details.observation_modal.empty_observation': 'No se ha informado de ninguna justificación',
    'invoice.error_message.fail_update_item': 'No fue posible actualizar los ítems, verifique la información llenada e inténtelo de nuevo.',
    'customer_id.error_message.fail_create_account': 'Error al intentar crear la cuenta, por favor revise la información e inténtelo de nuevo.',
    'customer_id.error_message.invalid_email': 'Error al realizar la acción, correo electrónico no válido.',
    'customer_id.error_message.invalid_customer_id': 'ID Customer informado no es válido, compruebe el ID Customer informado y si está vinculado a este distribuidor.',
    'customer_id.error_message.requested_email': 'requested_email',
    'customer_id.error_message.company_not_found': 'Error al ejecutar la acción, empresa no encontrada.',
    'zero_touch_register_details.alert.confirm_button.approve': 'Aprobar',
    'zero_touch_register_details.alert.message_alert.approve': '¿Seguro que desea aprobar todos los dispositivos seleccionados?',
    'zero_touch_register_details.alert.reject_button': 'Rechazar',
    'zero_touch_register_details.alert.message_alert.reject': '¿Seguro que desea rechazar todos los dispositivos seleccionados?',
    'zero_touch_register_details.filter.status_placeholder': 'Seleccione un estado',
    'update_policies.settings.accordion.lockScreen.maximum_time_to_lock.select.fifteen_seconds': '15 segundos',
    'update_policies.settings.accordion.lockScreen.maximum_time_to_lock.select.thirty_seconds': '30 segundos',
    'update_policies.settings.accordion.lockScreen.maximum_time_to_lock.select.one_minute': '1 minuto',
    'update_policies.settings.accordion.lockScreen.maximum_time_to_lock.select.two_minutes': '2 minutos',
    'update_policies.settings.accordion.lockScreen.maximum_time_to_lock.select.five_minutes': '5 minutos',
    'update_policies.settings.accordion.lockScreen.maximum_time_to_lock.select.ten_minutes': '10 minutos',
    'update_policies.settings.accordion.lockScreen.maximum_time_to_lock.select.thirty_minutes': '30 minutos',
    'update_policies.settings.accordion.lockScreen.maximum_time_to_lock.select.nothing': 'Ninguno',
    'update_policies.settings.accordion.lockScreen.maximum_time_to_lock.title': 'Tiempo máximo para bloquear la pantalla',
    'update_policies.settings.accordion.lockScreen.maximum_time_to_lock.description': 'Define el tiempo máximo para que el usuario realice una actividad antes de que el dispositivo se bloquee.',
    'update_policies.settings.accordion.user.permitted_input_methods.title': 'Política para métodos de entrada',
    'update_policies.settings.accordion.user.permitted_input_methods.description': 'Si se establece un paquete, solo se permiten los métodos de entrada proporcionados por los paquetes. Si no define un paquete, solo se permitirán los métodos de entrada del sistema.',
    'update_policies.settings.accordion.lock_screen': 'Pantalla de Bloqueo',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_camera.title': 'Desactivar cámara',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_camera.description': 'La cámara es desactivada en la pantalla de bloqueo.',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_notifications.title': 'Desactivar notificaciones',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_notifications.description': 'La visualización de todas las notificaciones en las pantallas de protección de teclado seguro están desactivadas.',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_unredacted_notifications.title': 'Desactivar las notificaciones editadas',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_unredacted_notifications.description': 'Las notificaciones no editadas en pantallas de protección seguras son desactivadas.',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_trust_agents.title': 'Desactivar agente de confianza',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_trust_agents.description': 'Ignora el estado del agente de confianza en pantallas de protección de teclado seguras.',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_finger_print.title': 'Desactivar sensor de huellas dactilares',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_finger_print.description': 'El sensor de huellas dactilares en las pantallas de protección de teclado está desactivado.',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_remote_input.title': 'Desactivar la edición de texto en las notificaciones',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_remote_input.description': 'En dispositivos con Android 6 o versiones anteriores, desactiva la entrada de texto en notificaciones en pantallas de bloqueo de teclado. No tiene efecto en Android 7 y versiones más recientes.',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_face_auth.title': 'Desactivar la autenticación facial',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_face_auth.description': 'La autenticación facial en pantallas de protección seguras es desactivada.',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_iris_auth.title': 'Desactivar autenticación de iris',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_iris_auth.description': 'La autenticación de la iris en pantallas de protección de teclado seguras es desactivada.',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_biometric_auth.title': 'Desactivar autenticación biométrica',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_biometric_auth.description': 'La autenticación biométrica en las pantallas de protección de teclado seguro es desactivada.',

    'global.show_all': 'Mostrar todos',
    'global.show_less': 'Mostrar menos',
    'invoice_details.device_status.approved_zero_touch': 'Registrado Zero Touch',
    'update_policies.settings.accordion.user.permitted_accessibility_services.title': 'Política de servicios de accesibilidad',
    'update_policies.settings.accordion.user.permitted_accessibility_services.description': 'Define los servicios de accesibilidad permitidos. Si se especifica un paquete, solo se podrán utilizar los servicios de accesibilidad de esa lista y el servicio de accesibilidad integrado del sistema. Si no se especifica un paquete, solo se podrán utilizar los servicios de accesibilidad integrados del sistema.',
    'update_policies.settings.accordion.network.recommended_global_proxy.title': 'Configuración de Proxy ',
    'update_policies.settings.accordion.network.recommended_global_proxy.description': 'Seleccione la opción de proxy que tiene.  Para proxy manual, proporcione la información de hosts y puertas intermediarias hacía internet. Si cuentas con una solución de proxy automático (PAC), proporcione la URL de los servidores. ',
    'update_policies.settings.accordion.network.recommended_global_proxy.manual': 'Configuración Manual',
    'update_policies.settings.accordion.network.recommended_global_proxy.automatic': 'Configuración automática - PAC',
    'update_policies.settings.accordion.network.recommended_global_proxy.pac_uri': 'Pac URL',
    'update_policies.maximumTimeToLock': 'Tiempo máximo para apagar la pantalla',
    'update_policies.settings.accordion.network.recommended_global_proxy.host': 'Host',
    'update_policies.settings.accordion.network.recommended_global_proxy.port': 'Puerta',
    'update_policies.settings.accordion.network.recommended_global_proxy.ignored_hosts': 'Lista de hosts ignorados',
    'update_policies.settings.accordion.lockScreen.stay_on_plugged_modes.title': 'Mantener el dispositivo siempre encendido',
    'update_policies.settings.accordion.lockScreen.stay_on_plugged_modes.description': 'Define el modo de la batería conectada en el que el dispositivo permanecerá encendido. Al utilizar esta configuración, se recomienda que la configuración "Tiempo máximo para bloquear la pantalla" esté seleccionada en la opción "Ninguno" para que el dispositivo no se bloquee mientras permanezca encendido.',
    'update_policies.settings.accordion.lockScreen.stay_on_plugged_modes.select.ac': 'AC - Cargador de CA',
    'update_policies.settings.accordion.lockScreen.stay_on_plugged_modes.select.usb': 'USB - Puerto USB',
    'update_policies.settings.accordion.lockScreen.stay_on_plugged_modes.select.wireless': 'WIRELESS - Sin hilos',
    'update_policies.settings.accordion.network.recommended_global_proxy.none': 'Ninguno',
    'update_policies.settings.accordion.user.account_types_with_management_disabled.title': 'Tipos de cuentas con administración desactivada',
    'update_policies.settings.accordion.user.account_types_with_management_disabled.description': 'Tipos de cuenta que no pueden ser administrados por el usuario.',
    'update_policies.settings.accordion.user.account_types_with_management_disabled.placeholder': 'Seleccione o agregue una opción',
    'global.creatable.add_new_option': 'Añadir nueva opción: {inputValue}',
    'update_policies.settings.accordion.safety.private_key_selection_enabled.title': 'Habilitar selección de clave privada',
    'update_policies.settings.accordion.safety.private_key_selection_enabled.description': 'Permite mostrar la interfaz en el dispositivo para que el usuario elija un alias de clave privada si no se han definido reglas de claves privadas. En dispositivos anteriores a Android 9, esta configuración puede dejar las claves empresariales vulnerables.',
    'update_policies.settings.accordion.vpn.lockdown_enabled.placeholder': 'Seleccione una aplicación',
    'update_policies.settings.accordion.vpn.package_name.title': 'Aplicaciones de VPN',
    'update_policies.settings.accordion.vpn.package_name.description': 'Configuración para una conexión VPN siempre activa. Agregue la aplicación VPN al perfil para verla en la lista.',
    'update_policies.alwaysOnVpnPackage.packageName': 'Aplicaciones de VPN',
    'update_policies.permittedInputMethods': 'Política para métodos de entrada',
    'update_policies.permittedAccessibilityServices': 'Política de servicios de accesibilidad',
    'update_policies.recommendedGlobalProxy.pacUri': 'Pac URL',
    'update_policies.permittedAccessibilityServices.arr': 'Política de servicios de accesibilidad',
    'update_policies.permittedInputMethods.arr': 'Política para métodos de entrada',
    'input_keywords.error.key_existing_alert': 'Palabra clave ya existente.',
    'input_keywords.error.key_not_valid_alert': 'La palabra clave insertada no es un valor válido.',
    'update_policies.keyword_error.start_only_with_letters': 'La palabra clave solo puede comenzar con letras',
    'update_policies.settings.accordion.safety.choose_private_key_rule.title': 'Reglas de elección de clave privada',
    'update_policies.settings.accordion.safety.choose_private_key_rule.description': 'Identifique la regla que permite leer la clave privada.',
    'update_policies.settings.accordion.safety.choose_private_key_rule.add_button': '+ Añadir    ',
    'update_policies.settings.accordion.safety.choose_private_key_rule.add_modal.title': 'Agregar Regla de Elección de Clave Privada',
    'update_policies.settings.accordion.safety.choose_private_key_rule.add_modal.private_key_alias': 'Nombre (Alias)',
    'update_policies.settings.accordion.safety.choose_private_key_rule.add_modal.url_pattern': 'URLPattern',
    'update_policies.settings.accordion.safety.choose_private_key_rule.add_modal.save': 'Guardar',
    'update_policies.settings.accordion.safety.choose_private_key_rule.add_modal.package_names': 'Aplicacion',
    'update_policies.settings.accordion.safety.choose_private_key_rule.add_modal.add_package_name': 'Añadir',
    'update_policies.settings.accordion.safety.choose_private_key_rule.add_modal.same_alias_error': 'Ya existe una regla con este nombre',
    'update_policies.settings.accordion.safety.choose_private_key_rule.edit_button': 'Editar',
    'update_policies.settings.accordion.safety.choose_private_key_rule.delete_button': 'Eliminar',
    'update_policies.choosePrivateKeyRules.arr.urlPattern': 'URLPattern',
    'update_policies.choosePrivateKeyRules.arr.privateKeyAlias': 'Nombre (Alias)',
    'update_policies.choosePrivateKeyRules.arr': 'Reglas de Elección de Clave Privada',
    'update_policies.recommendedGlobalProxy.port': 'Puerta',
    'update_policies.recommendedGlobalProxy.host': 'Host',
    'modal_customer_id.customerId.subscribe_button_text': 'Haga clic aquí para registrarse.',
    'customer_id.error_message.fail_create_invoice_without_device': 'No se ha identificado dispositivo móvil en la factura. Compruebe e intente de nuevo.',
    'update_policies.settings.accordion.network.recommended_global_proxy.empty_list': 'No Añadido Ningún Proxy.',
    'update_policies.settings.accordion.user.permitted_input_methods.empty_list': 'No se agregó ningún método de entrada.',
    'update_policies.settings.accordion.user.permitted_accessibility_services.empty_list': 'Ningún servicio de accesibilidad añadido.',
    'modal_customer_id.customerToken_title': 'Vincular ID Customer - Validación del Correo Electrónico',
    'modal_customer_id.customerToken_description': 'Escriba el Token enviado al correo electrónico {email}',
    'modal_customer_id.customerToken.button': 'Validar',
    'modal_customer_id.customerToken.link_button': 'Cambiar el correo electrónico',
    'modal_customer_id.customerToken.secondary_button': 'Reenviar correo electrónico',
    'modal_customer_id.input.customerToken': 'Token',
    'global.valid_email': 'Escriba un correo electrónico válido',
    'manage_invoice.error_message.server_error': 'No se pudo conectar al servicio Importar IMEI',
    'manage_invoice.error_message.default_error': 'No se pudo conectar al servicio Importar IMEI',
    'invoice_details.approve_modal.title': '¿Seguro que desea aprobar todos los dispositivos seleccionados?',
    'customer_id.error_message.invalid_token': 'Token informado no es válido, inténtelo de nuevo',
    'invoice_details.model_details.input.manufacturer': 'Fabricante',
    'invoice_details.model_details.input.model': 'Modelo',
    'zero_touch_register_details.error_message.fail_device_invalid_imei': 'IMEI informado es inválido',
    'zero_touch_register_details.error_message.fail_device_invalid_imei_model': 'IMEI informado es diferente del IMEI Modelo',
    'zero_touch_register_details.error_message.fail_device_invalid_model': 'Modelo introducido no es válido',
    'zero_touch_register_details.error_message.fail_device_duplicate_imei': 'IMEI informado ya existe',
    'manage_zero_touch_register.release_date': 'Fecha de envío del IMEI',
    'modal_import_invoice.error_message.invalid_xml': 'Archivo de factura no válido',
    'modal_import_invoice.error_message.invalid_format': 'Es necesario importar un archivo .XML',
    'modal_import_invoice.error_message.default': 'Error al intentar importar la factura',
    'register_policies.ios_supervised': 'IOS - Supervisado',
    'register_policies.ios_supervised.description': 'Para dispositivos de propiedad de la empresa. Permite administrar configuraciones y crear restricciones en dispositivos iOS aprovisionados en modo Supervisado.',
    'register_policies.ios_supervised.profile': 'Perfil',
    'register_policies.ios_supervised.upload_button': 'Cargar',
    'register_policies.ios_supervised.profile_input.placeholder': 'Cargar un archivo de configuración',
    'register_policies.ios_supervised.profile_input.error_message.invalid_format': 'Es necesario importar un archivo .mobileconfig',
    'register_policies.ios_supervised.profile_input.error_message.invalid_mobileconfig': 'Archivo de configuración no válido',
    'register_policies.ios_supervised.profile_input.error_message.default': 'Error al intentar importar el archivo de configuración',
    'register_policies.ios_supervised.profile_input.success': 'Archivo cargado con éxito',
    'update_policies.tab.profile': 'Perfil',
    'update_policies.file': 'Archivo de configuración del perfil',
    'edit_policy.ios.profile.profile_file_input': 'Archivo de configuración',
    'manage_policies.filter.management_mode.ios_supervised': 'IOS Supervisado',
    'menu.settings.networks.list': 'Administrar Redes Wifi',
    'manage_networks.entity': 'Red Wifi',
    'manage_networks.title': 'Administrar Redes Wifi',
    'manage_networks.description': 'Define las configuraciones avanzadas de la red Wi-Fi.',
    'zero_touch_register_details.success.approved': 'Dispositivo registrado en Zero Touch.',
    'zero_touch_register_details.success.refused': 'Registro de dispositivo denegado.',
    'network_settings_details.title': 'Detalles de configuración Wi-Fi',
    'network_settings_details.description': 'Define las configuraciones de la red Wi-Fi que necesita ser administrada',
    'network_settings_details.name': 'Nombre de la configuración',
    'network_settings_details.ssid': 'SSID',
    'network_settings_details.passphrase': 'Contraseña',
    'network_settings_details.auto_connect': 'Conectar Automáticamente',
    'network_settings_details.security': 'Tipo de Seguridad',
    'network_settings_details.security.none': 'Ninguno',
    'network_settings_details.eap_inner': 'Autenticación Inner',
    'network_settings_details.eap_outer': 'Autenticación Outer',
    'network_settings_details.certificate_client.certificate': 'Certificado del cliente',
    'network_settings_details.certificate_server.certificate': 'Certificado de servidor',
    'network_settings_details.certificate_client.type': 'Tipo de certificado de cliente',
    'network_settings_details.select_placeholder': 'Seleccione una opción',
    'network_settings_details.certificate_server.type': 'Tipo de certificado del servidor',
    'network_settings_details.button.save': 'Guardar',
    'network_settings_details.error_message.invalid_format': 'El archivo cargado no es del tipo {filetype}',
    'file_upload.message.success': 'Archivo cargado con éxito!',
    'file_upload.message.error': '¡Error al intentar cargar el archivo!',
    'file_upload.button': 'Cargar archivo',
    'file_upload.placeholder': 'Haga la carga de un archivo.',
    'network_settings_details.success_message': 'La configuración de la red wi-fi se ha registrado con éxito',
    'manage_networks.open_destroy_message.text_default': '¿Seguro que desea eliminar este ítem?',
    'manage_networks.open_destroy_message.network_settings_bound_text': 'Esta configuración de red Wi-fi está vinculada a la política, ¿está seguro de que desea eliminar este ítem? ¡Importante! No se puede deshacer la acción.',
    'register_policies.operational_system.android': 'Android',
    'register_policies.operational_system.ios': 'IOS',
    'manage_zero_touch_register.zero_touch_pending.pendency': 'Pendientes',
    'manage_zero_touch_register.zero_touch_status': 'Estado',
    'update_policies.settings.accordion.wifi': 'Configuraciones Wi-Fi',
    'update_policies.settings.accordion.wifi.description': 'Define la configuración de la red Wi-Fi permitida, posibilitando la conexión automática del dispositivo, respetando siempre el registro de la configuración. Si no hay una configuración de red Wi-Fi definida, permitirá la conexión manual del dispositivo a otras redes Wi-Fi.',
    'update_policies.settings.accordion.wifi.empty_message': 'Ninguna red Wi-Fi vinculada',
    'zero_touch_register_details.error_message.fail_approve_device': 'Error al aprobar dispositivos',
    'register_policies.android_work_profile': 'Android - Work Profile',
    'register_policies.android_work_profile.description': 'Para dispositivos de propiedad personal. Permite la creación y gestión del perfil laboral, preservando la privacidad y seguridad en el perfil personal y asegurando el mejor rendimiento.',
    'manage_policies.filter.management_mode.work_profile_android': 'Android - Work profile',
    'devices.infos.operational_system': 'Sistema Operativo',
    'register_policies.work_profile_android': 'Android - Work profile',
    'manage_admin.toaster.success.delete': 'Administrador removido exitosamente',
    'update_policies.settings.accordion.password_policy.device': 'Restricciones de Contraseña - Dispositivo',
    'update_policies.settings.accordion.password_policy.work_profile': 'Restricciones de Contraseña - Perfil Laboral',
    'update_policies.passwordPolicies.applied_device_settings.title': 'Permitir al usuario mantener la misma contraseña definida para el "Dispositivo" en el "Perfil de Trabajo" o elegir una nueva contraseña para el "Perfil de Trabajo".',
    'update_policies.passwordPolicy.deviceScope.passwordHistoryLength': 'Historial máximo de contraseñas que el usuario no podrá volver a utilizar ',
    'update_policies.passwordPolicy.deviceScope.maximumFailedPasswordsForWipe': 'Máximo de contraseñas incorrectas antes de ejecutar wipe',
    'update_policies.passwordPolicy.deviceScope.passwordExpirationTimeout': 'Tiempo límite de expiración de la contraseña (días)',
    'update_policies.passwordPolicy.deviceScope.requirePasswordUnlock': 'Exigir desbloqueo mediante contraseña',
    'update_policies.passwordPolicy.deviceScope.passwordMinimumLength': 'Longitud mínima de la contraseña',
    'update_policies.passwordPolicy.deviceScope.passwordMinimumLetters': 'Cantidad mínima de letras requeridas en la contraseña',
    'update_policies.passwordPolicy.deviceScope.passwordMinimumNonLetter': 'Cantidad mínima de caracteres que no sean letras (dígitos numéricos o símbolos) necesarios en la contraseña',
    'update_policies.passwordPolicy.deviceScope.passwordMinimumNumeric': 'Cantidad mínima de dígitos numéricos necesarios en la contraseña',
    'update_policies.passwordPolicy.deviceScope.passwordMinimumSymbols': 'Cantidad mínima de símbolos necesarios en la contraseña',
    'update_policies.passwordPolicy.deviceScope.passwordMinimumUpperCase': 'Cantidad mínima de letras mayúsculas requeridas en la contraseña',
    'update_policies.passwordPolicy.deviceScope.passwordMinimumLowerCase': 'Cantidad mínima de letras minúsculas requeridas en la contraseña',
    'update_policies.passwordPolicy.profileScope.passwordHistoryLength': 'Historial máximo de contraseñas que el usuario no podrá volver a utilizar',
    'update_policies.passwordPolicy.profileScope.maximumFailedPasswordsForWipe': 'Máximo de contraseñas incorrectas antes de realizar el wipe ',
    'update_policies.passwordPolicy.profileScope.passwordExpirationTimeout': 'Tiempo límite de expiración de la contraseña (días)',
    'update_policies.passwordPolicy.profileScope.requirePasswordUnlock': 'Exigir desbloqueo mediante contraseña',
    'update_policies.passwordPolicy.profileScope.passwordMinimumLength': 'Longitud mínima de la contraseña',
    'update_policies.passwordPolicy.profileScope.passwordMinimumLetters': 'Cantidad mínima de letras requeridas en la contraseña',
    'update_policies.passwordPolicy.profileScope.passwordMinimumNonLetter': 'Cantidad mínima de caracteres que no sean letras (dígitos numéricos o símbolos) necesarios en la contraseña',
    'update_policies.passwordPolicy.profileScope.passwordMinimumNumeric': 'Cantidad mínima de dígitos numéricos necesarios en la contraseña',
    'update_policies.passwordPolicy.profileScope.passwordMinimumSymbols': 'Cantidad mínima de símbolos necesarios en la contraseña',
    'update_policies.passwordPolicy.profileScope.passwordMinimumUpperCase': 'Cantidad mínima de letras mayúsculas requeridas en la contraseña',
    'update_policies.passwordPolicy.profileScope.passwordMinimumLowerCase': 'Cantidad mínima de letras minúsculas requeridas en la contraseña',
    'edit_policy.kiosk.wallpaper.title': 'Configuraciones de fondo de pantalla',
    'dropzone.drop_text': 'Arrastre el archivo aquí',
    'dropzone.or': 'o',
    'dropzone.button': 'Cargar archivo',
    'edit_policy.kiosk.manage_configuration': 'Configuraciones gestionadas',
    'edit_policy.kiosk.kiosk_wallpaper': 'Dirección del fondo de pantalla',
    'edit_policy.kiosk.kiosk_wallpaper.errors.default': 'No se pudo cargar la imagen seleccionada',
    'edit_policy.kiosk.kiosk_wallpaper.errors.server_limit': 'El tamaño de la imagen ha excedido el límite permitido',
    'dropzone.rejected_file': 'Formato de archivo o cantidad no permitidos',
    'update_policies.applications.installation_type.kiosk.system_app': 'Sistema',
    'update_policies.applications.table.column.show_in_kiosk_mode': 'Mostrar en el Quiosco',
    'update_policies.modal_enterprise_system_apps.title': 'Aplicaciones del sistema',
    'update_policies.applications.menu_action_button.system': 'Sistema',
    'update_policies.applications.menu_action_button.store': 'Play Store',
    'company_info.token': 'Código de confirmación de e-mail',
    'update_policies.applications.table.origin.PLAYSTORE': 'PLAY STORE',
    'update_policies.applications.table.origin.SYSTEM': 'SISTEMA',
    'update_policies.applications.table.origin.WEB': 'WEB',
    'update_policies.applications.table.origin.PARTICULAR': 'PARTICULAR',
    'error.generic_error': 'Error al realizar la solicitud, inténtalo de nuevo.',
    'invoice_details.import_devices_imei.error': 'Hay más IMEIs que dispositivos identificados en la factura',
    'invoice_details.import_devices_imei.size.error': '¡Tamaño de archivo excedido!',
    'invoice_details.import_devices_imei.success': '¡IMEIs importados exitosamente!',
    'invoice_details.model_details.upload_button': 'Importar',
    'zero_touch.accept_terms.title': 'Declaración de Responsabilidad',
    'zero_touch.accept_terms.accept': 'Aceptar',
    'zero_touch.accept_terms.decline': 'Rechazar',
    'zero_touch.accept_terms.loading_message': 'Cargando términos',
    'zero_touch.accept_terms.errors.get_description': 'No se pudo cargar los términos',
    'zero_touch.accept_terms.back': 'Volver',
    'zero_touch.accept_terms.errors.get_is_accepted': 'No se pudo verificar la aceptación de los términos',
    'zero_touch.accept_terms.errors.reply': 'No se pudo responder a los términos',
    'error_boundary.title': 'Algo salió mal',
    'error_boundary.description': 'Hubo un error al procesar su solicitud, intente nuevamente.',
    'error_boundary.action.back': 'Volver al inicio',
    'error_boundary.action.reload': 'Recargar la página',
    'register_policies.policy': 'Política',
    'general_config.values.policyBoolean.true': 'Aceptado',
    'general_config.values.policyBoolean.false': 'Rechazado',
    'device_location.address_error': 'No se pudo obtener la ubicación del dispositivo',
    'device_location.user': 'Usuario',
    'table.action.empty_export_message': 'No hay información registrada para generar un informe',
    'edit_group.report_title': 'Usuarios del grupo',
    'general_config.form.cycle_start.title': 'Inicio del ciclo',
    'invoice_details.import_devices_imei.divergent_information.error': 'Hay información contradictoria, es necesario verificar.',
    'invoice_details.import_devices_imei.invalid_format.error': 'Formato de archivo no válido.',
    'invoice_details.import_devices_imei.template_download': 'Descargar modelo.',
    'general_config.form.sync_time.title': 'Sincronización de la información',
    'manage_invoice.error_message.fail_invalid_cnpj_reseler': 'Emisor de la factura no autorizado.',
    'edit_policy.kiosk.kiosk_wallpaper.errors.invalid_size': 'El tamaño de la imagen ha excedido el límite permitido.',
    'devices.advanced_filters': 'Filtros Avanzados',
    'edit_policy.kiosk.kiosk_icon_font_color': 'Seleccionar color',
    'company_info.modal.send_code_success': 'El código se ha reenviado con éxito.',
    'manage_admin.toaster.success.edit_with_email': 'El usuario administrador se ha modificado con éxito, se ha enviado un correo electrónico al usuario con las instrucciones de acceso.',
    'login.modal.title.not_validated': '¡Consulta su correo electrónico!',
    'login.modal.not_validated': 'Para garantizar la seguridad de su acceso, deberá registrar una nueva contraseña. Las instrucciones necesarias han sido enviadas a su correo electrónico. Si el correo electrónico no es correcto, comuníquese con el soporte.',
    'devices.policy': 'Política',
    'invoice_details.import_devices_imei.generic.error': 'Error al realizar la solicitud, intente nuevamente.',
    'update_policies.settings.accordion.remote_view': 'Acceso Remoto',
    'update_policies.settings.accordion.remote_view.allow_remote_view.title': 'Habilitar acceso remoto',
    'update_policies.settings.accordion.remote_view.allow_remote_view.description': 'Remote device access configuration is enabled',
    'update_policies.modal_enterprise_apps.device_filter': 'Dispositivo',
    'update_policies.modal_enterprise_apps.device_filter.placeholder': 'Seleccione lo dispositivo',
    'manual.open_manual': 'Manual de Apoyo',
    'update_policies.modal_enterprise_apps.device_filter.tooltip': 'Los dispositivos relacionados corresponden a dispositivos vinculados a la política',
    'devices.action.remote_view': 'Control Remoto',
    'remote_view.start_connection': 'Iniciar conexión',
    'remote_view.stop_connection': 'Terminar conexión',
    'remote_view.title': 'Acceso Remoto',
    'remote_view.description': 'En esta pantalla es posible conectar con el dispositivo',
    'remote_view.success': 'Conexión realizada con éxito',
    'update_policies.settings.accordion.safety.modify_accounts_manager.select.none': '',
    'update_policies.settings.accordion.safety.modify_accounts_manager.select.google': '',
    'update_policies.settings.accordion.safety.modify_accounts_manager.select.all': '',
    'remote_view.error': 'Se produjo un error al intentar conectar con el dispositivo',
    'update_policies.applications.table.column.package_name': 'Nombre del Paquete',
    'enterprise_applications.column.package_name': 'Nombre del Paquete',
    'import.file.error.size': '¡Tamaño de archivo excedido!',
    'import.file.error.invalid_format': '¡Formato de archivo inválido!',
    'import.file.error.fail_read_file': '¡La información del archivo de modelo es inválida, revíselo y vuelva a intentarlo!',
    'import.file.error.fail_to_process_file': '¡Se produjo un problema al procesar el archivo!',
    'manage_groups.success.import': 'Por favor, espere a que se complete la importación de la información. El sistema mostrará una notificación en la parte superior de la pantalla cuando haya terminado, donde podrá revisar el resultado de la importación.',
    'manage_groups.error.import': '¡La información del archivo de modelo es inválida, revíselo y vuelva a intentarlo!',
    'manage_groups.error.import.device_in_other_group': 'Dispositivo vinculado en otro grupo',
    'manage_groups.error.import.number_not_found': 'El número no existe en la empresa',
    'manage_groups.import_groups.button_title': 'Importar',
    'manage_groups.template_model.button_title': 'Descargar Modelo',
    'manage_groups.menu.button_title': 'Registrar en lote',
    'batch_template_import.file_name': 'Importar {entity} en lote',
    'batch_template_import.groups': 'Grupo',
    'batch_template_import.groups.columns.name': 'GRUPO',
    'batch_template_import.groups.columns.device': 'DISPOSITIVO',
    'notification.batch_import.group.title': 'Registro en lote de grupos',
    'notification.list.import_button.url': 'Ver detalles de errores',
    'notification.badge.not_read.title': 'No leído',
    'notification.import_list.title': 'Importación:',
    'import_groups.column.group': 'Grupo',
    'import_groups.column.device': 'Dispositivo',
    'import_groups.column.error': 'Error',
    'modal_notification_details.title': 'Detalles de la notificación',
    'update_policies.settings.accordion.safety.modify_accounts_disabled.title': 'Bloquear cambio de cuentas',
    'update_policies.settings.accordion.safety.modify_accounts_disabled.description': 'La opción de agregar o quitar cuentas está desactivada.',
    'update_policies.settings.accordion.safety.account_types_with_management_disabled.title': 'Tipos de cuentas con administración desactivada',
    'update_policies.settings.accordion.safety.account_types_with_management_disabled.description': 'Tipos de cuenta que no pueden ser administrados por el usuario.',
    'update_policies.settings.accordion.safety.account_types_with_management_disabled.placeholder': 'Seleccione o agregue una opción',
    'devices.filter.start_at': 'Fecha de inicio de registro',
    'devices.filter.end_at': 'Fecha de finalización del registro',
    'update_policies.applications.menu_action_button.manual': 'Manual',
    'update_policies.modal_add_manual_system_apps.title': 'Agregar aplicaciones manualmente',
    'update_policies.modal_add_manual_system_apps.add_button': 'Agregar',
    'update_policies.modal_add_manual_system_apps.input_name': 'Nombre',
    'update_policies.modal_add_manual_system_apps.input_name.placeholder': 'Ingresa un nombre',
    'update_policies.modal_add_manual_system_apps.input_package_name': 'Nombre del paquete',
    'update_policies.modal_add_manual_system_apps.input_package_name.placeholder': 'Ingresa un nombre de paquete',
    'update_policies.modal_add_manual_system_apps.input_package_name.error': 'La aplicación ya ha sido agregada. ¡Revisa la información!',
    'update_policies.applications.table.origin.MANUAL': 'MANUAL',
    'update_policies.manual_applications.arr.package_name': 'Nombre del paquete',
    'general_config.inactivity_time': 'Tiempo de inactividad',
    'general_config.inactivity_time.5min': '5 min',
    'general_config.inactivity_time.10min': '10 min',
    'general_config.inactivity_time.20min': '20 min',
    'general_config.inactivity_time.30min': '30 min',
    'inactivity_alert.after_redirect': '¡Su sesión ha caducado!',
    'inactivity_alert.confirm': 'Confirmar',
    'inactivity_alert.title': '¡Tu acceso caducará en {seconds} segundos!',
    'inactivity_alert.description': 'Confirma que deseas permanecer conectado',
    'update_policies.modal_enterprise_apps.manufacturer_filter': 'Fabricante',
    'update_policies.modal_enterprise_apps.manufacturer_filter.placeholder': 'Seccione el fabricante',
    'import.file.error.fail_empty_file': '¡El archivo importado no tiene información!',
    'login_confirmation.info': 'Para garantizar la seguridad de su acceso, debe confirmar la siguiente información. Complete el código de 6 dígitos que fue enviado a su correo electrónico',
    'login_confirmation.error.invalid_code': 'El código de confirmación ingresado no es válido, inténtelo nuevamente',
    'login_confirmation.error.default': 'Se produjo un error al intentar realizar su solicitud, inténtelo nuevamente',
    'company_info.subtitle.abm': 'Apple Business Manager',
    'company_info.field.contentTokenABM': 'Token de contenido',
    'devices.infos.emailAppleBusinessManager': 'Correo electrónico del usuario en Apple Business Manager (ABM)',
    'devices.infos.ios_error.invalid_email': 'El correo electrónico proporcionado aún no tiene un usuario registrado en Apple Business Manager. Accede a la cuenta de tu empresa en Apple Business Manager para registrar el usuario y luego intenta nuevamente vincular el correo electrónico en esta pantalla.',
    'company_info.abm.info': 'Para obtener el "Token de Contenido" acceda a la cuenta Apple Business Manager (ABM) de su empresa.',
    'devices.infos.ios_error.not_found_content_token_abm': 'No se encontró ningún token de Apple Business Manager (ABM) en el registro de su empresa',
    'devices.alert.restart_device.text': '¿Realmente quieres restablecer este dispositivo?',
    'devices.alert.turn_off_screen.text': '¿Realmente quieres apagar la pantalla en este dispositivo?',
    'update_policies.manual_applications.arr.name': 'Nombre de la aplicación',
    'global.about': 'Acerca de',
    'modal_product_version.actual_version': 'Versión Actual: {productVersion} ({frontVersion}, {backVersion})',
    'devices.action.more_information': 'Mas informaciones',
    'devices.action.device_information': 'Información del dispositivo',
    'update_policies.settings.accordion.work': 'Trabajo',
    'update_policies.settings.accordion.work.lock_apps_outside_working_hours.title': 'Bloquear dispositivo fuera del horario laboral',
    'update_policies.settings.accordion.work.lock_apps_outside_working_hours.description': 'Se activa el bloqueo total del dispositivo cuando está fuera del horario laboral.',
    'update_policies.settings.accordion.work.days_of_work.title': 'Días laborables',
    'update_policies.settings.accordion.work.days_of_work.description': 'Trabajo',
    'update_policies.settings.accordion.work.start_day_of_work': 'Fecha de inicio',
    'update_policies.settings.accordion.work.end_day_of_work': 'Fecha final',
    'update_policies.settings.accordion.work.work_time.title': 'Horario de trabajo',
    'update_policies.settings.accordion.work.work_time.description': 'Define el horario laboral',
    'update_policies.settings.accordion.work.work_time.tooltip': 'Al dejar los campos en blanco, el sistema considera todo el período del día.',
    'update_policies.settings.accordion.work.start_working_time': 'Hora de inicio',
    'update_policies.settings.accordion.work.work_time.placeholder': 'Seleccionar',
    'update_policies.settings.accordion.work.end_working_time': 'Hora final',
    'devices.infos.details': 'Detalles',
    'devices.infos.install': 'Instalación',
    'devices.infos.hardware': 'Hardware',
    'menu.enterprise_applications.external_apps': 'Administrar aplicaciones externas',
    'external_apps.title': 'Administrar aplicaciones externas',
    'external_apps.description': 'Lorem Ipsum',
    'external_apps.start_date': 'Fecha de creación Inicio',
    'external_apps.end_date': 'Fecha de creación finalización',
    'external_apps.new': 'Nueva aplicación',
    'external_apps.entity': 'Aplicación',
    'external_app_edit.create_title': 'Nueva aplicación',
    'external_app_edit.edit_title': 'Editar aplicación',
    'external_app_edit.create_description': 'En esta pantalla podrás enviar una nueva aplicación a los dispositivos.',
    'external_app_edit.edit_description': 'En esta pantalla puedes editar una aplicación.',
    'external_app_edit.create_send_label': 'Guardar',
    'external_app_edit.edit_send_label': 'Actualizar',
    'external_app_edit.input.url': 'URL de descarga',
    'external_app_edit.invalid_url': 'Introduce una URL válida',
    'confirm_welcome_email.title': '¡Bienvenido!',
    'confirm_welcome_email.description': 'Confirme toda la información de la empresa y complete la dirección de correo electrónico del administrador, donde se enviará el correo electrónico de bienvenida. Una vez enviada, esta acción no se puede volver a realizar.',
    'confirm_welcome_email.corporate_reason': 'Razón social',
    'confirm_welcome_email.externalCode': 'CNPJ',
    'confirm_welcome_email.contact': 'Contacto',
    'confirm_welcome_email.phone': 'Teléfono de contacto',
    'confirm_welcome_email.admin_email': 'Correo electrónico del administrador de la empresa',
    'confirm_welcome_email.confirm': 'Enviar correo electrónico de bienvenida',
    'confirm_welcome_email.error.invalid_email': 'Introduzca un correo electrónico válido',
    'edit_policy.kiosk.telephone_services': 'Servicios de telefonía adicionales',
    'app_managed_configurations.add_bundle_array_button': 'Agregar configuración',
    'app_managed_configurations.remove_bundle_array_button': 'Eliminar configuración',
    'devices.batch_devices_actions.button': 'Acciones por lotes',
    'devices.modal_batch_devices_actions.title': 'Acciones por lotes',
    'devices.modal_batch_devices_actions.actions': 'Comandos',
    'devices.modal_batch_devices_actions.action.disable': 'Desactivar dispositivo',
    'devices.modal_batch_devices_actions.action.enable': 'Activar dispositivo',
    'devices.modal_batch_devices_actions.action.lock': 'Apagar la pantalla',
    'devices.modal_batch_devices_actions.action.reboot': 'Reiniciar el dipositivo',
    'devices.modal_batch_devices_actions.action.wipe': 'Quitar dispositivo (limpiar)',
    'devices.modal_batch_devices_actions.alert.disable': '¿Realmente desea desactivar los dispositivos seleccionados?',
    'devices.modal_batch_devices_actions.alert.enable': '¿Realmente deseas activar los dispositivos seleccionados?',
    'devices.modal_batch_devices_actions.alert.lock': '¿Realmente deseas apagar la pantalla en dispositivos seleccionados?',
    'devices.modal_batch_devices_actions.alert.reboot': '¿Realmente deseas reiniciar los dispositivos seleccionados?',
    'devices.modal_batch_devices_actions.alert.wipe': '¿Realmente desea eliminar todos los dispositivos seleccionados?',
    'devices.modal_batch_devices_actions.alert.default': '¿Realmente deseas enviar este comando a todos los dispositivos seleccionados?',
    'devices.modal_batch_devices_actions.alert.irreversible': 'Esta acción no se puede deshacer.',
    'devices.alert.batch_in_process': '¡Ya tienes una Solicitud de acción por lotes en progreso! Espere a que se complete el proceso antes de solicitar que se envíe otra acción.',
    'devices.alert.default': 'Ocurrió un error al procesar su solicitud. Por favor, inténtelo de nuevo más tarde.',
    'notification.command.title': 'Comando',
    'notification.list.command.url': '¡Comando enviado exitosamente!',
    'notification.list.command.error': 'Error al enviar el comando',
    'general_config.two_factor_authentication': 'Autenticación de dos factores',
    'general_config.two_factor_authentication.tooltip': 'Antes de activar esta configuración, asegúrese de que todos los usuarios estén registrados con una dirección de correo electrónico válida. Luego de activar la configuración, para realizar la autenticación de dos factores y acceder al Portal, el usuario deberá ingresar un código de confirmación que se enviará al correo electrónico del usuario cada vez que intente acceder al Portal.',
    'update_policies.applications.table.blockTime': 'Restricciones de funcionamento',
    'update_policies.applications.blockTimeModal.title': 'Restricciones de funcionamento',
    'update_policies.applications.blockTimeModal.description': 'Defina el período de funcionamiento de la aplicación durante los días de la semana, o active la opción “Restringido” para bloquear el funcionamiento todo el día',
    'update_policies.applications.blockTimeModal.dayOfWeek.0': 'Lunes',
    'update_policies.applications.blockTimeModal.dayOfWeek.1': 'Martes',
    'update_policies.applications.blockTimeModal.dayOfWeek.2': 'Miércoles',
    'update_policies.applications.blockTimeModal.dayOfWeek.3': 'Jueves',
    'update_policies.applications.blockTimeModal.dayOfWeek.4': 'Viernes',
    'update_policies.applications.blockTimeModal.dayOfWeek.5': 'Sábado',
    'update_policies.applications.blockTimeModal.dayOfWeek.6': 'Domingo',
    'update_policies.applications.blockTimeModal.table.header.dayOfWeek': 'Dia de la semana',
    'update_policies.applications.blockTimeModal.table.header.block': 'Restringir',
    'update_policies.applications.blockTimeModal.table.header.blockTime': 'Horas de uso',
    'update_policies.applications.blockTimeModal.enableBlockTime': 'Permitir restricciones',
    'update_policies.applications.blockTimeModal.confirm': 'Confirmar',
    'update_policies.applications.blockTimeModal.cancel': 'Cancelar',
    'edit_policy.kiosk.telephone_services.active': 'Activo',
    'edit_policy.kiosk.telephone_services.user_defined': 'Definido por dispositivo',
    'menu.companies': 'Empresas',
    'menu.companies.manage_companies': 'Gestionar Empresas',
    'menu.companies.register': 'Registrar Nueva Eempresa',
    'manage_companies.title': 'Gestionar Empresas',
    'manage_companies.company': 'Empresa',
    'manage_companies.description': 'En esta pantalla puedes gestionar empresas.',
    'manage_companies.button.new': 'Nueva empresa',
    'manage_companies.action_button.resend_email': 'Reenviar correo electrónico de bienvenida',
    'manage_companies.filter.created_at': 'Fecha de creación',
    'manage_companies.filter.start_at': 'Fecha de inicio',
    'manage_companies.filter.end_at': 'Fecha final',
    'manage_companies.filter.status': 'Estado',
    'edit_policy.app_config.integer_limit_error': 'Introduzca únicamente números entre {min} y {max}',
    'edit_company.edit.title': 'Editar empresa',
    'edit_company.edit.description': 'En esta pantalla puede editar la empresa.',
    'edit_company.register.title': 'Registrar nueva empresa',
    'edit_company.register.description': 'En esta pantalla puede registrar una empresa.',
    'edit_company.corporate_name': 'Razón social',
    'edit_company.federal_code': 'Número de identificación',
    'edit_company.email': 'Correo electrónico',
    'edit_company.number_of_licenses': 'Licencias',
    'edit_company.plan': 'Plan',
    'edit_company.contact': 'Contacto',
    'edit_company.phone_number': 'Teléfono',
    'edit_company.zip_code': 'Código postal',
    'edit_company.address': 'Dirección',
    'edit_company.city': 'Ciudad',
    'edit_company.state': 'Estado',
    'edit_company.edit.alert_update.text': '¿Está seguro de que desea actualizar la empresa {name}?',
    'edit_company.edit.alert_email_update.text': '¿Está seguro de que desea actualizar la empresa {name} y enviar el correo electrónico de bienvenida a {email}?',
    'edit_company.register.alert.text': '¿Está seguro de que desea registrar la empresa {name} y enviar el correo electrónico de bienvenida a {email}?',
    'manage_companies.action_button.cancel_company': 'Cancelar empresa',
    'manage_companies.alert.cancel_company_text': '¿Está seguro de que desea cancelar la empresa {name}? Tras la confirmación, ¡se eliminarán todas las licencias!',
    'manage_companies.alert.resend_company_email_text': 'Confirme que el correo electrónico de bienvenida de la empresa {name} se ha reenviado a {email}.',
    'manage_companies.alert.resend_company_email.success_message': '¡El correo electrónico de bienvenida se envió correctamente!',
    'edit_policy.app_config.max_characters': 'El tamaño máximo de caracteres permitido es {maxCharacters}.',
    'devices.infos.permissions': 'Permisos',
    'devices.infos.usage_data_access': 'Acceso a datos de uso',
    'devices.infos.skip_battery_optimization': 'Saltar optimización de batería',
    'devices.infos.writing_system_settings': 'Cambio de Configuraciones del Sistema',
    'devices.infos.sms_reading': 'Lectura de SMS',
    'plan.plane_name': 'Plan',
    'update_policies.start_working_time': 'Hora de inicio del trabajo',
    'update_policies.end_working_time': 'Horario de trabajo',
    'enterprise_applications.error.there_are_policies_with_app.confirm_button': 'Confirmar',
    'global.plan_alert_route': '¡No puedes utilizar esta función porque no forma parte de tu plan! ¡Actualiza tu plan para aprovechar esta funcionalidad!',
    'edit_policy.app_config.plan_max_keywords': '¡Has alcanzado el límite de tu plan! ¡Actualice su plan para agregar URL y palabras clave ilimitadas!',
    'edit_policy.app_config.plan_max_website_categories': '¡Has alcanzado el límite de tu plan! ¡Actualiza tu plan para activar categorías ilimitadas!',
    'update_policies.max_block_website_categories': 'Bloqueo de sitios por categoría',
    'update_policies.max_block_keywords': 'URL y palabras clave bloqueadas y liberadas',
    'dashboard.roaming_disabled': '¡No puedes utilizar esta función porque no forma parte de tu plan! ¡Actualiza tu plan para aprovechar esta funcionalidad!',
    'register_policies.android_block_sim.tooltip': '¡No puedes utilizar esta función porque no forma parte de tu plan! ¡Actualiza tu plan para aprovechar esta funcionalidad!',
    'edit_policy.kiosk.manage_configuration.plan_alert': '¡No puedes utilizar esta función porque no forma parte de tu plan! ¡Actualiza tu plan para aprovechar esta funcionalidad!',
    'manage_policies.android_block_sim.tooltip': '¡No puedes utilizar esta función porque no forma parte de tu plan! ¡Actualiza tu plan para aprovechar esta funcionalidad!',
    'manage_policies.table_actions.edit_policy': 'Editar política',
    'manage_policies.modal_enrollmentToken.enable_system_apps': 'Habilitar aplicaciones del sistema',
    'manage_policies.modal_enrollmentToken.success_enable_system_apps': '¡Código QR actualizado exitosamente!',
    'update_policies.allow_unlimited_kiosk': 'Configuración administrada del modo quiosco',
    'register_policies.ios_supervised.tooltip': '¡No puedes utilizar esta función porque no forma parte de tu plan! ¡Actualiza tu plan para aprovechar esta funcionalidad!',
    'global.plan_warning': '¡No puedes utilizar esta función porque no forma parte de tu plan! ¡Actualiza tu plan para aprovechar esta funcionalidad!',
    'devices.infos.ios_error.email_already_in_use': '¡El correo electrónico proporcionado está vinculado a otro dispositivo! Cambia tu correo electrónico y vuelve a intentarlo.',
    'menu.enterprise_applications.remote_apps': 'Instalación remota de aplicaciones',
    'remoteApp.title': 'Instalación remota de aplicaciones',
    'remoteApp.description': 'En esta pantalla podrás gestionar y enviar la instalación remota de aplicaciones a los dispositivos.',
    'remoteApp.start_date': 'Fecha inicio',
    'remoteApp.end_date': 'Fecha final',
    'remoteApp.new': 'Nueva instalación',
    'remoteApp.column.date': 'Fecha de envío',
    'remoteApp.column.name': 'Nombre de la aplicación',
    'remoteApp.column.packageName': 'Nombre del paquete',
    'remoteApp.column.url': 'Descargar URL',
    'remoteApp.entity': 'Instalación',
    'remoteApp.toaster_success': '¡La instalación remota se realizó correctamente!',
    'remoteApps.title': 'Detalles de envío de la solicitud',
    'remoteApps.description': 'En esta pantalla podrás gestionar y enviar la instalación remota de aplicaciones a los dispositivos.',
    'remoteApps.subtitle': 'Destinatarios',
    'remoteApps.name': 'Nombre de la aplicación',
    'remoteApps.packageName': 'Paquete de aplicaciones',
    'remoteApps.url': 'Descargar URL',
    'remote_app_edit.title': 'Instalar aplicación',
    'remote_app_edit.description': 'Desde esta pantalla es posible enviar la instalación remota de una aplicación a los dispositivos.',
    'remote_app_edit.send_label': 'Enviar',
    'remote_app_edit.filter_title': 'Enviar aplicación',
    'remote_app_edit.input.name': 'Nombre',
    'remote_app_edit.input.package_name': 'Nombre del paquete',
    'remote_app_edit.input.url': 'Descargar URL',
    'remote_app_edit.input.recipient': 'Recipiente',
    'company_info.subtitle.google_workspace': 'Workspace Google',
    'company_info.google_workspace.client_id': 'Después de registrar una función de administrador en su entorno de espacio de trabajo con nuestra cuenta de servicio, ingrese su ID de cliente aquí para integrarlo con sus dispositivos ChromeOS.',
    'company_info.field.workspaceClientId': 'ID de cliente del Workspace Google',
    'register_policies.operational_system.chrome_os': 'ChromeOS',
    'register_policies.chrome_os': 'ChromeOS',
    'register_policies.chrome_os.description': 'Para dispositivos propiedad de la empresa. Permite la gestión de la configuración del dispositivo y los controles de políticas.',
    'update_policies.chrome_os.tab.kiosk': 'Modo quiosco',
    'update_policies.chrome_os.events_accordion.title': 'Eventos de activación',
    'update_policies.chrome_os.events_accordion.description': 'Configuración de eventos para activar el modo kiosco en los dispositivos.',
    'update_policies.chrome_os.events_accordion.originUnits': 'Unidades organizativas propietarias de los dispositivos',
    'update_policies.chrome_os.events_accordion.destinationUnit': 'Unidad organizativa de destino que tiene el modo quiosco',
    'update_policies.settings.accordion.safety.usb_data_access.title': 'Configuración de transferencia USB',
    'update_policies.settings.accordion.safety.usb_data_access.description': 'Define qué archivos y/o datos se pueden transferir a través de USB. No afecta las funciones de carga. La opción “No permitir transferir todo tipo de datos” solo se admite en dispositivos con Android 12 o posterior y USB HAL 1.3 o posterior.',
    'update_policies.settings.accordion.safety.usb_data_access.select.allow_usb_data_transfer': 'Permitir transferir todo tipo de datos.',
    'update_policies.settings.accordion.safety.usb_data_access.select.disallow_usb_file_transfer': 'No permitir transferir archivos',
    'update_policies.settings.accordion.safety.usb_data_access.select.disallow_usb_data_transfer': 'No permitir transferir todo tipo de datos',
    'update_policies.settings.accordion.network.configure_wifi.title': 'Privilegios de configuración de Wi-Fi',
    'update_policies.settings.accordion.network.configure_wifi.description': 'Define los privilegios de configuración de Wi-Fi. Según la opción definida, el usuario tendrá control total o limitado o ningún control sobre la configuración de las redes Wi-Fi. La opción "No permitir agregar configuración de Wi-Fi" solo es compatible con Android 13 y versiones más recientes.',
    'update_policies.settings.accordion.network.configure_wifi.select.allow_configuring_wifi': 'Permitir configuración Wi-Fi',
    'update_policies.settings.accordion.network.configure_wifi.select.disallow_add_wifi_config': 'No permitir agregar configuración de Wi-Fi',
    'update_policies.settings.accordion.network.configure_wifi.select.disallow_configuring_wifi': 'No permitir la configuración de Wi-Fi',
    'update_policies.settings.accordion.hardware.wifi_direct_settings.title': 'Configuración de Wi-Fi Direct',
    'update_policies.settings.accordion.hardware.wifi_direct_settings.description': 'Configura la configuración y el uso de los ajustes de Wi-Fi Direct. La opción “No permitir el uso de Wi-Fi Direct” solo es compatible con Android 13 y versiones más recientes.',
    'update_policies.settings.accordion.hardware.wifi_direct_settings.select.allow_wifi_direct': 'Permitir usar Wi-Fi Direct',
    'update_policies.settings.accordion.hardware.wifi_direct_settings.select.disallow_wifi_direct': 'No permitir el uso de Wi-Fi Direct',
    'update_policies.settings.accordion.hardware.tethering_settings.title': 'Configuración de anclaje a red',
    'update_policies.settings.accordion.hardware.tethering_settings.description': 'Configura los ajustes de anclaje a red. La opción "No permitir la conexión Wi-Fi" solo es compatible con Android 13 y versiones posteriores.',
    'update_policies.settings.accordion.hardware.tethering_settings.select.allow_all_tethering': 'Permitir todas las formas de anclaje',
    'update_policies.settings.accordion.hardware.tethering_settings.select.disallow_wifi_tethering': 'No permitir la conexión Wi-Fi',
    'update_policies.settings.accordion.hardware.tethering_settings.select.disallow_all_tethering': 'No permitir todas las formas de anclaje',
    'update_policies.chrome_os.events_accordion.google_alert': 'Google implementará este cambio y puede tardar hasta 24 horas en surtir efecto.',
    'update_policies.chrome_os.events_accordion.start_event': 'Comenzar',
    'update_policies.chrome_os.events_accordion.end_event': 'Concluir',
    'update_policies.chrome_os.events_accordion.success_on_execute': '¡Evento ejecutado exitosamente!',
    'edit_policy.kiosk.manage_event_restrictions.title': '',
    'edit_policy.kiosk.manage_event_restrictions.description': '',
    'edit_policy.kiosk.manage_event_restrictions.list': '',
    'edit_policy.kiosk.manage_event_restrictions.column.date': '',
    'edit_policy.kiosk.manage_event_restrictions.column.hour': '',
    'edit_policy.kiosk.manage_event_restrictions.column.application': '',
    'edit_policy.kiosk.manage_event_restrictions.action_button.edit': '',
    'edit_policy.kiosk.manage_event_restrictions.action_button.delete': '',
    'edit_policy.kiosk.manage_event_restrictions.button': '',
    'edit_policy.kiosk.modal_event_restrictions.title': '',
    'edit_policy.kiosk.modal_event_restrictions.edit_title': '',
    'edit_policy.kiosk.modal_event_restrictions.button.confirm': '',
    'edit_policy.kiosk.modal_event_restrictions.button.edit': '',
    'edit_policy.kiosk.modal_event_restrictions.button.cancel': '',
    'edit_policy.kiosk.modal_event_restrictions.input.date': '',
    'edit_policy.kiosk.modal_event_restrictions.input.start_hour': '',
    'edit_policy.kiosk.modal_event_restrictions.input.end_hour': '',
    'edit_policy.kiosk.modal_event_restrictions.select.application': '',
    'edit_policy.kiosk.modal_event_restrictions.error': '',
    'register_policies.register_policy.error_message.client_id_not_found': '',
    'company_info.error_message.invalid_workspace_client_id': '',
    'devices.action.policy.plan_tooltip': '',
    'update_policies.lock_apps_outside_working_hours': 'Bloquear dispositivo fuera del horario laboral',
    'update_policies.app_restriction_schedules': '',
    'update_policies.block_keywords': 'URL y palabras clave bloqueadas y liberadas',
    'plan_compliance.manage_applications.title': '',
    'plan_compliance.manage_applications.description': '',
    'plan_compliance.manage_applications.table.name': '',
    'plan_compliance.manage_applications.table.origin': '',
    'plan_compliance.manage_applications.table.package_name': '',
    'plan_compliance.manage_applications.select_apps_button': '',
    'manage_policy_update.title': '',
    'manage_policy_update.description': '',
    'manage_policy_update.column.name': '',
    'manage_policy_update.column.management_mode': '',
    'manage_policy_update.column.kiosk': '',
    'manage_policy_update.button.update_policy': '',
    'manage_policy_update.alert.text': '',
    'manage_policy_update.alert.success': '',
    'manage_policy_update.kiosk_limited_description': '',
    'manage_policies.modal_enrollmentToken.manage_networks': '',
    'manage_policies.modal_enrollmentToken.provisioning_skip_encryption': '',
    'manage_policies.modal_enrollmentToken.provisioning_use_mobile_data': '',
    'manage_policies.modal_enrollmentToken.toast_success': '',
    'manage_policies.modal_enrollmentToken.toast_error': '',
    'global.save': '',
    'login.modal.title.compliance_max_apps': '',
    'login.modal.compliance_max_apps': '',
    'update_policies.block_website_categories': 'Bloqueo de sitios por categoría',
    'guides.is_empty': '',
    'menu.ios_configs': '',
    'ios_config.title': '',
    'ios_config.description': '',
    'ios_config.apns.title': '',
    'ios_config.apns.create_button': '',
    'ios_config.apns.config_modal.title': '',
    'ios_config.apns.config_modal.steps.description': '',
    'ios_config.apns.config_modal.step': '',
    'ios_config.apns.config_modal.steps.1': '',
    'ios_config.apns.config_modal.steps.2': '',
    'ios_config.apns.config_modal.steps.3': '',
    'ios_config.apns.config_modal.steps.4': '',
    'ios_config.apns.config_modal.steps.5': '',
    'ios_config.apns.config_modal.form.title': '',
    'ios_config.apns.config_modal.form.certificate_download': '',
    'ios_config.apns.config_modal.form.download_button': '',
    'ios_config.apns.config_modal.form.certificate': '',
    'ios_config.apns.config_modal.form.password': '',
    'ios_config.apns.config_modal.form.apple_id': '',
    'ios_config.apns.config_modal.save': '',
    'ios_config.apns.config_modal.errors.invalid_type': '',
    'ios_config.apns.status': '',
    'ios_config.apns.topic': '',
    'ios_config.apns.serial_number': '',
    'ios_config.apns.inclusion_date': '',
    'ios_config.apns.expiration_date': '',
    'ios_config.apns.status.active': '',
    'ios_config.apns.status.inactive': '',
    'ios_config.apns.update_button': '',
    'ios_config.apns.config_modal.update_warning': '',
    'ios_config.apns.config_modal.update.title': '',
    'ios_config.apns.config_modal.update.steps.description': '',
    'ios_config.apns.config_modal.update.steps.1': '',
    'ios_config.apns.config_modal.update.steps.2': '',
    'ios_config.apns.config_modal.update.steps.3': '',
    'ios_config.apns.config_modal.update.steps.4': '',
    'ios_config.apns.config_modal.update.steps.5': '',
    'dashboard.devices_location.fetch_devices_location': '',
    'plan_compliance.manage_applications.confirmation': '',
  },
};

export default i18nConfig;
