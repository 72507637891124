import {
  DeleteIcon,
  NotAllowedIcon,
  SettingsIcon,
  ViewOffIcon,
} from '@chakra-ui/icons';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { PrivilegeEnum, routeWithParameters } from '../../../../helper';
import { handleOpenExternalWindow } from '../../../../helper/router';
import { usePrivilege } from '../../../../hooks/usePrivilege';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useRemoteView } from '../../../../hooks/useRemoteView';
import { ModalTypeEnum } from '../../../../pages/Devices/ManageDevices';
import routes from '../../../../routes';
import {
  enableDevice,
  disableDevice,
  removeDevice,
  restartDevice,
  turnOffScreen,
  removeScreenBlockDevice,
} from '../../../../store/device';
import { setRemoteViewDevice } from '../../../../store/remoteView';
import {
  DeviceStateEnum,
  DeviceType,
  OperationalSystemEnum,
} from '../../../../types/device';
import { ManagementModeEnum } from '../../../../types/policy';
import Alert, { AlertHtml } from '../../../Alert';
import Checkmark from '../../../Icons/Checkmark';
import DocumentIcon from '../../../Icons/Documents';
import GraphIcon from '../../../Icons/Graph';
import LockIcon from '../../../Icons/Lock';
import RefreshIcon from '../../../Icons/Refresh';
import RemoteViewIcon from '../../../Icons/RemoteView';
import MenuItem from '../../../TableActions/MenuItem';

interface ActionItemsProps {
  device?: DeviceType;
  modalOpen?: (modalName: string, device: DeviceType) => void;
  setSelectedDevice?: (device: DeviceType) => void;
}

interface DeviceActionType {
  id: string;
  isHidden?: boolean;
  isDisabled?: boolean;
  icon: JSX.Element;
  text: string;
  onClick: () => void;
}

enum DeviceActionEnum {
  BATTERY = 'BATTERY',
  REMOTE_VIEW = 'REMOTE_VIEW',
  WIPE_DEVICE = 'WIPE_DEVICE',
  STORAGE = 'STORAGE',
  CHANGE_POLICY = 'CHANGE_POLICY',
  DISABLE = 'DISABLE',
  ENABLE = 'ENABLE',
  NON_COMPLIANCE = 'NON_COMPLIANCE',
  SCREEN_OFF = 'SCREEN_OFF',
  RESTART_DEVICE = 'RESTART_DEVICE',
  NEW_PASSWORD = 'NEW_PASSWORD',
  REMOVE_SCREEN_BLOCK = 'REMOVE_SCREEN_BLOCK',
  MANAGE_INFO = 'MANAGE_INFO',
  MORE_INFORMATION = 'MORE_INFORMATION',
}

const ActionItems = ({
  device,
  modalOpen,
  setSelectedDevice,
}: ActionItemsProps) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const history = useHistory();
  const { isHelpDesk } = usePrivilege();
  const { handleKeepConnected } = useRemoteView();

  const { user } = useAppSelector((state) => state.auth);
  const { portalSettings } = useAppSelector((state) => state.portalSettings);

  const { managementMode, remoteViewEnabled } = device?.policy || {};
  const { WORK_PROFILE_ANDROID, MANAGED_ANDROID, BLOCK_SIM_ANDROID } =
    ManagementModeEnum;
  const { allowRemoteView } = portalSettings;

  const isCompany = user?.privilege === PrivilegeEnum.COMPANY;
  const isIOSDevice = device.operationalSystem === OperationalSystemEnum.IOS;
  const isAndroidWorkProfile = managementMode === WORK_PROFILE_ANDROID;
  const isAndroidManaged = managementMode === MANAGED_ANDROID;
  const isBlockSim = managementMode === BLOCK_SIM_ANDROID;
  const isDeviceAwaitEnterpriseEnroll =
    device?.state === DeviceStateEnum.AWAIT_ENTERPRISE_ENROLL;
  const hasRemoteView = allowRemoteView && (isAndroidManaged || isBlockSim);

  const isDeviceDisabled = device?.state === 'DISABLED';

  const actionEnableByOperationalSystem = {
    [OperationalSystemEnum.ANDROID]: [
      DeviceActionEnum.BATTERY,
      DeviceActionEnum.STORAGE,
      DeviceActionEnum.NON_COMPLIANCE,
      DeviceActionEnum.CHANGE_POLICY,
      DeviceActionEnum.ENABLE,
      DeviceActionEnum.DISABLE,
      DeviceActionEnum.SCREEN_OFF,
      DeviceActionEnum.RESTART_DEVICE,
      DeviceActionEnum.REMOVE_SCREEN_BLOCK,
      DeviceActionEnum.NEW_PASSWORD,
      DeviceActionEnum.WIPE_DEVICE,
      DeviceActionEnum.MANAGE_INFO,
      DeviceActionEnum.REMOTE_VIEW,
    ],
    [OperationalSystemEnum.IOS]: [
      DeviceActionEnum.BATTERY,
      DeviceActionEnum.CHANGE_POLICY,
      DeviceActionEnum.WIPE_DEVICE,
      DeviceActionEnum.MANAGE_INFO,
    ],
  };

  function handleRenderAction(deviceAction: DeviceActionEnum) {
    return actionEnableByOperationalSystem[device?.operationalSystem].includes(
      deviceAction
    );
  }

  const deviceActions: DeviceActionType[] = [
    {
      id: DeviceActionEnum.BATTERY,
      isHidden: false,
      icon: <GraphIcon boxSize={6} color="white" mr="5px" />,
      text: intl.formatMessage({ id: 'devices.action.battery' }),
      onClick: () =>
        history.push(
          routeWithParameters(routes.device.battery, {
            id: device?.id,
          })
        ),
    },
    {
      id: DeviceActionEnum.STORAGE,
      isHidden: isIOSDevice,
      icon: <GraphIcon boxSize={6} color="white" mr="5px" />,
      text: intl.formatMessage({ id: 'devices.action.storage' }),
      onClick: () =>
        history.push(
          routeWithParameters(routes.device.storage, {
            id: device?.id,
          })
        ),
    },
    {
      id: DeviceActionEnum.NON_COMPLIANCE,
      isHidden: isDeviceAwaitEnterpriseEnroll || !isCompany,
      isDisabled: !device?.hasNonComplianceDetails,
      icon: <DocumentIcon boxSize={6} mr="5px" />,
      text: intl.formatMessage({ id: 'devices.action.non_compliance' }),

      onClick: () =>
        history.push(
          routeWithParameters(routes.device.nonCompliance, {
            id: device?.id,
          })
        ),
    },
    {
      id: DeviceActionEnum.CHANGE_POLICY,
      isHidden: isDeviceAwaitEnterpriseEnroll || !isCompany,
      isDisabled: isHelpDesk,
      icon: <DocumentIcon boxSize={6} mr="5px" />,
      text: intl.formatMessage({ id: 'devices.action.policy' }),
      onClick: () => modalOpen(ModalTypeEnum.DEVICE_POLICY, device),
    },
    {
      id: DeviceActionEnum.DISABLE,
      isHidden:
        isDeviceAwaitEnterpriseEnroll ||
        isDeviceDisabled ||
        isAndroidWorkProfile,
      isDisabled: isHelpDesk,
      icon: <NotAllowedIcon boxSize={6} mr="5px" />,
      text: intl.formatMessage({ id: 'devices.action.disable' }),
      onClick: () =>
        Alert({
          onConfirm: () => dispatch(disableDevice(device?.id)),
          html: (
            <AlertHtml
              text={intl.formatMessage({ id: 'devices.alert.disable.text' })}
            />
          ),
          confirmButtonText: intl.formatMessage({
            id: 'devices.alert.disable.button',
          }),
          cancelButtonText: intl.formatMessage({ id: 'devices.alert.cancel' }),
        }),
    },
    {
      id: DeviceActionEnum.ENABLE,
      isHidden:
        isDeviceAwaitEnterpriseEnroll ||
        !isDeviceDisabled ||
        isAndroidWorkProfile,
      isDisabled: isHelpDesk,
      icon: <Checkmark boxSize={6} mr="5px" />,
      text: intl.formatMessage({ id: 'devices.action.activate' }),
      onClick: () =>
        Alert({
          onConfirm: () => dispatch(enableDevice(device?.id)),
          html: (
            <AlertHtml
              text={intl.formatMessage({ id: 'devices.alert.activate.text' })}
            />
          ),
          confirmButtonText: intl.formatMessage({
            id: 'devices.alert.activate.button',
          }),
          cancelButtonText: intl.formatMessage({ id: 'devices.alert.cancel' }),
        }),
    },
    {
      id: DeviceActionEnum.SCREEN_OFF,
      isHidden: isDeviceAwaitEnterpriseEnroll || isAndroidWorkProfile,
      isDisabled: isHelpDesk,
      icon: <ViewOffIcon boxSize={6} mr="5px" />,
      text: intl.formatMessage({ id: 'devices.action.turn_off_screen' }),
      onClick: () =>
        Alert({
          onConfirm: () => dispatch(turnOffScreen(device?.id)),
          html: (
            <AlertHtml
              text={intl.formatMessage({
                id: 'devices.alert.turn_off_screen.text',
              })}
            />
          ),
          confirmButtonText: intl.formatMessage({
            id: 'global.confirm',
          }),
          cancelButtonText: intl.formatMessage({ id: 'devices.alert.cancel' }),
        }),
    },
    {
      id: DeviceActionEnum.RESTART_DEVICE,
      isHidden: isDeviceAwaitEnterpriseEnroll || isAndroidWorkProfile,
      isDisabled: isHelpDesk,
      icon: <RefreshIcon boxSize={6} mr="5px" />,
      text: intl.formatMessage({ id: 'devices.action.restart_device' }),
      onClick: () =>
        Alert({
          onConfirm: () => {
            setSelectedDevice(device);
            dispatch(restartDevice(device?.id));
          },
          html: (
            <AlertHtml
              text={intl.formatMessage({
                id: 'devices.alert.restart_device.text',
              })}
            />
          ),
          confirmButtonText: intl.formatMessage({
            id: 'global.confirm',
          }),
          cancelButtonText: intl.formatMessage({ id: 'devices.alert.cancel' }),
        }),
    },
    {
      id: DeviceActionEnum.REMOVE_SCREEN_BLOCK,
      isHidden:
        !isBlockSim || isDeviceAwaitEnterpriseEnroll || isAndroidWorkProfile,
      isDisabled: isHelpDesk,
      icon: <LockIcon boxSize={6} mr="5px" />,
      text: intl.formatMessage({ id: 'devices.action.remove_screen_block' }),
      onClick: () =>
        Alert({
          onConfirm: () => dispatch(removeScreenBlockDevice(device?.id)),
          html: (
            <AlertHtml
              text={intl.formatMessage({
                id: 'devices.alert.remove_screen_block.text',
              })}
            />
          ),
          confirmButtonText: intl.formatMessage({
            id: 'global.confirm',
          }),
          cancelButtonText: intl.formatMessage({ id: 'devices.alert.cancel' }),
        }),
    },
    {
      id: DeviceActionEnum.NEW_PASSWORD,
      isHidden:
        isBlockSim || isDeviceAwaitEnterpriseEnroll || isAndroidWorkProfile,
      isDisabled: isHelpDesk,
      icon: <LockIcon boxSize={6} mr="5px" />,
      text: intl.formatMessage({ id: 'devices.action.new_device_password' }),
      onClick: () => modalOpen(ModalTypeEnum.NEW_DEVICE_PASSWORD, device),
    },
    {
      id: DeviceActionEnum.WIPE_DEVICE,
      isHidden: isDeviceAwaitEnterpriseEnroll,
      isDisabled: isHelpDesk,
      icon: <DeleteIcon boxSize={6} mr="5px" color="warning.500" />,
      text: intl.formatMessage({
        id: `devices.action.${[
          isIOSDevice || isAndroidWorkProfile ? 'remove' : 'wipe',
        ]}_device`,
      }),
      onClick: () =>
        Alert({
          onConfirm: () => dispatch(removeDevice(device?.id)),
          html: (
            <AlertHtml
              irreversible={intl.formatMessage({
                id: 'devices.alert.irreversible',
              })}
              text={intl.formatMessage({
                id: 'devices.alert.remove.text',
              })}
            />
          ),
          confirmButtonText: intl.formatMessage({
            id: 'devices.alert.remove.button',
          }),
          cancelButtonText: intl.formatMessage({ id: 'devices.alert.cancel' }),
        }),
      color: 'warning.500',
    },
    {
      id: DeviceActionEnum.MANAGE_INFO,
      isHidden: isDeviceAwaitEnterpriseEnroll,
      icon: <SettingsIcon boxSize={6} mr="5px" />,
      text: intl.formatMessage({ id: 'devices.action.manage_infos' }),
      onClick: () =>
        history.push(
          routeWithParameters(routes.device.infos, {
            id: device?.id,
          })
        ),
    },
    {
      id: DeviceActionEnum.REMOTE_VIEW,
      isHidden: !hasRemoteView || isDeviceAwaitEnterpriseEnroll,
      isDisabled: !remoteViewEnabled,
      icon: <RemoteViewIcon boxSize={6} color="white" mr="5px" />,
      text: intl.formatMessage({ id: 'devices.action.remote_view' }),
      onClick: () => {
        dispatch(setRemoteViewDevice(device));
        handleKeepConnected();
        handleOpenExternalWindow(routes.remoteView, { id: device.id });
      },
    },
  ].filter((action) => handleRenderAction(action.id));

  return (
    <>
      {deviceActions.map(({ id, isHidden, ...actionRest }) => (
        <MenuItem
          key={id}
          display={isHidden ? 'none' : 'block'}
          {...actionRest}
        />
      ))}
    </>
  );
};

export default ActionItems;
