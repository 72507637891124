import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { usePrivilege } from '../../../hooks/usePrivilege';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import {
  dashboardUpdateFilters,
  listDashboardComplete,
} from '../../../store/dashboard';
import { listDeviceUserToFilter } from '../../../store/deviceUser';
import { listGroupsToFilter } from '../../../store/group';
import { DeviceUserType } from '../../../types/deviceUser';
import { Autocomplete, LevelTab } from '../../../types/util';
import Card from '../../Card';
import FormControl from '../../FormControl';
import SelectAutocomplete from '../../SelectAutocomplete';
import Text from '../../Text';

function Filters() {
  const dispatch = useAppDispatch();
  const [filterOpened, setFilterOpened] = useState(true);

  const { filters: selectedFilter } = useAppSelector(
    (state) => state.dashboard
  );
  const { company } = useAppSelector((state) => state.auth.user);
  const { groupsToFilter: groups } = useAppSelector((state) => state.group);
  const { devicesUsersToFilter: users } = useAppSelector(
    (state) => state.deviceUser
  );

  const { accessFilterCompany, accessFilterGroup } = usePrivilege();

  useEffect(() => {
    dispatch(listGroupsToFilter());
    dispatch(listDeviceUserToFilter());
    dispatch(
      dashboardUpdateFilters({
        name: LevelTab.GENERAL,
        value: company,
        nameId: 'companyId',
      })
    );
  }, []);

  useEffect(() => {
    dispatch(listDashboardComplete(selectedFilter));
  }, [selectedFilter.roaming]);

  const handleFilter = (name: LevelTab, value: Autocomplete) => {
    const filterName = {
      [LevelTab.GENERAL]: 'companyId',
      [LevelTab.GROUP]: 'groupId',
      [LevelTab.USER]: 'userId',
    };
    const newFilter = {
      name,
      value,
      roaming: selectedFilter.roaming,
      nameId: filterName[name],
    };
    if (name !== LevelTab.GENERAL || selectedFilter.name !== LevelTab.GENERAL) {
      dispatch(listDashboardComplete(newFilter));
    }
    dispatch(dashboardUpdateFilters(newFilter));
  };

  const handleInput = (name: LevelTab, value: string) => {
    if (name === LevelTab.GROUP) {
      dispatch(listGroupsToFilter({ search: value }));
    }
    if (name === LevelTab.USER) {
      dispatch(listDeviceUserToFilter({ search: value }));
    }
  };

  return (
    <Card
      backgroundColor="transparent"
      p={filterOpened ? '0 0 31px 0' : '0'}
      borderBottomWidth="1px"
      borderColor="gray.600"
      mb="31px"
      borderRadius="0"
      w="100%"
    >
      <Text
        margin="0 0 20px 0"
        color="gray.300"
        fontSize="md"
        fontWeight="bold"
      >
        {!filterOpened && (
          <ChevronDownIcon
            color="gray.600"
            boxSize="8"
            onClick={() => setFilterOpened(!filterOpened)}
            cursor="pointer"
          />
        )}
        {filterOpened && (
          <ChevronUpIcon
            color="gray.600"
            boxSize="8"
            onClick={() => setFilterOpened(!filterOpened)}
            cursor="pointer"
          />
        )}
        <FormattedMessage id="global.filter" />
      </Text>
      <Flex display={filterOpened ? 'flex' : 'none'} align="center">
        {accessFilterCompany && (
          <FormControl
            flex={1}
            textLabel={<FormattedMessage id="global.company" />}
          >
            <SelectAutocomplete
              options={[company]}
              value={
                selectedFilter.name === LevelTab.GENERAL
                  ? selectedFilter.value
                  : null
              }
              onChange={(company: Autocomplete) =>
                handleFilter(LevelTab.GENERAL, company)
              }
              onInputChange={(company) =>
                handleInput(LevelTab.GENERAL, company)
              }
              placeholder={<FormattedMessage id="global.company" />}
            />
          </FormControl>
        )}
        {accessFilterGroup && (
          <FormControl
            flex={1}
            textLabel={<FormattedMessage id="global.groups" />}
          >
            <SelectAutocomplete
              options={groups}
              value={
                selectedFilter.name === LevelTab.GROUP
                  ? selectedFilter.value
                  : null
              }
              onChange={(group: Autocomplete) =>
                handleFilter(LevelTab.GROUP, group)
              }
              onInputChange={(group) => handleInput(LevelTab.GROUP, group)}
              placeholder={<FormattedMessage id="global.groups" />}
            />
          </FormControl>
        )}
        {accessFilterGroup && (
          <FormControl
            flex={1}
            textLabel={<FormattedMessage id="global.users" />}
          >
            <SelectAutocomplete
              options={users}
              value={
                selectedFilter.name === LevelTab.USER
                  ? selectedFilter.value
                  : null
              }
              onChange={(user: Autocomplete) =>
                handleFilter(LevelTab.USER, user)
              }
              onInputChange={(user) => handleInput(LevelTab.USER, user)}
              getOptionLabel={(option: DeviceUserType) =>
                `${option.name || ''} ${option.device?.phoneNumber || ''}`
              }
              placeholder={<FormattedMessage id="global.users" />}
            />
          </FormControl>
        )}
      </Flex>
    </Card>
  );
}

export default Filters;
