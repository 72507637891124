import React from 'react';

import IconButton from '../../../Icons/IconButton';
import PowerIcon from '../../../Icons/RemoteView/ToolkitBar/Power';
import VolumeDownIcon from '../../../Icons/RemoteView/ToolkitBar/VolumeDown';
import VolumeUpIcon from '../../../Icons/RemoteView/ToolkitBar/VolumeUp';
import MockupBar from './MockupBar';

interface ToolkitBarProps {
  handlePower?: () => void;
  handleVolumeUp?: () => void;
  handleVolumeDown?: () => void;
}

const ToolkitBar = ({
  handleVolumeDown,
  handleVolumeUp,
  handlePower,
}: ToolkitBarProps) => {
  return (
    <MockupBar
      h="auto"
      maxH="200px"
      w="45px"
      borderRadius="10px"
      direction="column"
    >
      <IconButton
        aria-label="PowerIcon"
        icon={<PowerIcon />}
        onClick={handlePower}
      />
      <IconButton
        aria-label="VolumeUpIcon"
        icon={<VolumeUpIcon />}
        onClick={handleVolumeUp}
      />
      <IconButton
        aria-label="VolumeDownIcon"
        icon={<VolumeDownIcon />}
        onClick={handleVolumeDown}
      />
    </MockupBar>
  );
};

export default ToolkitBar;
