import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { useSorting } from '../../../helper/sort';
import { Types } from '../../../store/consumptionProfile';
import { generateExcel } from '../../../store/event';
import Table from '../../Table';

const TabPanel = ({
  type,
  columns,
  rows,
  handleSort,
  linkNew,
  setFilterSearch,
  metaData,
  filterSearch,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const staticTable = [
    {
      header: intl.formatMessage({
        id: 'consumption_profile.column.data',
      }),
      accessor: 'data',
    },
    {
      header: intl.formatMessage({
        id: 'consumption_profile.column.data_roaming',
      }),
      accessor: 'roaming_data',
    },
    {
      header: intl.formatMessage({
        id: 'consumption_profile.column.sms',
      }),
      accessor: 'sms',
    },
    {
      header: intl.formatMessage({
        id: 'consumption_profile.column.sms_roaming',
      }),
      accessor: 'roaming_sms',
    },
    {
      header: '',
      accessor: 'actions',
      canSort: false,
    },
  ];

  const columnsData = useSorting([...columns, ...staticTable], metaData);

  const handleExportAllToExcel = () => {
    dispatch(
      generateExcel({
        type: type,
        metadata: metaData,
        filters: { search: filterSearch },
      })
    );
  };

  return (
    <Table
      headerColumns={columnsData}
      rows={rows}
      keyProp={type}
      handleSort={handleSort}
      metadata={metaData}
      pageActionsProps={{
        initialSearch: filterSearch,
        onSearch: setFilterSearch,
        handleExportAllToExcel,
        linkNew,
        labelButtonNew: intl.formatMessage({ id: 'global.register_rule' }),
      }}
      nameTable={`${intl.formatMessage({
        id: 'consumption_profile.title',
      })} - ${intl.formatMessage({
        id: `global.${type === Types.LIST_GROUP ? 'group' : 'user'}`,
      })}`}
    />
  );
};

export default TabPanel;
