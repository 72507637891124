import { Center, Flex } from '@chakra-ui/react';
import QRCode from 'qrcode';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Text from '../../components/Text';
import { useWindowSize } from '../../hooks/useWindowSize';

export const QRCodeCanvas = ({ text }) => {
  const [failure, setFailure] = useState(false);
  const [canvasSize, setCanvasSize] = useState(360);
  const canvasRef = useRef();
  const { size } = useWindowSize();

  useEffect(() => {
    const { clientHeight } = size;
    if (clientHeight <= 520) {
      setCanvasSize(200);
    } else if (clientHeight <= 600) {
      setCanvasSize(250);
    } else if (clientHeight <= 700) {
      setCanvasSize(300);
    } else {
      setCanvasSize(360);
    }
  }, [size]);

  useEffect(() => {
    QRCode.toCanvas(canvasRef.current, text, { width: canvasSize }, () => {
      setFailure(!text);
    });
  }, [text, canvasSize]);

  return (
    <Flex>
      <canvas ref={canvasRef} id="canvas" />
      {failure && (
        <Center>
          <Text>
            <FormattedMessage id="qrcode.failure_generate" />
          </Text>
        </Center>
      )}
    </Flex>
  );
};
