import { useToast as useChakraToast, UseToastOptions } from '@chakra-ui/react';

export const useToast = (options: UseToastOptions) => {
  const toast = useChakraToast({
    duration: 4000,
    isClosable: true,
    position: 'bottom',
    variant: options.status === 'error' ? 'toast_error' : 'toast_success',
    ...options,
  });

  return toast;
};
