import { CopyIcon } from '@chakra-ui/icons';
import { Flex, useToast } from '@chakra-ui/react';
import { truncate } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';

import IconButton from '../../../../Icons/IconButton';
import Text from '../../../../Text';

interface TextWithCopyProps {
  title: string;
  text?: string;
  disabledCopy?: boolean;
  textLimiter?: number;
}

function TextWithCopy({
  title,
  text,
  disabledCopy,
  textLimiter,
}: TextWithCopyProps) {
  const intl = useIntl();
  const toast = useToast({
    duration: 3000,
    isClosable: true,
    position: 'bottom',
    variant: 'toast_success',
    status: 'success',
  });
  const TEXT_LIMITER = textLimiter || 40;
  const titleIntl = intl.formatMessage({ id: title });
  const showCopyButton = text && !disabledCopy;

  async function handleCopy() {
    await navigator.clipboard.writeText(text);
    toast({
      description: intl.formatMessage(
        { id: 'text_with_copy.success' },
        { value: titleIntl }
      ),
    });
  }

  return (
    <Flex align="center" gridGap={2} w="full">
      <Flex gridGap={2} m={0} align="center">
        <Text color="gray.500" fontWeight="bold" my={2}>
          {titleIntl}:
        </Text>
        {text && (
          <Text m={0}>
            {truncate(text, {
              length: TEXT_LIMITER,
            })}
          </Text>
        )}
      </Flex>
      {showCopyButton && (
        <IconButton
          aria-label="Copy button"
          icon={<CopyIcon />}
          onClick={handleCopy}
        />
      )}
    </Flex>
  );
}

export default TextWithCopy;
