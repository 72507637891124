import { Route } from 'react-router-dom';

import LayoutExternal from '../layouts/LayoutExternal';
import { CustomRouteProps } from './Routes';

const ExternalRoute = ({ ...rest }: CustomRouteProps) => {
  return (
    <LayoutExternal>
      <Route {...rest} />
    </LayoutExternal>
  );
};

export default ExternalRoute;
