import { Checkbox, Flex, Tooltip } from '@chakra-ui/react';
import _ from 'lodash';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import { useSorting } from '../../../../helper/sort';
import { useFilter } from '../../../../hooks/useFilter';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import routes from '../../../../routes';
import {
  invoiceDevicesMetadata,
  registerZeroTouch,
  setInvoiceDeviceInfo,
  Types,
  updateInvoice,
} from '../../../../store/invoice';
import { hasLoading, hasSuccess } from '../../../../store/ui';
import { ListMetadata } from '../../../../types/generic_list';
import {
  InvoiceDeviceStatusEnum,
  InvoiceDeviceType,
  RegisterZeroTouchData,
} from '../../../../types/invoice';
import EditableField from '../../../EditableField';
import FormContainer from '../../../FormContainer';
import AlertIcon from '../../../Icons/Alert';
import Table from '../../../Table';
import ModalCustomerID from '../ModalCustomerId';
import { ModalObservation } from '../ModalObservation';
import { invoiceDeviceStatusToIntl } from '../utils';
import InvoiceAccessKey from './components/InvoiceAccessKey';

type UpdateDeviceType = 'SET' | 'REMOVE';

const DevicesDetailsTab = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const {
    invoice,
    initialInvoice,
    invoiceDevicesMetadata: metadata,
    hasCustomerId,
  } = useAppSelector((state) => state.invoice);

  const [selectedDevices, setSelectedDevices] = useState<InvoiceDeviceType[]>(
    []
  );
  const [filterSearch, setFilterSearch] = useState<string>('');
  const [openCustomerIdModal, setOpenCustomerIdModal] =
    useState<boolean>(false);
  const [openObservationModal, setOpenObservationModal] =
    useState<boolean>(false);
  const [observationDevice, setObservationDevice] =
    useState<InvoiceDeviceType>();

  const invoiceIsEqual = _.isEqual(invoice, initialInvoice);
  const isVerifyAccountSuccess = hasSuccess(Types.VERIFY_CUSTOMER_ACCOUNT);
  const successRegisterZeroTouch = hasSuccess(Types.REGISTER_ZERO_TOUCH);
  const isUpdateLoading = hasLoading(Types.UPDATE);
  const isLoading = hasLoading(Types.GET);
  const isRegisterZeroTouchLoading = hasLoading(Types.REGISTER_ZERO_TOUCH);
  const isVerifyAccountLoading = hasLoading(Types.VERIFY_CUSTOMER_ACCOUNT);
  const showObservationStatus = [
    InvoiceDeviceStatusEnum.REGISTERED,
    InvoiceDeviceStatusEnum.REJECT,
    InvoiceDeviceStatusEnum.APPROVED,
  ];

  const { data: listInvoiceDevices } = useFilter(invoice?.devices || [], {
    pagination: {
      metadata,
    },
    filter: (query) =>
      query?.textSearch(['model', 'imei', 'manufacturer'], filterSearch),
  });

  const selectedAll = useMemo(() => {
    const allCheckableDevices = listInvoiceDevices.filter((device) =>
      validateDeviceCanChecked(device)
    );

    return allCheckableDevices.every((checkable) =>
      selectedDevices.some(
        (selected) => selected.productId === checkable.productId
      )
    );
  }, [listInvoiceDevices]);

  useEffect(() => {
    if (isVerifyAccountSuccess && hasCustomerId !== null) {
      setOpenCustomerIdModal(!hasCustomerId);
    }
  }, [hasCustomerId, isVerifyAccountSuccess]);

  useEffect(() => {
    successRegisterZeroTouch && setSelectedDevices([]);
  }, [successRegisterZeroTouch]);

  const columns = useSorting(
    [
      {
        header: '',
        accessor: 'checkbox',
        isExportHidden: true,
        transform: () => {
          return null;

          return (
            <Checkbox
              isDisabled={
                !listInvoiceDevices.some((device) =>
                  validateDeviceCanChecked(device)
                )
              }
              name="selectAll"
              onChange={handleSelectAll}
              isChecked={selectedAll}
            />
          );
        },
      },
      {
        header: intl.formatMessage({
          id: 'invoice_details.column.description',
        }),
        accessor: 'description',
      },
      {
        header: intl.formatMessage({
          id: 'invoice_details.column.imei',
        }),
        accessor: 'imei',
      },
      {
        header: intl.formatMessage({
          id: 'invoice_details.column.manufacturer',
        }),
        accessor: 'manufacturer',
      },
      {
        header: intl.formatMessage({
          id: 'invoice_details.column.model',
        }),
        accessor: 'model',
      },

      {
        header: intl.formatMessage({
          id: 'invoice_details.column.status_zero_touch',
        }),
        accessor: 'statusZeroTouch',
      },
    ],
    metadata
  );

  const data = listInvoiceDevices?.map((device) => ({
    key: device?.productId,
    cells: [
      {
        field: 'checkbox',
        value: '',
        transform: () => {
          return (
            <Checkbox
              isDisabled={!validateDeviceCanChecked(device)}
              id={device.productId}
              name={device.imei}
              onChange={(event) => handleCheckboxChange(device, event)}
              isChecked={
                !!selectedDevices.find(
                  (mapDevice) => mapDevice.productId === device.productId
                )
              }
            />
          );
        },
        isExportHidden: true,
      },
      {
        field: 'description',
        value: device?.description,
        transform: () => <Flex maxW="20rem">{device?.description}</Flex>,
      },
      {
        field: 'imei',
        value: device?.imei,
        chakraProps: {},
        transform: () => {
          return (
            <Flex w="250px">
              <EditableField
                id={device.productId}
                isDisabled={
                  device?.status === InvoiceDeviceStatusEnum.REGISTERED ||
                  device?.status === InvoiceDeviceStatusEnum.WAITING
                }
                defaultValue={device?.imei}
                onSubmit={(newImei) => {
                  const deviceToFill = {
                    ...device,
                    imei: newImei,
                  };
                  const updateMode: UpdateDeviceType = !!newImei
                    ? 'SET'
                    : 'REMOVE';
                  handleUpdateSelectedDevices(deviceToFill, updateMode);
                  dispatch(setInvoiceDeviceInfo(deviceToFill));
                }}
              />
            </Flex>
          );
        },
      },
      {
        field: 'manufacturer',
        value: device?.manufacturer,
      },
      {
        field: 'model',
        value: device?.model,
      },

      {
        field: 'statusZeroTouch',
        value: '',
        transform: () => (
          <Flex alignItems="center" gridGap=".5rem">
            {intl.formatMessage({
              id: `${invoiceDeviceStatusToIntl[device?.status]}`,
            })}

            {showObservationStatus.includes(device.status) && (
              <Tooltip
                hasArrow
                arrowSize={8}
                label={intl.formatMessage({
                  id: 'invoice_details.devices_details.view_observation',
                })}
              >
                <Flex
                  cursor="pointer"
                  onClick={() => {
                    setObservationDevice(device);
                    setOpenObservationModal(true);
                  }}
                >
                  <AlertIcon boxSize={5} />
                </Flex>
              </Tooltip>
            )}
          </Flex>
        ),
      },
    ],
  }));

  function handleSelectAll() {
    setSelectedDevices((prev) => {
      if (selectedAll) {
        return prev.filter(
          (deviceSelected) =>
            !listInvoiceDevices?.some(
              (device) => device.productId === deviceSelected.productId
            )
        );
      }

      return [
        ...prev,
        ...listInvoiceDevices.filter((device) =>
          validateDeviceCanChecked(device)
        ),
      ];
    });
  }

  function validateDeviceCanChecked(device: InvoiceDeviceType): boolean {
    return !(
      device?.status === InvoiceDeviceStatusEnum.REGISTERED ||
      device?.status === InvoiceDeviceStatusEnum.WAITING ||
      !device?.imei
    );
  }

  function handleUpdateSelectedDevices(
    newDevice: InvoiceDeviceType,
    updateMode: UpdateDeviceType
  ) {
    if (updateMode === 'SET') {
      setSelectedDevices((prev) =>
        prev.map((mapDevice) =>
          mapDevice.productId === newDevice.productId ? newDevice : mapDevice
        )
      );
    }
    if (updateMode === 'REMOVE') {
      setSelectedDevices((prev) =>
        prev.filter((mapDevice) => mapDevice.productId !== newDevice.productId)
      );
    }
  }

  function handleCheckboxChange(
    selectedDevice: InvoiceDeviceType,
    event: ChangeEvent<HTMLInputElement>
  ) {
    if (event.target.checked) {
      setSelectedDevices((prev) => [...prev, selectedDevice]);
    } else {
      handleUpdateSelectedDevices(selectedDevice, 'REMOVE');
    }
  }

  function handleMetadata(newMetadata: Partial<ListMetadata>) {
    dispatch(invoiceDevicesMetadata({ ...metadata, ...newMetadata }));
  }

  const handlePrimary = () => {
    dispatch(
      updateInvoice({
        invoiceId: id,
        devices: invoice?.devices,
      })
    );
  };

  function handleRegisterDevices() {
    const data: RegisterZeroTouchData = {
      invoiceId: id,
      devices: selectedDevices,
    };

    dispatch(registerZeroTouch(data));
  }

  const handleSearch = (newSearch: string) => {
    setFilterSearch(newSearch);
    setSelectedDevices([]);
  };

  return (
    <>
      <FormContainer
        labelPrimary={<FormattedMessage id={'invoice_details.button.save'} />}
        handlePrimary={handlePrimary}
        disabledPrimary={invoiceIsEqual}
        loadingPrimary={isUpdateLoading}
        labelSecondary={<FormattedMessage id={'global.cancel'} />}
        handleSecondary={() => history.push(routes.zeroTouch.invoices.manage)}
        mb={8}
      >
        <InvoiceAccessKey
          invoiceAccessKey={invoice?.accessKey}
          isLoading={isLoading}
        />

        <Table
          headerColumns={columns}
          rows={data}
          handleSort={handleMetadata}
          metadata={metadata}
          keyProp={Types.GET}
          pageActionsProps={{
            onSearch: handleSearch,
            labelPrimaryButton: intl.formatMessage({
              id: `invoice_details.button.register`,
            }),
            disabledPrimaryButton: selectedDevices?.length === 0,
            isPrimaryButtonLoading:
              isVerifyAccountLoading || isRegisterZeroTouchLoading,
            onClickPrimaryButton: handleRegisterDevices,
            disabledAllExportsActions: true,
          }}
          nameTable={`${intl.formatMessage({
            id: 'invoice_details.title',
          })}`}
        />
      </FormContainer>

      {openCustomerIdModal && (
        <ModalCustomerID
          isOpen={openCustomerIdModal}
          closeModal={() => setOpenCustomerIdModal(false)}
        />
      )}

      <ModalObservation
        isOpen={openObservationModal}
        onClose={() => setOpenObservationModal(false)}
        device={observationDevice}
      />
    </>
  );
};

export default DevicesDetailsTab;
