import { Flex } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import Button from '../Button';
import Modal from '../Modal';
import Text from '../Text';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  secondsRemaining?: number;
  onConfirm?: () => void;
}

export const ModalExpirationSession = ({
  isOpen,
  onClose,
  secondsRemaining,
  onConfirm,
}: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      modalWidth="500px"
    >
      <Flex
        h="100%"
        flexDirection="column"
        justify="center"
        w="100%"
        align="center"
        gridGap={6}
        px={4}
        py={8}
      >
        <Text color="gray.900" fontWeight="bold" fontSize="22px">
          <FormattedMessage
            id="inactivity_alert.title"
            values={{
              seconds: secondsRemaining,
            }}
          />
        </Text>

        <Flex>
          <Text fontStyle="italic" color="gray.900" fontSize="18px">
            <FormattedMessage id="inactivity_alert.description" />
          </Text>
        </Flex>

        <Flex>
          <Button w="180px" h="45px" onClick={onConfirm}>
            <FormattedMessage id="inactivity_alert.confirm" />
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
