import {
  HStack,
  PinInput,
  PinInputField,
  PinInputProps,
} from '@chakra-ui/react';
import _ from 'lodash';
import { useMemo } from 'react';

interface PinInputType extends Omit<PinInputProps, 'children'> {
  numberOfInputs?: number;
  onChange: (e) => void;
  isError?: boolean;
}

const PinInputs = ({ numberOfInputs = 6, isError, ...rest }: PinInputType) => {
  const renderInputs = useMemo(
    () =>
      [...Array(numberOfInputs)].map(() => (
        <PinInputField key={_.uniqueId()} />
      )),
    [numberOfInputs]
  );

  return (
    <HStack>
      <PinInput
        type="alphanumeric"
        errorBorderColor="warning.500"
        isInvalid={!!isError}
        variant="flushed"
        focusBorderColor="primary.500"
        {...rest}
      >
        {renderInputs}
      </PinInput>
    </HStack>
  );
};

export default PinInputs;
