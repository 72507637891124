import Icon from '@chakra-ui/icon';
import React from 'react';

const RemoteViewIcon = (props) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M24 49.3c-4.9 1.6-13.3 10.2-14.8 15.3-1.7 6-1.7 312.8 0 318.8 1.6 5.5 9.9 13.8 15.4 15.4 6 1.8 456.8 1.8 462.8 0 5.5-1.6 13.8-9.9 15.4-15.4 1.7-5.9 1.8-312.8 0-318.6-.6-2.2-3.1-6.2-5.5-8.8-7.9-8.8 15.5-8-241.7-7.9-163.3.1-229.1.4-231.6 1.2zm461.5 17.2 2.5 2.4V336H44.9l-2.4 2.5c-3.3 3.2-3.3 7.8 0 11l2.4 2.5H488v13.5c0 13.1-.1 13.7-2.5 16l-2.4 2.5H28.9l-2.4-2.5-2.5-2.4V68.9l2.5-2.4 2.4-2.5h454.2l2.4 2.5z" />
        <path d="M250.5 98.5c-2.5 2.4-2.5 2.7-2.5 19.9v17.5l-4.2.5c-2.4.3-7 .8-10.3 1.2-9.6 1-23.5 4-34.9 7.7l-10.6 3.5-7.6-14.9c-4.3-8.2-8.6-15.6-9.6-16.6-4.2-3.8-12.8-.1-12.8 5.5 0 1.3 3.4 9 7.5 17.1 4.1 8 7.5 14.9 7.5 15.3 0 .3-3.5 2.5-7.7 4.8-8.4 4.5-20.9 13.2-28 19.4l-4.3 3.8-7.6-6.8C108.2 160.8 106 159 102.9 159c-3.8 0-7.9 4-7.9 7.7 0 2.3 2.3 5.1 12.9 15.3l13 12.5-4.4 5.3c-5.3 6.3-5.8 10-2 13.7 4.8 4.9 6.9 3.9 21.7-10.8 22.4-22.5 45.3-36.2 73.4-44.1 62.3-17.5 129.3 1.6 171.9 49 6.1 6.8 7 7.4 10.5 7.4 3 0 4.3-.6 5.9-2.6 1.2-1.5 2.1-3.7 2.1-4.9 0-2.3-4.3-8.9-7.4-11.5-1.7-1.3-.8-2.4 11.4-14.1 13-12.3 13.3-12.7 12.6-16-.8-4.2-3.9-6.9-8-6.9-2.6 0-5.3 2-16.1 11.9l-13 11.9-5.5-4.5c-6.7-5.4-23.7-16.9-30.2-20.3-2.7-1.4-4.8-2.9-4.8-3.4 0-.4 3.4-7.2 7.5-15.1 4.1-7.8 7.5-15.2 7.5-16.3 0-1.2-1.1-3.3-2.3-4.8-2.8-3.3-7.6-3.7-10.7-.9-1.1 1-5.5 8.5-9.7 16.6-6.7 12.7-7.9 14.6-9.5 13.8-6.7-3.6-32.9-9.3-52-11.4l-5.8-.6v-16.5c0-16.6-.5-19.5-3.9-22.1-2.6-2-7-1.5-9.6 1.2z" />
        <path d="M244.1 169.6c-25.3 6.8-41 33.1-34.6 57.9 6.7 25.6 32.9 41.5 58 35 25.6-6.7 41.5-32.9 35-57.8-6.7-25.9-33.3-41.9-58.4-35.1zm27 18.5c6.7 3.6 9.8 6.8 13.7 14.3 2.2 4.3 2.6 6.4 2.6 13.6.1 7.8-.2 9-3.3 14.8-3.8 7-7 10-14.5 14-4.3 2.2-6.4 2.6-13.6 2.6-7.2 0-9.3-.4-13.6-2.6-7.5-4-10.7-7-14.5-14-3.1-5.8-3.4-7-3.3-14.8 0-10 2.4-16 8.9-22.5 10-10 24.9-12.1 37.6-5.4z" />
        <path d="M149 233.3c-3.4 1.7-5.5 5.7-4.6 9 .8 3.5 14.9 17.7 24.6 24.9 44.7 33.2 104.7 38 153.8 12.3 14.1-7.4 24.3-14.9 35.6-26.4 8.1-8.3 9.6-10.3 9.6-13 0-4.1-3.8-8.1-7.9-8.1-2.4 0-4.8 1.8-12.8 9.8-19 18.9-41 30.6-67.1 35.9-12.1 2.4-37.5 2.4-49.1-.1-26.5-5.7-46.2-16.1-65.6-34.7-5.5-5.3-10.9-9.9-12-10.2-1.1-.4-3.1-.1-4.5.6zM218.5 418.5c-2.4 2.3-2.5 2.9-2.5 16V448h-59.1l-2.4 2.5c-1.6 1.5-2.5 3.6-2.5 5.5s.9 4 2.5 5.5l2.4 2.5h198.2l2.4-2.5c1.6-1.5 2.5-3.6 2.5-5.5s-.9-4-2.5-5.5l-2.4-2.5H296v-13.5c0-13.1-.1-13.7-2.5-16-1.5-1.6-3.6-2.5-5.5-2.5s-4 .9-5.5 2.5c-2.4 2.3-2.5 2.9-2.5 16V448h-48v-13.5c0-13.1-.1-13.7-2.5-16-1.5-1.6-3.6-2.5-5.5-2.5s-4 .9-5.5 2.5z" />
      </svg>
    </Icon>
  );
};
export default RemoteViewIcon;
