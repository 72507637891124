import { ThemeType } from '../theme';

const theme: ThemeType = {
  colors: {
    primary: {
      500: '#17b89b', // primary
      600: '#008C75', // secondary
      900: '#005B4C', // shade of secondary
    },
  },
  images: {
    logo: 'https://storage.googleapis.com/datamob/assets/logo_dashboard.svg',
    logo_dashboard:
      'https://storage.googleapis.com/datamob/assets/logo_dashboard.svg',
  },
};

export default theme;
