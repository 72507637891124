import { Tabs, TabList, TabPanels, TabPanel } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import DatePicker from '../../../components/Datepicker';
import FormContainer from '../../../components/FormContainer';
import FormControl from '../../../components/FormControl';
import PageFilter from '../../../components/PageFilter';
import DataTable from '../../../components/pages/Companies/CompanyConsumption/dataTable';
import SmsTable from '../../../components/pages/Companies/CompanyConsumption/smsTable';
import PageTitle from '../../../components/PageTitle';
import Tab from '../../../components/Tab';
import { inFirstPage } from '../../../helper/metadata';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import {
  listCompanyConsumptionData,
  listCompanyConsumptionSms,
  Types,
} from '../../../store/company';
import {
  hasTable,
  selectorLoading,
  selectorMetadata,
  uiRemoveMetadata,
  uiRemoveTable,
  uiShowTable,
} from '../../../store/ui';

interface LocationTypeFilter {
  startDate?: Date;
  endDate?: Date;
}

enum TabEnum {
  DATA,
  SMS,
}

const CompanyConsumption = () => {
  const dispatch = useAppDispatch();
  const { consumptionsData, consumptionsSms } = useAppSelector(
    (state) => state.company
  );
  const { portalSettings } = useAppSelector((state) => state.portalSettings);
  const [tabSelected, setTabSelected] = useState<TabEnum>(TabEnum.DATA);
  const [dateInput, setDateInput] = useState<LocationTypeFilter>({
    startDate: null,
    endDate: null,
  });
  const [lastFetch, setLastFetch] = useState({
    startDate: null,
    endDate: null,
    search: null,
  });
  const [filterSearch, setFilterSearch] = useState(null);
  const TYPE_LIST =
    tabSelected === TabEnum.DATA
      ? Types.CONSUMPTION_DATA
      : Types.CONSUMPTION_SMS;

  const showTable = hasTable(TYPE_LIST);

  const isLoading = useAppSelector(selectorLoading(TYPE_LIST));

  const metadata = useAppSelector(selectorMetadata(TYPE_LIST));

  const allFilters = {
    startDate: dateInput?.startDate,
    endDate: dateInput?.endDate,
    search: filterSearch,
  };

  const lastFilterWithSearch = {
    ...lastFetch,
    search: filterSearch,
  };

  const listByTabSelected = {
    [TabEnum.DATA]: listCompanyConsumptionData,
    [TabEnum.SMS]: listCompanyConsumptionSms,
  };

  useEffect(() => {
    return () => {
      dispatch(uiRemoveMetadata(TYPE_LIST));
      dispatch(uiRemoveTable(TYPE_LIST));
    };
  }, [TYPE_LIST]);

  useEffect(() => {
    if (showTable) {
      setDateInput(lastFilterWithSearch);
      dispatch(
        listByTabSelected[tabSelected](
          inFirstPage(metadata),
          lastFilterWithSearch
        )
      );
    }
  }, [allFilters?.search]);

  const handleTabsChange = (index) => {
    setTabSelected(index);
    setDateInput({
      startDate: null,
      endDate: null,
    });
  };

  const handlePeriodFilterChange = (date: Date, field: string) => {
    setDateInput({ ...dateInput, [field]: date });
  };

  const handleFilter = () => {
    dispatch(uiShowTable(TYPE_LIST));
    setLastFetch({
      ...lastFetch,
      startDate: dateInput?.startDate,
      endDate: dateInput?.endDate,
    });
    dispatch(listByTabSelected[tabSelected](inFirstPage(metadata), allFilters));
  };

  const handleFilterSearch = (newSearch: string) => {
    setFilterSearch(newSearch);
    setLastFetch({
      ...allFilters,
      search: newSearch,
    });
  };

  return (
    <>
      <PageTitle
        showManualIcon
        title={<FormattedMessage id="company_consumption.title" />}
        description={<FormattedMessage id="company_consumption.description" />}
      />
      <Tabs onChange={handleTabsChange}>
        <TabList>
          <Tab showOperationalSystems={portalSettings.allowDeviceIOS}>
            <FormattedMessage id="company_consumption.tabs_data" />
          </Tab>
          <Tab hideIOSSystem={portalSettings.allowDeviceIOS}>
            <FormattedMessage id="company_consumption.tabs_sms" />
          </Tab>
        </TabList>
        <FormContainer
          labelFilter={<FormattedMessage id="global.search" />}
          handleFilter={handleFilter}
          disabledFilter={!dateInput?.startDate || !dateInput?.endDate}
          loadingFilter={isLoading}
        >
          <PageFilter>
            <FormControl
              w="176px"
              mr="24px"
              textLabel={
                <FormattedMessage id="company_consumption.start_date" />
              }
            >
              <DatePicker
                name="startDate"
                selected={dateInput?.startDate}
                onChange={(e) => {
                  handlePeriodFilterChange(e, 'startDate');
                }}
                checkValue={dateInput?.startDate}
                hasValue={dateInput?.startDate}
                selectedsStart
                maxDate={dateInput?.endDate || new Date()}
                autoComplete={false}
              />
            </FormControl>
            <FormControl
              w="176px"
              textLabel={<FormattedMessage id="company_consumption.end_date" />}
            >
              <DatePicker
                name="endDate"
                selected={dateInput?.endDate}
                onChange={(e) => {
                  handlePeriodFilterChange(e, 'endDate');
                }}
                checkValue={dateInput?.endDate}
                hasValue={dateInput?.endDate}
                minDate={dateInput.startDate}
                maxDate={new Date()}
                autoComplete={false}
              />
            </FormControl>
          </PageFilter>
        </FormContainer>
        {showTable && (
          <TabPanels>
            <TabPanel>
              <DataTable
                data={consumptionsData}
                allFilters={allFilters}
                setSearch={handleFilterSearch}
                keyProps={TYPE_LIST}
                lastFetch={lastFetch}
              />
            </TabPanel>
            <TabPanel>
              <SmsTable
                data={consumptionsSms}
                allFilters={allFilters}
                setSearch={setFilterSearch}
                keyProps={TYPE_LIST}
                lastFetch={lastFetch}
              />
            </TabPanel>
          </TabPanels>
        )}
      </Tabs>
    </>
  );
};

export default CompanyConsumption;
