import { Redirect, Route } from 'react-router-dom';

import { usePrivilege } from '../hooks/usePrivilege';
import { useAppSelector } from '../hooks/useRedux';
import LayoutPublic from '../layouts/LayoutPublic';
import { CustomRouteProps, homeByPrivilege } from './Routes';

const PublicRoute = ({ ...rest }: CustomRouteProps) => {
  const authStore = useAppSelector((state) => state.auth);
  const { pages: privilegePages } = usePrivilege();

  if (
    authStore.isLogged &&
    privilegePages[homeByPrivilege[authStore?.user?.privilege]]
  ) {
    return <Redirect to={homeByPrivilege[authStore?.user?.privilege]} />;
  }

  return (
    <LayoutPublic>
      <Route {...rest} />
    </LayoutPublic>
  );
};

export default PublicRoute;
