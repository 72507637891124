import { ThemeType } from '../theme';

const theme: ThemeType = {
  colors: {
    primary: {
      500: '#000000', // primary
      600: '#AF272F', // secondary
      900: '#8D1F26', // shade of secondary
    },
  },
  images: {
    logo: 'https://storage.googleapis.com/enterprise-claroch/assets/logo_login.png',
    logo_dashboard:
      'https://storage.googleapis.com/enterprise-claroch/assets/logo_dashboard.png',
  },
};

export default theme;
