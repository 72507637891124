import { Flex, UnorderedList } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { TimelineItem, TimelineItemTitle } from '..';

import { ReportSiteDateType } from '../../../types/reports';
import { FormatDateByUserLogged } from '../../FormatDateByUserLogged';
import Text from '../../Text';
import TimelineSite from '../TimelineSite';
import TimelineSkeleton from '../TimelineSkeleton';

interface TimelimeProps {
  rows: ReportSiteDateType[];
  isLoading: boolean;
  hasSomeUserSelected: boolean;
}

function Timeline({ rows, hasSomeUserSelected, isLoading }: TimelimeProps) {
  const isEmpty = hasSomeUserSelected && rows.length === 0;

  if (hasSomeUserSelected && isLoading) {
    return <TimelineSkeleton />;
  }

  return (
    <UnorderedList ml="0" display="flex" flexGrow={1} flexDirection="column">
      {isEmpty ? (
        <Flex align="center" justify="center">
          <Text>
            <FormattedMessage id="global.empty_results" />
          </Text>
        </Flex>
      ) : (
        rows.map((timelineItem, index) => {
          return (
            <React.Fragment key={index}>
              <TimelineItemTitle>{timelineItem.date}</TimelineItemTitle>
              {timelineItem.items?.map((item, index) => {
                return (
                  <TimelineItem
                    key={`subitem-${index}`}
                    leftContent={
                      <FormatDateByUserLogged
                        date={item.accessedAt}
                        timeFormat="medium"
                      />
                    }
                    rightContent={<TimelineSite label={item.domain} />}
                    markColor={item.markColor}
                  />
                );
              })}
            </React.Fragment>
          );
        })
      )}
    </UnorderedList>
  );
}

export default Timeline;
