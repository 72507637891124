import { Box, Divider, Text } from '@chakra-ui/react';
import { Duration } from 'date-fns';
import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import currentPositionMap from '../../../assets/Images/current-position-map.svg';
import initialPositionMap from '../../../assets/Images/initial-position-map-contained.svg';
import lastPositionMap from '../../../assets/Images/last-position-map-contained.svg';
import { formatDate } from '../../../helper/date';
import { useFormatDate } from '../../../hooks/useFormatDate';
import { useAppSelector } from '../../../hooks/useRedux';
import { LocationType } from '../../../types/locations';
import MapInitialPositionIcon from '../../Icons/MapInitialPosition';
import MapLastPositionIcon from '../../Icons/MapLastPosition';
import MapPresentPositionIcon from '../../Icons/MapPresentPosition';
import { Map } from '../../Map';
import WindowCloud from './WindowCloud';

interface GeolocationMapProps {
  locations: LocationType[];
  showMap: boolean;
  convertTimezone: boolean;
}

export const GeolocationMap = ({
  locations,
  showMap,
  convertTimezone,
}: GeolocationMapProps) => {
  const intl = useIntl();
  const { handleFormatDateByUserLogged } = useFormatDate();
  const { user } = useAppSelector((state) => state.auth);

  const mapIcons = {
    currentPosition: currentPositionMap,
    initialPosition: initialPositionMap,
    lastPosition: lastPositionMap,
  };

  const changePosition = (currentIndex, lastIndex) => {
    if (currentIndex === 0) {
      return 'initialPosition';
    }
    if (currentIndex === lastIndex) {
      return 'currentPosition';
    }
    return 'lastPosition';
  };

  const convertToMinutes = (period: Duration) => {
    const daysToMin = period.days * 1440;
    const hoursToMin = period.hours * 60;

    return daysToMin + hoursToMin + period.minutes;
  };

  const markersFilteredByDate =
    locations?.length > 0
      ? locations.map((dataInfo, currentIndex, array) => {
          return {
            name: changePosition(currentIndex, array.length - 1),
            lat: parseFloat(dataInfo.latitude),
            lng: parseFloat(dataInfo.longitude),
            address: dataInfo.address,
            speed: dataInfo.speed,
            precision: +dataInfo.precision,
            id: dataInfo.id,
            icon: mapIcons[changePosition(currentIndex, array.length - 1)],
            showIndex: currentIndex !== locations.length - 1,
            zIndex: currentIndex,
            infos: {
              date: convertTimezone
                ? handleFormatDateByUserLogged(dataInfo.createdAt)
                : formatDate(user.language, 'UTC', dataInfo.createdAt),
              state: dataInfo?.downtime
                ? `${intl.formatMessage({
                    id: 'geolocation.stopped',
                  })}: min: ${convertToMinutes(dataInfo.downtime).toFixed()}`
                : intl.formatMessage({ id: 'geolocation.moving' }),
              precision: Math.trunc(+dataInfo.precision),
              battery: dataInfo.battery,
            },
          };
        })
      : [];

  return (
    <>
      <Text m="2% 0% 1% 0%" fontSize="md" fontWeight="600">
        <FormattedMessage id="geolocation.label" />
      </Text>
      <Box>
        <Divider borderColor="gray.600" orientation="horizontal" />
      </Box>
      <Box
        m="1% 0%"
        d="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Text m="0" fontSize="sm" d="flex">
          <MapInitialPositionIcon boxSize={6} mr="5px" color="map.100" />
          <FormattedMessage id="geolocation.initial_position" />
        </Text>
        <Text m="0" fontSize="sm" d="flex">
          <MapPresentPositionIcon boxSize={6} mr="5px" color="map.300" />
          <FormattedMessage id="geolocation.current_position" />
        </Text>
        <Text m="0" fontSize="sm" d="flex">
          <MapLastPositionIcon boxSize={6} mr="5px" color="map.200" />
          <FormattedMessage id="geolocation.position_history" />
        </Text>
      </Box>
      {showMap && (
        <Box mt="1%">
          <Map
            markers={markersFilteredByDate}
            WindowCloud={WindowCloud}
            hasClusterer
            strokeBetweenMarkers
          />
        </Box>
      )}
    </>
  );
};
export default memo(GeolocationMap);
