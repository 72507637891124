import { Box, Flex, useToast, UseToastOptions } from '@chakra-ui/react';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Alert, { AlertHtml } from '../../../components/Alert';
import Spinner from '../../../components/Spinner';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import {
  enterpriseWebTokenClear,
  getEnterpriseWebToken,
} from '../../../store/company';
import { Types as TypesCompany } from '../../../store/company';
import { selectEnterpriseApp, Types } from '../../../store/enterpriseApps';
import {
  hasError,
  hasLoading,
  hasSuccess,
  uiRemoveError,
  uiRemoveSuccess,
} from '../../../store/ui';

type SelectedAppType = {
  action: string;
  productId: string;
  packageName: string;
};

const GOOGLE_SELECT_PRODUCT_EVENT = 'onproductselect';

const GooglePlayManaged = () => {
  const dispatch = useAppDispatch();
  const { enterpriseWebToken } = useAppSelector((state) => state.company);
  const [iframeEventListener, setIframeEventListener] = useState(null);
  const intl = useIntl();
  const selectedAppId = 'selectedAppToast';
  const isLoading = hasLoading(TypesCompany.GET_ENTERPRISE_WEB_TOKEN);

  const selectedSuccess = hasSuccess(Types.SELECT_APP);
  const selectedError = hasError(Types.SELECT_APP);

  const IFRAME_GOOGLE_URL = `https://play.google.com/work/embedded/search?&mode=SELECT&token=${enterpriseWebToken}`;

  const gapi = window.gapi;
  const iframe = document.getElementById('iframeId');

  const toast = useToast({
    id: selectedAppId,
    duration: 4000,
    isClosable: true,
    position: 'bottom',
    variant: 'toast_success',
    status: 'success',
    description: intl.formatMessage({
      id: 'google_play_managed.toast.success.description',
    }),
  });

  useLayoutEffect(() => {
    if (!!iframe && !!enterpriseWebToken) {
      gapi.load('gapi.iframes', async () => {
        const options = {
          url: IFRAME_GOOGLE_URL,
          where: await iframe,
          attributes: {
            style: 'width: 100%; height:calc(100vh - 90px)',
            scrolling: 'yes',
          },
        };
        const response = await gapi?.iframes?.getContext().openChild(options);
        return setIframeEventListener(response);
      });
    }
  }, [enterpriseWebToken, iframe, gapi]);

  useEffect(() => {
    dispatch(
      getEnterpriseWebToken({
        parentFrameUrl: window.location.href,
      })
    );
    return () => {
      dispatch(enterpriseWebTokenClear());
    };
  }, []);

  useEffect(() => {
    if (!toast.isActive(selectedAppId)) {
      if (selectedSuccess) {
        toast();
      }

      if (selectedError) {
        showToastError();
      }
    }
    dispatch(uiRemoveSuccess(Types.SELECT_APP));
    dispatch(uiRemoveError(Types.SELECT_APP));
  }, [selectedSuccess, selectedError]);

  useEffect(() => {
    if (iframeEventListener) {
      iframeEventListener?.register(
        GOOGLE_SELECT_PRODUCT_EVENT,
        function (selectedApp: SelectedAppType) {
          return Alert({
            onConfirm: () => handleSelectApp(selectedApp),
            html: (
              <AlertHtml
                text={intl.formatMessage({
                  id: 'google_play_managed.toast.success.modal.text',
                })}
              />
            ),
            confirmButtonText: intl.formatMessage({
              id: 'global.confirm',
            }),
            cancelButtonText: intl.formatMessage({
              id: 'global.cancel',
            }),
          });
        },
        gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER
      );
    }
  }, [iframeEventListener]);

  function handleSelectApp(selectedApp: SelectedAppType) {
    dispatch(
      selectEnterpriseApp({
        packageName: selectedApp.packageName,
      })
    );
  }

  function showToastError() {
    let errorVariant: UseToastOptions['variant'] = 'toast_error';

    if (
      selectedError?.messages &&
      Object.keys(selectedError?.messages)?.includes('plan')
    ) {
      errorVariant = 'toast_warning';
    }

    toast({
      variant: errorVariant,
      status: 'error',
      description: formatToastErrorDescription(),
    });
  }

  function formatToastErrorDescription(): string {
    const defaultErrorMessage = intl.formatMessage({
      id: 'google_play_managed.toast.error.description',
    });

    if (selectedError?.messages) {
      const newMessage = Object.keys(selectedError?.messages)?.reduce(
        (prevMessage, key) => {
          const nextMessage = prevMessage?.length
            ? `${prevMessage}. ${selectedError?.messages[key]?.join(', ')}`
            : selectedError?.messages[key]?.join(', ');

          return nextMessage || defaultErrorMessage;
        },
        ''
      );

      return newMessage;
    }

    if (selectedError?.message) {
      return selectedError?.message;
    }

    return defaultErrorMessage;
  }

  return (
    <Flex
      m="-3% -4% -2% -4%"
      height="calc(100vh - 90px)"
      maxH="calc(100vh - 90px)"
    >
      {!gapi ||
        (isLoading && (
          <Spinner size={'xl'} position="absolute" top="50%" left="50%" />
        ))}
      <Box id="iframeId" title="googlePlayManaged" w="100%" />
    </Flex>
  );
};

export default GooglePlayManaged;
