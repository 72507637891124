import { Icon } from '@chakra-ui/react';
import React from 'react';

const GoBackIcon = (props) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="#e8e3e3"
        viewBox="0 0 256 256"
      >
        <path d="M165.66 202.34a8 8 0 0 1-11.32 11.32l-80-80a8 8 0 0 1 0-11.32l80-80a8 8 0 0 1 11.32 11.32L91.31 128Z" />
      </svg>
    </Icon>
  );
};

export default GoBackIcon;
