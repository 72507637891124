import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
  chakra,
  Text,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Divider,
} from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import routes from '../../../routes';
import Button from '../../Button';
import AlertModal from '../../Icons/AlertModal';

interface ErrorModalProps {
  open: boolean;
  onClose: () => void;
  error: Error;
}

export enum AuthCredentialErrorEnum {
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  FOUR_FAILED_ATTEMPTS = 'FOUR_FAILED_ATTEMPTS',
  FIVE_FAILED_ATTEMPTS = 'FIVE_FAILED_ATTEMPTS',
  NEED_CHANGE_PASSWORD = 'NEED_CHANGE_PASSWORD',
  LOGIN_CONFIRMATION = 'LOGIN_CONFIRMATION',
  INVALID_TOKEN = 'INVALID_TOKEN',
}

const ErrorModal = ({ open, onClose, error }: ErrorModalProps) => {
  const intl = useIntl();

  const defaultInvalidCredentials = {
    title: intl.formatMessage({
      id: 'login.modal.title.invalid_user_password',
    }),
    text: intl.formatMessage({
      id: 'login.modal.invalid_user_password',
    }),
  };

  const errors_config = {
    [AuthCredentialErrorEnum.INVALID_CREDENTIALS]: defaultInvalidCredentials,
    [AuthCredentialErrorEnum.FOUR_FAILED_ATTEMPTS]: {
      title: intl.formatMessage({
        id: 'login.modal.title.invalid_user_password',
      }),
      text: intl.formatMessage({
        id: 'login.modal.invalid_user_password',
      }),
      warning: intl.formatMessage({
        id: 'login.modal.user_last_try',
      }),
    },
    [AuthCredentialErrorEnum.FIVE_FAILED_ATTEMPTS]: {
      title: intl.formatMessage({
        id: 'login.modal.title.invalid_user_password',
      }),
      text: intl.formatMessage({
        id: 'login.modal.invalid_user_password',
      }),
      warning: intl.formatMessage({
        id: 'login.modal.block_user',
      }),
    },
    [AuthCredentialErrorEnum.NEED_CHANGE_PASSWORD]: {
      title: intl.formatMessage({
        id: 'login.modal.title.not_validated',
      }),
      text: intl.formatMessage({
        id: 'login.modal.not_validated',
      }),
      disableForgotPass: true,
    },
  };
  if (!error) {
    return null;
  }
  const errorsMessages =
    errors_config[error?.message] || defaultInvalidCredentials;

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent w="424px" h="450px">
        <ModalHeader d="flex" flexDirection="column" alignItems="center">
          <AlertModal boxSize={24} mt="20px" color="primary.500" />
        </ModalHeader>
        <ModalBody d="flex" flexDirection="column" alignItems="center">
          {errorsMessages.title && (
            <Text fontWeight="bold" fontSize="16px">
              {errorsMessages.title}
            </Text>
          )}
          {errorsMessages.text && (
            <Text fontWeight="normal" align="center" fontSize="14px" mt="10px">
              {errorsMessages.text}
            </Text>
          )}
          {errorsMessages.warning && (
            <Text
              fontWeight="bold"
              fontSize="14px"
              mt="30px"
              color="warning.500"
              textAlign="center"
            >
              {errorsMessages.warning}
            </Text>
          )}
          {errorsMessages.blocked_text && (
            <Text fontWeight="bold" fontSize="16px" textAlign="center">
              <chakra.span color="warning.500">Usuário bloqueado </chakra.span>
              para iniciar sesíon, se enviará um correo electrónico para
              desbloquear o esperar 15 minutos.
            </Text>
          )}

          <Text
            fontWeight="normal"
            fontSize="14px"
            mt="30px"
            color="primary.500"
            cursor="pointer"
          >
            {errorsMessages.blocked_text ? (
              <Link to={routes.unblock}>
                <FormattedMessage id="login.modal.unblock_user" />
              </Link>
            ) : (
              !errorsMessages.disableForgotPass && (
                <Link to={routes.passwordRecovery}>
                  <FormattedMessage id="login.forgot_password" />
                  <ArrowForwardIcon ml="3px" />
                </Link>
              )
            )}
          </Text>
        </ModalBody>

        <ModalFooter d="flex" flexDirection="column" alignSelf="center">
          <Box mb="19px" w="424px">
            <Divider borderColor="gray.600" orientation="horizontal" />
          </Box>
          <Box d="flex" flexDirection="row">
            {errorsMessages.blocked_text ? (
              <Box mr="14px">
                <Button
                  w="180px"
                  h="45px"
                  fontWeight="normal"
                  onClick={onClose}
                >
                  <FormattedMessage id="global.close" />
                </Button>
              </Box>
            ) : (
              <Box mr="14px">
                <Button
                  w="180px"
                  h="45px"
                  fontWeight="normal"
                  onClick={onClose}
                >
                  <FormattedMessage id="global.try_again" />
                </Button>
              </Box>
            )}
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ErrorModal;
