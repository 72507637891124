import { Box, Tooltip } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';

import FormControl from '../../components/FormControl';
import Text from '../../components/Text';
import InputPassword from '../InputPassword';

interface ChangePasswordProps {
  form: {
    password: string;
    confirmPassword?: string;
  };
  setForm: (p: { password: string; confirmPassword?: string }) => void;
}

const ChangePassword = ({ form, setForm }: ChangePasswordProps) => {
  const intl = useIntl();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value || e.target.checked });
  };

  const alertPassword = () => {
    if (
      form.password &&
      form.confirmPassword &&
      form.password !== form.confirmPassword
    ) {
      return (
        <Text color="warning.500" m="0 0 0 0" fontWeight="bold" fontSize="sm">
          <FormattedMessage id="register_company.password_alert" />
        </Text>
      );
    }
  };

  return (
    <>
      <FormControl
        pr="2%"
        w="300px"
        textLabel={
          <Tooltip
            label={<FormattedMessage id="register_company.password" />}
            hasArrow
          >
            {intl.formatMessage({
              id: 'register_company.password',
            })}
          </Tooltip>
        }
      >
        <InputPassword
          inputProps={{
            name: 'password',
            value: form.password || '',
            onChange: handleInputChange,
          }}
        />
        <Box h="21px">
          {!form?.password && (
            <Text color="warning.500" fontSize="sm" fontWeight="bold" m="0">
              <FormattedMessage id="register_company.empty_password_alert" />
            </Text>
          )}
        </Box>
      </FormControl>
      <FormControl
        mt="2%"
        w="300px"
        textLabel={
          <Tooltip
            label={<FormattedMessage id="register_company.confirm_password" />}
            hasArrow
          >
            {intl.formatMessage({
              id: 'register_company.confirm_password',
            })}
          </Tooltip>
        }
      >
        <InputPassword
          inputProps={{
            name: 'confirmPassword',
            value: form.confirmPassword || '',
            onChange: handleInputChange,
          }}
        />
        <Box my="6">{alertPassword()}</Box>
      </FormControl>
    </>
  );
};

export default ChangePassword;
