import { Flex } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import IconButton from '../../../Icons/IconButton';
import StartIcon from '../../../Icons/RemoteView/ConnectionBar/Start';
import StopIcon from '../../../Icons/RemoteView/ConnectionBar/Stop';
import Text from '../../../Text';
import Timer from './Timer';

interface ConnectionBarProps {
  startConnection?: Date;
  setStartConnection?: () => void;
}

const ConnectionBar = ({
  startConnection,
  setStartConnection,
}: ConnectionBarProps) => {
  const hasConnection = !!startConnection;
  return (
    <Flex
      alignItems="center"
      w="280px"
      h="45"
      pr="4"
      justifyContent="space-between"
    >
      <Flex align="center" gridGap={4}>
        <IconButton
          aria-label="StartIcon"
          borderWidth="1px"
          borderColor="success.500"
          variant="outline"
          icon={hasConnection ? <StopIcon /> : <StartIcon />}
          onClick={setStartConnection}
        />
        <Text m={0}>
          <FormattedMessage
            id={`remote_view.${hasConnection ? 'stop' : 'start'}_connection`}
          />
        </Text>
      </Flex>
      <Timer startDateConnection={startConnection} />
    </Flex>
  );
};

export default ConnectionBar;
