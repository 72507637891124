import { Divider, Flex, SimpleGrid, Tooltip } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import FormContainer from '../../../components/FormContainer';
import FormControl from '../../../components/FormControl';
import Input from '../../../components/Input';
import PageErrors from '../../../components/PageErrors';
import {
  radioOptions,
  syncTimeOptions,
} from '../../../components/pages/Configurations/GeneralConfig/translate';
import PageShowToaster from '../../../components/PageShowToaster';
import PageTitle from '../../../components/PageTitle';
import RadioButtonForm from '../../../components/RadioButton/RadioButtonForm';
import Select from '../../../components/Select';
import Text from '../../../components/Text';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import {
  getGeneralConfiguration,
  Types,
  updateEnterpriseConfig,
} from '../../../store/company';
import { selectorLoading } from '../../../store/ui';
import {
  LoginExpirationTimeEnum,
  SettingsUpdateType,
} from '../../../types/company';
import { SyncTimeEnum } from '../../../types/generalConfiguration';

const GeneralConfig = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectorLoading(Types.SETTINGS_UPDATE));
  const { generalConfiguration } = useAppSelector((state) => state.company);
  const {
    portalSettings: { allowTwoFactorAuthentication },
  } = useAppSelector((state) => state.portalSettings);

  const [form, setForm] = useState<SettingsUpdateType>({
    startCycleDay: 1,
    syncTime: SyncTimeEnum.h1,
    startDayOfWeek: 0,
    endDayOfWeek: 0,
    notifyByEmail: false,
    loginExpirationInSeconds: LoginExpirationTimeEnum.ThirtyMinutes,
    hasTwoFactorAuthentication: false,
  });

  const loginExpirationOptions = [
    {
      label: intl.formatMessage({ id: 'general_config.inactivity_time.30min' }),
      value: LoginExpirationTimeEnum.ThirtyMinutes,
    },
    {
      label: intl.formatMessage({ id: 'general_config.inactivity_time.20min' }),
      value: LoginExpirationTimeEnum.TwentyMinutes,
    },
    {
      label: intl.formatMessage({ id: 'general_config.inactivity_time.10min' }),
      value: LoginExpirationTimeEnum.TenMinutes,
    },
    {
      label: intl.formatMessage({ id: 'general_config.inactivity_time.5min' }),
      value: LoginExpirationTimeEnum.FiveMinutes,
    },
  ];

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: parseInt(e.target.value) });
  };

  const submit = () => {
    dispatch(updateEnterpriseConfig(form));
  };

  useEffect(() => {
    dispatch(getGeneralConfiguration());
  }, []);

  useEffect(() => {
    setForm({
      startCycleDay: generalConfiguration?.startCycleDay,
      syncTime: generalConfiguration?.syncTime,
      startDayOfWeek: generalConfiguration?.startDayOfWeek,
      endDayOfWeek: generalConfiguration?.endDayOfWeek,
      notifyByEmail: generalConfiguration?.notifyByEmail,
      loginExpirationInSeconds: generalConfiguration?.loginExpirationInSeconds,
      hasTwoFactorAuthentication:
        generalConfiguration?.hasTwoFactorAuthentication,
    });
  }, [generalConfiguration]);

  return (
    <>
      <PageTitle
        showManualIcon
        title={<FormattedMessage id="general_config.title" />}
        description={<FormattedMessage id="general_config.description" />}
      />
      <PageShowToaster toasterKeys={[Types.SETTINGS_UPDATE]} />
      <PageErrors
        toasterKeys={[Types.SETTINGS_UPDATE]}
        translateKey="general_config.form"
        w="full"
      />
      <FormContainer
        labelPrimary={<FormattedMessage id="global.update" />}
        handlePrimary={submit}
        disabledPrimary={!form?.startCycleDay}
        loadingPrimary={isLoading}
      >
        <Flex direction="column">
          <Text color="gray.500" fontWeight="bold" m={0}>
            <FormattedMessage id="general_config.form.cycle_start.title" />
          </Text>
          <Divider mb="1.5%" mt="1%" />

          <Flex maxW="40rem">
            <FormControl
              textLabel={
                <Tooltip
                  label={
                    <FormattedMessage id="general_config.form.cycle_start.tooltip" />
                  }
                  hasArrow
                >
                  {intl.formatMessage({
                    id: 'general_config.form.startCycleDay',
                  })}
                </Tooltip>
              }
            >
              <Input
                inputProps={{
                  value: form?.startCycleDay || '',
                  name: 'startCycleDay',
                  onChange: handleChangeInput,
                }}
              />
            </FormControl>
          </Flex>

          <Text color="gray.500" fontWeight="bold" mb={0} pt="3">
            <FormattedMessage id="general_config.form.sync_time.title" />
          </Text>
          <Divider mb="1.5%" mt="1%" />

          <Flex maxW="40rem">
            <FormControl
              textLabel={<FormattedMessage id="general_config.form.syncTime" />}
            >
              <Select
                value={form?.syncTime || ''}
                name="syncTime"
                onChange={handleChangeInput}
              >
                {syncTimeOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {intl.formatMessage({ id: option.label })}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Flex>

          <Text color="gray.500" fontWeight="bold" pt="3" mb="0%">
            <FormattedMessage id="general_config.form.distinct_configs" />
          </Text>
          <Divider mb="2%" mt="1%" />
          <SimpleGrid gridGap={6} columns={{ md: 1, lg: 3 }}>
            <RadioButtonForm
              name="notifyByEmail"
              label="general_config.form.warning_email"
              textInfo="general_config.form.warning_email.tooltip"
              options={radioOptions}
              value={String(form?.notifyByEmail)}
              defaultValue={String(form?.notifyByEmail)}
              onChange={(value) =>
                setForm({ ...form, notifyByEmail: value === 'true' && true })
              }
            />

            {allowTwoFactorAuthentication && (
              <RadioButtonForm
                name="hasTwoFactorAuthentication"
                label="general_config.two_factor_authentication"
                textInfo="general_config.two_factor_authentication.tooltip"
                options={radioOptions}
                value={String(form?.hasTwoFactorAuthentication)}
                defaultValue={String(form?.hasTwoFactorAuthentication)}
                onChange={(value) =>
                  setForm({
                    ...form,
                    hasTwoFactorAuthentication: value === 'true' && true,
                  })
                }
              />
            )}

            <FormControl
              textLabel={
                <FormattedMessage id="general_config.inactivity_time" />
              }
            >
              <Select
                name="loginExpirationInSeconds"
                value={form?.loginExpirationInSeconds}
                onChange={handleChangeInput}
              >
                {loginExpirationOptions.map((time) => (
                  <option key={time.value} value={time.value}>
                    {time.label}
                  </option>
                ))}
              </Select>
            </FormControl>
          </SimpleGrid>
        </Flex>
      </FormContainer>
    </>
  );
};

export default GeneralConfig;
