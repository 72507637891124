import { useIntl } from 'react-intl';

import { usePrivilege } from '../../hooks/usePrivilege';
import { useAppSelector } from '../../hooks/useRedux';
import routes from '../../routes';
import BrowserIcon from '../Icons/Browser';
import CompanyIcon from '../Icons/Company';
import ConfigIcon from '../Icons/Config';
import DeviceIcon from '../Icons/Device';
import GpsIcon from '../Icons/GPS';
import GroupsIcon from '../Icons/Groups';
import HelpIcon from '../Icons/Help';
import HomeIcon from '../Icons/Home';
import MessageIcon from '../Icons/Message';
import ShareIcon from '../Icons/Share';
import StoreIcon from '../Icons/Store';
import UserIcon from '../Icons/User';
import ZeroTouchIcon from '../Icons/ZeroTouchIcon';

interface MenuLink {
  link: string;
  title: string;
  external?: string;
}

interface MenuSubitem {
  id: string;
  title: string;
  titleStyle?: string;
  drilldown: MenuLink[];
}

export interface MenuItem {
  id: string;
  title: string;
  link?: string;
  icon: React.ReactNode;
  drilldown?: MenuSubitem[];
}

function menuItems() {
  const { portalSettings } = useAppSelector((state) => state.portalSettings);
  const privilege = usePrivilege();
  const intl = useIntl();

  // company drill down
  const mountCompanyDrilldownMenu = () => {
    const menuItems: MenuSubitem[] = [];

    menuItems.push({
      id: 'company',
      title: intl.formatMessage({ id: 'menu.company' }),
      drilldown: [
        {
          title: intl.formatMessage({ id: 'menu.company.info' }),
          link: routes.company.info,
        },
        {
          title: intl.formatMessage({ id: 'menu.company.consumption' }),
          link: routes.company.consumption,
        },
        {
          title: intl.formatMessage({ id: 'menu.company.license' }),
          link: routes.company.license,
        },
        {
          title: intl.formatMessage({ id: 'menu.settings.general_config' }),
          link: routes.generalConfig.manage,
        },
        {
          title: intl.formatMessage({ id: 'menu.audit' }),
          link: routes.company.audit,
        },
      ],
    });

    return menuItems;
  };

  //
  // groups drill down
  const mountGroupsDrilldownMenu = () => {
    const menuItems: MenuSubitem[] = [];

    menuItems.push({
      id: 'groups',
      title: intl.formatMessage({ id: 'menu.groups_and_subgroups.groups' }),
      drilldown: [
        {
          title: intl.formatMessage({
            id: 'menu.groups_and_subgroups.groups.register',
          }),
          link: routes.groups.register,
        },
        {
          title: intl.formatMessage({
            id: 'menu.groups_and_subgroups.groups.manage',
          }),
          link: routes.groups.manage,
        },
      ],
    });

    return menuItems;
  };

  const mountSitesDrilldownMenu = () => {
    const menuItems: MenuSubitem[] = [];

    menuItems.push({
      id: 'browser',
      title: intl.formatMessage({ id: 'menu.websites' }),
      drilldown: [
        {
          title: intl.formatMessage({ id: 'menu.websites.report_date' }),
          link: routes.sites.reportDate,
        },
        {
          title: intl.formatMessage({ id: 'menu.websites.report_url' }),
          link: routes.sites.reportUrl,
        },
      ],
    });

    return menuItems;
  };

  const mountUsersDrilldownMenu = () => {
    const menuItems: MenuSubitem[] = [];
    menuItems.push({
      id: 'adminUsers',
      title: intl.formatMessage({ id: 'menu.users.device_users' }),
      drilldown: [
        {
          title: intl.formatMessage({ id: 'menu.users.admin_users.register' }),
          link: routes.adminUsers.register,
        },
        {
          title: intl.formatMessage({ id: 'menu.users.admin_users.manage' }),
          link: routes.adminUsers.manage,
        },
      ],
    });

    return menuItems;
  };

  //
  // devices drill down
  const mountDevicesDrilldownMenu = () => {
    const menuItems: MenuSubitem[] = [];
    const drilldownItens = [
      {
        title: intl.formatMessage({
          id: 'menu.device.devices',
        }),
        link: routes.device.manage,
      },
      {
        title: intl.formatMessage({
          id: 'menu.device.application',
        }),
        link: routes.application.manage,
      },
    ];
    const itensMenu = {
      id: 'devices',
      title: intl.formatMessage({ id: 'menu.devices' }),
      drilldown: drilldownItens,
    };
    menuItems.push(itensMenu);

    return menuItems;
  };
  // zero touch drill down
  const mountZeroTouchDrilldownMenu = () => {
    const menuItems: MenuSubitem[] = [];
    const drilldownItens = [
      {
        title: intl.formatMessage({
          id: 'menu.zero_touch.manage_notes',
        }),
        link: routes.zeroTouch.invoices.manage,
      },
      {
        title: intl.formatMessage({
          id: 'menu.zero_touch.manage',
        }),
        link: routes.zeroTouch.manageZeroTouch,
      },
      {
        title: intl.formatMessage({
          id: 'menu.manage_zero_touch_register',
        }),
        link: routes.zeroTouch.backoffice.manage,
      },
    ];
    const itensMenu = {
      id: 'zeroTouch',
      title: intl.formatMessage({ id: 'menu.zero_touch' }),
      drilldown: drilldownItens,
    };
    menuItems.push(itensMenu);

    return menuItems;
  };
  //
  // locations
  const mountLocationDrilldownMenu = () => {
    const menuItems: MenuSubitem[] = [];

    menuItems.push({
      id: 'location',
      title: intl.formatMessage({ id: 'menu.location' }),
      drilldown: [
        {
          title: intl.formatMessage({ id: 'menu.location.geolocation' }),
          link: routes.geolocation,
        },
        {
          title: intl.formatMessage({ id: 'menu.location.device_location' }),
          link: routes.informes.deviceLocation,
        },
        {
          title: intl.formatMessage({ id: 'menu.location.location_history' }),
          link: routes.informes.locationHistory,
        },
      ],
    });

    return menuItems;
  };

  //
  // config
  const mountConfigDrilldownMenu = () => {
    const menuItems: MenuSubitem[] = [];

    menuItems.push({
      id: 'config',
      title: intl.formatMessage({ id: 'menu.settings' }),
      drilldown: [
        {
          title: intl.formatMessage({ id: 'menu.policy.manage' }),
          link: routes.policies.manage,
        },
        {
          title: intl.formatMessage({ id: 'menu.policy.register' }),
          link: routes.policies.register,
        },
        {
          title: intl.formatMessage({
            id: 'menu.settings.profile_consumption',
          }),
          link: routes.profileConsumption.manage,
        },
        {
          title: intl.formatMessage({
            id: 'menu.settings.register_policy',
          }),
          link: routes.policies.registerPoliceIOS,
        },
        {
          title: intl.formatMessage({
            id: 'menu.settings.networks.list',
          }),
          link: routes.generalConfig.networkSettings.list,
        },
      ],
    });

    return menuItems;
  };

  //
  // help
  const mountHelpDrilldownMenu = () => {
    const menuItems: MenuSubitem[] = [];
    const supportUrl = portalSettings?.supportUrl;
    const drilldownItens = [
      {
        title: intl.formatMessage({ id: 'menu.help.faq' }),
        link: routes.faq,
      },
      {
        title: intl.formatMessage({ id: 'menu.help.documents' }),
        link: routes.support,
      },
      {
        title: intl.formatMessage({ id: 'menu.help.suport' }),
        link: routes.supportRequest,
        external: supportUrl,
      },
    ];
    const itensMenu = {
      id: 'help',
      title: intl.formatMessage({ id: 'menu.help' }),
      drilldown: supportUrl ? drilldownItens : drilldownItens.slice(0, 2),
    };
    menuItems.push(itensMenu);

    return menuItems;
  };

  //
  // Managed Applications Enterprise
  const mountManagedApplicationsDrilldownMenu = () => {
    const menuItems: MenuSubitem[] = [];
    const drilldownItens = [
      {
        title: intl.formatMessage({
          id: 'menu.enterprise_applications.manage',
        }),
        link: routes.enterpriseApps.manage,
      },
      {
        title: intl.formatMessage({
          id: 'menu.enterprise_applications.google_play_managed',
        }),
        link: routes.enterpriseApps.googlePlayManaged,
      },
      {
        title: intl.formatMessage({
          id: 'menu.enterprise_applications.external_apps',
        }),
        link: routes.externalApps.list,
      },
      {
        title: intl.formatMessage({
          id: 'menu.enterprise_applications.remote_apps',
        }),
        link: routes.remoteApps.list,
      },
    ];
    const itensMenu = {
      id: 'enterpriseApps',
      title: intl.formatMessage({ id: 'menu.enterprise_applications' }),
      drilldown: drilldownItens,
    };
    menuItems.push(itensMenu);

    return menuItems;
  };

  // companies drill down
  const mountCompaniesDrilldownMenu = () => {
    const menuItems: MenuSubitem[] = [];

    menuItems.push({
      id: 'companies',
      title: intl.formatMessage({ id: 'menu.companies' }),
      drilldown: [
        {
          title: intl.formatMessage({ id: 'menu.companies.manage_companies' }),
          link: routes.carrier.manage,
        },
        {
          title: intl.formatMessage({ id: 'menu.companies.register' }),
          link: routes.carrier.register,
        },
      ],
    });

    return menuItems;
  };

  //
  // ------------------------------
  // Start mounting root menus
  // ------------------------------
  const menuList: MenuItem[] = [];
  const desktopMenuBoxSize = 6;
  const desktopMenuColor = 'white';

  //
  // dashboard menu
  menuList.push({
    id: 'dashboard',
    title: intl.formatMessage({ id: 'menu.dashboard' }),
    link: routes.dashboard,
    icon: <HomeIcon boxSize={desktopMenuBoxSize} color={desktopMenuColor} />,
  });

  //
  // company info menu
  menuList.push({
    id: 'company',
    title: intl.formatMessage({ id: 'menu.company' }),
    icon: <CompanyIcon boxSize={desktopMenuBoxSize} color={desktopMenuColor} />,
    drilldown: [].concat(mountCompanyDrilldownMenu()),
  });

  // manage companies menu
  menuList.push({
    id: 'companies',
    title: intl.formatMessage({ id: 'menu.companies' }),
    icon: <CompanyIcon boxSize={desktopMenuBoxSize} color={desktopMenuColor} />,
    drilldown: [].concat(mountCompaniesDrilldownMenu()),
  });

  //
  // manage groups menu
  menuList.push({
    id: 'groups',
    title: intl.formatMessage({ id: 'menu.groups_and_subgroups' }),
    icon: <GroupsIcon boxSize={desktopMenuBoxSize} color={desktopMenuColor} />,
    drilldown: [].concat(mountGroupsDrilldownMenu()),
  });

  //
  // manage users menu
  menuList.push({
    id: 'users',
    title: intl.formatMessage({ id: 'menu.users' }),
    icon: <UserIcon boxSize={desktopMenuBoxSize} color={desktopMenuColor} />,
    drilldown: [].concat(mountUsersDrilldownMenu()),
  });

  //
  // Enterprise Applications menu
  menuList.push({
    id: 'enterpriseApps',
    title: intl.formatMessage({ id: 'menu.enterprise_applications' }),
    icon: <StoreIcon boxSize={desktopMenuBoxSize} color={desktopMenuColor} />,
    drilldown: [].concat(mountManagedApplicationsDrilldownMenu()),
  });

  //
  // config menu
  menuList.push({
    id: 'config',
    title: intl.formatMessage({ id: 'menu.settings' }),
    icon: <ConfigIcon boxSize={desktopMenuBoxSize} color={desktopMenuColor} />,
    drilldown: [].concat(mountConfigDrilldownMenu()),
  });

  //
  // device menu
  menuList.push({
    id: 'devices',
    title: intl.formatMessage({ id: 'menu.devices' }),
    icon: <DeviceIcon boxSize={desktopMenuBoxSize} color={desktopMenuColor} />,
    drilldown: [].concat(mountDevicesDrilldownMenu()),
  });

  //
  // zeroTouch menu
  menuList.push({
    id: 'zeroTouch',
    title: intl.formatMessage({ id: 'menu.zero_touch' }),
    icon: (
      <ZeroTouchIcon boxSize={desktopMenuBoxSize} color={desktopMenuColor} />
    ),
    drilldown: [].concat(mountZeroTouchDrilldownMenu()),
  });

  //
  // browser menu
  menuList.push({
    id: 'browser',
    title: intl.formatMessage({ id: 'menu.websites' }),
    icon: <BrowserIcon boxSize={desktopMenuBoxSize} color={desktopMenuColor} />,
    drilldown: [].concat(mountSitesDrilldownMenu()),
  });

  //
  // messages menu
  menuList.push({
    id: 'messages',
    title: intl.formatMessage({ id: 'menu.messages' }),
    link: routes.messages.list,
    icon: <MessageIcon boxSize={desktopMenuBoxSize} color={desktopMenuColor} />,
  });

  //
  // shared documents menu
  menuList.push({
    id: 'sharedDocs',
    title: intl.formatMessage({ id: 'menu.documents' }),
    link: routes.documents.list,
    icon: <ShareIcon boxSize={desktopMenuBoxSize} color={desktopMenuColor} />,
  });

  //
  // gps menu
  menuList.push({
    id: 'location',
    title: intl.formatMessage({ id: 'menu.location' }),
    icon: <GpsIcon boxSize={desktopMenuBoxSize} color={desktopMenuColor} />,
    drilldown: [].concat(mountLocationDrilldownMenu()),
  });

  //
  // config menu
  menuList.push({
    id: 'help',
    title: intl.formatMessage({ id: 'menu.help' }),
    icon: <HelpIcon boxSize={desktopMenuBoxSize} color={desktopMenuColor} />,
    drilldown: [].concat(mountHelpDrilldownMenu()),
  });

  const menuListAuthorized = menuList
    .filter((item) => !!privilege.menuItems[item.id])
    .map((item) => ({
      ...item,
      drilldown: item.drilldown?.filter(
        (subItem) => !!privilege.menuSubitems[subItem.id]
      ),
    }))
    .map((item) => ({
      ...item,
      drilldown: item.drilldown?.map((subItem) => ({
        ...subItem,
        drilldown: subItem.drilldown?.filter(
          (linkItem) => !!privilege.pages[linkItem.link]
        ),
      })),
    }));

  return menuListAuthorized;
}

export default menuItems;
