import { IntlShape } from 'react-intl';

import { batchGroupsImportErrorToIntl } from '../../../pages/Groups/ManageGroups';
import { ImportGroupExceptionEnum } from '../../../types/group';
import { NotificationDetailsType } from '../../../types/notification';
import { Body, Header } from '../../Table/TableInterfaces';

interface BatchGroupImportDetailsType {
  group: string;
  device: string;
  error: ImportGroupExceptionEnum;
}

interface BatchGroupImportDetailsProps {
  groupDetails: NotificationDetailsType['data'];
  intl: IntlShape;
}

export interface DataTableType {
  columns: Header[];
  rows: Body[];
}

export const getBatchGroupImportDetailsData = ({
  groupDetails,
  intl,
}: BatchGroupImportDetailsProps): DataTableType => {
  const columns = [
    {
      header: intl.formatMessage({
        id: 'import_groups.column.group',
      }),
      accessor: 'group',
    },
    {
      header: intl.formatMessage({
        id: 'import_groups.column.device',
      }),
      accessor: 'device',
    },
    {
      header: intl.formatMessage({
        id: 'import_groups.column.error',
      }),
      accessor: 'error',
    },
  ];

  const rows = groupDetails?.map((groupDetail) => ({
    cells: [
      {
        field: 'group',
        value: groupDetail?.group,
      },
      {
        field: 'device',
        value: groupDetail?.device,
      },
      {
        field: 'error',
        value: !!groupDetail?.error
          ? intl.formatMessage({
              id: batchGroupsImportErrorToIntl[
                groupDetail.error as BatchGroupImportDetailsType['error']
              ],
            })
          : '',
      },
    ],
  }));

  return { columns, rows };
};
