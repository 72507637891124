import { Flex, Stack, Tooltip } from '@chakra-ui/react';
import React, { ChangeEvent, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { useToast } from '../../../../../hooks/useToast';
import {
  enrollmentTokenClear,
  getEnrollmentToken,
  setEnrollmentToken,
  Types,
} from '../../../../../store/policy';
import { selectorLoading } from '../../../../../store/ui';
import Modal from '../../../../Modal';
import ModalBody from '../../../../Modal/ModalBody';
import ModalFooter from '../../../../Modal/ModalFooter';
import ModalHeader from '../../../../Modal/ModalHeader';
import { QRCodeCanvas } from '../../../../QRCodeCanvas';
import Skeleton from '../../../../Skeleton';
import SwitchButton from '../../../../SwitchButton';
import Text from '../../../../Text';
import TextWithCopy from './TextWithCopy';
import { enableSystemAppsProp } from './utils';

interface EnrollmentTokenModalProps {
  isOpen: boolean;
  onClose: () => void;
  policyId: number;
}

const ModalEnrollmentToken = ({
  isOpen,
  onClose,
  policyId,
}: EnrollmentTokenModalProps) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { enrollmentToken } = useAppSelector((state) => state.policy);
  const isLoading = useAppSelector(selectorLoading(Types.GET_ENROLLMENT_TOKEN));
  const toast = useToast({
    id: enrollmentToken.value,
  });

  useEffect(() => {
    if (policyId) {
      dispatch(getEnrollmentToken(policyId));
    }
    return () => {
      dispatch(enrollmentTokenClear());
    };
  }, [policyId]);

  const handleChangeEnableSystemApps = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target;

    if (!enrollmentToken?.qrCode) return;

    let qrCodeObj = JSON.parse(enrollmentToken?.qrCode);

    qrCodeObj = {
      ...qrCodeObj,
      [enableSystemAppsProp]: checked,
    };

    dispatch(
      setEnrollmentToken({
        ...enrollmentToken,
        qrCode: JSON.stringify(qrCodeObj),
      })
    );

    toast({
      duration: 1000,
      description: intl.formatMessage({
        id: 'manage_policies.modal_enrollmentToken.success_enable_system_apps',
      }),
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose()}
      size={'xl'}
      modalWidth="900px"
      scrollBehavior={'inside'}
    >
      <ModalHeader>
        <Text fontWeight="bold" my={0}>
          <FormattedMessage id="manage_policies.modal_enrollmentToken.title" />
        </Text>
      </ModalHeader>

      <ModalBody>
        {isLoading ? (
          <Tooltip label={<FormattedMessage id="qrcode.lading_qrCode" />}>
            <Stack>
              <Flex>
                <Skeleton h="400px" w="360px" />
                <Flex w="full" flexDirection="column" px={1}>
                  <Skeleton h="24px" w="180px" my="20px" />
                  <Skeleton h="24px" w="180px" my="20px" />
                  <Skeleton h="24px" w="180px" my="20px" />
                </Flex>
              </Flex>
            </Stack>
          </Tooltip>
        ) : (
          <Flex>
            <QRCodeCanvas text={enrollmentToken.qrCode} />
            {enrollmentToken.value && (
              <Flex flexDirection="column" w="full" px={1}>
                <TextWithCopy
                  title={'manage_policies.modal_enrollmentToken.policy'}
                  text={enrollmentToken?.policyName}
                  disabledCopy
                />
                <TextWithCopy
                  title={'manage_policies.modal_enrollmentToken.register_token'}
                  text={enrollmentToken?.value}
                />
                <TextWithCopy
                  title={
                    'manage_policies.modal_enrollmentToken.json_zero_touch'
                  }
                  text={enrollmentToken?.qrCode}
                  textLimiter={20}
                />

                <Flex gridGap={4} w="full" alignItems="center">
                  <Text color="gray.500" fontWeight="bold" my={2}>
                    {intl.formatMessage({
                      id: 'manage_policies.modal_enrollmentToken.enable_system_apps',
                    })}
                    :
                  </Text>

                  <SwitchButton onChange={handleChangeEnableSystemApps} />
                </Flex>
              </Flex>
            )}
          </Flex>
        )}
      </ModalBody>

      <ModalFooter
        onConfirm={() => onClose()}
        labelConfirm={
          <FormattedMessage id="manage_policies.modal_enrollmentToken.confirm" />
        }
      />
    </Modal>
  );
};

export default ModalEnrollmentToken;
