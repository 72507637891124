import { i18n } from 'portal-lib';

const i18nConfig = {
  locale: 'en',
  messages: {
    ...i18n?.['en'],
    // errors
    'errors.generic_error': 'Error making request',
    // success
    'success.generic_success': 'Request made successfully',
    // global buttons and text
    'global.add': 'Add',
    'global.cancel': 'Cancel',
    'global.close': 'Close',
    'global.confirm': 'Confirm',
    'global.try_again': 'Try again',
    'global.remove': 'Remove',
    'global.update': 'Update',
    'global.copy': 'Copy',
    'global.excel': 'Excel',
    'global.history': 'Historic',
    'global.send': 'Submit',
    'global.remove_record': 'Remove record',
    'global.register': 'Register',
    'global.register_new': 'Register new',
    'global.register_rule': 'Register rule',
    'global.rule': 'Rule',
    'global.load_more': 'Load more',
    'global.showing_x_of': 'Showing {rowInit} - {rowEnd} of {totalRowa}',
    'global.filter': 'Filter',
    'global.radiogroup_all': 'All',
    'global.radiogroup_yes': 'Yes',
    'global.radiogroup_no': 'No',
    'global.general': 'General',
    'global.groups': 'Groups',
    'global.gps': 'GPS',
    'global.subgroups': 'Subgroups',
    'global.users': 'Users',
    'global.select_group': 'Select a group',
    'global.select_subgroup': 'Select a subgroup',
    'global.select_user': 'Select a user',
    'global.edit_config': 'Edit configuration',
    'global.remove_config': 'Remove configuration',
    'global.message': 'Message',
    'global.search': 'Search',
    'global.back': 'Back',
    'global.no_data': 'No data',
    // global form and table text
    'global.date': 'Date',
    'global.start_date': 'Initial date',
    'global.last_date': 'Final date',
    'global.company_name': 'Company',
    'global.identification': 'Identification number',
    'global.password': 'Password',
    'global.email': 'Email',
    'global.name': 'Name',
    'global.contact': 'Contact',
    'global.phone': 'Telephone',
    'global.zip_code': 'Zip code',
    'global.address_line': 'Address',
    'global.city': 'City',
    'global.state': 'State',
    'global.user': 'User',
    'global.adress': 'Address',
    'global.group': 'Group',
    'global.new_group_name': 'New group name',
    'global.consumption': 'Consumption',
    'global.roaming': 'Roaming',
    'global.provider': 'Carrier',
    'global.company': 'Company',
    'global.data': 'Data',
    'global.sms': 'SMS',
    'global.source_number': 'Origin number',
    'global.target_number': 'Destination number',
    'global.plan': 'Plan',
    'global.last_update': 'Last update',
    'global.Enrolled_device': 'Device installed',
    'global.group_name': "Group's name",
    'global.group_users_qtd': 'Number of users in the group',
    'global.subgroup_name': 'Subgroup name',
    'global.subgroup_users_qtd': 'Number of users in the subgroup',
    'global.recipient': 'Recipient',
    'global.action.edit': 'Edit {entity}',
    'global.action.remove': 'Remove {entity}',
    'global.action.view': 'View {entity}',
    'global.newUsers': 'New users',
    'global.totalUsers': 'Total users',
    'global.conected': 'Connected',
    'global.disconnected': 'Disconnected',

    'global.all': 'All',
    'global.yes': 'Yes',
    'global.no': 'No',
    'global.logoff': 'Log off',
    // alerts
    'alert.delete.text': 'Are you sure you<br/>want to remove this record?',
    'alert.button.remove': 'Remove',
    'alert.button.cancel': 'Cancel',
    // login
    'login.welcome_1': 'Welcome.',
    'login.welcome_2': 'Log in.',
    'login.email': 'Email',
    'login.email_placeholder': 'Inform your email',
    'login.password': 'Password',
    'login.password_placeholder': 'Type your password',
    'login.forgot_password': 'Forgot password?',
    'login.btn_submit': 'Start session',
    'login.keep_connected': 'Keep connected',
    'login.terms_and_conditions': 'Terms and conditions',
    'login.privacy_warning': 'Privacy Notice',
    'login.modal.invalid': 'Invalid email or password!',
    'login.modal.verify_login': 'Check the email or password entered.',
    'login.modal.user_last_try': ' You have only one more attempt before your access is blocked. Review the password before making another attempt.',
    'login.modal.title.invalid_user_password': 'Invalid email or password!',
    'login.modal.invalid_user_password': 'Check the email or password entered.',
    'login.modal.unblock_user': 'Send unlock instructions',
    // Recovery password
    'password_recovery.welcome_1': 'To recover',
    'password_recovery.welcome_2': 'Password',
    'password_recovery.continue': 'Continue',
    'password_recovery.modal.check_email': 'Check your email!',
    'password_recovery.modal.email_sent': 'We have sent the unlocking instructions to your email',
    'password_recovery.modal.support_text': 'If the email above is not correct, please contact support.',
    'password_recovery.modal.support_link': 'Request help from technical support',
    // Change password
    'password_chance.welcome_1': 'Update',
    'password_chance.welcome_2': 'Password',
    'password_chance.continue': 'Continue',
    'password_chance.password': 'Password',
    'password_chance.error': 'Invalid or expired token',
    'password_chance.success': 'Your portal access password has been changed!',

    // Unblock user
    'unblock.welcome_1': 'Send instructions',
    'unblock.welcome_2': 'of unlocking',

    // company info
    'company_info.title': 'Company Information',
    'company_info.description': "On this screen, you can update your company's registration information.",
    'company_info.toaster.edit_success': 'Company information has been updated successfully!',
    'company_info.field.dataProtectionOfficerName': 'Name',
    'company_info.field.dataProtectionOfficerEmail': 'Email',
    'company_info.field.dataProtectionOfficerPhone': 'Telephone',
    'company_info.field.euRepresentativeName': 'Name',
    'company_info.field.euRepresentativeEmail': 'Email',
    'company_info.field.euRepresentativePhone': 'Telephone',
    'company_info.dataProtectionOfficerName': 'Data Protection - Name',
    'company_info.dataProtectionOfficerEmail': 'Data Protection - Email',
    'company_info.dataProtectionOfficerPhone': 'Data Protection - Telephone',
    'company_info.euRepresentativeName': 'Representative in the EU - Name',
    'company_info.euRepresentativeEmail': 'Representative in the EU - Email',
    'company_info.euRepresentativePhone': 'Representative in the EU - Telephone',
    'company_info.name': 'Company',
    'company_info.federalCode': 'Identification number',
    'company_info.phoneNumber': 'Telephone',
    'company_info.zipCode': 'Zip code',
    'company_info.address': 'Address',
    'company_info.email': 'Email',
    'company_info.contact': 'Contact',
    'company_info.city': 'City',
    'company_info.state': 'State',
    'company_info.delete_company': 'Delete Company',
    'company_info.delete_alert': 'When deleting the company all registered devices will be removed through the "Wipe" command and all policies and information will be removed!',
    // company consumption
    'company_consumption.title': 'Consumptions',
    'company_consumption.description': "On this screen, you can to manage the data and SMS consumption of the company's devices.",
    'company_consumption.tabs_data': 'Data',
    'company_consumption.tabs_sms': 'SMS',
    'company_consumption.company': 'Company',
    'company_consumption.package_label': 'Application package name',
    'company_consumption.date_error': 'Enter a consistent date.',
    'company_consumption.start_date': 'Initial date',
    'company_consumption.end_date': 'Final date',
    'company_consumption.filter.group': 'Group',
    'company_consumption.filter.subgroup': 'Subgroup',
    'company_consumption.filter.device_user': 'User',
    // company license
    'company_license.title': 'Contracted licenses',
    'company_license.description': 'In this screen you can to view the information of all the contracted licenses.',
    // admin user
    'manage_admin.title': 'Manage Admin Users',
    'manage_admin.description': 'On this screen you can to manage the registration of Portal administrator users.',
    'manage_admin.filter.title': 'Privilege',
    'manage_admin.filter.company': 'Company Responsible',
    'manage_admin.filter.group_manager': 'Group Responsible',
    'manage_admin.filter.subgroup_manager': 'Subgroup Leaders',
    'manage_admin.filter.title_group_manager': 'managed group',
    'manage_admin.filter.title_subgroup_manager': 'Managed Subgroup',
    'manage_admin.filter.title_company': 'Managed company',
    'manage_admin.toaster.success': 'User created successfully, an email was sent to the user with access instructions.',
    // edit admin user
    'edit_admin.user': 'Edit admin user',
    'edit_admin.group': 'Group administration',
    'edit_admin.subgroup': 'Subgroup administration',
    'edit_admin.group_description': 'Select the additional groups you can manage',
    'edit_admin.subgroup_description': 'Select additional subgroups you can manage',
    'edit_admin.register': 'Register admin user',
    'edit_admin.description_new': 'On this screen you can to manage the registration information, privileges, groups and permissions for this user. It is also possible to change the password to access the portal that the user administers.',
    'edit_admin.privilege_company': 'Company Responsible',
    'edit_admin.privilege_group': 'Group Responsible',
    'edit_admin.privilege_subgroup': 'Subgroup Responsible',
    'edit_admin.name': 'Name',
    'edit_admin.federalCode': 'Identification Number',
    'edit_admin.email': 'Email',
    'edit_admin.company': 'Company',
    'edit_admin.timezone': 'Timezone',
    'edit_admin.language': 'Language',
    'edit_admin.password': 'New Password',
    'edit_admin.permissions.subtitle': 'Permissions',
    'edit_admin.groupIds': 'Group administration',
    // manage groups
    'manage_groups.entity': 'Group',
    'manage_groups.title': 'Manage Groups',
    'manage_groups.description': 'On this screen you can to manage the registration of device groups.',
    'manage_groups.actions_edit': 'Edit group',
    'manage_groups.actions_remove': 'Remove group',
    'manage_groups.modal_title': 'Do you really want to remove this group?',
    'manage_groups.modal_subtitle': 'This action cannot be undone.',
    'manage_groups.success.create': 'Group created successfully!',
    'manage_groups.success.update': 'Group successfully changed!',
    'manage_groups.success.delete': 'Group successfully removed!',
    // edit group
    'edit_group.title_new': 'Register New Group',
    'edit_group.title_edit': 'Edit group',
    'edit_group.description_new': 'On this screen you can to create a new device group.',
    'edit_group.description_edit': 'On this screen you can to manage the information and the link of the devices in this group.',
    'edit_group.name': "Group's name",
    'edit_group.attach_user': 'Link user',
    'edit_group.attach': 'Link',
    'edit_group.new_attached_users': 'New users to link',
    'edit_group.attached_users': 'Linked users',
    'edit_group.no_attached_user': 'No users linked to this group',
    'edit_group.attach_user_label': 'Select user',
    // manage subgroups
    'manage_subgroups.title': 'Manage subgroups',
    'manage_subgroups.entity': 'Subgroup',
    'manage_subgroups.actions_edit': 'Edit subgroup',
    'manage_subgroups.actions_remove': 'Remove subgroup',
    'manage_subgroups.modal_title': 'Do you really want to remove this subgroup?',
    'manage_subgroups.modal_subtitle': 'This action cannot be undone.',
    'manage_subgroups.success': 'Subgroup successfully changed!',
    // edit subgroup
    'edit_subgroup.title': 'Edit subgroup',
    'menu.app_action': 'Android Actions',
    'edit_subgroup.attach_user': 'Link user',
    'edit_subgroup.attach': 'Link',
    'edit_subgroup.attached_users': 'Users linked to the subgroup',
    'edit_subgroup.no_attached_user': 'No users linked to this subgroup',
    'edit_subgroup.attach_user_label': 'Select user',
    // register group
    'register_subgroup.title': 'Register new subgroup',
    'menu.users.admin_users': 'Administrators',
    'register_subgroup.success': 'Subgroup registered successfully!',
    // Consumption Profile
    'consumption_profile.title': 'Consumption Profile',
    'consumption_profile.description': 'On this screen you can to manage the consumption profile of the devices.',
    'consumption_profile.success': 'Consumption profile updated successfully!',
    'menu.help': 'Help',
    'consumption_profile.radio_button_defined': 'Set on level above',
    'consumption_profile.radio_button_ilimited': 'Unlimited',
    'consumption_profile.radio_button_assigned': 'Consumption attributed',
    'consumption_profile.general.data': 'Data',
    'consumption_profile.general.quantity': 'Quantity',
    'consumption_profile.general.bytes': 'Bytes',
    'consumption_profile.group.register_success': 'Consumption profile rule successfully registered!',
    'menu.device.application': 'Application Information',
    'consumption_profile.group.modal_title': 'Do you really want to remove this setting?',
    'consumption_profile.group.title': "* The configuration of Consumption Profiles does not reflect the nature of the service contracted with the operator. The information recorded on this form reflects your organization's usage policies only.",
    'menu.app_action.application_control.manage': 'Applications',
    'consumption_profile.group.subtitle': 'This action cannot be undone.',
    'consumption_profile.group_register.title': 'Register consumption profile rule',
    'consumption_profile.group_edit.title': 'Edit rule',
    'consumption_profile.group_remove.title': 'Remove rule',
    'consumption_profile.level_up': 'Set on level above',
    'consumption_profile.ilimited': 'Unlimited',
    'consumption_profile.data': 'Data',
    'consumption_profile.data_roaming': 'Data Roaming',
    'consumption_profile.sms': 'SMS',
    'consumption_profile.sms_roaming': 'SMS Roaming',

    // dashboard
    'dashboard.good_morning': 'Good Morning',
    'dashboard.good_afternoon': 'Good afternoon',
    'dashboard.good_evening': 'Goodnight',
    'dashboard.roaming': 'International roaming',
    'dashboard.description': 'Check the application overview.',
    'dashboard.usage_time_by_application.title': 'Usage time by applications',
    'dashboard.usage_time_by_application.empty_chart': 'No data found',
    'dashboard.most_visited_websites.title': 'Most visited websites',
    'dashboard.most_visited_websites.empty_chart': 'No visits registered.',
    'dashboard.devices_inventory': 'Device inventory',
    'dashboard.devices_location': 'Device Location',
    'dashboard.devices_inventory.empty_list': 'No devices found',
    'dashboard.users_total_heading': 'Total users',
    'dashboard.uninstalled_applications': 'Uninstalled Apps',
    'dashboard.uninstall_attempts': 'Uninstall Attempts',
    'dashboard.not_activated_licenses': 'Licenses not installed on the portal',
    'dashboard.device_card.title': 'Devices',
    'dashboard.device_card.status.active': 'Active',
    'dashboard.device_card.status.total': 'Total',
    'dashboard.device_card.status.without_communication': 'No communication',
    'dashboard.device_card.status.no_compilation': 'No compliance',
    'dashboard.device_card.status.mode_owner': 'In Device Owner',
    'dashboard.device_card.status.mode_kiosk': 'In Kiosk Mode',
    'dashboard.device_card.status.outdated_app': 'Outdated APP',
    'menu.settings.audit': 'Audit',
    'dashboard.used': 'Used',
    'dashboard.data_consumption_chart.app': 'Data consumption by apps',
    'dashboard.data_consumption_chart.user': 'Data consumption per user',
    'dashboard.total': 'Total',
    'dashboard.unlimited': 'Unlimited',
    'dashboard.data_consumption': 'Data consumption',
    'dashboard.sms_consumption': 'SMS consumption',
    'dashboard.data_consumption.label.link': 'See full list',
    'menu.app_action.application_control': 'Lock / Unlock',

    // Android Block Application
    'block_application.title': 'Lock/Unlock Apps',
    'block_application.title_text': 'See Rules already created to block or release applications, Actions generated in this form refer to internet access. The applications can be opened normally. The exception occurs in the device owner mode where the icons of the applications will be hidden.',
    'block_application.general': 'General',
    'block_application.groups': 'Groups',
    'block_application.subgroups': 'Subgroups',
    'block_application.users': 'Users',
    // Android Block Application
    'block_application.general.edit.title': 'Edit application rule',
    'block_application.general.register.title': 'Register application rule',
    'block_application.general.register.title_text': 'On this screen you can to configure a blocking or unblocking rule for websites by URL or keyword on the devices.',
    'block_application.success': 'Lock / Unlock Apps',
    'block_application.apply_to': 'Apply rule on',
    'block_application.apply_rule': 'Apply rules',
    'block_application.apply': 'To apply',
    'block_application.applied': 'Applied',
    'block_application.not_applied': 'Not applied',
    'block_application.lock': 'Blocked',
    'block_application.unlock': 'Unlocked',
    'block_application.default_label': 'Default',
    'block_application.geofence_label': 'Geofence',
    'block_application.geofence.not_found': 'No geofence added to this rule',
    'block_application.time_label': 'Schedules',
    'block_application.add_rule': 'Register rule',
    'block_application.edit_config': 'Edit configuration',
    'block_application.modal.apply_config': 'Do you really want to apply all application rules?',
    'menu.app_action.geofences.register': 'Register new geofence',
    'block_application.remove_config': 'Remove configuration',
    'block_application.modal.remove_config': 'Do you really want to remove this rule from applications?',
    'menu.groups_and_subgroups.groups.register': 'Register New Group',
    'block_application.alert.irreversible': 'This action cannot be undone.',
    'block_application.url': 'URL/Word',
    'menu.groups_and_subgroups.subgroups.register': 'Register new subgroup',
    'block_application.config_rule': 'Configure rule',
    'menu.users.admin_users.register': 'Register new admin user',
    'block_application.select_action': 'Select action',
    'block_application.modal.confirm_rule': 'Rule successfully registered!',
    'block_application.modal.new_rule_question': 'Do you want to register a new rule for <b>same company</b>?',
    'block_application.modal.new_rule': 'Create new rule',
    'block_application.geofence.geofence': 'Geofence',
    'block_application.geofence.select': 'Select a geofence',
    'block_application.schedule.time': 'Schedules',
    'block_application.schedule.select_time': 'Select the times you want to perform the action.',
    'block_application.schedule.monday': 'Mon',
    'block_application.schedule.tuesday': 'Tue',
    'block_application.schedule.wednesday': 'Wed',
    'block_application.schedule.thursday': 'Thu',
    'block_application.schedule.friday': 'Fri',
    'block_application.schedule.saturday': 'Sat',
    'block_application.schedule.sunday': 'Sun',
    'block_application.packageName': 'Package name',
    'block_application.application.placeholder': 'Select the application',
    // Sites
    'sites.block.radio_button.defined': 'Set on level above',
    'sites.block.radio_button.disabled': 'Inactive',
    'sites.block.radio_button.active': 'Active',
    'sites.kiosk.checkbox.block': 'Blocked via rule',
    'sites.kiosk.checkbox.tipe_rule': 'Review rule of',
    'menu.documents': 'Share document',
    'menu.app_action.settings': 'Settings',
    'menu.settings': 'Settings',
    'menu.settings.general_config': 'General Settings',
    'sites.block.general.text': 'On this screen you can to configure a blocking or unblocking profile rule for websites by categories on devices',
    'sites.block.general.success.register': 'Rule created successfully!',
    'sites.block.general.success.edit': 'Rule updated successfully!',
    'sites.block.general.success.delete': 'Rule deleted successfully!',
    'sites.kiosk.title': 'enable kiosk mode',
    'sites.kiosk.description': "Transforms the device's home screen into a controlled working environment, displaying a list of pre-defined applications.",
    'sites.kiosk.function': 'Operation:',
    'sites.kiosk.general.permited_actions': 'Actions allowed in kiosk mode',
    'sites.kiosk.general.success': 'Kiosk Mode Settings Updated Successfully',
    'sites.kiosk.kiosk_state': 'Kiosk mode status',
    'sites_category.radio_button.defined': 'Set on level above',
    'sites_category.radio_button.active': 'Active',
    'sites_category.general.form_label': 'Rule applied to the Company',
    'menu.company.consumption': 'Consumption',
    'sites_category.group.form_label': 'Rule applied to the group',
    'sites_category.subgroup.form_label': 'Rule applied to subgroup',
    'sites_category.user.form_label': 'Rule applied to user',
    'sites_category.general.block_content': 'Content blocking (activate the content you want to block)',
    'sites_category.general.notice': '* View licenses that are on the Basic plane or suspended',

    // Application Manage
    'application_manage.title': 'Applications',
    'application_manage.sub_title': 'On this screen, you can view the device application report.',
    'application_manage.group': 'Groups',
    'application_manage.app': 'Applications',
    'application_manage.sub_group': 'Subgroup',
    'menu.dashboard': 'Dashboard',
    'application_manage.users': 'Users',
    'application_manage.table_action.storage': 'Consumption history',
    'application_manage.table_action.application': 'Users with apps',
    'application_manage.toaster_success': 'Application registered successfully!',
    'application_manage.toaster_sent_success': 'Application sent successfully!',
    'application_manage.modal.send_title': 'Send messages',
    'application_manage.modal.send_text': 'To install or update applications you can select by Company (will be sent to all registered teams), by Group (will be sent to group members only) or by User (you can choose more than 1 user to send).',
    'application_manage.modal.destroy.title': 'Do you really want to remove this application?',
    'application_manage.modal.destroy.text': 'This action cannot be undone.',

    // Application Register
    'application_register.title': 'Register and Remove Application to Install',
    'application_register.title_text': 'On this screen you can to register an application to be installed on devices remotely.',
    'application_register.url': 'File URL:',
    'application_register.packageName': 'Package name',
    'application_register.action.history': 'Historic',
    'application_register.title_tag': 'Only enabled in device owner mode',
    'application_register.message_success': 'Application created successfully!',

    // Application History
    'application_history.title': 'Application history',
    'application_history.title_text': 'On this screen, you can view the history of all shipments made to this app.',
    'application_history.title_package': 'package name:',
    'application_history.sent_status': 'Shipping status',
    'application_history.empresa': 'Shipping status',
    'application_history.action.history': 'Shipping status',
    // Application history Status
    'application_status.title': 'Application submission details',
    'application_status.sent': 'Sent',
    'application_status.sending': 'In process of sending',
    'application_status.url': 'URL',
    'application_status.recipients': 'Recipients',
    // Application Send
    'application_send.title': 'Submit the applications',
    'application_send.sub_title': 'Applications to Install/Uninstall.',
    'application_send.send_application': 'Submit Applications',
    'application_send.message_success': 'Application sent successfully!',
    'application_send.column.action': 'Action',
    'application_send.table_title': 'Selected apps',
    'application_send.action.install': 'Install',
    'application_send.action.uninstall': 'Uninstall',

    // Application device users
    'application_device_users.title': 'Users with Application',
    'application_device_users.description': 'On this screen, you can view all devices that have the application installed.',
    'application_device_users.filter.group': 'group',
    'application_device_users.filter.subgroup': 'Subgroup',
    'application_device_users.filter.device_user': 'User',
    // deviceuser
    'manage_deviceuser.entity': 'Device User',
    'manage_deviceuser.title': 'Manage Users Devices',
    'manage_deviceuser.description': 'In this screen you can to manage the registration of device users.',
    'menu.devices': 'Devices',
    'menu.device.devices': 'Device List',
    'edit_deviceuser.deviceuser': 'Edit user',
    'edit_deviceuser.title': 'Edit Device User',
    'edit_deviceuser.description': "On this screen, you can change the device's user information.",
    'edit_deviceuser.name': 'Username',
    'edit_deviceuser.federalCode': 'Identification Number. (Optional)',
    'edit_deviceuser.groupId': 'Groups',
    'edit_deviceuser.subgroup': 'Subgroups',
    'edit_deviceuser.success': 'User has been successfully updated!',
    'menu.help.documents': 'Supporting Documents and Materials',
    // GEOLOCATION
    'geolocation.title': 'Geolocation',
    'geolocation.title_text': 'On this screen it is possible to view the geolocation of the devices.',
    'geolocation.filter': 'Filter',
    'geolocation.user': 'User',
    'geolocation.findBy': 'To locate',
    'geolocation.date': 'Date',
    'geolocation.timezone': 'Timezone',
    'geolocation.precision': 'Precision',
    'geolocation.search': 'Search',
    'geolocation.label': 'Subtitle',
    'geolocation.initial_position': 'Initial GPS reading position on selected day',
    'geolocation.current_position': 'Current or last position recorded for the day',
    'geolocation.position_history': 'Place where the user went',
    'geolocation.moving': 'In motion',
    'geolocation.stopped': 'Stopped',
    'geolocation.currentUser': 'Current user',
    'geolocation.device': 'Device',
    'geolocation.byData': 'By date',
    'geolocation.now': 'Now',
    'geolocation.all': 'All',
    'geolocation.loading': "Requesting the device's current location. Please wait.",
    'geolocation.warning_exit': 'The system is waiting for the current device location requested! When exiting the screen you will not receive the requested location.',
    'geolocalization.realtime.not_found': 'Nothing found!',
    'geolocalization.realtime.time_exceeded': 'Unable to get current device location! Confirm the device is turned on, GPS is enabled and connected to an internet network to send the location.',
    'menu.company': 'Companies',
    // LOCATION HISTORY
    'location_history.title': 'Location History',
    'location_history.subtitle': 'On this screen you can to view the history of the locations registered by the devices.',
    'location_history.month': 'Month',
    'location_history.day': 'Day',
    'location_history.initial_time': 'Start time',
    'location_history.final_time': 'End time',
    'location_history.precision': 'GPS accuracy (meters)',
    'location_history.user': 'User',
    'location_history.select': 'Select',
    'location_history.select_option.all': 'All',
    // MESSAGE
    'message.title': 'Messages',
    'message.description': 'On this screen you can to manage and send messages and commands via push to the devices.',
    'message.title_text': 'In this screen you can to manage and send messages and commands via push to the devices.',
    'message.filter': 'Filter',
    'message.start_date': 'Initial date',
    'message.end_date': 'Final date',
    'message.new': 'New message',
    'message.toaster_success': 'Message sent successfully!',
    'message.entity': 'Message',
    'message.filter_title': 'Send messages',
    // MESSAGE DETAILS
    'message_detail.title': 'Message details',
    'message_detail.title_text': 'On this screen, you can view the message details.',
    'message_detail.subtitle': 'Recipients',
    'message_detail.status.error_sending_message': 'Error sending message',
    'message_detail.status.in_the_send_queue': 'In the send queue',
    'message_detail.status.sent': 'Sent',
    'message_detail.status.received': 'Received',
    'message_detail.status.read': 'Read',
    'message_detail.status.installed': 'Installed',
    'message_detail.status.not_installed': 'Not installed',
    'message_detail.status.executed': 'Executed',
    'message_detail.status.not_executed': 'Not executed',
    'message_detail.status.uninstalled': 'Uninstalled',
    'message_detail.status.not_uninstalled': 'Not uninstalled',

    // MESSAGE TYPES
    'message_type.status.install_apk': 'Install / Update APK',

    // MESSAGE REGISTER
    'message_register.title': 'New message',
    'message_register.title_text': 'On this screen you can to send messages to the devices.',

    // REPOSTS
    // - GPS
    'reportsGps.title': 'GPS',
    'reportsGps.description': 'On this screen you can to generate the GPS status report of the devices',
    //DEVICE LOCATION
    'device_location.title': 'Device Location',
    'device_location.sub_title': 'On this screen you can to generate a report of the last location recorded by the devices',
    'device_location.start_date': 'Initial date',
    'menu.app_action.geofences': 'Geofences',
    'device_location.end_date': 'Final date',
    'menu.location.geolocation': 'Geolocation',
    'device_location.toaster_success': 'Message sent successfully!',
    // DEVICES
    'devices.title': 'Device List',
    'devices.sub_title': 'On this screen, you can manage and perform actions on the devices.',
    'devices.manufacturer': 'Manufacturer',
    'devices.model': 'Model',
    'devices.phone': 'Telephone',
    'devices.inventory.title': 'Device distribution',
    'menu.app_action.geofences.manage': 'Manage geofences',
    'devices.action.disconnected': 'Re-establish communication with this device to enable these actions.',
    'menu.groups_and_subgroups.groups.manage': 'Manage Groups',
    'devices.action.battery': 'Battery history',
    'devices.action.storage': 'Storage History',
    'menu.groups_and_subgroups.subgroups.manage': 'Manage subgroups',
    'devices.action.remove': 'Remove device',
    'devices.action.change': 'Equipment exchange',
    'devices.action.disable': 'Disable Device',
    'menu.users.admin_users.manage': 'Manage Admin Users',
    'devices.action.activate': 'Activate Device',
    'devices.action.non_compliance': 'Non-Compliance Report',
    'menu.users.device_users.manage': 'Manage device users',
    'devices.action.newpassword': 'Generate new device password',
    'devices.action.passwordHistoric': 'Latest device passwords',
    'devices.alert.irreversible': 'This action cannot be undone.',
    'devices.alert.cancel': 'Cancel',
    'devices.alert.remove.button': 'Remove',
    'devices.alert.remove.text': 'Do you really want to remove this device?',
    'devices.alert.disable.button': 'Disable',
    'devices.alert.disable.text': 'Do you really want to disable this device?',
    'devices.alert.activate.button': 'Activate',
    'devices.alert.activate.text': 'Do you really want to activate this device?',
    'devices.alert.newpassword.button': 'generate password',
    'devices.alert.newpassword.text': 'Do you really want to generate a new device password?',
    'devices.passwordHistoric.title': 'Latest device passwords',
    'devices.passwordHistoric.newpassword': 'Generate new device password',
    'devices.block.title': 'Lock/Unlock Apps',
    'devices.block.subtitle': "On this screen you can to manage the blocking and unblocking rules of the devices' applications.",
    //  BATTERY
    'battery.title': 'Battery History',
    'battery.sub_title': 'On this screen, you can view the battery evolution graph on a given day.',
    'battery.label.filter': 'Date',
    'menu.groups_and_subgroups': 'Groups',
    // STORAGE
    'menu.groups_and_subgroups.groups': 'Groups',
    'storage.title': 'Storage History',
    'storage.sub_title': 'On this screen, you can view the graph of the evolution of internal storage on a given day.',
    'storage.label.filter': 'Date',
    // QR CODE to Device Owner
    'qrcode.title': 'Generate QR code for Device Owner',
    'menu.app_action.settings.kioskMode': 'Enable Kiosk Mode',
    'qrcode.description': 'On this screen you can to generate a QR Code to install the Device Owner mode on the devices.',
    'qrcode.url': 'Address (URL) to download the app',
    'qrcode.packageName': 'App package name',
    'qrcode.language': 'Language',
    'qrcode.timezone': 'Timezone',
    'qrcode.ssid': 'Wifi network name (SSID) that will connect',
    'qrcode.ssidPassword': 'Password of the Wifi network that will connect',
    'qrcode.enableAllApps': 'Enable all system apps',
    'qrcode.button': 'To generate',
    'qrcode.wifi_network_name_label': 'Setting the WiFi network name (SSID)',
    'qrcode.wifi_network_password_label': 'Set your WiFi password',
    'menu.location.location_history': 'Location History',
    'qrcode.failure_generate': 'No data',
    'qrcode.show.title': 'QR Code for Device Owner mode',
    'qrcode.show.description': 'This screen displays the QR Code to perform the Device Owner mode installation. After factory resetting your devices, scan this code to start the installation process.',
    'qrcode.show.url': 'Address (URL) to download the app',
    'qrcode.show.packageName': 'App package name',
    'qrcode.show.language': 'Language',
    'qrcode.show.timezone': 'Timezone',
    'qrcode.show.ssid': 'Wifi network name (SSID)',
    'qrcode.show.ssidPassword': 'Wifi network password',
    'qrcode.show.enabledApplication': 'Enable all system apps',
    // GENERAL CONFIGURATION
    'general_config.title': 'General Settings',
    'general_config.description': 'On this screen you can manage the general settings.',
    'general_config.toaster': 'The settings have been updated successfully!',
    'general_config.form.startCycleDay': 'Start of cycle',
    'general_config.form.syncTime': 'Sync every',
    'general_config.form.work_config': 'Work settings',
    'general_config.form.startDayOfWeek': 'Days of the week',
    'general_config.form.optional': '(optional)',
    'general_config.form.endDayOfWeek': 'to',
    'general_config.form.work_hour': 'Work schedule',
    'menu.company.info': 'Company Information',
    'general_config.form.lock_outside_work_hours': 'Total blocking outside working hours',
    'general_config.form.gps_config': 'GPS Settings',
    'general_config.form.gps_enabled': 'Enable monitoring',
    'general_config.form.gps_hour': 'GPS Monitoring Schedule',
    'general_config.form.gps_precision': 'GPS accuracy (meters)',
    'general_config.form.locate_every': 'Location Frequency',
    'general_config.form.wifi_config': 'WIFI Settings',
    'general_config.form.block_apps': 'Application Blocking',
    'general_config.form.block_sites': 'Website Blocking',
    'general_config.form.get_usage_time': 'Get usage time',
    'general_config.form.distinct_configs': 'Miscellaneous Settings',
    'menu.app_action.settings.manage_application_config': 'Install / Update Application',
    'general_config.form.block_url': 'Full URL blocking',
    'general_config.form.total_block_apps': 'Total blocking of apps',
    'general_config.form.hotspot': 'Allow Wi-Fi Routing (Hotspot)',
    'general_config.form.warning_email': 'Notice Email',
    'menu.company.license': 'Contracted Licenses',
    'general_config.form.device_owner_allowed': 'Settings allowed in Device Owner',
    'general_config.form.device_owner_allowed_description': 'These settings will only apply if the device is in Device Owner mode.',
    'menu.location': 'Location',
    'general_config.form.allow_safe_start': 'Allow booting in Safe Mode',
    'general_config.form.allow_add_user': 'Allow add user',
    'general_config.form.week_start': 'Beginning of the week',
    'general_config.form.week_end': 'End of week',
    'menu.location.device_location': 'Device Location',
    'general_config.form.hour_start': 'Start time',
    'general_config.form.hour_end': 'End time',
    'general_config.form.total_block': 'Total blocking out of hours',
    'general_config.form.gps_hour_start': 'GPS start',
    'general_config.form.gps_hour_end': 'GPS end',
    'general_config.form.sd_card_install': 'Allow Installation of External SD Card',
    'general_config.form.cycle_start.tooltip': 'Changing the cycle start date during the cycle can generate data inconsistencies, which will only be corrected when starting a new cycle.',
    'general_config.form.week_days.tooltip': "Define the company's working days. The system considers Monday as the first day of the week.",
    'general_config.form.lock_outside_work_hours.tooltip': 'Enables total blocking of apps and website browsing on mobile data and Wi-Fi when the device is outside the configured working hours.',
    'general_config.form.gps_enabled.tooltip': 'Allows you to activate the device monitoring via GPS according to the configured time.',
    'general_config.form.wifi_config.tooltip': 'When activating one of the settings in Wi-Fi, the rule will be applied also when the device is connected to a Wi-Fi network.',
    'general_config.form.block_url.tooltip': 'Enables total blocking of website browsing on mobile data and Wi-Fi.',
    'general_config.form.total_block_apps.tooltip': "Enables the total blocking of the device's apps, on mobile data and Wi-Fi.",
    'general_config.form.hotspot.tooltip': "Allows you to enable sharing your device's mobile data network with other devices.",
    'general_config.form.warning_email.tooltip': 'Enables sending notification via email to the administrator when the device reaches 80%, 90% and 100% of the configured consumption profile.',
    'general_config.form.allow_safe_start.tooltip': 'Allows you to start the device in Safe Mode.',
    'general_config.form.allow_add_user.tooltip': "Allows you to add a new user on the device's operating system.",
    'general_config.form.sd_card_install.tooltip': 'Allows you to install a memory card (external SD Card).',
    'general_config.register.title': 'Register configuration',
    'general_config.edit.title': 'Edit configuration',
    'general_config.edit.description': 'On this screen, you can manage general settings and apply them to devices.',
    'general_config.group.toaster_create': 'Group settings have been successfully registered!',
    'general_config.group.toaster_edit': 'Group settings have been updated successfully!',
    'menu.messages': 'Messages',
    'general_config.subgroup.toaster_create': 'Subgroup settings have been successfully registered!',
    'general_config.subgroup.toaster_edit': 'Subgroup settings updated successfully!',
    'general_config.user.toaster_create': 'User settings have been successfully registered!',
    'general_config.user.toaster_edit': 'User settings updated successfully!',
    'general_config.edit.group.track_gps': 'Monitor GPS',
    'general_config.edit.group.title': 'Edit group configuration',
    'general_config.register.group.title': 'Register group configuration',
    'general_config.edit.subgroup.title': 'Edit subgroup configuration',
    'general_config.register.subgroup.title': 'Register subgroup configuration',
    'general_config.edit.user.title': 'Edit User Configuration',
    'general_config.register.user.title': 'Register user configuration',
    // DOCUMENT
    'document.entity': 'Document',
    'document.title': 'Share documents',
    'document.description': 'On this screen, you can manage and share documents via push to devices.',
    'document.start_date': 'Initial date',
    'document.end_date': 'Final date',
    'document.new': 'Share new',
    'document.view': 'View',
    'document.toaster_success': 'Document shared successfully!',
    'document.content': 'Document Link',
    'document.send_label': 'Submit',
    'document.filter_title': 'Send documents',
    // DOCUMENT DETAILS
    'document_details.title': 'Share details',
    'document_details.description': 'On this screen, you can view the details of the shared document.',
    'document_details.doc': 'Document',
    'document_details.subtitle': 'Recipients',
    // DATE REPORT
    'date_report.title': 'Sites by Date Report',
    'date_report.title_text': 'On this screen, you can view a timeline of the websites accessed the "Security Browser" on the devices.',
    'date_report.select_user': 'Select a user',
    // DATE REPORT
    'sites_url.title': 'Sites by URL or Keyword Report',
    'sites_url.description': 'In this screen you can to generate the report of the sites accessed on the devices by a URL or keyword.',
    'sites_url.field_search': 'URL or keyword',
    'sites_url.field_user': 'User',
    'sites_url.placeholder_search': 'Enter the URL or keyword',

    // Validations
    'form.field_default': 'Validation failed',
    'form.field_required': 'Complete the field',
    'form.field_email': 'Enter a valid email',
    'form.field_alpha_num_dash_space': 'Field must have letters, numbers, spaces or dashes',
    'form.integer': 'Must be an integer',
    // Permissions / Roles
    'role.viewUsersGps': 'View users GPS',
    'role.editUserRole': 'Edit User Role',
    'role.editConfig': 'Edit configuration',
    'role.editLimits': 'Edit limits',
    'role.editActions': 'Edit actions',
    // Map
    'map.state': 'State',
    'map.precision': 'GPS accuracy',
    'map.battery': 'Battery',
    'map.selected.infos.phone': 'Telephone',
    'map.selected.infos.date_time': 'Date/Time',
    'map.empty': 'No devices found',

    // Card Help
    'faq.card_one.manuals.title': 'Download Manuals',
    'faq.card_one.manuals.description': 'Check out our documents and support materials.',
    'faq.card_tow.tutorial_video_title': 'Tutorial videos',
    'faq.card_tow.tutorial_video_description': 'Check out our explainer videos.',
    'faq.card_three.suport.title': 'Still have doubts?',
    'faq.card_three.suport.description': 'Our support team can help you.',
    'faq.card_three.suport.button.link': 'Support Request',
    'card_help.faq.title': 'Any questions?',
    'card_help.faq.description': 'Visit our FAQ page and search for your question.',
    'card_help.faq.button.link': 'Common Questions',

    // Guides
    'guides.title': 'Supporting Documents and Materials',
    'guides.description': 'On this screen, you can download documents and support materials to use the {portalName} solution.',
    // FAQ
    'faq.all': 'All',
    'faq.title': 'Common Questions',
    'faq.description': 'On this screen you can view the frequently asked questions asked by users',
    'faq.input.placeholder': 'How can we help you?',
    'faq.input.instruction': 'Filter your question in the categories below, or search in the search field above.',
    'faq.list.empty': 'No questions registered',
    'faq.list.not_found': 'no questions found',

    // Uninstalled Apllication
    'uninstalled_application.title': 'App Uninstalled',
    'uninstalled_application.title_description': 'On this screen you can to view the information of all devices on which the application was uninstalled',

    // Uninstall Attempts
    'uninstall_attempts.title': 'Attempts to Uninstall',
    'uninstall_attempts.title_description': 'On this screen, you can view information about all attempts to uninstall the ${process.env.REACT_APP_PORTAL_NAME} app from devices.',
    // Devices without communication
    'device_without_communication.title': 'Devices without Communication',
    'device_without_communication.title_description': 'On this screen you can to view the devices that are not communicating with the Portal',

    // Notification Menu
    'notification.title': 'Notifications',
    'notification.list.empty': "You don't have any notifications",
    'notification.list.title': 'Report:',
    'notification.list.button.url': 'Click to download',
    'menu.settings.profile_consumption': 'Consumption Profile',
    'notification.list.error': 'Report generation error',

    // Generate Report Alert
    'generate_report_alert.title': 'Do you want to export a report with the information displayed on the first page or a complete report with the result of all pages?',
    'generate_report_alert.sub_title': 'If you select the Complete option, the report will be generated in the background and may take a few minutes to complete.',
    'menu.help.faq': 'Common Questions',
    'generate_report_alert.button.first_page': 'First page',
    'generate_report_alert.button.complete': 'Complete',
    'generate_report_excel.legend.report': 'Report',
    'generate_report_excel.legend.current_date': 'Date',
    'generate_report_excel.legend.page': 'Page',
    'generate_report_excel.legend.total_pages': 'Total Pages',

    // New Keys
    'consumption_profile.toaster_success': 'Rule successfully registered!',
    'consumption_profile.general.description': "The configuration of Consumption Profiles does not reflect the nature of the service contracted with the operator. The information recorded on this form reflects your organization's usage policies only.",
    'consumption_profile.toaster_update': 'Rule updated successfully!',
    'consumption_profile.toaster_level_up': 'Rule set to level up successfully!',

    'manage_admin.entity': 'Administrator',
    'manage_admin.toaster.success.edit': 'Admin successfully edited',
    'manage_admin.filter.placeholder': 'Select',
    'message.send_label': 'Submit',

    'block_application.toaster.register': 'Rule successfully registered!',
    'block_application.toaster.update': 'Rule updated successfully!',
    'block_application.toaster.delete': 'Rule deleted successfully!',
    'menu.settings.qrcode': 'QR Code for Device Owner',

    'block_application.toaster.apply': 'Rules applied successfully!',
    'devices.message.remove_success': 'Successfully removed',
    'menu.websites.report_date': 'Report by date',
    'menu.websites.report_url': 'Report by URL / Keyword',
    'menu.websites': 'Websites',
    'menu.app_action.application_control.website_by_category': 'Websites by category',
    'menu.app_action.application_control.website_by_url': 'Websites by URL',
    'menu.help.suport': 'Support Request',
    'menu.groups_and_subgroups.subgroups': 'Subgroups',
    'menu.users': 'Users',
    'menu.users.device_users': 'Users',
    // Date Report
    'date_report.header.user': 'User',
    'date_report.header.phoneNumber': 'Telephone',
    'date_report.header.domain': 'Domain',
    'date_report.header.accessedAt': 'Date',

    'edit_group.company_name': 'Company',
    //MENU
    //dashboard
    //company
    // groups and subgroups
    //users
    'menu.users.device_users.manage_profile_access': 'Access profiles',
    //devices
    // GPS
    'reportsGps.column.phone': 'Telephone',
    //application
    'reportsGps.column.user': 'User',
    //sites
    'reportsGps.column.gps': 'GPS',
    //messages
    'reportsGps.column.createdAt': 'Date',
    //shered docs
    //location
    // CONSUMPTION HISTORIC
    'company_consumption.column.tabs_date': 'Date',
    'company_consumption.column.user': 'User',
    // adroid actions
    'company_consumption.column.package_consumption': 'Consumption',
    // settings
    'company_license.column.enrolled_device': 'Registered device',
    // help
    'company_license.select_state': 'Select a status',
    'map.address': 'Address',
    'map.speed': 'Speed',
    'consumption_profile.goup_company': 'Set on level above',
    'consumption_profile.edit.description': 'On this screen you can to register a new consumption profile rule for users who do not have a rule. If the user is not displayed in the selected field, he may already have a rule previously registered and you can change it. Review the previous screen and find the user who wants to change the consumption profile rule.',
    'sites_category.block.general.text': 'On this screen you can to configure a blocking or unblocking profile rule for websites by categories on devices.',
    'consumption_profile.data.limit': 'Data (Quantity)',
    'consumption_profile.dataRoaming.limit': 'Data roaming (Quantity)',
    'consumption_profile.sms.limit': 'SMS (Quantity)',
    'consumption_profile.smsRoaming.limit': 'SMS roaming (Quantity)',
    'card_help.discover_more': 'Know more',
    'sites_category.block.title': 'Block / Unblock websites by category',
    'message.content': 'Message',
    'consumption_profile.groupId': 'Group',
    'consumption_profile.userId': 'User',
    'global.empty_results': 'No results found.',
    'menu.open': 'Open Menu',
    'menu.close': 'Close menu',
    'reportsGps.start_date': 'Initial date',
    'reportsGps.end_date': 'Final date',

    'qrcode.valid_url': 'Must be a valid URL',
    'qrcode.lading_qrCode': 'Loading QRCode...',
    'qrcode.invalid_url': 'Invalid URL for download',
    'global.generic_not_found': 'Nothing to show.',
    'messages.max_characters': 'This field must have a maximum of 255 characters.',
    'global.not_option_found': 'No option found',
    'global.select_placeholder': 'Select an option',
    'block_application.rule_type': 'Rule type',
    'block_application.application': 'Application',
    'sites.block.success_create': 'Successfully created',
    'sites.block.success_update': 'Successfully updated',
    'company_license.select_plan': 'Select a plan',
    'global.action.set_level_up': 'Set Level Up',
    'devices.alert.change.text': 'Do you want to exchange the equipment?',
    'devices.alert.change.button': 'To exchange',
    'battery.chart_battery': 'Battery',
    'battery.chart_date': 'Time',
    'consumption_profile.modal.apply_config': 'Do you really want to level up?',
    'storage.chart_storage': 'Storage',
    'storage.chart_date': 'Time',
    'generate_complete_report_alert.confirm_button': 'Ok',
    'generate_complete_report_alert.title': 'Please wait for the report to be generated!',
    'generate_complete_report_alert.text': 'The system will display a notification at the top of the screen when finished, where you can download the report.',
    'global.copy.success': 'Copied to the clipboard.',
    'register_policies.title': 'Register new policy',
    'register_policies.description': 'In this screen you can to register a new policy.',
    'register_policies.name': 'Policy Name',
    'register_policies.policyType': 'Select policy management mode',
    'register_policies.select_policy': 'Select',
    'register_policies.select.placeholder': 'Select a mode',
    'menu.policy': 'Policies',
    'menu.policy.manage': 'Manage Policies',
    'manage_policies.title': 'Manage Policies',
    'manage_policies.description': 'On this page it is possible to manage policies',
    'manage_policies.success.create': 'Policy created successfully!',
    'manage_policies.new': 'New Policy',
    'manage_policies.table_actions.enrollment_token': 'Registration Token',
    'manage_policies.table_actions.edit': 'Edit Policy',
    'manage_policies.table_actions.copy': 'Copy Policy',
    'manage_policies.table_actions.delete': 'Remove Policy',
    'manage_policies.modal_enrollmentToken.title': 'Registration Token',
    'manage_policies.modal_enrollmentToken.code': 'Code',
    'manage_policies.modal_enrollmentToken.confirm': 'Ok',
    'menu.enterprise_applications': 'Application Management',
    'menu.enterprise_applications.manage': 'Managed Applications',
    'enterprise_applications.title': 'Managed Applications',
    'enterprise_applications.description': 'On this page it is possible to manage enterprise applications',
    'enterprise_applications.table_actions.entity': 'Application',
    'enterprise_applications.column.icon': 'Icon',
    'enterprise_applications.column.name_app': 'Application Name',
    'enterprise_applications.column.origin': 'Origin',
    'enterprise_applications.column.name_package': 'Package name',
    'enterprise_applications.filter': 'Application Name',
    'register_policies.android': 'Android - Fully Managed',
    'register_policies.android.description': 'For company-owned devices. Allows management of a wide variety of device settings and policy controls.',
    'register_policies.dedicated_mode': 'Dedicated Devices',
    'register_policies.dedicated_mode.description': 'For Dedicated Devices, where devices are locked to a set of apps (through configuring a kiosk app). It can prevent other applications from starting and other actions from being performed on the device.',
    'enterprise_applications.success.delete': 'Application successfully removed!',
    'manage_policies.success.delete': 'Policy successfully removed!',
    'menu.enterprise_applications.google_play_managed': 'Google Play Managed',
    'devices.groups': 'Groups',
    'google_play_managed.toast.success.description': 'Application selected successfully!',
    'google_play_managed.toast.error.description': 'An error occurred while selecting the application.',
    'google_play_managed.toast.success.modal.text': 'Do you want to select this application?',
    'register_company.button.next': 'Next',
    'manage_policies.confirm.text': 'Are you sure you want to remove this policy?',
    'register_policies.name.placeholder': 'Enter the policy name',
    'global.report': 'Report',
    'message.recipient': 'Recipient',
    'document.recipient': 'Recipient',

    'devices.column.management_mode': 'Model',
    'devices.column.enterpriseId': 'Id on Android Enterprise',
    'devices.action.wipe_device': 'Remove Device (WIPE)',
    'company_register.title': 'Create company',
    'company_register.subtitle': 'On this screen you can register your company information.',
    'menu.policy.register': 'Register new policy',
    'company_callback.success': 'Congratulations! Your company has been successfully registered with Android Enterprise.',
    'company_callback.failure': 'There was an error creating, please try again.',
    'global.ok': 'Ok',
    'global.confirm_password': 'Confirm new password',
    'register_company.info': 'A confirmation code will be sent to the email provided above.',
    'register_company.title1': 'Confirm company details',
    'register_company.title2': 'To continue',
    'register_company.create_new_password': 'Create new password for user',
    'register_company.email': 'Email',
    'register_company.corporateName': 'Company Name',
    'register_company.password': 'Password',
    'register_company.confirm_password': 'Password Confirmation',
    'register_company.optional': '(Optional)',
    'register_company.send_confirm': 'Send confirmation code',
    'register_company.eu.representative': 'EU Representative',
    'register_company.data.protection.officer': 'Data Protection Officer',
    'register_company.password_alert': 'Passwords do not match. It is necessary to fill in the confirmation field with the same password to change the password. Review the passwords entered and try again.',
    'register_validate.title1': 'Check the',
    'register_validate.title2': 'your email',
    'register_validate.info': 'Fill in the 6-digit code that was sent to your email',
    'register_validate.resend': 'Resend code',
    'register_validate.send_confirm': 'Confirm',
    'enterprise_applications.table_actions.remove': 'Remove',
    'policy_copy.title': 'Copy policy',
    'policy_copy.subtitle': 'copying policy',
    'manage_policies.policy': 'Policy',
    'register_company.empty_password_alert': 'The password must be informed',
    'update_policies.title': 'Edit Policy',
    'update_policies.description': 'On this screen you can update a policy.',
    'update_policies.tab.configuration': 'Settings',
    'update_policies.tab.application': 'Applications',
    'update_policies.tab.kiosk': 'Kiosk Mode',
    'update_policies.tab.token': 'Registration Token',
    'update_policies.policyType': 'Management Mode',
    'update_policies.name': 'Policy Name',
    'devices.column.policy': 'Policy',
    'devices.column.accordion.management_mode': 'Management Mode',
    'devices.column.accordion.management_mode.managed_android': 'Android',
    'devices.column.accordion.management_mode.block_sim_android': 'Android - Block Sim',
    'devices.column.accordion.management_mode.work_profile_android': 'Android - Work Profile',
    'devices.column.accordion.management_mode.managed_ios': 'IOS',
    'devices.column.accordion.management_mode.supervised_ios': 'IOS supervisionado',
    'devices.column.status': 'Status',
    'devices.column.appliedState': 'Applied Status',
    'devices.column.register_date': 'Registration Date',
    'devices.column.accordeon.management_mode': 'management mode',
    'devices.column.operation_system': 'Operational system',
    'devices.column.last_sync': 'Last Policy Sync',
    'devices.column.serial_number': 'Serial number',
    'devices.column.battery_power': 'Battery Level',
    'devices.column.storage': 'Internal Storage',
    'devices.value.state_active': 'Active',
    'devices.value.state_disabled': 'Disabled',
    'devices.value.state_deleted': 'Deleted',
    'devices.value.state_provisioning': 'Provisioning',
    'edit_policy.kiosk.navigate_button': 'Navigation Buttons',
    'edit_policy.kiosk.power_button': 'Power button',
    'edit_policy.kiosk.message_error_exibition': 'Display Error Messages',
    'edit_policy.kiosk.status_bar_exibition': 'Information Displayed in the Status Bar',
    'edit_policy.kiosk.config_access': 'Access to Settings',
    'edit_policy.kiosk.available': 'Available',
    'edit_policy.kiosk.blocked': 'Blocked',
    'edit_policy.kiosk.active': 'Active',
    'edit_policy.kiosk.muted': 'Muted',
    'edit_policy.kiosk.notify_info_system': 'Notifications and System Information',
    'edit_policy.kiosk.sytem_only': 'System Information Only',
    'edit_policy.kiosk.none': 'None',
    'edit_policy.kiosk.released': 'Released',
    'edit_policy.kiosk.home_only': 'Only “Home” button ',
    'edit_policy.kiosk.kiosk_mode.title': 'Kiosk Mode',
    'edit_policy.kiosk.kiosk_mode.subtitle': 'You can control Kiosk mode settings. After enabling Kiosk Mode, access the "Applications" tab to define how they will be displayed on devices registered in this policy.',
    'edit_policy.kiosk.modal.active': 'Activate',
    'edit_policy.kiosk.modal.title': 'Are you sure you want to activate Kiosk Mode?',
    'update_policies.button.save': 'Save',
    'register_company.term.title': 'Policy and Terms',
    'register_company.term.description': 'To use, {productName}, you must read and agree to the Privacy Policy and User Terms of Use',
    'register_company.term.user_term': 'I agree to the User Terms of Use',
    'register_company.term.privacy_policy': 'I agree with the Privacy Policy',
    'update_policies.warning_exit': 'Do you want to exit policy editing without saving it? All changes made will be discarded!',
    'update_policies.message_success': 'Policy saved successfully!',
    'history_consumption.title': 'Consumption History',
    'history_consumption.description': "On this screen, you can to visualize the evolution graph of the application's data consumption on the devices.",
    'update_policies.applications.card_mode.title': 'Apps selection mode in the Google Play',
    'update_policies.applications.card_mode.description': 'Here you can control how your apps will be displayed on devices registered to this profile. See the description of the selected mode for details. In "Restricted" mode, users can only view and install the apps listed below from the Google Play Store. The other apps will be removed.',
    'update_policies.applications.card_mode.whitelist': 'Restricted',
    'update_policies.applications.card_mode.blacklist': 'Open',
    'update_policies.applications.card_mode.kiosk': 'Kiosk Mode',
    'update_policies.applications.table.column.name_app': 'Application Name',
    'update_policies.applications.table.column.origin': 'Origin',
    'update_policies.applications.table.column.install_type': 'Installation Type',
    'update_policies.applications.table.button_label': 'Add apps',
    'update_policies.applications.installation_type.preinstalled': 'Pre-defined',
    'update_policies.applications.installation_type.force_installed': 'Forced Installation',
    'update_policies.applications.installation_type.blocked': 'Blocked',
    'update_policies.applications.installation_type.available': 'Available',
    'update_policies.applications.table_action.settings': 'Managed Settings',
    'update_policies.applications.table_action.permissions': 'Permissions',
    'update_policies.applications.table_action.advance_settings': 'Advanced Settings',
    'update_policies.applications.table_action.remove_app': 'Remove Application',
    'update_policies.modal_enterprise_apps.title': 'Add apps',
    'update_policies.modal_enterprise_apps.button_label': 'Add selected',
    'update_policies.settings.accordion.network': 'Network',
    'update_policies.settings.accordion.network.vpn.title': 'Block VPN Settings',
    'update_policies.settings.accordion.network.vpn.description': 'VPN configuration is disabled',
    'update_policies.settings.accordion.network.wifi.title': 'Block WiFi settings',
    'update_policies.settings.accordion.network.wifi.description': 'Wi-Fi hotspot configuration is disabled',
    'update_policies.settings.accordion.network.network_reset.title': 'Block resetting network settings',
    'update_policies.settings.accordion.network.network_reset.description': 'Network settings reset is disabled',
    'update_policies.settings.accordion.network.roaming_block.title': 'Block roaming data',
    'update_policies.settings.accordion.network.roaming_block.description': 'Data roaming settings are disabled',
    'update_policies.settings.accordion.network.mobile_network_block.title': 'Block mobile network configuration',
    'update_policies.settings.accordion.network.mobile_network_block.description': 'Mobile network configuration is disabled',

    'update_policies.settings.accordion.hardware': 'Hardware',
    'update_policies.settings.accordion.hardware.disable_camera.title': 'Disable camera',
    'update_policies.settings.accordion.hardware.disable_camera.description': 'All device cameras are disabled',
    'update_policies.settings.accordion.hardware.lock_volume.title': 'Lock volume adjustments',
    'update_policies.settings.accordion.hardware.lock_volume.description': 'Master volume adjustment is disabled. It also mutes the device.',
    'update_policies.settings.accordion.hardware.disable_bluetooth.title': 'Disable bluetooth',
    'update_policies.settings.accordion.hardware.disable_bluetooth.description': 'Bluetooth is off',
    'update_policies.settings.accordion.hardware.bluetooth_settings_block.title': 'Block bluetooth settings',
    'update_policies.settings.accordion.hardware.bluetooth_settings_block.description': 'Bluetooth setting is disabled',
    'update_policies.settings.accordion.hardware.disable_hotspot.title': 'Disable hotspot settings',
    'update_policies.settings.accordion.hardware.disable_hotspot.description': 'Portable hotspots configuration is disabled',
    'update_policies.settings.accordion.hardware.disable_screenshots.title': 'Disable screenshots',
    'update_policies.settings.accordion.hardware.disable_screenshots.description': 'Screen capture is disabled',
    'update_policies.settings.accordion.hardware.microphone_access.title': 'Microphone access',
    'update_policies.settings.accordion.hardware.microphone_access.description': 'Microphone is muted and microphone volume adjustment is disabled',
    'update_policies.settings.accordion.hardware.microphone_access.select.user_choice': 'User defined',
    'update_policies.settings.accordion.hardware.microphone_access.select.access_disabled': 'Disabled',
    'update_policies.settings.accordion.hardware.microphone_access.select.access_enforced': 'Activated',
    'update_policies.settings.accordion.hardware.outgoing_calls_disabled.title': 'Disable outgoing calls',
    'update_policies.settings.accordion.hardware.outgoing_calls_disabled.description': 'All outgoing phone calls are disabled.',
    'update_policies.settings.accordion.hardware.bluetooth_contact_sharing_disabled.title': 'Block Contact Sharing Via Bluetooth',
    'update_policies.settings.accordion.hardware.bluetooth_contact_sharing_disabled.description': 'Contact sharing via Bluetooth is disabled.',

    'update_policies.settings.accordion.safety': 'Safety',
    'update_policies.settings.accordion.safety.new_users_block.title': 'Block adding new users',
    'update_policies.settings.accordion.safety.new_users_block.description': 'Adding new users and profiles is disabled',
    'update_policies.settings.accordion.safety.new_users_block_remove.title': 'Block removal of new users',
    'update_policies.settings.accordion.safety.new_users_block_remove.description': 'Removal from other users is disabled',
    'update_policies.settings.accordion.safety.reset_block.title': 'Block factory reset',
    'update_policies.settings.accordion.safety.reset_block.description': 'Factory reset of settings is disabled',
    'update_policies.settings.accordion.safety.sd_card_block.title': 'Block SD Card',
    'update_policies.settings.accordion.safety.sd_card_block.description': 'External physical media mount is disabled',
    'update_policies.settings.accordion.safety.usb_sharing_block.title': 'Block file sharing via USB',
    'update_policies.settings.accordion.safety.usb_sharing_block.description': 'USB file transfer is disabled',

    'update_policies.settings.accordion.localization': 'Location',
    'update_policies.settings.accordion.localization.mode.title': 'Location mode',
    'update_policies.settings.accordion.localization.mode.description': 'Location detection enabled',
    'update_policies.settings.accordion.localization.mode.select.user_choice': 'User defined',
    'update_policies.settings.accordion.localization.mode.select.location_disabled': 'Disabled',
    'update_policies.settings.accordion.localization.mode.select.location_enforced': 'Activated',
    'update_policies.settings.accordion.localization.location_sharing_disabled.title': 'Disable location sharing',
    'update_policies.settings.accordion.localization.location_sharing_disabled.description': 'Location sharing is turned off',
    'update_policies.settings.accordion.localization.monitoring_hours.title': 'Monitoring Hours',
    'update_policies.settings.accordion.localization.monitoring_hours.description': 'Time when location detection is enabled',
    'update_policies.settings.accordion.localization.monitoring_hours.initial_time': 'Start time',
    'update_policies.settings.accordion.localization.monitoring_hours.final_time': 'End time',
    'update_policies.settings.accordion.localization.monitoring_hours.select': 'Select',
    'update_policies.settings.accordion.localization.monitoring_hours.gps_frequency.min3': '3 minutes',
    'update_policies.settings.accordion.localization.monitoring_hours.gps_frequency.min10': '10 minutes',
    'update_policies.settings.accordion.localization.monitoring_hours.gps_frequency.min30': '30 minutes',
    'update_policies.settings.accordion.localization.monitoring_hours.gps_frequency.h1': '1 hour',
    'update_policies.settings.accordion.localization.monitoring_hours.gps_frequency.h6': '6 hours',
    'update_policies.settings.accordion.localization.monitoring_hours.gps_frequency.h12': '12 hours',
    'update_policies.settings.accordion.localization.monitoring_hours.gps_frequency.h24': '24 hours',
    'update_policies.settings.accordion.localization.find_every.title': 'Location Frequency',
    'update_policies.settings.accordion.localization.find_every.description': 'Location capture frequency',
    'update_policies.locationMode': 'Location - Location mode',
    'update_policies.gpsCaptureStartTime': 'Location - Start time',
    'update_policies.gpsCaptureEndTime': 'Location - End time',
    'update_policies.gpsFrequency': 'Location - Locate every',
    'edit_policy.app_config.title': 'Managed Settings',
    'edit_policy.app_config.list_application': 'Application List',
    'update_policies.applications.app_advanced_config.title': 'Advanced Settings',
    'update_policies.applications.app_advanced_config.auto_update_mode.title': 'Update Priority',
    'update_policies.applications.app_advanced_config.auto_update_mode.description': 'Auto update mode',
    'update_policies.applications.app_advanced_config.auto_update_mode.no_specify': 'Not Specified',
    'update_policies.applications.app_advanced_config.auto_update_mode.default': 'Pre-defined',
    'update_policies.applications.app_advanced_config.auto_update_mode.postponed': 'Postponed',
    'update_policies.applications.app_advanced_config.auto_update_mode.high_priority': 'Priority',
    'update_policies.applications.app_advanced_config.minimum_version_code.title': 'Minimum Version',
    'update_policies.applications.app_advanced_config.minimum_version_code.description': 'Minimum version code',
    'edit_policy.app_permissions.title': 'Permissions',
    'edit_policy.app_permissions.select_label.unspecified': 'Not specified',
    'edit_policy.app_permissions.select_label.prompt': 'Ask the User',
    'edit_policy.app_permissions.select_label.grant': 'Activated',
    'edit_policy.app_permissions.select_label.deny': 'Denied',
    'edit_policy.applications.managed_config.empty_config': 'There are no settings available for this application.',
    'edit_policy.applications.managed_config.empty_permission': 'There are no settings available for this application.',
    'devices.column.federalCode': 'Identification',

    'history_consumption.header.filter_by': 'Filtered by:',
    'history_consumption.chart_legend.consumption': 'Consumption',
    'history_consumption.chart_legend.day': 'Day',
    'history_consumption.chart_legend.empty': 'No history found',
    'history_consumption_data.title': 'Data Consumption History',
    'history_consumption_sms.title': 'SMS Consumption History',
    'history_consumption.history_user.description': "On this screen, you can to visualize the evolution graph of the application's data consumption.",
    'notification.not_found_key': 'Key not found',

    'update_policies.applications': 'Application',
    'update_policies.applications.minimumVersionCode': 'Minimum App Version',

    'global.blocked': 'Blocked',
    'global.released': 'Released',

    'edit_policy.app_config.key_existing_alert': 'Existing keyword',
    'consumption_limit_group.title': 'Consumption Profile - Group',
    'consumption_limit_user.title': 'Consumption Profile - User',
    'devices.action.policy': 'Change Policy',
    'devices.action.turn_off_screen': 'Turn off screen',
    'devices.action.restart_device': 'Restart device',
    'devices.action.alert_reboot1': 'The device',
    'devices.action.alert_reboot2': 'Has been restarted.',
    'devices.action.alert_reboot': 'Device restart command was sent successfully!',

    'non_compliance.title': 'Non-compliances',
    'non_compliance.sub_title': 'On this screen, you can to identify the policy configuration that was not applied by the user on the device and the reason for the non-compliance.',
    'non_compliance.card_header.title': 'Device',
    'non_compliance.form.user': 'User',
    'non_compliance.form.phoneNumber': 'Telephone',
    'non_compliance.form.imei': 'IMEI',
    'non_compliance.form.manufacturer': 'Manufacturer',
    'non_compliance.form.model': 'Model',
    'non_compliance.column.settingName': 'Settings',
    'non_compliance.column.nonComplianceReason': 'Reason for Non-Compliance',
    'non_compliance.nonComplianceReason.reason_unspecified': 'This value is not allowed',
    'non_compliance.nonComplianceReason.api_level': 'The configuration is not compatible with the API level of the Android version running on the device.',
    'non_compliance.nonComplianceReason.management_mode': 'Management mode (profile owner, device owner, etc.) is not supported by the configuration.',
    'non_compliance.nonComplianceReason.user_action': 'The user has not taken the necessary steps to comply with the configuration.',
    'non_compliance.nonComplianceReason.invalid_value': 'The setting has an invalid value.',
    'non_compliance.nonComplianceReason.app_not_installed': 'The application required to implement the policy is not installed.',
    'non_compliance.nonComplianceReason.unsupported': 'The policy is not supported by the Android Device Policy version on the device.',
    'non_compliance.nonComplianceReason.app_installed': 'A blocked app is installed.',
    'non_compliance.nonComplianceReason.pending': 'The configuration has not been applied at the time of reporting, but is expected to be applied soon.',
    'non_compliance.nonComplianceReason.app_incompatible': 'The configuration cannot be applied to the application because the application does not support it, for example because the target SDK version is not high enough.',
    'non_compliance.nonComplianceReason.app_not_updated': 'The application is installed but not updated to the minimum version code specified by the policy.',
    'non_compliance.column.specificNonComplianceReason': 'Non-Compliance Detail',
    'non_compliance.specificNonComplianceReason.unspecified': 'The specific reason for non-compliance is not specified.',
    'non_compliance.specificNonComplianceReason.credentials_confirmation_required': 'User needs to confirm credentials by typing screen lock.',
    'non_compliance.specificNonComplianceReason.password_expired': 'The device or profile password has expired.',
    'non_compliance.specificNonComplianceReason.password_not_sufficient': 'Device password does not meet password requirements.',
    'non_compliance.specificNonComplianceReason.wifi_invalid_value': 'There is an incorrect value in the ONC Wi-Fi setting.',
    'non_compliance.specificNonComplianceReason.wifi_api_level': 'The ONC Wi-Fi configuration does not support the API level of the Android version running on the device.',
    'non_compliance.expander.installationFailureReason': 'Installation Failure Reason',
    'non_compliance.expander.installationFailureReason.unspecified': 'This value is not allowed.',
    'non_compliance.expander.installationFailureReason.unknown': "An unknown condition is preventing the application from installing. Some possible reasons are that the device does not have enough storage, the device's network connection is unreliable, or the installation is taking longer than expected. The installation will automatically repeat.",
    'non_compliance.expander.installationFailureReason.in_progress': 'Installation is still in progress.',
    'non_compliance.expander.installationFailureReason.not_found': 'The application was not found on Play.',
    'non_compliance.expander.installationFailureReason.not_compatible': 'The application is incompatible with the device.',
    'non_compliance.expander.installationFailureReason.not_approved': 'The application has not been approved by the administrator.',
    'non_compliance.expander.installationFailureReason.permissions_not_accepted': 'The application has new permissions that were not accepted by the admin.',
    'non_compliance.expander.installationFailureReason.not_available_in_country': "The application is not available in the user's country.",
    'non_compliance.expander.installationFailureReason.no_licenses_remaining': 'There are no licenses available to assign to the user.',
    'non_compliance.expander.installationFailureReason.not_enrolled': 'The company is no longer enrolled in Managed Google Play or the administrator has not accepted the latest Managed Google Play Terms of Service.',
    'non_compliance.expander.installationFailureReason.user_invalid': 'The user is no longer valid. The user may have been deleted or disabled.',
    'non_compliance.expander.packageName': 'Package name',
    'non_compliance.expander.fieldPath': 'Field Path',
    'non_compliance.expander.currentValue': 'Current value',
    'non_compliance.expander.wifi_context': 'Wi-Fi Context',
    'non_compliance.expander.password_context': 'Policy password context',
    'non_compliance.expander.password_context.scope_unspecified': 'Unspecified scope',
    'non_compliance.expander.password_context.scope_device': 'Device scope',
    'non_compliance.expander.password_context.scope_profile': 'Profile scope',
    'update_policies.settings.accordion.password': 'Password Restrictions',
    'update_policies.settings.accordion.password.select.something': 'Some',
    'update_policies.settings.accordion.password.select.numeric': 'Numerical',
    'update_policies.settings.accordion.password.select.numeric_complex': 'Complex Numeric',
    'update_policies.settings.accordion.password.select.alphabetic': 'alphabetical',
    'update_policies.settings.accordion.password.select.alphanumeric': 'alphanumeric',
    'update_policies.settings.accordion.password.select.complex': 'Complex',
    'update_policies.settings.accordion.password.select.biometric_weak': 'Biometry',
    'update_policies.settings.accordion.password.title': 'Password Quality',
    'devices.action.new_device_password': 'Generate New Device Password',
    'device.modal.new_device_password.description': 'Enter settings to reset device lock password',
    'device.modal.new_device_password.newPassword': 'New Password',
    'device.modal.new_device_password.new_password_confirm': 'Confirm new password',
    'device.modal.new_device_password.checkbox.not_allow_password_change': 'Do not allow other admins to change the password again until the user enters it on the device.',
    'device.modal.new_device_password.checkbox.not_ask_credentials_user': 'Do not ask for user credentials at device startup.',
    'device.modal.new_device_password.checkbox.device_block': 'Block device after password reset.',
    'update_policies.passwordPolicies.select.something': 'Some',
    'update_policies.passwordPolicies.select.numeric': 'Numerical',
    'update_policies.passwordPolicies.select.numeric_complex': 'Complex Numeric',
    'update_policies.passwordPolicies.select.alphabetic': 'alphabetical',
    'update_policies.passwordPolicies.select.alphanumeric': 'alphanumeric',
    'update_policies.passwordPolicies.select.complex': 'Complex',
    'update_policies.passwordPolicies.select.biometric_weak': 'Biometry',
    'update_policies.passwordPolicies.title': 'Password Quality',
    'update_policies.passwordPolicies.select.password_require.default': 'Device Default',
    'update_policies.passwordPolicies.select.password_require.every_day': 'Everyday',
    'update_policies.passwordPolicies.passwordHistoryLength': 'Password history length',
    'update_policies.passwordPolicies.maximumFailedPasswordsForWipe': 'Maximum bad passwords before running wipe',
    'update_policies.passwordPolicies.passwordExpirationTimeout': 'Password expiration timeout (days)',
    'update_policies.passwordPolicies.requirePasswordUnlock': 'Require password unlock',
    'update_policies.passwordPolicies.passwordMinimumLength': 'Minimum password length',
    'update_policies.passwordPolicies.passwordMinimumLetters': 'Minimum number of letters required in the password',
    'update_policies.passwordPolicies.passwordMinimumNonLetter': 'Minimum number of non-letter characters (numeric digits or symbols) required in the password',
    'update_policies.passwordPolicies.passwordMinimumNumeric': 'Minimum number of numeric digits required in the password',
    'update_policies.passwordPolicies.passwordMinimumSymbols': 'Minimum number of symbols required in the password',
    'update_policies.passwordPolicies.passwordMinimumUpperCase': 'Minimum number of capital letters required in the password',
    'update_policies.passwordPolicies.passwordMinimumLowerCase': 'Minimum number of lowercase letters required in the password',
    'update_policies.passwordPolicies.description.biometric_weak': 'The device must be secured with at least low security biometric recognition technology.',
    'update_policies.passwordPolicies.description.something': 'A password is required, but there are no restrictions on what the password must contain.',
    'update_policies.passwordPolicies.description.complex': 'The password must contain at least one letter, one numeric character and one symbol.',
    'update_policies.passwordPolicies.description.numeric': 'The password must contain at least numeric characters.',
    'update_policies.passwordPolicies.description.numeric_complex': 'The password must contain at least numeric characters without repeating (4444) or ordered (1234, 4321, 2468) sequences.',
    'update_policies.passwordPolicies.description.alphabetic': 'The password must contain at least alphabetic characters (or symbols).',
    'update_policies.passwordPolicies.description.alphanumeric': 'The password must contain at least numeric and alphabetic characters (or symbols).',
    'device.modal.new_device_password.success': 'The password reset command was sent successfully!',
    'update_policies.applications.installation_type.required_for_setup': 'Required for Setup',
    'update_policies.settings.accordion.safety.untrusted_app.title': 'Untrusted Applications Policy.',
    // System Update
    'update_policies.settings.accordion.operational_system': 'Operational system',
    'update_policies.systemUpdate.select.type': 'Type',
    'update_policies.systemUpdate.select.type.description': 'The system update policy controls how operating system updates are applied. If the update type is "Automatic within a window", the update window will also automatically apply to app updates from Google Play.',
    'update_policies.systemUpdate.select.unspecified': 'User defined',
    'update_policies.systemUpdate.select.automatic': 'Automatic',
    'update_policies.systemUpdate.select.windowed': 'Automatic within a window',
    'update_policies.systemUpdate.select.postpone': 'Postponed',
    'update_policies.systemUpdate.time': 'Time',
    'update_policies.systemUpdate.time.description': 'By setting the “Automatic within a window” policy, system updates will be performed within the specified window time.',
    'update_policies.systemUpdate.time.select': 'Select',
    'update_policies.systemUpdate.startMinutes': 'Start time',
    'update_policies.systemUpdate.endMinutes': 'End time',
    'update_policies.freezePeriods': 'Freeze periods',
    'update_policies.systemUpdate.freeze_period.description': 'All received system updates (including security patches) are locked and will not be installed. When a device is out of the freezing period, the normal update behavior will apply.',
    'update_policies.systemUpdate.add_freeze_period': 'Add freeze period',
    'update_policies.systemUpdate.freezePeriods.startDate': 'Start month and day of freeze period',
    'update_policies.systemUpdate.freezePeriods.endDate': 'End month and day of freeze period',
    'update_policies.systemUpdate.start': 'Month/Day Start',
    'update_policies.systemUpdate.end': 'Month/Day End',
    'update_policies.systemUpdate.month': 'Month',
    'update_policies.systemUpdate.day': 'Day',
    'update_policies.settings.accordion.safety.untrusted_app.description': 'Installation of applications from unknown sources',
    'update_policies.settings.accordion.safety.developer_settings.title': 'Developer settings.',
    'update_policies.settings.accordion.safety.developer_settings.description': 'Control the access to the developer settings: unwrap options and safe initialization',
    'update_policies.settings.accordion.safety.untrusted_apps.select.disallow_install': 'Do not allow installs of untrusted apps',
    'update_policies.settings.accordion.safety.untrusted_apps.select.allow_install': 'Allow installs of untrusted apps',
    'update_policies.settings.accordion.safety.developer_settings.select.developer_settings_disabled': 'Disable developer settings',
    'update_policies.settings.accordion.safety.developer_settings.select.developer_settings_allowed': ' Enable developer settings',
    'update_policies.applications.installation_type.kiosk': 'Kiosk',
    'password_recovery.modal.error': 'Error sending unlocking instructions to your e-mail',
    'global.privacy_policy': 'Privacy Policy',
    'global.terms_of_use': 'User Terms of Use',
    'device.modal.update_policy': 'Select new policy',
    'menu.zero_touch': 'Zero Touch',
    'manage_policies.modal_enrollmentToken.policy': 'Policy',
    'manage_policies.modal_enrollmentToken.register_token': 'Registration Token',
    'manage_policies.modal_enrollmentToken.json_zero_touch': 'Zero Touch Settings',
    'text_with_copy.success': '{value} has been successfully copied!',
    'register_validate.token.error': 'Invalid or expired token',
    'edit_policy.applications.managed_config.block_categories': 'Site Blocking by Category',
    'edit_policy.applications.managed_config.block_categories.adult_content': 'Adult content',
    'edit_policy.applications.managed_config.block_categories.alcoholic': 'Alcoholic content',
    'edit_policy.applications.managed_config.block_categories.business': 'Business',
    'edit_policy.applications.managed_config.block_categories.chat': 'Chat',
    'edit_policy.applications.managed_config.block_categories.email': 'Email',
    'edit_policy.applications.managed_config.block_categories.educational': 'Educational',
    'edit_policy.applications.managed_config.block_categories.entertainment': 'Entertainment',
    'edit_policy.applications.managed_config.block_categories.file_shares': 'Shared files',
    'edit_policy.applications.managed_config.block_categories.forum': 'Forum',
    'edit_policy.applications.managed_config.block_categories.gambling': 'Bets',
    'edit_policy.applications.managed_config.block_categories.games': 'Games',
    'edit_policy.applications.managed_config.block_categories.news': 'News',
    'edit_policy.applications.managed_config.block_categories.proxies': 'Powers of attorney',
    'edit_policy.applications.managed_config.block_categories.search_engine': 'Means of research',
    'edit_policy.applications.managed_config.block_categories.shopping': 'Shopping',
    'edit_policy.applications.managed_config.block_categories.social_media': 'Social media',
    'edit_policy.applications.managed_config.block_categories.sports': 'Sports',
    'edit_policy.applications.managed_config.block_categories.tabaco': 'Tobacco',
    'edit_policy.applications.managed_config.block_categories.technology': 'Technology',
    'edit_policy.applications.managed_config.block_categories.travel': 'Trips',
    'edit_policy.applications.managed_config.block_categories.violence': 'Violence',
    'edit_policy.applications.managed_config.block_categories.health': 'Health',
    'edit_policy.applications.managed_config.block_categories.jobs': 'Jobs',
    'company_info.subtitle.dataProtection': 'Data Protection Officer',
    'company_info.subtitle.euRepresentative': 'Representative in the European Union',
    'company_license.column.state': 'Status',
    'company_license.filter.state': 'Status',
    'global.version': 'Version',
    'company_license.filter.enrolled_device': 'Registered device',
    'edit_policy.kiosk.modal.disable': 'Disable',
    'edit_policy.kiosk.modal.disable.title': 'Are you sure you want to disable Kiosk Mode?',
    'update_policies.passwordPolicies.select.unspecified': 'None',
    'update_policies.applications.alert.text.add': 'When adding the {appName} in the policy, the system will remove the password restrictions settings. Are you sure you want to add the app?',
    'update_policies.applications.alert.text.remove': 'Password restrictions settings have been enabled',
    'update_policies.passwordPolicies.description.app_block_sim': 'It is not possible to change these settings while the policy has the CHIP Lock App. Remove the app from the policy so you can change these settings.',
    'manage_policies.filter.policy_type.ios': 'IOS',
    'manage_policies.filter.policy_type.android_managed': 'Fully managed',
    'manage_policies.filter.policy_type': 'Management Mode',
    'manage_policies.filter.kiosk.true': 'Yes',
    'manage_policies.filter.kiosk.false': 'No',
    'manage_policies.filter.kiosk_custom_launcher_enabled': 'Kiosk Mode',
    'manage_policies.filter.play_store': 'Play Store',
    'manage_policies.filter.play_store.open': 'Open',
    'manage_policies.filter.play_store.restricted': 'Restricted',
    'update_policies.passwordPolicies.description.unspecified': 'No password is required.',
    'manage_policies.filter.placeholder': 'Select an option',
    'menu.audit': 'Audit',
    'audit.title': 'Audit',
    'audit.description': 'On this screen, you can view and generate reports of changes made to the portal and the device.',
    'audit.start_date': 'Initial date',
    'audit.last_date': 'Final date',
    'audit.users': 'Admin Users',
    'audit.activity': 'Activity',
    'audit.category': 'Category',
    'audit.fields_modified': 'Change',
    'devices.value.state_register': 'Waiting for Android Enterprise',
    'devices.value.state_android_enterprise': 'Android Enterprise',
    'company_info.modal.title': 'Check your email',
    'company_info.modal.text': 'Fill in the 6-digit code that was sent to your email',
    'company_info.modal.send_code': 'Resend Code',

    'consumption_profile.data.mode': 'Data',
    'consumption_profile.dataRoaming.mode': 'Data Roaming',
    'consumption_profile.dataRoaming.id': 'Roaming Data ID',

    'consumption_profile.sms.mode': 'SMS',
    'consumption_profile.smsRoaming.id': 'SMS ID',
    'consumption_profile.smsRoaming.mode': 'SMS Roaming',
    'manage_groups.column.name': 'Group',
    'devices.infos.title': 'Manage Device',
    'devices.infos.sub_title': 'On this screen, you can access device information.',
    'devices.infos.card_header.title': 'Device',
    'devices.infos.imei': 'IMEI',
    'devices.infos.mode': 'Mode',
    'devices.infos.identification': 'Identification',
    'devices.infos.serial_number': 'Serial number',
    'devices.infos.management_mode': 'Management Mode',
    'devices.infos.appliedState': 'Applied Status',
    'devices.infos.in_accordance': 'In accordance',
    'devices.infos.register_date': 'Registration Date',
    'devices.infos.last_update': 'Last Update Date',
    'devices.infos.last_communication': 'Last Communication Date',
    'devices.infos.date_sync': 'Policy Sync Date',
    'devices.infos.info_software': 'Software Information',
    'devices.infos.info_hardware': 'Hardware Information',
    'devices.infos.android_version': 'Android version',
    'devices.infos.policy_version_applied': 'Applied Policy Version on Device',
    'devices.infos.policy_name_on_device': 'Applied Policy Name on Device',
    'devices.infos.policy_name_on_portal': 'Policy Name on the Portal',
    'devices.infos.intern_storage': 'Internal Storage',
    'devices.infos.battery': 'Battery',
    'devices.infos.label_dedicated': 'Dedicated Device',
    'devices.infos.label_managed': 'Fully Managed',
    'devices.infos.label_ios': 'IOS',
    'devices.infos.label_android': 'Android',
    'devices.infos.status': 'Status',
    'devices.infos.compliance': 'According to',
    'devices.infos.incompliance': 'No compliance',
    'devices.infos.status_active': 'Activated',
    'devices.infos.status_non_active': 'Disabled',
    'devices.infos.last_update_tooltip': 'This date refers to the last time the device information was updated.',
    'devices.infos.last_communication_tooltip': 'This date refers to the last time the device communicated with the Portal',
    'login.modal.block_user': 'Your access has been blocked due to too many incorrect attempts. Wait 15 minutes before trying again.',
    'devices.action.manage_infos': 'Manage',
    'devices.infos.user': 'User',
    'devices.infos.manufacturer': 'Manufacturer',
    'devices.infos.phone': 'Telephone',
    'devices.infos.model': 'Model',
    'devices.infos.policy_sync': 'Policy Sync Date',
    'devices.infos.non_accordance': 'Non Accordance',
    'audit.company.email': 'Email',
    'audit.company.authenticatedEmail': 'Authenticated Email',
    'audit.company.privacyPolicy': 'Privacy Policy',
    'audit.company.userTerms': 'User Terms of Use',
    'audit.company.corporateName': 'Corporate Name',
    'audit.company.dataProtectionOfficerName': 'Data Protection - Name',
    'audit.company.dataProtectionOfficerEmail': 'Data Protection - Email',
    'audit.company.dataProtectionOfficerPhone': 'Data Protection - Phone',
    'audit.company.euRepresentativeName': 'Representative in the EU - Name',
    'audit.company.euRepresentativeEmail': 'Representative in the EU - Email',
    'audit.company.euRepresentativePhone': 'Representative in the EU - Telephone',
    'audit.company.federalCode': 'Federal Code',
    'audit.company.phoneNumber': 'Telephone',
    'audit.company.contact': 'Contact',
    'audit.company.address': 'Address',
    'audit.company.zipCode': 'Zip code',
    'audit.company.city': 'City',
    'audit.company.state': 'State',
    'audit.company.timezone': 'Timezone',
    'audit.company.createdAt': 'Registration Date',
    'audit.company.startDayOfWeek': 'Start day of the week',
    'audit.company.endDayOfWeek': 'End day of the week',
    'audit.company.startCycleDay': 'Start of cycle',
    'audit.company.syncTime': 'Sync every',
    'audit.company.notifyByEmail': 'Notice Email',
    'audit.company.emailToken': 'Token for email registration',
    'audit.company.enterpriseId': 'Code in Google',
    'audit.admin_user.remove': 'Remove',
    'audit.accordion_empty': ' No changes available to user',
    'register_policies.ios': 'IOS',
    'register_policies.ios.description': 'For company-owned devices. Allows management of IOS device settings and sites and policy controls.',
    'manage_policies.data.android_managed': 'Android',
    'manage_policies.data.ios': 'IOS',
    'register_policies.operationalSystem': 'Select policy management mode',
    'update_policies.tab.web_sites': 'Websites',
    'devices.infos.federalCode': 'Identification',
    'devices.infos.group': 'Group',
    'devices.infos.name': 'User',
    'application_manage.column.name': 'Application Name',
    'application_manage.column.quantity': 'Quantity',
    'application_manage.column.consumption': 'Consumption',
    'application_manage.column.time': 'Usage time',
    'devices.infos.udid': 'UDID',
    'global.operational_system': 'Operational system',
    'devices.infos.in_accordance.yes': 'Yes',
    'devices.infos.in_accordance.no': 'No',
    'policy_copy.name': 'Policy Name',
    'policy_copy.name.placeholder': 'Enter the policy name',
    'update_policies.settings.accordion.sync_backup': 'Sync and Backup',
    'update_policies.settings.accordion.sync_backup.backup.title': 'Allow iCloud Backup',
    'update_policies.settings.accordion.sync_backup.backup.description': 'This setting will be removed',
    'update_policies.settings.accordion.sync_backup.keychain.title': ' Allow iCloud Keychain',
    'update_policies.settings.accordion.sync_backup.keychain.description': 'This setting will be removed',
    'update_policies.settings.accordion.sync_backup.data.title': 'Allow Managed Apps to Store Data in iCloud',
    'update_policies.settings.accordion.sync_backup.data.description': 'Users can store Managed Apps data in iCloud',
    'update_policies.settings.accordion.sync_backup.enterprise_books.title': ' Allow corporate ledger backup',
    'update_policies.settings.accordion.sync_backup.enterprise_books.description': 'Users can back up books distributed by their organizations',
    'update_policies.settings.accordion.sync_backup.sync_books.title': 'Allow sync of notes and highlights for corporate books',
    'update_policies.settings.accordion.sync_backup.sync_books.description': 'Users can sync notes or highlights to other devices via iCloud',
    'update_policies.settings.accordion.sync_backup.photo.title': 'Allow iCloud Photos',
    'update_policies.settings.accordion.sync_backup.photo.description': 'Users can access iCloud Photos.',
    'update_policies.settings.accordion.sync_backup.photo_menu.title': 'Allow My Photo Stream (not allowing may cause data loss)',
    'update_policies.settings.accordion.sync_backup.photo_menu.description': 'Camera Roll photos are uploaded to My Stream and photos and videos in shared albums can no longer be viewed on the device.',
    'update_policies.settings.accordion.sync_backup.encrypted_backup.title': 'Force encrypted backups',
    'update_policies.settings.accordion.sync_backup.encrypted_backup.description': 'Users can choose whether device backups are stored in encrypted format. If a profile is encrypted and this option is disabled, encryption of backups is required and enforced',
    'update_policies.settings.accordion.sharing': 'Camera and Screen Sharing',
    'update_policies.settings.accordion.sharing.allow_remote_screen_observation.title': 'Allow AirPlay, View Screen by Classroom, and Screen Share',
    'update_policies.settings.accordion.sharing.allow_remote_screen_observation.description': 'Students in managed classes are warned when teacher uses AirPlay or View Screen',
    'update_policies.settings.accordion.sharing.allow_screen_shot.title': 'Allow screen capture and recording',
    'update_policies.settings.accordion.sharing.allow_screen_shot.description': 'Users can save a screenshot or recording',
    'update_policies.settings.accordion.sharing.allow_camera.title': 'Allow use of camera',
    'update_policies.settings.accordion.sharing.allow_camera.description': ' All device cameras are enabled',
    'update_policies.settings.accordion.restriction': 'Miscellaneous Restrictions',
    'update_policies.settings.accordion.restriction.force_limit_ad_tracking.title': 'Prevent apps from requesting tracking',
    'update_policies.settings.accordion.restriction.force_limit_ad_tracking.description': 'Location services with apps is blocked',
    'update_policies.settings.accordion.restriction.allow_apple_personalized_advertising.title': 'Allow personalized ads provided by Apple',
    'update_policies.settings.accordion.restriction.allow_apple_personalized_advertising.description': "Disabling personalized advertising will limit Apple's ability to serve you relevant content.",
    'update_policies.settings.accordion.restriction.allow_enterprise_app_trust.title': 'Allow new authors of trusted enterprise applications',
    'update_policies.settings.accordion.restriction.allow_enterprise_app_trust.description': 'This method is secure and the app is installed via the mobile device management portal',
    'update_policies.settings.accordion.restriction.allow_open_from_unmanaged_to_managed.title': 'Allow Documents from Managed Sources in Unmanaged Destinations',
    'update_policies.settings.accordion.restriction.allow_open_from_unmanaged_to_managed.description': 'Documents created or transferred from managed sources cannot be opened in unmanaged destinations',
    'update_policies.settings.accordion.restriction.force_air_drop_unmanaged.title': 'Treat AirDrop as unmanaged destination',
    'update_policies.settings.accordion.restriction.force_air_drop_unmanaged.description': '"Users see AirDrop as an option in a Managed App.  For this restriction to work when enabled, the option “Allow documents from managed sources in unmanaged destinations” will also be disabled"',
    'update_policies.settings.accordion.restriction.allow_diagnostic_submission.title': 'Allow sending diagnostic and usage data to Apple',
    'update_policies.settings.accordion.restriction.allow_diagnostic_submission.description': 'Activating allows Apple to share data and statistics about the use of these apps',
    'update_policies.settings.accordion.restriction.force_watch_wrist_detection.title': 'Force Apple Watch Wrist Detection',
    'update_policies.settings.accordion.restriction.force_watch_wrist_detection.description': 'When you take the watch off your wrist, it locks automatically to protect your data.',
    'update_policies.settings.accordion.restriction.allow_in_app_purchases.title': ' Allow in-app purchases',
    'update_policies.settings.accordion.restriction.allow_in_app_purchases.description': 'Allow users to make in-app purchases',
    'update_policies.settings.accordion.restriction.force_itunes_store_password_entry.title': 'Require iTunes Store password for all purchases',
    'update_policies.settings.accordion.restriction.force_itunes_store_password_entry.description': 'In-app purchases and iTunes Store purchases ask for account password',
    'update_policies.settings.accordion.assistant': 'Assistant',
    'update_policies.settings.accordion.assistant.allow_assistant.title': 'Allow Siri',
    'update_policies.settings.accordion.assistant.allow_assistant.description': 'Allows you to use Siri',
    'update_policies.settings.accordion.assistant.allow_assistant_while_locked.title': 'Allow Siri While Device Is Locked',
    'update_policies.settings.accordion.assistant.allow_assistant_while_locked.description': 'Siri responds even when the device is locked',
    'update_policies.settings.accordion.assistant.allow_spotlight_internet_results.title': 'Allow suggestions from Siri',
    'update_policies.settings.accordion.assistant.allow_spotlight_internet_results.description': 'During the search, Siri can offer suggestions for apps, people, places, and more.',

    // Antigo Master
    'register_policies.managed_mode': 'Fully managed',
    'register_policies.managed_mode.description': 'For company-owned devices. Enables management of a wide range of device settings and policy controls.',
    'manage_policies.filter.policy_type.dedicaded': 'Dedicated Devices',
    'manage_policies.filter.policy_type.managed': 'Fully managed',
    'devices.action.more_infos': 'Manage',

    // Novos
    'register_policies.android_block_sim': 'Android - Block SIM',
    'update_policies.settings.accordion.block_screen': 'Lock Screen',
    'register_policies.android_block_sim.description': 'Allows management of Android device settings and policy controls. Automatically adds the Block SIM application, restricts device password changes, and ensures the SIM is bonded to the device.',
    'update_policies.settings.accordion.show_control_center_while_locked.title': 'Show control center on lock screen',
    'update_policies.settings.accordion.show_control_center_while_locked.description': 'Users can access Control Center with screen locked',
    'update_policies.settings.accordion.show_notification_center_while_locked.title': 'Show notification center on lock screen',
    'update_policies.settings.accordion.show_notification_center_while_locked.description': ' Users can view notification history with screen locked',
    'update_policies.settings.accordion.allow_voice_dialing.title': 'Allow voice dialing while device is locked',
    'update_policies.settings.accordion.allow_voice_dialing.description': 'Users can use voice commands to make calls when the device is screen locked',
    'update_policies.settings.accordion.show_today_view_while_locked.title': 'Show today view on lock screen',
    'update_policies.settings.accordion.show_today_view_while_locked.description': ' Users can swipe down to view Notification Center via Today View on Lock Screen.',
    'update_policies.settings.accordion.allow_touch_id_face_id.title': 'Allow Touch ID / Face ID to unlock device',
    'update_policies.settings.accordion.allow_touch_id_face_id.description': 'Users can use biometric authentication to autofill app data',
    'device.modal.label': 'Select the new policy - {managedMode}',
    'update_policies.tab.sites': 'Websites',
    'update_policies.sites.block_all_title': 'Block All Websites',
    'update_policies.sites.block_all_subtitle': 'Here you can block access to all websites on your IOS.',
    'update_policies.sites.block_keyword_title': 'Blocked and Allowed URLs and Keywords',
    'update_policies.sites.block_keyword_subtitle': 'Here you can see all blocked websites and keywords',
    'update_policies.sites.block_category_title': 'Site Blocking by Category',
    'update_policies.sites.block_category_subtitle': 'Select the categories of websites that access will be blocked',
    'devices.operational_system': 'Operational system',
    'devices.value.active': 'Active',
    'devices.value.disabled': 'Disabled',
    'devices.value.deleted': 'Deleted',
    'devices.value.provisioning': 'Provisioning',
    'devices.value.await_enterprise_enroll': 'Waiting for Android Enterprise',
    'devices.infos.iccid': 'ICCID',
    'menu.settings.register_policy': 'IOS Registry Policy',
    'entity.policy.defaultName': 'Registration Policy',
    'error.iosPolicy.isInitial.delete': 'Removing policy from registration is not allowed.',
    'exception.setting.allowDeviceIOS': 'Setup for iOS is disabled',
    'policy.block_sim': 'Block SIM',
    'devices.action.remove_device': 'Remove Device',
    'manage_policies.filter.management_mode': 'Management Mode',
    'manage_policies.filter.management_mode.android': 'Android',
    'manage_policies.filter.management_mode.ios': 'IOS',
    'manage_policies.filter.management_mode.block_sim': 'Android - Block SIM',
    'companies_list.title': 'Companies ',
    'companies_list.description': 'Loren xx',
    'companies_list.filter.terms_of_usage': 'Acceptance Term',
    'companies_list.filter.placeholder': 'Select an option',
    'companies_list.filter.start_at': 'Initial date',
    'companies_list.filter.end_at': 'Final date',
    'companies_list.button.select_company': 'Access the Portal',
    'companies_list.alert.confirm': 'Access ',
    'companies_list.alert.text': 'Want to access the {companyName} ?',
    'header.settings.help_desk.option_change_company': 'Change Company',
    'global.unauthorized.message': 'User Does Not Have Privilege To Access This Information',
    'devices.action.remove_screen_block': 'Remove Lock Screen',
    'devices.alert.remove_screen_block.text': 'Do you really want to remove the screen lock from this device?',
    'companies_list.filter.title': 'Company creation date',
    'menu.zero_touch.manage_notes': 'Manage Invoices',
    'menu.zero_touch.manage': 'Manage Zero Touch',
    'manage_invoice.title': 'Manage Invoices',
    'manage_invoice.description': 'Here you can manage invoices and access details for registration in Zero Touch.',
    'manage_invoice.filter': 'Invoice import date',
    'manage_invoice.start_date': 'Initial date',
    'manage_invoice.end_date': 'Final date',
    'manage_invoice.import': 'Import Invoice',
    'manage_invoice.menu_action.details': ' Invoice Details',
    'manage_invoice.toaster.customer_email.success': 'Customer ID successfully registered. Access the email provided to validate the request',
    'invoice_details.warning_exit': 'There is information that has not been saved and will be discarded. Do you wish to continue?.',
    'invoice_details.title': 'Invoice Details',
    'invoice_details.description': 'On this screen, you can view the invoice details',
    'invoice_details.access_key': 'Access key',
    'invoice_details.filter.manufacturer': 'Manufacturer',
    'invoice_details.filter.model': 'Model',
    'invoice_details.filter.imei': 'IMEI',
    'invoice_details.filter.place_holder': 'Select an option',
    'invoice_details.button.save': 'Save',
    'invoice_details.button.register': 'Request Registration',
    'invoice.device_status.registered': 'Registered',
    'invoice.device_status.not_registered': 'Not registered',
    'invoice_details.info_update.success': 'Information successfully saved',
    'manage_invoice.toaster.success': 'Invoice successfully imported.',
    'modal_customer_id.customerId_title': 'Customer ID Registration',
    'modal_customer_id.customerId_description': 'To register the devices in Zero Touch, it is necessary to inform the Customer ID registered in the reseller {resellerName}.',
    'modal_customer_id.input.customerId.placeholder': 'Enter your Customer ID',
    'modal_customer_id.customerId.button': 'Access',
    'modal_customer_id.customerId.link_button': "Don't have a Customer ID?",
    'modal_customer_id.input.customerId': 'Customer ID',
    'modal_customer_id.customerEmail_title': 'Customer ID registration',
    'modal_customer_id.customerEmail_description': 'To register the devices in Zero Touch it is necessary to create the Customer ID. Indicate the e-mail to receive the instructions for registering your Customer ID.',
    'modal_customer_id.input.customerEmail': 'Email',
    'modal_customer_id.input.customerEmail.placeholder': 'Type your email',
    'modal_customer_id.customerEmail.button': 'Send Email',
    'modal_customer_id.customerEmail.link_button': 'Do you have a Customer ID?',
    'modal_customer_id.button.do_later': 'Report later',
    'invoice.register_zero_touch_success': 'Zero Touch Registration Request Sent',
    'modal_customer_id.error_message.bad_request': 'Information entered is incorrect.',
    'modal_customer_id.error_message.connection_error': 'Server communication failure Import IMEI',
    'modal_customer_id.customerEmail.valid_gmail': 'Enter a valid Gmail',
    'global.copy.click_to_copy': 'Click to copy',
    'invoice_details.column.description': 'Product Description',
    'company_info.accessCode': 'Access code',
    'dashboard.total_licenses': 'Total licenses',
    'update_policies.settings.accordion.hardware.outgoing_beam_disabled.title': 'Block sending data via NFC',
    'update_policies.settings.accordion.hardware.outgoing_beam_disabled.description': 'Sending Data via NFC is disabled',
    'update_policies.settings.accordion.safety.frp_admin_emails_enable.title': 'Enable Admin Email for FRP',
    'update_policies.settings.accordion.safety.frp_admin_emails_enable.description': 'Will require the indicated email to perform the factory reset',
    'update_policies.settings.accordion.safety.frp_admin_emails.title': 'Admin email for FRP',
    'update_policies.settings.accordion.safety.frp_admin_emails.description': 'Defines the email that will be requested when resetting the device',
    'global.valid_g_email': 'Enter a valid Gmail',
    'update_policies.settings.accordion.lockScreen.disable_all_features.title': 'Disable all settings',
    'update_policies.settings.accordion.lockScreen.disable_all_features.description': 'All screen lock customizations are disabled.',
    'update_policies.settings.accordion.vpn': 'VPN',
    'update_policies.settings.accordion.vpn.vpn.title': 'Block VPN Settings',
    'update_policies.settings.accordion.vpn.vpn.description': 'VPN configuration is disabled',
    'update_policies.settings.accordion.vpn.lockdown_enabled.title': 'Block connection without VPN',
    'update_policies.settings.accordion.vpn.lockdown_enabled.description': 'Set network traffic according to VPN configuration.',
    'update_policies.settings.accordion.vpn.application.title': 'VPN App',
    'update_policies.settings.accordion.vpn.application.description': 'loren xx',
    'update_policies.frpAdminEmails': 'Admin Email for FRP',
    'update_policies.settings.accordion.safety.modify_accounts_manager.title': 'Block account changes',
    'update_policies.settings.accordion.safety.modify_accounts_manager.description': 'The option to add or remove accounts is disabled.',
    'update_policies.settings.accordion.user': 'User',
    'update_policies.settings.accordion.user.skip_first_use_hints_enabled.title': 'Disable first use tips',
    'update_policies.settings.accordion.user.skip_first_use_hints_enabled.description': 'User tutorial and other introductory tips on first-launch apps is disabled.',
    'update_policies.settings.accordion.user.set_wallpaper_disabled.title': 'Disable change wallpaper',
    'update_policies.settings.accordion.user.set_wallpaper_disabled.description': 'Background Change is disabled.',
    'update_policies.settings.accordion.user.set_user_icon_disabled.title': 'Disable user icon change',
    'update_policies.settings.accordion.user.set_user_icon_disabled.description': 'User icon change is disabled.',
    'update_policies.settings.accordion.user.credentials_config_disabled.title': 'Disable user credentials configuration',
    'update_policies.settings.accordion.user.credentials_config_disabled.description': 'User credentials configuration is disabled.',
    'update_policies.minimumApiLevel': 'Android API Minimum Level',
    'update_policies.settings.accordion.network.sms_disabled.title': 'Disable sending and receiving SMS',
    'update_policies.settings.accordion.network.sms_disabled.description': 'Sending and receiving SMS messages is disabled.',
    'update_policies.settings.accordion.network.cell_broadcasts_config_disabled.title': 'Disable Cell Broadcast Settings',
    'update_policies.settings.accordion.network.cell_broadcasts_config_disabled.description': 'The cell broadcast setting is disabled.',
    'update_policies.systemUpdate.minimum_api_level.title': 'Android API minimum level',
    'update_policies.systemUpdate.minimum_api_level.description': 'Minimum Android API level allowed on the device.',
    'update_policies.settings.accordion.safety.default_permission_policy.title': 'Permission policy',
    'update_policies.settings.accordion.safety.default_permission_policy.description': 'Sets default permission policy for permission requests in apps.',
    'update_policies.settings.accordion.safety.default_permission_policy.select.prompt': 'Request permission',
    'update_policies.settings.accordion.safety.default_permission_policy.select.grant': 'Automatically allow',
    'update_policies.settings.accordion.safety.default_permission_policy.select.deny': 'Automatically deny',
    'update_policies.systemUpdate.auto_date_and_time_zone.defined_by_user': 'User Defined',
    'update_policies.systemUpdate.auto_date_and_time_zone.automatic': 'Automatic',
    'update_policies.systemUpdate.auto_date_and_time_zone.title': 'Automatically set date and time',
    'update_policies.systemUpdate.auto_date_and_time_zone.description': 'Indicates whether automatic time is required, which prevents the user from manually setting the date and time.',
    'update_policies.settings.accordion.safety.encryption_policy.title': 'Encryption policy',
    'update_policies.settings.accordion.safety.encryption_policy.description': 'Requeired encryption policy',
    'update_policies.settings.accordion.safety.encryption_policy.select.disabled': 'Disabled',
    'update_policies.settings.accordion.safety.encryption_policy.select.enabled_without_password': 'Activate without password',
    'update_policies.settings.accordion.safety.encryption_policy.select.enabled_with_password': 'Activate with password',
    'menu.manage_zero_touch_register': 'Zero Touch Register',
    'modal_import_invoice.title': 'Import Invoice',
    'modal_import_invoice.import_button': 'Import',
    'modal_import_invoice.input.label': 'Access Key',
    'modal_import_invoice.input.placeholder': 'Enter a access key',
    'modal_import_invoice.label.uploda_button': 'Import XML',
    'manage_zero_touch_register.title': 'Zero Touch Register',
    'manage_zero_touch_register.description': 'Here it is possible to manage the registration requests in Zero Touch through the "Import IMEI" function',
    'manage_zero_touch_register.error_message.bad_request': 'Unable to fetch records',
    'manage_zero_touch_register.column.company': 'Company',
    'manage_zero_touch_register.column.cnpj': 'Identification Number',
    'manage_zero_touch_register.column.invoice_number': 'Invoice Number',
    'manage_zero_touch_register.column.pending_devices': 'Pending items',
    'manage_invoice.error_message.invalid_federal_code': 'Unable to register the company, invalid Identification Number',
    'manage_invoice.error_message.fail_create_company': 'Error when trying to register the company in Zero Touch',
    'manage_invoice.error_message.fail_create_invoice': ' Error when trying to import invoice',
    'manage_invoice.error_message.fail_create_invoice_duplicate': 'Unable to register the company, duplicate invoice',
    'manage_invoice.error_message.fail_create_invoice_cnpj': "The sender's Identification Number must match the Identification Number entered in the company's registration",
    'manage_invoice.error_message.fail_create_invoice_cnpj_unauthorized': "The sender's Identification Number must match the Identification Number entered in the company's registration",
    'manage_invoice.error_message.fail_create_invoice_not_found': 'Invoice not found, review information and try again',
    'manage_invoice.error_message.unauthorized': 'Unprivileged user to access Import IMEI functionality',

    'manage_zero_touch_register.start_date': 'Initial date',
    'manage_zero_touch_register.end_date': 'Final date',
    'manage_zero_touch_register.zero_touch_pending': 'Zero Touch Pending',
    'manage_zero_touch_register.zero_touch_pending.yes': 'Yes',
    'manage_zero_touch_register.zero_touch_pending.all': 'All',
    'invoice_details.device_status.waiting': 'Waiting for approval',
    'invoice_details.device_status.reject': 'Rejected',
    'invoice_details.device_status.not_registered': 'Not registered',
    'invoice_details.device_status.disapprove': 'Zero Touch disapproved',
    'invoice_details.device_status.registered': 'Registered',
    'invoice_details.device_status.approved': 'Approved',
    'manage_zero_touch_register.menu_action.details': 'Details',
    'zero_touch_register_details.title': 'Details',
    'zero_touch_register_details.description': 'Here it is possible to manage the details of the registration requests in Zero Touch.',
    'zero_touch_register_details.error_message.bad_request': 'Unable to fetch invoice data',
    'zero_touch_register_details.company_name': 'Company name',
    'zero_touch_register_details.federal_code': 'Identification Number',
    'zero_touch_register_details.access_key': 'Key Access',
    'zero_touch_register_details.column.model': 'Model',
    'zero_touch_register_details.column.manufacturer': 'Manufacturer ',
    'zero_touch_register_details.column.imei': 'IMEI',
    'zero_touch_register_details.column.status': 'Status',
    'zero_touch_register_details.column.observation': 'Observation',
    'zero_touch_register_details.actions.approve': 'Approve',
    'zero_touch_register_details.actions.reject': 'Refuse',
    'invoice_details.tab.model_details': 'IMEI Model',
    'invoice_details.tab.devices_details': 'All the items',
    'invoice_details.model_details.title': 'Enter a IMEI Model',
    'invoice_details.model_details.description': 'It is necessary to inform an IMEI corresponding to each model so that we can continue with the validations and registration of the devices identified in the invoice',
    'invoice_details.model_details.button.submit': 'Submit',
    'invoice_details.model_details.input.imei': 'IMEI Model',
    'invoice_details.model_details.input.imei_error': 'Invalid IMEI',
    'invoice_details.model_details.input.description': 'Item description',
    'invoice_details.model_details.input.model_and_manufacturer': 'Model and Manufacturer',
    'invoice_details.model_details.input.model_and_manufacturer.placeholder': 'Enter a IMEI Model',
    'invoice_details.info_update.error': 'It was not possible to update the information, please check the filled fields and try again',
    'invoice_details.devices_details.view_observation': 'View observation',
    'invoice_details.devices_details.observation_modal.title': 'Product observation',
    'invoice_details.devices_details.observation_modal.empty_observation': 'No reason given',
    'invoice.error_message.fail_update_item': 'It was not possible to update the items, check the information filled in and try again.',
    'customer_id.error_message.fail_create_account': 'Error when trying to create account, please verify the information and try again.',
    'customer_id.error_message.invalid_email': 'Error executing action, Invalid email.',
    'customer_id.error_message.invalid_customer_id': 'The Customer ID entered is not valid, please check the Customer ID entered and that it is linked to this reseller.',
    'customer_id.error_message.requested_email': 'requested_email',
    'customer_id.error_message.company_not_found': 'Error executing action, company not found.',
    'zero_touch_register_details.alert.confirm_button.approve': 'Approve',
    'zero_touch_register_details.alert.message_alert.approve': 'Are you sure you want to approve all selected devices?',
    'zero_touch_register_details.alert.reject_button': 'Reject',
    'zero_touch_register_details.alert.message_alert.reject': 'Are you sure you want to reject all selected devices?',
    'zero_touch_register_details.filter.status_placeholder': 'Select a status',
    'update_policies.settings.accordion.lockScreen.maximum_time_to_lock.select.fifteen_seconds': '15 seconds',
    'update_policies.settings.accordion.lockScreen.maximum_time_to_lock.select.thirty_seconds': '30 seconds',
    'update_policies.settings.accordion.lockScreen.maximum_time_to_lock.select.one_minute': '1 minute',
    'update_policies.settings.accordion.lockScreen.maximum_time_to_lock.select.two_minutes': '2 minutes',
    'update_policies.settings.accordion.lockScreen.maximum_time_to_lock.select.five_minutes': '5 minutes',
    'update_policies.settings.accordion.lockScreen.maximum_time_to_lock.select.ten_minutes': '10 minutes',
    'update_policies.settings.accordion.lockScreen.maximum_time_to_lock.select.thirty_minutes': '30 minutes',
    'update_policies.settings.accordion.lockScreen.maximum_time_to_lock.select.nothing': 'None',
    'update_policies.settings.accordion.lockScreen.maximum_time_to_lock.title': 'Maximum time to lock the screen',
    'update_policies.settings.accordion.lockScreen.maximum_time_to_lock.description': 'Sets the maximum time for the user to perform an activity before the device is locked.',
    'update_policies.settings.accordion.user.permitted_input_methods.title': 'Policy for input methods',
    'update_policies.settings.accordion.user.permitted_input_methods.description': "If a package is defined, only input methods provided by packages are allowed. If you don't define a package, only system input methods are allowed.",
    'update_policies.settings.accordion.lock_screen': 'Lock Screen',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_camera.title': 'Disable lock screen camera',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_camera.description': 'The camera is disabled on the lock screen.',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_notifications.title': 'Disable lock screen notifications',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_notifications.description': 'Display of all notifications on secure keyguard screens are disabled.',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_unredacted_notifications.title': 'Disable edited notifications',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_unredacted_notifications.description': 'Unedited notifications on secure sandboxes are disabled.',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_trust_agents.title': 'Disable Trustee',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_trust_agents.description': 'Ignores the trust broker state on secure keyguard screens.',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_finger_print.title': 'Disable fingerprint sensor',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_finger_print.description': 'Fingerprint sensor in secure keyguard screens are disabled.',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_remote_input.title': 'Disable text editing in notifications',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_remote_input.description': 'On devices running Android 6 or earlier, disables text entry in notifications on secure keypad lock screens. It has no effect on Android 7 and newer.',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_face_auth.title': 'Disable face authentication',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_face_auth.description': 'Facial authentication on secure sandboxes is disabled.',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_iris_auth.title': 'Disable iris authentication',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_iris_auth.description': 'Iris authentication on secure keyguard screens is disabled.',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_biometric_auth.title': 'Disable biometric authentication',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_biometric_auth.description': 'Biometric authentication on secure keyguard screens is disabled.',

    'global.show_all': 'Show all',
    'global.show_less': 'Show less',
    'invoice_details.device_status.approved_zero_touch': 'Zero Touch Registered',
    'update_policies.settings.accordion.user.permitted_accessibility_services.title': 'Policy for accessibility services',
    'update_policies.settings.accordion.user.permitted_accessibility_services.description': "Defines the allowed accessibility services. If a package is defined, only the accessibility services in that list and the system's built-in accessibility service can be used. If you do not define a package, only the system's built-in accessibility services can be used.",
    'update_policies.settings.accordion.network.recommended_global_proxy.title': 'Proxy configuration',
    'update_policies.settings.accordion.network.recommended_global_proxy.description': 'Configuration of an HTTP proxy. For a "Manual Proxy", set the Host, Port, and Ignored Hosts fields. For an automatic proxy - PAC, set the PAC URL field.',
    'update_policies.settings.accordion.network.recommended_global_proxy.manual': 'Manual configuration',
    'update_policies.settings.accordion.network.recommended_global_proxy.automatic': 'Automatic Configuration - PAC',
    'update_policies.settings.accordion.network.recommended_global_proxy.pac_uri': 'PAC URL',
    'update_policies.maximumTimeToLock': 'Maximum Time to Turn Off the Screen',
    'update_policies.settings.accordion.network.recommended_global_proxy.host': 'Host',
    'update_policies.settings.accordion.network.recommended_global_proxy.port': 'Port',
    'update_policies.settings.accordion.network.recommended_global_proxy.ignored_hosts': 'List of Ignored Hosts',
    'update_policies.settings.accordion.lockScreen.stay_on_plugged_modes.title': 'Keep device always on',
    'update_policies.settings.accordion.lockScreen.stay_on_plugged_modes.description': 'Sets the connected battery mode in which the device will remain on. When using this setting, it is recommended that the "Maximum time to lock screen" setting has "None" selected so that the device does not lock while it remains on.',
    'update_policies.settings.accordion.lockScreen.stay_on_plugged_modes.select.ac': 'AC - AC Charger',
    'update_policies.settings.accordion.lockScreen.stay_on_plugged_modes.select.usb': 'USB - USB port',
    'update_policies.settings.accordion.lockScreen.stay_on_plugged_modes.select.wireless': 'WIRELESS ',
    'update_policies.settings.accordion.network.recommended_global_proxy.none': 'None',
    'update_policies.settings.accordion.user.account_types_with_management_disabled.title': 'Account types with management disabled',
    'update_policies.settings.accordion.user.account_types_with_management_disabled.description': 'Account types that cannot be managed by the user.',
    'update_policies.settings.accordion.user.account_types_with_management_disabled.placeholder': 'Select or add an option',
    'global.creatable.add_new_option': 'Add new option: {inputValue}',
    'update_policies.settings.accordion.safety.private_key_selection_enabled.title': 'Enable private key selection',
    'update_policies.settings.accordion.safety.private_key_selection_enabled.description': 'Lets you show the interface on the device for the user to choose a private key alias if no private key rule is defined. On devices prior to Android 9, the setting may make enterprise keys vulnerable.',
    'update_policies.settings.accordion.vpn.lockdown_enabled.placeholder': 'Select an app',
    'update_policies.settings.accordion.vpn.package_name.title': 'VPN Apps',
    'update_policies.settings.accordion.vpn.package_name.description': 'Configuration for an always-on VPN connection. Add the VPN app to the profile to see it listed.',
    'update_policies.alwaysOnVpnPackage.packageName': 'VPN Apps',
    'update_policies.permittedInputMethods': 'Policy for input methods',
    'update_policies.permittedAccessibilityServices': 'Policy for accessibility services',
    'update_policies.recommendedGlobalProxy.pacUri': 'Pac URL',
    'update_policies.permittedAccessibilityServices.arr': 'Policy for accessibility services',
    'update_policies.permittedInputMethods.arr': 'Policy for input methods',
    'input_keywords.error.key_existing_alert': 'Existing keyword.',
    'input_keywords.error.key_not_valid_alert': 'Keyword entered is not a valid value.',
    'update_policies.keyword_error.start_only_with_letters': 'Keyword can only start with letters',
    'update_policies.settings.accordion.safety.choose_private_key_rule.title': 'Private key choice rules',
    'update_policies.settings.accordion.safety.choose_private_key_rule.description': 'Identify the rule that allows reading the private key',
    'update_policies.settings.accordion.safety.choose_private_key_rule.add_button': '+ Add',
    'update_policies.settings.accordion.safety.choose_private_key_rule.add_modal.title': 'Add Private Key Choice Rule',
    'update_policies.settings.accordion.safety.choose_private_key_rule.add_modal.private_key_alias': 'Name (Alias)',
    'update_policies.settings.accordion.safety.choose_private_key_rule.add_modal.url_pattern': 'URLPattern',
    'update_policies.settings.accordion.safety.choose_private_key_rule.add_modal.save': 'Save',
    'update_policies.settings.accordion.safety.choose_private_key_rule.add_modal.package_names': 'APP',
    'update_policies.settings.accordion.safety.choose_private_key_rule.add_modal.add_package_name': 'Add',
    'update_policies.settings.accordion.safety.choose_private_key_rule.add_modal.same_alias_error': 'A rule with this name already exists',
    'update_policies.settings.accordion.safety.choose_private_key_rule.edit_button': 'Edit',
    'update_policies.settings.accordion.safety.choose_private_key_rule.delete_button': 'Delete',
    'update_policies.choosePrivateKeyRules.arr.urlPattern': 'URLPattern',
    'update_policies.choosePrivateKeyRules.arr.privateKeyAlias': 'Name (Alias)',
    'update_policies.choosePrivateKeyRules.arr': 'Private key choice rules',
    'update_policies.recommendedGlobalProxy.port': 'Port',
    'update_policies.recommendedGlobalProxy.host': 'Host',
    'modal_customer_id.customerId.subscribe_button_text': 'Click here to subscribe',
    'customer_id.error_message.fail_create_invoice_without_device': 'No mobile device was identified on the invoice. Check and try again.',
    'update_policies.settings.accordion.network.recommended_global_proxy.empty_list': 'No Proxy Added.',
    'update_policies.settings.accordion.user.permitted_input_methods.empty_list': 'No input methods added.',
    'update_policies.settings.accordion.user.permitted_accessibility_services.empty_list': 'No accessibility services added.',
    'modal_customer_id.customerToken_title': 'Bind Customer ID - Email Validation',
    'modal_customer_id.customerToken_description': 'Inform the Token sent to the email {email}',
    'modal_customer_id.customerToken.button': 'Generate Customer ID',
    'modal_customer_id.customerToken.link_button': 'Change email',
    'modal_customer_id.customerToken.secondary_button': 'Send email again',
    'modal_customer_id.input.customerToken': 'Token',
    'global.valid_email': 'Enter a valid email',
    'manage_invoice.error_message.server_error': 'Unable to connect to Import IMEI service',
    'manage_invoice.error_message.default_error': 'Unable to connect to Import IMEI service',
    'invoice_details.approve_modal.title': 'Are you sure you want to approve all selected devices?',
    'customer_id.error_message.invalid_token': 'Entered token is invalid, try again',
    'invoice_details.model_details.input.manufacturer': 'Manufacturer',
    'invoice_details.model_details.input.model': 'Model',
    'zero_touch_register_details.error_message.fail_device_invalid_imei': 'Entered IMEI is invalid',
    'zero_touch_register_details.error_message.fail_device_invalid_imei_model': 'Informed IMEI is different from Model IMEI',
    'zero_touch_register_details.error_message.fail_device_invalid_model': 'Model informed is invalid',
    'zero_touch_register_details.error_message.fail_device_duplicate_imei': 'Entered IMEI already exists',
    'manage_zero_touch_register.release_date': 'IMEI send date',
    'modal_import_invoice.error_message.invalid_xml': 'Invalid invoice file',
    'modal_import_invoice.error_message.invalid_format': 'It is necessary to import an .XML file',
    'modal_import_invoice.error_message.default': 'An error occurred when trying to import the invoice',
    'register_policies.ios_supervised': 'IOS - Supervised',
    'register_policies.ios_supervised.description': 'For company-owned devices. Allows managing settings and creating restrictions on iOS devices provisioned in Supervised mode.',
    'register_policies.ios_supervised.profile': 'Profile',
    'register_policies.ios_supervised.upload_button': 'Upload',
    'register_policies.ios_supervised.profile_input.placeholder': 'Upload a configuration file',
    'register_policies.ios_supervised.profile_input.error_message.invalid_format': 'You need to import a .mobileconfig file',
    'register_policies.ios_supervised.profile_input.error_message.invalid_mobileconfig': 'Invalid configuration file',
    'register_policies.ios_supervised.profile_input.error_message.default': 'An error occurred while trying to import configuration file',
    'register_policies.ios_supervised.profile_input.success': 'File uploaded successfully',
    'update_policies.tab.profile': 'Profile',
    'update_policies.file': 'Profile configuration file',
    'edit_policy.ios.profile.profile_file_input': 'Configuration file',
    'manage_policies.filter.management_mode.ios_supervised': 'IOS Supervised',
    'menu.settings.networks.list': 'Manage Wifi Networks',
    'manage_networks.entity': 'Wifi network',
    'manage_networks.title': 'Manage Wifi Networks',
    'manage_networks.description': 'Configure advanced wifi network settings',
    'zero_touch_register_details.success.approved': 'Device Enrolled in Zero Touch.',
    'zero_touch_register_details.success.refused': 'Device registration refused.',
    'network_settings_details.title': 'WiFi Setup Details',
    'network_settings_details.description': 'Configure the Wi-Fi network settings that need to be managed',
    'network_settings_details.name': 'Configuration name',
    'network_settings_details.ssid': 'SSID',
    'network_settings_details.passphrase': 'Password',
    'network_settings_details.auto_connect': 'Auto Connect',
    'network_settings_details.security': 'Security type',
    'network_settings_details.security.none': 'None',
    'network_settings_details.eap_inner': 'Inner Authentication',
    'network_settings_details.eap_outer': 'Outer Authentication',
    'network_settings_details.certificate_client.certificate': 'Client Certificate',
    'network_settings_details.certificate_server.certificate': 'Server Certificate',
    'network_settings_details.certificate_client.type': 'Client Certificate Type',
    'network_settings_details.select_placeholder': 'Select an option',
    'network_settings_details.certificate_server.type': 'Server Certificate Type',
    'network_settings_details.button.save': 'Save',
    'network_settings_details.error_message.invalid_format': 'Uploaded file is not of type {fileType}',
    'file_upload.message.success': 'File uploaded successfully!',
    'file_upload.message.error': 'Error trying to load the file!',
    'file_upload.button': 'Upload file',
    'file_upload.placeholder': 'Upload a file',
    'network_settings_details.success_message': 'Wi-Fi network settings have been successfully registered',
    'manage_networks.open_destroy_message.text_default': 'Are you sure you want to remove this item?',
    'manage_networks.open_destroy_message.network_settings_bound_text': 'This WiFi network setting is linked to policy, are you sure you want to remove this item? Important! Action cannot be undone.',
    'register_policies.operational_system.android': 'Android',
    'register_policies.operational_system.ios': 'IOS',
    'manage_zero_touch_register.zero_touch_pending.pendency': 'Pendencies',
    'manage_zero_touch_register.zero_touch_status': 'Status',
    'update_policies.settings.accordion.wifi': 'Wifi Settings',
    'update_policies.settings.accordion.wifi.description': 'Defines the configuration of the allowed Wi-Fi network, enabling the automatic connection of the device, always respecting the registration of the configuration. If there is no Wi-Fi network setting set, it will allow manual connection of the device to other Wi-Fi networks.',
    'update_policies.settings.accordion.wifi.empty_message': 'No Wi-Fi network linked',
    'zero_touch_register_details.error_message.fail_approve_device': 'Failed to approve devices',
    'register_policies.android_work_profile': 'Android - Work Profile',
    'register_policies.android_work_profile.description': 'For personally owned devices. It allows the creation and management of the work profile, preserving the privacy and security in the personal profile and guaranteeing the best performance.',
    'manage_policies.filter.management_mode.work_profile_android': 'Android - Work Profile',
    'devices.infos.operational_system': 'Operational System',
    'register_policies.work_profile_android': 'Android - Work Profile',
    'manage_admin.toaster.success.delete': 'Admin successfully removed',
    'update_policies.settings.accordion.password_policy.device': 'Password Restrictions - Device',
    'update_policies.settings.accordion.password_policy.work_profile': 'Password Restrictions - Job Profile',
    'update_policies.passwordPolicies.applied_device_settings.title': 'Allow the user to keep the same password defined for the “Device” in the “Work Profile” or choose a new password for the “Work Profile”.',
    'update_policies.passwordPolicy.deviceScope.passwordHistoryLength': 'Password history length',
    'update_policies.passwordPolicy.deviceScope.maximumFailedPasswordsForWipe': 'Maximum wrong passwords before wiping',
    'update_policies.passwordPolicy.deviceScope.passwordExpirationTimeout': 'Password expiration timeout (days)',
    'update_policies.passwordPolicy.deviceScope.requirePasswordUnlock': 'Require password unlock',
    'update_policies.passwordPolicy.deviceScope.passwordMinimumLength': 'Minimum password length',
    'update_policies.passwordPolicy.deviceScope.passwordMinimumLetters': 'Minimum number of letters required in password',
    'update_policies.passwordPolicy.deviceScope.passwordMinimumNonLetter': 'Minimum number of non-letter characters (numeric digits or symbols) required in the password',
    'update_policies.passwordPolicy.deviceScope.passwordMinimumNumeric': 'Minimum number of numeric digits required in password',
    'update_policies.passwordPolicy.deviceScope.passwordMinimumSymbols': 'Minimum number of symbols required in password',
    'update_policies.passwordPolicy.deviceScope.passwordMinimumUpperCase': 'Minimum number of capital letters required in password',
    'update_policies.passwordPolicy.deviceScope.passwordMinimumLowerCase': 'Minimum number of lowercase letters required in password',
    'update_policies.passwordPolicy.profileScope.passwordHistoryLength': 'Password history length',
    'update_policies.passwordPolicy.profileScope.maximumFailedPasswordsForWipe': 'Maximum wrong passwords before wiping',
    'update_policies.passwordPolicy.profileScope.passwordExpirationTimeout': 'Password expiration timeout (days)',
    'update_policies.passwordPolicy.profileScope.requirePasswordUnlock': 'Require password unlock',
    'update_policies.passwordPolicy.profileScope.passwordMinimumLength': 'Minimum password length',
    'update_policies.passwordPolicy.profileScope.passwordMinimumLetters': 'Minimum number of letters required in password',
    'update_policies.passwordPolicy.profileScope.passwordMinimumNonLetter': 'Minimum number of non-letter characters (numeric digits or symbols) required in the password',
    'update_policies.passwordPolicy.profileScope.passwordMinimumNumeric': 'Minimum number of numeric digits required in password',
    'update_policies.passwordPolicy.profileScope.passwordMinimumSymbols': 'Minimum number of symbols required in password',
    'update_policies.passwordPolicy.profileScope.passwordMinimumUpperCase': 'Minimum number of capital letters required in password',
    'update_policies.passwordPolicy.profileScope.passwordMinimumLowerCase': 'Minimum number of lowercase letters required in password',
    'edit_policy.kiosk.wallpaper.title': 'Wallpaper settings',
    'dropzone.drop_text': 'Drop the file here',
    'dropzone.or': 'or',
    'dropzone.button': 'Upload files',
    'edit_policy.kiosk.manage_configuration': 'Manage Configurations',
    'edit_policy.kiosk.kiosk_wallpaper': 'Wallpaper address',
    'edit_policy.kiosk.kiosk_wallpaper.errors.default': 'Unable to upload selected image',
    'edit_policy.kiosk.kiosk_wallpaper.errors.server_limit': 'Image size exceeds allowable limit',
    'dropzone.rejected_file': 'File format or quantity not allowed',
    'update_policies.applications.installation_type.kiosk.system_app': 'System',
    'update_policies.applications.table.column.show_in_kiosk_mode': 'Show in Kiosk',
    'update_policies.modal_enterprise_system_apps.title': 'System Apps',
    'update_policies.applications.menu_action_button.system': 'System',
    'update_policies.applications.menu_action_button.store': 'Play Store',
    'company_info.token': 'Email confirmation code',
    'update_policies.applications.table.origin.PLAYSTORE': 'PLAY STORE',
    'update_policies.applications.table.origin.SYSTEM': 'SYSTEM',
    'update_policies.applications.table.origin.WEB': 'WEB',
    'update_policies.applications.table.origin.PARTICULAR': 'PARTICULAR',
    'error.generic_error': 'Error making request, please try again.',
    'invoice_details.import_devices_imei.error': 'There are more IMEIs than devices identified on the invoice',
    'invoice_details.import_devices_imei.size.error': 'File size exceeded!',
    'invoice_details.import_devices_imei.success': "IMEI's imported successfully!",
    'invoice_details.model_details.upload_button': 'Import',
    'zero_touch.accept_terms.title': 'Terms of Responsability',
    'zero_touch.accept_terms.accept': 'Accept',
    'zero_touch.accept_terms.decline': 'Decline',
    'zero_touch.accept_terms.loading_message': 'Loading terms',
    'zero_touch.accept_terms.errors.get_description': 'Unable to load terms',
    'zero_touch.accept_terms.back': 'Back',
    'zero_touch.accept_terms.errors.get_is_accepted': 'Unable to verify acceptance of terms',
    'zero_touch.accept_terms.errors.reply': 'Unable to respond to terms',
    'error_boundary.title': 'Something went wrong',
    'error_boundary.description': 'An error occurred while processing your request, please try again.',
    'error_boundary.action.back': 'Back to top',
    'error_boundary.action.reload': 'Reload page',
    'register_policies.policy': 'Policy',
    'general_config.values.policyBoolean.true': 'Accepted',
    'general_config.values.policyBoolean.false': 'Declined',
    'device_location.address_error': 'Unable to obtain device location',
    'device_location.user': 'User',
    'table.action.empty_export_message': 'There is no information recorded to generate a report',
    'edit_group.report_title': 'Group Users',
    'general_config.form.cycle_start.title': 'Cycle Start',
    'invoice_details.import_devices_imei.divergent_information.error': 'There is conflicting information, it is necessary to check',
    'invoice_details.import_devices_imei.invalid_format.error': 'Invalid file format',
    'invoice_details.import_devices_imei.template_download': 'Download template',
    'general_config.form.sync_time.title': 'Information Synchronization',
    'manage_invoice.error_message.fail_invalid_cnpj_reseler': 'Unauthorized invoice issuer.',
    'edit_policy.kiosk.kiosk_wallpaper.errors.invalid_size': 'Image size exceeds allowable limit',
    'devices.advanced_filters': 'Advanced Filters',
    'edit_policy.kiosk.kiosk_icon_font_color': 'Select color',
    'company_info.modal.send_code_success': 'Code sent successfully.',
    'manage_admin.toaster.success.edit_with_email': 'Admin user successfully changed, an email was sent to the user with access instructions.',
    'login.modal.title.not_validated': 'Check your email!',
    'login.modal.not_validated': 'To ensure the security of your access, you must register a new password. The necessary instructions have been sent to your email. If the email is not correct, contact support.',
    'devices.policy': 'Policy',
    'invoice_details.import_devices_imei.generic.error': 'Error making request, please try again.',
    'update_policies.settings.accordion.remote_view': 'Remote Access',
    'update_policies.settings.accordion.remote_view.allow_remote_view.title': 'Enable remote access',
    'update_policies.settings.accordion.remote_view.allow_remote_view.description': 'La configuración de acceso remoto al dispositivo está habilitada',
    'update_policies.modal_enterprise_apps.device_filter': 'Device',
    'update_policies.modal_enterprise_apps.device_filter.placeholder': 'Select device',
    'manual.open_manual': 'Support Manual',
    'update_policies.modal_enterprise_apps.device_filter.tooltip': 'Related devices correspond to devices linked to the policy',
    'devices.action.remote_view': 'Remote Control',
    'remote_view.start_connection': 'Start connection',
    'remote_view.stop_connection': 'Close connection',
    'remote_view.title': 'Remote Access',
    'remote_view.description': 'On this screen you can connect to the device.',
    'remote_view.success': 'Connection made successfully!',
    'update_policies.settings.accordion.safety.modify_accounts_manager.select.none': '',
    'update_policies.settings.accordion.safety.modify_accounts_manager.select.google': '',
    'update_policies.settings.accordion.safety.modify_accounts_manager.select.all': '',
    'remote_view.error': 'An error occurred when trying to connect to the device.',
    'update_policies.applications.table.column.package_name': 'Package name',
    'enterprise_applications.column.package_name': 'Package name',
    'import.file.error.size': 'File size exceeded!',
    'import.file.error.invalid_format': 'Invalid file format!',
    'import.file.error.fail_read_file': 'The template file information is invalid, please review the file and try again!',
    'import.file.error.fail_to_process_file': 'There was a problem processing the file!',
    'manage_groups.success.import': 'Please wait for the information to import! The system will display a notification at the top of the screen when completed, where you can review the import result.',
    'manage_groups.error.import': 'The template file information is invalid, please review the file and try again!',
    'manage_groups.error.import.device_in_other_group': 'Device linked in another group',
    'manage_groups.error.import.number_not_found': 'Number does not exist in the company',
    'manage_groups.import_groups.button_title': 'Import',
    'manage_groups.template_model.button_title': 'Download Template',
    'manage_groups.menu.button_title': 'Register in batch',
    'batch_template_import.file_name': 'Import {entity} in batch',
    'batch_template_import.groups': 'Group',
    'batch_template_import.groups.columns.name': 'GROUP',
    'batch_template_import.groups.columns.device': 'DEVICE',
    'notification.batch_import.group.title': 'Batch group registration',
    'notification.list.import_button.url': 'Ver detalhes de erros',
    'notification.badge.not_read.title': 'Not read',
    'notification.import_list.title': 'Import:',
    'import_groups.column.group': 'Group',
    'import_groups.column.device': 'Device',
    'import_groups.column.error': 'Error',
    'modal_notification_details.title': 'Notification Details',
    'update_policies.settings.accordion.safety.modify_accounts_disabled.title': 'Block account changes',
    'update_policies.settings.accordion.safety.modify_accounts_disabled.description': 'The option to add or remove accounts is disabled.',
    'update_policies.settings.accordion.safety.account_types_with_management_disabled.title': 'Account types with management disabled',
    'update_policies.settings.accordion.safety.account_types_with_management_disabled.description': 'Account types that cannot be managed by the user.',
    'update_policies.settings.accordion.safety.account_types_with_management_disabled.placeholder': 'Select or add an option',
    'devices.filter.start_at': 'Registration Start Date',
    'devices.filter.end_at': 'Registration End Date',
    'update_policies.applications.menu_action_button.manual': 'Manual',
    'update_policies.modal_add_manual_system_apps.title': 'Add Applications Manually',
    'update_policies.modal_add_manual_system_apps.add_button': 'Add',
    'update_policies.modal_add_manual_system_apps.input_name': 'Name',
    'update_policies.modal_add_manual_system_apps.input_name.placeholder': 'Enter a name',
    'update_policies.modal_add_manual_system_apps.input_package_name': 'Package name',
    'update_policies.modal_add_manual_system_apps.input_package_name.placeholder': 'Enter a package name',
    'update_policies.modal_add_manual_system_apps.input_package_name.error': 'App has already been added. Review the information!',
    'update_policies.applications.table.origin.MANUAL': 'MANUAL',
    'update_policies.manual_applications.arr.package_name': 'Package name',
    'general_config.inactivity_time': 'Inactivity timeout',
    'general_config.inactivity_time.5min': '5 min',
    'general_config.inactivity_time.10min': '10 min',
    'general_config.inactivity_time.20min': '20 min',
    'general_config.inactivity_time.30min': '30 min',
    'inactivity_alert.after_redirect': 'Session expired!',
    'inactivity_alert.confirm': 'Confirm',
    'inactivity_alert.title': 'Your access will expire in {seconds} seconds!',
    'inactivity_alert.description': 'Confirm that you want to remain signed in',
    'update_policies.modal_enterprise_apps.manufacturer_filter': 'Manufacturer',
    'update_policies.modal_enterprise_apps.manufacturer_filter.placeholder': 'Select the manufacturer',
    'import.file.error.fail_empty_file': 'Imported file has no information!',
    'login_confirmation.info': 'To ensure the security of your access, you must confirm the following information. Fill in the 6-digit code that was sent to your email',
    'login_confirmation.error.invalid_code': 'Confirmation code entered is invalid, please try again',
    'login_confirmation.error.default': 'An error occurred while trying to make your request, please try again',
    'company_info.subtitle.abm': 'Apple Business Manager',
    'company_info.field.contentTokenABM': 'Content Token',
    'devices.infos.emailAppleBusinessManager': 'User email in Apple Business Manager (ABM)',
    'devices.infos.ios_error.invalid_email': "The email provided does not yet have a user registered in Apple Business Manager. Access your company's account in Apple Business Manager to register and then try again to link your email on this screen.",
    'company_info.abm.info': 'To obtain the "Content Token" access your company\'s Apple Business Manager (ABM) account.',
    'devices.infos.ios_error.not_found_content_token_abm': 'No Apple Business Manager (ABM) Token was found in your company registration.',
    'devices.alert.restart_device.text': 'Do you really want to reset this device?',
    'devices.alert.turn_off_screen.text': 'Do you really want to turn off the screen on this device?',
    'update_policies.manual_applications.arr.name': 'Application Name',
    'global.about': 'About',
    'modal_product_version.actual_version': 'Current Version: {productVersion} ({frontVersion}, {backVersion})',
    'devices.action.more_information': 'More information',
    'devices.action.device_information': 'Device information',
    'update_policies.settings.accordion.work': 'Work',
    'update_policies.settings.accordion.work.lock_apps_outside_working_hours.title': 'Block device outside working hours',
    'update_policies.settings.accordion.work.lock_apps_outside_working_hours.description': 'Full blocking of the device when outside working hours is activated.',
    'update_policies.settings.accordion.work.days_of_work.title': 'Work days',
    'update_policies.settings.accordion.work.days_of_work.description': 'Defines the working days of the week',
    'update_policies.settings.accordion.work.start_day_of_work': 'Start day',
    'update_policies.settings.accordion.work.end_day_of_work': 'End day',
    'update_policies.settings.accordion.work.work_time.title': 'Working hours',
    'update_policies.settings.accordion.work.work_time.description': 'Sets working hours',
    'update_policies.settings.accordion.work.work_time.tooltip': 'When leaving the fields blank, the system considers the entire period of the day.',
    'update_policies.settings.accordion.work.start_working_time': 'Start time',
    'update_policies.settings.accordion.work.work_time.placeholder': 'Select',
    'update_policies.settings.accordion.work.end_working_time': 'End time',
    'devices.infos.details': 'Details',
    'devices.infos.install': 'Installation',
    'devices.infos.hardware': 'Hardware',
    'menu.enterprise_applications.external_apps': 'Manage External Applications',
    'external_apps.title': 'Manage External Applications',
    'external_apps.description': 'Lorem Ipsum',
    'external_apps.start_date': 'Creation Date Start',
    'external_apps.end_date': 'Creation Date End',
    'external_apps.new': 'New Application',
    'external_apps.entity': 'Application',
    'external_app_edit.create_title': 'New Application',
    'external_app_edit.edit_title': 'Edit Application',
    'external_app_edit.create_description': 'On this screen you can send a new application to the devices.',
    'external_app_edit.edit_description': 'On this screen you can edit an application',
    'external_app_edit.create_send_label': 'To save',
    'external_app_edit.edit_send_label': 'To update',
    'external_app_edit.input.url': 'Download URL',
    'external_app_edit.invalid_url': 'Enter a valid url',
    'confirm_welcome_email.title': 'Welcome!',
    'confirm_welcome_email.description': "Please confirm all company information and fill in the administrator's email address, where the welcome email will be sent. Once sent, this action cannot be performed again.",
    'confirm_welcome_email.corporate_reason': 'Corporate name',
    'confirm_welcome_email.externalCode': 'CNPJ',
    'confirm_welcome_email.contact': 'Contact',
    'confirm_welcome_email.phone': 'Contact Phone',
    'confirm_welcome_email.admin_email': 'Company administrator email',
    'confirm_welcome_email.confirm': 'Send Welcome Email',
    'confirm_welcome_email.error.invalid_email': 'Enter a valid email',
    'edit_policy.kiosk.telephone_services': 'Additional Telephony Services',
    'app_managed_configurations.add_bundle_array_button': 'Add configuration',
    'app_managed_configurations.remove_bundle_array_button': 'Remove configuration',
    'devices.batch_devices_actions.button': 'Batch actions',
    'devices.modal_batch_devices_actions.title': 'Batch actions',
    'devices.modal_batch_devices_actions.actions': 'Commands',
    'devices.modal_batch_devices_actions.action.disable': 'Disable device',
    'devices.modal_batch_devices_actions.action.enable': 'Activate device',
    'devices.modal_batch_devices_actions.action.lock': 'Turn off screen',
    'devices.modal_batch_devices_actions.action.reboot': 'Restart device',
    'devices.modal_batch_devices_actions.action.wipe': 'Remove device (Wipe)',
    'devices.modal_batch_devices_actions.alert.disable': 'Do you really want to disable the selected devices?',
    'devices.modal_batch_devices_actions.alert.enable': 'Do you really want to activate the selected devices?',
    'devices.modal_batch_devices_actions.alert.lock': 'Do you really want to turn off the screen on selected devices?',
    'devices.modal_batch_devices_actions.alert.reboot': 'Do you really want to restart the selected devices?',
    'devices.modal_batch_devices_actions.alert.wipe': 'Do you really want to remove all selected devices?',
    'devices.modal_batch_devices_actions.alert.default': 'Do you really want to send this command to all selected devices?',
    'devices.modal_batch_devices_actions.alert.irreversible': 'This action cannot be undone.',
    'devices.alert.batch_in_process': 'You already have a Batch Action Request in progress! Please wait for the process to complete before requesting another action to be sent.',
    'devices.alert.default': 'An error occurred while processing your request. Please try again later.',
    'notification.command.title': 'Command',
    'notification.list.command.url': 'Command sent successfully!',
    'notification.list.command.error': 'Error sending command',
    'general_config.two_factor_authentication': 'Two-factor authentication',
    'general_config.two_factor_authentication.tooltip': "Before activating this setting, make sure that all users are registered with a valid email address. After activating the configuration, to perform two-factor authentication and access the Portal, the user will need to enter a confirmation code that will be sent to the user's email whenever they try to access the Portal.",
    'update_policies.applications.table.blockTime': 'Operating Restrictions',
    'update_policies.applications.blockTimeModal.title': 'Operating Restrictions',
    'update_policies.applications.blockTimeModal.description': "Define the app's operating period during the days of the week, or activate the “Restricted” option to block operation all day",
    'update_policies.applications.blockTimeModal.dayOfWeek.0': 'Monday',
    'update_policies.applications.blockTimeModal.dayOfWeek.1': 'Tuesday',
    'update_policies.applications.blockTimeModal.dayOfWeek.2': 'Wednesday',
    'update_policies.applications.blockTimeModal.dayOfWeek.3': 'Thursday',
    'update_policies.applications.blockTimeModal.dayOfWeek.4': 'Friday',
    'update_policies.applications.blockTimeModal.dayOfWeek.5': 'Saturday',
    'update_policies.applications.blockTimeModal.dayOfWeek.6': 'Sunday',
    'update_policies.applications.blockTimeModal.table.header.dayOfWeek': 'Day of the week',
    'update_policies.applications.blockTimeModal.table.header.block': 'Restrict',
    'update_policies.applications.blockTimeModal.table.header.blockTime': 'Opening hours',
    'update_policies.applications.blockTimeModal.enableBlockTime': 'Enable restrictions',
    'update_policies.applications.blockTimeModal.confirm': 'Confirm',
    'update_policies.applications.blockTimeModal.cancel': 'Cancel',
    'edit_policy.kiosk.telephone_services.active': 'Active',
    'edit_policy.kiosk.telephone_services.user_defined': 'Defined by Device',
    'menu.companies': 'Companies',
    'menu.companies.manage_companies': 'Manage Companies',
    'menu.companies.register': 'Register New Company',
    'manage_companies.title': 'Manage Companies',
    'manage_companies.company': 'Company',
    'manage_companies.description': 'On this screen you can manage companies.',
    'manage_companies.button.new': 'New Company',
    'manage_companies.action_button.resend_email': 'Resend Welcome Email',
    'manage_companies.filter.created_at': 'Creation date',
    'manage_companies.filter.start_at': 'Start date',
    'manage_companies.filter.end_at': 'Final date',
    'manage_companies.filter.status': 'Status',
    'edit_policy.app_config.integer_limit_error': 'Please only enter numbers between {min} and {max}',
    'edit_company.edit.title': 'Edit company',
    'edit_company.edit.description': 'On this screen you can edit company.',
    'edit_company.register.title': 'Register New Company',
    'edit_company.register.description': 'On this screen you can register a company.',
    'edit_company.corporate_name': 'Corporate reason',
    'edit_company.federal_code': 'Identification number',
    'edit_company.email': 'Email',
    'edit_company.number_of_licenses': 'Licenses',
    'edit_company.plan': 'Flat',
    'edit_company.contact': 'Contact',
    'edit_company.phone_number': 'Telephone',
    'edit_company.zip_code': 'Zip code',
    'edit_company.address': 'Address',
    'edit_company.city': 'City',
    'edit_company.state': 'state',
    'edit_company.edit.alert_update.text': 'Are you sure you want to update company {name}?',
    'edit_company.edit.alert_email_update.text': 'Are you sure you want to update the company {name} and send the Welcome email to {email}?',
    'edit_company.register.alert.text': 'Are you sure you want to register the company {name} and send the Welcome email to {email}?',
    'manage_companies.action_button.cancel_company': 'Cancel Company',
    'manage_companies.alert.cancel_company_text': 'Are you sure you want to cancel {name} company? Upon confirmation, all licenses will be deleted!',
    'manage_companies.alert.resend_company_email_text': 'Please confirm that the welcome email from the company {name} has been forwarded to {email}?',
    'manage_companies.alert.resend_company_email.success_message': 'Welcome email sent successfully!',
    'edit_policy.app_config.max_characters': 'The maximum character size allowed is {maxCharacters}.',
    'devices.infos.permissions': 'Permissions',
    'devices.infos.usage_data_access': 'Access to Usage Data',
    'devices.infos.skip_battery_optimization': 'Skip Battery Optimization',
    'devices.infos.writing_system_settings': 'Writing System Settings',
    'devices.infos.sms_reading': 'SMS reading',
    'plan.plane_name': 'Flat',
    'update_policies.start_working_time': 'Work start time',
    'update_policies.end_working_time': 'Work schedule',
    'enterprise_applications.error.there_are_policies_with_app.confirm_button': 'Confirm',
    'global.plan_alert_route': 'You cannot use this feature as it is not part of your plan! Upgrade your plan to take advantage of this functionality!',
    'edit_policy.app_config.plan_max_keywords': 'You have reached the limit for your plan! Upgrade your plan to add unlimited URLs and keywords!',
    'edit_policy.app_config.plan_max_website_categories': 'You have reached the limit for your plan! Upgrade your plan to activate unlimited categories!',
    'update_policies.max_block_website_categories': 'Blocking Sites by Category',
    'update_policies.max_block_keywords': 'Blocked and Released URLs and Keywords',
    'dashboard.roaming_disabled': 'You cannot use this feature as it is not part of your plan! Upgrade your plan to take advantage of this functionality!',
    'register_policies.android_block_sim.tooltip': 'You cannot use this feature as it is not part of your plan! Upgrade your plan to take advantage of this functionality!',
    'edit_policy.kiosk.manage_configuration.plan_alert': 'You cannot use this feature as it is not part of your plan! Upgrade your plan to take advantage of this functionality!',
    'manage_policies.android_block_sim.tooltip': 'You cannot use this feature as it is not part of your plan! Upgrade your plan to take advantage of this functionality!',
    'manage_policies.table_actions.edit_policy': 'Edit Policy',
    'manage_policies.modal_enrollmentToken.enable_system_apps': 'Enable System Applications',
    'manage_policies.modal_enrollmentToken.success_enable_system_apps': 'QRCode updated successfully!',
    'update_policies.allow_unlimited_kiosk': 'Kiosk Mode managed settings',
    'register_policies.ios_supervised.tooltip': 'You cannot use this feature as it is not part of your plan! Upgrade your plan to take advantage of this functionality!',
    'global.plan_warning': 'You cannot use this feature as it is not part of your plan! Upgrade your plan to take advantage of this functionality!',
    'devices.infos.ios_error.email_already_in_use': 'The email provided is linked to another device! Change your email and try again',
    'menu.enterprise_applications.remote_apps': 'Remote Application Installation',
    'remoteApp.title': 'Remote Application Installation',
    'remoteApp.description': 'On this screen you can manage and send the remote installation of applications to the devices.',
    'remoteApp.start_date': 'Initial date',
    'remoteApp.end_date': 'Final date',
    'remoteApp.new': 'New Installation',
    'remoteApp.column.date': 'Shipping Date',
    'remoteApp.column.name': 'App Name',
    'remoteApp.column.packageName': 'Package name',
    'remoteApp.column.url': 'Download URL',
    'remoteApp.entity': 'Installation',
    'remoteApp.toaster_success': 'Remote installation created successfully!',
    'remoteApps.title': 'Application Submission Details',
    'remoteApps.description': 'On this screen you can manage and send the remote installation of applications to the devices.',
    'remoteApps.subtitle': 'Recipients',
    'remoteApps.name': 'App Name',
    'remoteApps.packageName': 'App Package',
    'remoteApps.url': 'Download URL',
    'remote_app_edit.title': 'Install Application',
    'remote_app_edit.description': 'From this screen it is possible to send the remote installation of an application to the devices.',
    'remote_app_edit.send_label': 'To send',
    'remote_app_edit.filter_title': 'Submit Application',
    'remote_app_edit.input.name': 'Name',
    'remote_app_edit.input.package_name': 'Package name',
    'remote_app_edit.input.url': 'Download URL',
    'remote_app_edit.input.recipient': 'Recipient',
    'company_info.subtitle.google_workspace': 'Google Workspace',
    'company_info.google_workspace.client_id': 'After registering an Administrator Role in your Workspace environment with our service account, enter your Customer ID here to integrate with your ChromeOS devices',
    'company_info.field.workspaceClientId': 'Workspace Client ID',
    'register_policies.operational_system.chrome_os': 'ChromeOS',
    'register_policies.chrome_os': 'ChromeOS',
    'register_policies.chrome_os.description': 'For company-owned devices. Enables management of device settings and policy controls.',
    'update_policies.chrome_os.tab.kiosk': 'Kiosk Mode',
    'update_policies.chrome_os.events_accordion.title': 'Activation events',
    'update_policies.chrome_os.events_accordion.description': 'Event configuration for activating kiosk mode on devices.',
    'update_policies.chrome_os.events_accordion.originUnits': 'Organizational Units that own the devices',
    'update_policies.chrome_os.events_accordion.destinationUnit': 'Target Organizational Unit that has Kiosk Mode',
    'update_policies.settings.accordion.safety.usb_data_access.title': 'USB transfer settings',
    'update_policies.settings.accordion.safety.usb_data_access.description': 'Defines which files and/or data can be transferred via USB. Does not affect charging functions. The “Do not allow transferring all types of data” option is only supported on devices running Android 12 or newer and USB HAL 1.3 or newer.',
    'update_policies.settings.accordion.safety.usb_data_access.select.allow_usb_data_transfer': 'Allow to transfer all types of data',
    'update_policies.settings.accordion.safety.usb_data_access.select.disallow_usb_file_transfer': 'Do not allow transferring files',
    'update_policies.settings.accordion.safety.usb_data_access.select.disallow_usb_data_transfer': 'Do not allow transferring all types of data',
    'update_policies.settings.accordion.network.configure_wifi.title': 'Wi-Fi Configuration Privileges',
    'update_policies.settings.accordion.network.configure_wifi.description': 'Defines Wi-Fi configuration privileges. Based on the option defined, the user will have full or limited control or no control over the configuration of Wi-Fi networks. The "Do not allow adding Wi-Fi configuration” option is only compatible with the Android 13 and newer versions.',
    'update_policies.settings.accordion.network.configure_wifi.select.allow_configuring_wifi': 'Allow Wi-Fi configuration',
    'update_policies.settings.accordion.network.configure_wifi.select.disallow_add_wifi_config': 'Do not allow adding Wi-Fi configuration',
    'update_policies.settings.accordion.network.configure_wifi.select.disallow_configuring_wifi': 'Do not allow Wi-Fi configuration',
    'update_policies.settings.accordion.hardware.wifi_direct_settings.title': 'Wi-Fi Direct Settings',
    'update_policies.settings.accordion.hardware.wifi_direct_settings.description': 'Configures the configuration and use of Wi-Fi Direct settings. The “Do not allow using Wi-Fi Direct” option is only compatible with Android 13 and newer versions.',
    'update_policies.settings.accordion.hardware.wifi_direct_settings.select.allow_wifi_direct': 'Allow to use Wi-Fi Direct',
    'update_policies.settings.accordion.hardware.wifi_direct_settings.select.disallow_wifi_direct': 'Do not allow using Wi-Fi Direct',
    'update_policies.settings.accordion.hardware.tethering_settings.title': 'Tethering Settings',
    'update_policies.settings.accordion.hardware.tethering_settings.description': 'Configures tethering settings. The “Do not allow Wi-Fi tethering” option is only supported on Android 13 and higher.',
    'update_policies.settings.accordion.hardware.tethering_settings.select.allow_all_tethering': 'Allow all forms of tethering',
    'update_policies.settings.accordion.hardware.tethering_settings.select.disallow_wifi_tethering': 'Do not allow Wi-Fi tethering',
    'update_policies.settings.accordion.hardware.tethering_settings.select.disallow_all_tethering': 'Disallow all forms of tethering',
    'update_policies.chrome_os.events_accordion.google_alert': 'This change will be implemented by Google and may take up to 24 hours to take effect.',
    'update_policies.chrome_os.events_accordion.start_event': 'Start',
    'update_policies.chrome_os.events_accordion.end_event': 'Conclude',
    'update_policies.chrome_os.events_accordion.success_on_execute': 'Event executed successfully!',
    'edit_policy.kiosk.manage_event_restrictions.title': '',
    'edit_policy.kiosk.manage_event_restrictions.description': '',
    'edit_policy.kiosk.manage_event_restrictions.list': '',
    'edit_policy.kiosk.manage_event_restrictions.column.date': '',
    'edit_policy.kiosk.manage_event_restrictions.column.hour': '',
    'edit_policy.kiosk.manage_event_restrictions.column.application': '',
    'edit_policy.kiosk.manage_event_restrictions.action_button.edit': '',
    'edit_policy.kiosk.manage_event_restrictions.action_button.delete': '',
    'edit_policy.kiosk.manage_event_restrictions.button': '',
    'edit_policy.kiosk.modal_event_restrictions.title': '',
    'edit_policy.kiosk.modal_event_restrictions.edit_title': '',
    'edit_policy.kiosk.modal_event_restrictions.button.confirm': '',
    'edit_policy.kiosk.modal_event_restrictions.button.edit': '',
    'edit_policy.kiosk.modal_event_restrictions.button.cancel': '',
    'edit_policy.kiosk.modal_event_restrictions.input.date': '',
    'edit_policy.kiosk.modal_event_restrictions.input.start_hour': '',
    'edit_policy.kiosk.modal_event_restrictions.input.end_hour': '',
    'edit_policy.kiosk.modal_event_restrictions.select.application': '',
    'edit_policy.kiosk.modal_event_restrictions.error': '',
    'register_policies.register_policy.error_message.client_id_not_found': '',
    'company_info.error_message.invalid_workspace_client_id': '',
    'devices.action.policy.plan_tooltip': '',
    'update_policies.lock_apps_outside_working_hours': 'Block device outside working hours',
    'update_policies.app_restriction_schedules': '',
    'update_policies.block_keywords': 'Blocked and Released URLs and Keywords',
    'plan_compliance.manage_applications.title': '',
    'plan_compliance.manage_applications.description': '',
    'plan_compliance.manage_applications.table.name': '',
    'plan_compliance.manage_applications.table.origin': '',
    'plan_compliance.manage_applications.table.package_name': '',
    'plan_compliance.manage_applications.select_apps_button': '',
    'manage_policy_update.title': '',
    'manage_policy_update.description': '',
    'manage_policy_update.column.name': '',
    'manage_policy_update.column.management_mode': '',
    'manage_policy_update.column.kiosk': '',
    'manage_policy_update.button.update_policy': '',
    'manage_policy_update.alert.text': '',
    'manage_policy_update.alert.success': '',
    'manage_policy_update.kiosk_limited_description': '',
    'manage_policies.modal_enrollmentToken.manage_networks': '',
    'manage_policies.modal_enrollmentToken.provisioning_skip_encryption': '',
    'manage_policies.modal_enrollmentToken.provisioning_use_mobile_data': '',
    'manage_policies.modal_enrollmentToken.toast_success': '',
    'manage_policies.modal_enrollmentToken.toast_error': '',
    'global.save': '',
    'login.modal.title.compliance_max_apps': '',
    'login.modal.compliance_max_apps': '',
    'update_policies.block_website_categories': 'Blocking Sites by Category',
    'guides.is_empty': '',
    'menu.ios_configs': '',
    'ios_config.title': '',
    'ios_config.description': '',
    'ios_config.apns.title': '',
    'ios_config.apns.create_button': '',
    'ios_config.apns.config_modal.title': '',
    'ios_config.apns.config_modal.steps.description': '',
    'ios_config.apns.config_modal.step': '',
    'ios_config.apns.config_modal.steps.1': '',
    'ios_config.apns.config_modal.steps.2': '',
    'ios_config.apns.config_modal.steps.3': '',
    'ios_config.apns.config_modal.steps.4': '',
    'ios_config.apns.config_modal.steps.5': '',
    'ios_config.apns.config_modal.form.title': '',
    'ios_config.apns.config_modal.form.certificate_download': '',
    'ios_config.apns.config_modal.form.download_button': '',
    'ios_config.apns.config_modal.form.certificate': '',
    'ios_config.apns.config_modal.form.password': '',
    'ios_config.apns.config_modal.form.apple_id': '',
    'ios_config.apns.config_modal.save': '',
    'ios_config.apns.config_modal.errors.invalid_type': '',
    'ios_config.apns.status': '',
    'ios_config.apns.topic': '',
    'ios_config.apns.serial_number': '',
    'ios_config.apns.inclusion_date': '',
    'ios_config.apns.expiration_date': '',
    'ios_config.apns.status.active': '',
    'ios_config.apns.status.inactive': '',
    'ios_config.apns.update_button': '',
    'ios_config.apns.config_modal.update_warning': '',
    'ios_config.apns.config_modal.update.title': '',
    'ios_config.apns.config_modal.update.steps.description': '',
    'ios_config.apns.config_modal.update.steps.1': '',
    'ios_config.apns.config_modal.update.steps.2': '',
    'ios_config.apns.config_modal.update.steps.3': '',
    'ios_config.apns.config_modal.update.steps.4': '',
    'ios_config.apns.config_modal.update.steps.5': '',
    'dashboard.devices_location.fetch_devices_location': '',
    'plan_compliance.manage_applications.confirmation': '',
  },
};

export default i18nConfig;
