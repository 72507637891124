import { Grid, GridItem, Flex, Box } from '@chakra-ui/react';
import React, { ReactNode, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import Alert, { AlertHtml } from '../../components/Alert';
import LayoutHeader from '../../components/LayoutHeader';
import ManageSession from '../../components/ManageSession';
import Menu from '../../components/Menu';
import { usePlan } from '../../hooks/usePlan';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import routes from '../../routes';
import { uiScrollToTopClear } from '../../store/ui';

interface LayoutProps {
  children: ReactNode;
}

const LayoutInternal = ({ children }: LayoutProps) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { scrollToTop } = useAppSelector((state) => state.ui);
  const { isLogged } = useAppSelector((state) => state.auth);
  const gridItemRef = useRef(null);
  const location = useLocation();
  const { handleCheckFeaturePlanByRoute } = usePlan();

  const isUnauthenticatedRoute =
    routes.login === location?.pathname || !isLogged;

  useEffect(() => {
    if (scrollToTop) {
      handleScrollToTop();
    }
  }, [scrollToTop]);

  useEffect(() => {
    const hasFeaturePlan = handleCheckFeaturePlanByRoute(location.pathname);

    if (!hasFeaturePlan) {
      handleOpenPlanAlert();
    }

    return () => {
      if (!hasFeaturePlan) {
        Swal.close();
      }
    };
  }, []);

  function handleOpenPlanAlert() {
    Alert({
      onConfirm: () => history.goBack(),
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      disabledCancel: true,
      customClass: {
        container: 'swal2-container-plan-alert',
      },
      html: (
        <AlertHtml
          text={intl.formatMessage({ id: 'global.plan_alert_route' })}
        />
      ),
    });
  }

  function handleScrollToTop() {
    if (gridItemRef) {
      gridItemRef.current?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      return dispatch(uiScrollToTopClear());
    }
  }

  return (
    <Flex flexDirection="row" position="relative" overflow="hidden">
      <Menu />
      <Grid
        w="100%"
        templateAreas="'appBar' 'content'"
        templateColumns="repeat(auto-fit, minmax(65px, 1fr))"
        zIndex={0}
        bg="gray.400"
      >
        <GridItem
          gridArea="appBar"
          bg="white"
          borderColor="gray.600"
          borderBottomWidth="1px"
          borderLeftWidth="1px"
          w="inherit"
          position="fixed"
          zIndex={5}
        >
          <LayoutHeader />
        </GridItem>
        <GridItem
          ref={gridItemRef}
          gridArea="content"
          mt="80px"
          overflowY="auto"
          height="calc(100vh - 80px)"
        >
          <Box m="3% 4% 2% 4%">{children}</Box>
        </GridItem>
      </Grid>

      {!isUnauthenticatedRoute && <ManageSession />}
    </Flex>
  );
};

export default LayoutInternal;
