import { useIntl } from 'react-intl';

export enum changeOptionsSelectEnum {
  LOCALE = 'locale',
  TIMEZONE = 'timeZone',
}

const changeOptionsSelect = (name) => {
  const intl = useIntl();
  if (name === changeOptionsSelectEnum.LOCALE) {
    return [
      {
        id: 'byData',
        label: intl.formatMessage({
          id: 'geolocation.byData',
        }),
      },
      {
        id: 'now',
        label: intl.formatMessage({
          id: 'geolocation.now',
        }),
      },
    ];
  }

  if (name === changeOptionsSelectEnum.TIMEZONE) {
    return [
      {
        id: 'user',
        label: intl.formatMessage({
          id: 'geolocation.currentUser',
        }),
      },
      {
        id: 'device',
        label: intl.formatMessage({
          id: 'geolocation.device',
        }),
      },
    ];
  }

  return [
    {
      id: '',
      label: intl.formatMessage({
        id: 'geolocation.all',
      }),
    },
    {
      id: 30,
      label: '30m',
    },
    {
      id: 50,
      label: '50m',
    },
    {
      id: 100,
      label: '100m',
    },
    {
      id: 200,
      label: '200m',
    },
    {
      id: 300,
      label: '300m',
    },
    {
      id: 400,
      label: '400m',
    },
    {
      id: 500,
      label: '500m',
    },
    {
      id: 600,
      label: '600m',
    },
    {
      id: 700,
      label: '700m',
    },
    {
      id: 800,
      label: '800m',
    },
    {
      id: 900,
      label: '900m',
    },
    {
      id: 1000,
      label: '1000m',
    },
  ];
};

export default changeOptionsSelect;
