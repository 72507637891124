import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { formatNumberWithDecimals } from '../../../../helper/number';
import ChartLoading from '../../../ChartLoading';
import PieChart, { DataDonut } from '../../../ChartPie';
import ChartTooltip from '../../../ChartTooltip';
import NotificationBadgeIcon from '../../../Icons/NotificationBadge';

export enum CHART_ID {
  USED = 'used',
  TOTAL = 'total',
}

interface ConsumptionChartProps {
  colorsToUse: string[];
  consumption: {
    used?: number;
    limit?: number;
  };
  isLoading: boolean;
  usedFormatted?: string;
}

const ConsumptionChart = ({
  consumption,
  colorsToUse,
  isLoading,
  usedFormatted,
}: ConsumptionChartProps) => {
  const intl = useIntl();

  const { limit, used } = consumption || {};

  const isUnlimited = limit === -1;

  const percentUsed = (used * 100) / (isUnlimited ? 100 : limit) || 0;

  const dataGraphFormatted: DataDonut[] = [
    {
      id: CHART_ID.USED,
      label: intl.formatMessage({ id: 'dashboard.used' }),
      value: isUnlimited ? 0 : used,
      color: colorsToUse[0],
    },
    {
      id: CHART_ID.TOTAL,
      label: intl.formatMessage({ id: 'dashboard.total' }),
      value: isUnlimited ? 1 : limit - used,
      color: colorsToUse[1],
      hiddenTooltip: !isUnlimited,
    },
  ];

  const dataGraphFormattedUsed = dataGraphFormatted.find(
    (item) => item.id === 'used'
  );

  const formattedPercent = (percent: number) =>
    formatNumberWithDecimals(intl, percent, 1) || '';

  return (
    <>
      {isLoading ? (
        <ChartLoading color={dataGraphFormatted[0].color} isSmall />
      ) : (
        <PieChart
          data={dataGraphFormatted}
          percentToShow={
            isUnlimited
              ? ''
              : `${formattedPercent(percentUsed > 100 ? 100 : percentUsed)}%`
          }
          CustomTooltip={({ color }) => {
            return (
              <ChartTooltip color={color}>
                {!isUnlimited ? (
                  <strong>
                    {`${intl.formatMessage({
                      id: 'dashboard.used',
                    })}: ${`${formattedPercent(percentUsed)}%`}`}
                  </strong>
                ) : (
                  <FormattedMessage id="dashboard.unlimited" />
                )}
              </ChartTooltip>
            );
          }}
          TransformLegends={() => {
            return (
              <Flex fontSize="15px" color="gray.900">
                <NotificationBadgeIcon color={dataGraphFormattedUsed.color} />
                <Box lineHeight="1" ml="10px" mr="10px">
                  {usedFormatted || used}
                </Box>
              </Flex>
            );
          }}
        />
      )}
    </>
  );
};

export default ConsumptionChart;
