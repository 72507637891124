import { DeviceUserType } from './deviceUser';
export enum ImportGroupExceptionEnum {
  DEVICE_IN_OTHER_GROUP = 'DEVICE_IN_OTHER_GROUP',
  NUMBER_NOT_FOUND = 'NUMBER_NOT_FOUND',
}

export interface GroupType {
  id?: number;
  name?: string;
  userCount?: number;
  addDeviceUserIds?: DeviceUserType['id'][];
  deleteDeviceUserIds?: DeviceUserType['id'][];
}
