import { SimpleGrid } from '@chakra-ui/react';
import { formatBytesTo } from 'portal-lib';
import { useIntl } from 'react-intl';

import { managementModeToIntl } from '../../../../../pages/Policies/RegisterPolicy';
import ItemList from '../../../Reports/DeviceLocation/ItemList';
import { ModalSection } from './ModalMoreInformation';
import { ModalInformationProps } from './utils';

const ModalIOSInformation = ({
  device,
  handleFormatDateByUserLogged,
  portalSettings,
}: ModalInformationProps) => {
  const intl = useIntl();

  return (
    <SimpleGrid my="5" w="full" columns={2} spacing={10}>
      <ModalSection title={intl.formatMessage({ id: 'devices.infos.details' })}>
        <ItemList
          label={intl.formatMessage({ id: 'devices.infos.name' })}
          value={device?.name}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'devices.infos.federalCode',
          })}
          value={device?.federalCode}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'global.group',
          })}
          value={device?.group?.name}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'devices.infos.imei',
          })}
          value={device?.imei}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'devices.infos.udid',
          })}
          value={device?.udid}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'devices.infos.iccid',
          })}
          value={device?.iccid}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'devices.infos.phone',
          })}
          value={device?.phoneNumber}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'devices.infos.model',
          })}
          value={device?.model}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'devices.infos.manufacturer',
          })}
          value={device?.manufacturer}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'devices.infos.operational_system',
          })}
          value={device?.operationalSystem}
        />
      </ModalSection>

      <ModalSection title={intl.formatMessage({ id: 'devices.infos.install' })}>
        <ItemList
          label={intl.formatMessage({ id: 'devices.infos.register_date' })}
          value={handleFormatDateByUserLogged(device?.createdAt)}
        />
        <ItemList
          label={intl.formatMessage({ id: 'devices.infos.management_mode' })}
          value={
            device?.policy?.managementMode &&
            intl.formatMessage({
              id: managementModeToIntl[device?.policy?.managementMode],
            })
          }
        />
        <ItemList
          label={intl.formatMessage(
            { id: 'devices.column.companion_version' },
            { companionName: portalSettings.name }
          )}
          value={device?.companionVersion}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'devices.infos.policy_name_on_portal',
          })}
          value={device?.policy?.name}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'devices.infos.last_communication',
          })}
          value={handleFormatDateByUserLogged(device?.lastCommunication)}
        />
      </ModalSection>

      <ModalSection
        title={intl.formatMessage({ id: 'devices.infos.hardware' })}
      >
        <ItemList
          label={intl.formatMessage({
            id: 'devices.infos.intern_storage',
          })}
          value={formatBytesTo({
            bytes: device?.freeMemory,
          })}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'devices.infos.battery',
          })}
          value={`${device.battery}%`}
        />
      </ModalSection>
    </SimpleGrid>
  );
};

export default ModalIOSInformation;
