import { Box, Flex, useRadioGroup } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Alert, { AlertHtml } from '../../../components/Alert';
import Button from '../../../components/Button';
import DatePicker from '../../../components/Datepicker';
import FormContainer from '../../../components/FormContainer';
import FormControl from '../../../components/FormControl';
import PageFilter from '../../../components/PageFilter';
import PageTitle from '../../../components/PageTitle';
import RadioButton from '../../../components/RadioButton';
import Table from '../../../components/Table';
import { inFirstPage } from '../../../helper/metadata';
import { useSorting } from '../../../helper/sort';
import { useFormatDate } from '../../../hooks/useFormatDate';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import { changeSession } from '../../../store/auth';
import { listCompanies, Types } from '../../../store/company';
import { selectorMetadata } from '../../../store/ui';
import { ListMetadata } from '../../../types/generic_list';

interface CompaniesFilterType {
  termsOfUse?: string;
  startAt?: Date;
  endAt?: Date;
}

const TODAY = new Date();
const CompaniesList = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { handleFormatDateByUserLogged } = useFormatDate();
  const { companies } = useAppSelector((state) => state.company);
  const metadata = useAppSelector(selectorMetadata(Types.LIST));

  const [filterSearch, setFilterSearch] = useState('');
  const [filters, setFilters] = useState<CompaniesFilterType>({
    termsOfUse: '',
  });
  const { getRadioProps } = useRadioGroup({
    name: 'termsOfUse',
    defaultValue: '',
    value: filters.termsOfUse,
    onChange: handleRadioButtonChange,
  });

  const allFilters = {
    search: filterSearch,
    termsOfUse: filters?.termsOfUse,
    startAt: filters?.startAt,
    endAt: filters?.endAt,
  };

  useEffect(() => {
    dispatch(listCompanies(inFirstPage(metadata), allFilters));
  }, [filterSearch, filters]);

  const termsOfUseOptions = [
    {
      label: 'global.all',
      value: '',
    },
    {
      label: 'global.yes',
      value: 'true',
    },
    {
      label: 'global.no',
      value: 'false',
    },
  ];

  const headersColumn = useSorting(
    [
      {
        header: intl.formatMessage({
          id: 'companies_list.column.name',
        }),
        accessor: 'name',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: 'companies_list.column.created_at',
        }),
        accessor: 'createdAt',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: 'companies_list.column.email',
        }),
        accessor: 'email',
      },
      {
        header: intl.formatMessage({
          id: 'companies_list.column.licenses',
        }),
        accessor: 'licenses',
      },
      {
        header: intl.formatMessage({
          id: 'companies_list.column.devices',
        }),
        accessor: 'devices',
      },
      {
        header: intl.formatMessage({
          id: 'companies_list.column.terms_of_use',
        }),
        accessor: 'termsOfUse',
      },
      {
        header: '',
        accessor: 'selectCompany',
      },
    ],
    metadata
  );

  const data = companies?.map((company) => ({
    key: String(company?.id),
    cells: [
      {
        field: 'name',
        value: company?.name,
      },
      {
        field: 'createdAt',
        value: handleFormatDateByUserLogged(company?.createdAt),
      },
      {
        field: 'email',
        value: company?.email,
      },
      {
        field: 'licenses',
        value: company?.licenses,
      },
      {
        field: 'devices',
        value: company?.devices,
      },
      {
        field: 'termsOfUse',
        value: intl.formatMessage({
          id: company?.termsOfUse ? 'global.yes' : 'global.no',
        }),
      },
      {
        field: 'selectCompany',
        value: (
          <Button
            fontWeight="normal"
            size="sm"
            isDisabled={!company?.termsOfUse}
            onClick={() =>
              Alert({
                onConfirm: () =>
                  dispatch(changeSession({ companyId: company?.id })),
                confirmButtonText: intl.formatMessage({
                  id: 'companies_list.alert.confirm',
                }),
                cancelButtonText: intl.formatMessage({
                  id: 'alert.button.cancel',
                }),
                html: (
                  <AlertHtml
                    text={intl.formatMessage(
                      {
                        id: 'companies_list.alert.text',
                      },
                      { companyName: company?.name }
                    )}
                  />
                ),
              })
            }
          >
            <FormattedMessage id="companies_list.button.select_company" />
          </Button>
        ),
      },
    ],
  }));

  const handleMetadata = (newMetadata: Partial<ListMetadata>) => {
    dispatch(listCompanies({ ...metadata, ...newMetadata }, allFilters));
  };

  function handleRadioButtonChange(value) {
    setFilters({ ...filters, termsOfUse: value });
  }

  const handlePeriodFilterChange = (date: Date, field: string) => {
    setFilters({ ...filters, [field]: date });
  };

  return (
    <>
      <PageTitle
        showManualIcon
        title={<FormattedMessage id="companies_list.title" />}
        description={<FormattedMessage id="companies_list.description" />}
      />
      <FormContainer>
        <PageFilter gridGap={4}>
          <FormControl
            as={Flex}
            flexDirection="column"
            justifyContent="space-between"
            w="350px"
            textLabel={<FormattedMessage id="companies_list.filter.title" />}
          >
            <Flex w="350px" gridGap={4}>
              <Box w="165px">
                <DatePicker
                  maxDate={filters.endAt || TODAY}
                  hasValue={filters.startAt}
                  selected={filters.startAt}
                  placeholder={intl.formatMessage({
                    id: 'companies_list.filter.start_at',
                  })}
                  onChange={(e) => {
                    handlePeriodFilterChange(e, 'startAt');
                  }}
                />
              </Box>
              <Box w="165px">
                <DatePicker
                  minDate={filters.startAt}
                  maxDate={TODAY}
                  hasValue={filters.endAt}
                  placeholder={intl.formatMessage({
                    id: 'companies_list.filter.end_at',
                  })}
                  selected={filters.endAt}
                  onChange={(e) => {
                    handlePeriodFilterChange(e, 'endAt');
                  }}
                />
              </Box>
            </Flex>
          </FormControl>

          <FormControl
            textLabel={
              <FormattedMessage id="companies_list.filter.terms_of_usage" />
            }
          >
            <Flex>
              {termsOfUseOptions.map((option, index) => {
                const radio = getRadioProps({ value: option.value });
                return (
                  <RadioButton key={index} {...radio}>
                    <FormattedMessage id={option.label} />
                  </RadioButton>
                );
              })}
            </Flex>
          </FormControl>
        </PageFilter>
      </FormContainer>
      <Table
        headerColumns={headersColumn}
        rows={data}
        handleSort={handleMetadata}
        metadata={metadata}
        keyProp={Types.LIST}
        pageActionsProps={{
          onSearch: setFilterSearch,
          disabledAllExportsActions: true,
        }}
        nameTable={`${intl.formatMessage({ id: 'companies_list.title' })}`}
      />
    </>
  );
};

export default CompaniesList;
