import { formatDate } from '../../helper/date';
import { useAppSelector } from '../../hooks/useRedux';

interface FormatDateByUserLoggedProps {
  date: string | number | Date;
  dateFormat?: 'short' | 'full' | 'long' | 'medium' | undefined;
  timeFormat?: 'short' | 'full' | 'long' | 'medium' | undefined;
}

export const FormatDateByUserLogged = ({
  date,
  dateFormat,
  timeFormat,
}: FormatDateByUserLoggedProps) => {
  const { user } = useAppSelector((state) => state.auth);
  const { portalSettings } = useAppSelector((state) => state.portalSettings);
  return (
    <>
      {formatDate(
        user.language || portalSettings?.defaultLanguage,
        user.timezone || portalSettings?.defaultTimeZone,
        date,
        dateFormat,
        timeFormat
      )}
    </>
  );
};
