import { Flex } from '@chakra-ui/layout';
import { FormattedMessage } from 'react-intl';

import { InvoiceDeviceType } from '../../../../types/invoice';
import Modal from '../../../Modal';
import PageTitle from '../../../PageTitle';
import Text from '../../../Text';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  device?: InvoiceDeviceType;
}

export const ModalObservation = ({ isOpen, onClose, device }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Flex align="center" w="full" p="5%">
        <Flex h="100%" flexDirection="column" justify="space-around" w="100%">
          <PageTitle
            title={
              <FormattedMessage id="invoice_details.devices_details.observation_modal.title" />
            }
          />

          <Flex>
            {device?.observation ? (
              <Text>{device?.observation}</Text>
            ) : (
              <Text fontStyle="italic">
                <FormattedMessage id="invoice_details.devices_details.observation_modal.empty_observation" />
              </Text>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
};
