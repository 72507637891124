import { Box, Flex } from '@chakra-ui/react';
import React from 'react';

import LayoutHeader from '../../components/LayoutHeader';

const LayoutExternal = ({ children }) => {
  return (
    <Flex flexDir="column" h="100%" w="full">
      <Box boxShadow="md">
        <LayoutHeader isPublic />
      </Box>
      <Flex px="20%" py="5%">
        {children}
      </Flex>
    </Flex>
  );
};

export default LayoutExternal;
