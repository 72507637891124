import { Flex } from '@chakra-ui/react';

import Skeleton from '../../Skeleton';

const TimelineSkeleton = () => {
  const SkeletonItem = () => (
    <>
      <Skeleton h={10} w={20} />
      <Flex justify="center" align="center" gridGap="6">
        <Skeleton h={4} w={20} />
        <Flex flexDirection="column" justify="center" align="center">
          <Skeleton h={5} w={1} />
          <Skeleton type="CIRCLE" w={4} h={4} />
          <Skeleton h={5} w={1} />
        </Flex>
        <Skeleton h={4} w={20} />
      </Flex>
    </>
  );

  return (
    <Flex
      justify="center"
      align="center"
      flexDirection="column"
      w="full"
      mt="14"
    >
      {[...Array(6)].map((_value, index) => (
        <SkeletonItem key={index} />
      ))}
    </Flex>
  );
};

export default TimelineSkeleton;
