interface StorageType {
  setItem(key: string, value: unknown): void;
  getItem(key: string): unknown | undefined;
  removeItem(key: string): void;
}

export const useStorage = (storage: StorageType) => {
  const setItem = (key: string, value: unknown) => storage.setItem(key, value);

  const getItem = (key: string) => {
    const item = storage.getItem(key);
    if (!item) return undefined;

    return JSON.parse(String(item));
  };

  const removeItem = (key: string) => storage.removeItem(key);

  return {
    getItem,
    removeItem,
    setItem,
  };
};
