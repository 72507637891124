import { useTheme } from '@chakra-ui/react';
import { formatBytesTo } from 'portal-lib';

import { useAppSelector } from '../../../../hooks/useRedux';
import { Types } from '../../../../store/dashboard';
import { selectorLoading } from '../../../../store/ui';
import ConsumptionChart from './ConsumptionChart';

const DashboardDataConsumptionChart = () => {
  const { dataConsumption } = useAppSelector((state) => state.dashboard);
  const { user } = useAppSelector((state) => state.auth);

  const isLoading = useAppSelector(
    selectorLoading(Types.LIST_DATA_CONSUMPTION)
  );

  const { colors } = useTheme();
  const colorsToUse = [colors['chart']['600'], colors['chart']['700']];
  return (
    <ConsumptionChart
      colorsToUse={colorsToUse}
      consumption={dataConsumption}
      isLoading={isLoading}
      usedFormatted={formatBytesTo({
        bytes: dataConsumption?.used,
        decimals: 2,
        language: user?.language,
      })}
    />
  );
};

export default DashboardDataConsumptionChart;
