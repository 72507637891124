import { intervalToDuration } from 'date-fns';
import React, { useState, useEffect } from 'react';

import Text from '../../../Text';

interface TimerProps {
  startDateConnection?: Date;
}

function Timer({ startDateConnection }: TimerProps) {
  const [duration, setDuration] = useState(null);

  useEffect(() => {
    if (!!startDateConnection) {
      setTimeout(() => {
        setDuration(
          intervalToDuration({
            start: startDateConnection,
            end: new Date(),
          })
        );
      }, 1000);
    }
  }, [duration, startDateConnection]);

  const formatTime = (time: number) => String(time || 0).padStart(2, '0');

  const timerFormatted = (duration: Duration) => {
    return `${formatTime(duration?.hours)}:${formatTime(
      duration?.minutes
    )}:${formatTime(duration?.seconds)}`;
  };

  return (
    <Text color="warning.600" fontWeight="md" m={0}>
      {timerFormatted(duration)}
    </Text>
  );
}

export default Timer;
