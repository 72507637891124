import {
  ConsumptionProfileMode,
  RuleType,
} from '../../../types/consumptionProfile';
import { OperationalSystemEnum } from '../../../types/device';

export function calculateConsumptionProfileLabel(
  intl,
  value: RuleType,
  formatValue = (limit: number) => limit?.toString(),
  operationalSystem?: OperationalSystemEnum
) {
  if (operationalSystem === OperationalSystemEnum.IOS) {
    return null;
  }

  if (value.mode === ConsumptionProfileMode.ASSIGNED) {
    return value.limit !== null
      ? formatValue(value.limit)
      : intl.formatMessage({
          id: 'consumption_profile.values.level_up',
        });
  }
  if (value.mode === ConsumptionProfileMode.LEVEL_UP) {
    return intl.formatMessage({
      id: 'consumption_profile.values.level_up',
    });
  }
  return intl.formatMessage({
    id: 'consumption_profile.values.unlimited',
  });
}
