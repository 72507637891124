import {
  Text,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Divider,
  Tooltip,
  Flex,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAppDispatch } from '../../../../hooks/useRedux';
import { editCompany, newEmailToken } from '../../../../store/company';
import { Types } from '../../../../store/company';
import {
  hasError,
  hasLoading,
  hasSuccess,
  uiRemoveError,
  uiRemoveSuccess,
} from '../../../../store/ui';
import { CompanyType } from '../../../../types/company';
import Button from '../../../Button';
import AlertModal from '../../../Icons/AlertModal';
import PinInputs from '../../../PinInputField';

interface CompanyInfoModalProps {
  open: boolean;
  onClose: () => void;
  company: CompanyType;
}

const CompanyInfoModal = ({
  open,
  onClose,
  company,
}: CompanyInfoModalProps) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [token, setToken] = useState<string>('');
  const error = hasError(Types.EDIT);
  const successConfirm = hasSuccess(Types.EDIT);
  const loadingSendEmail = hasLoading(Types.NEW_EMAIL_TOKEN);
  const successSendEmail = hasSuccess(Types.NEW_EMAIL_TOKEN);
  const loadingConfirmEmail = hasLoading(Types.EDIT);
  const isEmailError = error?.messages?.hasOwnProperty('email');

  useEffect(() => {
    if (successConfirm) {
      onClose();
    }

    if (error) {
      setToken('');
    }
  }, [successConfirm, error]);

  useEffect(() => {
    if (successSendEmail) {
      setToken('');

      dispatch(uiRemoveError(Types.EDIT));

      setTimeout(() => {
        dispatch(uiRemoveSuccess(Types.NEW_EMAIL_TOKEN));
      }, 3000);
    }
  }, [successSendEmail]);

  const handleModalUpdate = () => {
    dispatch(editCompany({ ...company, token: token }));
  };

  const handleResendTokenEmail = () => {
    dispatch(uiRemoveSuccess(Types.NEW_EMAIL_TOKEN));
    dispatch(newEmailToken({ email: company?.email }));
  };

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      closeOnEsc
      isCentered
      closeOnOverlayClick
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader d="flex" flexDirection="column" alignItems="center">
          <AlertModal boxSize={24} mt="20px" color="primary.500" />
        </ModalHeader>
        <ModalBody d="flex" flexDirection="column" alignItems="center">
          <Text fontWeight="bold" fontSize="16px">
            <FormattedMessage id="company_info.modal.title" />
          </Text>
          <Text fontWeight="normal" fontSize="14px" mt="10px">
            <FormattedMessage id="company_info.modal.text" />
            <strong>{` ${company.email}`}</strong>
          </Text>
          {isEmailError && (
            <Text color="warning.500" m="4" fontWeight="bold" fontSize="sm">
              {error?.messages?.['email']}
            </Text>
          )}
          <PinInputs
            value={token}
            onChange={(e) => setToken(e)}
            isError={isEmailError}
          />
          <Tooltip
            isOpen={successSendEmail || false}
            bg="success.500"
            hasArrow
            arrowSize={8}
            label={intl.formatMessage({
              id: 'company_info.modal.send_code_success',
            })}
          >
            <Flex>
              <Button
                fontWeight="normal"
                fontSize="14px"
                mt="30px"
                variant="link"
                onClick={handleResendTokenEmail}
                isLoading={loadingSendEmail}
              >
                <FormattedMessage id="company_info.modal.send_code" />
              </Button>
            </Flex>
          </Tooltip>
        </ModalBody>
        <ModalFooter d="flex" flexDirection="column" alignSelf="center">
          <Box mb="19px" w="424px">
            <Divider borderColor="gray.600" orientation="horizontal" />
          </Box>
          <Box d="flex" flexDirection="row">
            <Box mr="14px">
              <Button
                w="180px"
                h="45px"
                fontWeight="normal"
                onClick={onClose}
                variant="outline"
              >
                <FormattedMessage id="global.cancel" />
              </Button>
            </Box>
            <Box mr="14px">
              <Button
                w="180px"
                h="45px"
                fontWeight="normal"
                onClick={handleModalUpdate}
                isLoading={loadingConfirmEmail}
              >
                <FormattedMessage id="global.confirm" />
              </Button>
            </Box>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CompanyInfoModal;
