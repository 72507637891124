import { Flex, FlexProps, Box, useTheme } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface LayoutProps extends FlexProps {
  children: ReactNode;
}

const LayoutCompanyInitial = ({ children, ...rest }: LayoutProps) => {
  const { images } = useTheme();
  return (
    <Flex w="100%" justify="space-between">
      <Flex
        overflowY="auto"
        flexDirection="column"
        w="800px"
        h="100%"
        pr="5%"
        pl="5%"
        pt="2.5%"
        pb="2.5%"
        {...rest}
      >
        {children}
      </Flex>
      <Box
        flex="1"
        w="100%"
        backgroundImage={images?.backgroundLogin}
        backgroundColor="primary.600"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="70% 50%"
      ></Box>
    </Flex>
  );
};

export default LayoutCompanyInitial;
