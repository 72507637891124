import { Flex } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAppDispatch } from '../../../../hooks/useRedux';
import { operationalSystemToIntl } from '../../../../pages/Policies/EditPolicies';
import { copyPolicy } from '../../../../store/policy';
import { Types } from '../../../../store/policy';
import { hasLoading, hasSuccess } from '../../../../store/ui';
import { PolicyType } from '../../../../types/policy';
import FormContainer from '../../../FormContainer';
import FormControl from '../../../FormControl';
import Input from '../../../Input';
import Modal from '../../../Modal';
import PageErrors from '../../../PageErrors';
import PageTitle from '../../../PageTitle';

interface ModalPolicyType {
  isOpen: boolean;
  policy: PolicyType;
  closeModal: () => void;
}

const ModalPolicy = ({ policy, isOpen, closeModal }: ModalPolicyType) => {
  const intl = useIntl();
  const [name, setName] = useState('');
  const dispatch = useAppDispatch();
  const success = hasSuccess(Types.COPY);
  const isLoading = hasLoading(Types.COPY);

  const submit = () => {
    dispatch(copyPolicy(policy?.id, { name }));
  };

  useEffect(() => {
    if (success) {
      closeModal();
    }
  }, [success]);

  return (
    <Modal isOpen={isOpen} onClose={() => closeModal()} size="xl">
      <Flex align="center" w="full" p="5%">
        <Flex h="100%" flexDirection="column" justify="space-around" w="100%">
          <PageTitle
            title={<FormattedMessage id="policy_copy.title" />}
            description={`${intl.formatMessage(
              {
                id: 'policy_copy.subtitle',
              },
              {
                name: policy?.name,
                operationalSystem: intl.formatMessage({
                  id: operationalSystemToIntl[policy.operationalSystem],
                }),
              }
            )}`}
          />
          <FormContainer
            labelPrimary={<FormattedMessage id={'global.register'} />}
            handlePrimary={submit}
            labelSecondary={<FormattedMessage id={'global.cancel'} />}
            handleSecondary={() => closeModal()}
            loadingPrimary={isLoading}
            disabledPrimary={!name}
            divider={false}
          >
            <PageErrors
              toasterKeys={[Types.COPY]}
              translateKey="register_policies"
              w="full"
            />
            <FormControl
              textLabel={<FormattedMessage id="policy_copy.name" />}
              mb="10%"
            >
              <Input
                inputProps={{
                  value: name,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    setName(e.target.value);
                  },
                  placeholder: intl.formatMessage({
                    id: 'policy_copy.name.placeholder',
                  }),
                }}
              />
            </FormControl>
          </FormContainer>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default ModalPolicy;
