import { Box, Flex } from '@chakra-ui/react';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import FormContainer from '../../components/FormContainer';
import FormControl from '../../components/FormControl';
import Input from '../../components/Input';
import ItemList from '../../components/ItemListDrill/ItemListDrill';
import PageErrors from '../../components/PageErrors';
import PageShowToaster from '../../components/PageShowToaster';
import Text from '../../components/Text';
import { isValidEmail } from '../../helper/email';
import { useQueryParams } from '../../hooks/useQueryParams';
import { useAppDispatch } from '../../hooks/useRedux';
import { sendWelcomeEmail, Types } from '../../store/company';
import { hasLoading } from '../../store/ui';

interface ParamsType {
  contact?: string;
  razao_social?: string;
  phone?: string;
  cnpj?: string;
}

const ConfirmWelcomeEmail = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const params: ParamsType = useQueryParams();
  const [adminEmail, setAdminEmail] = useState('');

  const isLoading = hasLoading(Types.SEND_WELCOME_EMAIL);

  const isNotValidEmail = adminEmail?.length > 0 && !isValidEmail(adminEmail);

  const isDisabledPrimary = isNotValidEmail || !adminEmail || !params?.cnpj;

  const handleSubmit = () => {
    dispatch(
      sendWelcomeEmail({
        externalCode: params.cnpj,
        adminEmail,
      })
    );
  };

  return (
    <Flex flexDirection="column">
      <Box pb="4">
        <Text fontSize="4xl" as="b" color="gray.900" fontWeight="700">
          <FormattedMessage id="confirm_welcome_email.title" />
        </Text>
        <Text fontSize="lg" fontWeight="300" color="gray.900" m="0">
          <FormattedMessage id="confirm_welcome_email.description" />
        </Text>
      </Box>

      <PageShowToaster toasterKeys={[Types.SEND_WELCOME_EMAIL]} disabledError />
      <PageErrors
        toasterKeys={[Types.SEND_WELCOME_EMAIL]}
        translateKey="confirm_welcome_email"
        w="100%"
      />

      <FormContainer
        pl="0"
        labelPrimary={<FormattedMessage id="confirm_welcome_email.confirm" />}
        handlePrimary={handleSubmit}
        divider={false}
        primaryWidth="300px"
        disabledPrimary={isDisabledPrimary}
        loadingPrimary={isLoading}
      >
        <ItemList
          label={intl.formatMessage({
            id: 'confirm_welcome_email.corporate_reason',
          })}
          value={params?.razao_social}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'confirm_welcome_email.externalCode',
          })}
          value={params?.cnpj}
        />
        <ItemList
          label={intl.formatMessage({ id: 'confirm_welcome_email.contact' })}
          value={params?.contact}
        />
        <ItemList
          label={intl.formatMessage({ id: 'confirm_welcome_email.phone' })}
          value={params?.phone}
        />
        <FormControl
          textLabel={
            <FormattedMessage id="confirm_welcome_email.admin_email" />
          }
          pt="6"
          pb="4"
        >
          <Input
            inputProps={{
              name: 'adminEmail',
              value: adminEmail,
              onChange: (e) => setAdminEmail(e.target.value),
              maxW: '700px',
            }}
          />
          <Box
            color="warning.500"
            fontSize="sm"
            fontWeight="bold"
            h="21px"
            m="0"
          >
            {isNotValidEmail && (
              <FormattedMessage id="confirm_welcome_email.error.invalid_email" />
            )}
          </Box>
        </FormControl>
      </FormContainer>
    </Flex>
  );
};

export default ConfirmWelcomeEmail;
