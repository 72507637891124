import { WarningTwoIcon } from '@chakra-ui/icons';
import { Divider, Box, Text, Icon } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import Button from '../../Button';
import Checkmark from '../../Checkmark';
import Heading from '../../Heading';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../Modal';

export enum ModalTypeEnum {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

interface RecoveryPasswordModalProps {
  open: boolean;
  modalType: ModalTypeEnum;
  onClose: () => void;
  email?: string | unknown;
}

const RecoveryPasswordModal = ({
  open,
  modalType,
  onClose,
  email,
}: RecoveryPasswordModalProps) => {
  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={false}
      modalContentProps={{ w: '500px' }}
    >
      <ModalHeader>
        {modalType === ModalTypeEnum.SUCCESS ? (
          <Checkmark boxSize={24} mt="20px" color="success.500" />
        ) : (
          <Icon as={WarningTwoIcon} boxSize={24} color="warning.600" />
        )}
      </ModalHeader>
      <ModalBody>
        {modalType === ModalTypeEnum.SUCCESS && (
          <Heading variant="text">
            <FormattedMessage id="password_recovery.modal.check_email" />
          </Heading>
        )}

        <Text fontSize="14px" mt="20px">
          <FormattedMessage
            id={
              modalType === ModalTypeEnum.SUCCESS
                ? 'password_recovery.modal.email_sent'
                : 'password_recovery.modal.error'
            }
          />
        </Text>
        <Text fontWeight="bold" fontSize="14px">
          {email}
        </Text>
        <Text fontSize="14px" mt="20px" color="gray.300" textAlign="center">
          <FormattedMessage id="password_recovery.modal.support_text" />
        </Text>
      </ModalBody>
      <ModalFooter>
        <Box mb="19px" w="424px">
          <Divider borderColor="gray.600" orientation="horizontal" />
        </Box>
        <Box d="flex" flexDirection="row">
          <Box mr="14px">
            <Button w="180px" h="45px" onClick={onClose}>
              <FormattedMessage id="global.close" />
            </Button>
          </Box>
        </Box>
      </ModalFooter>
    </Modal>
  );
};

export default RecoveryPasswordModal;
