import { Box, useTheme } from '@chakra-ui/react';
import React, { useEffect } from 'react';

import TermsLoading from '../../../components/pages/terms/TermsLoading';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import { listTermsOfUsage, Types } from '../../../store/term';
import { hasLoading } from '../../../store/ui';

const TermsOfUsage = () => {
  const dispatch = useAppDispatch();
  const { termsOfUsage } = useAppSelector((state) => state.term);
  const isLoading = hasLoading(Types.TERMS_OF_USAGE);
  const theme = useTheme();

  useEffect(() => {
    if (theme.terms) {
      window.location.href = theme.terms;
    } else {
      dispatch(listTermsOfUsage());
    }
  }, []);

  if (isLoading) {
    return <TermsLoading />;
  }

  return (
    <Box
      className="termsOfUsage"
      dangerouslySetInnerHTML={{ __html: termsOfUsage }}
    />
  );
};

export default TermsOfUsage;
