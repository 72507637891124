import { Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { MarkerMapProps } from '../../Map';

export interface WindowCloudInfoProps {
  date: string;
  state: string;
  precision: number;
  battery: number;
  address: string;
  speed: number;
}

interface WindowCloudProps {
  selected: MarkerMapProps<WindowCloudInfoProps>;
}

const WindowCloud = ({ selected }: WindowCloudProps) => {
  return (
    <>
      {selected.infos.date && <Text>{selected.infos.date}</Text>}
      {selected.infos.state && (
        <Text>
          <FormattedMessage id="map.state" />: {selected.infos.state}
        </Text>
      )}
      {selected.infos.precision && (
        <Text>
          <FormattedMessage id="map.precision" />: {selected.infos.precision}
        </Text>
      )}
      {selected.infos.battery && (
        <Text>
          <FormattedMessage id="map.battery" />: {selected.infos.battery}%
        </Text>
      )}
      {selected.infos.address && (
        <Text>
          <FormattedMessage id="map.address" />: {selected.infos.address}
        </Text>
      )}
      {selected.infos.speed && (
        <Text>
          <FormattedMessage id="map.speed" />
          a: {selected.infos.speed}
        </Text>
      )}
    </>
  );
};

export default WindowCloud;
