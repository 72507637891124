import { Box, Divider, Flex, FormLabel, useRadioGroup } from '@chakra-ui/react';
import { Size } from 'portal-lib';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Text from '../../../../components/Text';
import { LimitConsumptionType } from '../../../../pages/Configuration/ConsumptionProfile/ConsumptioProfileEdit';
import { ConsumptionProfileMode } from '../../../../types/consumptionProfile';
import FormControl from '../../../FormControl';
import Input from '../../../Input';
import OperationalSystemIcons from '../../../OperationalSystemIcons';
import RadioButton from '../../../RadioButton';
import Select from '../../../Select';

interface OptionsRadiosType {
  id: string;
  label: string;
}
interface PropsTypeFormRule {
  rule: LimitConsumptionType;
  setRule?: (value: LimitConsumptionType) => void;
  idRule: string;
  titleGroupRadios: string;
  optionsRadios: OptionsRadiosType[];
  hideIOS?: boolean;
  hideAndroid?: boolean;
  showOperationalSystemIcons?: boolean;
  isDisable?: boolean;
}
const FormRule = ({
  rule,
  setRule,
  idRule,
  titleGroupRadios,
  optionsRadios,
  hideIOS,
  hideAndroid,
  isDisable,
  showOperationalSystemIcons,
}: PropsTypeFormRule) => {
  const selectTemp = ['Bytes', 'KB', 'MB', 'GB'];

  const handleSelectChange = (selectedUnit: Size) => {
    setRule({
      ...rule,
      limitUnit: selectedUnit,
    });
  };

  const handleRadioChange = (newMode: ConsumptionProfileMode) => {
    setRule({
      ...rule,
      mode: newMode,
      limitValue: null,
    });
  };

  const handleInputChange = (newQuantity: number) => {
    setRule({
      ...rule,
      limitValue: newQuantity,
    });
  };

  const { getRadioProps } = useRadioGroup({
    name: idRule,
    value: rule.mode,
    onChange: handleRadioChange,
  });

  return (
    <>
      <Flex p="1">
        <Text m="0" fontSize="md" fontWeight="600">
          <FormattedMessage id={titleGroupRadios} />
        </Text>

        {showOperationalSystemIcons && (
          <OperationalSystemIcons
            hideIOS={hideIOS}
            hideAndroid={hideAndroid}
            size="sm"
          />
        )}
      </Flex>

      <Divider borderColor="gray.600" orientation="horizontal" mb="1.5%" />
      <Box d="flex" flexDirection="column">
        <Box d="flex" flexDirection="row">
          {optionsRadios?.map((option) => {
            const radio = getRadioProps({
              value: option?.id,
            });
            return (
              <RadioButton key={option?.id} disabled={isDisable} {...radio}>
                {option?.label}
              </RadioButton>
            );
          })}
        </Box>
        <Box d="flex" flexDirection="row" mt="30px" minHeight="75px">
          <FormControl w="176px" mr="24px">
            <FormLabel d="flex" flexDirection="row" fontSize="sm">
              <FormattedMessage id="consumption_profile.general.quantity" />
            </FormLabel>
            <Input
              inputProps={{
                disabled:
                  rule.mode !== ConsumptionProfileMode.ASSIGNED || isDisable,
                name: 'data_qtd',
                value: rule?.limitValue || '',
                type: 'number',
                min: 1,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(parseInt(e.target.value)),
              }}
            />
          </FormControl>

          {(idRule === 'data' || idRule === 'dataRoaming') && (
            <FormControl w="176px" mr="24px">
              <FormLabel d="flex" flexDirection="row" fontSize="sm">
                <FormattedMessage id="consumption_profile.general.bytes" />
              </FormLabel>
              <Select
                disabled={
                  rule.mode !== ConsumptionProfileMode.ASSIGNED || isDisable
                }
                name="data"
                value={rule.limitUnit || ''}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  handleSelectChange(e.target.value as Size)
                }
              >
                {selectTemp.map((timestamp, index) => (
                  <option key={index} value={timestamp}>
                    {timestamp}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>
      </Box>
    </>
  );
};

export default FormRule;
