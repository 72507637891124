import { Box, Button, Flex, Tooltip } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { matchPath } from 'react-router';
import { useHistory } from 'react-router-dom';
import { ActionCreator, AnyAction } from 'redux';

import FormContainer from '../../../../components/FormContainer';
import FormControl from '../../../../components/FormControl';
import LeftArrowCircleIcon from '../../../../components/Icons/LeftArrowCircle';
import PageErrors from '../../../../components/PageErrors';
import { AuthCredentialErrorEnum } from '../../../../components/pages/Login/ErrorModal';
import PinInputs from '../../../../components/PinInputField';
import Spinner from '../../../../components/Spinner';
import Text from '../../../../components/Text';
import { getCallbackRegisterUrl } from '../../../../helper';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import LayoutCompanyInitial from '../../../../layouts/LayoutCompanyInitial';
import routes from '../../../../routes';
import {
  loginConfirmation,
  resendToken,
  Types as TypesAuth,
} from '../../../../store/auth';
import {
  validateEmailCompany,
  sendUrlCallback,
  Types as TypesCompany,
  listCompanyInfo,
  validateEmailSendCompany,
} from '../../../../store/company';
import {
  hasError,
  hasLoading,
  hasSomeLoading,
  hasSuccess,
  uiRemoveError,
  uiRemoveSuccess,
} from '../../../../store/ui';

interface ActionsType {
  email?: string;
  emailDescriptionKey?: string;
  onGoBack?: () => void;
  onResendToken?: ActionCreator<AnyAction>;
  onSubmit?: ActionCreator<AnyAction>;
  errorsToasterKeys?: string[];
  resendCodeType?: string;
  submitType?: string;
  errorSubmitType?: string;
}

const errorsByMessageResponse = {
  [AuthCredentialErrorEnum.INVALID_TOKEN]:
    'login_confirmation.error.invalid_code',
  default: 'login_confirmation.error.default',
};

const CompanyConfirmEmail = () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { authenticatedEmail, company } = useAppSelector(
    (state) => state.company
  );
  const { formConfirmation } = useAppSelector((state) => state.auth);
  const [token, setToken] = useState<string>();

  const isLoginEmailConfirmation = matchPath(history.location.pathname, {
    path: routes.loginConfirmation,
  });

  const actionByRouteMatch: Record<string, ActionsType> = {
    [routes.company.validEmail]: {
      email: company?.email,
      emailDescriptionKey: 'register_validate.info',
      errorsToasterKeys: [
        TypesCompany.VALIDATE_TOKEN,
        TypesCompany.SIGNUP_INITIAL,
      ],
      onGoBack: backwardRedirect,
      onResendToken: validateEmailSendCompany,
      onSubmit: validateEmailCompany,
      submitType: TypesCompany.CONFIRM_INFO,
      resendCodeType: TypesCompany.VALIDATE_EMAIL_SEND,
      errorSubmitType: TypesCompany.VALIDATE_TOKEN,
    },
    [routes.loginConfirmation]: {
      email: formConfirmation?.email,
      emailDescriptionKey: 'login_confirmation.info',
      errorsToasterKeys: [TypesAuth.LOGIN_CONFIRMATION],
      onGoBack: history.goBack,
      onResendToken: resendToken,
      onSubmit: loginConfirmation,
      submitType: TypesAuth.LOGIN_CONFIRMATION,
      resendCodeType: TypesAuth.RESEND_TOKEN,
      errorSubmitType: TypesAuth.LOGIN_CONFIRMATION,
    },
  };

  const actionsEntity: ActionsType =
    actionByRouteMatch[history.location.pathname];
  const success = hasSuccess(actionsEntity.submitType);
  const errorOnValidateToken = hasError(actionsEntity.errorSubmitType);
  const successResendCode = hasSuccess(actionsEntity.resendCodeType);
  const loadingResendToken = hasLoading(actionsEntity.resendCodeType);

  const loadingConfirm = hasSomeLoading([
    TypesCompany.VALIDATE_EMAIL,
    TypesAuth.LOGIN_CONFIRMATION,
  ]);

  useEffect(() => {
    if (!isLoginEmailConfirmation) {
      if (!company?.email) {
        dispatch(listCompanyInfo());
      }

      if (!success) {
        history.push(routes.company.register);
      }
    }

    if (isLoginEmailConfirmation && !formConfirmation?.email) {
      history.push(routes.login);
    }
  }, [isLoginEmailConfirmation]);

  useEffect(() => {
    if (authenticatedEmail && !isLoginEmailConfirmation) {
      dispatch(
        sendUrlCallback({
          callbackUrl: getCallbackRegisterUrl(routes),
        })
      );
    }
  }, [authenticatedEmail, isLoginEmailConfirmation]);

  useEffect(() => {
    if (successResendCode || !!errorOnValidateToken) {
      setToken('');
    }

    if (successResendCode) {
      dispatch(uiRemoveError(actionsEntity.errorSubmitType));

      setTimeout(() => {
        dispatch(uiRemoveSuccess(actionsEntity.resendCodeType));
      }, 3000);
    }
  }, [successResendCode, errorOnValidateToken]);

  function submit() {
    const formToSubmit = isLoginEmailConfirmation
      ? {
          ...formConfirmation,
          token,
        }
      : token;
    dispatch(actionsEntity.onSubmit(formToSubmit));
  }

  function backwardRedirect() {
    dispatch(uiRemoveSuccess(TypesCompany.CONFIRM_INFO));
    dispatch(uiRemoveSuccess(TypesCompany.VALIDATE_EMAIL_SEND));
    history.push(routes.company.register);
  }

  function handleResend() {
    dispatch(actionsEntity.onResendToken({ email: actionsEntity?.email }));
  }

  return (
    <LayoutCompanyInitial
      px={isLoginEmailConfirmation && 0}
      py={isLoginEmailConfirmation && 6}
      h={!isLoginEmailConfirmation && '100vh'}
    >
      <Box>
        <Button
          variant="link"
          leftIcon={<LeftArrowCircleIcon boxSize={8} color="primary.500" />}
          onClick={actionsEntity.onGoBack}
        >
          <FormattedMessage id="global.back" />
        </Button>
      </Box>

      <Box pt="4" pb="4">
        <Text fontSize="4xl" fontWeight="300" color="gray.900" m="0">
          <FormattedMessage id="register_validate.title1" />
        </Text>

        <Text fontSize="4xl" as="b" color="gray.900" fontWeight="700">
          <FormattedMessage id="register_validate.title2" />
        </Text>
      </Box>

      <PageErrors
        toasterKeys={actionsEntity.errorsToasterKeys}
        translateKey="global"
        messageError={
          isLoginEmailConfirmation && (
            <FormattedMessage
              id={
                errorsByMessageResponse[
                  errorOnValidateToken?.message || 'default'
                ]
              }
            />
          )
        }
        w="100%"
      />
      <FormContainer
        pl="0"
        labelPrimary={<FormattedMessage id="register_validate.send_confirm" />}
        handlePrimary={submit}
        divider={false}
        primaryWidth="80%"
        disabledPrimary={!token}
        loadingPrimary={loadingConfirm}
      >
        <Flex flexDirection="column" gridGap={4} mb={4}>
          <Text>
            <FormattedMessage id={actionsEntity.emailDescriptionKey} />
            <strong>{` ${actionsEntity?.email}`}</strong>.
          </Text>

          <FormControl textLabel="Token">
            <PinInputs
              onChange={(e) => setToken(e)}
              value={token}
              isError={!!errorOnValidateToken}
            />
          </FormControl>

          <Tooltip
            isOpen={successResendCode || false}
            bg="success.500"
            placement="right"
            hasArrow
            arrowSize={8}
            label={intl.formatMessage({
              id: 'company_info.modal.send_code_success',
            })}
          >
            <Flex maxW="max-content">
              <Text
                onClick={handleResend}
                cursor="pointer"
                size="sm"
                color="primary.500"
                m={0}
              >
                {loadingResendToken ? (
                  <Spinner size="sm" />
                ) : (
                  <FormattedMessage id="register_validate.resend" />
                )}
              </Text>
            </Flex>
          </Tooltip>
        </Flex>
      </FormContainer>
    </LayoutCompanyInitial>
  );
};

export default CompanyConfirmEmail;
