import { useEffect, useState } from 'react';

interface WindowSizeType {
  size: SizeType;
}

interface SizeType {
  clientWidth?: number;
  clientHeight?: number;
}

export function useWindowSize(): WindowSizeType {
  const [size, setSize] = useState<SizeType>({
    clientWidth: window.innerWidth,
    clientHeight: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setSize({
        clientWidth: window.innerWidth,
        clientHeight: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { size };
}
