import { Box } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useFormatDate } from '../../../../hooks/useFormatDate';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { listPasswordHistoric, Types } from '../../../../store/device';
import { DeviceType } from '../../../../types/device';
import LockIcon from '../../../Icons/Lock';
import { ModalBody, ModalFooter, ModalHeader } from '../../../Modal';
import Table from '../../../Table';
import { Body, Header } from '../../../Table/TableInterfaces';
import Text from '../../../Text';

interface LastPasswordsModalProps {
  device: DeviceType;
  openNewPassword: () => void;
  onCancel: () => void;
}

const LastPasswordsModal = ({
  device,
  openNewPassword,
  onCancel,
}: LastPasswordsModalProps) => {
  const dispatch = useAppDispatch();
  const { passwordsHistoric } = useAppSelector((state) => state.device);
  const { handleFormatDateByUserLogged } = useFormatDate();
  const intl = useIntl();

  useEffect(() => {
    if (device.id) {
      dispatch(listPasswordHistoric(device.id));
    }
  }, [device.id]);

  const columns: Header[] = [
    {
      header: intl.formatMessage({
        id: 'devices.passwordHistoric.column.password',
      }),
      accessor: 'password',
    },
    {
      header: intl.formatMessage({
        id: 'devices.passwordHistoric.column.date',
      }),
      accessor: 'date',
    },
  ];
  const data: Body[] = passwordsHistoric.map((historic) => ({
    cells: [
      {
        field: 'password',
        value: historic.password,
      },
      {
        field: 'date',
        value: handleFormatDateByUserLogged(historic.createdAt),
      },
    ],
  }));

  return (
    <>
      <ModalHeader>
        <Text fontWeight="600" fontSize="2xl" color="gray.500">
          <FormattedMessage id="devices.passwordHistoric.title" />
        </Text>
      </ModalHeader>
      <ModalBody>
        <Box h="330px" overflowY="auto" w="75%">
          <Table
            rows={data}
            headerColumns={columns}
            disabledActions
            keyProp={Types.ACTION_LISTPASSWORDS}
          />
        </Box>
        <Box d="flex" alignSelf="flex-start">
          <Text
            fontSize="sm"
            color="primary.500"
            cursor="pointer"
            onClick={() => openNewPassword()}
          >
            <LockIcon color="primary" boxSize={6} mr="5px" />
            <FormattedMessage id="devices.passwordHistoric.newpassword" />
          </Text>
        </Box>
      </ModalBody>
      <ModalFooter
        onConfirm={onCancel}
        labelConfirm={intl.formatMessage({ id: 'global.close' })}
      />
    </>
  );
};

export default LastPasswordsModal;
