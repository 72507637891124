import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { IntlProvider } from 'react-intl';
import { Router } from 'react-router-dom';

import ErrorBoundary from './components/ErrorBoundary';
import { languageValidator } from './helper/validador';
import { useAppSelector } from './hooks/useRedux';
import i18nConfig from './locales';
import Routes from './routes/Routes';
import { history } from './store/history';

function App() {
  const user = useAppSelector((state) => state.auth.user);
  const loginLanguage = useAppSelector((state) => state.auth.loginLanguage);
  const defaultLanguage = useAppSelector(
    (state) => state.portalSettings.portalSettings.defaultLanguage
  );
  const storageLanguage = useMemo(
    () => JSON.parse(localStorage.getItem('portalLanguage')),
    []
  );
  const { REACT_APP_DEFAULT_LANGUAGE, REACT_APP_PORTAL_NAME } = process.env;

  const i18nSelected = languageValidator(
    i18nConfig[
      user?.language || loginLanguage || storageLanguage || defaultLanguage
    ],
    REACT_APP_DEFAULT_LANGUAGE,
    i18nConfig
  );

  const title = REACT_APP_PORTAL_NAME || 'Datamob';

  return (
    <IntlProvider locale={i18nSelected.locale} messages={i18nSelected.messages}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Router history={history}>
        <ErrorBoundary isExternal>
          <Routes />
        </ErrorBoundary>
      </Router>
    </IntlProvider>
  );
}

export default App;
