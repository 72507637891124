import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Tooltip, useStyleConfig } from '@chakra-ui/react';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import MenuIcon from '../../Icons/Menu';
import { MenuContext } from '../index';

interface Props {
  fewItems?: boolean;
}

function MenuSideIcons({ fewItems }: Props) {
  const {
    drillDownMenuOpened,
    menuItems,
    selectedMenu,
    setSelectedMenu,
    setDrillDownMenuOpened,
  } = useContext(MenuContext);
  const history = useHistory();
  const intl = useIntl();

  const style = useStyleConfig('MenuSideIcons');

  return (
    <Box position="relative" min-height="100%" zIndex={1500} width="65px">
      <Box
        d="flex"
        h="80px"
        w="65px"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        onClick={() => {
          if (selectedMenu === 'root') {
            setDrillDownMenuOpened(!drillDownMenuOpened);
          } else {
            setSelectedMenu('root');
          }
        }}
        __css={{
          ...style['menuIcon'],
        }}
      >
        {!drillDownMenuOpened && (
          <Tooltip label={intl.formatMessage({ id: 'menu.open' })}>
            <span>
              <MenuIcon boxSize={7} color="primary.500" />
            </span>
          </Tooltip>
        )}
        {drillDownMenuOpened && (
          <ArrowBackIcon boxSize={7} color="primary.500" />
        )}
      </Box>
      <Box
        d="flex"
        flexDirection="column"
        alignItems="center"
        w="65px"
        justifyContent={fewItems ? 'start' : 'space-around'}
        padding={fewItems ? '36px 0' : '0'}
        gridGap={fewItems ? '36px 0' : '0'}
        h="calc(100vh - 80px)"
        __css={{
          ...style['items'],
        }}
      >
        {menuItems.map((menuItem, index) => (
          <Box
            key={`menu-${index}`}
            cursor="pointer"
            // height="52.5px"
            onClick={() => {
              if (menuItem.link) {
                setDrillDownMenuOpened(false);
                setSelectedMenu('root');
                history.push(menuItem.link);
              } else {
                setDrillDownMenuOpened(true);
                setSelectedMenu(menuItem.id);
              }
            }}
          >
            <Tooltip label={!drillDownMenuOpened && menuItem.title}>
              <span>{menuItem.icon}</span>
            </Tooltip>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
export default MenuSideIcons;
