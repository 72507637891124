import { Icon, IconProps } from '@chakra-ui/react';

const ZeroTouchIcon = ({ ...props }: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <svg
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
      >
        <path d="M22 12a10.009 10.009 0 1 1-9-9.949v8.226a2 2 0 1 1-2 0V7.934a4.2 4.2 0 1 0 4 1.136V6.812a6 6 0 1 1-4-.722V4.069a7.993 7.993 0 1 0 4 .518V2.461A10.017 10.017 0 0 1 22 12z" />
      </svg>
    </Icon>
  );
};

export default ZeroTouchIcon;
