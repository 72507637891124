import { Box, Flex, FlexProps, Spinner } from '@chakra-ui/react';
import { ReactNode } from 'react';
interface DeviceInformationProps extends FlexProps {
  color: string;
  value: number;
  text: ReactNode;
  isColumn?: boolean;
  isUpperCase?: boolean;
  isLoading?: boolean;
  handleClick?: () => void;
}

const DeviceStatusInformation = ({
  color,
  value,
  text,
  isColumn,
  isLoading,
  isUpperCase,
  handleClick,
  ...rest
}: DeviceInformationProps) => {
  const formatValue = !!value ? String(value).padStart(2, '0') : 0;

  return (
    <Flex
      flexDirection={isColumn ? 'column' : 'row'}
      alignItems={isColumn ? 'flex-start' : 'center'}
      mr={isColumn && '30px'}
      mb={!isColumn && '10px'}
      onClick={handleClick}
      cursor={handleClick && 'pointer'}
      w="max-content"
      {...rest}
    >
      <Box fontSize={isColumn ? 40 : 20} lineHeight="1.35" color={color}>
        {isLoading ? <Spinner /> : formatValue}
      </Box>
      <Box
        fontSize="14px"
        lineHeight="1.43"
        letterSpacing="0.56px"
        color="gray.500"
        textTransform={isUpperCase ? 'uppercase' : 'inherit'}
        ml={!isColumn && '13px'}
      >
        {text}
      </Box>
    </Flex>
  );
};

export default DeviceStatusInformation;
