import { formatBytesTo } from 'portal-lib';
import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Alert, { AlertHtml } from '../../../components/Alert';
import Delete from '../../../components/Icons/Delete';
import { calculateConsumptionProfileLabel } from '../../../components/pages/ProfileConsumption/CalculateConsumptionProfileLabel';
import TabPanel from '../../../components/pages/ProfileConsumption/TabPanel';
import TableActions from '../../../components/TableActions';
import MenuItem from '../../../components/TableActions/MenuItem';
import { routeWithParameters } from '../../../helper';
import { usePrivilege } from '../../../hooks/usePrivilege';
import { useAppDispatch } from '../../../hooks/useRedux';
import routes from '../../../routes';
import {
  deleteConsumptionGroup,
  listConsumptionGroup,
  Types,
} from '../../../store/consumptionProfile';
import { hasMetadata } from '../../../store/ui';
import { GroupConsumptionType } from '../../../types/consumptionProfile';
import { ListMetadata } from '../../../types/generic_list';
import { ID } from '../../../types/util';

interface GroupsCardProps {
  groups?: { list: GroupConsumptionType[] };
}

const GroupsCard = ({ groups }: GroupsCardProps) => {
  const intl = useIntl();
  const { isHelpDesk } = usePrivilege();

  const dispatch = useAppDispatch();

  const [filterSearch, setFilterSearch] = useState('');
  const groupMetadata = hasMetadata(Types.LIST_GROUP);

  const allFilters = {
    search: filterSearch,
  };

  const handleMetadata = (newMetadata: Partial<ListMetadata>) => {
    dispatch(
      listConsumptionGroup(
        {
          ...groupMetadata,
          ...newMetadata,
        },
        allFilters
      )
    );
  };

  const handleSetLevelUp = (id: ID) => {
    dispatch(deleteConsumptionGroup(id));
  };

  useEffect(() => {
    dispatch(
      listConsumptionGroup({
        ...groupMetadata,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(listConsumptionGroup(groupMetadata, allFilters));
  }, [filterSearch]);

  const dataGroups = groups?.list.map((group) => ({
    cells: [
      {
        field: 'name',
        value: group.group.name,
      },
      {
        field: 'data',
        value: calculateConsumptionProfileLabel(
          intl,
          group.data,
          (limitToFormater) =>
            formatBytesTo({
              bytes: limitToFormater,
              to: group.data.unit || 'Bytes',
            })
        ),
      },
      {
        field: 'data_roaming',
        value: calculateConsumptionProfileLabel(
          intl,
          group.dataRoaming,
          (limitToFormater) =>
            formatBytesTo({
              bytes: limitToFormater,
              to: group.dataRoaming.unit || 'Bytes',
            })
        ),
      },
      {
        field: 'sms',
        value: calculateConsumptionProfileLabel(intl, group.sms),
      },
      {
        field: 'roaming_sms',
        value: calculateConsumptionProfileLabel(intl, group.smsRoaming),
      },
      {
        field: 'actions',
        value: '',
        transform: () => {
          return (
            <TableActions
              entityIntlLabel={<FormattedMessage id="global.rule" />}
              linkEdit={routeWithParameters(
                routes.profileConsumption.groups.edit,
                {
                  id: group.ruleId,
                }
              )}
              moreItems={
                <MenuItem
                  icon={<Delete boxSize={6} mr="5px" color="warning.500" />}
                  text={<FormattedMessage id="global.action.set_level_up" />}
                  isDisabled={isHelpDesk}
                  onClick={() =>
                    Alert({
                      onConfirm: () => handleSetLevelUp(group.ruleId),
                      html: (
                        <AlertHtml
                          text={intl.formatMessage({
                            id: 'consumption_profile.modal.apply_config',
                          })}
                        />
                      ),
                    })
                  }
                />
              }
            />
          );
        },
      },
    ],
  }));

  const groupColumn = [
    {
      header: intl.formatMessage({
        id: 'consumption_profile.column.group',
      }),
      accessor: 'name',
      canSort: true,
    },
  ];

  return (
    <TabPanel
      type={Types.LIST_GROUP}
      columns={groupColumn}
      rows={dataGroups}
      handleSort={handleMetadata}
      linkNew={routes.profileConsumption.groups.register}
      filterSearch={filterSearch}
      setFilterSearch={setFilterSearch}
      metaData={groupMetadata}
    />
  );
};

export default GroupsCard;
