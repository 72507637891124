import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { SimpleGrid } from '@chakra-ui/react';
import {
  translateInstallationFailureReasonEnum,
  translateNonComplianceReason,
  translatePasswordPolicyScopeEnum,
  translateSpecificNonComplianceReasonEnum,
} from 'portal-lib/dist/helpers/device/nonCompliance';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import CardHeader from '../../../components/CardHeader';
import FormControl from '../../../components/FormControl';
import Input from '../../../components/Input';
import ListDrillDown from '../../../components/pages/Devices/DeviceNonCompliance/ListDrillDown';
import PageTitle from '../../../components/PageTitle';
import Table from '../../../components/Table';
import { Body } from '../../../components/Table/TableInterfaces';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import { getDevice, Types } from '../../../store/device';
import {
  listDeviceNonCompliance,
  Types as InfoTypes,
} from '../../../store/deviceInfo';
import { generateExcel } from '../../../store/event';
import { hasLoading, selectorMetadata } from '../../../store/ui';

const DeviceNonCompliance = () => {
  const dispatch = useAppDispatch();
  const { nonCompliances } = useAppSelector((state) => state.deviceInfo);
  const { device } = useAppSelector((state) => state.device);
  const metadata = useAppSelector(
    selectorMetadata(InfoTypes.LIST_NON_COMPLIANCE)
  );
  const [filterSearch, setFilterSearch] = useState('');
  const { id } = useParams<{ id: string }>();
  const isLoading = hasLoading(Types.GET);
  const intl = useIntl();
  const form = {
    user: device?.name,
    phoneNumber: device?.phoneNumber,
    imei: device?.imei,
    manufacturer: device?.manufacturer,
    model: device?.model,
  };

  const allFilters = {
    search: filterSearch,
  };

  useEffect(() => {
    dispatch(getDevice(parseInt(id)));
  }, [id]);

  useEffect(() => {
    dispatch(listDeviceNonCompliance(parseInt(id), allFilters));
  }, [id, filterSearch]);

  const handleMetadata = (value) => {
    dispatch(
      listDeviceNonCompliance(parseInt(id), {
        ...metadata,
        ...value,
        ...allFilters,
      })
    );
  };

  const handleExportAllToExcel = () => {
    dispatch(
      generateExcel({
        type: InfoTypes.LIST_NON_COMPLIANCE,
        metadata: metadata,
        filters: allFilters,
        id,
      })
    );
  };

  const headerColumns = [
    {
      isExportHidden: true,
      header: '',
      accessor: 'handle_expander',
    },
    {
      header: intl.formatMessage({ id: 'non_compliance.column.settingName' }),
      accessor: 'settingName',
    },
    {
      header: intl.formatMessage({
        id: 'non_compliance.column.nonComplianceReason',
      }),
      accessor: 'nonComplianceReason',
    },
    {
      header: intl.formatMessage({
        id: 'non_compliance.column.specificNonComplianceReason',
      }),
      accessor: 'specificNonComplianceReason',
    },
    {
      isExpandedField: true,
      header: intl.formatMessage({
        id: 'non_compliance.expander.packageName',
      }),
      accessor: 'packageName',
    },
    {
      isExpandedField: true,
      header: intl.formatMessage({
        id: 'non_compliance.expander.fieldPath',
      }),
      accessor: 'fieldPath',
    },
    {
      isExpandedField: true,
      header: intl.formatMessage({
        id: 'non_compliance.expander.currentValue',
      }),
      accessor: 'currentValue',
    },
    {
      isExpandedField: true,
      header: intl.formatMessage({
        id: 'non_compliance.expander.installationFailureReason',
      }),
      accessor: 'installationFailureReason',
    },
    {
      isExpandedField: true,
      header: intl.formatMessage({
        id: 'non_compliance.expander.wifi_context',
      }),
      accessor: 'wifiGuid',
    },
    {
      isExpandedField: true,
      header: intl.formatMessage({
        id: 'non_compliance.expander.password_context',
      }),
      accessor: 'passwordPolicyScope',
    },
  ];

  const rows: Body[] = nonCompliances?.map((nonCompliance) => ({
    cells: [
      {
        isExportHidden: true,
        field: 'handle_expander',
        value: null,
        transform: ({ isExpanded, toggleExpanded }) => (
          <span>
            {isExpanded ? (
              <ChevronDownIcon
                boxSize="5"
                color="gray.600"
                onClick={toggleExpanded}
              />
            ) : (
              <ChevronRightIcon
                boxSize="5"
                color="gray.600"
                onClick={toggleExpanded}
              />
            )}
          </span>
        ),
        chackraProps: { width: 0 },
      },
      {
        value: nonCompliance?.settingName,
        field: 'settingName',
      },
      {
        value:
          nonCompliance?.nonComplianceReason &&
          intl.formatMessage({
            id: translateNonComplianceReason[
              nonCompliance?.nonComplianceReason
            ],
          }),
        field: 'nonComplianceReason',
      },
      {
        value:
          nonCompliance?.specificNonComplianceReason &&
          intl.formatMessage({
            id: translateSpecificNonComplianceReasonEnum[
              nonCompliance?.specificNonComplianceReason
            ],
          }),
        field: 'specificNonComplianceReason',
      },
      {
        isExpandedField: true,
        value: nonCompliance?.packageName,
        field: 'packageName',
      },
      {
        isExpandedField: true,
        value: nonCompliance?.fieldPath,
        field: 'fieldPath',
      },
      {
        isExpandedField: true,
        value: nonCompliance?.currentValue,
        field: 'currentValue',
      },
      {
        isExpandedField: true,
        value:
          nonCompliance?.installationFailureReason &&
          intl.formatMessage({
            id: translateInstallationFailureReasonEnum[
              nonCompliance?.installationFailureReason
            ],
          }),
        field: 'installationFailureReason',
      },
      {
        isExpandedField: true,
        value: nonCompliance?.wifiGuid,
        field: 'wifiGuid',
      },
      {
        isExpandedField: true,
        value:
          nonCompliance?.passwordPolicyScope &&
          intl.formatMessage({
            id: translatePasswordPolicyScopeEnum[
              nonCompliance?.passwordPolicyScope
            ],
          }),
        field: 'passwordPolicyScope',
      },
      {
        isExportHidden: true,
        value: null,
        field: 'expander',
        transform: () => <ListDrillDown nonCompliance={nonCompliance} />,
      },
    ],
  }));

  return (
    <>
      <PageTitle
        showManualIcon
        title={<FormattedMessage id="non_compliance.title" />}
        description={<FormattedMessage id="non_compliance.sub_title" />}
      />
      <CardHeader
        my="12"
        title={intl.formatMessage({ id: 'non_compliance.card_header.title' })}
        description={
          <SimpleGrid columns={[1, 1, 1, 3]} gap="6" p="2">
            <FormControl
              textLabel={<FormattedMessage id="non_compliance.form.user" />}
            >
              <Input
                inputProps={{
                  disabled: true,
                  defaultValue: form.user || '',
                }}
                isLoading={isLoading}
              />
            </FormControl>

            <FormControl
              textLabel={
                <FormattedMessage id="non_compliance.form.phoneNumber" />
              }
            >
              <Input
                inputProps={{
                  disabled: true,
                  defaultValue: form.phoneNumber || '',
                }}
                isLoading={isLoading}
              />
            </FormControl>

            <FormControl
              textLabel={<FormattedMessage id="non_compliance.form.imei" />}
            >
              <Input
                inputProps={{
                  disabled: true,
                  defaultValue: form.imei || '',
                }}
                isLoading={isLoading}
              />
            </FormControl>

            <FormControl
              textLabel={
                <FormattedMessage id="non_compliance.form.manufacturer" />
              }
            >
              <Input
                inputProps={{
                  disabled: true,
                  defaultValue: form.manufacturer || '',
                }}
                isLoading={isLoading}
              />
            </FormControl>

            <FormControl
              textLabel={<FormattedMessage id="non_compliance.form.model" />}
            >
              <Input
                inputProps={{
                  disabled: true,
                  defaultValue: form.model || '',
                }}
                isLoading={isLoading}
              />
            </FormControl>
          </SimpleGrid>
        }
      />
      <Table
        keyProp={InfoTypes.LIST_NON_COMPLIANCE}
        headerColumns={headerColumns}
        rows={rows}
        metadata={metadata}
        handleSort={handleMetadata}
        pageActionsProps={{
          initialSearch: filterSearch,
          onSearch: setFilterSearch,
          handleExportAllToExcel,
        }}
        nameTable={`${intl.formatMessage({ id: 'non_compliance.title' })}`}
      />
    </>
  );
};

export default DeviceNonCompliance;
