import { ThemeType } from '../theme';

const theme: ThemeType = {
  colors: {
    primary: {
      500: '#EB0028', // primary
      600: '#004691', // secondary
      900: '#002c5e', // shade of secondary
    },
  },
  images: {
    logo: 'https://storage.googleapis.com/enterprise-tim/assets/logo_login.png',
    logo_dashboard:
      'https://storage.googleapis.com/enterprise-tim/assets/logo_dashboard.png',
  },
};

export default theme;
