import { Box, Grid, GridItem } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import faqImageOne from '../../assets/Images/help/faq1.png';
// import faqImageTwo from '../../assets/Images/help/faq2.png';
import BadgeIcon from '../../components/Icons/Badge';
import UserIcon from '../../components/Icons/User';
import CardHeading from '../../components/pages/Dashboard/CardHeading';
import DashboardDataConsumptionChart from '../../components/pages/Dashboard/ConsumptionChart/DashboardDataConsumptionChart';
import DashboardSMSChart from '../../components/pages/Dashboard/ConsumptionChart/DashboardSMSChart';
import DashboardApplicationConsumptionBarChart from '../../components/pages/Dashboard/DashboardApplicationConsumptionBarChart';
import DashboardCard from '../../components/pages/Dashboard/DashboardCard';
import DashboardConsumptionHistory from '../../components/pages/Dashboard/DashboardConsumptionHistory';
import DashboardTimeBarChart from '../../components/pages/Dashboard/DashboardTimeBarChart';
import DashboardTotalUsersBarChart from '../../components/pages/Dashboard/DashboardTotalUsersBarChart';
import DashboardUserConsumptionBarChart from '../../components/pages/Dashboard/DashboardUserConsumptionBarChart';
import DashboardWebsiteVisitedBarChart from '../../components/pages/Dashboard/DashboardWebsiteVisitedBarChart';
import DashboardDevicesInventoryChart from '../../components/pages/Dashboard/DeviceInventoryChart/DashboardDevicesInventoryChart';
import DevicesCard from '../../components/pages/Dashboard/DevicesCard';
import { DevicesLocationMap } from '../../components/pages/Dashboard/DevicesLocationsMap';
import Filters from '../../components/pages/Dashboard/Filters';
import Header from '../../components/pages/Dashboard/Header';
import HelpCard from '../../components/pages/Help/FAQ/HelpCard';
import InformationCard from '../../components/pages/Help/FAQ/InformationCard';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import routes from '../../routes';
import { cleanFilters, Types } from '../../store/dashboard';

function Dashboard() {
  const { usersTotalHeading, notActivatedLicenses, totalLicenses } =
    useAppSelector((state) => state.dashboard);
  const { portalSettings } = useAppSelector((state) => state.portalSettings);
  const loadings = useAppSelector((state) => state.ui.loading);
  const dispatch = useAppDispatch();

  const iconBoxSize = 12;
  const iconColor = 'gray.600';

  useEffect(() => {
    return () => {
      dispatch(cleanFilters());
    };
  }, []);

  return (
    <Box>
      <Header />
      <Filters />
      <Grid templateColumns="repeat(12, 1fr)" gridColumnGap={6} gridRowGap={10}>
        <GridItem colSpan={4}>
          <CardHeading
            loading={loadings[Types.LIST_USERS]}
            title="dashboard.users_total_heading"
            icon={<UserIcon boxSize={iconBoxSize} color={iconColor} />}
            qty={usersTotalHeading}
            link={routes.device.manage}
            showOperationalSystems={portalSettings?.allowDeviceIOS}
          />
        </GridItem>

        <GridItem colSpan={4}>
          <CardHeading
            loading={loadings[Types.LIST_TOTAL_LICENSES]}
            title="dashboard.total_licenses"
            icon={<BadgeIcon boxSize={iconBoxSize} color={iconColor} />}
            qty={totalLicenses}
            link={routes.company.license}
            showOperationalSystems={portalSettings?.allowDeviceIOS}
          />
        </GridItem>

        <GridItem colSpan={4}>
          <CardHeading
            loading={loadings[Types.LIST_LICENSES]}
            title="dashboard.not_activated_licenses"
            icon={<BadgeIcon boxSize={iconBoxSize} color={iconColor} />}
            qty={notActivatedLicenses}
            qtyColor="warning.600"
            link={routes.company.licenseNotActivated}
            showOperationalSystems={portalSettings?.allowDeviceIOS}
          />
        </GridItem>

        <GridItem colSpan={4} height={'380px'}>
          <DevicesCard
            showOperationalSystems={portalSettings?.allowDeviceIOS}
          />
        </GridItem>
        <GridItem colSpan={4} height={'380px'}>
          <DashboardCard
            showActiveRoaming
            title={<FormattedMessage id="dashboard.data_consumption" />}
            showOperationalSystems={portalSettings?.allowDeviceIOS}
          >
            <DashboardDataConsumptionChart />
          </DashboardCard>
        </GridItem>
        <GridItem colSpan={4} height={'380px'}>
          <DashboardCard
            hideIOS={portalSettings?.allowDeviceIOS}
            showActiveRoaming
            title={<FormattedMessage id="dashboard.sms_consumption" />}
          >
            <DashboardSMSChart />
          </DashboardCard>
        </GridItem>
        <GridItem colSpan={6}>
          <DashboardCard
            title={
              <FormattedMessage id="dashboard.data_consumption_chart.app" />
            }
            routeToLink={routes.application.manage}
            hideIOS={portalSettings?.allowDeviceIOS}
          >
            <DashboardApplicationConsumptionBarChart />
          </DashboardCard>
        </GridItem>
        <GridItem colSpan={6}>
          <DashboardCard
            showOperationalSystems={portalSettings?.allowDeviceIOS}
            title={
              <FormattedMessage id="dashboard.data_consumption_chart.user" />
            }
            routeToLink={routes.company.consumption}
          >
            <DashboardUserConsumptionBarChart />
          </DashboardCard>
        </GridItem>
        <GridItem colSpan={6}>
          <DashboardCard
            title={
              <FormattedMessage id="dashboard.usage_time_by_application.title" />
            }
            routeToLink={routes.application.manage}
            hideIOS={portalSettings?.allowDeviceIOS}
          >
            <DashboardTimeBarChart />
          </DashboardCard>
        </GridItem>
        <GridItem colSpan={6}>
          <DashboardCard
            showOperationalSystems={portalSettings?.allowDeviceIOS}
            title={<FormattedMessage id="dashboard.devices_inventory" />}
          >
            <DashboardDevicesInventoryChart />
          </DashboardCard>
        </GridItem>
        <GridItem colSpan={12}>
          <DashboardCard
            showOperationalSystems={portalSettings?.allowDeviceIOS}
            title={
              <FormattedMessage id="dashboard.most_visited_websites.title" />
            }
          >
            <DashboardWebsiteVisitedBarChart />
          </DashboardCard>
        </GridItem>
        <GridItem colSpan={6}>
          <DashboardCard
            showActiveRoaming
            title={<FormattedMessage id="global.consumption" />}
          >
            <DashboardConsumptionHistory
              showOperationalSystems={portalSettings?.allowDeviceIOS}
            />
          </DashboardCard>
        </GridItem>
        <GridItem colSpan={6}>
          <DashboardCard
            showOperationalSystems={portalSettings?.allowDeviceIOS}
            title={<FormattedMessage id="global.totalUsers" />}
          >
            <DashboardTotalUsersBarChart />
          </DashboardCard>
        </GridItem>
        <GridItem colSpan={12}>
          <DevicesLocationMap
            showOperationalSystems={portalSettings?.allowDeviceIOS}
          />
        </GridItem>

        <GridItem colSpan={12}>
          <InformationCard
            urlImage={faqImageOne}
            title={<FormattedMessage id="faq.card_one.manuals.title" />}
            description={
              <FormattedMessage id="faq.card_one.manuals.description" />
            }
            link={routes.support}
          />
        </GridItem>
        {
          // <GridItem colSpan={6}>
          //   <InformationCard
          //     urlImage={faqImageTwo}
          //     title={<FormattedMessage id="faq.card_tow.tutorial_video_title" />}
          //     description={
          //       <FormattedMessage id="faq.card_tow.tutorial_video_description" />
          //     }
          //   />
          // </GridItem>
        }
        <GridItem colSpan={12}>
          <HelpCard />
        </GridItem>
      </Grid>
    </Box>
  );
}

export default Dashboard;
