import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import DatePicker from '../../../components/Datepicker';
import FormContainer from '../../../components/FormContainer';
import FormControl from '../../../components/FormControl';
import PageActions from '../../../components/PageActions';
import PageFilter from '../../../components/PageFilter';
import PageLoadMore from '../../../components/PageLoadMore';
import PageTitle from '../../../components/PageTitle';
import SelectAutocomplete from '../../../components/SelectAutocomplete';
import { Timeline } from '../../../components/Timeline';
import { useFormatDate } from '../../../hooks/useFormatDate';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import { listDeviceUserToFilter } from '../../../store/deviceUser';
import { generateExcel } from '../../../store/event';
import {
  listDataFirst,
  listDataMore,
  reportsSitesDateListClean,
  Types,
} from '../../../store/reports';
import {
  hasSomeEmpty,
  hasSomeLoading,
  hasTable,
  selectorMetadata,
  uiRemoveTable,
  uiShowTable,
} from '../../../store/ui';

const ReportDate = () => {
  const dispatch = useAppDispatch();
  const { sitesDate, sitesDateHistory } = useAppSelector(
    (state) => state.reports
  );
  const { devicesUsersToFilter } = useAppSelector((state) => state.deviceUser);
  const { portalSettings } = useAppSelector((state) => state.portalSettings);
  const { handleFormatDateByUserLogged } = useFormatDate();

  const metadataDate = useAppSelector(selectorMetadata(Types.SITES_DATE));
  const isLoading = hasSomeLoading([Types.SITES_DATE, Types.SITES_DATE_FIRST]);
  const showTimeline = hasTable(Types.SITES_DATE);

  const [form, setForm] = useState({ user: null, accessedAt: null });
  const [lastFilter, setLastFilter] = useState({
    userId: null,
    accessedAt: null,
  });

  const isListEmpty = hasSomeEmpty([Types.SITES_DATE_FIRST, Types.SITES_DATE]);

  const intl = useIntl();

  const allFilters = {
    userId: form.user?.id,
    accessedAt: form?.accessedAt,
  };

  useEffect(() => {
    dispatch(listDeviceUserToFilter());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(uiRemoveTable(Types.SITES_DATE));
    };
  }, []);

  const handleFilter = () => {
    if (form.user?.id) {
      setLastFilter({
        userId: form.user?.id,
        accessedAt: form?.accessedAt,
      });
      dispatch(listDataFirst(metadataDate, allFilters));
      dispatch(uiShowTable(Types.SITES_DATE));
    } else {
      dispatch(reportsSitesDateListClean());
    }
  };

  const handleDateSelect = (field) => (value) => {
    setForm({ ...form, [field]: value });
  };

  const handleLoadMore = () => {
    dispatch(
      listDataMore(
        { ...metadataDate, page: +metadataDate.page + 1 },
        allFilters
      )
    );
  };

  const handleDeviceUsers = (value) => {
    setForm({ ...form, user: value });
  };

  const handleDeviceUsersInput = (value) => {
    dispatch(listDeviceUserToFilter({ search: value }));
  };

  const handleExportAllToExcel = () => {
    dispatch(
      generateExcel({
        type: Types.SITES_DATE,
        metadata: metadataDate,
        filters: lastFilter,
      })
    );
  };

  const headers = [
    {
      header: intl.formatMessage({ id: 'date_report.header.user' }),
      accessor: 'user',
    },
    {
      header: intl.formatMessage({ id: 'date_report.header.phoneNumber' }),
      accessor: 'phoneNumber',
    },
    {
      header: intl.formatMessage({ id: 'date_report.header.domain' }),
      accessor: 'domain',
    },
    {
      header: intl.formatMessage({ id: 'date_report.header.accessedAt' }),
      accessor: 'accessedAt',
    },
  ];

  const rows = sitesDateHistory.map((history) => ({
    cells: [
      {
        field: 'user',
        value: history.device.deviceUser.name,
      },
      {
        field: 'phoneNumber',
        value: history.device.phoneNumber,
      },
      {
        field: 'domain',
        value: history.domain,
      },
      {
        field: 'accessedAt',
        value: handleFormatDateByUserLogged(history.accessedAt),
      },
    ],
  }));

  return (
    <>
      <PageTitle
        showManualIcon
        title={<FormattedMessage id="date_report.title" />}
        description={<FormattedMessage id="date_report.title_text" />}
        showOperationalSystems={portalSettings.allowDeviceIOS}
      />
      <FormContainer
        labelFilter={<FormattedMessage id="global.search" />}
        handleFilter={handleFilter}
        disabledFilter={!form.user}
      >
        <PageFilter>
          <FormControl
            textLabel={<FormattedMessage id="date_report.select_user" />}
          >
            <SelectAutocomplete
              options={devicesUsersToFilter}
              value={form.user}
              onChange={handleDeviceUsers}
              onInputChange={handleDeviceUsersInput}
              getOptionLabel={(option) =>
                `${option?.name || ''} ${option?.device.phoneNumber || ''}`
              }
              placeholder={<FormattedMessage id="sites_url.field_user" />}
            />
          </FormControl>
          <FormControl textLabel={<FormattedMessage id="global.date" />}>
            <DatePicker
              name="date"
              selected={form.accessedAt}
              onChange={handleDateSelect('accessedAt')}
              hasValue={form.accessedAt}
              maxDate={new Date()}
            />
          </FormControl>
        </PageFilter>
      </FormContainer>
      {showTimeline && (
        <>
          <PageActions
            excel={{
              name: `${intl.formatMessage({ id: 'date_report.title' })}`,
              tableData: rows,
              tableColumns: headers,
              tableMetadata: metadataDate,
              handleExportAllToExcel: handleExportAllToExcel,
            }}
            isEmptyExportActions={isListEmpty}
            emptyExportActionsMessage="table.action.empty_export_message"
          />
          <Box display="flex" alignItems="center" mt="60px">
            <Timeline
              rows={sitesDate}
              isLoading={isLoading}
              hasSomeUserSelected={!!form.user?.id && sitesDate?.length === 0}
            />
          </Box>
        </>
      )}
      {sitesDate?.length > 0 && metadataDate.page < metadataDate.totalPages && (
        <PageLoadMore handleLoadMore={handleLoadMore} />
      )}
    </>
  );
};

export default ReportDate;
