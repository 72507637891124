import { As, Badge, Flex, Icon } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import {
  NotificationStatusCodeEnum,
  NotificationType,
  NotificationTypeCodeEnum,
} from '../../types/notification';
import { FormatDateByUserLogged } from '../FormatDateByUserLogged';
import CircleCheckMarkIcon from '../Icons/CircleCheckMark';
import CircleWarningIcon from '../Icons/CircleWarning';
import Command from '../Icons/Command';
import DocumentIcon from '../Icons/Documents';
import Download from '../Icons/Download';
import Text from '../Text';
import {
  colorByStatusCode,
  importNotificationErrorToIntl,
  NotificationDescriptionByTypeCodeType,
  notificationTitleToIntl,
} from './utils';

interface NotificationDescriptionProps {
  notification: NotificationType;
}

const NotificationDescription = ({
  notification,
}: NotificationDescriptionProps) => {
  const hasError = notification.statusCode === NotificationStatusCodeEnum.ERROR;
  const isHighLight = !notification.visualized && !hasError;

  const notificationDescriptionByTypeCode: Record<
    NotificationTypeCodeEnum,
    NotificationDescriptionByTypeCodeType
  > = {
    [NotificationTypeCodeEnum.REPORT]: {
      listTitle: 'notification.list.title',
      buttonUrlTitle: 'notification.list.button.url',
      prefixIcon: DocumentIcon,
      errorTitle: 'notification.list.error',
    },
    [NotificationTypeCodeEnum.IMPORT]: {
      listTitle: 'notification.import_list.title',
      buttonUrlTitle: 'notification.list.import_button.url',
      prefixIcon: Download,
      errorTitle:
        importNotificationErrorToIntl[notification?.data] ||
        'notification.list.button.url',
    },
    [NotificationTypeCodeEnum.COMMAND]: {
      listTitle: 'notification.command.title',
      buttonUrlTitle: 'notification.list.command.url',
      prefixIcon: Command,
      errorTitle: 'notification.list.command.error',
    },
  };

  const iconStatusNotificationByStatusCode: Record<
    NotificationStatusCodeEnum,
    As<Element>
  > = {
    [NotificationStatusCodeEnum.WARNING]: CircleWarningIcon,
    [NotificationStatusCodeEnum.COMPLETE]: CircleCheckMarkIcon,
    [NotificationStatusCodeEnum.ERROR]: CircleWarningIcon,
    [NotificationStatusCodeEnum.IN_PROGRESS]: CircleCheckMarkIcon,
  };

  return (
    <Flex py={4} justify="space-between" w="full" gridGap={2}>
      <Icon
        as={notificationDescriptionByTypeCode[notification.typeCode].prefixIcon}
        boxSize={12}
      />
      <Flex direction="column" w="full" justify="flex-start" gridGap={1}>
        <Flex gridGap={2} align="center">
          <Text m="0" fontWeight="bold">
            <FormattedMessage
              id={
                notificationDescriptionByTypeCode[notification.typeCode]
                  .listTitle
              }
            />{' '}
            <FormattedMessage
              id={
                notificationTitleToIntl[notification?.title]
                  ? notificationTitleToIntl[notification?.title]
                  : 'notification.not_found_key'
              }
            />
          </Text>
          {isHighLight && (
            <Badge fontSize="0.8em" colorScheme="primary">
              <FormattedMessage id="notification.badge.not_read.title" />
            </Badge>
          )}
        </Flex>

        <Text m="0" color="gray.50">
          <FormatDateByUserLogged date={notification?.createdAt} />
        </Text>

        {!hasError ? (
          <Flex align="center" gridGap={2}>
            <Icon
              as={iconStatusNotificationByStatusCode[notification.statusCode]}
              color={colorByStatusCode[notification.statusCode]}
            />
            <Text
              m="0"
              color={colorByStatusCode[notification.statusCode]}
              fontWeight="400"
            >
              <FormattedMessage
                id={
                  notificationDescriptionByTypeCode[notification.typeCode]
                    .buttonUrlTitle
                }
              />
            </Text>
          </Flex>
        ) : (
          <Text m="0" color="red.500" fontWeight="400">
            <FormattedMessage
              id={
                notificationDescriptionByTypeCode[notification.typeCode]
                  .errorTitle
              }
            />
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default NotificationDescription;
