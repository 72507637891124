import { Box } from '@chakra-ui/layout';
import { IntlShape } from 'react-intl';

import { customMatch } from '../../../helper/router';
import { useFormatDate } from '../../../hooks/useFormatDate';
import routes from '../../../routes';
import { DocumentType } from '../../../types/document';
import { ExternalAppType } from '../../../types/externalApps';
import { MessageType } from '../../../types/message';
import { RemoteAppType } from '../../../types/remoteApps';
import { BodyCell } from '../../Table/TableInterfaces';

export enum Screens {
  MESSAGES = 'MESSAGES',
  DOCUMENTS = 'DOCUMENTS',
  EXTERNAL_APPS = 'EXTERNAL_APPS',
  REMOTE_APPS = 'REMOTE_APPS',
}

export const getScreenByPath = (path: string): Screens => {
  const verifyMatch = (routeToCompare: string) =>
    customMatch(path, routeToCompare);

  const matchDocuments = [
    routes.documents.register,
    routes.documents.details,
    routes.documents.list,
  ];

  if (matchDocuments.some(verifyMatch)) return Screens.DOCUMENTS;

  const matchExternalApp = [
    routes.externalApps.register,
    routes.externalApps.details,
    routes.externalApps.list,
  ];

  if (matchExternalApp.some(verifyMatch)) return Screens.EXTERNAL_APPS;

  const matchRemoteApp = [
    routes.remoteApps.register,
    routes.remoteApps.details,
    routes.remoteApps.list,
  ];

  if (matchRemoteApp.some(verifyMatch)) return Screens.REMOTE_APPS;

  return Screens.MESSAGES;
};

export const listColumnByScreen = (
  intl: IntlShape,
  keysIntl: Record<string, string>
) => ({
  default: [
    {
      header: intl.formatMessage({
        id: keysIntl.column_date,
      }),
      accessor: 'createdAt',
      canSort: true,
    },
    {
      header: intl.formatMessage({
        id: keysIntl.column_message,
      }),
      accessor: 'content',
      canSort: true,
    },
  ],
  [Screens.EXTERNAL_APPS]: [
    {
      header: intl.formatMessage({
        id: 'external_apps.column.name',
      }),
      accessor: 'name',
      canSort: true,
    },
    {
      header: intl.formatMessage({
        id: 'external_apps.column.package_name',
      }),
      accessor: 'packageName',
      canSort: true,
    },
    {
      header: intl.formatMessage({
        id: 'external_apps.column.version',
      }),
      accessor: 'versionName',
      canSort: false,
    },
    {
      header: intl.formatMessage({
        id: 'external_apps.column.version_code',
      }),
      accessor: 'versionCode',
      canSort: false,
    },
    {
      header: intl.formatMessage({
        id: 'external_apps.column.url',
      }),
      accessor: 'downloadUrl',
      canSort: false,
    },
    {
      header: intl.formatMessage({
        id: 'external_apps.column.updated_at',
      }),
      accessor: 'updatedAt',
      canSort: true,
    },
  ],
  [Screens.REMOTE_APPS]: [
    {
      header: intl.formatMessage({
        id: keysIntl.column_date,
      }),
      accessor: 'createdAt',
      canSort: true,
    },
    {
      header: intl.formatMessage({
        id: 'remoteApp.column.name',
      }),
      accessor: 'name',
      canSort: false,
    },
    {
      header: intl.formatMessage({
        id: 'remoteApp.column.packageName',
      }),
      accessor: 'packageName',
      canSort: false,
    },
    {
      header: intl.formatMessage({
        id: 'remoteApp.column.url',
      }),
      accessor: 'url',
      canSort: false,
    },
  ],
});

export const listRowsByScreen = (
  obj: MessageType & DocumentType & ExternalAppType & RemoteAppType,
  handleFormatDateByUserLogged: ReturnType<
    typeof useFormatDate
  >['handleFormatDateByUserLogged']
): Partial<Record<Screens | 'default', BodyCell[]>> => ({
  default: [
    {
      field: 'createdAt',
      value: handleFormatDateByUserLogged(obj?.createdAt),
    },
    {
      field: 'content',
      value: obj?.content,
    },
  ],
  [Screens.EXTERNAL_APPS]: [
    {
      field: 'name',
      value: obj?.name,
    },
    {
      field: 'packageName',
      value: obj?.packageName,
    },
    {
      field: 'versionName',
      value: obj?.versionName,
    },
    {
      field: 'versionCode',
      value: obj?.versionCode,
    },
    {
      field: 'url',
      transform: () => (
        <Box maxW={{ xxl: '800px', xl: '500px', lg: '300px' }}>
          {obj?.downloadUrl}
        </Box>
      ),
      value: obj?.downloadUrl,
    },
    {
      field: 'updatedAt',
      value: handleFormatDateByUserLogged(obj?.updatedAt),
    },
  ],
  [Screens.REMOTE_APPS]: [
    {
      field: 'createdAt',
      value: handleFormatDateByUserLogged(obj.createdAt),
    },
    {
      field: 'name',
      value: obj.name,
    },
    {
      field: 'packageName',
      value: obj.packageName,
    },
    {
      field: 'url',
      transform: () => (
        <Box maxW={{ xxl: '800px', xl: '500px', lg: '300px' }}>{obj.url}</Box>
      ),
      value: obj.url,
    },
  ],
});
