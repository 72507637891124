import { Flex } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { operationalSystemToIntl } from '../../../../../pages/Policies/EditPolicies';
import { editDevicePolicy, Types } from '../../../../../store/device';
import { listPoliciesToSelect } from '../../../../../store/policy';
import { hasLoading, hasSuccess } from '../../../../../store/ui';
import { DeviceType, OperationalSystemEnum } from '../../../../../types/device';
import { PolicyToSelectType } from '../../../../../types/policy';
import FormContainer from '../../../../FormContainer';
import FormControl from '../../../../FormControl';
import Modal, { ModalBody, ModalHeader } from '../../../../Modal';
import PageErrors from '../../../../PageErrors';
import SelectAutocomplete from '../../../../SelectAutocomplete';
import Text from '../../../../Text';

interface ModalPolicyType {
  isOpen: boolean;
  closeModal?: () => void;
  selectedDevice: DeviceType;
}

const ModalPolicyDevice = ({
  isOpen,
  closeModal,
  selectedDevice,
}: ModalPolicyType) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const success = hasSuccess(Types.EDIT_POLICY);
  const isLoading = hasLoading(Types.EDIT_POLICY);

  const { policiesToFilter } = useAppSelector((state) => state.policy);
  const [selectDevicePolicy, setSelectDevicePolicy] =
    useState<PolicyToSelectType>();

  const allFilters = {
    operationalSystem: selectedDevice?.operationalSystem,
    managementMode:
      selectedDevice?.operationalSystem === OperationalSystemEnum.ANDROID
        ? selectedDevice?.policy?.managementMode
        : null,
  };

  const handleFilterPhone = (newFilter: PolicyToSelectType) => {
    setSelectDevicePolicy(newFilter);
    dispatch(listPoliciesToSelect(allFilters));
  };

  const handleFilterPhoneChange = (value) => {
    dispatch(
      listPoliciesToSelect({
        ...allFilters,
        search: value,
      })
    );
  };

  useEffect(() => {
    dispatch(listPoliciesToSelect(allFilters));
  }, []);

  useEffect(() => {
    if (success) {
      closeModal();
    }
  }, [success]);

  const handleChangeConfirmation = () => {
    dispatch(
      editDevicePolicy({
        deviceId: selectedDevice.id,
        policy: {
          policyId: selectDevicePolicy?.id,
        },
      })
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={() => closeModal()} size="xl">
      <ModalHeader>
        <Text>
          <FormattedMessage id="device.modal.update_policy" />
        </Text>
      </ModalHeader>
      <ModalBody>
        <Flex h="100%" flexDirection="column" justify="space-around" w="100%">
          <PageErrors
            toasterKeys={[Types.EDIT_POLICY]}
            translateKey="register_policies"
          />
          <FormContainer
            labelPrimary={<FormattedMessage id={'global.update'} />}
            handlePrimary={handleChangeConfirmation}
            disabledPrimary={!selectDevicePolicy}
            labelSecondary={<FormattedMessage id={'global.cancel'} />}
            handleSecondary={() => closeModal()}
            loadingPrimary={isLoading}
          >
            <FormControl
              textLabel={
                <FormattedMessage
                  id="device.modal.label"
                  values={{
                    managedMode: intl.formatMessage({
                      id: operationalSystemToIntl[
                        selectedDevice?.operationalSystem
                      ],
                    }),
                  }}
                />
              }
              mb="6"
            >
              <SelectAutocomplete
                options={policiesToFilter}
                value={selectDevicePolicy}
                onChange={handleFilterPhone}
                onInputChange={handleFilterPhoneChange}
              />
            </FormControl>
          </FormContainer>
        </Flex>
      </ModalBody>
    </Modal>
  );
};

export default ModalPolicyDevice;
