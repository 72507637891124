import { Flex, useTheme } from '@chakra-ui/react';
import { formatBytesTo } from 'portal-lib';
import { FormattedMessage } from 'react-intl';

import { useAppSelector } from '../../../hooks/useRedux';
import { Types } from '../../../store/dashboard';
import { selectorLoading } from '../../../store/ui';
import ChartBar from '../../ChartBar';
import ChartLoading from '../../ChartLoading';
import Text from '../../Text';

const DashboardUserConsumptionBarChart = () => {
  const { dataConsumptionUser } = useAppSelector((state) => state.dashboard);
  const { user } = useAppSelector((state) => state.auth);
  const isLoading = useAppSelector(
    selectorLoading(Types.LIST_DEVICES_LOCATION)
  );

  const { colors } = useTheme();

  const hasData = dataConsumptionUser?.data.length > 0;

  const total = dataConsumptionUser?.total || Number.MAX_SAFE_INTEGER;

  const dataChart = dataConsumptionUser?.data
    ?.map((dataBarChart) => {
      const consumption = Number(dataBarChart?.total);
      return {
        indexBy: dataBarChart?.phone,
        consumptionPercentage:
          total > 0 ? ((consumption * 100) / total).toFixed(1) : '0',
        consumption,
      };
    })
    .reverse();

  if (isLoading) {
    return <ChartLoading color="chart.50" />;
  }

  return (
    <Flex align="center" justify="center" h="400px">
      {!hasData ? (
        <Text>
          <FormattedMessage id="dashboard.usage_time_by_application.empty_chart" />
        </Text>
      ) : (
        <ChartBar
          data={dataChart}
          color={colors['chart']['50']}
          keys={['consumptionPercentage']}
          formatRight={(chartValue) => {
            const dataConsumptionLegend = dataChart?.find(
              (consumption) => consumption?.indexBy === chartValue
            );
            return (
              <>
                <tspan x="0" dy="-0.7em" fill={colors['gray']['900']}>
                  {formatBytesTo({
                    bytes: dataConsumptionLegend?.consumption,
                    decimals: 2,
                  })}
                </tspan>
                <tspan x="0" dy="1.2em" fill={colors['chart']['300']}>
                  {parseFloat(
                    dataConsumptionLegend?.consumptionPercentage
                  ).toLocaleString(user?.language)}
                  %
                </tspan>
              </>
            );
          }}
          formatTop={(data) => {
            return `${data}%`;
          }}
          formatLeft={(chatValue) => {
            const dataConsumptionLegend = dataChart?.find(
              (dataConsumption) => dataConsumption?.indexBy === chatValue
            );
            return (
              <tspan x="0" dy="0em" fill={colors['chart']['300']}>
                {dataConsumptionLegend?.indexBy}
              </tspan>
            );
          }}
        />
      )}
    </Flex>
  );
};

export default DashboardUserConsumptionBarChart;
