import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useAppSelector } from '../../hooks/useRedux';
import { ManagementModeEnum } from '../../types/policy';
import SelectAutocomplete from '../SelectAutocomplete';

export interface ManagementModeOption {
  label: string;
  value: ManagementModeEnum;
}

interface Props {
  value?: ManagementModeOption;
  onChange?: (value: ManagementModeOption) => void;
  name?: string;
}

export const ManagementModeSelect = ({ name, onChange, value }: Props) => {
  const intl = useIntl();
  const { portalSettings } = useAppSelector((state) => state.portalSettings);

  const fillOptions = useMemo(() => {
    const options = [
      {
        label: intl.formatMessage({
          id: 'manage_policies.filter.management_mode.android',
        }),
        value: ManagementModeEnum.MANAGED_ANDROID,
        isHidden: false,
      },
      {
        label: intl.formatMessage({
          id: 'manage_policies.filter.management_mode.block_sim',
        }),
        value: ManagementModeEnum.BLOCK_SIM_ANDROID,
        isHidden: !portalSettings.allowAppBlockSim,
      },
      {
        label: intl.formatMessage({
          id: 'manage_policies.filter.management_mode.work_profile_android',
        }),
        value: ManagementModeEnum.WORK_PROFILE_ANDROID,
        isHidden: !portalSettings.allowWorkProfile,
      },
      {
        label: intl.formatMessage({
          id: 'manage_policies.filter.management_mode.ios',
        }),
        value: ManagementModeEnum.MANAGED_IOS,
        isHidden: !portalSettings.allowDeviceIOS,
      },
      {
        label: intl.formatMessage({
          id: 'manage_policies.filter.management_mode.ios_supervised',
        }),
        value: ManagementModeEnum.IOS_SUPERVISED,
        isHidden: !portalSettings.allowDeviceIOS,
      },
    ];

    return options.filter((opt) => !opt.isHidden);
  }, []);

  return (
    <SelectAutocomplete
      options={fillOptions}
      value={value}
      name={name}
      onChange={onChange}
      getOptionValue={(option) => option.value}
      getOptionLabel={(option) => option.label}
      placeholder={intl.formatMessage({
        id: 'manage_policies.filter.placeholder',
      })}
    />
  );
};
