import { convertBytesTo } from 'portal-lib';
import { FormattedMessage, useIntl } from 'react-intl';

import { formatDateByUserLogged } from '../../../../helper/date';
import { useAppSelector } from '../../../../hooks/useRedux';
import { DeviceInfoStorageType } from '../../../../types/deviceInfo';
import ChartLine from '../../../ChartLine';
import ChartTooltip from '../../../ChartTooltip';
import Text from '../../../Text';

interface DeviceStorageChartProps {
  storageData?: DeviceInfoStorageType[];
}
const GB = 'GB';

function DeviceStorageChart({ storageData }: DeviceStorageChartProps) {
  const { user } = useAppSelector((state) => state.auth);
  const { portalSettings } = useAppSelector((state) => state.portalSettings);
  const intl = useIntl();
  const chartData = [
    {
      id: 'storage',
      data: storageData.map((item) => ({
        ...item,
        x: formatDateByUserLogged(
          item.date,
          user,
          portalSettings,
          undefined,
          'short'
        ),
        y: convertBytesTo({ bytes: item.bytes, to: GB }).bytes,
      })),
    },
  ];

  return (
    <ChartLine
      chartData={chartData}
      yFormat={(value) => value + GB}
      enablePointLabel={false}
      theme={{
        fontSize: 12,
      }}
      tooltip={({ point }) => (
        <ChartTooltip color={'primary.500'}>
          <Text>
            <FormattedMessage id="storage.chart_storage" />:
            {point.data.yFormatted}
          </Text>

          <Text>
            <FormattedMessage id="battery.chart_date" />:{point.data.xFormatted}
          </Text>
        </ChartTooltip>
      )}
      axisLeft={{
        legend: intl.formatMessage({
          id: 'storage.chart_storage',
        }),
        legendPosition: 'middle',
        legendOffset: -60,
        format: (value) => value + GB,
      }}
      axisBottom={{
        legend: intl.formatMessage({
          id: 'storage.chart_date',
        }),
        legendPosition: 'middle',
        legendOffset: 50,
      }}
    />
  );
}

export default DeviceStorageChart;
