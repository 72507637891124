import {
  Grid,
  GridItem,
  Box,
  Flex,
  Link as ChakraLink,
  useTheme,
} from '@chakra-ui/react';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Select from '../../components/Select';
import Text from '../../components/Text';
import { validatorUrl } from '../../helper/validador';
import { useAppDispatch } from '../../hooks/useRedux';
import routes from '../../routes';
import { changeLoginLanguage } from '../../store/auth';

interface LayoutPublicProps {
  children: ReactNode;
}

const LayoutPublic = ({ children }: LayoutPublicProps) => {
  const { REACT_APP_PORTAL_PRIVACY_POLICY, REACT_APP_DEFAULT_LANGUAGE } =
    process.env;
  const privacyPolicy = validatorUrl(REACT_APP_PORTAL_PRIVACY_POLICY)
    ? REACT_APP_PORTAL_PRIVACY_POLICY
    : routes.terms.privacyPolicy;
  const { images } = useTheme();
  const portalLanguage = localStorage.getItem('portalLanguage') || null;
  const disableLanguageChange = useMemo(
    () => process.env['REACT_APP_ENABLE_LANGUAGE_CHANGE'] === 'false',
    []
  );

  const [language, setLanguage] = useState<string>(
    JSON.parse(portalLanguage) || REACT_APP_DEFAULT_LANGUAGE
  );

  const dispatch = useAppDispatch();

  const inputValuesData = [
    {
      label: 'PT-BR',
      id: 'pt-br',
    },
    { label: 'EN', id: 'en' },
    { label: 'ES', id: 'es' },
  ];

  const handleChangeLanguage = (e) => {
    setLanguage(e.target.value);
  };

  useEffect(() => {
    localStorage.setItem('portalLanguage', JSON.stringify(language));
    dispatch(changeLoginLanguage(language));
  }, [language]);

  return (
    <Grid h="100vh" templateColumns="repeat(auto-fit, minmax(100px, 1fr))">
      <GridItem rowStart={1} rowEnd={7} colSpan={4} bg="white">
        <Flex
          flexDir="column"
          justifyContent="space-between"
          h="100%"
          pl="20%"
          pr="10%"
        >
          {children}
          <Box pb="25px">
            <Text
              fontSize="xs"
              lineHeight="40px"
              textAlign="left"
              color="primary.500"
              cursor="pointer"
            >
              <ChakraLink href={privacyPolicy} isExternal>
                <FormattedMessage id="login.privacy_warning" />
              </ChakraLink>
            </Text>
          </Box>
        </Flex>
      </GridItem>
      <GridItem rowStart={1} rowEnd={7} colSpan={8} bg="papayawhip">
        <Box
          h="100%"
          backgroundImage={images?.backgroundLogin}
          backgroundColor="primary.600"
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          backgroundPosition="50% 50%"
        >
          {!disableLanguageChange && (
            <Flex position="absolute" right="50px" top="50px">
              <Select
                name="language"
                onChange={(e) => handleChangeLanguage(e)}
                value={language}
                backgroundColor="white"
                size="xs"
                fontSize="md"
              >
                {inputValuesData.map((language) => (
                  <option key={language.id} value={language.id}>
                    {language.label}
                  </option>
                ))}
              </Select>
            </Flex>
          )}
        </Box>
      </GridItem>
    </Grid>
  );
};

export default LayoutPublic;
