import { Box, Flex, Switch, Tooltip } from '@chakra-ui/react';
import { useIntl, FormattedMessage } from 'react-intl';

import { featurePlanKeys } from '../../../helper/plan';
import { usePlan } from '../../../hooks/usePlan';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import { dashboardUpdateFilters } from '../../../store/dashboard';
import Heading from '../../Heading';
import ManualLink from '../../ManualLink';
import Text from '../../Text';

function Header() {
  const dispatch = useAppDispatch();

  const { name } = useAppSelector((state) => state.auth.user);
  const { filters } = useAppSelector((state) => state.dashboard);
  const { hasFeaturePlan } = usePlan({
    featurePlanKey: featurePlanKeys.allowRoaming,
  });
  const intl = useIntl();

  const greeting =
    new Date().getHours() < 12
      ? 'dashboard.good_morning'
      : new Date().getHours() < 18
      ? 'dashboard.good_afternoon'
      : 'dashboard.good_evening';

  const handleRoaming = (checked) => {
    dispatch(dashboardUpdateFilters({ ...filters, roaming: checked }));
  };

  return (
    <>
      <Flex justify="space-between" alignItems="center">
        <Heading>
          {intl.formatMessage({ id: greeting })}, {name}
        </Heading>
        <Flex>
          <Tooltip
            isDisabled={hasFeaturePlan}
            placement="left-start"
            label={intl.formatMessage({ id: 'dashboard.roaming_disabled' })}
            hasArrow
          >
            <Box>
              <Switch
                id="roaming"
                mr="10px"
                isDisabled={!hasFeaturePlan}
                onChange={(e) => handleRoaming(e.target.checked)}
              />
            </Box>
          </Tooltip>
          <Box
            as="span"
            fontSize="14px"
            letterSpacing="0.56px"
            color="gray.500"
          >
            <FormattedMessage id="dashboard.roaming" />
          </Box>
        </Flex>
      </Flex>
      <Flex justifyContent="space-between">
        <Text>
          <FormattedMessage id="dashboard.description" />
        </Text>

        <ManualLink />
      </Flex>
    </>
  );
}

export default Header;
