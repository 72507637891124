import { SimpleGrid, Tooltip, Flex } from '@chakra-ui/react';
import { ChangeEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Alert, { AlertHtml } from '../../../components/Alert';
import FormContainer from '../../../components/FormContainer';
import FormControl from '../../../components/FormControl';
import FormSubtitle from '../../../components/FormSubtitle';
import AlertIcon from '../../../components/Icons/Alert';
import Input from '../../../components/Input';
import PageErrors from '../../../components/PageErrors';
import CompanyInfoModal from '../../../components/pages/Companies/CompanyInfo/companyInfoModal';
import PageShowToaster from '../../../components/PageShowToaster';
import PageTitle from '../../../components/PageTitle';
import { getCallbackRegisterUrl } from '../../../helper';
import { usePrivilege } from '../../../hooks/usePrivilege';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import routes from '../../../routes';
import { logout } from '../../../store/auth';
import {
  deleteCompany,
  editCompany,
  listCompanyInfo,
  sendUrlCallback,
  Types,
} from '../../../store/company';
import { hasError, selectorLoading } from '../../../store/ui';
import {
  CompanyInfoResponseErrorEnum,
  CompanyType,
} from '../../../types/company';

const CompanyInfo = () => {
  const dispatch = useAppDispatch();
  const { company } = useAppSelector((state) => state.company);
  const { portalSettings } = useAppSelector((state) => state.portalSettings);
  const { enterpriseId: hasCompany } = useAppSelector(
    (state) => state.auth.user.company
  );
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState<CompanyType & { oldEmail?: string }>({});
  const intl = useIntl();
  const { isHelpDesk } = usePrivilege();

  const isLoading = useAppSelector(selectorLoading(Types.EDIT));
  const errorConfirm = hasError(Types.EDIT);
  const isMissingToken =
    errorConfirm?.message === CompanyInfoResponseErrorEnum.MISSING_TOKEN;

  const isModifiedEmail = form?.oldEmail !== form?.email;

  useEffect(() => {
    if (isModifiedEmail && isMissingToken) {
      setOpen(true);
    }
  }, [errorConfirm]);

  useEffect(() => {
    dispatch(listCompanyInfo());
  }, []);

  useEffect(() => {
    if (company) {
      setForm({
        passCode: company?.passCode,
        federalCode: company?.federalCode,
        name: company?.corporateName,
        email: company?.email,
        contact: company?.contact,
        phoneNumber: company?.phoneNumber,
        zipCode: company?.zipCode,
        address: company?.address,
        city: company?.city,
        state: company?.state,
        dataProtectionOfficerName: company.dataProtectionOfficerName,
        dataProtectionOfficerEmail: company.dataProtectionOfficerEmail,
        dataProtectionOfficerPhone: company.dataProtectionOfficerPhone,
        euRepresentativeName: company.euRepresentativeName,
        euRepresentativeEmail: company.euRepresentativeEmail,
        euRepresentativePhone: company.euRepresentativePhone,
        oldEmail: company?.email,
        contentTokenABM: company.contentTokenABM,
      });
    }
  }, [company]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const submit = () => {
    dispatch(editCompany(form));
  };

  const handleCreate = () => {
    dispatch(
      sendUrlCallback({
        callbackUrl: getCallbackRegisterUrl(routes),
      })
    );
  };

  const handleDeleteCompany = () => {
    Alert({
      onCancel: () => {
        dispatch(deleteCompany());
        dispatch(logout());
      },
      html: (
        <AlertHtml
          irreversible={intl.formatMessage({
            id: 'devices.alert.irreversible',
          })}
          text={intl.formatMessage({
            id: 'company_info.delete_alert',
          })}
        />
      ),
      confirmButtonText: intl.formatMessage({
        id: 'global.cancel',
      }),
      allowOutsideClick: false,
      allowEscapeKey: false,
      cancelButtonText: intl.formatMessage({ id: 'global.confirm' }),
    });
  };

  return (
    <>
      <PageTitle
        showManualIcon
        title={
          <FormattedMessage
            id={hasCompany ? 'company_info.title' : 'company_register.title'}
          />
        }
        description={
          <FormattedMessage
            id={
              hasCompany
                ? 'company_info.description'
                : 'company_register.subtitle'
            }
          />
        }
      />
      <PageShowToaster toasterKeys={[Types.EDIT]} disabledError />
      {!open && !isMissingToken && (
        <PageErrors
          toasterKeys={[Types.EDIT, Types.NEW_EMAIL_TOKEN]}
          translateKey="company_info"
        />
      )}
      <FormContainer
        labelPrimary={
          <FormattedMessage
            id={hasCompany ? 'global.update' : 'global.register'}
          />
        }
        handlePrimary={hasCompany ? submit : handleCreate}
        loadingPrimary={isLoading}
        disabledPrimary={!form?.email}
        disabledSecondary={isHelpDesk}
        labelSecondary={<FormattedMessage id="company_info.delete_company" />}
        handleSecondary={handleDeleteCompany}
      >
        <SimpleGrid columns={{ md: 1, lg: 3 }} gap="6" p="2">
          <FormControl textLabel={<FormattedMessage id="company_info.name" />}>
            <Input
              inputProps={{
                isDisabled: hasCompany,
                name: 'name',
                value: form?.name || '',
                onChange: handleInputChange,
              }}
            />
          </FormControl>
          <FormControl
            textLabel={<FormattedMessage id="company_info.federalCode" />}
          >
            <Input
              inputProps={{
                isDisabled: hasCompany,
                name: 'federalCode',
                value: form?.federalCode || '',
                onChange: handleInputChange,
              }}
            />
          </FormControl>
          <FormControl
            textLabel={<FormattedMessage id="company_info.accessCode" />}
          >
            <Input
              inputProps={{
                isDisabled: true,
                name: 'accessCode',
                value: form?.passCode || '',
                onChange: handleInputChange,
              }}
            />
          </FormControl>
          <FormControl textLabel={<FormattedMessage id="company_info.email" />}>
            <Input
              inputProps={{
                name: 'email',
                value: form?.email || '',
                onChange: handleInputChange,
              }}
            />
          </FormControl>
          <FormControl
            textLabel={<FormattedMessage id="company_info.contact" />}
          >
            <Input
              inputProps={{
                name: 'contact',
                value: form?.contact || '',
                onChange: handleInputChange,
              }}
            />
          </FormControl>
          <FormControl
            textLabel={<FormattedMessage id="company_info.phoneNumber" />}
          >
            <Input
              inputProps={{
                name: 'phoneNumber',
                value: form?.phoneNumber || '',
                onChange: handleInputChange,
              }}
            />
          </FormControl>
          <FormControl
            textLabel={<FormattedMessage id="company_info.zipCode" />}
          >
            <Input
              inputProps={{
                name: 'zipCode',
                value: form?.zipCode || '',
                onChange: handleInputChange,
              }}
            />
          </FormControl>
          <FormControl
            textLabel={<FormattedMessage id="company_info.address" />}
          >
            <Input
              inputProps={{
                name: 'address',
                value: form?.address || '',
                onChange: handleInputChange,
              }}
            />
          </FormControl>
          <FormControl textLabel={<FormattedMessage id="company_info.city" />}>
            <Input
              inputProps={{
                name: 'city',
                value: form?.city || '',
                onChange: handleInputChange,
              }}
            />
          </FormControl>
          <FormControl textLabel={<FormattedMessage id="company_info.state" />}>
            <Input
              inputProps={{
                name: 'state',
                value: form?.state || '',
                onChange: handleInputChange,
              }}
            />
          </FormControl>
        </SimpleGrid>

        <FormSubtitle
          mt={8}
          subtitle={
            <FormattedMessage id="company_info.subtitle.dataProtection" />
          }
        >
          <SimpleGrid columns={{ md: 1, lg: 3 }} gap="6" p="2">
            <FormControl
              textLabel={
                <FormattedMessage id="company_info.field.dataProtectionOfficerName" />
              }
            >
              <Input
                inputProps={{
                  name: 'dataProtectionOfficerName',
                  value: form?.dataProtectionOfficerName || '',
                  onChange: handleInputChange,
                }}
              />
            </FormControl>
            <FormControl
              textLabel={
                <FormattedMessage id="company_info.field.dataProtectionOfficerEmail" />
              }
            >
              <Input
                inputProps={{
                  name: 'dataProtectionOfficerEmail',
                  value: form?.dataProtectionOfficerEmail || '',
                  onChange: handleInputChange,
                }}
              />
            </FormControl>
            <FormControl
              textLabel={
                <FormattedMessage id="company_info.field.dataProtectionOfficerPhone" />
              }
            >
              <Input
                inputProps={{
                  name: 'dataProtectionOfficerPhone',
                  value: form?.dataProtectionOfficerPhone || '',
                  onChange: handleInputChange,
                }}
              />
            </FormControl>
          </SimpleGrid>
        </FormSubtitle>

        <FormSubtitle
          mt={8}
          subtitle={
            <FormattedMessage id="company_info.subtitle.euRepresentative" />
          }
        >
          <SimpleGrid columns={{ md: 1, lg: 3 }} gap="6" p="2">
            <FormControl
              textLabel={
                <FormattedMessage id="company_info.field.euRepresentativeName" />
              }
            >
              <Input
                inputProps={{
                  name: 'euRepresentativeName',
                  value: form?.euRepresentativeName || '',
                  onChange: handleInputChange,
                }}
              />
            </FormControl>
            <FormControl
              textLabel={
                <FormattedMessage id="company_info.field.euRepresentativeEmail" />
              }
            >
              <Input
                inputProps={{
                  name: 'euRepresentativeEmail',
                  value: form?.euRepresentativeEmail || '',
                  onChange: handleInputChange,
                }}
              />
            </FormControl>
            <FormControl
              textLabel={
                <FormattedMessage id="company_info.field.euRepresentativePhone" />
              }
            >
              <Input
                inputProps={{
                  name: 'euRepresentativePhone',
                  value: form?.euRepresentativePhone || '',
                  onChange: handleInputChange,
                }}
              />
            </FormControl>
          </SimpleGrid>
        </FormSubtitle>

        {portalSettings.allowDeviceIOS && (
          <FormSubtitle
            mt={8}
            subtitle={
              <>
                <Flex alignItems="center" gridGap={2}>
                  <FormattedMessage id="company_info.subtitle.abm" />

                  <Tooltip
                    hasArrow
                    arrowSize={8}
                    padding="2"
                    borderRadius="5"
                    placement="right"
                    label={intl.formatMessage({ id: 'company_info.abm.info' })}
                  >
                    <Flex>
                      <AlertIcon />
                    </Flex>
                  </Tooltip>
                </Flex>
              </>
            }
          >
            <SimpleGrid columns={{ md: 1 }} gap="6" p="2">
              <FormControl
                textLabel={
                  <FormattedMessage id="company_info.field.contentTokenABM" />
                }
              >
                <Input
                  inputProps={{
                    name: 'contentTokenABM',
                    value: form?.contentTokenABM || '',
                    onChange: handleInputChange,
                  }}
                />
              </FormControl>
            </SimpleGrid>
          </FormSubtitle>
        )}
      </FormContainer>

      {open && (
        <CompanyInfoModal
          open={open}
          onClose={() => setOpen(false)}
          company={form}
        />
      )}
    </>
  );
};

export default CompanyInfo;
