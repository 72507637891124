import { Flex } from '@chakra-ui/layout';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useFilter } from '../../../hooks/useFilter';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import {
  clearNotification,
  getNotification,
  notificationDetailsMetadata,
  Types,
} from '../../../store/notification';
import { ListMetadata } from '../../../types/generic_list';
import { NotificationImportTitleEnum } from '../../../types/notification';
import Modal from '../../Modal';
import PageTitle from '../../PageTitle';
import Table from '../../Table';
import {
  DataTableType,
  getBatchGroupImportDetailsData,
} from './BatchGroupImportDetails';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  notificationId?: string;
}

export default function Index({ isOpen, onClose, notificationId }: Props) {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { notification, notificationDetailsMetadata: metadata } =
    useAppSelector((state) => state.notification);

  const DEFAULT_TABLE_DATA = {
    rows: [],
    columns: [],
  };

  const { data: notificationDetailsData } = useFilter(
    notification?.details?.data || [],
    {
      pagination: {
        metadata,
      },
    }
  );

  const detailsToRender: Record<
    Partial<NotificationImportTitleEnum | string>,
    DataTableType
  > = {
    [NotificationImportTitleEnum.IMPORT_GROUP]: getBatchGroupImportDetailsData({
      groupDetails: notificationDetailsData,
      intl,
    }),
    default: DEFAULT_TABLE_DATA,
  };

  const data: DataTableType =
    detailsToRender[notification?.title] || detailsToRender['default'];

  useEffect(() => {
    dispatch(getNotification(Number(notificationId)));

    return () => {
      dispatch(clearNotification());
    };
  }, [notificationId]);

  function handleMetadata(newMetadata: Partial<ListMetadata>) {
    dispatch(notificationDetailsMetadata({ ...metadata, ...newMetadata }));
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      modalWidth="750px"
      modalContentProps={{ bgColor: 'gray.400', py: 6 }}
      preserveScrollBarGap
    >
      <Flex
        px="10"
        direction="column"
        maxH="calc(100vh - 170px)"
        overflowY="auto"
      >
        <PageTitle
          title={intl.formatMessage({
            id: 'modal_notification_details.title',
          })}
        />

        <Table
          headerColumns={data.columns}
          rows={data?.rows}
          metadata={metadata}
          handleSort={handleMetadata}
          keyProp={Types.GET_NOTIFICATION}
          pageActionsProps={{
            disabledExportAction: true,
          }}
          paginationProps={{
            isColumn: true,
          }}
        />
      </Flex>
    </Modal>
  );
}
