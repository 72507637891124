import { Flex, FlexProps } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

interface MockupDeviceProps extends FlexProps {
  children?: ReactNode;
}
const MockupDevice = ({ children, ...props }: MockupDeviceProps) => {
  return (
    <Flex
      flexDirection="column"
      h="600px"
      w="350px"
      minW="350px"
      p={4}
      borderRadius="16px"
      bgColor="gray.900"
      {...props}
    >
      {children}
    </Flex>
  );
};

export default MockupDevice;
