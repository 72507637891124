import { ThemeType } from '../theme';

const theme: ThemeType = {
  colors: {
    primary: {
      500: '#1a76b7', // primary
      600: '#203E77', // secondary
      900: '#172b53', // shade of secondary
    },
  },
  images: {
    logo: 'https://storage.googleapis.com/enterprise-telcel/assets/logo_login.png',
    logo_dashboard:
      'https://storage.googleapis.com/enterprise-telcel/assets/logo_dashboard.png',
    backgroundLogin:
      'https://storage.googleapis.com/enterprise-telcel/assets/background_login.jpg',
  },
};

export default theme;
