import { Flex } from '@chakra-ui/react';
import { useIntl } from 'react-intl';

import { useFormatDate } from '../../../../hooks/useFormatDate';
import { useAppSelector } from '../../../../hooks/useRedux';
import { operationalSystemToIntl } from '../../../../pages/Policies/EditPolicies';
import { DeviceType } from '../../../../types/device';
import { ManagementModeEnumToIntl } from '../../../../types/policy';
import ItemList from '../../../ItemListDrill/ItemListDrill';

interface ListDrillDownProps {
  device: DeviceType;
  transformValue: (object: DeviceType, key: keyof DeviceType) => unknown;
}

function ListDrillDown({ device, transformValue }: ListDrillDownProps) {
  const intl = useIntl();
  const { handleFormatDateByUserLogged } = useFormatDate();
  const managementMode = device?.policy?.managementMode;
  const { portalSettings } = useAppSelector((state) => state.portalSettings);

  return (
    <Flex p="30px 0 30px 70px">
      <Flex flexDirection="column">
        <ItemList
          label={intl.formatMessage({ id: 'devices.column.policy' })}
          value={device?.policy?.name}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'devices.column.accordion.management_mode',
          })}
          value={
            managementMode
              ? intl.formatMessage({
                  id: `${ManagementModeEnumToIntl[managementMode]}`,
                })
              : ''
          }
        />
        <ItemList
          label={intl.formatMessage(
            { id: 'devices.column.companion_version' },
            { companionName: portalSettings.name }
          )}
          value={device?.companionVersion}
        />
        <ItemList
          label={intl.formatMessage({ id: 'devices.column.iccid' })}
          value={device?.iccid}
        />
        <ItemList
          label={intl.formatMessage({ id: 'devices.column.manufacturer' })}
          value={device?.manufacturer}
        />
      </Flex>
      <Flex flexDirection="column" ml="100px">
        <ItemList
          label={intl.formatMessage({
            id: 'devices.column.operational_system',
          })}
          value={intl.formatMessage({
            id: `${operationalSystemToIntl[device?.operationalSystem]}`,
          })}
        />
        <ItemList
          label={intl.formatMessage({ id: 'devices.column.register_date' })}
          value={handleFormatDateByUserLogged(device?.createdAt)}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'devices.column.group',
          })}
          value={transformValue(device?.group, 'name')}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'devices.column.state',
          })}
          value={transformValue(device, 'state')}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'devices.column.last_communication',
          })}
          value={handleFormatDateByUserLogged(device?.lastCommunication)}
        />
      </Flex>
    </Flex>
  );
}

export default ListDrillDown;
