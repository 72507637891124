import React from 'react';
import { Switch, Redirect, RouteProps, Route } from 'react-router-dom';

import routes from '.';
import { PrivilegeEnum } from '../helper';
import EditAdminUser from '../pages/AdminUsers/EditAdminUser';
import ManageAdminUsers from '../pages/AdminUsers/ManageAdminUsers';
import ApplicationDeviceUsers from '../pages/Applications/ApplicationDeviceUsers';
import ConsumptionHistory from '../pages/Applications/ConsumptionHistory';
import ManageApplications from '../pages/Applications/ManageApplications';
import ManageZeroTouchRegister from '../pages/Backoffice/ManageZeroTouchRegister';
import ZeroTouchRegisterDetails from '../pages/Backoffice/ZeroTouchRegisterDetails';
import EditCompany from '../pages/Carrier/EditCompany';
import ManageCompanies from '../pages/Carrier/ManageCompanies';
import Audit from '../pages/Companies/Audit';
import CompanyConsumption from '../pages/Companies/CompanyConsumption';
import CompanyInfo from '../pages/Companies/CompanyInfo';
import CompanyLicense from '../pages/Companies/CompanyLicense';
import CompanyConfirmEmail from '../pages/Companies/CompanyRegister/CompanyConfirmEmail';
import CompanyConfirmInfo from '../pages/Companies/CompanyRegister/CompanyConfirmInfo';
import CompanyRegisterCallback from '../pages/Companies/CompanyRegister/CompanyRegisterCallback';
import GeneralConfig from '../pages/Companies/GeneralConfig';
import ConsumptionProfile from '../pages/Configuration/ConsumptionProfile';
import ConsumptionProfileEdit from '../pages/Configuration/ConsumptionProfile/ConsumptioProfileEdit';
import ManageNetworks from '../pages/Configuration/NetworkSettings/ManageNetworks';
import NetworkDetails from '../pages/Configuration/NetworkSettings/NetworkDetails';
import ConfirmWelcomeEmail from '../pages/ConfirmWelcomeEmail';
import Dashboard from '../pages/Dashboard';
import DeviceBattery from '../pages/Devices/DeviceBattery';
import DeviceInfos from '../pages/Devices/DeviceInfos';
import DeviceNonCompliance from '../pages/Devices/DeviceNonCompliance';
import DeviceStorage from '../pages/Devices/DeviceStorage';
import DevicesWithoutCommunication from '../pages/Devices/DevicesWithoutCommunication';
import ManageDevices from '../pages/Devices/ManageDevices';
import GooglePlayManaged from '../pages/EnterpriseApps/GooglePlayManaged';
import EnterpriseApplications from '../pages/EnterpriseApps/ManageEnterpriseApps';
import Geolocation from '../pages/Geolocation';
import EditGroup from '../pages/Groups/EditGroup';
import ManageGroups from '../pages/Groups/ManageGroups';
import FAQ from '../pages/Help/FAQ';
import Guides from '../pages/Help/Guides';
import CompaniesList from '../pages/HelpDesk/CompaniesList';
import Login from '../pages/Login';
import Unblock from '../pages/Login/Unblock';
import MessageDetails from '../pages/Messages/MessageDetails';
import MessagesEdit from '../pages/Messages/MessagesEdit';
import MessagesList from '../pages/Messages/MessagesList';
import PasswordChange from '../pages/PasswordChange';
import PasswordRecovery from '../pages/PasswordRecovery';
import EditPolicies from '../pages/Policies/EditPolicies';
import ManagePolicies from '../pages/Policies/ManagePolicies';
import RegisterPolicies from '../pages/Policies/RegisterPolicy';
import RemoteView from '../pages/RemoteView';
import DeviceLocation from '../pages/Reports/DeviceLocation';
import LocationHistory from '../pages/Reports/LocationHistory';
import ReportDate from '../pages/Sites/ReportDate';
import ReportUrl from '../pages/Sites/ReportUrl';
import PrivacyPolicy from '../pages/Terms/PrivacyPolicy';
import TermsOfUsage from '../pages/Terms/TermsOfUsage';
import InvoiceDetails from '../pages/ZeroTouch/InvoiceDetails';
import ManageInvoices from '../pages/ZeroTouch/ManageInvoices';
import ZeroTouch from '../pages/ZeroTouch/ManageZeroTouch';
import ExternalRoute from './ExternalRoute';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

export interface CustomRouteProps extends RouteProps {
  isPrivate?: boolean;
}

export const homeByPrivilege = {
  [PrivilegeEnum.COMPANY]: routes.dashboard,
  [PrivilegeEnum.GROUP]: routes.dashboard,
  [PrivilegeEnum.HELP_DESK]: routes.helpDesk.companiesList,
  [PrivilegeEnum.CARRIER]: routes.faq,
};

export default function Routes() {
  return (
    <Switch>
      <PublicRoute exact path={routes.login} component={Login} />
      <PublicRoute
        exact
        path={routes.passwordRecovery}
        component={PasswordRecovery}
      />
      <PublicRoute
        exact
        path={routes.passwordChange}
        component={PasswordChange}
      />
      <PublicRoute exact path={routes.unblock} component={Unblock} />
      <PublicRoute
        exact
        path={routes.loginConfirmation}
        component={CompanyConfirmEmail}
      />
      <ExternalRoute
        exact
        path={routes.confirmWelcomeEmail}
        component={ConfirmWelcomeEmail}
      />

      <ExternalRoute
        exact
        path={routes.terms.privacyPolicy}
        component={PrivacyPolicy}
      />
      <ExternalRoute
        exact
        path={routes.terms.termsOfUsage}
        component={TermsOfUsage}
      />
      <Route
        exact
        path={routes.company.callback}
        component={CompanyRegisterCallback}
      />

      <PrivateRoute
        isPrivate
        exact
        path={routes.company.register}
        component={CompanyConfirmInfo}
      />
      <PrivateRoute
        isPrivate
        exact
        path={routes.company.validEmail}
        component={CompanyConfirmEmail}
      />
      <PrivateRoute
        isPrivate
        exact
        path={routes.company.info}
        component={CompanyInfo}
      />
      <PrivateRoute
        isPrivate
        exact
        path={routes.company.consumption}
        component={CompanyConsumption}
      />
      <PrivateRoute
        isPrivate
        exact
        path={[routes.company.license, routes.company.licenseNotActivated]}
        component={CompanyLicense}
      />
      <PrivateRoute
        isPrivate
        exact
        path={routes.company.audit}
        component={Audit}
      />
      <PrivateRoute
        isPrivate
        exact
        path={routes.groups.manage}
        component={ManageGroups}
      />
      <PrivateRoute
        isPrivate
        exact
        path={[routes.groups.edit, routes.groups.register]}
        component={EditGroup}
      />

      <PrivateRoute
        isPrivate
        exact
        path={routes.adminUsers.manage}
        component={ManageAdminUsers}
      />
      <PrivateRoute
        isPrivate
        exact
        path={[routes.adminUsers.edit, routes.adminUsers.register]}
        component={EditAdminUser}
      />

      <PrivateRoute
        isPrivate
        exact
        path={routes.application.manage}
        component={ManageApplications}
      />
      <PrivateRoute
        isPrivate
        exact
        path={routes.application.applicationDeviceUsers}
        component={ApplicationDeviceUsers}
      />
      <PrivateRoute
        isPrivate
        exact
        path={[
          routes.application.consumptionHistory,
          routes.application.consumptionHistoryByDeviceUser,
        ]}
        component={ConsumptionHistory}
      />
      <PrivateRoute
        isPrivate
        exact
        path={routes.messages.list}
        component={MessagesList}
      />
      <PrivateRoute
        isPrivate
        exact
        path={routes.messages.details}
        component={MessageDetails}
      />
      <PrivateRoute
        isPrivate
        exact
        path={routes.messages.register}
        component={MessagesEdit}
      />
      <PrivateRoute
        isPrivate
        exact
        path={routes.documents.list}
        component={MessagesList}
      />
      <PrivateRoute
        isPrivate
        exact
        path={routes.documents.register}
        component={MessagesEdit}
      />
      <PrivateRoute
        isPrivate
        exact
        path={routes.documents.details}
        component={MessageDetails}
      />
      <PrivateRoute
        isPrivate
        exact
        path={routes.externalApps.list}
        component={MessagesList}
      />
      <PrivateRoute
        isPrivate
        exact
        path={routes.externalApps.register}
        component={MessagesEdit}
      />
      <PrivateRoute
        isPrivate
        exact
        path={routes.externalApps.details}
        component={MessagesEdit}
      />

      <PrivateRoute
        isPrivate
        exact
        path={routes.device.manage}
        component={ManageDevices}
      />

      <PrivateRoute
        isPrivate
        exact
        path={routes.zeroTouch.manageZeroTouch}
        component={ZeroTouch}
      />

      <PrivateRoute
        isPrivate
        exact
        path={routes.zeroTouch.invoices.manage}
        component={ManageInvoices}
      />

      <PrivateRoute
        isPrivate
        exact
        path={routes.zeroTouch.invoices.edit}
        component={InvoiceDetails}
      />

      <PrivateRoute
        isPrivate
        exact
        path={routes.device.battery}
        component={DeviceBattery}
      />

      <PrivateRoute
        isPrivate
        exact
        path={routes.device.storage}
        component={DeviceStorage}
      />

      <PrivateRoute
        isPrivate
        exact
        path={routes.device.infos}
        component={DeviceInfos}
      />

      <PrivateRoute
        isPrivate
        exact
        path={routes.device.withoutCommunication}
        component={DevicesWithoutCommunication}
      />

      <PrivateRoute
        isPrivate
        exact
        path={routes.device.nonCompliance}
        component={DeviceNonCompliance}
      />

      <PrivateRoute
        isPrivate
        exact
        path={routes.informes.locationHistory}
        component={LocationHistory}
      />
      <PrivateRoute
        isPrivate
        exact
        path={routes.informes.deviceLocation}
        component={DeviceLocation}
      />

      <PrivateRoute
        isPrivate
        exact
        path={routes.geolocation}
        component={Geolocation}
      />

      <PrivateRoute isPrivate exact path={routes.support} component={Guides} />
      <PrivateRoute
        isPrivate
        exact
        path={routes.sites.reportUrl}
        component={ReportUrl}
      />

      <PrivateRoute
        isPrivate
        exact
        path={routes.sites.reportDate}
        component={ReportDate}
      />

      <PrivateRoute
        isPrivate
        exact
        path={routes.profileConsumption.manage}
        component={ConsumptionProfile}
      />
      <PrivateRoute
        isPrivate
        exact
        path={[
          routes.profileConsumption.register,
          routes.profileConsumption.edit,
        ]}
        component={ConsumptionProfileEdit}
      />

      <PrivateRoute
        isPrivate
        exact
        path={[routes.generalConfig.manage]}
        component={GeneralConfig}
      />

      <PrivateRoute isPrivate exact path={routes.faq} component={FAQ} />

      <PrivateRoute
        isPrivate
        exact
        path={routes.dashboard}
        component={Dashboard}
      />

      <PrivateRoute
        isPrivate
        path={routes.enterpriseApps.manage}
        component={EnterpriseApplications}
      />
      <PrivateRoute
        isPrivate
        path={routes.enterpriseApps.googlePlayManaged}
        component={GooglePlayManaged}
      />
      <PrivateRoute
        isPrivate
        path={routes.policies.manage}
        component={ManagePolicies}
      />
      <PrivateRoute
        isPrivate
        path={routes.policies.edit}
        component={EditPolicies}
      />
      <PrivateRoute
        isPrivate
        path={routes.policies.register}
        component={RegisterPolicies}
      />
      <PrivateRoute
        isPrivate
        path={routes.policies.registerPoliceIOS}
        component={EditPolicies}
      />
      <PrivateRoute
        isPrivate
        exact
        path={routes.helpDesk.companiesList}
        component={CompaniesList}
      />
      <PrivateRoute
        isPrivate
        exact
        path={routes.zeroTouch.backoffice.manage}
        component={ManageZeroTouchRegister}
      />
      <PrivateRoute
        isPrivate
        exact
        path={routes.zeroTouch.backoffice.edit}
        component={ZeroTouchRegisterDetails}
      />
      <PrivateRoute
        isPrivate
        exact
        path={routes.generalConfig.networkSettings.list}
        component={ManageNetworks}
      />
      <PrivateRoute
        isPrivate
        path={routes.generalConfig.networkSettings.edit}
        component={NetworkDetails}
      />
      <PrivateRoute
        path={routes.generalConfig.networkSettings.register}
        component={NetworkDetails}
      />
      <PrivateRoute
        isPrivate
        layoutType="Clean"
        path={routes.remoteView}
        component={RemoteView}
      />
      <PrivateRoute
        isPrivate
        exact
        path={routes.remoteApps.list}
        component={MessagesList}
      />
      <PrivateRoute
        isPrivate
        exact
        path={routes.remoteApps.register}
        component={MessagesEdit}
      />
      <PrivateRoute
        isPrivate
        exact
        path={routes.remoteApps.details}
        component={MessageDetails}
      />
      <PrivateRoute
        isPrivate
        exact
        path={routes.carrier.manage}
        component={ManageCompanies}
      />

      <PrivateRoute
        isPrivate
        exact
        path={[routes.carrier.edit, routes.carrier.register]}
        component={EditCompany}
      />

      <PrivateRoute
        isPrivate
        path={routes.home}
        component={() => (
          <Redirect
            exact
            from={routes.home}
            to={routes.dashboard}
            path={routes.home}
          />
        )}
      />
    </Switch>
  );
}
