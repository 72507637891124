import { Box, Divider, Flex, SimpleGrid } from '@chakra-ui/react';
import { formatBytesTo } from 'portal-lib';
import React, { ChangeEvent, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import Card from '../../../components/Card';
import CardHeader from '../../../components/CardHeader';
import FormContainer from '../../../components/FormContainer';
import FormControl from '../../../components/FormControl';
import Input from '../../../components/Input';
import ItemListDrill from '../../../components/ItemListDrill/ItemListDrill';
import PageErrors from '../../../components/PageErrors';
import PageShowToaster from '../../../components/PageShowToaster';
import PageTitle from '../../../components/PageTitle';
import SelectAutocomplete from '../../../components/SelectAutocomplete';
import Text from '../../../components/Text';
import { useFormatDate } from '../../../hooks/useFormatDate';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import routes from '../../../routes';
import {
  deviceEditInfo,
  editDevice,
  getDevice,
  Types,
} from '../../../store/device';
import { listGroupsToFilter } from '../../../store/group';
import {
  hasError,
  hasLoading,
  hasSuccess,
  uiRemoveSuccess,
} from '../../../store/ui';
import {
  deviceStateToIntl,
  OperationalSystemEnum,
} from '../../../types/device';
import { GroupType } from '../../../types/group';
import { operationalSystemToIntl } from '../../Policies/EditPolicies';
import { managementModeToIntl } from '../../Policies/RegisterPolicy';

enum AppleBusinessManagerErrorEnum {
  INVALID_EMAIL = 'apple.business.manager.user.fail',
  NOT_FOUND_CONTENT_TOKEN_ABM = 'not.found.contentTokenABM',
  EMAIL_ALREADY_IN_USE = 'emailAppleBusinessManager.alreadyInUse',
}

const errorResponseToIntl = {
  [AppleBusinessManagerErrorEnum.INVALID_EMAIL]:
    'devices.infos.ios_error.invalid_email',
  [AppleBusinessManagerErrorEnum.NOT_FOUND_CONTENT_TOKEN_ABM]:
    'devices.infos.ios_error.not_found_content_token_abm',
  [AppleBusinessManagerErrorEnum.EMAIL_ALREADY_IN_USE]:
    'devices.infos.ios_error.email_already_in_use',
};

const DeviceInfos = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { device } = useAppSelector((state) => state.device);
  const { groupsToFilter } = useAppSelector((state) => state.group);
  const { portalSettings } = useAppSelector((state) => state.portalSettings);
  const { handleFormatDateByUserLogged } = useFormatDate();
  const getSuccess = hasSuccess(Types.GET);
  const editSuccess = hasSuccess(Types.EDIT);
  const hasEmailAppleBusinessManager = useMemo(
    () => !!device?.emailAppleBusinessManager,
    [getSuccess, editSuccess, id]
  );
  const history = useHistory();

  const isIOS = device?.operationalSystem === OperationalSystemEnum.IOS;

  const isLoading = hasLoading(Types.EDIT);
  const error = hasError(Types.EDIT);

  useEffect(() => {
    dispatch(listGroupsToFilter());
    dispatch(getDevice(parseInt(id)));

    return () => {
      dispatch(uiRemoveSuccess(Types.GET));
      dispatch(uiRemoveSuccess(Types.EDIT));
    };
  }, [id]);

  const handleSubmit = () => {
    dispatch(editDevice(device));
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(deviceEditInfo({ [e.target.name]: e.target.value }));
  };

  const handleGroupsSelect = (groupSelected: GroupType) => {
    dispatch(deviceEditInfo({ group: groupSelected }));
  };

  const handleGroupsChange = (value) => {
    dispatch(listGroupsToFilter({ search: value }));
  };

  return (
    <>
      <PageTitle
        showManualIcon
        title={<FormattedMessage id="devices.infos.title" />}
        description={<FormattedMessage id="devices.infos.sub_title" />}
      />
      <PageShowToaster toasterKeys={[Types.EDIT]} disabledError />

      <PageErrors
        toasterKeys={[Types.GET, Types.EDIT]}
        translateKey="devices.infos"
        messageError={
          isIOS &&
          errorResponseToIntl[error?.message] && (
            <FormattedMessage id={errorResponseToIntl[error.message]} />
          )
        }
      />
      <Flex flexDirection="column">
        <FormContainer
          labelPrimary={<FormattedMessage id="global.update" />}
          handlePrimary={handleSubmit}
          loadingPrimary={isLoading}
          labelSecondary={<FormattedMessage id="global.cancel" />}
          handleSecondary={() => history.push(routes.device.manage)}
        >
          <Text fontSize="2xl" fontWeight="bold" my="0">
            <FormattedMessage id="devices.infos.card_header.title" />
          </Text>
          <Divider my="4" />
          <SimpleGrid columns={[2, 3]} gridGap={6}>
            <FormControl
              textLabel={<FormattedMessage id="devices.infos.name" />}
            >
              <Input
                inputProps={{
                  value: device?.name || '',
                  name: 'name',
                  onChange: handleInputChange,
                }}
              />
            </FormControl>

            <FormControl
              textLabel={<FormattedMessage id="devices.infos.federalCode" />}
            >
              <Input
                inputProps={{
                  value: device?.federalCode || '',
                  name: 'federalCode',
                  onChange: handleInputChange,
                }}
              />
            </FormControl>

            {isIOS && (
              <FormControl
                textLabel={
                  <FormattedMessage id="devices.infos.emailAppleBusinessManager" />
                }
              >
                <Input
                  inputProps={{
                    value: device?.emailAppleBusinessManager || '',
                    name: 'emailAppleBusinessManager',
                    onChange: handleInputChange,
                    disabled: hasEmailAppleBusinessManager,
                  }}
                />
              </FormControl>
            )}

            <FormControl
              textLabel={<FormattedMessage id="devices.infos.imei" />}
            >
              <Input
                inputProps={{
                  disabled: true,
                  value: device?.imei || '',
                }}
              />
            </FormControl>
            <FormControl
              textLabel={<FormattedMessage id="devices.infos.iccid" />}
            >
              <Input
                inputProps={{
                  disabled: true,
                  value: device?.iccid || '',
                }}
              />
            </FormControl>

            <FormControl
              textLabel={<FormattedMessage id="devices.infos.phone" />}
            >
              <Input
                inputProps={{
                  disabled: true,
                  value: device?.phoneNumber || '',
                }}
              />
            </FormControl>

            <FormControl
              textLabel={<FormattedMessage id="devices.infos.manufacturer" />}
            >
              <Input
                inputProps={{
                  disabled: true,
                  value: device?.manufacturer || '',
                }}
              />
            </FormControl>

            <FormControl
              textLabel={<FormattedMessage id="devices.infos.model" />}
            >
              <Input
                inputProps={{
                  disabled: true,
                  value: device?.model || '',
                }}
              />
            </FormControl>

            <FormControl
              textLabel={
                <FormattedMessage id="devices.infos.operational_system" />
              }
            >
              <Input
                inputProps={{
                  disabled: true,
                  value: device?.operationalSystem
                    ? intl.formatMessage({
                        id: operationalSystemToIntl[device?.operationalSystem],
                      })
                    : '',
                }}
              />
            </FormControl>

            <FormControl
              textLabel={<FormattedMessage id="devices.infos.register_date" />}
            >
              <Input
                inputProps={{
                  disabled: true,
                  value: handleFormatDateByUserLogged(device?.createdAt) || '',
                }}
              />
            </FormControl>

            <FormControl textLabel={<FormattedMessage id="global.group" />}>
              <SelectAutocomplete
                options={groupsToFilter}
                value={device?.group}
                onChange={handleGroupsSelect}
                onInputChange={handleGroupsChange}
                placeholder={<FormattedMessage id="global.groups" />}
              />
            </FormControl>

            <FormControl
              textLabel={
                <FormattedMessage id="devices.infos.last_communication" />
              }
            >
              <Input
                inputProps={{
                  disabled: true,
                  value:
                    handleFormatDateByUserLogged(device?.lastCommunication) ||
                    '',
                }}
              />
            </FormControl>
          </SimpleGrid>
        </FormContainer>
        <Card mt="8">
          <Flex
            flexDirection="row"
            justifyContent="space-between"
            backgroundColor="white"
          >
            <Box w="48%">
              <CardHeader
                mb="3%"
                title={intl.formatMessage({
                  id: 'devices.infos.info_software',
                })}
                disableGoBack
                description={
                  <Flex flexDirection="column">
                    {isIOS ? (
                      <>
                        <ItemListDrill
                          label={intl.formatMessage({
                            id: 'devices.infos.management_mode',
                          })}
                          value={
                            device?.policy?.managementMode &&
                            intl.formatMessage({
                              id: managementModeToIntl[
                                device?.policy?.managementMode
                              ],
                            })
                          }
                        />
                        <ItemListDrill
                          label={intl.formatMessage(
                            { id: 'devices.column.companion_version' },
                            { companionName: portalSettings.name }
                          )}
                          value={device?.companionVersion}
                        />
                        <ItemListDrill
                          label={intl.formatMessage({
                            id: 'devices.infos.last_update',
                          })}
                          value={
                            handleFormatDateByUserLogged(
                              device?.enterpriseUpdatedAt
                            ) || ''
                          }
                        />
                        <ItemListDrill
                          label={intl.formatMessage({
                            id: 'devices.infos.udid',
                          })}
                          value={device?.udid}
                        />
                        <ItemListDrill
                          label={intl.formatMessage({
                            id: 'devices.infos.policy_name_on_portal',
                          })}
                          value={device?.policy?.name}
                        />
                      </>
                    ) : (
                      <>
                        <ItemListDrill
                          label={intl.formatMessage({
                            id: 'devices.infos.management_mode',
                          })}
                          value={
                            device?.policy?.managementMode &&
                            intl.formatMessage({
                              id: managementModeToIntl[
                                device?.policy?.managementMode
                              ],
                            })
                          }
                        />
                        <ItemListDrill
                          label={intl.formatMessage(
                            { id: 'devices.column.companion_version' },
                            { companionName: portalSettings.name }
                          )}
                          value={device?.companionVersion}
                        />
                        <ItemListDrill
                          label={intl.formatMessage({
                            id: 'devices.infos.serial_number',
                          })}
                          value={device?.serialNumber}
                        />
                        <ItemListDrill
                          label={intl.formatMessage({
                            id: 'devices.infos.in_accordance',
                          })}
                          value={intl.formatMessage({
                            id: device?.policyCompliant
                              ? 'devices.infos.in_accordance.yes'
                              : 'devices.infos.in_accordance.no',
                          })}
                        />
                        <ItemListDrill
                          label={intl.formatMessage({
                            id: 'devices.infos.appliedState',
                          })}
                          value={
                            device?.state &&
                            intl.formatMessage({
                              id: deviceStateToIntl[device?.state],
                            })
                          }
                        />
                        <ItemListDrill
                          label={intl.formatMessage({
                            id: 'devices.infos.last_update',
                          })}
                          value={
                            handleFormatDateByUserLogged(
                              device?.enterpriseUpdatedAt
                            ) || ''
                          }
                        />
                        <ItemListDrill
                          label={intl.formatMessage({
                            id: 'devices.infos.policy_sync',
                          })}
                          value={
                            handleFormatDateByUserLogged(
                              device?.lastPolicySyncTime
                            ) || ''
                          }
                        />
                        <ItemListDrill
                          label={intl.formatMessage({
                            id: 'devices.infos.android_version',
                          })}
                          value={device?.androidVersion}
                        />
                        <ItemListDrill
                          label={intl.formatMessage({
                            id: 'devices.infos.policy_version_applied',
                          })}
                          value={device?.policy?.version}
                        />
                        <ItemListDrill
                          label={intl.formatMessage({
                            id: 'devices.infos.policy_name_on_portal',
                          })}
                          value={device?.policy?.name}
                        />
                        <ItemListDrill
                          label={intl.formatMessage({
                            id: 'devices.infos.policy_name_on_device',
                          })}
                          value={device?.appliedPolicyName}
                        />
                      </>
                    )}
                  </Flex>
                }
              />
            </Box>
            <Box w="48%">
              <CardHeader
                mb="3%"
                title={intl.formatMessage({
                  id: 'devices.infos.info_hardware',
                })}
                disableGoBack
                description={
                  <Flex flexDirection="column">
                    <ItemListDrill
                      label={intl.formatMessage({
                        id: 'devices.infos.intern_storage',
                      })}
                      value={formatBytesTo({
                        bytes: device?.freeMemory,
                      })}
                    />
                    <ItemListDrill
                      label={intl.formatMessage({
                        id: 'devices.infos.battery',
                      })}
                      value={`${device.battery}%`}
                    />
                  </Flex>
                }
              />
            </Box>
          </Flex>
        </Card>
      </Flex>
    </>
  );
};

export default DeviceInfos;
