import { Flex } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '../Button';
import Modal from '../Modal';
import Text from '../Text';

export interface ProductVersionType {
  value?: string;
  backVersion?: string;
  frontVersion?: string;
}

interface ModalProductVersionProps {
  isOpen?: boolean;
  onClose?: () => void;
  productVersion?: ProductVersionType;
}

const ModalProductVersion = ({
  isOpen,
  onClose,
  productVersion,
}: ModalProductVersionProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      preserveScrollBarGap
      modalWidth="600px"
    >
      <Flex direction="column" width="full" p="6" gridGap={6}>
        <Text color="gray.900" fontWeight="bold" fontSize="22px">
          <FormattedMessage id="global.about" />
        </Text>
        <Flex direction="column" gridGap={4}>
          <Text m={0}>
            <FormattedMessage
              id="modal_product_version.actual_version"
              values={{
                productVersion: productVersion?.value,
                frontVersion: productVersion?.frontVersion,
                backVersion: productVersion?.backVersion,
              }}
            />
          </Text>
        </Flex>

        <Flex align="center" justify="center">
          <Button w="180px" h="45px" onClick={onClose}>
            <FormattedMessage id="global.close" />
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default ModalProductVersion;
