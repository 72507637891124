import { Text, Box } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

import Button from '../../components/Button';
import FormControl from '../../components/FormControl';
import LeftArrowCircleIcon from '../../components/Icons/LeftArrowCircle';
import Input from '../../components/Input';
import RecoveryPasswordModal, {
  ModalTypeEnum,
} from '../../components/pages/RecoveryPassword/RecoveryPasswordModal';
import { validatorDefaultMessages } from '../../helper/validador';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import routes from '../../routes';
import { recover, Types } from '../../store/auth';
import {
  hasError,
  selectorLoading,
  selectorSuccess,
  uiRemoveError,
  uiRemoveSuccess,
} from '../../store/ui';

const PasswordRecovery = () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { loginLanguage } = useAppSelector((state) => state.auth);
  const [form, setForm] = useState({
    email: '',
    url: `${window.location.origin}${routes.passwordChange}`,
  });
  const [simpleValidator, setSimpleValidator] =
    useState<SimpleReactValidator>();
  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState<ModalTypeEnum>(null);
  const isLoading = useAppSelector(selectorLoading(Types.RECOVER_PASSWORD));
  const isSuccess = useAppSelector(selectorSuccess(Types.RECOVER_PASSWORD));
  const isError = hasError(Types.RECOVER_PASSWORD);

  useEffect(() => {
    setSimpleValidator(
      new SimpleReactValidator({
        messages: {
          ...validatorDefaultMessages(intl),
        },
      })
    );
    simpleValidator?.showMessages();
  }, [loginLanguage]);

  useEffect(() => {
    if (!!isError) {
      setModalType(ModalTypeEnum.ERROR);
    }
    if (isSuccess) {
      setModalType(ModalTypeEnum.SUCCESS);
    }
    setOpen(isSuccess || !!isError);
  }, [isSuccess, isError]);

  const updateForm = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    simpleValidator?.showMessages();
  };

  const submit = (event) => {
    event.preventDefault();
    dispatch(recover(form));
  };

  const clearForm = () => {
    setForm({ ...form, email: '' });
    dispatch(uiRemoveSuccess(Types.RECOVER_PASSWORD));
    dispatch(uiRemoveError(Types.RECOVER_PASSWORD));
    setOpen(false);
  };

  return (
    <>
      <Box pt="20%">
        <Button
          variant="link"
          leftIcon={<LeftArrowCircleIcon boxSize={8} color="primary.500" />}
          onClick={() => history.push(routes.login)}
        >
          <FormattedMessage id="global.back" />
        </Button>
      </Box>

      <form onSubmit={submit}>
        <Box pt="8">
          <Text fontSize="4xl" fontWeight="300">
            <FormattedMessage id="password_recovery.welcome_1" />
          </Text>
          <Text fontSize="4xl" as="b">
            <FormattedMessage id="password_recovery.welcome_2" />
          </Text>
        </Box>

        <Box pt="40px" m="20px 0px 40px 0px">
          <FormControl textLabel={<FormattedMessage id="global.email" />}>
            <Input
              inputProps={{
                placeholder: intl.formatMessage({
                  id: 'login.email_placeholder',
                }),
                type: 'email',
                onChange: updateForm,
                value: form.email,
                name: 'email',
              }}
            />
            <Box
              color="warning.500"
              fontSize="sm"
              fontWeight="bold"
              h="21px"
              m="0"
            >
              {simpleValidator?.message('email', form.email, 'required|email')}
            </Box>
          </FormControl>
        </Box>

        <Box>
          <Button
            type="submit"
            w="170px"
            h="45px"
            disabled={!simpleValidator?.allValid()}
            isLoading={isLoading}
          >
            <FormattedMessage id="password_recovery.continue" />
          </Button>
        </Box>
      </form>
      <RecoveryPasswordModal
        open={open}
        modalType={modalType}
        onClose={clearForm}
        email={form.email}
      />
    </>
  );
};

export default PasswordRecovery;
