import { Flex, Tooltip, useTheme } from '@chakra-ui/react';
import { secondsToHours, secondsToMinutes } from 'date-fns';
import { truncate } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';

import { formatNumberWithDecimals } from '../../../helper/number';
import { useAppSelector } from '../../../hooks/useRedux';
import { Types } from '../../../store/dashboard';
import { selectorLoading } from '../../../store/ui';
import ChartBar from '../../ChartBar';
import ChartLoading from '../../ChartLoading';
import Text from '../../Text';

const DashboardTimeBarChart = () => {
  const intl = useIntl();

  const { usageTimeByApplications } = useAppSelector(
    (state) => state.dashboard
  );
  const isLoading = useAppSelector(
    selectorLoading(Types.LIST_DEVICES_LOCATION)
  );
  const higherValue = usageTimeByApplications.total;
  const { colors } = useTheme();
  const hasData = usageTimeByApplications.data?.length > 0;
  const CONSUMPTION_LEGEND_LIMITER = 16;

  function convertSecondsToPeriod(secondsTotal: number) {
    const hours = secondsToHours(secondsTotal);
    const minutes = secondsToMinutes(secondsTotal % 3600);
    const seconds = secondsTotal % 60;

    return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  }

  const dataChart = usageTimeByApplications.data
    ?.map((item) => {
      return {
        indexBy: item.applicationName,
        consumptionPercentage:
          higherValue > 0 ? (item.timeOfUse * 100) / higherValue : 0,
        consumption: Math.round(item.timeOfUse / 1000),
      };
    })
    .reverse();

  if (isLoading) {
    return <ChartLoading color="chart.200" />;
  }

  return (
    <Flex align="center" justify="center" h="400px">
      {!hasData ? (
        <Text>
          <FormattedMessage id="dashboard.usage_time_by_application.empty_chart" />
        </Text>
      ) : (
        <ChartBar
          data={dataChart}
          color={colors['chart']['200']}
          keys={['consumptionPercentage']}
          margin={{ top: 50, right: 70, bottom: 20, left: 110 }}
          formatRight={(chartValue) => {
            const applicationTimeLegend = dataChart?.find(
              (applicationTime) => applicationTime?.indexBy === chartValue
            );
            return (
              <>
                <tspan x="0" dy="-0.7em" fill={colors['gray']['900']}>
                  {convertSecondsToPeriod(
                    applicationTimeLegend?.consumption || 0
                  )}
                </tspan>
                <tspan x="0" dy="1.2em" fill={colors['chart']['300']}>
                  {formatNumberWithDecimals(
                    intl,
                    applicationTimeLegend?.consumptionPercentage || 0,
                    1
                  ) + '%' || ''}
                </tspan>
              </>
            );
          }}
          formatTop={(chartValue) => {
            return `${chartValue}%`;
          }}
          formatLeft={(chatValue) => {
            const applicationTimeLegend = dataChart?.find(
              (applicationTime) => applicationTime?.indexBy === chatValue
            );
            return (
              <Tooltip label={applicationTimeLegend?.indexBy}>
                <tspan x="0" dy="0em" fill={colors['chart']['300']}>
                  {truncate(applicationTimeLegend?.indexBy, {
                    length: CONSUMPTION_LEGEND_LIMITER,
                  })}
                </tspan>
              </Tooltip>
            );
          }}
        />
      )}
    </Flex>
  );
};

export default DashboardTimeBarChart;
