import { Flex, SimpleGrid } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import faqImageOne from '../../../assets/Images/help/faq1.png';
// import faqImageTwo from '../../../assets/Images/help/faq2.png';
import faqImageThree from '../../../assets/Images/help/faq3.png';
import FormSubtitle from '../../../components/FormSubtitle';
import Search from '../../../components/Icons/Search';
import Input from '../../../components/Input';
import AccordionCard from '../../../components/pages/Help/FAQ/Accordion';
import Category from '../../../components/pages/Help/FAQ/Category';
import InformationCard from '../../../components/pages/Help/FAQ/InformationCard';
import PageTitle from '../../../components/PageTitle';
import Text from '../../../components/Text';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import routes from '../../../routes';
import { listFaq } from '../../../store/help';
import { FAQType } from '../../../types/help';

interface Question extends FAQType {
  id: number;
}

const FAQ = () => {
  const { faqs } = useAppSelector((state) => state.help);
  const { portalSettings } = useAppSelector((state) => state.portalSettings);
  const suportUrl = portalSettings.supportUrl;
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const ALL_FAQ = intl.formatMessage({ id: 'faq.all' });

  const [filter, setFilter] = useState<string>('');
  const [selectCategory, setSelectCategory] = useState<string>(ALL_FAQ);
  const [questions, setQuestions] = useState<Question[]>();

  const FAQValues = faqs.map((question, index) => ({ ...question, id: index }));

  const categories: string[] = [
    ALL_FAQ,
    ...faqs.map((question) => question?.categories).flat(),
  ].filter(
    (category, index, categories) =>
      categories?.indexOf(category) === index && category
  );

  useEffect(() => {
    dispatch(listFaq());
  }, []);

  useEffect(() => {
    setQuestions(FAQValues);
  }, [faqs]);

  useEffect(() => {
    if (filter) {
      const filteredQuestions = FAQValues.filter(
        ({ answer, question }) =>
          answer?.toLowerCase().includes(filter) ||
          question?.toLowerCase().includes(filter)
      ).flat();
      setQuestions(filteredQuestions);
    }
  }, [filter]);

  useEffect(() => {
    if (selectCategory) {
      const filteredQuestions = FAQValues.filter((questions) =>
        selectCategory === ALL_FAQ
          ? questions
          : questions.categories?.includes(selectCategory)
      );
      setQuestions(filteredQuestions);
    }
  }, [selectCategory]);

  const handleSelectCategory = (name: string) => {
    setSelectCategory(name);
    setFilter('');
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filterValue = event.target.value.toLowerCase();
    setSelectCategory(ALL_FAQ);
    setFilter(filterValue);
  };

  return (
    <Flex flexDirection="column">
      <PageTitle
        showManualIcon
        title={<FormattedMessage id="faq.title" />}
        description={<FormattedMessage id="faq.description" />}
      />
      <Input
        inputProps={{
          placeholder: intl.formatMessage({ id: 'faq.input.placeholder' }),
          display: 'flex',
          onChange: handleFilterChange,
          value: filter,
        }}
        leftElement={<Search boxSize={8} color="gray.600" />}
      />

      <Text fontSize="md" as="i">
        <FormattedMessage id="faq.input.instruction" />
      </Text>
      {FAQValues.length > 0 ? (
        <>
          <SimpleGrid columns={4} gridGap={6} mb={6}>
            {categories.map((category, index) => (
              <Category
                key={index}
                categoryName={category}
                onSelectCategory={handleSelectCategory}
              />
            ))}
          </SimpleGrid>

          <FormSubtitle subtitle={selectCategory}>
            {questions?.length > 0 ? (
              questions?.map((question) => (
                <AccordionCard question={question} key={question.id} />
              ))
            ) : (
              <Text>
                <FormattedMessage id="faq.list.not_found" />
              </Text>
            )}
          </FormSubtitle>
        </>
      ) : (
        <Text>
          <FormattedMessage id="faq.list.empty" />
        </Text>
      )}

      <Flex flexDirection="column" m="3% 10% 3% 10%" gridRowGap={6}>
        <Flex gridColumnGap={5}>
          <InformationCard
            urlImage={faqImageOne}
            title={<FormattedMessage id="faq.card_one.manuals.title" />}
            description={
              <FormattedMessage id="faq.card_one.manuals.description" />
            }
            link={routes.support}
          />
          {portalSettings?.supportUrl && (
            <InformationCard
              urlImage={faqImageThree}
              title={<FormattedMessage id="faq.card_three.suport.title" />}
              description={
                <FormattedMessage id="faq.card_three.suport.description" />
              }
              textLink={
                <FormattedMessage id="faq.card_three.suport.button.link" />
              }
              link={suportUrl}
            />
          )}
          {
            // <InformationCard
            //   urlImage={faqImageTwo}
            //   title={<FormattedMessage id="faq.card_tow.tutorial_video_title" />}
            //   description={
            //     <FormattedMessage id="faq.card_tow.tutorial_video_description" />
            //   }
            // />
          }
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FAQ;
