import { Box, Flex, Link as ChakraLink } from '@chakra-ui/react';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ActionCreator, AnyAction } from 'redux';

import { isValidGmail } from '../../../../helper/email';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import {
  clearHasCustomerId,
  createCustomerId,
  registerCustomerId,
  Types,
} from '../../../../store/invoice';
import {
  hasError,
  hasLoading,
  hasSuccess,
  uiRemoveError,
  uiRemoveSuccess,
} from '../../../../store/ui';
import Button from '../../../Button';
import FormContainer from '../../../FormContainer';
import FormControl from '../../../FormControl';
import Input from '../../../Input';
import Modal from '../../../Modal';
import PageErrors from '../../../PageErrors';
import PageTitle from '../../../PageTitle';
import PinInputs from '../../../PinInputField';
import Text from '../../../Text';
import { apiErrorMessagesByResponse } from '../zeroTouchErros';

interface ModalCustomerIDType {
  isOpen: boolean;
  closeModal: () => void;
}

enum ModalModeEnum {
  CUSTOMER_ID = 'customerId',
  CUSTOMER_EMAIL = 'customerEmail',
  CUSTOMER_TOKEN = 'customerToken',
}

interface EntityModeType {
  onSubmit?: ActionCreator<AnyAction>;
  onChangeMode?: () => void;
  onChangeModeButtonIntl?: string;
  secondaryButtonIntl?: string;
  secondaryButtonLoadingType?: string;
  onClickSecondaryButton?: () => void;
  modalTitle?: string;
  modalDescription?: string;
  propertyToFill?: string;
  submitButtonIntl?: string;
  inputName?: string;
  inputPlaceHolder?: string;
  type?: string;
  onSuccess?: () => void;
  intlDescriptionValues?: Record<string, string>;
}

const ModalCustomerID = ({ isOpen, closeModal }: ModalCustomerIDType) => {
  const dispatch = useAppDispatch();
  const { portalSettings } = useAppSelector((state) => state.portalSettings);
  const intl = useIntl();
  const initialFocusRef = useRef();

  const [form, setForm] = useState<{
    customerId?: string;
    customerEmail?: string;
    customerToken?: string;
  }>({ customerEmail: '', customerId: '', customerToken: '' });
  const [oldEmail, setOldEmail] = useState<string>('');
  const [selectedMode, setSelectedMode] = useState<ModalModeEnum>(
    ModalModeEnum.CUSTOMER_ID
  );
  const isCustomerEmailMode = selectedMode === ModalModeEnum.CUSTOMER_EMAIL;
  const isCustomerIdMode = selectedMode === ModalModeEnum.CUSTOMER_ID;
  const isCustomerTokenMode = selectedMode === ModalModeEnum.CUSTOMER_TOKEN;
  const isNotValidEmail =
    isCustomerEmailMode &&
    form[selectedMode]?.length > 0 &&
    !isValidGmail(form[selectedMode]);
  const disablePrimary = !form[selectedMode] || isNotValidEmail;

  const configByMode: Record<ModalModeEnum, EntityModeType> = {
    [ModalModeEnum.CUSTOMER_EMAIL]: {
      onSubmit: createCustomerId,
      onChangeMode: () => setSelectedMode(ModalModeEnum.CUSTOMER_ID),
      onSuccess: () => {
        setOldEmail(form.customerEmail);
        setSelectedMode(ModalModeEnum.CUSTOMER_TOKEN);
      },
      type: Types.CREATE_CUSTOMER_ID,
    },
    [ModalModeEnum.CUSTOMER_ID]: {
      onSubmit: registerCustomerId,
      onChangeMode: () => setSelectedMode(ModalModeEnum.CUSTOMER_EMAIL),
      type: Types.REGISTER_CUSTOMER_ID,
      onSuccess: closeModal,
      intlDescriptionValues: {
        resellerName: portalSettings?.resellerName,
      },
    },
    [ModalModeEnum.CUSTOMER_TOKEN]: {
      onSuccess: closeModal,
      type: Types.REGISTER_CUSTOMER_ID,
      onSubmit: registerCustomerId,
      onChangeMode: () => {
        dispatch(uiRemoveSuccess(Types.CREATE_CUSTOMER_ID));
        setSelectedMode(ModalModeEnum.CUSTOMER_EMAIL);
      },
      modalDescription: `modal_customer_id.${selectedMode}_description`,
      secondaryButtonIntl: `modal_customer_id.${selectedMode}.secondary_button`,
      onClickSecondaryButton: resendConfirmationEmail,
      secondaryButtonLoadingType: Types.CREATE_CUSTOMER_ID,
      intlDescriptionValues: {
        email: oldEmail,
      },
    },
  };

  const commonConfigs: EntityModeType = {
    modalTitle: `modal_customer_id.${selectedMode}_title`,
    modalDescription: `modal_customer_id.${selectedMode}_description`,
    submitButtonIntl: `modal_customer_id.${selectedMode}.button`,
    onChangeModeButtonIntl: `modal_customer_id.${selectedMode}.link_button`,
    inputName: `modal_customer_id.input.${selectedMode}`,
    inputPlaceHolder: `modal_customer_id.input.${selectedMode}.placeholder`,
    ...configByMode[selectedMode],
  };

  const success = hasSuccess(commonConfigs.type);
  const error = hasError(commonConfigs.type);
  const isLoading = hasLoading(commonConfigs.type);
  const isLoadingSecondaryButton = hasLoading(
    commonConfigs.secondaryButtonLoadingType
  );

  useEffect(() => {
    if (success && commonConfigs.onSuccess) {
      commonConfigs.onSuccess?.();
    }
  }, [success]);

  useEffect(() => {
    dispatch(uiRemoveError(commonConfigs.type));
  }, [selectedMode]);

  useEffect(() => {
    return () => {
      dispatch(clearHasCustomerId());
    };
  }, []);

  const handleSubmit = () => {
    dispatch(
      commonConfigs.onSubmit({
        customerId: form?.customerId,
        email: isCustomerTokenMode ? oldEmail : form?.customerEmail,
        token: form?.customerToken,
      })
    );
  };

  function resendConfirmationEmail() {
    dispatch(
      createCustomerId({
        email: oldEmail,
      })
    );
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event?.target;
    setForm({
      [name]: value,
    });
  };

  return (
    <Modal
      initialFocusRef={initialFocusRef}
      isOpen={isOpen}
      onClose={closeModal}
      size="xl"
    >
      <Flex align="center" w="full" p="5%">
        <Flex h="100%" flexDirection="column" justify="space-around" w="100%">
          <PageTitle
            title={<FormattedMessage id={commonConfigs.modalTitle} />}
            description={
              <Text>
                <FormattedMessage
                  id={commonConfigs.modalDescription}
                  values={commonConfigs.intlDescriptionValues}
                />
                {isCustomerIdMode && (
                  <ChakraLink
                    color="primary.500"
                    lineHeight="40px"
                    href="https://partner.android.com/zerotouch"
                    isExternal
                    ml="1"
                  >
                    <FormattedMessage id="modal_customer_id.customerId.subscribe_button_text" />
                  </ChakraLink>
                )}
              </Text>
            }
          />

          <PageErrors
            toasterKeys={[commonConfigs.type]}
            messageError={
              <FormattedMessage
                id={
                  apiErrorMessagesByResponse[error?.message] ||
                  apiErrorMessagesByResponse.default
                }
              />
            }
            translateKey="modal_customer_id"
            w="full"
          />
          <FormContainer
            labelPrimary={
              <FormattedMessage id={commonConfigs.submitButtonIntl} />
            }
            handlePrimary={handleSubmit}
            labelSecondary={
              <FormattedMessage id="modal_customer_id.button.do_later" />
            }
            handleSecondary={closeModal}
            loadingPrimary={isLoading}
            disabledPrimary={disablePrimary}
            divider={false}
          >
            <FormControl
              textLabel={<FormattedMessage id={commonConfigs.inputName} />}
              mb="10%"
            >
              {selectedMode === ModalModeEnum.CUSTOMER_TOKEN ? (
                <PinInputs
                  onChange={(value) => setForm({ customerToken: value })}
                  numberOfInputs={6}
                />
              ) : (
                <Input
                  inputProps={{
                    name: selectedMode,
                    value: form[selectedMode] || '',
                    onChange: handleChange,
                    placeholder: intl.formatMessage({
                      id: commonConfigs.inputPlaceHolder,
                    }),
                    type: isCustomerEmailMode ? 'email' : 'text',
                    required: true,
                  }}
                />
              )}

              <Box
                color="warning.500"
                fontSize="sm"
                fontWeight="bold"
                h="21px"
                m="0"
              >
                {isNotValidEmail && (
                  <FormattedMessage id="modal_customer_id.customerEmail.valid_gmail" />
                )}
              </Box>
              <Flex py="3" gridGap={6}>
                <Button
                  variant="link"
                  onClick={commonConfigs.onChangeMode}
                  testId="submit"
                >
                  <FormattedMessage id={commonConfigs.onChangeModeButtonIntl} />
                </Button>

                {commonConfigs.onClickSecondaryButton && (
                  <Button
                    variant="link"
                    onClick={commonConfigs.onClickSecondaryButton}
                    testId="cancel"
                    isLoading={isLoadingSecondaryButton}
                  >
                    <FormattedMessage id={commonConfigs.secondaryButtonIntl} />
                  </Button>
                )}
              </Flex>
            </FormControl>
          </FormContainer>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default ModalCustomerID;
