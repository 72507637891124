import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Map, MarkerMapProps } from '../../../Map';
import Spinner from '../../../Spinner';
import Text from '../../../Text';
import { MapInfoWindow, MarkerInfosProps } from './MapInfoWindow';

interface DashboardMapProps {
  loading?: boolean;
  isEmpty?: boolean;
  devicesMarkers?: MarkerMapProps<MarkerInfosProps>[];
}

const DashboardMap = ({
  isEmpty,
  devicesMarkers,
  loading,
}: DashboardMapProps) => {
  if (loading) {
    return <Spinner size={'xl'} />;
  }

  if (isEmpty) {
    return (
      <Text>
        <FormattedMessage id="map.empty" />
      </Text>
    );
  }

  return (
    <Map markers={devicesMarkers} WindowCloud={MapInfoWindow} hasClusterer />
  );
};

export default DashboardMap;
