import { Box } from '@chakra-ui/react';
import React from 'react';

const ScreenDevice = () => {
  return (
    <Box h="552px" w="full" borderRadius="8px 8px 0 0" bgColor="gray.500" />
  );
};

export default ScreenDevice;
