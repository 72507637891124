import { Flex } from '@chakra-ui/react';
import React from 'react';

import Card from '../Card';
import Spinner from '../Spinner';

function LoadingMap() {
  return (
    <Card
      as={Flex}
      alignItems="center"
      justifyContent="center"
      height="485px"
      w="100%"
    >
      <Spinner size={'xl'} />
    </Card>
  );
}

export default LoadingMap;
