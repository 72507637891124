import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Checkbox, Image, Box, Flex, useTheme } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useHistory, useLocation } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

import Alert, { AlertHtml } from '../../components/Alert';
import Button from '../../components/Button';
import FormControl from '../../components/FormControl';
import Input from '../../components/Input';
import InputPassword from '../../components/InputPassword';
import ErrorModal, {
  AuthCredentialErrorEnum,
} from '../../components/pages/Login/ErrorModal';
import Text from '../../components/Text';
import { validatorDefaultMessages } from '../../helper/validador';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import routes from '../../routes';
import { authSetEmailConfirmation, login, Types } from '../../store/auth';
import { hasError, selectorLoading, uiRemoveError } from '../../store/ui';
import { LoginFormType } from '../../types/loginAdminUser';
import '../../assets/css/form-validation.css';
import { HistoryState } from '../../types/route';

const Login = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation<HistoryState>();
  const [simpleValidator, setSimpleValidator] =
    useState<SimpleReactValidator>();
  const { images } = useTheme();

  const [form, setForm] = useState<LoginFormType>({
    email: '',
    password: '',
    keepLogged: false,
  });
  const [open, setOpen] = useState(false);
  const [expirationSessionOk, setExpirationSessionOk] = useState(false);

  const { loginLanguage } = useAppSelector((state) => state.auth);
  const error = hasError(Types.LOGIN);
  const isLoading = useAppSelector(selectorLoading(Types.LOGIN));

  useEffect(() => {
    setSimpleValidator(
      new SimpleReactValidator({
        messages: {
          ...validatorDefaultMessages(intl),
        },
      })
    );
    simpleValidator?.showMessages();
  }, [loginLanguage]);

  useEffect(() => {
    if (error?.message === AuthCredentialErrorEnum.LOGIN_CONFIRMATION) {
      history.push(routes.loginConfirmation);
      dispatch(uiRemoveError(Types.LOGIN));
    } else {
      setOpen(!!error);
    }
  }, [error]);

  useEffect(() => {
    return () => {
      dispatch(uiRemoveError(Types.LOGIN));
    };
  }, []);

  useEffect(() => {
    if (location?.state?.fromExpiration && !expirationSessionOk) {
      setExpirationSessionOk(true);
      Alert({
        disabledCancel: true,
        html: (
          <AlertHtml
            text={intl.formatMessage({ id: 'inactivity_alert.after_redirect' })}
          />
        ),
      });

      history.replace({
        pathname: history.location.pathname,
        state: {},
      });
    }
  }, [location?.state?.fromExpiration]);

  const updateForm = (e) => {
    setForm({
      ...form,
      [e.target.name]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    });
    simpleValidator?.showMessages();
  };

  const submit = (event) => {
    event.preventDefault();
    dispatch(
      authSetEmailConfirmation({
        email: form.email,
        keepLogged: form.keepLogged,
      })
    );

    const filledForm = {
      ...form,
      url: `${window.location.origin}${routes.passwordChange}`,
    };

    dispatch(login(filledForm));
  };

  return (
    <>
      <Box pt="60px">
        <Image
          src={images?.logo}
          maxWidth="250px"
          maxHeight="55px"
          alt="logo"
        />
      </Box>
      <form onSubmit={submit} noValidate>
        <Box pt="8">
          <Text fontSize="4xl" fontWeight="300" color="gray.900" m="0">
            <FormattedMessage id="login.welcome_1" />
          </Text>
          <Text fontSize="4xl" as="b" color="gray.900" fontWeight="700">
            <FormattedMessage id="login.welcome_2" />
          </Text>
        </Box>
        <Flex flexDir={'column'} minW="xs" pt="40px" gridGap="2">
          <FormControl
            textLabel={<FormattedMessage id="global.email" />}
            mb="2"
          >
            <Input
              inputProps={{
                placeholder: intl.formatMessage({
                  id: 'login.email_placeholder',
                }),
                type: 'email',
                onChange: updateForm,
                value: form.email,
                id: 'email',
                name: 'email',
                autoFocus: true,
              }}
            />
            <Box
              color="warning.500"
              fontSize="sm"
              fontWeight="bold"
              h="21px"
              m="0"
            >
              {simpleValidator?.message('email', form.email, 'required|email')}
            </Box>
          </FormControl>

          <FormControl
            textLabel={<FormattedMessage id="global.password" />}
            mb="2"
          >
            <InputPassword
              inputProps={{
                autoComplete: 'off',
                placeholder: intl.formatMessage({
                  id: 'login.password_placeholder',
                }),
                onChange: updateForm,
                value: form.password,
                name: 'password',
              }}
            />
          </FormControl>
        </Flex>

        <Box minW="xs">
          <Text
            fontSize="sm"
            color="primary.500"
            m="20px 0px 40px 0px"
            cursor="pointer"
          >
            <Link to={routes.passwordRecovery}>
              <FormattedMessage id="login.forgot_password" />
              <ArrowForwardIcon ml="3px" />
            </Link>
          </Text>
        </Box>
        <Box>
          <Button
            type="submit"
            w="170px"
            h="45px"
            disabled={!simpleValidator?.allValid()}
            isLoading={isLoading}
          >
            <FormattedMessage id="login.btn_submit" />
          </Button>
        </Box>
        <Box mt="20px">
          <Checkbox
            colorScheme="success"
            name="keepLogged"
            isChecked={form.keepLogged}
            onChange={updateForm}
          >
            <Text fontSize="sm" color="gray.500">
              <FormattedMessage id="login.keep_connected" />
            </Text>
          </Checkbox>
        </Box>
      </form>
      <ErrorModal open={open} onClose={() => setOpen(false)} error={error} />
    </>
  );
};

export default Login;
