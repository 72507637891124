import { storageKey } from '../store/persister';
import { useAppSelector } from './useRedux';
import { useStorage } from './useStorage';

interface RemoteViewType {
  handleRemoveLocalStorage?: () => void;
  handleKeepConnected?: () => void;
}

export const useRemoteView = (): RemoteViewType => {
  const auth = useAppSelector((state) => state.auth);
  const portalSettings = useAppSelector((state) => state.portalSettings);
  const { setItem, removeItem } = useStorage(localStorage);

  const handleKeepConnected = () => {
    if (auth?.keepLogged) return;

    setItem(storageKey, {
      ...auth,
      ...portalSettings,
    });
  };

  const handleRemoveLocalStorage = () => {
    if (auth?.keepLogged) return;
    removeItem(storageKey);
  };

  return { handleKeepConnected, handleRemoveLocalStorage };
};
