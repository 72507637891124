import { Flex } from '@chakra-ui/react';
import {
  translateInstallationFailureReasonEnum,
  translatePasswordPolicyScopeEnum,
} from 'portal-lib/dist/helpers/device/nonCompliance';
import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { DeviceInfoNonComplianceType } from '../../../../../types/deviceInfo';
import Text from '../../../../Text';

interface ListDrillDownProps {
  nonCompliance: DeviceInfoNonComplianceType;
}

const ListDrillDown = ({ nonCompliance }: ListDrillDownProps) => {
  const intl = useIntl();

  function ItemList({ label, value }: { label: ReactNode; value: ReactNode }) {
    return (
      <Text>
        <strong>{label}: </strong>
        {value}
      </Text>
    );
  }

  return (
    <Flex p="30px 0 30px 70px">
      <Flex flexDirection="column">
        <ItemList
          label={intl.formatMessage({
            id: 'non_compliance.expander.packageName',
          })}
          value={nonCompliance?.packageName}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'non_compliance.expander.fieldPath',
          })}
          value={nonCompliance?.fieldPath}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'non_compliance.expander.currentValue',
          })}
          value={nonCompliance?.currentValue}
        />
      </Flex>
      <Flex flexDirection="column" ml="100px">
        <ItemList
          label={intl.formatMessage({
            id: 'non_compliance.expander.installationFailureReason',
          })}
          value={
            translateInstallationFailureReasonEnum[
              nonCompliance?.installationFailureReason
            ]
          }
        />
        <ItemList
          label={intl.formatMessage({
            id: 'non_compliance.expander.wifi_context',
          })}
          value={nonCompliance?.wifiGuid}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'non_compliance.expander.password_context',
          })}
          value={
            translatePasswordPolicyScopeEnum[nonCompliance?.passwordPolicyScope]
          }
        />
      </Flex>
    </Flex>
  );
};

export default ListDrillDown;
