import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import FormContainer from '../../../components/FormContainer';
import FormControl from '../../../components/FormControl';
import Copy from '../../../components/Icons/Copy';
import Documents from '../../../components/Icons/Documents';
import Edit from '../../../components/Icons/Edit';
import OperationalSystemIcon from '../../../components/Icons/OperationalSystemIcon';
import {
  ManagementModeOption,
  ManagementModeSelect,
} from '../../../components/ManagementModeSelect';
import MenuItemWithTooltip from '../../../components/MenuItemWithTooltip';
import PageFilter from '../../../components/PageFilter';
import ModalEnrollmentToken from '../../../components/pages/Policies/ManagePolicies/ModalEnrollmentToken';
import ModalPolicy from '../../../components/pages/Policies/ModalCopy';
import PageShowToaster from '../../../components/PageShowToaster';
import PageTitle from '../../../components/PageTitle';
import Select from '../../../components/Select';
import Table from '../../../components/Table';
import TableActions from '../../../components/TableActions';
import { routeWithParameters } from '../../../helper';
import { inFirstPage } from '../../../helper/metadata';
import { featurePlanKeys } from '../../../helper/plan';
import { useSorting } from '../../../helper/sort';
import { useFormatDate } from '../../../hooks/useFormatDate';
import { usePlan } from '../../../hooks/usePlan';
import { usePrivilege } from '../../../hooks/usePrivilege';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import routes from '../../../routes';
import { generateExcel } from '../../../store/event';
import { deletePolicy, listPolicies, Types } from '../../../store/policy';
import {
  hasSuccess,
  selectorMetadata,
  uiRemoveMetadata,
  uiRemoveTable,
} from '../../../store/ui';
import { ListMetadata } from '../../../types/generic_list';
import {
  KioskCustomLauncherEnabledEnum,
  PlayStoreModeEnum,
  OperationalSystemEnum,
  PolicyType,
  ManagementModeEnum,
} from '../../../types/policy';
import { ID } from '../../../types/util';
import { operationalSystemToIntl } from '../EditPolicies';
import { managementModeToIntl } from '../RegisterPolicy';

const ManagePolicies = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const history = useHistory();
  const { handleFormatDateByUserLogged } = useFormatDate();
  const { isHelpDesk } = usePrivilege();
  const { policies } = useAppSelector((state) => state.policy);
  const metadata = useAppSelector(selectorMetadata(Types.LIST));

  const { hasFeaturePlan: allowFeatureBlockSimPolicy } = usePlan({
    featurePlanKey: featurePlanKeys.allowBlockSimPolicy,
  });
  const { hasFeaturePlan: allowFeatureIOSSupervisedPolicy } = usePlan({
    featurePlanKey: featurePlanKeys.allowIOSSupervisedPolicy,
  });
  const successOnDelete = hasSuccess(Types.DELETE);

  const [filterSearch, setFilterSearch] = useState('');
  const [filters, setFilters] = useState({
    kioskCustomLauncherEnabled: '',
    managementMode: null,
    playStoreMode: '',
  });
  const [viewEnrollmentToken, setViewEnrollmentToken] = useState(null);
  const [copySelectedPolicy, setCopySelectedPolicy] =
    useState<PolicyType | null>(null);

  const kioskCustomLauncherEnabledToFilter = {
    [KioskCustomLauncherEnabledEnum.YES]: true,
    [KioskCustomLauncherEnabledEnum.NO]: false,
  };

  const playStoreToIntl = {
    [PlayStoreModeEnum.BLACKLIST]: 'manage_policies.data.play_store.blacklist',
    [PlayStoreModeEnum.WHITELIST]: 'manage_policies.data.play_store.whitelist',
  };

  const allFilters = {
    search: filterSearch,
    kioskCustomLauncherEnabled:
      kioskCustomLauncherEnabledToFilter[
        filters?.kioskCustomLauncherEnabled
      ]?.toString(),
    managementMode: filters?.managementMode?.value,
    playStoreMode: filters?.playStoreMode,
  };

  useEffect(() => {
    dispatch(listPolicies(inFirstPage(metadata), allFilters));
    return () => {
      dispatch(uiRemoveMetadata(Types.LIST));
      dispatch(uiRemoveTable(Types.LIST));
    };
  }, [filterSearch, filters, successOnDelete]);

  const policyAttributes = {
    [OperationalSystemEnum.ANDROID]: 'androidPolicy',
    [OperationalSystemEnum.IOS]: 'iosPolicy',
  };

  const playStoreOptions = [
    {
      label: intl.formatMessage({
        id: 'manage_policies.filter.play_store.open',
      }),
      value: PlayStoreModeEnum.BLACKLIST,
    },
    {
      label: intl.formatMessage({
        id: 'manage_policies.filter.play_store.restricted',
      }),
      value: PlayStoreModeEnum.WHITELIST,
    },
  ];

  const kioskOptions = [
    {
      label: intl.formatMessage({
        id: 'manage_policies.filter.kiosk.true',
      }),
      value: KioskCustomLauncherEnabledEnum.YES,
    },
    {
      label: intl.formatMessage({
        id: 'manage_policies.filter.kiosk.false',
      }),
      value: KioskCustomLauncherEnabledEnum.NO,
    },
  ];

  const handleMetadata = (newMetadata: Partial<ListMetadata>) => {
    dispatch(listPolicies({ ...metadata, ...newMetadata }, allFilters));
  };

  const handleCopyPolicy = (policy: PolicyType) => {
    setCopySelectedPolicy(policy);
  };

  const handleDeletePolicy = (id: ID) => {
    dispatch(deletePolicy(id));
  };

  const handleFilterPolicy = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleFilterByManagementMode = (option: ManagementModeOption) => {
    setFilters({ ...filters, managementMode: option });
  };

  const handleDisableTableActionButton = (policy: PolicyType) => {
    const disableActionButtonByManagementMode = {
      [ManagementModeEnum.BLOCK_SIM_ANDROID]: !allowFeatureBlockSimPolicy,
      [ManagementModeEnum.IOS_SUPERVISED]: !allowFeatureIOSSupervisedPolicy,
      default: false,
    };

    return (
      disableActionButtonByManagementMode[policy?.managementMode] ||
      disableActionButtonByManagementMode.default
    );
  };

  const headersColumn = useSorting(
    [
      {
        header: '',
        accessor: 'systemIcon',
        isExportHidden: true,
      },
      {
        accessor: 'operationalSystem',
        header: intl.formatMessage({
          id: 'global.operational_system',
        }),
        isExportField: true,
      },
      {
        header: intl.formatMessage({
          id: 'manage_policies.column.name',
        }),
        accessor: 'name',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: 'manage_policies.column.management_mode',
        }),
        accessor: 'managementMode',
      },
      {
        header: intl.formatMessage({
          id: 'manage_policies.column.play_store_mode',
        }),
        accessor: 'playStoreMode',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: 'manage_policies.column.kiosk_custom_launcher_enabled',
        }),
        accessor: 'kioskCustomLauncherEnabled',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: 'manage_policies.column.devices_count',
        }),
        accessor: 'devicesCount',
      },
      {
        header: intl.formatMessage({
          id: 'manage_policies.column.created_at',
        }),
        accessor: 'createdAt',
        canSort: true,
      },
    ],
    metadata
  );

  const data = policies?.map((policy) => ({
    key: String(policy?.id),
    cells: [
      {
        value: (
          <OperationalSystemIcon
            operationalSystem={policy?.operationalSystem}
          />
        ),
        field: 'systemIcon',
        isExportHidden: true,
        chakraProps: { px: 0 },
      },
      {
        field: 'operationalSystem',
        value: intl.formatMessage({
          id: `${operationalSystemToIntl[policy?.operationalSystem]}`,
        }),
        isExportField: true,
      },
      {
        field: 'name',
        value: policy?.name,
      },
      {
        field: 'managementMode',
        value: intl.formatMessage({
          id: `${managementModeToIntl[policy?.managementMode]}`,
        }),
      },
      {
        field: 'playStoreMode',
        value:
          policy?.operationalSystem === OperationalSystemEnum.ANDROID
            ? intl.formatMessage({
                id: `${
                  playStoreToIntl[
                    policy[policyAttributes[policy.operationalSystem]]
                      ?.playStoreMode
                  ]
                }`,
              })
            : '',
      },
      {
        field: 'kioskCustomLauncherEnabled',
        value:
          policy?.operationalSystem === OperationalSystemEnum.ANDROID
            ? intl.formatMessage({
                id: policy[policyAttributes[policy?.operationalSystem]]
                  ?.kioskCustomLauncherEnabled
                  ? 'manage_policies.data.kiosk.yes'
                  : 'manage_policies.data.kiosk.no',
              })
            : '',
      },
      {
        field: 'devicesCount',
        value: policy?.devicesCount,
      },
      {
        field: 'createdAt',
        value: handleFormatDateByUserLogged(policy?.createdAt),
      },
      {
        value: '',
        field: 'actions',
        transform: () => {
          return (
            <TableActions
              entityIntlLabel={<FormattedMessage id="manage_policies.policy" />}
              disableDestroy={isHelpDesk}
              openDestroy={() => {
                handleDeletePolicy(policy?.id);
              }}
              textToConfirm={`${intl.formatMessage({
                id: 'manage_policies.confirm.text',
              })}`}
              moreItems={
                <>
                  <MenuItemWithTooltip
                    icon={<Edit boxSize={6} mr="5px" />}
                    text={
                      <FormattedMessage id="manage_policies.table_actions.edit_policy" />
                    }
                    onClick={() =>
                      history.push(
                        routeWithParameters(routes.policies.edit, {
                          id: policy?.id,
                        })
                      )
                    }
                    isDisabled={handleDisableTableActionButton(policy)}
                    labelTooltip={intl.formatMessage({
                      id: 'manage_policies.android_block_sim.tooltip',
                    })}
                    disabledTooltip={!handleDisableTableActionButton(policy)}
                  />
                  {policy?.operationalSystem ===
                    OperationalSystemEnum.ANDROID && (
                    <MenuItemWithTooltip
                      icon={<Documents boxSize={6} mr="5px" />}
                      text={
                        <FormattedMessage id="manage_policies.table_actions.enrollment_token" />
                      }
                      onClick={() => setViewEnrollmentToken(policy?.id)}
                      isDisabled={handleDisableTableActionButton(policy)}
                      labelTooltip={intl.formatMessage({
                        id: 'manage_policies.android_block_sim.tooltip',
                      })}
                      disabledTooltip={!handleDisableTableActionButton(policy)}
                    />
                  )}
                  <MenuItemWithTooltip
                    icon={<Copy boxSize={6} mr="5px" />}
                    text={
                      <FormattedMessage id="manage_policies.table_actions.copy" />
                    }
                    onClick={() => handleCopyPolicy(policy)}
                    isDisabled={handleDisableTableActionButton(policy)}
                    labelTooltip={intl.formatMessage({
                      id: 'manage_policies.android_block_sim.tooltip',
                    })}
                    disabledTooltip={!handleDisableTableActionButton(policy)}
                  />
                </>
              }
            />
          );
        },
      },
    ],
  }));

  const handleExportAllToExcel = () => {
    dispatch(
      generateExcel({
        type: Types.LIST,
        metadata: metadata,
        filters: allFilters,
      })
    );
  };

  return (
    <>
      <PageTitle
        showManualIcon
        title={<FormattedMessage id="manage_policies.title" />}
        description={<FormattedMessage id="manage_policies.description" />}
      />
      <PageShowToaster
        toasterKeys={[Types.CREATE, Types.COPY]}
        messageSuccess={
          <FormattedMessage id="manage_policies.success.create" />
        }
        disabledError
      />
      <PageShowToaster
        toasterKeys={[Types.DELETE]}
        messageSuccess={
          <FormattedMessage id="manage_policies.success.delete" />
        }
      />
      <FormContainer>
        <PageFilter>
          <FormControl
            textLabel={
              <FormattedMessage id="manage_policies.filter.management_mode" />
            }
          >
            <ManagementModeSelect
              name="managementMode"
              value={filters.managementMode}
              onChange={handleFilterByManagementMode}
            />
          </FormControl>

          <FormControl
            textLabel={
              <FormattedMessage id="manage_policies.filter.play_store" />
            }
          >
            <Select
              name="playStoreMode"
              placeholder={intl.formatMessage({
                id: 'manage_policies.filter.placeholder',
              })}
              value={filters.playStoreMode}
              onChange={handleFilterPolicy}
            >
              {playStoreOptions.map((playStore) => (
                <option key={playStore.value} value={playStore.value}>
                  {playStore.label}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl
            textLabel={
              <FormattedMessage id="manage_policies.filter.kiosk_custom_launcher_enabled" />
            }
          >
            <Select
              name="kioskCustomLauncherEnabled"
              placeholder={intl.formatMessage({
                id: 'manage_policies.filter.placeholder',
              })}
              value={filters.kioskCustomLauncherEnabled}
              onChange={handleFilterPolicy}
            >
              {kioskOptions.map((kiosk) => (
                <option key={kiosk.value} value={kiosk.value}>
                  {kiosk.label}
                </option>
              ))}
            </Select>
          </FormControl>
        </PageFilter>
      </FormContainer>
      <Table
        headerColumns={headersColumn}
        rows={data}
        handleSort={handleMetadata}
        metadata={metadata}
        keyProp={Types.LIST}
        pageActionsProps={{
          linkNew: routes.policies.register,
          handleExportAllToExcel,
          labelButtonNew: <FormattedMessage id={'manage_policies.new'} />,
          onSearch: setFilterSearch,
        }}
        nameTable={`${intl.formatMessage({ id: 'manage_policies.title' })}`}
      />

      {!!copySelectedPolicy && (
        <ModalPolicy
          isOpen={!!copySelectedPolicy}
          policy={copySelectedPolicy}
          closeModal={() => setCopySelectedPolicy(null)}
        />
      )}
      <ModalEnrollmentToken
        isOpen={!!viewEnrollmentToken}
        onClose={() => setViewEnrollmentToken(null)}
        policyId={viewEnrollmentToken}
      />
    </>
  );
};

export default ManagePolicies;
