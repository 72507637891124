import { addMonths, format, lastDayOfMonth } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import DatePicker from '../../../components/Datepicker';
import FormContainer from '../../../components/FormContainer';
import FormControl from '../../../components/FormControl';
import PageFilter from '../../../components/PageFilter';
import changeOptionsSelect from '../../../components/pages/Geolocation/SelectOptions';
import PageTitle from '../../../components/PageTitle';
import Select from '../../../components/Select';
import SelectAutocomplete from '../../../components/SelectAutocomplete';
import Table from '../../../components/Table';
import TimePicker from '../../../components/TimePicker/TimePicker';
import { inFirstPage } from '../../../helper/metadata';
import { featurePlanKeys } from '../../../helper/plan';
import { useSorting } from '../../../helper/sort';
import { useFormatDate } from '../../../hooks/useFormatDate';
import { useAppDispatchByPlan, useAppSelector } from '../../../hooks/useRedux';
import { listDeviceUserToFilter } from '../../../store/deviceUser';
import { generateExcel } from '../../../store/event';
import { listlocationHistoric, Types } from '../../../store/location';
import {
  hasTable,
  selectorLoading,
  selectorMetadata,
  uiRemoveMetadata,
  uiRemoveTable,
  uiShowTable,
} from '../../../store/ui';
import { DeviceUserType } from '../../../types/deviceUser';

interface LocationFilterType {
  month: Date;
  day?: Date;
  startHour?: Date;
  endHour?: Date;
  user?: DeviceUserType;
  precision?: number;
  search?: string;
}

const FIRST_DAY = 1;

const LocationHistory = () => {
  const today = new Date();
  const MIN_HOUR = new Date(new Date().setHours(0, 0));
  const MAX_HOUR = new Date(new Date().setHours(23, 45));
  const { locationHistories } = useAppSelector((state) => state.location);
  const { devicesUsersToFilter } = useAppSelector((state) => state.deviceUser);
  const { portalSettings } = useAppSelector((state) => state.portalSettings);
  const { handleFormatDateByUserLogged } = useFormatDate();
  const [filterLocation, setFilterLocation] = useState<LocationFilterType>({
    month: today,
  });

  const [lastFetch, setLastFetch] = useState({
    month: filterLocation.month,
    day: null,
    startHour: null,
    endHour: null,
    precision: null,
    user: null,
    search: '',
  });

  const loadings = useAppSelector(selectorLoading(Types.LIST_HISTORIC));
  const metadata = useAppSelector(selectorMetadata(Types.LIST_HISTORIC));

  const allFilters = {
    month: filterLocation.month,
    day: filterLocation.day,
    startHour:
      filterLocation.startHour && format(filterLocation.startHour, 'HH:mm:ss'),
    endHour:
      filterLocation.endHour && format(filterLocation.endHour, 'HH:mm:ss'),
    precision: filterLocation.precision,
    user: filterLocation.user?.id,
    search: filterLocation?.search,
  };

  const showTable = hasTable(Types.LIST_HISTORIC);

  const intl = useIntl();
  const dispatch = useAppDispatchByPlan(featurePlanKeys.allowLocalization);

  const firstDayOfMonth = filterLocation.month
    ? new Date(
        filterLocation.month.getFullYear(),
        filterLocation.month.getMonth(),
        FIRST_DAY
      )
    : null;
  const lastDayOfMontht = lastDayOfMonth(filterLocation.month);

  useEffect(() => {
    dispatch(listDeviceUserToFilter());
  }, []);

  useEffect(() => {
    if (showTable) {
      setFilterLocation(filterLocation);
      dispatch(
        listlocationHistoric(inFirstPage(metadata), { ...filterLocation })
      );
    }
  }, [allFilters.search]);

  useEffect(() => {
    return () => {
      dispatch(uiRemoveMetadata(Types.LIST_HISTORIC));
      dispatch(uiRemoveTable(Types.LIST_HISTORIC));
    };
  }, []);

  const handleMetadata = (value) => {
    dispatch(listlocationHistoric({ ...metadata, ...value }, allFilters));
  };
  const handleFilter = () => {
    setLastFetch(allFilters);
    dispatch(uiShowTable(Types.LIST_HISTORIC));
    dispatch(listlocationHistoric(inFirstPage(metadata), allFilters));
  };
  const handlePeriodFilterChange = (date: Date, field: string) => {
    setFilterLocation({ ...filterLocation, [field]: date });
  };
  const handleFilterPrecision = (
    precision: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFilterLocation({
      ...filterLocation,
      ['precision']: parseInt(precision.target.value),
    });
  };
  const handleFilterPhone = (newFilter: DeviceUserType) => {
    setFilterLocation({
      ...filterLocation,
      ['user']: newFilter,
    });
    dispatch(listDeviceUserToFilter());
  };

  const handleFilterSearch = (newSearch: string) => {
    setFilterLocation({
      ...filterLocation,
      search: newSearch,
    });
    setLastFetch({
      ...allFilters,
      search: newSearch,
    });
  };

  const handleFilterPhoneChange = (value) => {
    dispatch(listDeviceUserToFilter({ search: value }));
  };
  const data = locationHistories.map((location) => ({
    cells: [
      {
        field: 'user',
        value: location.user,
      },
      {
        field: 'phone',
        value: location.phone,
      },
      {
        field: 'lat_long',
        value: `${location.latitude} , ${location.longitude}`,
      },
      {
        field: 'address',
        value: location.address,
      },
      {
        field: 'precision',
        value: parseInt(location.precision).toFixed(0),
      },
      {
        field: 'date',
        value: handleFormatDateByUserLogged(location.date),
      },
    ],
  }));
  const columns = useSorting(
    [
      {
        header: intl.formatMessage({
          id: 'location_history.column.user',
        }),
        accessor: 'user',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: 'location_history.column.phone_number',
        }),
        accessor: 'phone',
        canSort: true,
      },

      {
        header: intl.formatMessage({
          id: 'location_history.column.lat_long',
        }),
        accessor: 'lat_long',
        canSort: false,
      },

      {
        header: intl.formatMessage({
          id: 'location_history.column.address',
        }),
        accessor: 'address',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: 'location_history.column.precision',
        }),
        accessor: 'precision',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: 'location_history.column.date',
        }),
        accessor: 'date',
        canSort: true,
      },
    ],
    metadata
  );

  const handleExportAllToExcel = () => {
    dispatch(
      generateExcel({
        type: Types.LIST_HISTORIC,
        metadata: metadata,
        filters: lastFetch,
      })
    );
  };

  return (
    <>
      <PageTitle
        showManualIcon
        title={<FormattedMessage id="location_history.title" />}
        description={<FormattedMessage id="location_history.subtitle" />}
        showOperationalSystems={portalSettings.allowDeviceIOS}
      />
      <FormContainer
        labelFilter={<FormattedMessage id="global.search" />}
        handleFilter={handleFilter}
        loadingFilter={loadings}
      >
        <PageFilter>
          <FormControl
            textLabel={<FormattedMessage id="location_history.month" />}
          >
            <DatePicker
              selected={filterLocation.month}
              dateFormat="MMMM"
              showMonthYearPicker
              showTwoColumnMonthYearPicker
              onChange={(e) => {
                handlePeriodFilterChange(e, 'month');
              }}
              minDate={addMonths(
                new Date(today.getFullYear(), today.getMonth(), 1),
                -5
              )}
              maxDate={today}
            />
          </FormControl>
          <FormControl
            textLabel={<FormattedMessage id="location_history.day" />}
          >
            <DatePicker
              selected={filterLocation.day}
              openToDate={filterLocation.day}
              dateFormat="dd"
              disabled={!filterLocation.month}
              minDate={firstDayOfMonth}
              maxDate={today < lastDayOfMontht ? today : lastDayOfMontht}
              onChange={(e) => {
                handlePeriodFilterChange(e, 'day');
              }}
              checkValue={filterLocation.day || null}
              hasValue={!!filterLocation.day}
            />
          </FormControl>
          <FormControl
            textLabel={<FormattedMessage id="location_history.initial_time" />}
          >
            <TimePicker
              textLabel={intl.formatMessage({
                id: 'location_history.select',
              })}
              onChange={(e) => {
                handlePeriodFilterChange(e, 'startHour');
              }}
              selected={filterLocation.startHour}
              minTime={!!filterLocation.endHour && MIN_HOUR}
              maxTime={!!filterLocation.endHour && filterLocation.endHour}
              hasValue={!!filterLocation.startHour}
              readOnly={!filterLocation?.day}
              disabled={!filterLocation?.day}
            />
          </FormControl>
          <FormControl
            textLabel={<FormattedMessage id="location_history.final_time" />}
          >
            <TimePicker
              textLabel={intl.formatMessage({
                id: 'location_history.select',
              })}
              minTime={!!filterLocation.startHour && filterLocation.startHour}
              maxTime={!!filterLocation.startHour && MAX_HOUR}
              selected={filterLocation.endHour}
              onChange={(e) => {
                handlePeriodFilterChange(e, 'endHour');
              }}
              hasValue={!!filterLocation.endHour}
              readOnly={!filterLocation?.day}
              disabled={!filterLocation?.day}
            />
          </FormControl>
          <FormControl
            textLabel={<FormattedMessage id="location_history.precision" />}
          >
            <Select
              name="precision"
              value={filterLocation.precision}
              onChange={handleFilterPrecision}
            >
              {changeOptionsSelect('precision').map((m) => (
                <option key={m.id} value={m.id}>
                  {m.label}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl
            textLabel={<FormattedMessage id="location_history.user" />}
          >
            <SelectAutocomplete
              options={devicesUsersToFilter}
              value={filterLocation.user}
              onChange={handleFilterPhone}
              onInputChange={handleFilterPhoneChange}
              getOptionLabel={(option) =>
                `${option.name || ''} ${option.device.phoneNumber || ''}`
              }
              placeholder={<FormattedMessage id="global.phone" />}
              customStyles={{
                maxWidth: '240px',
              }}
            />
          </FormControl>
        </PageFilter>
      </FormContainer>
      {showTable && (
        <Table
          headerColumns={columns}
          rows={data}
          handleSort={handleMetadata}
          metadata={metadata}
          keyProp={Types.LIST_HISTORIC}
          pageActionsProps={{
            initialSearch: filterLocation.search,
            onSearch: handleFilterSearch,
            handleExportAllToExcel,
          }}
          nameTable={`${intl.formatMessage({ id: 'location_history.title' })}`}
        />
      )}
    </>
  );
};

export default LocationHistory;
