import { Box } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import DatePicker from '../../../components/Datepicker';
import FormContainer from '../../../components/FormContainer';
import FormControl from '../../../components/FormControl';
import EyeIcon from '../../../components/Icons/Eye';
import PageActions from '../../../components/PageActions';
import PageErrors from '../../../components/PageErrors';
import PageFilter from '../../../components/PageFilter';
import ModalAcceptTerms from '../../../components/pages/ZeroTouch/ModalAcceptTerms';
import ModalCustomerID from '../../../components/pages/ZeroTouch/ModalCustomerId';
import ModalImportInvoice from '../../../components/pages/ZeroTouch/ModalImportInvoice';
import { apiErrorMessagesByResponse } from '../../../components/pages/ZeroTouch/zeroTouchErros';
import PageShowToaster from '../../../components/PageShowToaster';
import PageTitle from '../../../components/PageTitle';
import Table from '../../../components/Table';
import TableActions from '../../../components/TableActions';
import MenuItem from '../../../components/TableActions/MenuItem';
import { routeWithParameters } from '../../../helper';
import { isDateInRange } from '../../../helper/date';
import { handleSortTable, useSorting } from '../../../helper/sort';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import routes from '../../../routes';
import {
  clearInvoicesList,
  invoicesMetadata,
  listInvoices,
  Types,
  verifyCustomerAcceptTerms,
} from '../../../store/invoice';
import { hasSuccess, hasError, hasLoading } from '../../../store/ui';
import { ListMetadata } from '../../../types/generic_list';

export interface InvoiceTypeFilter {
  startAt?: Date;
  endAt?: Date;
}
const ManageInvoices = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const TODAY = new Date();
  const { portalSettings } = useAppSelector((state) => state.portalSettings);
  const {
    invoices,
    invoicesMetadata: metadata,
    hasCustomerId,
    isAcceptedTerms,
  } = useAppSelector((state) => state.invoice);
  const history = useHistory();

  const [filterInvoice, setFilterInvoice] = useState<InvoiceTypeFilter>({});
  const [filterSearch, setFilterSearch] = useState<string>(null);
  const [isCustomerIdModalOpen, setIsCustomerIdModalOpen] = useState(false);
  const [isImportInvoiceModalOpen, setImportInvoiceModalIsOpen] =
    useState(false);
  const [isAcceptTermsModalOpen, setIsAcceptTermsModalOpen] = useState(false);

  const isCustomerEmailSuccess = hasSuccess(Types.REGISTER_CUSTOMER_ID);
  const isVerifyAccountSuccess = hasSuccess(Types.VERIFY_CUSTOMER_ACCOUNT);
  const listApiError = hasError(Types.LIST);
  const listApiLoading = hasLoading(Types.LIST);

  const invoicesData = useMemo(() => {
    const firstPageIndex = (metadata.page - 1) * metadata.pageSize;
    const lastPageIndex = firstPageIndex + metadata.pageSize;
    const hasFilterDate = !!filterInvoice?.startAt || !!filterInvoice?.endAt;

    const listInvoices = [...invoices]
      ?.sort((propertyA, propertyB) => {
        return handleSortTable(
          metadata?.sortingDirection,
          propertyA[metadata?.sortingProperty],
          propertyB[metadata?.sortingProperty]
        );
      })
      .filter(({ accessKey, invoiceNumber }) =>
        filterSearch?.length > 0
          ? [accessKey, String(invoiceNumber)]?.find(
              (invoiceProperty) =>
                invoiceProperty.length > 0 &&
                invoiceProperty
                  ?.toLowerCase()
                  .includes(filterSearch.toLowerCase())
            )
          : true
      )
      .filter(({ createdAt }) =>
        hasFilterDate
          ? isDateInRange(
              createdAt,
              filterInvoice?.startAt,
              filterInvoice?.endAt
            )
          : true
      );

    return listInvoices?.slice(firstPageIndex, lastPageIndex);
  }, [metadata, invoices, filterSearch, filterInvoice]);

  const hasData = invoices?.length > 0;

  useEffect(() => {
    if (isAcceptedTerms) {
      handleListInvoices();
    } else {
      handleVerifyTerms();
    }

    return () => {
      dispatch(clearInvoicesList());
    };
  }, []);

  useEffect(() => {
    if (isVerifyAccountSuccess && hasCustomerId !== null) {
      setIsCustomerIdModalOpen(!hasCustomerId);
    }
  }, [hasCustomerId, isVerifyAccountSuccess]);

  useEffect(() => {
    if (isAcceptedTerms === true) {
      dispatch(listInvoices());
    }

    if (isAcceptedTerms === false) {
      setIsAcceptTermsModalOpen(true);
    }
  }, [isAcceptedTerms]);

  const handleListInvoices = () => {
    dispatch(listInvoices());
  };

  const handleVerifyTerms = () => {
    dispatch(verifyCustomerAcceptTerms());
  };

  const handlePeriodFilterChange = (date: Date, field: string) => {
    setFilterInvoice({ ...filterInvoice, [field]: date });
  };

  const handleMetadata = (newMetadata: Partial<ListMetadata>) => {
    dispatch(invoicesMetadata({ ...metadata, ...newMetadata }));
  };

  const columns = useSorting(
    [
      {
        header: intl.formatMessage({
          id: 'manage_invoice.column.invoice',
        }),
        accessor: 'invoiceNumber',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: 'manage_invoice.column.access_key',
        }),
        accessor: 'accessKey',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: 'manage_invoice.column.devices',
        }),
        accessor: 'devicesCount',
      },
      {
        header: intl.formatMessage({
          id: 'manage_invoice.column.imei',
        }),
        accessor: 'devicesWithImei',
      },
    ],
    metadata
  );

  const data = invoicesData?.map((invoice) => ({
    cells: [
      {
        field: 'invoiceNumber',
        value: invoice.invoiceNumber,
      },
      {
        field: 'accessKey',
        value: invoice.accessKey,
      },
      {
        field: 'devicesCount',
        value: invoice.devicesCount,
      },
      {
        field: 'devicesWithImei',
        value: invoice.devicesWithImei,
      },
      {
        field: 'actions',
        value: '',
        transform: () => {
          return (
            <TableActions
              moreItems={
                <>
                  <MenuItem
                    icon={<EyeIcon boxSize={6} mr="5px" />}
                    text={
                      <FormattedMessage id="manage_invoice.menu_action.details" />
                    }
                    onClick={() =>
                      history.push(
                        routeWithParameters(routes.zeroTouch.invoices.edit, {
                          id: invoice?.id,
                        })
                      )
                    }
                  />
                </>
              }
            />
          );
        },
      },
    ],
  }));
  return (
    <>
      <Box>
        <PageTitle
          showManualIcon
          title={<FormattedMessage id="manage_invoice.title" />}
          description={<FormattedMessage id="manage_invoice.description" />}
          showOperationalSystems={portalSettings.allowDeviceIOS}
          hideIOSIcon
        />

        <PageErrors
          toasterKeys={[Types.VERIFY_CUSTOMER_ACCEPT_TERMS]}
          translateKey="zero_touch"
          messageError={intl.formatMessage({
            id: 'zero_touch.accept_terms.errors.get_is_accepted',
          })}
        />

        <PageShowToaster
          toasterKeys={[Types.REGISTER_CUSTOMER_ID]}
          messageSuccess={
            <FormattedMessage
              id={
                isCustomerEmailSuccess
                  ? 'manage_invoice.toaster.customer_email.success'
                  : 'manage_invoice.toaster.success'
              }
            />
          }
          disabledError
        />
        <FormContainer>
          <PageFilter>
            <FormControl
              w="176px"
              mr="24px"
              textLabel={<FormattedMessage id="manage_invoice.start_date" />}
            >
              <DatePicker
                maxDate={filterInvoice.endAt || TODAY}
                hasValue={filterInvoice.startAt}
                selected={filterInvoice.startAt}
                onChange={(date) => {
                  handlePeriodFilterChange(date, 'startAt');
                }}
              />
            </FormControl>
            <FormControl
              w="176px"
              textLabel={<FormattedMessage id="manage_invoice.end_date" />}
            >
              <DatePicker
                minDate={filterInvoice.startAt}
                maxDate={TODAY}
                hasValue={filterInvoice.endAt}
                selected={filterInvoice.endAt}
                onChange={(date) => {
                  handlePeriodFilterChange(date, 'endAt');
                }}
              />
            </FormControl>
          </PageFilter>
        </FormContainer>
      </Box>
      {listApiLoading || listApiError || hasData ? (
        <Table
          headerColumns={columns}
          rows={data}
          handleSort={handleMetadata}
          keyProp={Types.LIST}
          isEmpty={invoicesData?.length === 0}
          messageError={
            <FormattedMessage
              id={
                apiErrorMessagesByResponse[listApiError?.message] ||
                apiErrorMessagesByResponse.default
              }
            />
          }
          metadata={metadata}
          pageActionsProps={{
            disabledAllExportsActions: true,
            onSearch: setFilterSearch,
            labelPrimaryButton: <FormattedMessage id="manage_invoice.import" />,
            onClickPrimaryButton: () =>
              setImportInvoiceModalIsOpen(!isImportInvoiceModalOpen),
          }}
          nameTable={intl.formatMessage({
            id: 'manage_invoice.title',
          })}
        />
      ) : (
        <PageActions
          labelPrimaryButton={<FormattedMessage id="manage_invoice.import" />}
          onClickPrimaryButton={() =>
            setImportInvoiceModalIsOpen(!isImportInvoiceModalOpen)
          }
          disabledAllExportsActions={!hasData}
        />
      )}
      {isCustomerIdModalOpen && (
        <ModalCustomerID
          isOpen={isCustomerIdModalOpen}
          closeModal={() => setIsCustomerIdModalOpen(!isCustomerIdModalOpen)}
        />
      )}

      {isImportInvoiceModalOpen && (
        <ModalImportInvoice
          isOpen={isImportInvoiceModalOpen}
          closeModal={() =>
            setImportInvoiceModalIsOpen(!isImportInvoiceModalOpen)
          }
        />
      )}

      {isAcceptTermsModalOpen && (
        <ModalAcceptTerms
          isOpen={isAcceptTermsModalOpen}
          onClose={() => setIsAcceptTermsModalOpen(false)}
          onAccept={handleListInvoices}
        />
      )}
    </>
  );
};

export default ManageInvoices;
