import { Tabs, TabList, TabPanels, TabPanel } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import PageShowToaster from '../../../components/PageShowToaster';
import PageTitle from '../../../components/PageTitle';
import Tab from '../../../components/Tab';
import { usePrivilege } from '../../../hooks/usePrivilege';
import { useAppSelector } from '../../../hooks/useRedux';
import {
  consumptionProfileUpdateTab,
  TypesConsumptionProfile,
} from '../../../store/consumptionProfile';
import { ConsumptionProfileTab } from '../../../types/consumptionProfile';
import ConsumptionProfileEdit from './ConsumptioProfileEdit';
import GroupsCard from './GroupsCard';
import UsersCard from './UsersCard';

const ConsumptionProfile = () => {
  const dispatch = useDispatch();
  const { groups, users } = useAppSelector((state) => state.consumptionProfile);
  const { accessFilterCompany, accessFilterGroup } = usePrivilege();
  const handleTabsChange = (index) => {
    dispatch(consumptionProfileUpdateTab(index));
  };
  return (
    <>
      <PageTitle
        showManualIcon
        title={<FormattedMessage id="consumption_profile.title" />}
        description={<FormattedMessage id="consumption_profile.description" />}
      />
      <Tabs onChange={handleTabsChange} isLazy>
        <TabList>
          {accessFilterCompany && (
            <Tab>
              <FormattedMessage id="global.general" />
            </Tab>
          )}
          {accessFilterGroup && (
            <Tab>
              <FormattedMessage id="global.groups" />
            </Tab>
          )}
          {accessFilterGroup && (
            <Tab>
              <FormattedMessage id="global.users" />
            </Tab>
          )}
        </TabList>
        <PageShowToaster
          toasterKeys={[
            TypesConsumptionProfile.create[ConsumptionProfileTab.GENERAL],
            TypesConsumptionProfile.create[ConsumptionProfileTab.GROUP],
            TypesConsumptionProfile.create[ConsumptionProfileTab.USER],
          ]}
          messageSuccess={
            <FormattedMessage id="consumption_profile.toaster_success" />
          }
        />
        <PageShowToaster
          toasterKeys={[
            TypesConsumptionProfile.update[ConsumptionProfileTab.GENERAL],
            TypesConsumptionProfile.update[ConsumptionProfileTab.GROUP],
            TypesConsumptionProfile.update[ConsumptionProfileTab.USER],
          ]}
          messageSuccess={
            <FormattedMessage id="consumption_profile.toaster_update" />
          }
        />
        <PageShowToaster
          toasterKeys={[
            TypesConsumptionProfile.delete[ConsumptionProfileTab.GROUP],
            TypesConsumptionProfile.delete[ConsumptionProfileTab.USER],
          ]}
          messageSuccess={
            <FormattedMessage id="consumption_profile.toaster_level_up" />
          }
        />
        <TabPanels>
          {accessFilterCompany && (
            <TabPanel>
              <ConsumptionProfileEdit />
            </TabPanel>
          )}
          {accessFilterGroup && (
            <TabPanel>
              <GroupsCard groups={groups} />
            </TabPanel>
          )}
          {accessFilterGroup && (
            <TabPanel>
              <UsersCard users={users} />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </>
  );
};

export default ConsumptionProfile;
