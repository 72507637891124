import { Flex, useTheme } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { useAppSelector } from '../../../hooks/useRedux';
import { Types } from '../../../store/dashboard';
import { selectorLoading } from '../../../store/ui';
import ChartBar from '../../ChartBar';
import ChartLoading from '../../ChartLoading';
import Text from '../../Text';

const DashboardWebsiteVisitedBarChart = () => {
  const { mostVisitedWebsites } = useAppSelector((state) => state.dashboard);
  const { user } = useAppSelector((state) => state.auth);

  const isLoading = useAppSelector(
    selectorLoading(Types.LIST_DEVICES_LOCATION)
  );
  const total = mostVisitedWebsites?.total || Number.MAX_SAFE_INTEGER;
  const { colors } = useTheme();
  const CHART_COLOR = colors['chart']['100'];
  const hasData = mostVisitedWebsites.data.length > 0;

  const dataChart = mostVisitedWebsites?.data
    ?.map((item) => {
      return {
        indexBy: item.label,
        consumptionPercentage:
          total > 0 ? ((item.qty * 100) / total).toFixed(2) : '0',
        consumption: item.qty,
      };
    })
    .reverse();

  if (isLoading) {
    return <ChartLoading color={CHART_COLOR} />;
  }

  return (
    <Flex align="center" justify="center" h="400px">
      {!hasData ? (
        <Text>
          <FormattedMessage id="dashboard.most_visited_websites.empty_chart" />
        </Text>
      ) : (
        <ChartBar
          data={dataChart}
          color={[CHART_COLOR]}
          keys={['consumptionPercentage']}
          margin={{ left: 200, right: 200 }}
          formatRight={(chatValue) => {
            const mostVisitedLegend = dataChart?.find(
              (mostVisited) => mostVisited?.indexBy === chatValue
            );
            return (
              <>
                <tspan x="0" dy="-0.7em" fill="chart.950">
                  {mostVisitedLegend?.consumption || 0}
                </tspan>
                <tspan x="0" dy="1.2em" fill="chart.300">
                  {parseFloat(
                    mostVisitedLegend?.consumptionPercentage || '0'
                  ).toLocaleString(user?.language)}
                  %
                </tspan>
              </>
            );
          }}
          formatLeft={(chatValue) => {
            const mostVisitedLegend = dataChart?.find(
              (mostVisited) => mostVisited?.indexBy === chatValue
            );
            return (
              <tspan x="0" dy="0em" fill="chart.300">
                {mostVisitedLegend?.indexBy}
              </tspan>
            );
          }}
          formatTop={(chartValue) => {
            return `${chartValue}%`;
          }}
        />
      )}
    </Flex>
  );
};

export default DashboardWebsiteVisitedBarChart;
