import { Box, useTheme } from '@chakra-ui/react';
import React, { useEffect } from 'react';

import TermsLoading from '../../../components/pages/terms/TermsLoading';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import { listPrivacyPolicy, Types } from '../../../store/term';
import { hasLoading } from '../../../store/ui';

const PrivacyPolicy = () => {
  const dispatch = useAppDispatch();
  const { privacyPolicy } = useAppSelector((state) => state.term);
  const isLoading = hasLoading(Types.PRIVACY_POLICY);
  const theme = useTheme();

  useEffect(() => {
    if (theme.privacy) {
      window.location.href = theme.privacy;
    } else {
      dispatch(listPrivacyPolicy());
    }
  }, []);

  if (isLoading) {
    return <TermsLoading />;
  }

  return (
    <Box
      className="privacyPolicy"
      dangerouslySetInnerHTML={{ __html: privacyPolicy }}
    />
  );
};

export default PrivacyPolicy;
