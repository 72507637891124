import { Box, Flex, SimpleGrid } from '@chakra-ui/react';
import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import routes from '../../../../routes';
import {
  getImeiInfo,
  registerItemsImei,
  setInvoiceItemInfo,
  Types,
} from '../../../../store/invoice';
import {
  hasError,
  hasLoading,
  hasSuccess,
  uiRemoveError,
} from '../../../../store/ui';
import { InvoiceItemType } from '../../../../types/invoice';
import EditableField from '../../../EditableField';
import FormContainer from '../../../FormContainer';
import FormControl from '../../../FormControl';
import FormSubtitle from '../../../FormSubtitle';
import Input from '../../../Input';
import InvoiceAccessKey from './components/InvoiceAccessKey';

const IMEIModelDetailsTab = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { invoice, initialInvoice } = useAppSelector((state) => state.invoice);

  const invoiceItems = useMemo(() => invoice?.items, [invoice]);

  const isLoading = hasLoading(Types.GET);
  const isUpdateLoading = hasLoading(Types.REGISTER_ITEMS_IMEI);

  const isImportSuccess = hasSuccess(Types.IMPORT_DEVICES_IMEI);

  const invoiceIsEqual = _.isEqual(invoice, initialInvoice);

  const isImeiError = hasError(Types.GET_IMEI_INFO);

  const hasData = invoiceItems?.length > 0;

  const isAllIMEIsFilled =
    hasData && invoiceItems?.every((invoiceItem) => !!invoiceItem.imei);

  useEffect(() => {
    return () => {
      dispatch(uiRemoveError(Types.GET_IMEI_INFO));
      dispatch(uiRemoveError(Types.IMPORT_DEVICES_IMEI));
    };
  }, []);

  useEffect(() => {
    if (isImportSuccess) {
      dispatch(uiRemoveError(Types.IMPORT_DEVICES_IMEI));
    }
  }, [isImportSuccess]);

  const handleFormSubmit = () => {
    dispatch(
      registerItemsImei({
        invoiceId: id,
        items: invoice.items,
      })
    );
  };

  const handleGetImeiInfo = (invoiceItem: InvoiceItemType) => {
    dispatch(uiRemoveError(Types.GET_IMEI_INFO));
    dispatch(setInvoiceItemInfo(invoiceItem));

    if (!!invoiceItem.imei) {
      dispatch(getImeiInfo(invoiceItem.imei));
    }
  };

  return (
    <>
      <FormContainer
        labelPrimary={
          <FormattedMessage
            id={'invoice_details.model_details.button.submit'}
          />
        }
        labelSecondary={<FormattedMessage id={'global.cancel'} />}
        handlePrimary={handleFormSubmit}
        disabledPrimary={!isAllIMEIsFilled || invoiceIsEqual || !!isImeiError}
        loadingPrimary={isUpdateLoading}
        handleSecondary={() => history.push(routes.zeroTouch.invoices.manage)}
      >
        <Flex flexDirection="column" gridGap={8}>
          <FormSubtitle
            subtitle={
              <FormattedMessage id="invoice_details.model_details.title" />
            }
            description={
              <FormattedMessage id="invoice_details.model_details.description" />
            }
          />

          <Flex width="full" alignItems="center" gridGap={2}>
            <InvoiceAccessKey
              invoiceAccessKey={invoice?.accessKey}
              isLoading={isLoading}
            />
          </Flex>

          {hasData && (
            <SimpleGrid px={10} gridGap={6}>
              {invoiceItems?.map((item) => (
                <Flex key={item.id} alignItems="center" gridGap={4}>
                  <FormControl
                    textLabel={
                      <FormattedMessage
                        id={'invoice_details.model_details.input.description'}
                      />
                    }
                    pb="21px"
                  >
                    <Input
                      inputProps={{
                        isDisabled: true,
                        value: item.description || '',
                      }}
                    />
                  </FormControl>
                  <FormControl
                    textLabel={
                      <FormattedMessage
                        id={'invoice_details.model_details.input.imei'}
                      />
                    }
                  >
                    <Flex flexDirection="column" w="full">
                      <EditableField
                        id={item.id}
                        w="full"
                        defaultValue={item?.imei}
                        onSubmit={(newImei) =>
                          handleGetImeiInfo({
                            ...item,
                            model: null,
                            manufacturer: null,
                            imei: newImei?.trim(),
                          })
                        }
                      />
                      <Box
                        color="warning.500"
                        fontSize="sm"
                        fontWeight="bold"
                        h="21px"
                        m="0"
                      >
                        {isImeiError && (
                          <FormattedMessage id="invoice_details.model_details.input.imei_error" />
                        )}
                      </Box>
                    </Flex>
                  </FormControl>
                  <FormControl
                    textLabel={
                      <FormattedMessage
                        id={'invoice_details.model_details.input.manufacturer'}
                      />
                    }
                    pb="21px"
                  >
                    <Input
                      inputProps={{
                        isDisabled: true,
                        value: item?.manufacturer || '',
                      }}
                    />
                  </FormControl>
                  <FormControl
                    textLabel={
                      <FormattedMessage
                        id={'invoice_details.model_details.input.model'}
                      />
                    }
                    pb="21px"
                  >
                    <Input
                      inputProps={{
                        isDisabled: true,
                        value: item?.model || '',
                      }}
                    />
                  </FormControl>
                </Flex>
              ))}
            </SimpleGrid>
          )}
        </Flex>
      </FormContainer>
    </>
  );
};

export default IMEIModelDetailsTab;
