import { Box, Flex } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

import ButtonFileUpload, {
  ButtonFileUploadProps,
} from '../../../ButtonFileUpload';

interface ImportInvoiceUploadFile extends ButtonFileUploadProps {
  errorMessage?: ReactNode;
}

const ImportInvoiceUploadFile = ({
  inputProps,
  uploadTitle,
  errorMessage,
  ...rest
}: ImportInvoiceUploadFile) => {
  return (
    <Flex align="center" justify="flex-start" gridGap={2} {...rest}>
      <ButtonFileUpload inputProps={inputProps} uploadTitle={uploadTitle} />
      {!!errorMessage && (
        <Box color="warning.500" fontSize="sm" fontWeight="bold" h="21px" m="0">
          {errorMessage}
        </Box>
      )}
    </Flex>
  );
};

export default ImportInvoiceUploadFile;
