import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@chakra-ui/react';

import Card from '../../../Card';
import Heading from '../../../Heading';
import Text from '../../../Text';

interface AccordionCardProps {
  question: {
    answer: string;
    question: string;
  };
}

const AccordionCard = ({
  question: { answer, question },
}: AccordionCardProps) => {
  return (
    <Accordion allowMultiple>
      <Card w="100%" p="0">
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <Heading as="h2">
                <AccordionButton h="60px">
                  {isExpanded ? (
                    <ChevronDownIcon color="gray.600" boxSize={8} />
                  ) : (
                    <ChevronRightIcon color="gray.600" boxSize={8} />
                  )}
                  <Text ml="1%" flex="1" textAlign="left" m={0}>
                    {question}
                  </Text>
                </AccordionButton>
              </Heading>
              <AccordionPanel fontSize="sm">
                <Box m="25px" color="gray.500">
                  <div dangerouslySetInnerHTML={{ __html: answer }} />
                </Box>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Card>
    </Accordion>
  );
};

export default AccordionCard;
