import { Flex, Image, Text, useTheme, BoxProps } from '@chakra-ui/react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import routes from '../../routes';
import { homeByPrivilege } from '../../routes/Routes';
import { authHelpDeskClear, logout } from '../../store/auth';
import NotificationMenu from '../NotificationMenu';
import Settings from '../Settings';
import ModalProductVersion, { ProductVersionType } from './ModalProductVersion';

interface LayoutHeaderProps extends BoxProps {
  isPublic?: boolean;
}

const LayoutHeader = ({ isPublic, ...rest }: LayoutHeaderProps) => {
  const dispatch = useAppDispatch();
  const { images } = useTheme();
  const history = useHistory();

  const user = useAppSelector((state) => state?.auth?.user);
  const portalSettings = useAppSelector(
    (state) => state.portalSettings.portalSettings
  );

  const [isVersionModalOpen, setIsVersionModalOpen] = useState(false);

  const portalFrontVersion = process.env.REACT_APP_GIT_COMMIT;

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleChangeCompany = () => {
    dispatch(authHelpDeskClear());
    history.push(routes.helpDesk.companiesList);
  };

  const formattedCommitVersion = (version: string) => {
    return !!version ? version.substring(0, 7) : '0';
  };

  const productVersion: ProductVersionType = {
    value: portalSettings?.productVersion || '0',
    frontVersion: formattedCommitVersion(portalFrontVersion),
    backVersion: formattedCommitVersion(portalSettings?.backendVersion),
  };

  return (
    <>
      <Flex
        h="80px"
        justifyContent="space-between"
        m="0% 8% 0% 2.5%"
        position="sticky"
        alignItems="center"
        {...rest}
      >
        <Image
          src={images?.logo_dashboard}
          layout="fixed"
          alt={portalSettings?.name}
          maxH="60px"
          maxW="350px"
          cursor="pointer"
          onClick={() => history.push(homeByPrivilege[user?.privilege])}
        />
        {!isPublic && (
          <Flex justify="space-evenly">
            <Flex alignItems="center">
              <NotificationMenu />
            </Flex>
            <Flex alignItems="center">
              <Settings
                labelLogout="global.logoff"
                logoutClick={handleLogout}
                changeCompanyClick={handleChangeCompany}
                versionClick={() => setIsVersionModalOpen(true)}
              />
              <Text color="gray.500" fontSize="sm">
                {user?.name || user?.email}
              </Text>
            </Flex>
          </Flex>
        )}
      </Flex>
      {isVersionModalOpen && (
        <ModalProductVersion
          isOpen={isVersionModalOpen}
          onClose={() => setIsVersionModalOpen(false)}
          productVersion={productVersion}
        />
      )}
    </>
  );
};

export default LayoutHeader;
