import { MenuItem } from '@chakra-ui/react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import React, { useRef } from 'react';

import Input, { InputProps } from '../Input';

export interface MenuItemFileUploadProps extends InputProps {
  uploadTitle: string | React.ReactNode;
  isDisabled?: boolean;
  isLoading?: boolean;
  icon?: ReactJSXElement;
}

const MenuItemFileUpload = ({
  uploadTitle,
  isDisabled,
  icon,
  ...rest
}: MenuItemFileUploadProps) => {
  const fileInputRef = useRef(null);

  return (
    <MenuItem
      onClick={() => fileInputRef.current.click()}
      color="gray.900"
      icon={icon}
    >
      <Input
        inputProps={{
          required: true,
          type: 'file',
          ref: fileInputRef,
          style: { display: 'none' },
          isDisabled,
          ...rest.inputProps,
        }}
      />
      {uploadTitle}
    </MenuItem>
  );
};

export default MenuItemFileUpload;
