import { Flex } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { PrivilegeEnum } from '../../../../../../helper';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../hooks/useRedux';
import { batchDevicesActions, Types } from '../../../../../../store/device';
import { listDeviceUserToFilter } from '../../../../../../store/deviceUser';
import { listGroupsToFilter } from '../../../../../../store/group';
import { hasLoading, hasSuccess } from '../../../../../../store/ui';
import { CompanyType } from '../../../../../../types/company';
import { DeviceActionEnum } from '../../../../../../types/device';
import { DeviceUserType } from '../../../../../../types/deviceUser';
import { GroupType } from '../../../../../../types/group';
import Alert, { AlertHtml } from '../../../../../Alert';
import FormContainer from '../../../../../FormContainer';
import FormControl from '../../../../../FormControl';
import Modal from '../../../../../Modal';
import ModalTemplate from '../../../../../Modal/ModalTemplate';
import PageErrors from '../../../../../PageErrors';
import SelectAutocomplete from '../../../../../SelectAutocomplete';
import { alertToIntlByActionSelected, batchActions } from './utils';

interface ModalBatchDevicesProps {
  isOpen: boolean;
  closeModal?: () => void;
}

interface FormType {
  company?: CompanyType;
  groups?: GroupType[];
  devicesUsers?: DeviceUserType[];
  action?: { id?: DeviceActionEnum; name?: string };
}

type FormEntityType = 'company' | 'groups' | 'devicesUsers' | 'action';

const ModalBatchDevices = ({ isOpen, closeModal }: ModalBatchDevicesProps) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const user = useAppSelector((state) => state.auth.user);
  const { groupsToFilter } = useAppSelector((state) => state.group);
  const { devicesUsersToFilter } = useAppSelector((state) => state.deviceUser);

  const [form, setForm] = useState<FormType>();

  const isSuccess = hasSuccess(Types.BATCH_DEVICES_ACTIONS);
  const isLoading = hasLoading(Types.BATCH_DEVICES_ACTIONS);
  const isGroup = user?.privilege === PrivilegeEnum.GROUP;

  useEffect(() => {
    dispatch(listGroupsToFilter());
    dispatch(listDeviceUserToFilter());
  }, []);

  useEffect(() => {
    if (isSuccess) {
      closeModal();
    }
  }, [isSuccess]);

  const handleSubmit = () => {
    Alert({
      onConfirm: () =>
        dispatch(
          batchDevicesActions({
            isCompany: !!form?.company,
            groupIds: form?.groups?.map((groupsId) => groupsId.id),
            userIds: form?.devicesUsers?.map((usersId) => usersId.id),
            commandType: form?.action?.id,
          })
        ),
      html: (
        <AlertHtml
          text={intl.formatMessage({
            id: alertToIntlByActionSelected[form?.action?.id || 'default'],
          })}
          irreversible={
            form?.action?.id === DeviceActionEnum.WIPE &&
            intl.formatMessage({
              id: 'devices.modal_batch_devices_actions.alert.irreversible',
            })
          }
        />
      ),
      confirmButtonText: intl.formatMessage({
        id: 'global.confirm',
      }),
      cancelButtonText: intl.formatMessage({ id: 'global.cancel' }),
    });
  };

  const handleSetForm = (newFilter, entity: FormEntityType) => {
    setForm({ ...form, [entity]: newFilter });
  };

  const handleFilterGroupChange = (value) => {
    dispatch(listGroupsToFilter({ search: value }));
  };

  const handleFilterDeviceUserChange = (value) => {
    dispatch(listDeviceUserToFilter({ search: value }));
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} size="xl">
      <ModalTemplate
        title={
          <FormattedMessage id="devices.modal_batch_devices_actions.title" />
        }
      >
        <FormContainer
          handlePrimary={handleSubmit}
          disabledPrimary={!form?.action}
          loadingPrimary={isLoading}
          handleSecondary={closeModal}
          labelPrimary={<FormattedMessage id="global.send" />}
          labelSecondary={<FormattedMessage id="global.cancel" />}
          divider={false}
          w="full"
          p={0}
        >
          <PageErrors
            toasterKeys={[Types.BATCH_DEVICES_ACTIONS]}
            translateKey="global"
            width="full"
          />
          <Flex flexDirection="column" gridGap={4} mb={6}>
            {!isGroup && (
              <FormControl
                textLabel={<FormattedMessage id="global.company_name" />}
              >
                <SelectAutocomplete
                  options={[user?.company]}
                  value={form?.company}
                  placeholder={<FormattedMessage id="global.company_name" />}
                  onChange={(newValue) => handleSetForm(newValue, 'company')}
                />
              </FormControl>
            )}

            <FormControl textLabel={<FormattedMessage id="global.group" />}>
              <SelectAutocomplete
                options={groupsToFilter}
                isMulti
                value={form?.company?.id ? null : form?.groups}
                onChange={(newValue) => handleSetForm(newValue, 'groups')}
                onInputChange={handleFilterGroupChange}
                placeholder={<FormattedMessage id="global.group" />}
                disabled={!!form?.company?.id}
              />
            </FormControl>

            <FormControl textLabel={<FormattedMessage id="global.users" />}>
              <SelectAutocomplete
                options={devicesUsersToFilter}
                value={form?.company?.id ? null : form?.devicesUsers}
                isMulti
                onChange={(newValue) => handleSetForm(newValue, 'devicesUsers')}
                onInputChange={handleFilterDeviceUserChange}
                placeholder={<FormattedMessage id="global.users" />}
                getOptionLabel={(option) =>
                  `${option.name || ''} ${option.device.phoneNumber || ''}`
                }
                disabled={!!form?.company?.id}
              />
            </FormControl>

            <FormControl
              textLabel={
                <FormattedMessage id="devices.modal_batch_devices_actions.actions" />
              }
            >
              <SelectAutocomplete
                options={batchActions}
                value={form?.action}
                onChange={(newValue) => handleSetForm(newValue, 'action')}
                placeholder={
                  <FormattedMessage id="devices.modal_batch_devices_actions.actions" />
                }
                getOptionLabel={(option) =>
                  `${intl.formatMessage({ id: option?.name })}`
                }
              />
            </FormControl>
          </Flex>
        </FormContainer>
      </ModalTemplate>
    </Modal>
  );
};

export default ModalBatchDevices;
