import { Box, Flex, useTheme } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { useAppSelector } from '../../../hooks/useRedux';
import { Types } from '../../../store/dashboard';
import { selectorLoading } from '../../../store/ui';
import ChartBar from '../../ChartBar';
import ChartLoading from '../../ChartLoading';
import ChartTooltip from '../../ChartTooltip';
import NotificationBadgeIcon from '../../Icons/NotificationBadge';
import Text from '../../Text';

enum LegendsEnum {
  NEW_USERS = 'newUser',
}

const DashboardTotalUsersBarChart = () => {
  const { newUsersSemester } = useAppSelector((state) => state.dashboard);

  const isLoading = useAppSelector(
    selectorLoading(Types.LIST_DEVICES_LOCATION)
  );
  const hasData = newUsersSemester.length > 0;

  const legends = [
    { label: 'newUser', color: 'chart.50' },
    { label: 'totalUsers', color: 'chart.100' },
  ];

  const currentDate = new Date();

  const lastMonth = (date, diff) => {
    const d = new Date(date);
    d.setMonth(d.getMonth() + diff + 1);
    return d;
  };

  const { colors } = useTheme();

  const lastSixMonths = (data) => {
    const dates = [];
    for (let i = 1; i < 7; i++) {
      dates.push(lastMonth(data, i * -1));
    }
    const monthAndYear = dates.map((date) => {
      return {
        month: date.getUTCMonth() + 1,
        year: date.getUTCFullYear(),
      };
    });
    const lastDates = monthAndYear.map((date) => {
      let formatMonth;
      if (date.month.toString().length === 1) {
        formatMonth = `${`0${date.month}`}`;
      } else {
        formatMonth = date.month;
      }
      return `${formatMonth}/${date.year}`;
    });
    return lastDates;
  };

  const dates = lastSixMonths(currentDate).reverse();
  const dataChart = newUsersSemester.map((consumption, index) => {
    return {
      indexBy: dates[index],
      newUsers: consumption.newUser,
      totalUsers: consumption.total,
    };
  });

  if (isLoading) {
    return <ChartLoading color={legends[1].color} />;
  }

  return (
    <Box height="400px" pos="relative" paddingBottom="25px">
      {!hasData ? (
        <Flex align="center" justify="center" h="400px">
          <Text>
            <FormattedMessage id="dashboard.usage_time_by_application.empty_chart" />
          </Text>
        </Flex>
      ) : (
        <>
          <ChartBar
            data={dataChart}
            keys={['newUsers', 'totalUsers']}
            formatBottom={(value) => {
              return value;
            }}
            formatLeft={(value) => {
              return value;
            }}
            layout="vertical"
            maxValue={Math.max(10, ...dataChart.map((item) => item.totalUsers))}
            groupMode="grouped"
            margin={{ top: 50, right: 30, bottom: 50, left: 60 }}
            color={[colors['chart']['50'], colors['chart']['100']]}
            isInteractive={true}
            innerPadding={2}
            padding={0.25}
            tooltip={({ value, color, data, id }) => {
              const unformattedValue = (value / 100) * 100;
              return (
                <ChartTooltip color={color}>
                  <Text style={{ color: 'gray.500' }}>{data.indexBy}</Text>
                  <Box style={{ color: 'gray.500' }}>
                    <NotificationBadgeIcon color={color} />{' '}
                    {
                      <FormattedMessage
                        id={
                          id === 'totalUsers'
                            ? 'global.totalUsers'
                            : 'global.newUsers'
                        }
                      />
                    }
                    : <strong>{unformattedValue.toFixed()}</strong>
                  </Box>
                </ChartTooltip>
              );
            }}
          />
          <Flex paddingLeft="10px">
            {legends.map((item, index) => (
              <Flex key={index}>
                <NotificationBadgeIcon color={item.color} />
                <Box lineHeight="1" ml="10px" mr="10px">
                  {item.label === LegendsEnum.NEW_USERS ? (
                    <FormattedMessage id="global.newUsers" />
                  ) : (
                    <FormattedMessage id="global.totalUsers" />
                  )}
                </Box>
              </Flex>
            ))}
          </Flex>
        </>
      )}
    </Box>
  );
};

export default DashboardTotalUsersBarChart;
