import { useTheme } from '@chakra-ui/system';
import {
  Circle,
  GoogleMap,
  InfoWindowF,
  MarkerClustererF,
  MarkerF,
  Polyline,
  useJsApiLoader,
} from '@react-google-maps/api';
import {
  ComponentProps,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { ZOOM_MAP } from '../../helper';
import { ThemeType } from '../../theme/theme';
import LoadingMap from './LoadingMap';

type MapRef = Parameters<ComponentProps<typeof GoogleMap>['onLoad']>[0];

export interface MarkerMapProps<T> {
  name?: string;
  lat?: number;
  lng?: number;
  icon?: string;
  address?: string;
  speed?: number;
  precision?: number;
  id?: number;
  infos?: T;
  showIndex?: boolean;
  zIndex?: number;
  circle?: {
    radius?: number;
    optionsCircle?: {
      color?: string;
    };
  };
}

interface LocationMeasures<T> {
  highValueLat: MarkerMapProps<T>;
  lowValueLat: MarkerMapProps<T>;
  highValueLng: MarkerMapProps<T>;
  lowValueLng: MarkerMapProps<T>;
}

interface MapProps<T> {
  hasClusterer?: boolean;
  markers?: MarkerMapProps<T>[];
  WindowCloud?: (props: { selected: MarkerMapProps<T> }) => JSX.Element;
  strokeBetweenMarkers?: boolean;
}

export const MapComponent: <T>(props: MapProps<T>) => JSX.Element = ({
  markers,
  WindowCloud,
  hasClusterer,
  strokeBetweenMarkers = false,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });
  const clustererRef = useRef(null);
  const mapRef = useRef<MapRef>(null);
  const [selected, setSelected] = useState<number>(null);
  const theme = useTheme<ThemeType>();

  const circleColor = 'map.400';

  const {
    highValueLat,
    lowValueLat,
    highValueLng,
    lowValueLng,
  }: LocationMeasures<typeof markers[number]['infos']> = useMemo(() => {
    return markers?.reduce((prev, current) => {
      return {
        highValueLat:
          prev.highValueLat?.lat < current.lat ? prev.highValueLat : current,
        lowValueLat:
          prev.lowValueLat?.lat > current.lat ? prev.lowValueLat : current,
        highValueLng:
          prev.highValueLng?.lng < current.lng ? prev.highValueLng : current,
        lowValueLng:
          prev.lowValueLng?.lng > current.lng ? prev.lowValueLng : current,
      };
    }, {} as LocationMeasures<typeof markers[number]['infos']>);
  }, [markers]);

  const center = {
    lat: (lowValueLat?.lat + highValueLat?.lat) / 2 || 0,
    lng: (lowValueLng?.lng + highValueLng?.lng) / 2 || 0,
  };

  const options = {
    gestureHandling: 'cooperative',
    styles: [
      {
        featureType: 'poi',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
    ],
  };

  useEffect(() => {
    clustererRef?.current?.repaint();
  }, [markers?.length]);

  useLayoutEffect(() => {
    handleSetBounds();
  }, [markers]);

  const handleOnLoad = useCallback((map) => {
    mapRef.current = map;
    handleSetBounds();
  }, []);

  const handleSetBounds = () => {
    if (mapRef?.current) {
      const bounds = new window.google.maps.LatLngBounds();

      markers.map((marker) => {
        bounds.extend({
          lat: marker.lat,
          lng: marker.lng,
        });
      });

      mapRef?.current?.fitBounds(bounds);
    }
  };

  const handleMarkerChange = (
    mark?: MarkerMapProps<typeof markers[number]['infos']>
  ) => {
    const actualZoom = mapRef?.current.getZoom();
    const actualCenter = mapRef?.current.getCenter();
    setSelected(mark ? mark.id : null);
    mapRef.current?.setZoom(actualZoom);
    mapRef.current?.setCenter({
      lat: mark ? mark?.lat : actualCenter?.lat(),
      lng: mark ? mark?.lng : actualCenter?.lng(),
    });
  };

  if (!isLoaded) {
    return <LoadingMap />;
  }

  return (
    <>
      <GoogleMap
        id={'map'}
        mapContainerStyle={{
          width: '100%',
          height: '485px',
        }}
        center={center}
        onLoad={handleOnLoad}
        onUnmount={() => (mapRef.current = null)}
        zoom={ZOOM_MAP}
        options={options}
      >
        <>
          <MarkerClustererF
            averageCenter
            minimumClusterSize={5}
            onLoad={(clusterer) => (clustererRef.current = clusterer)}
            zoomOnClick
            ignoreHidden
          >
            {(clusterer) => (
              <>
                {strokeBetweenMarkers && (
                  <Polyline
                    path={markers?.map((mark) => ({
                      lat: mark.lat,
                      lng: mark.lng,
                    }))}
                    options={{
                      strokeColor: theme.colors.primary[600],
                      strokeOpacity: 0.6,
                      strokeWeight: 3,
                    }}
                  />
                )}

                {markers?.map((mark, index) => (
                  <MarkerF
                    key={mark.id}
                    noClustererRedraw
                    position={{ lat: mark.lat, lng: mark.lng }}
                    icon={{
                      url: mark.icon,
                    }}
                    onClick={() => handleMarkerChange(mark)}
                    animation={null}
                    clusterer={hasClusterer && clusterer}
                    zIndex={mark?.zIndex}
                    label={
                      mark.showIndex
                        ? {
                            text: `${index + 1}`,
                            fontWeight: '600',
                            fontFamily: theme.fonts.body,
                            className: 'google-maps-marker-index',
                            color: theme.colors.gray[500],
                          }
                        : undefined
                    }
                  >
                    {selected === mark.id && WindowCloud && (
                      <InfoWindowF
                        position={{ lat: mark.lat, lng: mark.lng }}
                        onCloseClick={handleMarkerChange}
                      >
                        <>
                          <WindowCloud selected={mark} />
                        </>
                      </InfoWindowF>
                    )}
                    {mark.circle && (
                      <Circle
                        center={{
                          lat: mark.lat,
                          lng: mark.lng,
                        }}
                        radius={mark.circle.radius}
                        options={{
                          strokeColor:
                            mark.circle.optionsCircle?.color || circleColor,
                          fillColor:
                            mark.circle.optionsCircle?.color || circleColor,
                        }}
                      />
                    )}
                  </MarkerF>
                ))}
              </>
            )}
          </MarkerClustererF>
        </>
      </GoogleMap>
    </>
  );
};
export const Map = memo(MapComponent);
