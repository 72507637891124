import { Box, Flex } from '@chakra-ui/react';
import React, { useEffect, useLayoutEffect } from 'react';

import Spinner from '../../../components/Spinner';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import {
  enterpriseWebTokenClear,
  getEnterpriseWebToken,
  Types,
} from '../../../store/company';
import { hasLoading } from '../../../store/ui';

const ZeroTouch = () => {
  const dispatch = useAppDispatch();
  const { enterpriseWebToken } = useAppSelector((state) => state.company);
  const isLoading = hasLoading(Types.GET_ENTERPRISE_WEB_TOKEN);

  const DPC_ID = 'com.google.android.apps.work.clouddpc';
  const IFRAME_ZERO_TOUCH_URL = `https://enterprise.google.com/android/zero-touch/embedded/companyhome?token=${enterpriseWebToken}&dpcId=${DPC_ID}`;

  const gapi = window.gapi;
  const iframe = document.getElementById('iFrameZeroTouchId');

  useLayoutEffect(() => {
    if (!!iframe && !!enterpriseWebToken) {
      gapi.load('gapi.iframes', async () => {
        const options = {
          url: IFRAME_ZERO_TOUCH_URL,
          where: await iframe,
          attributes: {
            style: 'width: 100%; height:calc(100vh - 90px)',
            scrolling: 'yes',
          },
        };
        await gapi?.iframes?.getContext().openChild(options);
      });
    }
  }, [enterpriseWebToken, iframe, gapi]);

  useEffect(() => {
    dispatch(
      getEnterpriseWebToken({
        parentFrameUrl: window.location.href,
      })
    );
    return () => {
      dispatch(enterpriseWebTokenClear());
    };
  }, []);

  return (
    <Flex
      m="-3% -4% -2% -4%"
      height="calc(100vh - 90px)"
      maxH="calc(100vh - 90px)"
    >
      {!gapi ||
        (isLoading && (
          <Spinner size={'xl'} position="absolute" top="50%" left="50%" />
        ))}
      <Box id="iFrameZeroTouchId" title="zeroTouch" w="100%" />
    </Flex>
  );
};

export default ZeroTouch;
