import { Flex } from '@chakra-ui/react';
import { useIntl } from 'react-intl';

import { ErrorResponse } from '../../../../helper/error';
import { LocationType } from '../../../../types/locations';
import ItemList from './ItemList';

interface ListDrillDownProps {
  location: LocationType;
  isLoading?: boolean;
  error?: ErrorResponse;
}

function ListDrillDown({ location, error, isLoading }: ListDrillDownProps) {
  const intl = useIntl();

  return (
    <Flex p="0 0 30px 70px">
      <Flex flexDirection="column">
        <ItemList
          label={intl.formatMessage({
            id: 'device_location.column.address',
          })}
          value={location?.address}
          isLoading={isLoading}
          errorMessage={
            !!error?.message &&
            intl.formatMessage({ id: 'device_location.address_error' })
          }
        />
      </Flex>
    </Flex>
  );
}

export default ListDrillDown;
