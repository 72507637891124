import { Button, ButtonProps as ChakraButtonProps } from '@chakra-ui/react';

interface CategoryProps extends ChakraButtonProps {
  categoryName: string;
  onSelectCategory: (name: string) => void;
}

const Category = ({ categoryName, onSelectCategory }: CategoryProps) => {
  return (
    <Button
      h="75px"
      w="100%"
      bg="white"
      onClick={() => onSelectCategory(categoryName)}
      fontWeight="normal"
      _focus={{
        bg: 'gray.500',
        color: 'white',
      }}
      mr="24px"
    >
      {categoryName}
    </Button>
  );
};

export default Category;
