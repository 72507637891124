import { Image } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { RemoveAppsErrorModal } from '../../../components/pages/EnterpriseApps/RemoveAppErrorModal';
import PageShowToaster from '../../../components/PageShowToaster';
import PageTitle from '../../../components/PageTitle';
import Table from '../../../components/Table';
import TableActions from '../../../components/TableActions';
import { iconImgToRender } from '../../../helper';
import { inFirstPage } from '../../../helper/metadata';
import { useSorting } from '../../../helper/sort';
import { usePrivilege } from '../../../hooks/usePrivilege';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import {
  deleteEnterpriseApps,
  listEnterpriseApps,
  Types,
} from '../../../store/enterpriseApps';
import { generateExcel } from '../../../store/event';
import {
  selectorMetadata,
  uiRemoveMetadata,
  uiRemoveTable,
} from '../../../store/ui';
import { ListMetadata } from '../../../types/generic_list';
import { ID } from '../../../types/util';

const EnterpriseApplications = () => {
  const dispatch = useAppDispatch();
  const { portalSettings } = useAppSelector((state) => state.portalSettings);
  const { enterpriseApps } = useAppSelector((state) => state.enterpriseApps);
  const metadata = useAppSelector(selectorMetadata(Types.LIST));
  const { isHelpDesk } = usePrivilege();

  const [search, setSearch] = useState('');
  const intl = useIntl();

  const allFilters = {
    search: search,
  };

  useEffect(() => {
    dispatch(listEnterpriseApps(inFirstPage(metadata), allFilters));
    return () => {
      dispatch(uiRemoveMetadata(Types.LIST));
      dispatch(uiRemoveTable(Types.LIST));
    };
  }, [search]);

  const handleMetadata = (newMetadata: Partial<ListMetadata>) => {
    dispatch(listEnterpriseApps({ ...metadata, ...newMetadata }, allFilters));
  };

  const handleDeleteApp = (id: ID) => {
    dispatch(deleteEnterpriseApps(id));
  };

  const columns = useSorting(
    [
      {
        header: '',
        accessor: 'icon',
      },
      {
        header: intl.formatMessage({
          id: 'enterprise_applications.column.name_app',
        }),
        accessor: 'name',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: 'enterprise_applications.column.origin',
        }),
        accessor: 'origin',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: 'enterprise_applications.column.name_package',
        }),
        accessor: 'packageName',
        canSort: true,
      },
    ],
    metadata
  );

  const data = enterpriseApps.map((enterpriseApp) => {
    const disableDestroy =
      isHelpDesk ||
      enterpriseApp.packageName === portalSettings.appCompanionPackageName ||
      enterpriseApp.packageName === portalSettings.appKioskPackageName ||
      enterpriseApp.packageName === portalSettings.appRemoteViewPackageName;

    return {
      cells: [
        {
          field: 'icon',
          value: (
            <Image w="28px" src={iconImgToRender(enterpriseApp?.iconBase64)} />
          ),
          isExportHidden: true,
        },
        {
          field: 'name',
          value: enterpriseApp.name,
        },
        {
          field: 'origin',
          value: intl.formatMessage({
            id: `enterprise_apps.origin.${enterpriseApp?.origin}`,
          }),
        },
        {
          field: 'packageName',
          value: enterpriseApp.packageName,
        },
        {
          value: '',
          field: 'actions',
          transform: () => {
            return (
              <TableActions
                openDestroy={() => handleDeleteApp(enterpriseApp.id)}
                disableDestroy={disableDestroy}
                entityIntlLabel={
                  <FormattedMessage id="enterprise_applications.table_actions.entity" />
                }
              />
            );
          },
        },
      ],
    };
  });

  const handleExportAllToExcel = () => {
    dispatch(
      generateExcel({
        type: Types.LIST,
        metadata: metadata,
        filters: allFilters,
      })
    );
  };
  return (
    <>
      <PageTitle
        showManualIcon
        title={<FormattedMessage id="enterprise_applications.title" />}
        description={
          <FormattedMessage id="enterprise_applications.description" />
        }
      />
      <PageShowToaster
        toasterKeys={[Types.DELETE]}
        messageSuccess={
          <FormattedMessage id="enterprise_applications.success.delete" />
        }
        disabledError
      />
      <Table
        headerColumns={columns}
        rows={data}
        handleSort={handleMetadata}
        metadata={metadata}
        keyProp={Types.LIST}
        pageActionsProps={{
          handleExportAllToExcel,
          onSearch: setSearch,
        }}
        nameTable={`${intl.formatMessage({
          id: 'enterprise_applications.title',
        })}`}
      />

      <RemoveAppsErrorModal />
    </>
  );
};

export default EnterpriseApplications;
