import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import useIdle from '../../hooks/useIdle';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import routes from '../../routes';
import { logout, refreshSession, Types } from '../../store/auth';
import { hasSuccess } from '../../store/ui';
import { ModalExpirationSession } from './ModalExpirationSession';

const INITIAL_REMAINING_TIME = 30; // 30 seconds

export default function ManageSession() {
  const dispatch = useAppDispatch();
  const { isLogged, expiresIn, accessTokenCreateAt } = useAppSelector(
    (state) => state.auth
  );
  const location = useLocation();

  const [showModal, setShowModal] = useState(false);

  const isRefreshSuccess = hasSuccess(Types.REFRESH_SESSION);

  const timeToRefreshToken = new Date(accessTokenCreateAt).setSeconds(
    expiresIn / 2
  );

  const events = ['mousedown', 'keydown', 'touchstart'];

  const isUnauthenticatedRoute =
    routes.login === location?.pathname || !isLogged;

  const isIdleHookDisabled = showModal || isUnauthenticatedRoute;

  useIdle({
    idleTime: expiresIn,
    promptBeforeIdleTime: INITIAL_REMAINING_TIME,
    onIdle: handleOnIdle,
    onPromptBeforeIdle: handleOnPromptBeforeIdle,
    onAction: handleOnActive,
    disabled: isIdleHookDisabled,
    events,
  });

  function handleOnIdle() {
    if (isUnauthenticatedRoute) return;
    handleLogout();
  }
  function handleOnPromptBeforeIdle() {
    if (isUnauthenticatedRoute) return;
    if (!showModal) {
      setShowModal(true);
    }
  }

  function handleOnActive(_event, idleTime) {
    if (isUnauthenticatedRoute) return;
    const lastActiveTime = idleTime.getLastActiveTime()?.getTime();

    if (
      lastActiveTime >= timeToRefreshToken &&
      !showModal &&
      !isRefreshSuccess
    ) {
      idleTime.reset();
      handleRefreshToken();
    }
  }

  function handleRefreshToken() {
    dispatch(refreshSession());
    setShowModal(false);
  }
  function handleLogout() {
    dispatch(logout({ fromExpiration: true }));
    setShowModal(false);
  }

  return showModal && isLogged ? (
    <ModalExpirationSession
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      secondsRemaining={INITIAL_REMAINING_TIME}
      onConfirm={handleRefreshToken}
    />
  ) : null;
}
