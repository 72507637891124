import { Divider, Flex, Heading } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import Button from '../../../../components/Button';
import ChartLoading from '../../../../components/ChartLoading';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import routes from '../../../../routes';
import {
  sendRegisterToken,
  setRegisterToken,
  Types,
} from '../../../../store/company';
import { hasSuccess, selectorLoading } from '../../../../store/ui';

function CompanyRegisterCallback() {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const queryParams = new URLSearchParams(history.location.search);
  const urlToken = queryParams.get('enterpriseToken');

  const { signupUrlName, enterpriseToken } = useAppSelector(
    (state) => state.company.enterpriseRegister
  );
  const { isLogged } = useAppSelector((state) => state.auth);

  const token = urlToken || enterpriseToken;

  const isLoading = useAppSelector(
    selectorLoading(Types.COMPANY_REGISTER_ENTERPRISE)
  );

  const success = hasSuccess(Types.COMPANY_REGISTER_ENTERPRISE);

  useEffect(() => {
    if (success) {
      return;
    }
    if (!isLogged || !token) {
      dispatch(setRegisterToken(urlToken));
      history.push(routes.home);
      return;
    }
    dispatch(
      sendRegisterToken({
        enterpriseToken: token,
        signupUrlName: signupUrlName,
      })
    );
  }, []);

  return (
    <Flex
      align="center"
      justify="center"
      w="full"
      h="100vh"
      backgroundColor="rgb(0, 0, 0, .4)"
    >
      <Flex
        padding="2%"
        w="50%"
        backgroundColor="white"
        borderRadius="5px"
        justify="space-around"
        direction="column"
        align="center"
      >
        {isLoading ? (
          <ChartLoading color="primary.500" />
        ) : (
          <Flex direction="column" justify="space-around" align="center">
            <Heading as={'h2'} fontSize={'2xl'}>
              <FormattedMessage
                id={
                  success
                    ? 'company_callback.success'
                    : 'company_callback.failure'
                }
              />
            </Heading>

            <Divider
              borderColor="gray.600"
              orientation="horizontal"
              mt="30px"
              mb="30px"
            />
            <Button
              h="45px"
              w="176px"
              backgroundColor="primary.500"
              onClick={() =>
                history.push(
                  success ? routes.dashboard : routes.company.register
                )
              }
            >
              <FormattedMessage id={success ? 'global.ok' : 'global.back'} />
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}

export default CompanyRegisterCallback;
