import { Flex, SimpleGrid, useToast } from '@chakra-ui/react';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import ConnectionBar from '../../components/pages/RemoteView/RemoteViewEmulator/ConnectionBar';
import MockupDevice from '../../components/pages/RemoteView/RemoteViewEmulator/MockupDevice';
import NavigationBar from '../../components/pages/RemoteView/RemoteViewEmulator/NavigationBar';
import ScreenDevice from '../../components/pages/RemoteView/RemoteViewEmulator/ScreenDevice';
import ToolkitBar from '../../components/pages/RemoteView/RemoteViewEmulator/ToolkitBar';
import PageTitle from '../../components/PageTitle';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import { useRemoteView } from '../../hooks/useRemoteView';
import routes from '../../routes';
import { getDevice, Types } from '../../store/device';
import {
  hasError,
  hasSuccess,
  uiRemoveError,
  uiRemoveSuccess,
} from '../../store/ui';

const RemoteView = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { id: deviceId } = useParams<{ id: string }>();
  const { handleRemoveLocalStorage } = useRemoteView();
  const history = useHistory();
  const { portalSettings } = useAppSelector((state) => state.portalSettings);
  const [startDateConnection, setStartDateConnection] = useState<Date>(null);

  const isGetDeviceSuccess = hasSuccess(Types.GET);
  const isGetDeviceError = hasError(Types.GET);

  const toast = useToast({
    id: deviceId,
    duration: 4000,
    isClosable: true,
    position: 'bottom-right',
    variant: isGetDeviceSuccess ? 'toast_success' : 'toast_error',
    status: isGetDeviceSuccess ? 'success' : 'error',
    description: isGetDeviceSuccess
      ? intl.formatMessage({
          id: 'remote_view.success',
        })
      : isGetDeviceError?.message ||
        intl.formatMessage({ id: 'remote_view.error' }),
  });

  useLayoutEffect(() => {
    if (!portalSettings.allowRemoteView) {
      handleRemoveLocalStorage();
      return history.push(routes.dashboard);
    }
    handleRemoveLocalStorage();
    dispatch(getDevice(Number(deviceId)));
  }, [deviceId]);

  useEffect(() => {
    if (!toast.isActive(deviceId)) {
      if (isGetDeviceSuccess || isGetDeviceError) {
        toast();
      }
      dispatch(uiRemoveSuccess(Types.GET));
      dispatch(uiRemoveError(Types.GET));
    }
  }, [isGetDeviceSuccess, isGetDeviceError]);

  return (
    <>
      <PageTitle
        showManualIcon
        title={<FormattedMessage id="remote_view.title" />}
        description={<FormattedMessage id="remote_view.description" />}
      />
      <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} gridGap={6}>
        <Flex direction="column" gridGap={2} minW="700px">
          <ConnectionBar
            startConnection={startDateConnection}
            setStartConnection={() =>
              !!startDateConnection
                ? setStartDateConnection(null)
                : setStartDateConnection(new Date())
            }
          />
        </Flex>

        <Flex gridGap={2} minW="700px">
          <MockupDevice>
            <ScreenDevice />
            <NavigationBar
              handleGoBack={() => null}
              handleHome={() => null}
              handleRecentApps={() => null}
            />
          </MockupDevice>
          <ToolkitBar
            handlePower={() => null}
            handleVolumeDown={() => null}
            handleVolumeUp={() => null}
          />
        </Flex>
        <Flex />
      </SimpleGrid>
    </>
  );
};

export default RemoteView;
