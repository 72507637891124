import React from 'react';

import { newPasswordDevice } from '../../../../../store/device';
import Alert, { AlertHtml } from '../../../../Alert';

const alertNewPassword = (intl, dispatch, idDevice: number) => {
  Alert({
    onConfirm: () => dispatch(newPasswordDevice(idDevice)),
    html: (
      <AlertHtml
        irreversible={intl.formatMessage({ id: 'devices.alert.irreversible' })}
        text={intl.formatMessage({ id: 'devices.alert.newpassword.text' })}
      />
    ),
    confirmButtonText: intl.formatMessage({
      id: 'devices.alert.newpassword.button',
    }),
    cancelButtonText: intl.formatMessage({ id: 'devices.alert.cancel' }),
  });
};

export default alertNewPassword;
