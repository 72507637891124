import { Text, Box } from '@chakra-ui/react';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Alert, { AlertHtml } from '../../components/Alert';
import Button from '../../components/Button';
import ChangePassword from '../../components/ChangePassword';
import LeftArrowCircleIcon from '../../components/Icons/LeftArrowCircle';
import { useAppSelector } from '../../hooks/useRedux';
import routes from '../../routes';
import { changePasswordAdminUser, Types } from '../../store/adminUser';
import {
  selectorError,
  selectorLoading,
  selectorSuccess,
  uiRemoveError,
  uiRemoveSuccess,
} from '../../store/ui';

const PasswordChange = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();

  const queryParams = new URLSearchParams(history.location.search);
  const token = queryParams.get('token');

  const [form, setForm] = useState<{
    password: string;
    confirmPassword?: string;
  }>({ password: '' });
  const isLoading = useAppSelector(selectorLoading(Types.CHANGE_PASSWORD));
  const isError = useAppSelector(selectorError(Types.CHANGE_PASSWORD));
  const isSuccess = useAppSelector(selectorSuccess(Types.CHANGE_PASSWORD));

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(changePasswordAdminUser(form.password, token));
  };

  const handleError = () => {
    Alert({
      onConfirm: () => false,
      showCancelButton: false,
      icon: 'error',
      html: (
        <AlertHtml text={intl.formatMessage({ id: 'password_chance.error' })} />
      ),
    });
    dispatch(uiRemoveError(Types.CHANGE_PASSWORD));
  };

  const handleSuccess = () => {
    Alert({
      onConfirm: () => history.push(routes.login),
      showCancelButton: false,
      icon: 'success',
      html: (
        <AlertHtml
          text={intl.formatMessage({ id: 'password_chance.success' })}
        />
      ),
    });
    dispatch(uiRemoveSuccess(Types.CHANGE_PASSWORD));
  };

  return (
    <>
      <Box pt="20%">
        <Button
          variant="link"
          leftIcon={<LeftArrowCircleIcon boxSize={8} color="primary.500" />}
          onClick={() => history.push(routes.login)}
        >
          {intl.formatMessage({
            id: 'global.back',
          })}
        </Button>
      </Box>

      <form onSubmit={submit}>
        <Box pt="8">
          <Text fontSize="4xl" fontWeight="300">
            <FormattedMessage id="password_chance.welcome_1" />
          </Text>
          <Text fontSize="4xl" as="b">
            <FormattedMessage id="password_chance.welcome_2" />
          </Text>
        </Box>

        <ChangePassword form={form} setForm={setForm} />

        <Box>
          <Button
            type="submit"
            w="170px"
            h="45px"
            disabled={!form.password || form.password !== form.confirmPassword}
            isLoading={isLoading}
          >
            <FormattedMessage id="password_chance.continue" />
          </Button>
        </Box>
      </form>

      {isError && handleError()}
      {isSuccess && handleSuccess()}
    </>
  );
};

export default PasswordChange;
