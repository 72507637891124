import { VStack, Link } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import Card from '../../../components/Card';
import Download from '../../../components/Icons/Download';
import PageTitle from '../../../components/PageTitle';
import Skeleton from '../../../components/Skeleton';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import { listGuides, Types } from '../../../store/help';
import { hasLoading } from '../../../store/ui';

const Guides = () => {
  const dispatch = useAppDispatch();

  const { guides } = useAppSelector((state) => state.help);
  const { portalSettings } = useAppSelector((state) => state.portalSettings);
  const isLoading = hasLoading(Types.GUIDES);

  useEffect(() => {
    if (guides.length === 0) {
      dispatch(listGuides());
    }
  }, []);

  return (
    <>
      <PageTitle
        showManualIcon
        title={<FormattedMessage id="guides.title" />}
        description={
          <FormattedMessage
            id="guides.description"
            values={{
              portalName: portalSettings.name || '',
            }}
          />
        }
      />
      {isLoading ? (
        <VStack gridRowGap={4}>
          <Skeleton h={16} w="100%" />
          <Skeleton h={16} w="100%" />
        </VStack>
      ) : (
        guides.map((guide) => (
          <Card key={guide.url} mt="1%">
            <Link
              href={guide.url}
              isExternal
              m="0"
              fontSize="sm"
              color="primary.500"
              fontWeight="400"
              cursor="pointer"
            >
              <Download boxSize={6} mr="0.3%" />
              {guide.name}
            </Link>
          </Card>
        ))
      )}
    </>
  );
};

export default Guides;
