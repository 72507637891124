import { Box } from '@chakra-ui/react';
import { PieSvgProps, PieTooltipProps, ResponsivePie } from '@nivo/pie';
import React from 'react';

export interface DataDonut {
  id?: string;
  label?: string;
  value?: number | string;
  color?: string;
  hiddenTooltip?: boolean;
}

interface CustomTooltipProps {
  datum?: DataDonut;
  color?: string;
}

interface GraphDonutProps
  extends Omit<PieSvgProps<unknown>, 'width' | 'height'> {
  data: DataDonut[];
  legendsArc?: boolean;
  borderLegends?: boolean;
  percentToShow?: number | string;
  CustomTooltip?: (prop: CustomTooltipProps) => React.ReactElement;
  TransformLegends?: () => React.ReactElement;
}

const ChartPie = ({
  data,
  legendsArc,
  borderLegends,
  percentToShow,
  CustomTooltip,
  TransformLegends,
  ...rest
}: GraphDonutProps) => {
  return (
    <Box h="278px" pb="18px" pos="relative">
      <ResponsivePie
        data={data}
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        innerRadius={0.87}
        colors={data?.map((m) => m.color)}
        enableArcLinkLabels={legendsArc || false}
        enableArcLabels={borderLegends || false}
        tooltip={({ datum: { color, data } }: PieTooltipProps<DataDonut>) =>
          !data.hiddenTooltip && CustomTooltip ? (
            <CustomTooltip color={color} />
          ) : null
        }
        {...rest}
      />
      <Box
        pos="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        fontSize="5xl"
        color="chart.950"
      >
        {percentToShow && `${percentToShow}`}
      </Box>
      {TransformLegends && <TransformLegends />}
    </Box>
  );
};

export default ChartPie;
