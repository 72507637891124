import { Box } from '@chakra-ui/react';

import AndroidIcon from '../../Icons/Android';
import IOS from '../../Icons/IOS';

interface OperationalSystemIconsProps {
  margin?: string;
}
const OperationalSystemIcons = ({ margin }: OperationalSystemIconsProps) => {
  return (
    <Box>
      <AndroidIcon
        color="android.50"
        w="15px"
        h="26px"
        margin={margin || '0px 4px 0px 8px'}
      />
      <IOS color="ios.50" w="15px" h="26px" />
    </Box>
  );
};
export default OperationalSystemIcons;
