import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

type PresenceType = { type: 'idle' } | { type: 'active'; prompted: boolean };

function useIdle({
  onIdle,
  idleTime,
  promptBeforeIdleTime,
  onPromptBeforeIdle,
  onAction,
  ...rest
}) {
  const idleTimeout = idleTime * 1000;
  const promptBeforeIdleTimeout = promptBeforeIdleTime * 1000;

  const { getRemainingTime, getLastActiveTime, reset, start } = useIdleTimer({
    promptBeforeIdle: promptBeforeIdleTimeout,
    timeout: idleTimeout,
    onPrompt: onPromptBeforeIdle,
    onIdle: handleOnIdle,
    onAction: handleOnActive,
    onPresenceChange,
    debounce: 1000,
    ...rest,
  });

  const [isIdle, setIsIdle] = useState(false);

  //handles what happens when the user is idle
  function handleOnIdle(event, idleTime) {
    setIsIdle(true);
    onIdle(event, idleTime);
  }

  function handleOnActive(event, idleTime) {
    setIsIdle(false);
    reset();
    onAction(event, idleTime);
  }

  function onPresenceChange(presence: PresenceType) {
    const isIdle = presence.type === 'idle';
    const isActive = presence.type === 'active';

    if (isActive) {
      reset();
      setIsIdle(false);
    }

    if (isIdle) {
      start();
      setIsIdle(true);
    }
  }

  return {
    getRemainingTime,
    getLastActiveTime,
    isIdle,
  };
}

export default useIdle;
