import React from 'react';
import { useIntl } from 'react-intl';

import { useSorting } from '../../../../helper/sort';
import { useFormatDate } from '../../../../hooks/useFormatDate';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { listCompanyConsumptionSms, Types } from '../../../../store/company';
import { generateExcel } from '../../../../store/event';
import { selectorMetadata } from '../../../../store/ui';
import { ListMetadata } from '../../../../types/generic_list';
import Table from '../../../Table';

const SmsTable = ({ data, allFilters, setSearch, keyProps, lastFetch }) => {
  const intl = useIntl();
  const { language } = useFormatDate();
  const dispatch = useAppDispatch();
  const consumptionsSmsMetadata = useAppSelector(
    selectorMetadata(Types.CONSUMPTION_SMS)
  );

  const handleMetadata = (newMetadata: Partial<ListMetadata>) => {
    dispatch(
      listCompanyConsumptionSms(
        {
          ...consumptionsSmsMetadata,
          ...newMetadata,
        },
        allFilters
      )
    );
  };

  const columns = useSorting(
    [
      {
        canSort: true,
        header: intl.formatMessage({
          id: 'company_consumption.column.tabs_date',
        }),
        accessor: 'sendDate',
      },
      {
        canSort: true,
        header: intl.formatMessage({
          id: 'company_consumption.column.user',
        }),
        accessor: 'user',
      },
      {
        canSort: true,
        header: intl.formatMessage({
          id: 'company_consumption.column.phone',
        }),
        accessor: 'phone',
      },
      {
        canSort: true,
        header: intl.formatMessage({
          id: 'company_consumption.column.group',
        }),
        accessor: 'group',
      },
      {
        canSort: true,
        header: intl.formatMessage({
          id: 'company_consumption.column.target_number',
        }),
        accessor: 'to',
      },
      {
        canSort: true,
        header: intl.formatMessage({
          id: 'company_consumption.column.roaming',
        }),
        accessor: 'roaming',
      },
      {
        canSort: true,
        header: intl.formatMessage({
          id: 'company_consumption.column.provider',
        }),
        accessor: 'carrierNetwork',
      },
    ],
    consumptionsSmsMetadata
  );

  const rowData = data.map((consumption) => ({
    cells: [
      {
        field: 'sendDate',
        value: new Date(consumption.sendDate).toLocaleString(language, {
          timeZone: 'UTC',
          dateStyle: 'short',
        }),
      },
      {
        field: 'user',
        value: consumption?.user,
      },
      {
        field: 'phone',
        value: consumption?.phone,
      },
      {
        field: 'group',
        value: consumption?.group,
      },
      {
        field: 'to',
        value: consumption?.to,
      },
      {
        field: 'roaming',
        value: consumption?.roaming
          ? `${intl.formatMessage({
              id: 'global.yes',
            })}`
          : `${intl.formatMessage({
              id: 'global.no',
            })}`,
      },
      {
        field: 'carrierNetwork',
        value: consumption?.carrierNetwork,
      },
    ],
  }));

  const handleExportAllToExcel = () => {
    dispatch(
      generateExcel({
        type: Types.CONSUMPTION_SMS,
        metadata: consumptionsSmsMetadata,
        filters: lastFetch,
      })
    );
  };

  return (
    <Table
      headerColumns={columns}
      rows={rowData}
      handleSort={handleMetadata}
      metadata={consumptionsSmsMetadata}
      keyProp={keyProps}
      pageActionsProps={{
        onSearch: setSearch,
        handleExportAllToExcel: handleExportAllToExcel,
        idDivTableForCopy: 'table-sms',
      }}
      nameTable={`${intl.formatMessage({
        id: 'company_consumption.title',
      })}`}
    />
  );
};

export default SmsTable;
