import { Flex, Link } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import HelpImg from '../../../../assets/Images/dashboard-help.svg';
import routes from '../../../../routes';
import Card from '../../../Card';
import Heading from '../../../Heading';
import Text from '../../../Text';

function HelpCard() {
  return (
    <Card p="0" w="100%" overflow="hidden">
      <Flex justifyContent="space-between">
        <Flex p="40px" flexDirection="column">
          <Heading as="h2" fontSize="4xl">
            <FormattedMessage id="card_help.faq.title" />
          </Heading>
          <Text mt="20px" w="260px">
            <FormattedMessage id="card_help.faq.description" />
          </Text>
          <Text mt="31px" color="primary.500">
            <Link href={routes.faq}>
              <FormattedMessage id="card_help.discover_more" />
            </Link>
          </Text>
        </Flex>
        <Flex>
          <img src={HelpImg} />
        </Flex>
      </Flex>
    </Card>
  );
}

export default HelpCard;
