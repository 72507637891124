import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import DatePicker from '../../../components/Datepicker';
import FormContainer from '../../../components/FormContainer';
import FormControl from '../../../components/FormControl';
import PageFilter from '../../../components/PageFilter';
import ListDrillDown from '../../../components/pages/Reports/DeviceLocation/ListDrillDown';
import PageShowToaster from '../../../components/PageShowToaster';
import PageTitle from '../../../components/PageTitle';
import SelectAutocomplete from '../../../components/SelectAutocomplete';
import Table from '../../../components/Table';
import { Body } from '../../../components/Table/TableInterfaces';
import { inFirstPage } from '../../../helper/metadata';
import { featurePlanKeys } from '../../../helper/plan';
import { useSorting } from '../../../helper/sort';
import { useFormatDate } from '../../../hooks/useFormatDate';
import { useAppDispatchByPlan, useAppSelector } from '../../../hooks/useRedux';
import { generateExcel } from '../../../store/event';
import {
  clearStoreLocalization,
  getAddress,
  getUsersOptions,
  listLastLocation,
  Types,
} from '../../../store/location';
import {
  hasError,
  hasLoading,
  selectorMetadata,
  uiRemoveMetadata,
} from '../../../store/ui';
import { DeviceUserType } from '../../../types/deviceUser';
import { ListMetadata } from '../../../types/generic_list';

export interface LocationTypeFilter {
  startAt?: Date;
  endAt?: Date;
  search?: string;
  user?: DeviceUserType;
}

const DeviceLocation = () => {
  const intl = useIntl();

  const dispatch = useAppDispatchByPlan(featurePlanKeys.allowLocalization);
  const { locations, usersOptions } = useAppSelector((state) => state.location);
  const { portalSettings } = useAppSelector((state) => state.portalSettings);
  const metadata = useAppSelector(selectorMetadata(Types.LIST_LAST));
  const { handleFormatDateByUserLogged } = useFormatDate();

  const [filterLocation, setFilterLocation] = useState<LocationTypeFilter>({
    search: null,
    endAt: null,
    startAt: null,
    user: null,
  });

  const isAddressLoading = hasLoading(Types.GET_ADDRESS);
  const addressError = hasError(Types.GET_ADDRESS);

  useEffect(() => {
    dispatch(
      listLastLocation(inFirstPage(metadata), {
        ...filterLocation,
        user: null, // set filter null to not send to backend
        userId: filterLocation?.user?.id,
      } as Record<string, unknown>)
    );
  }, [filterLocation]);

  useEffect(() => {
    dispatch(getUsersOptions());

    return () => {
      dispatch(clearStoreLocalization());
      dispatch(uiRemoveMetadata(Types.LIST_LAST));
    };
  }, []);

  const handleMetadata = (newMetadata: Partial<ListMetadata>) => {
    dispatch(
      listLastLocation(
        { ...metadata, ...newMetadata },
        filterLocation as Record<string, unknown>
      )
    );
  };

  const handlePeriodFilterChange = (date: Date, field: string) => {
    setFilterLocation({ ...filterLocation, [field]: date });
  };

  const handleFilterSearch = (newSearch: string) => {
    setFilterLocation({
      ...filterLocation,
      search: newSearch,
    });
  };

  const columns = useSorting(
    [
      {
        isExportHidden: true,
        header: '',
        accessor: 'expanderHandler',
      },
      {
        header: intl.formatMessage({
          id: 'device_location.column.user',
        }),
        accessor: 'user',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: 'device_location.column.phone_user',
        }),
        accessor: 'phoneNumber',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: 'device_location.column.lat_lng',
        }),
        accessor: 'lat_lng',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: 'device_location.column.created_at',
        }),
        accessor: 'createdAt',
        canSort: true,
      },
      {
        isExportHidden: true,
        header: '',
        accessor: 'expander',
      },
      {
        isExpandedField: true,
        header: intl.formatMessage({
          id: 'device_location.column.address',
        }),
        accessor: 'address',
      },
    ],
    metadata
  );

  const data: Body[] = locations.map((location) => ({
    key: String(location.id),
    cells: [
      {
        isExportHidden: true,
        field: 'expanderHandler',
        value: null,
        onExpand: () => {
          if (!location?.address && !isAddressLoading) {
            dispatch(getAddress(location));
          }
        },
        transform: ({ isExpanded, toggleExpanded }) => (
          <span>
            {isExpanded ? (
              <ChevronDownIcon
                boxSize="5"
                color="gray.600"
                onClick={toggleExpanded}
              />
            ) : (
              <ChevronRightIcon
                boxSize="5"
                color="gray.600"
                onClick={toggleExpanded}
              />
            )}
          </span>
        ),
        chackraProps: { width: 0 },
      },
      {
        field: 'user',
        value: location.device?.deviceUser?.name,
      },
      {
        field: 'phoneNumber',
        value: location.device?.phoneNumber,
      },
      {
        field: 'lat_lng',
        value: `${location.latitude},${location.longitude}`,
      },
      {
        field: 'createdAt',
        value: handleFormatDateByUserLogged(location.createdAt),
      },
      {
        isExportHidden: true,
        field: 'expander',
        value: null,
        transform: () => (
          <ListDrillDown
            location={location}
            isLoading={isAddressLoading}
            error={addressError}
          />
        ),
      },
      {
        isExpandedField: true,
        field: 'address',
        value: location.address,
      },
    ],
  }));

  const handleExportAllToExcel = () => {
    dispatch(
      generateExcel({
        type: Types.LIST_LAST,
        metadata: metadata,
        filters: {
          ...filterLocation,
          userId: filterLocation?.user?.id,
        },
      })
    );
  };

  const handleSetFilter = <Field extends keyof LocationTypeFilter>(
    field: Field,
    value: LocationTypeFilter[Field]
  ) => {
    setFilterLocation((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <>
      <Box>
        <PageTitle
          showManualIcon
          title={<FormattedMessage id="device_location.title" />}
          description={<FormattedMessage id="device_location.sub_title" />}
          showOperationalSystems={portalSettings.allowDeviceIOS}
        />
        <FormContainer>
          <PageFilter>
            <FormControl
              w="320px"
              mr="24px"
              textLabel={<FormattedMessage id="device_location.user" />}
            >
              <SelectAutocomplete
                options={usersOptions}
                value={filterLocation.user}
                onChange={(newUser) =>
                  handleSetFilter('user', newUser as DeviceUserType)
                }
                getOptionLabel={(option) =>
                  `${option.name || ''} ${option.device.phoneNumber || ''}`
                }
              />
            </FormControl>

            <FormControl
              w="176px"
              mr="24px"
              textLabel={<FormattedMessage id="device_location.start_date" />}
            >
              <DatePicker
                selected={filterLocation.startAt}
                hasValue={filterLocation.startAt}
                onChange={(e) => {
                  handlePeriodFilterChange(e, 'startAt');
                }}
                maxDate={filterLocation.endAt || new Date()}
              />
            </FormControl>

            <FormControl
              w="176px"
              textLabel={<FormattedMessage id="device_location.end_date" />}
            >
              <DatePicker
                selected={filterLocation.endAt}
                minDate={filterLocation.startAt}
                hasValue={filterLocation.endAt}
                onChange={(e) => {
                  handlePeriodFilterChange(e, 'endAt');
                }}
                maxDate={new Date()}
              />
            </FormControl>
          </PageFilter>
        </FormContainer>
        <PageShowToaster
          toasterKeys={[Types.UPDATE]}
          messageSuccess={<FormattedMessage id="edit_deviceuser.success" />}
        />
      </Box>

      <Table
        headerColumns={columns}
        rows={data}
        handleSort={handleMetadata}
        keyProp={Types.LIST_LAST}
        metadata={metadata}
        pageActionsProps={{
          onSearch: handleFilterSearch,
          handleExportAllToExcel,
        }}
        nameTable={`${intl.formatMessage({ id: 'device_location.title' })}`}
      />
    </>
  );
};

export default DeviceLocation;
