import { Box, Flex, Text, Textarea } from '@chakra-ui/react';
import { ChangeEvent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import FormContainer from '../../../components/FormContainer';
import FormControl from '../../../components/FormControl';
import Input from '../../../components/Input';
import PageErrors from '../../../components/PageErrors';
import {
  getScreenByPath,
  Screens,
} from '../../../components/pages/Messages/utils';
import PageTitle from '../../../components/PageTitle';
import SelectAutocomplete from '../../../components/SelectAutocomplete';
import { PrivilegeEnum } from '../../../helper';
import { featurePlanKeys } from '../../../helper/plan';
import { validatorUrl } from '../../../helper/validador';
import {
  useAppDispatch,
  useAppDispatchByPlan,
  useAppSelector,
} from '../../../hooks/useRedux';
import routes from '../../../routes';
import { listDeviceUserToFilter } from '../../../store/deviceUser';
import { createDocument, Types as DocumentType } from '../../../store/document';
import {
  createExternalApp,
  getExternalApp,
  Types as ExternalAppType,
  updateExternalApp,
} from '../../../store/externalApps';
import { listGroupsToFilter } from '../../../store/group';
import { createMessage, Types as MessageType } from '../../../store/message';
import {
  createRemoteApp,
  Types as RemoteAppType,
} from '../../../store/remoteApps';
import { hasLoading, hasSuccess } from '../../../store/ui';
import { CompanyType } from '../../../types/company';
import { DeviceUserType } from '../../../types/deviceUser';
import { GroupType } from '../../../types/group';
import { ID } from '../../../types/util';

interface FormType {
  id?: ID;
  downloadUrl?: string;
  content?: string;
  name?: string;
  packageName?: string;
  url?: string;
}

const MessagesEdit = () => {
  const history = useHistory();
  const { id: externalAppId } = useParams<{ id: string }>();
  const currentScreen = getScreenByPath(history.location.pathname);
  const dispatchExternalApps = useAppDispatchByPlan(
    featurePlanKeys.allowExternalApps
  );
  const dispatchMessage = useAppDispatchByPlan(featurePlanKeys.allowMessage);
  const dispatchDocument = useAppDispatchByPlan(featurePlanKeys.allowDocument);
  const dispatchRemoteApps = useAppDispatch();
  const dispatch = {
    [Screens.MESSAGES]: dispatchMessage,
    [Screens.DOCUMENTS]: dispatchDocument,
    [Screens.EXTERNAL_APPS]: dispatchExternalApps,
    [Screens.REMOTE_APPS]: dispatchRemoteApps,
  }[currentScreen];
  const { user } = useAppSelector((state) => state.auth);
  const { company } = useAppSelector((state) => state.auth.user);
  const { groupsToFilter } = useAppSelector((state) => state.group);
  const { devicesUsersToFilter } = useAppSelector((state) => state.deviceUser);
  const { externalApp } = useAppSelector((state) => state.externalApps);
  const [filterCompany, setFilterCompany] = useState<CompanyType>();
  const [selectedGroup, setSelectedGroup] = useState<GroupType[]>([]);
  const [filterDeviceUser, setFilterDeviceUser] = useState<DeviceUserType[]>(
    []
  );
  const [form, setForm] = useState<FormType>();

  const isGroup = user.privilege === PrivilegeEnum.GROUP;
  const isExternalAppScreen = currentScreen === Screens.EXTERNAL_APPS;
  const isEdit = !!externalAppId;
  const isInvalidUrl =
    form?.downloadUrl?.length > 0 && !validatorUrl(form?.downloadUrl);
  const isRemoteAppScreen = currentScreen === Screens.REMOTE_APPS;

  const disablePrimary = {
    [Screens.MESSAGES]: !form?.content,
    [Screens.DOCUMENTS]: !form?.content,
    [Screens.EXTERNAL_APPS]: !form?.downloadUrl || isInvalidUrl,
    [Screens.REMOTE_APPS]: !form?.name || !form?.packageName || !form?.url,
  }[currentScreen];

  const Types = {
    [Screens.MESSAGES]: MessageType,
    [Screens.DOCUMENTS]: DocumentType,
    [Screens.EXTERNAL_APPS]: ExternalAppType,
    [Screens.REMOTE_APPS]: RemoteAppType,
  }[currentScreen];

  const success = hasSuccess(Types.CREATE);
  const isLoading = hasLoading(Types.CREATE);

  const toasterKey = Types.CREATE;

  const actionEntity = {
    [Screens.MESSAGES]: createMessage,
    [Screens.DOCUMENTS]: createDocument,
    [Screens.EXTERNAL_APPS]: isEdit ? updateExternalApp : createExternalApp,
    [Screens.REMOTE_APPS]: createRemoteApp,
  }[currentScreen];

  const linkList = {
    [Screens.MESSAGES]: routes.messages.list,
    [Screens.DOCUMENTS]: routes.documents.list,
    [Screens.EXTERNAL_APPS]: routes.externalApps.list,
    [Screens.REMOTE_APPS]: routes.remoteApps.list,
  }[currentScreen];

  const keysIntl = {
    [Screens.MESSAGES]: {
      title: 'message_register.title',
      description: 'message_register.title_text',
      field_message: 'message.content',
      send_label: 'message.send_label',
      filter_title: 'message.filter_title',
      errors: 'message',
    },
    [Screens.DOCUMENTS]: {
      title: 'document.title',
      description: 'document.description',
      field_message: 'document.content',
      send_label: 'document.send_label',
      filter_title: 'document.filter_title',
      errors: 'document',
    },
    [Screens.EXTERNAL_APPS]: {
      title: `external_app_edit.${[isEdit ? 'edit' : 'create']}_title`,
      description: `external_app_edit.${[
        isEdit ? 'edit' : 'create',
      ]}_description`,
      send_label: `external_app_edit.${[
        isEdit ? 'edit' : 'create',
      ]}_send_label`,
      errors: `external_app_edit.input`,
    },
    [Screens.REMOTE_APPS]: {
      title: 'remote_app_edit.title',
      description: 'remote_app_edit.description',
      send_label: 'remote_app_edit.send_label',
      filter_title: 'remote_app_edit.filter_title',
      errors: 'remote_app_edit.input',
    },
  }[currentScreen];

  useEffect(() => {
    if (success) {
      history.push(linkList);
    }
  }, [success]);

  useEffect(() => {
    if (externalApp?.downloadUrl) {
      setForm({ ...form, downloadUrl: externalApp?.downloadUrl });
    }
  }, [externalApp]);

  useEffect(() => {
    if (currentScreen !== Screens.EXTERNAL_APPS) {
      dispatch(listGroupsToFilter());
      dispatch(listDeviceUserToFilter());
    }
    if (currentScreen === Screens.EXTERNAL_APPS && isEdit) {
      dispatch(getExternalApp(Number(externalAppId)));
      setForm({ ...form, id: Number(externalAppId) });
    }
  }, [currentScreen, isEdit]);

  const handlePrimary = () => {
    dispatch(
      actionEntity({
        isCompany: !!filterCompany,
        groupIds: selectedGroup.map((groupsId) => groupsId.id),
        userIds: filterDeviceUser.map((usersId) => usersId.id),
        ...form,
      })
    );
  };

  const handleSecondary = () => {
    history.push(linkList);
  };

  const handleFilterGroup = (newFilter: GroupType[]) => {
    setSelectedGroup(newFilter);
  };

  const handleFilterCompany = (newFilter: CompanyType) => {
    setFilterCompany(newFilter);
  };

  const handleFilterGroupChange = (value) => {
    dispatch(listGroupsToFilter({ search: value }));
  };

  const handleFilterDeviceUser = (newFilter) => {
    setFilterDeviceUser(newFilter);
  };

  const handleFilterDeviceUserChange = (value) => {
    dispatch(listDeviceUserToFilter({ search: value }));
  };

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement & HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;

    setForm({ ...form, [name]: value });
  };

  return (
    <>
      <PageTitle
        showManualIcon
        title={<FormattedMessage id={keysIntl.title} />}
        description={<FormattedMessage id={keysIntl.description} />}
      />
      <PageErrors toasterKeys={[toasterKey]} translateKey={keysIntl.errors} />
      <FormContainer
        labelPrimary={<FormattedMessage id={keysIntl.send_label} />}
        disabledPrimary={disablePrimary}
        labelSecondary={<FormattedMessage id="global.cancel" />}
        handlePrimary={handlePrimary}
        handleSecondary={handleSecondary}
        loadingPrimary={isLoading}
      >
        <Flex flexDir="column" gridGap={4} w="100%">
          {isExternalAppScreen ? (
            <>
              <FormControl
                textLabel={
                  <FormattedMessage id="external_app_edit.input.url" />
                }
              >
                <Input
                  inputProps={{
                    name: 'downloadUrl',
                    value: form?.downloadUrl || '',
                    onChange: handleInputChange,
                  }}
                />
                <Box h="21px" mt={1}>
                  {isInvalidUrl && (
                    <Text color="warning.500" fontWeight="bold" fontSize="sm">
                      <FormattedMessage id="external_app_edit.invalid_url" />
                    </Text>
                  )}
                </Box>
              </FormControl>
            </>
          ) : (
            <>
              <Text mb="6" fontSize="2xl" fontWeight="600" color="gray.500">
                <FormattedMessage id={keysIntl.filter_title} />
              </Text>
              {!isGroup && (
                <FormControl
                  textLabel={<FormattedMessage id="global.company_name" />}
                >
                  <SelectAutocomplete
                    options={[company]}
                    value={filterCompany}
                    placeholder={<FormattedMessage id="global.company_name" />}
                    onChange={handleFilterCompany}
                  />
                </FormControl>
              )}
              <FormControl textLabel={<FormattedMessage id="global.group" />}>
                <SelectAutocomplete
                  options={groupsToFilter}
                  isMulti
                  value={filterCompany?.id ? null : selectedGroup}
                  onChange={handleFilterGroup}
                  onInputChange={handleFilterGroupChange}
                  placeholder={<FormattedMessage id="global.group" />}
                  disabled={!!filterCompany?.id}
                />
              </FormControl>
              <FormControl textLabel={<FormattedMessage id="global.users" />}>
                <SelectAutocomplete
                  options={devicesUsersToFilter}
                  value={filterCompany?.id ? null : filterDeviceUser}
                  isMulti
                  onChange={handleFilterDeviceUser}
                  onInputChange={handleFilterDeviceUserChange}
                  placeholder={<FormattedMessage id="global.users" />}
                  getOptionLabel={(option) =>
                    `${option.name || ''} ${option.device.phoneNumber || ''}`
                  }
                  disabled={!!filterCompany?.id}
                />
              </FormControl>

              {isRemoteAppScreen ? (
                <>
                  <FormControl
                    textLabel={
                      <FormattedMessage id="remote_app_edit.input.name" />
                    }
                  >
                    <Input
                      inputProps={{
                        name: 'name',
                        value: form?.name || '',
                        onChange: handleInputChange,
                      }}
                    />
                  </FormControl>
                  <FormControl
                    textLabel={
                      <FormattedMessage id="remote_app_edit.input.package_name" />
                    }
                  >
                    <Input
                      inputProps={{
                        name: 'packageName',
                        value: form?.packageName || '',
                        onChange: handleInputChange,
                      }}
                    />
                  </FormControl>
                  <FormControl
                    textLabel={
                      <FormattedMessage id="remote_app_edit.input.url" />
                    }
                  >
                    <Input
                      inputProps={{
                        name: 'url',
                        value: form?.url || '',
                        onChange: handleInputChange,
                      }}
                    />
                  </FormControl>
                </>
              ) : (
                <FormControl
                  mt="60px"
                  textLabel={<FormattedMessage id={keysIntl?.field_message} />}
                >
                  <Textarea
                    h="180px"
                    resize="none"
                    name="content"
                    onChange={handleInputChange}
                    borderColor="gray.600"
                    _focus={{
                      borderColor: 'primary.500',
                      borderWidth: 2,
                    }}
                  />
                  {form?.content?.length > 255 && (
                    <Text color="warning.500" fontWeight="bold" fontSize="sm">
                      <FormattedMessage id="messages.max_characters" />
                    </Text>
                  )}
                </FormControl>
              )}
            </>
          )}
        </Flex>
      </FormContainer>
    </>
  );
};

export default MessagesEdit;
