import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import {
  listNotifications,
  Types,
  updateNotificationRead,
} from '../../store/notification';
import {
  NotificationStatusCodeEnum,
  NotificationType,
  NotificationTypeCodeEnum,
} from '../../types/notification';
import BellIcon from '../Icons/Bell';
import NotificationBadgeIcon from '../Icons/NotificationBadge';
import Spinner from '../Spinner';
import Text from '../Text';
import ModalNotificationDetails from './ModalNotificationDetails';
import NotificationDescription from './NotificationDescription';
import NotificationSkeleton from './NotificationSkeleton';

const NotificationMenu = () => {
  const dispatch = useAppDispatch();
  const { notifications } = useAppSelector((state) => state.notification);
  const isLoading = useAppSelector(
    (state) => state.ui.loading[Types.LIST_NOTIFICATION]
  );
  const notificationsToShow = notifications.filter(
    (notification) =>
      notification.statusCode !== NotificationStatusCodeEnum.IN_PROGRESS
  );
  const [notificationDetails, setNotificationDetails] =
    useState<NotificationType>(null);
  const totalItems = notificationsToShow?.length;

  useEffect(() => {
    dispatch(listNotifications());
  }, []);

  const hasNewNotification = notificationsToShow?.some(
    (notification) =>
      !notification.visualized &&
      notification.statusCode !== NotificationStatusCodeEnum.ERROR
  );

  const handleClickOpenNotifications = () => {
    return null;
  };

  const isImportNotification = (notification: NotificationType) =>
    notification.typeCode === NotificationTypeCodeEnum.IMPORT;

  const handleClickItem = (notification: NotificationType) => {
    if (!notification.visualized) {
      dispatch(updateNotificationRead(Number(notification.id)));
    }
    if (!!notification.data && !isImportNotification(notification)) {
      window.open(notification.data);
    }
    if (isImportNotification(notification)) {
      setNotificationDetails(notification);
    }
  };

  const isMenuItemDisabled = (notification: NotificationType) => {
    const isImportNotification =
      notification?.typeCode === NotificationTypeCodeEnum.IMPORT;
    const isCommandNotification =
      notification?.typeCode === NotificationTypeCodeEnum.COMMAND;
    const hasNotificationError =
      notification?.statusCode === NotificationStatusCodeEnum.ERROR;

    const hasData = !!notification?.data;

    return (
      (!isImportNotification && !isCommandNotification && !hasData) ||
      hasNotificationError
    );
  };

  return (
    <>
      <Menu placement="bottom-start" isLazy>
        <MenuButton
          borderRadius="100%"
          w="48px"
          h="48px"
          _hover={{
            background: 'gray.200',
          }}
          _expanded={{
            background: 'gray.200',
          }}
          bgColor="gray.400"
          onClick={handleClickOpenNotifications}
        >
          <BellIcon boxSize={6} color={'primary.500'} />
          {hasNewNotification && (
            <NotificationBadgeIcon
              boxSize={2}
              color="warning.500"
              position="absolute"
              ml="-12px"
            />
          )}
        </MenuButton>
        <MenuList w="500px" maxW="500px">
          <Flex align="center" p="3" gridGap={2}>
            <Text m="0" fontSize="18px" color="primary.500" fontWeight="500">
              <FormattedMessage id="notification.title" />
            </Text>
            {isLoading && <Spinner size="sm" />}
          </Flex>

          <MenuDivider bgColor="gray.600" />
          <Box overflow="auto" maxH="500px" p="3">
            {isLoading && totalItems === 0 ? (
              [...Array(totalItems || 1)].map((_skeleton, index) => (
                <NotificationSkeleton key={index} />
              ))
            ) : totalItems > 0 ? (
              notificationsToShow?.map((notification, iNotification) => (
                <MenuItem
                  color="primary.500"
                  fontSize="sm"
                  key={notification.id}
                  borderBottom={iNotification + 1 < totalItems && '1px solid'}
                  borderColor="gray.600"
                  onClick={() => handleClickItem(notification)}
                  isDisabled={isMenuItemDisabled(notification)}
                >
                  <NotificationDescription notification={notification} />
                </MenuItem>
              ))
            ) : (
              <Text>
                <FormattedMessage id="notification.list.empty" />
              </Text>
            )}
          </Box>
        </MenuList>
      </Menu>

      {!!notificationDetails && (
        <ModalNotificationDetails
          isOpen={!!notificationDetails}
          onClose={() => setNotificationDetails(null)}
          notificationId={notificationDetails.id}
        />
      )}
    </>
  );
};

export default NotificationMenu;
