import { DeviceActionEnum } from '../../../../../../types/device';

export const batchActions = [
  {
    id: DeviceActionEnum.DISABLE,
    name: 'devices.modal_batch_devices_actions.action.disable',
  },
  {
    id: DeviceActionEnum.ENABLE,
    name: 'devices.modal_batch_devices_actions.action.enable',
  },
  {
    id: DeviceActionEnum.LOCK,
    name: 'devices.modal_batch_devices_actions.action.lock',
  },
  {
    id: DeviceActionEnum.REBOOT,
    name: 'devices.modal_batch_devices_actions.action.reboot',
  },
  {
    id: DeviceActionEnum.WIPE,
    name: 'devices.modal_batch_devices_actions.action.wipe',
  },
];

export const alertToIntlByActionSelected = {
  [DeviceActionEnum.DISABLE]:
    'devices.modal_batch_devices_actions.alert.disable',
  [DeviceActionEnum.ENABLE]: 'devices.modal_batch_devices_actions.alert.enable',
  [DeviceActionEnum.LOCK]: 'devices.modal_batch_devices_actions.alert.lock',
  [DeviceActionEnum.REBOOT]: 'devices.modal_batch_devices_actions.alert.reboot',
  [DeviceActionEnum.WIPE]: 'devices.modal_batch_devices_actions.alert.wipe',
  default: 'devices.modal_batch_devices_actions.alert.default',
};

export const commandTypeByTitle = {
  [DeviceActionEnum.ENABLE]:
    'devices.modal_batch_devices_actions.action.enable',
  [DeviceActionEnum.LOCK]: 'devices.modal_batch_devices_actions.action.lock',
  [DeviceActionEnum.REBOOT]:
    'devices.modal_batch_devices_actions.action.reboot',
  [DeviceActionEnum.WIPE]: 'devices.modal_batch_devices_actions.action.wipe',
  [DeviceActionEnum.DISABLE]:
    'devices.modal_batch_devices_actions.action.disable',
};
