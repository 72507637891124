import { Icon } from '@chakra-ui/react';
import React from 'react';

const RecentAppsIcon = (props) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="#e8e3e3"
        viewBox="0 0 256 256"
      >
        <path d="M208 32H48a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16Zm0 176H48V48h160v160Z" />
      </svg>
    </Icon>
  );
};

export default RecentAppsIcon;
