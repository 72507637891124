import { As } from '@chakra-ui/react';

import { importErrorToIntl } from '../../hooks/useImport';
import {
  NotificationImportTitleEnum,
  NotificationStatusCodeEnum,
  NotificationTitleEnum,
} from '../../types/notification';
import { commandTypeByTitle } from '../pages/Devices/ManageDevices/Modal/ModalBatchDevicesActions/utils';

export type NotificationDescriptionByTypeCodeType = {
  listTitle: string;
  buttonUrlTitle: string;
  prefixIcon: As<Element>;
  errorTitle?: string;
};

export const notificationTitleToIntl = {
  [NotificationTitleEnum.admin_user]: 'manage_admin.title',
  [NotificationTitleEnum.device_user]: 'manage_deviceuser.title',
  [NotificationTitleEnum.policy]: 'manage_policies.title',
  [NotificationTitleEnum.company_application]: 'enterprise_applications.title',
  [NotificationTitleEnum.device]: 'devices.title',
  [NotificationTitleEnum.application]: 'application_manage.title',
  [NotificationTitleEnum.group]: 'manage_groups.title',
  [NotificationTitleEnum.group_users]: 'edit_group.report_title',
  [NotificationTitleEnum.message]: 'message.title',
  [NotificationTitleEnum.recipient_message]: 'message_detail.title',
  [NotificationTitleEnum.document]: 'document.title',
  [NotificationTitleEnum.recipient_document]: 'document_details.title',
  [NotificationTitleEnum.license]: 'company_license.title',
  [NotificationTitleEnum.site]: 'sites_url.title',
  [NotificationTitleEnum.site_history]: 'date_report.title',
  [NotificationTitleEnum.consumption_data]: 'history_consumption_data.title',
  [NotificationTitleEnum.consumption_sms]: 'history_consumption_sms.title',
  [NotificationTitleEnum.consumption_limit_group]:
    'consumption_limit_group.title',
  [NotificationTitleEnum.consumption_limit_user]:
    'consumption_limit_user.title',
  [NotificationTitleEnum.website_limits_company]: 'sites.block.general.title',
  [NotificationTitleEnum.website_limits_group]: 'sites.block.general.title',
  [NotificationTitleEnum.device_without_communication]:
    'device_without_communication.title',
  [NotificationTitleEnum.localization_history]: 'location_history.title',
  [NotificationTitleEnum.last_location]: 'device_location.title',
  [NotificationTitleEnum.invoice_details]: 'invoice_details.title.title',
  [NotificationTitleEnum.invoice]: 'manage_invoice.title',
  [NotificationTitleEnum.network]: 'manage_networks.title',
  [NotificationTitleEnum.audit]: 'audit.title',
  [NotificationImportTitleEnum.IMPORT_GROUP]:
    'notification.batch_import.group.title',
  [NotificationTitleEnum.non_compliance]: 'non_compliance.title',
  [NotificationTitleEnum.install]: 'remoteApp.title',
  [NotificationTitleEnum.recipient_install]: 'external_apps.title',
  [NotificationTitleEnum.commands]: 'devices.modal_batch_devices_actions.title',
  [NotificationTitleEnum.carrier_companies_list]: 'manage_companies.title',
  ...commandTypeByTitle,
};

export const colorByStatusCode = {
  [NotificationStatusCodeEnum.WARNING]: 'warning.900',
  [NotificationStatusCodeEnum.COMPLETE]: 'success.500',
  [NotificationStatusCodeEnum.ERROR]: 'warning.600',
  [NotificationStatusCodeEnum.IN_PROGRESS]: 'warning.900',
};

export const importNotificationErrorToIntl = {
  ...importErrorToIntl,
};
