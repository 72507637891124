type ColorSchemeProps = {
  colorScheme: 'primary' | 'secondary' | 'success' | 'warning' | 'white';
};

const themeOption = {
  components: {
    ChakraReactSelect: {
      baseStyle: {
        menu: (provided) => ({ ...provided, zIndex: 3 }),
        container: (provided) => ({
          ...provided,
          minH: '45px',
          color: 'gray.900',
        }),
        valueContainer: (provided) => {
          return {
            ...provided,
          };
        },
        placeholder: () => ({
          color: 'gray.800',
        }),
      },
    },
    operationalSystemIcon: {
      baseStyle: {
        height: '20px',
        width: '20px',
      },
    },
    operationalSystemIcons: {
      variants: {
        sm: {
          w: '15px',
          h: '26px',
        },
        md: {
          h: '20px',
          w: '20px',
        },
        lg: {
          h: '24px',
          w: '24px',
        },
        xl: {
          h: '26px',
          w: '30px',
          color: 'gray.500',
        },
      },
    },
    Table: {
      baseStyle: {
        td: {
          role: 'cell',
          color: 'gray.500',
          fontSize: 'sm',
        },
      },
      variants: {
        simple: {
          th: {
            color: 'gray.900',
            fontSize: 'sm',
            fontWeight: 'normal',
          },
        },
      },
    },
    Skeleton: {
      defaultProps: {
        startColor: 'gray.300',
        endColor: 'gray.200',
      },
      baseStyle: {
        borderRadius: 'md',
      },
    },
    ChartTooltip: {
      baseStyle: {
        padding: '12px',
        color: 'gray.500',
        background: 'white',
        borderRadius: 'md',
        borderWidth: 2,
        shadow: 'lg',
      },
    },
    RadioButton: {
      baseStyle: {
        bg: 'gray.400',
        color: 'gray.900',
        borderWidth: '1px',
        borderRadius: '1px',
        _checked: {
          bg: 'gray.500',
          borderColor: 'gray.600',
          color: 'white',
        },
        _first: {
          borderTopLeftRadius: '5px',
          borderBottomLeftRadius: '5px',
        },
        _last: {
          borderTopRightRadius: '5px',
          borderBottomRightRadius: '5px',
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          fontSize: 'sm',
          borderColor: 'gray.500',
          _checked: {
            bg: 'primary.500',
            borderColor: 'primary.500',
            _hover: {
              bg: 'primary.500',
            },
          },
        },
      },
    },
    Tabs: {
      variants: {
        line: {
          tab: {
            fontSize: 'xl',
            fontWeight: 'bold',
            color: 'gray.300',
            _selected: {
              color: 'primary.600',
              borderColor: 'primary.600',
              borderBottomWidth: '4px',
              marginBottom: '-4px',
              _focus: { boxShadow: 'none' },
            },
          },
          tablist: {
            w: '100%',
            d: 'flex',
            borderBottom: '4px',
          },
          tabpanel: {
            pl: '0',
            pr: '0',
          },
        },
      },
    },
    MenuSideIcons: {
      baseStyle: {
        menuIcon: {
          bg: 'white',
        },
        items: {
          bg: 'primary.600',
        },
      },
    },
    MenuDrillDown: {
      baseStyle: {
        bg: 'white',
      },
    },
    Text: {
      baseStyle: {
        color: 'gray.500',
        fontWeight: '300',
      },
    },
    Alert: {
      variants: {
        // define own toast variant
        toast_success: {
          container: {
            color: 'white',
            bg: 'success.500',
          },
        },
        toast_error: {
          container: {
            color: 'white',
            bg: 'warning.600',
          },
        },
        toast_warning: {
          container: {
            color: 'white',
            bg: 'orange.400',
          },
        },
      },
      baseStyle: {
        color: 'gray.900',
        fontSize: 'sm',
        fontWeight: 'normal',
      },
    },
    Card: {
      baseStyle: {
        bg: 'white',
        borderRadius: '10px',
        p: '1.6% 1.3%',
      },
    },
    CardHeader: {
      baseStyle: {
        Text: {
          color: 'gray.500',
          fontWeight: '600',
        },
        Divider: {
          borderColor: 'gray.600',
        },
      },
    },
    FormContainer: {
      baseStyle: {
        Divider: {
          borderColor: 'gray.600',
        },
      },
    },
    FormControl: {
      baseStyle: {
        FormLabel: {
          fontSize: 'sm',
        },
        Text: {
          color: 'warning.500',
          fontSize: 'sm',
        },
      },
    },
    Divider: {
      defaultProps: {
        orientation: 'horizontal',
      },
      baseStyle: {
        borderColor: 'gray.600',
      },
    },
    Heading: {
      variants: {
        h1: {
          fontWeight: 'light',
          fontSize: '5xl',
        },
        h2: {
          fontWeight: '600',
          fontSize: '2xl',
        },
        text: {
          fontWeight: '600',
          fontSize: 'md',
        },
      },
    },
    Input: {
      baseStyle: {
        container: {
          h: '45px',
          color: 'gray.900',
        },
        field: {
          borderWidth: 1,
          borderColor: 'gray.600',
          _focus: {
            borderWidth: 2,
            borderColor: 'primary.500',
            boxShadow: 'none !important',
            outline: 'none !important',
          },
          _placeholder: {
            color: 'gray.800',
          },
          _disabled: {
            opacity: '0.5',
            _hover: {
              opacity: '1',
              cursor: 'not-allowed',
            },
          },
        },
      },
      sizes: {},
      variants: {},
      defaultProps: {
        variant: null, // null here
      },
    },
    Button: {
      variants: {
        solid: ({ colorScheme }: ColorSchemeProps) => {
          switch (colorScheme) {
            case 'primary':
              return {
                bg: 'primary.500',
                color: 'white',
                _active: {
                  bg: 'primary.500',
                },
                _hover: {
                  bg: 'primary.900',
                  _disabled: {
                    opacity: '0.5',
                  },
                },
                _focus: {
                  boxShadow: 'none',
                },
              };
          }
        },
        ghost: ({ colorScheme }: ColorSchemeProps) => {
          switch (colorScheme) {
            case 'primary':
              return {
                color: 'primary.500',
                outline: 'primary.500',
                _active: {
                  color: 'white',
                  bg: 'primary.500',
                  opacity: '0.6',
                },
                _focus: {
                  boxShadow: 'none',
                },
                _hover: {
                  _disabled: {
                    opacity: '0.5',
                  },
                },
              };
          }
        },
        link: ({ colorScheme }: ColorSchemeProps) => {
          switch (colorScheme) {
            case 'primary':
              return {
                color: 'primary.500',
                _focus: {
                  boxShadow: 'none',
                },
              };
          }
        },
        outline: ({ colorScheme }: ColorSchemeProps) => {
          switch (colorScheme) {
            case 'primary':
              return {
                bg: 'transparent',
                color: 'primary.500',
                borderColor: 'primary.500',
                _focus: {
                  boxShadow: 'none',
                },
              };
            case 'warning':
              return {
                bg: 'transparent',
                color: 'primary.700',
                borderColor: 'primary.700',
                _active: {
                  borderColor: 'primary.700',
                },
                _focus: {
                  boxShadow: 'none',
                },
              };
          }
        },
      },
    },
    Badge: {
      variants: {
        subtle: ({ colorScheme }) => {
          switch (colorScheme) {
            case 'primary':
              return {
                bg: 'primary.500',
                borderColor: 'primary.500',
                color: 'white',
                opacity: 0.75,
              };
          }
        },
      },
    },
  },

  colors: {
    transparent: 'transparent',
    white: '#fff',
    gray: {
      50: '#b5b1b1',
      200: '#e8e8eb',
      300: '#a0a0a5',
      400: '#f2f4f8',
      500: '#6e6e78',
      600: '#d7d7dc',
      800: '#999',
      900: '#282832',
    },
    primary: {
      500: '#8B0E55',
      600: '#7C0649',
      900: '#4C0629',
    },
    success: {
      300: '#68D391',
      500: '#00c3af',
    },
    warning: {
      500: '#ff0000',
      600: '#de6163',
      900: '#D6AD31',
    },
    map: {
      100: '#00d23f',
      200: '#ff961e',
      300: '#00d2b5',
      400: '#B2DCFC',
    },
    android: {
      50: '#AAA',
      500: '#a4c639',
    },
    ios: {
      50: '#AAA',
      500: '#000000',
    },
    chart: {
      50: '#98e6de',
      100: '#c8c7fd',
      200: '#adc9fa',
      300: '#a0a0a5',
      400: '#4a83e4',
      500: '#005cfa',
      600: '#86E2D5',
      700: '#D9F7F1',
      800: '#c8c7fd',
      900: '#EAE9FF',
      950: '#31394d',
      1000: '#900',
      1050: '#060',
      1100: '#6F6',
      1150: '#ebbfea',
      1200: '#FDDFA2',
    },
    orange: {
      400: '#F39C12',
    },
  },
  fonts: {
    body: '"Open Sans", no-serif',
    heading: 'Open Sans',
    mono: 'Open Sans',
  },
  fontSizes: {
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '20px',
    '2xl': '24px',
    '3xl': '28px',
    '4xl': '32px',
    '5xl': '40px',
    '6xl': '64px',
  },
  sizes: {
    full: '100%',
    '3xs': '14rem',
    '2xs': '16rem',
    xs: '20rem',
    sm: '24rem',
    md: '28rem',
    lg: '32rem',
    xl: '36rem',
    '2xl': '42rem',
    '3xl': '48rem',
    '4xl': '56rem',
    '5xl': '64rem',
    '6xl': '72rem',
  },
  breakpoints: {
    sm: '320px',
    md: '768px',
    lg: '960px',
    xl: '1200px',
    xxl: '1336px',
  },
  styles: {
    global: {
      body: {
        color: 'gray.900',
      },
      'h1, h2, h3, h4, h5, h6': {
        color: 'gray.500',
      },
      // sweetalert2
      '.swal2-container': {
        zIndex: '1500',
      },
      '.swal2-container-plan-alert': {
        zIndex: '1400',
      },

      '.button': {
        height: '45px',
      },

      '.button.solid': {
        margin: '19px 14px 0 29px',
        padding: '12px 53px 11px',
        backgroundColor: 'primary.500',
      },
      '.button.solid:hover': {
        opacity: '0.7',
      },

      '.button.outline': {
        margin: '19px 29px 0 14px',
        padding: '12px 54px 11px 53px',
        borderColor: 'primary.500',
        border: 'solid 1px',
        backgroundColor: 'transparent',
        color: 'primary.500',
      },
      '.button.outline:hover': {
        backgroundColor: 'transparent',
        opacity: '0.7',
      },
      '.button.outline:active': {
        backgroundColor: 'primary.500',
        opacity: '0.7',
      },

      // react-datepicker

      '.react-datepicker-popper': {
        zIndex: 3,
      },

      '.react-datepicker__day--keyboard-selected,': {
        backgroundColor: 'primary.500',
      },

      '.react-datepicker-wrapper, .react-datepicker__input-container': {
        display: 'block',
      },

      '.react-datepicker__input-container:hover': {
        borderColor: 'hsl(0, 0%, 70%)',
      },

      '.react-datepicker__input-container:focus-within': {
        zIndex: 2,
        borderColor: 'primary.500',
        borderRadius: '5px',
        boxShadow: '0 0 0 1px primary.500',
      },

      '.react-datepicker__input-container > input': {
        width: '100%',
        height: '100%',
        outline: 0,
      },

      '.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) ':
        {
          right: '90px',
        },

      '.react-datepicker__navigation--previous': {
        borderRightColor: '#cbd5e0',
      },

      '.react-datepicker__navigation--previous:hover': {
        borderRightColor: '#a0aec0',
      },

      '.react-datepicker__navigation--next': {
        borderLeftColor: '#cbd5e0',
      },

      '.react-datepicker__navigation--next:hover': {
        borderLeftColor: '#a0aec0',
      },

      '.react-datepicker__header': {
        background: '#f7fafc',
      },

      '.react-datepicker__header, .react-datepicker__time-container': {
        borderColor: '#e2e8f0',
      },

      '.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header':
        {
          fontSize: 'inherit',
          fontWeight: 600,
        },

      '.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item':
        {
          margin: '0 1px 0 0',
          height: 'auto',
          padding: ' 7px 10px',
        },

      '.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover':
        {
          background: '#edf2f7',
        },

      '.react-datepicker__day:hover': {
        background: '#edf2f7',
      },

      '.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected':
        {
          background: 'primary.500',
          fontWeight: 'normal',
        },

      '.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover':
        {
          background: 'primary.500',
        },

      '.react-datepicker__close-icon::after': {
        backgroundColor: 'unset',
        borderRadius: 'unset',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        color: 'hsl(0, 0%, 80%)',
        height: '20px',
        width: '20px',
      },

      '.react-datepicker__close-icon::after:hover': {
        color: 'hsl(0, 0%, 70%)',
      },

      // terms

      '.termsOfUsage, .privacyPolicy': {
        fontFamily: 'Open Sans',

        'h1, h2': {
          marginY: 6,
          color: 'black',
          fontWeight: '600',
        },
        'p, li': {
          color: 'gray.500',
        },
      },

      // Google maps
      '.google-maps-marker-index': {
        backgroundColor: 'white',
        borderRadius: '100%',
        width: '20px',
        height: '20px',
        display: 'grid',
        placeContent: 'center',
        marginBottom: '7px',
      },
    },
  },
};

export default themeOption;
