import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { FormatDateByUserLogged } from '../../../FormatDateByUserLogged';
import { MarkerMapProps } from '../../../Map';
import Text from '../../../Text';

export interface MarkerInfosProps {
  phoneNumber: string;
  dateAndTime: string | number | Date;
}

interface MapInfoWindowProps {
  selected: MarkerMapProps<MarkerInfosProps>;
}

const MapInfoWindowComponent = ({ selected }: MapInfoWindowProps) => {
  return (
    <>
      <Text color="gray.500">
        <FormattedMessage id="map.selected.infos.phone" />:{' '}
        {selected.infos.phoneNumber}
      </Text>
      <Text color="gray.500">
        <FormattedMessage id="map.selected.infos.date_time" />:{' '}
        <FormatDateByUserLogged date={selected.infos.dateAndTime} />
      </Text>
    </>
  );
};

export const MapInfoWindow = memo(MapInfoWindowComponent);
