import { OperationalSystemEnum } from './device';
import { DayOfWeekCodeEnum, SyncTimeEnum } from './generalConfiguration';
import { PlanType } from './plan';
import { StateType } from './state';
import { Bytes, enterpriseId, ID } from './util';

export enum CompanyInfoResponseErrorEnum {
  MISSING_TOKEN = 'MISSING_TOKEN',
}

export interface CompanyType {
  id?: ID;
  corporateName?: string;
  name?: string;
  phoneNumber?: string;
  email?: string;
  address?: string;
  zipCode?: string;
  city?: string;
  state?: string;
  contact?: string;
  federalCode?: string;
  passCode?: string;
  enterpriseId?: enterpriseId;
  dataProtectionOfficerName?: string;
  dataProtectionOfficerEmail?: string;
  dataProtectionOfficerPhone?: string;
  euRepresentativeName?: string;
  euRepresentativeEmail?: string;
  euRepresentativePhone?: string;
  token?: string;
  contentTokenABM?: string;
}

export interface LicenseType {
  id?: ID;
  name?: string;
  status: true;
  state?: StateType['key'];
  phoneNumber?: string;
  plan?: PlanType;
  planName?: string;
  cancelledAt?: never;
  createdAt?: Date;
  updatedAt?: Date;
  license?: LicenseEntityType;
}

export interface LicenseEntityType {
  id?: ID;
  companyId?: unknown;
  bought?: unknown;
  free?: unknown;
  poc?: unknown;
  pocStarted?: unknown;
  pocEnded?: unknown;
  createdAt?: unknown;
  updatedAt?: unknown;
}

export interface ConsumptionType {
  id?: ID;
  consumptionDate?: Date;
  user: string;
  phoneNumber: string;
  group: string;
  subGroup: string;
  roaming: boolean;
  carrierNetwork: string;
  operationalSystem?: OperationalSystemEnum;
}

export interface DataFilter {
  user?: string;
  phoneNumber?: string;
  group?: string;
}

export interface ConsumptionDataType extends ConsumptionType {
  consumption: Bytes;
}

export interface ConsumptionSmsType extends ConsumptionType {
  destinationPhoneNumber: string;
}

export interface EnterpriseRegister {
  enterpriseToken?: string;
  signupUrlName?: string;
}

export interface EnterpriseResponseType {
  url?: string;
  name?: string;
}

export interface SettingsUpdateType {
  startDayOfWeek: DayOfWeekCodeEnum;
  endDayOfWeek: DayOfWeekCodeEnum;
  startCycleDay: number;
  syncTime: SyncTimeEnum;
  notifyByEmail: boolean;
  loginExpirationInSeconds?: LoginExpirationTimeEnum;
  hasTwoFactorAuthentication?: boolean;
}

export interface HelpDeskCompanyType {
  id?: ID;
  name?: string;
  email?: string;
  devices?: number;
  termsOfUse?: boolean;
  licenses?: number;
  createdAt?: string;
}

export interface CarrierCompanyType extends CompanyType {
  updatedAt?: string;
  createdAt?: string;
  status?: boolean;
  numberOfLicenses?: number;
  referenceCompanyEmail?: string;
  planId: string;
}

export enum LoginExpirationTimeEnum {
  ThirtyMinutes = 1800,
  TwentyMinutes = 1200,
  TenMinutes = 600,
  FiveMinutes = 300,
}

export interface CompanySendWelcomeEmailData {
  externalCode: string;
  adminEmail: string;
}

export interface CompanyResendWelcomeEmailData {
  companyId: number;
}
export interface CompanyPlan {
  id?: string;
  name?: string;
  createdAt?: string;
  updatedAt?: string;
}
