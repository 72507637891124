import { Flex, FlexProps } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Skeleton from '../../../../Skeleton';
import Text from '../../../../Text';
import { TextCopy } from '../../../../TextCopy';

interface InvoiceAccessKeyProps extends FlexProps {
  invoiceAccessKey: string;
  isLoading?: boolean;
}

const InvoiceAccessKey = ({
  invoiceAccessKey,
  isLoading,
  ...rest
}: InvoiceAccessKeyProps) => {
  return (
    <Flex direction="column" {...rest}>
      <Text fontWeight="500" color="gray.900" margin="0 0 .5rem 0">
        <FormattedMessage id="invoice_details.access_key" />
      </Text>
      {isLoading ? (
        <Skeleton w="400px" h="25px" />
      ) : (
        <TextCopy text={invoiceAccessKey} />
      )}
    </Flex>
  );
};

export default InvoiceAccessKey;
