import { ArrowBackIcon, RepeatIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { Center, Flex, Heading, Text } from '@chakra-ui/layout';
import { useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router';

import routes from '../../routes';
import Button from '../Button';

export default function Fallback({ resetErrorBoundary }: FallbackProps) {
  const location = useLocation();
  const history = useHistory();
  const intl = useIntl();

  useEffect(() => {
    return () => {
      resetErrorBoundary();
    };
  }, [location.pathname]);

  const handleReload = () => {
    window.location.reload();
  };

  const handleBack = () => {
    history.push(routes.home);
  };

  return (
    <Flex w="full" height="full" justifyContent="center" alignItems="center">
      <Center bgColor="white" borderRadius="xl" flexDir="column" p={8}>
        <WarningTwoIcon boxSize={10} color="red.600" />

        <Heading mt={4} mb={2}>
          {intl.formatMessage({ id: 'error_boundary.title' })}
        </Heading>

        <Text letterSpacing="wide" fontSize="lg">
          {intl.formatMessage({ id: 'error_boundary.description' })}
        </Text>

        <Center gridGap={4} mt={8}>
          <Button onClick={handleBack}>
            <ArrowBackIcon boxSize={5} mr={2} />

            {intl.formatMessage({ id: 'error_boundary.action.back' })}
          </Button>

          <Button onClick={handleReload} variant="outline">
            <RepeatIcon boxSize={5} mr={2} />

            {intl.formatMessage({ id: 'error_boundary.action.reload' })}
          </Button>
        </Center>
      </Center>
    </Flex>
  );
}
