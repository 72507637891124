import React from 'react';

import IconButton from '../../../Icons/IconButton';
import GoBackIcon from '../../../Icons/RemoteView/NavigationBar/GoBack';
import HomeIcon from '../../../Icons/RemoteView/NavigationBar/Home';
import RecentAppsIcon from '../../../Icons/RemoteView/NavigationBar/RecentApps';
import MockupBar from './MockupBar';

interface NavigationBarProps {
  handleHome?: () => void;
  handleRecentApps?: () => void;
  handleGoBack?: () => void;
}

const NavigationBar = ({
  handleGoBack,
  handleRecentApps,
  handleHome,
}: NavigationBarProps) => {
  return (
    <MockupBar backgroundColor="black" borderBottomRadius="10px">
      <IconButton
        aria-label="GoBackIcon"
        icon={<GoBackIcon />}
        onClick={handleGoBack}
      />
      <IconButton
        aria-label="HomeIcon"
        icon={<HomeIcon />}
        onClick={handleHome}
      />
      <IconButton
        aria-label="RecentAppsIcon"
        icon={<RecentAppsIcon />}
        onClick={handleRecentApps}
      />
    </MockupBar>
  );
};

export default NavigationBar;
