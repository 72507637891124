import { useTheme } from '@chakra-ui/react';
import { ResponsiveBar, BarDatum, BarSvgProps } from '@nivo/bar';

interface DataType extends BarDatum {
  indexBy: string | number;
}

interface ChartBarProps
  extends Omit<BarSvgProps<BarDatum>, 'height' | 'width'> {
  data: DataType[];
  color?: string[];
  keys: string[];
  formatRight?: (e) => React.ReactNode;
  formatLeft?: (e) => React.ReactNode;
  formatTop?: (e) => React.ReactNode;
  formatBottom?: (e) => React.ReactNode;
}

const ChartBar = ({
  data,
  color,
  keys,
  formatRight,
  formatLeft,
  formatTop,
  formatBottom,
  ...rest
}: ChartBarProps) => {
  const { colors, fontSizes } = useTheme();

  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      indexBy="indexBy"
      margin={{ top: 50, right: 100, bottom: 50, left: 110 }}
      padding={0.5}
      layout="horizontal"
      colors={color || colors['chart']['50']}
      gridXValues={4}
      enableGridX={true}
      enableGridY={false}
      labelSkipWidth={14}
      labelSkipHeight={12}
      enableLabel={false}
      role="application"
      isInteractive={false}
      axisBottom={
        formatBottom
          ? {
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: 'middle',
              legendOffset: -40,
              format: formatBottom,
            }
          : null
      }
      axisTop={
        formatTop
          ? {
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: 'middle',
              legendOffset: -40,
              format: formatTop,
            }
          : null
      }
      axisLeft={
        formatLeft
          ? {
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: 'middle',
              legendOffset: -40,
              format: formatLeft,
            }
          : null
      }
      axisRight={
        formatRight
          ? {
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: 'middle',
              legendOffset: -40,
              format: formatRight,
            }
          : null
      }
      labelTextColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      theme={{
        fontSize: fontSizes['xs'],
      }}
      {...rest}
    />
  );
};

export default ChartBar;
