import {
  ChevronDownIcon,
  ChevronRightIcon,
  InfoOutlineIcon,
} from '@chakra-ui/icons';
import { Flex } from '@chakra-ui/layout';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Alert, { AlertHtml } from '../../../components/Alert';
import DatePicker from '../../../components/Datepicker';
import FormContainer from '../../../components/FormContainer';
import FormControl from '../../../components/FormControl';
import OperationalSystemIcon from '../../../components/Icons/OperationalSystemIcon';
import {
  ManagementModeOption,
  ManagementModeSelect,
} from '../../../components/ManagementModeSelect';
import Modal from '../../../components/Modal';
import PageFilter from '../../../components/PageFilter';
import ActionItems from '../../../components/pages/Devices/ManageDevices/ActionItems';
import Inventory from '../../../components/pages/Devices/ManageDevices/Inventory';
import LastPasswordsModal from '../../../components/pages/Devices/ManageDevices/LastPasswords';
import ListDrillDown from '../../../components/pages/Devices/ManageDevices/ListDrillDown';
import alertNewPassword from '../../../components/pages/Devices/ManageDevices/Modal/alertNewPassword';
import ModalBatchDevices from '../../../components/pages/Devices/ManageDevices/Modal/ModalBatchDevicesActions';
import ModalMoreInformation from '../../../components/pages/Devices/ManageDevices/Modal/ModalMoreInformation';
import ModalNewDevicePassword from '../../../components/pages/Devices/ManageDevices/Modal/ModalNewDevicePassword';
import ModalPolicyDevice from '../../../components/pages/Devices/ManageDevices/Modal/ModalPolicy';
import PageShowToaster from '../../../components/PageShowToaster';
import PageTitle from '../../../components/PageTitle';
import SelectAutocomplete from '../../../components/SelectAutocomplete';
import Table from '../../../components/Table';
import { Body } from '../../../components/Table/TableInterfaces';
import TableActions from '../../../components/TableActions';
import Text from '../../../components/Text';
import { DATE_OF_TODAY } from '../../../helper';
import { inFirstPage } from '../../../helper/metadata';
import { useSorting } from '../../../helper/sort';
import { useFormatDate } from '../../../hooks/useFormatDate';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import {
  deviceVerifyCommandInProcess,
  listDeviceManufacturer,
  listDeviceModel,
  listDevices,
  Types,
  verifyBatchDevicesActions,
} from '../../../store/device';
import { listDeviceUserToFilter } from '../../../store/deviceUser';
import { generateExcel } from '../../../store/event';
import { listGroupsToFilter } from '../../../store/group';
import { listPoliciesToSelect } from '../../../store/policy';
import {
  hasLoading,
  hasSuccess,
  selectorMetadata,
  uiRemoveSuccess,
} from '../../../store/ui';
import {
  deviceStateToIntl,
  DeviceType,
  ManufacturerType,
  ModelType,
  OperationalSystemEnum,
} from '../../../types/device';
import { GroupType } from '../../../types/group';
import {
  ManagementModeEnumToIntl,
  PolicyToSelectType,
} from '../../../types/policy';
import { operationalSystemToIntl } from '../../Policies/EditPolicies';

export enum ModalTypeEnum {
  'PASSWORD_HISTORIC' = 'PASSWORD_HISTORIC',
  'DEVICE_POLICY' = 'DEVICE_POLICY',
  'NEW_DEVICE_PASSWORD' = 'NEW_DEVICE_PASSWORD',
  'MORE_INFORMATION' = 'MORE_INFORMATION',
  'BATCH_DEVICES_ACTIONS' = 'BATCH_DEVICES_ACTIONS',
}

export interface DeviceRegisterFilterType {
  startAt?: Date;
  endAt?: Date;
}

const ManageDevices = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { handleFormatDateByUserLogged } = useFormatDate();
  const { devices, manufacturers, models, hasCommandInProcess } =
    useAppSelector((state) => state.device);
  const { portalSettings } = useAppSelector((state) => state.portalSettings);
  const { groupsToFilter } = useAppSelector((state) => state.group);
  const { policiesToFilter } = useAppSelector((state) => state.policy);

  const systemToFilter = [
    {
      id: OperationalSystemEnum.ANDROID,
      name: intl.formatMessage({
        id: operationalSystemToIntl[OperationalSystemEnum.ANDROID],
      }),
    },
    {
      id: OperationalSystemEnum.IOS,
      name: intl.formatMessage({
        id: operationalSystemToIntl[OperationalSystemEnum.IOS],
      }),
    },
  ];

  const metadata = useAppSelector(selectorMetadata(Types.LIST));

  const [modalType, setModalType] = useState<ModalTypeEnum | null>(null);
  const [selectedDevice, setSelectedDevice] = useState<DeviceType>();
  const isRemoveSuccess = hasSuccess(Types.ACTION_REMOVE);
  const isNewDevicePasswordSuccess = hasSuccess(
    Types.ACTION_CHANGE_DEVICE_PASSWORD
  );
  const isRebootSuccess = hasSuccess(Types.ACTION_REBOOT);

  const openModal = (modalType: ModalTypeEnum, device: DeviceType) => {
    setModalType(modalType);
    setSelectedDevice(device);
  };

  const [filterManufacturer, setFilterManufacturer] =
    useState<ManufacturerType>();
  const [filterModel, setFilterModel] = useState<ModelType>();
  const [filterSearch, setFilterSearch] = useState('');
  const [filterGroups, setFilterGroups] = useState<GroupType>();
  const [filterOperationalSystem, setFilterOperationalSystem] = useState(null);
  const [filterDeviceRegister, setFilterDeviceRegister] =
    useState<DeviceRegisterFilterType>();
  const [filterManagementMode, setFilterManagementMode] =
    useState<ManagementModeOption>(null);
  const [filterPolicy, setFilterPolicy] = useState<PolicyToSelectType>(null);
  const [hasAdvancedFilter, setHasAdvancedFilter] = useState(false);

  const allFilters = {
    manufacturer: filterManufacturer?.manufacturer,
    model: filterModel?.model,
    search: filterSearch,
    groupId: filterGroups?.id,
    operationalSystem: filterOperationalSystem?.id,
    managementMode: filterManagementMode?.value,
    policyId: filterPolicy?.id,
    startAt: filterDeviceRegister?.startAt,
    endAt: filterDeviceRegister?.endAt,
  };

  const isVerifyBatchDevicesActionsSuccess = hasSuccess(
    Types.VERIFY_BATCH_DEVICES_ACTIONS
  );
  const isVerifyDevicesActionsLoading = hasLoading(
    Types.VERIFY_BATCH_DEVICES_ACTIONS
  );

  useEffect(() => {
    if (
      isVerifyBatchDevicesActionsSuccess &&
      hasCommandInProcess !== null &&
      !hasCommandInProcess
    ) {
      setModalType(ModalTypeEnum.BATCH_DEVICES_ACTIONS);
      dispatch(uiRemoveSuccess(Types.VERIFY_BATCH_DEVICES_ACTIONS));
    }

    if (isVerifyBatchDevicesActionsSuccess && hasCommandInProcess) {
      Alert({
        onConfirm: () => dispatch(deviceVerifyCommandInProcess(null)),
        onCancel: () => dispatch(deviceVerifyCommandInProcess(null)),
        showCancelButton: false,
        html: (
          <AlertHtml
            text={intl.formatMessage({
              id: 'devices.alert.batch_in_process',
            })}
          />
        ),
        confirmButtonText: intl.formatMessage({
          id: 'global.confirm',
        }),
      });
    }
  }, [isVerifyBatchDevicesActionsSuccess, hasCommandInProcess]);

  useEffect(() => {
    dispatch(listDeviceManufacturer());
    dispatch(listDeviceModel());
    dispatch(listDeviceUserToFilter());
    dispatch(listGroupsToFilter());
    dispatch(listPoliciesToSelect());
  }, []);

  useEffect(() => {
    dispatch(listDevices(inFirstPage(metadata), allFilters));
  }, [
    filterManufacturer,
    filterModel,
    filterSearch,
    filterGroups,
    filterOperationalSystem,
    filterManagementMode,
    filterPolicy,
    filterDeviceRegister,
  ]);

  const handleFilterManufacturer = (newFilter: ManufacturerType) => {
    setFilterManufacturer(newFilter);
    dispatch(listDeviceManufacturer());
  };
  const handleFilterModel = (newFilter: ModelType) => {
    setFilterModel(newFilter);
    dispatch(listDeviceModel());
  };
  const handleFilterGroups = (newFilter: GroupType) => {
    setFilterGroups(newFilter);
    dispatch(listGroupsToFilter());
  };

  const handleOperationalSystem = (newFilter) => {
    setFilterOperationalSystem(newFilter);
  };

  const handleManagementModeFilter = (option: ManagementModeOption) => {
    setFilterManagementMode(option);
  };

  const handleFilterPolicies = (newFilter: PolicyToSelectType) => {
    setFilterPolicy(newFilter);
  };

  const handleFilterPoliciesChange = (value) => {
    dispatch(listPoliciesToSelect({ search: value }));
  };

  const handleFilterModelChange = (value) => {
    dispatch(listDeviceModel({ search: value }));
  };

  const handleFilterManufacturerChange = (value) => {
    dispatch(listDeviceManufacturer({ search: value }));
  };

  const handleFilterGroupsChange = (value) => {
    dispatch(listGroupsToFilter({ search: value }));
  };

  const handleMetadata = (value) => {
    dispatch(listDevices({ ...metadata, ...value }, allFilters));
  };

  const handlePeriodFilterChange = (date: Date, field: string) => {
    setFilterDeviceRegister({ ...filterDeviceRegister, [field]: date });
  };

  function handleGetSuccessMessage() {
    if (isRemoveSuccess) return 'devices.message.remove_success';
    if (isRebootSuccess) return 'devices.action.alert_reboot';
    if (isNewDevicePasswordSuccess)
      return 'device.modal.new_device_password.success';

    return 'success.generic_success';
  }

  const headerColumns = useSorting(
    [
      {
        isExportHidden: true,
        header: '',
        accessor: 'handle_expander',
      },
      {
        header: '',
        accessor: 'systemIcon',
        isExportHidden: true,
        px: 0,
      },
      {
        header: intl.formatMessage({ id: 'devices.column.user' }),
        accessor: 'user',
        canSort: true,
      },
      {
        header: intl.formatMessage({ id: 'devices.column.federalCode' }),
        accessor: 'federalCode',
        canSort: true,
      },
      {
        header: intl.formatMessage({ id: 'devices.column.phone_number' }),
        accessor: 'phone_number',
        canSort: true,
      },
      {
        header: intl.formatMessage({ id: 'devices.column.imei' }),
        accessor: 'imei',
        canSort: true,
      },
      {
        header: intl.formatMessage({ id: 'devices.column.model' }),
        accessor: 'model',
        canSort: true,
      },
      {
        header: '',
        accessor: 'actions',
      },
      {
        isExportHidden: true,
        header: '',
        accessor: 'expander',
      },
      {
        isExpandedField: true,
        header: intl.formatMessage({ id: 'devices.column.policy' }),
        accessor: 'policy',
      },
      {
        isExpandedField: true,
        header: intl.formatMessage({
          id: 'devices.column.accordion.management_mode',
        }),
        accessor: 'managementMode',
      },
      {
        isExpandedField: true,
        header: intl.formatMessage(
          { id: 'devices.column.companion_version' },
          { companionName: portalSettings.name }
        ),
        accessor: 'companionVersion',
      },
      {
        isExpandedField: true,
        header: intl.formatMessage({ id: 'devices.column.iccid' }),
        accessor: 'iccid',
      },
      {
        isExpandedField: true,
        header: intl.formatMessage({ id: 'devices.column.manufacturer' }),
        accessor: 'manufacturer',
      },
      {
        isExpandedField: true,
        header: intl.formatMessage({ id: 'devices.column.register_date' }),
        accessor: 'registerDate',
      },
      {
        isExpandedField: true,
        header: intl.formatMessage({ id: 'devices.column.group' }),
        accessor: 'group',
      },
      {
        isExpandedField: true,
        header: intl.formatMessage({ id: 'devices.column.state' }),
        accessor: 'state',
      },
      {
        isExpandedField: true,
        header: intl.formatMessage({ id: 'devices.column.operational_system' }),
        accessor: 'operationalSystem',
      },
      {
        isExpandedField: true,
        header: intl.formatMessage({ id: 'devices.column.last_communication' }),
        accessor: 'lastCommunication',
      },
    ],
    metadata
  );

  const customValue = (device) => ({
    state:
      device?.state &&
      intl.formatMessage({ id: deviceStateToIntl[device?.state] }),
    activatedAt: handleFormatDateByUserLogged(device?.activatedAt),
  });

  function transformValue(object: DeviceType, key: string) {
    return !customValue(object)?.[key]
      ? object?.[key]
      : customValue(object)?.[key];
  }

  const rows: Body[] = devices?.map((device) => ({
    cells: [
      {
        isExportHidden: true,
        field: 'handle_expander',
        value: null,
        transform: ({ isExpanded, toggleExpanded }) => (
          <span>
            {isExpanded ? (
              <ChevronDownIcon
                boxSize="5"
                color="gray.600"
                onClick={toggleExpanded}
              />
            ) : (
              <ChevronRightIcon
                boxSize="5"
                color="gray.600"
                onClick={toggleExpanded}
              />
            )}
          </span>
        ),
        chackraProps: { width: 0 },
      },
      {
        value: (
          <OperationalSystemIcon
            operationalSystem={device?.operationalSystem}
          />
        ),
        field: 'systemIcon',
        isExportHidden: true,
        chackraProps: { px: 0 },
      },
      {
        value: device?.name,
        field: 'user',
      },
      {
        value: device?.federalCode,
        field: 'federalCode',
      },
      {
        value: device?.phoneNumber,
        field: 'phone_number',
      },
      {
        value: device?.imei,
        field: 'imei',
      },
      {
        value: device?.model,
        field: 'model',
      },
      {
        isExportHidden: true,
        value: '',
        field: 'actions',
        transform: () => {
          return (
            <>
              <span
                title={intl.formatMessage({
                  id: 'devices.action.more_information',
                })}
                style={{ cursor: 'pointer', marginRight: '10px' }}
              >
                <InfoOutlineIcon
                  boxSize={4}
                  onClick={() =>
                    openModal(ModalTypeEnum.MORE_INFORMATION, device)
                  }
                />
              </span>

              <TableActions
                moreItems={
                  <ActionItems
                    device={device}
                    setSelectedDevice={setSelectedDevice}
                    modalOpen={openModal}
                  />
                }
              />
            </>
          );
        },
      },
      {
        isExportHidden: true,
        value: null,
        field: 'expander',
        transform: () => (
          <ListDrillDown device={device} transformValue={transformValue} />
        ),
      },
      {
        isExpandedField: true,
        value: device?.policy?.name,
        field: 'policy',
      },
      {
        isExpandedField: true,
        value: device?.policy?.managementMode
          ? intl.formatMessage({
              id: `${ManagementModeEnumToIntl[device?.policy?.managementMode]}`,
            })
          : '',
        field: 'managementMode',
      },
      {
        isExpandedField: true,
        value: device?.companionVersion,
        field: 'companionVersion',
      },
      {
        isExpandedField: true,
        value: device?.iccid,
        field: 'iccid',
      },
      {
        isExpandedField: true,
        value: device?.manufacturer,
        field: 'manufacturer',
      },
      {
        isExpandedField: true,
        value: handleFormatDateByUserLogged(device?.createdAt),
        field: 'registerDate',
      },
      {
        isExpandedField: true,
        value: device?.group?.name,
        field: 'group',
      },
      {
        isExpandedField: true,
        value:
          device?.state &&
          intl.formatMessage({ id: deviceStateToIntl[device?.state] }),
        field: 'state',
      },
      {
        isExpandedField: true,
        value: intl.formatMessage({
          id: `${operationalSystemToIntl[device?.operationalSystem]}`,
        }),
        field: 'operationalSystem',
      },
      {
        isExpandedField: true,
        value: handleFormatDateByUserLogged(device?.lastCommunication),
        field: 'lastCommunication',
      },
    ],
  }));

  const handleExportAllToExcel = () => {
    dispatch(
      generateExcel({
        type: Types.LIST,
        metadata: metadata,
        filters: allFilters,
      })
    );
  };

  const handleClearModalType = () => {
    setModalType(null);
  };

  const handleClickDevicesActions = () => {
    dispatch(verifyBatchDevicesActions());
  };

  const modalToRender = () => {
    if (modalType === ModalTypeEnum.PASSWORD_HISTORIC) {
      return (
        <Modal
          isOpen={modalType === ModalTypeEnum.PASSWORD_HISTORIC}
          onClose={handleClearModalType}
        >
          <LastPasswordsModal
            device={selectedDevice}
            onCancel={handleClearModalType}
            openNewPassword={() => {
              handleClearModalType();
              alertNewPassword(intl, dispatch, selectedDevice.id);
            }}
          />
        </Modal>
      );
    }

    if (modalType === ModalTypeEnum.DEVICE_POLICY) {
      return (
        <ModalPolicyDevice
          isOpen={modalType === ModalTypeEnum.DEVICE_POLICY}
          closeModal={handleClearModalType}
          selectedDevice={selectedDevice}
        />
      );
    }

    if (modalType === ModalTypeEnum.NEW_DEVICE_PASSWORD) {
      return (
        <ModalNewDevicePassword
          isOpen={modalType === ModalTypeEnum.NEW_DEVICE_PASSWORD}
          closeModal={handleClearModalType}
          deviceId={selectedDevice?.id}
        />
      );
    }

    if (modalType === ModalTypeEnum.MORE_INFORMATION) {
      return (
        <ModalMoreInformation
          isOpen={modalType === ModalTypeEnum.MORE_INFORMATION}
          closeModal={handleClearModalType}
          deviceId={selectedDevice?.id}
        />
      );
    }

    if (modalType === ModalTypeEnum.BATCH_DEVICES_ACTIONS) {
      return (
        <ModalBatchDevices
          isOpen={modalType === ModalTypeEnum.BATCH_DEVICES_ACTIONS}
          closeModal={handleClearModalType}
        />
      );
    }

    return null;
  };

  return (
    <>
      <PageTitle
        showManualIcon
        title={<FormattedMessage id="devices.title" />}
        description={<FormattedMessage id="devices.sub_title" />}
      />
      <PageShowToaster
        toasterKeys={[
          Types.ACTION_REMOVE,
          Types.ACTION_ENABLE,
          Types.ACTION_DISABLE,
          Types.ACTION_LOCK,
          Types.ACTION_REMOVE_SCREEN_BLOCK,
          Types.ACTION_REBOOT,
        ]}
        messageSuccess={<FormattedMessage id={handleGetSuccessMessage()} />}
      />
      <PageShowToaster
        toasterKeys={[
          Types.EDIT_POLICY,
          Types.ACTION_CHANGE_DEVICE_PASSWORD,
          Types.BATCH_DEVICES_ACTIONS,
        ]}
        messageSuccess={<FormattedMessage id={handleGetSuccessMessage()} />}
        disabledError
      />
      <PageShowToaster
        toasterKeys={[Types.SYNC_DEVICE, Types.VERIFY_BATCH_DEVICES_ACTIONS]}
        disabledSuccess
      />
      <Inventory />
      <FormContainer py={1}>
        <PageFilter>
          <FormControl textLabel={<FormattedMessage id="devices.groups" />}>
            <SelectAutocomplete
              options={groupsToFilter}
              value={filterGroups}
              onChange={handleFilterGroups}
              onInputChange={handleFilterGroupsChange}
              placeholder={<FormattedMessage id="global.groups" />}
            />
          </FormControl>
          <FormControl textLabel={<FormattedMessage id="devices.policy" />}>
            <SelectAutocomplete
              options={policiesToFilter}
              value={filterPolicy}
              onChange={handleFilterPolicies}
              onInputChange={handleFilterPoliciesChange}
              placeholder={<FormattedMessage id="global.select_placeholder" />}
            />
          </FormControl>
          <FormControl textLabel={<FormattedMessage id="devices.model" />}>
            <SelectAutocomplete
              options={models?.map(({ model }) => {
                return {
                  id: model,
                  model: model,
                };
              })}
              value={filterModel}
              getOptionLabel={(option) => option.model}
              onChange={handleFilterModel}
              onInputChange={handleFilterModelChange}
            />
          </FormControl>
          <FormControl
            textLabel={<FormattedMessage id="devices.manufacturer" />}
          >
            <SelectAutocomplete
              options={manufacturers?.map(({ manufacturer }) => {
                return {
                  id: manufacturer,
                  manufacturer: manufacturer,
                };
              })}
              value={filterManufacturer}
              getOptionLabel={(option) => option.manufacturer}
              onChange={handleFilterManufacturer}
              onInputChange={handleFilterManufacturerChange}
            />
          </FormControl>
        </PageFilter>

        <Flex
          cursor="pointer"
          gridGap={1}
          mt={4}
          alignItems="center"
          onClick={() => setHasAdvancedFilter(!hasAdvancedFilter)}
        >
          {hasAdvancedFilter ? (
            <ChevronDownIcon boxSize="5" color="gray.500" />
          ) : (
            <ChevronRightIcon boxSize="5" color="gray.500" />
          )}

          <Text m={0} fontSize="sm" fontWeight="semibold">
            {intl.formatMessage({ id: 'devices.advanced_filters' })}
          </Text>
        </Flex>

        {hasAdvancedFilter && (
          <Flex mt={4}>
            {portalSettings?.allowDeviceIOS && (
              <FormControl
                textLabel={<FormattedMessage id="devices.operational_system" />}
              >
                <SelectAutocomplete
                  options={systemToFilter}
                  getOptionLabel={(option) => option.name}
                  value={filterOperationalSystem}
                  onChange={handleOperationalSystem}
                  placeholder={
                    <FormattedMessage id="devices.operational_system" />
                  }
                />
              </FormControl>
            )}
            <FormControl
              textLabel={
                <FormattedMessage id="manage_policies.filter.management_mode" />
              }
            >
              <ManagementModeSelect
                name="managementMode"
                value={filterManagementMode}
                onChange={handleManagementModeFilter}
              />
            </FormControl>
            <FormControl
              textLabel={<FormattedMessage id="devices.filter.start_at" />}
            >
              <DatePicker
                maxDate={filterDeviceRegister?.endAt || DATE_OF_TODAY}
                hasValue={filterDeviceRegister?.startAt}
                selected={filterDeviceRegister?.startAt}
                onChange={(date) => {
                  handlePeriodFilterChange(date, 'startAt');
                }}
              />
            </FormControl>
            <FormControl
              textLabel={<FormattedMessage id="devices.filter.end_at" />}
            >
              <DatePicker
                minDate={filterDeviceRegister?.startAt}
                maxDate={DATE_OF_TODAY}
                hasValue={filterDeviceRegister?.endAt}
                selected={filterDeviceRegister?.endAt}
                onChange={(date) => {
                  handlePeriodFilterChange(date, 'endAt');
                }}
              />
            </FormControl>
          </Flex>
        )}
      </FormContainer>
      <Table
        headerColumns={headerColumns}
        rows={rows}
        handleSort={handleMetadata}
        metadata={metadata}
        keyProp={Types.LIST}
        pageActionsProps={{
          initialSearch: filterSearch,
          onSearch: setFilterSearch,
          handleExportAllToExcel,
          actionButtons: [
            {
              id: 'batchDevicesActions',
              label: intl.formatMessage({
                id: 'devices.batch_devices_actions.button',
              }),
              isLoading: isVerifyDevicesActionsLoading,
              onClick: handleClickDevicesActions,
            },
          ],
        }}
        nameTable={`${intl.formatMessage({ id: 'devices.title' })}`}
      />
      {modalToRender()}
    </>
  );
};

export default ManageDevices;
