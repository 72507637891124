import { i18n } from 'portal-lib';

const i18nConfig = {
  locale: 'pt-BR',
  messages: {
    ...i18n?.['pt-br'],
    // errors
    'errors.generic_error': 'Erro ao fazer a solicitação',
    // success
    'success.generic_success': 'Solicitação realizada com sucesso',
    // global buttons and text
    'global.add': 'Adicionar',
    'global.cancel': 'Cancelar',
    'global.close': 'Fechar',
    'global.confirm': 'Confirmar',
    'global.try_again': 'Tentar novamente',
    'global.remove': 'Remover',
    'global.update': 'Atualizar',
    'global.copy': 'Copiar',
    'global.excel': 'Excel',
    'global.history': 'Histórico',
    'global.send': 'Enviar',
    'global.remove_record': 'Remover registro',
    'global.register': 'Cadastrar',
    'global.register_new': 'Cadastrar novo',
    'global.register_rule': 'Cadastrar regra',
    'global.rule': 'Regra',
    'global.load_more': 'Carregar mais',
    'global.showing_x_of': 'Mostrando {rowInit} - {rowEnd} de {totalRowa}',
    'global.filter': 'Filtrar',
    'global.radiogroup_all': 'Todos',
    'global.radiogroup_yes': 'Sim',
    'global.radiogroup_no': 'Não',
    'global.general': 'Geral',
    'global.groups': 'Grupos',
    'global.gps': 'GPS',
    'global.subgroups': 'Subgrupos',
    'global.users': 'Usuários',
    'global.select_group': 'Selecione um grupo',
    'global.select_subgroup': 'Selecione um subgrupo',
    'global.select_user': 'Selecione um usuario',
    'global.edit_config': 'Editar configuração',
    'global.remove_config': 'Remover configuração',
    'global.message': 'Mensagem',
    'global.search': 'Buscar',
    'global.back': 'Voltar',
    'global.no_data': 'Sem dados',
    // global form and table text
    'global.date': 'Data',
    'global.start_date': 'Data inicial',
    'global.last_date': 'Data final',
    'global.company_name': 'Empresa',
    'global.identification': 'Número de Identificação',
    'global.password': 'Senha',
    'global.email': 'E-mail',
    'global.name': 'Nome',
    'global.contact': 'Contato',
    'global.phone': 'Telefone',
    'global.zip_code': 'CEP',
    'global.address_line': 'Endereço',
    'global.city': 'Cidade',
    'global.state': 'Estado',
    'global.user': 'Usuário',
    'global.adress': 'Endereço',
    'global.group': 'Grupo',
    'global.new_group_name': 'Nome do novo grupo',
    'global.consumption': 'Consumo',
    'global.roaming': 'Roaming',
    'global.provider': 'Operadora',
    'global.company': 'Empresa',
    'global.data': 'Dados',
    'global.sms': 'SMS',
    'global.source_number': 'Número de origem',
    'global.target_number': 'Número de destino',
    'global.plan': 'Plano',
    'global.last_update': 'Última atualização',
    'global.Enrolled_device': 'Dispositivo instalado',
    'global.group_name': 'Nome do grupo',
    'global.group_users_qtd': 'Quantidade de usuários no grupo',
    'global.subgroup_name': 'Nome do subgrupo',
    'global.subgroup_users_qtd': 'Quantidade de usuários no subgrupo',
    'global.recipient': 'Destinatário',
    'global.action.edit': 'Editar {entity}',
    'global.action.remove': 'Remover {entity}',
    'global.action.view': 'Visualizar {entity}',
    'global.newUsers': 'Novos usuários',
    'global.totalUsers': 'Total de usuários',
    'global.conected': 'Conectado',
    'global.disconnected': 'Desconectado',

    'global.all': 'Todos',
    'global.yes': 'Sim',
    'global.no': 'Não',
    'global.logoff': 'Sair',
    // alerts
    'alert.delete.text': 'Você tem certeza que<br/>deseja remover este registro?',
    'alert.button.remove': 'Remover',
    'alert.button.cancel': 'Cancelar',
    // login
    'login.welcome_1': 'Bem-vindo.',
    'login.welcome_2': 'Faça seu login.',
    'login.email': 'E-mail',
    'login.email_placeholder': 'Informe seu e-mail',
    'login.password': 'Senha',
    'login.password_placeholder': 'Digite sua senha',
    'login.forgot_password': 'Esqueceu a senha?',
    'login.btn_submit': 'Iniciar Sessão',
    'login.keep_connected': 'Manter conectado',
    'login.terms_and_conditions': 'Termos e Condições',
    'login.privacy_warning': 'Aviso de Privacidade',
    'login.modal.invalid': 'E-mail ou senha inválidos!',
    'login.modal.verify_login': 'Verifique o e-mail ou a senha inserida.',
    'login.modal.user_last_try': 'Você tem somente mais uma tentativa, antes que o seu acesso seja bloqueado. Revise a senha antes de realizar outra tentativa.',
    'login.modal.title.invalid_user_password': 'E-mail ou senha inválidos!',
    'login.modal.invalid_user_password': 'Verifique o e-mail ou a senha inserida.',
    'login.modal.unblock_user': 'Enviar instruções de desbloqueio',
    // Recovery password
    'password_recovery.welcome_1': 'Recuperar',
    'password_recovery.welcome_2': 'senha',
    'password_recovery.continue': 'Continuar',
    'password_recovery.modal.check_email': 'Verifique seu e-mail!',
    'password_recovery.modal.email_sent': 'Enviamos as instruções para recuperação de senha para seu e-mail',
    'password_recovery.modal.support_text': 'Se o e-mail acima não estiver correto, entre em contato com o suporte.',
    'password_recovery.modal.support_link': 'Solicitar ajuda ao suporte técnico',
    // Change password
    'password_chance.welcome_1': 'Atualizar',
    'password_chance.welcome_2': 'senha',
    'password_chance.continue': 'Continuar',
    'password_chance.password': 'Senha',
    'password_chance.error': 'Token inválido ou expirado',
    'password_chance.success': 'A sua senha de acesso ao portal foi alterada!',

    // Unblock user
    'unblock.welcome_1': 'Enviar instruções',
    'unblock.welcome_2': 'de desbloqueio',

    // company info
    'company_info.title': 'Informações da Empresa',
    'company_info.description': 'Nesta tela é possível atualizar as informações do cadastro da sua empresa.',
    'company_info.toaster.edit_success': 'As informações da empresa foram atualizadas com sucesso!',
    'company_info.field.dataProtectionOfficerName': 'Nome',
    'company_info.field.dataProtectionOfficerEmail': 'E-mail',
    'company_info.field.dataProtectionOfficerPhone': 'Telefone',
    'company_info.field.euRepresentativeName': 'Nome',
    'company_info.field.euRepresentativeEmail': 'E-mail',
    'company_info.field.euRepresentativePhone': 'Telefone',
    'company_info.dataProtectionOfficerName': 'Proteção de Dados - Nome',
    'company_info.dataProtectionOfficerEmail': 'Proteção de Dados - E-mail',
    'company_info.dataProtectionOfficerPhone': 'Proteção de Dados - Telefone',
    'company_info.euRepresentativeName': 'Representante na UE - Nome',
    'company_info.euRepresentativeEmail': 'Representante na UE - E-mail',
    'company_info.euRepresentativePhone': 'Representante na UE - Telefone',
    'company_info.name': 'Empresa',
    'company_info.federalCode': 'Número de Identificação',
    'company_info.phoneNumber': 'Telefone',
    'company_info.zipCode': 'CEP',
    'company_info.address': 'Endereço',
    'company_info.email': 'E-mail',
    'company_info.contact': 'Contato',
    'company_info.city': 'Cidade',
    'company_info.state': 'Estado',
    'company_info.delete_company': 'Excluir Empresa',
    'company_info.delete_alert': 'Ao excluir a empresa todos os dispositivos registrados serão removidos através do comando "Wipe" e todas as políticas e informações serão removidas!',
    // company consumption
    'company_consumption.title': 'Consumos',
    'company_consumption.description': 'Nesta tela é possível gerenciar os consumos de dados e SMS dos dispositivos da empresa.',
    'company_consumption.tabs_data': 'Dados',
    'company_consumption.tabs_sms': 'SMS',
    'company_consumption.company': 'Empresa',
    'company_consumption.package_label': 'Nome do pacote do aplicativo',
    'company_consumption.date_error': 'Insira uma data coerente.',
    'company_consumption.start_date': 'Data inicial',
    'company_consumption.end_date': 'Data final',
    'company_consumption.filter.group': 'grupo',
    'company_consumption.filter.subgroup': 'subgrupo',
    'company_consumption.filter.device_user': 'usuário',
    // company license
    'company_license.title': 'Licenças Contratadas',
    'company_license.description': 'Nesta tela é possível visualizar as informações de todas as licenças contratadas.',
    // admin user
    'manage_admin.title': 'Gerenciar Usuários Administradores',
    'manage_admin.description': 'Nesta tela é possível gerenciar o cadastro dos usuários administradores do Portal.',
    'manage_admin.filter.title': 'Privilégio',
    'manage_admin.filter.company': 'Responsáveis de Empresa',
    'manage_admin.filter.group_manager': 'Responsáveis de Grupo',
    'manage_admin.filter.subgroup_manager': 'Responsáveis de Subgrupo',
    'manage_admin.filter.title_group_manager': 'Grupo administrado',
    'manage_admin.filter.title_subgroup_manager': 'Subgrupo administrado',
    'manage_admin.filter.title_company': 'Empresa administrada',
    'manage_admin.toaster.success': 'Usuário criado com sucesso, um e-mail foi enviado para o usuário com as instruções de acesso.',
    // edit admin user
    'edit_admin.user': 'Editar usuário administrador',
    'edit_admin.group': 'Administração de grupos',
    'edit_admin.subgroup': 'Administração de subgrupos',
    'edit_admin.group_description': 'Seleciona os grupos adicionais que poderá administrar',
    'edit_admin.subgroup_description': 'Seleciona os subgrupos adicionais que poderá administrar',
    'edit_admin.register': 'Cadastrar Usuário Administrador',
    'edit_admin.description_new': 'Nesta tela é possível gerenciar as informações de cadastro, de privilégio, de grupos e das permissões deste usuário. Também é possível alterar a senha de acesso ao portal que o usuário administra.',
    'edit_admin.privilege_company': 'Responsável de Empresa',
    'edit_admin.privilege_group': 'Responsável de Grupo',
    'edit_admin.privilege_subgroup': 'Responsável de Subgrupo',
    'edit_admin.name': 'Nome',
    'edit_admin.federalCode': 'Núm. de Identificação',
    'edit_admin.email': 'E-mail',
    'edit_admin.company': 'Empresa',
    'edit_admin.timezone': 'Fuso Horário',
    'edit_admin.language': 'Idioma',
    'edit_admin.password': 'Nova senha',
    'edit_admin.permissions.subtitle': 'Permissões',
    'edit_admin.groupIds': 'Administração de grupos',
    // manage groups
    'manage_groups.entity': 'Grupo',
    'manage_groups.title': 'Gerenciar Grupos',
    'manage_groups.description': 'Nesta tela é possível gerenciar o cadastro dos grupos de dispositivos.',
    'manage_groups.actions_edit': 'Editar grupo',
    'manage_groups.actions_remove': 'Remover grupo',
    'manage_groups.modal_title': 'Você deseja mesmo remover este grupo?',
    'manage_groups.modal_subtitle': 'Esta ação não pode ser desfeita.',
    'manage_groups.success.create': 'Grupo criado com sucesso!',
    'manage_groups.success.update': 'Grupo alterado com sucesso!',
    'manage_groups.success.delete': 'Grupo removido com sucesso!',
    // edit group
    'edit_group.title_new': 'Cadastrar Novo Grupo',
    'edit_group.title_edit': 'Editar grupo',
    'edit_group.description_new': 'Nesta tela é possível criar um novo grupo de dispositivos.',
    'edit_group.description_edit': 'Nesta tela é possível gerenciar as informações e o vínculo dos dispositivos deste grupo.',
    'edit_group.name': 'Nome do grupo',
    'edit_group.attach_user': 'Vincular usuário',
    'edit_group.attach': 'Vincular',
    'edit_group.new_attached_users': 'Novos usuários para vincular',
    'edit_group.attached_users': 'Usuários vinculados',
    'edit_group.no_attached_user': 'Nenhum usuário vinculado a este grupo',
    'edit_group.attach_user_label': 'Selecione o usuário',
    // manage subgroups
    'manage_subgroups.title': 'Gerenciar subgrupos',
    'manage_subgroups.entity': 'Subgrupo',
    'manage_subgroups.actions_edit': 'Editar subgrupo',
    'manage_subgroups.actions_remove': 'Remover subgrupo',
    'manage_subgroups.modal_title': 'Você deseja mesmo remover este subgrupo?',
    'manage_subgroups.modal_subtitle': 'Esta ação não pode ser desfeita.',
    'manage_subgroups.success': 'Subgrupo alterado com sucesso!',
    // edit subgroup
    'edit_subgroup.title': 'Editar subgrupo',
    'menu.app_action': 'Ações Android',
    'edit_subgroup.attach_user': 'Vincular usuário',
    'edit_subgroup.attach': 'Vincular',
    'edit_subgroup.attached_users': 'Usuários vinculados ao subgrupo',
    'edit_subgroup.no_attached_user': 'Nenhum usuário vinculado a este subgrupo',
    'edit_subgroup.attach_user_label': 'Selecione o usuário',
    // register group
    'register_subgroup.title': 'Cadastrar novo subgrupo',
    'menu.users.admin_users': 'Administradores',
    'register_subgroup.success': 'Subgrupo cadastrado com sucesso!',
    // Consumption Profile
    'consumption_profile.title': 'Perfil de Consumo',
    'consumption_profile.description': 'Nesta tela é possível gerenciar o perfil de consumo dos dispositivos.',
    'consumption_profile.success': 'Perfil de consumo atualizado com sucesso!',
    'menu.help': 'Ajuda',
    'consumption_profile.radio_button_defined': 'Definido no nível acima',
    'consumption_profile.radio_button_ilimited': 'Ilimitado',
    'consumption_profile.radio_button_assigned': 'Consumo atribuído',
    'consumption_profile.general.data': 'Dados',
    'consumption_profile.general.quantity': 'Quantidade',
    'consumption_profile.general.bytes': 'Bytes',
    'consumption_profile.group.register_success': 'Regra de perfil de consumo cadastrada com sucesso!',
    'menu.device.application': 'Informações dos Aplicativos',
    'consumption_profile.group.modal_title': 'Você deseja mesmo remover esta configuração?',
    'consumption_profile.group.title': '* A configuração dos Perfis de Consumo não refletem a natureza do serviço contratado com a operadora. As informações registradas nesse formulário refletem apenas as políticas de uso da sua organização.',
    'menu.app_action.application_control.manage': 'Aplicativos',
    'consumption_profile.group.subtitle': 'Esta ação não pode ser desfeita.',
    'consumption_profile.group_register.title': 'Cadastrar regra de perfil de consumo',
    'consumption_profile.group_edit.title': 'Editar regra',
    'consumption_profile.group_remove.title': 'Remover regra',
    'consumption_profile.level_up': 'Definido no nível acima',
    'consumption_profile.ilimited': 'Ilimitado',
    'consumption_profile.data': 'Dados',
    'consumption_profile.data_roaming': 'Dados Roaming',
    'consumption_profile.sms': 'SMS',
    'consumption_profile.sms_roaming': 'SMS Roaming',

    // dashboard
    'dashboard.good_morning': 'Bom dia',
    'dashboard.good_afternoon': 'Boa tarde',
    'dashboard.good_evening': 'Boa noite',
    'dashboard.roaming': 'Roaming internacional',
    'dashboard.description': 'Confira a visão geral da aplicação.',
    'dashboard.usage_time_by_application.title': 'Tempo de uso por aplicativos',
    'dashboard.usage_time_by_application.empty_chart': 'Nenhum dado encontrado',
    'dashboard.most_visited_websites.title': 'Sites mais visitados',
    'dashboard.most_visited_websites.empty_chart': 'Nenhuma visita cadastrada.',
    'dashboard.devices_inventory': 'Inventário de dispositivos',
    'dashboard.devices_location': 'Localização dos Dispositivos',
    'dashboard.devices_inventory.empty_list': 'Nenhum dispositivo encontrado',
    'dashboard.users_total_heading': 'Total de usuários',
    'dashboard.uninstalled_applications': 'Aplicativos Desinstalados',
    'dashboard.uninstall_attempts': 'Tentativas de Desinstalação',
    'dashboard.not_activated_licenses': 'Licenças não instaladas no portal',
    'dashboard.device_card.title': 'Dispositivos',
    'dashboard.device_card.status.active': 'Ativos',
    'dashboard.device_card.status.total': 'Total',
    'dashboard.device_card.status.without_communication': 'Sem comunicação',
    'dashboard.device_card.status.no_compilation': 'Inconforme',
    'dashboard.device_card.status.mode_owner': 'Em Device Owner',
    'dashboard.device_card.status.mode_kiosk': 'Em Modo Quiosque',
    'dashboard.device_card.status.outdated_app': 'APP Desatualizado',
    'menu.settings.audit': 'Auditoria',
    'dashboard.used': 'Usado',
    'dashboard.data_consumption_chart.app': 'Consumo de dados por aplicativos',
    'dashboard.data_consumption_chart.user': 'Consumo de dados por usuário',
    'dashboard.total': 'Total',
    'dashboard.unlimited': 'Ilimitado',
    'dashboard.data_consumption': 'Consumo de dados',
    'dashboard.sms_consumption': 'Consumo de SMS',
    'dashboard.data_consumption.label.link': 'Ver lista completa',
    'menu.app_action.application_control': 'Bloquear / Desbloquear',

    // Android Block Application
    'block_application.title': 'Bloquear / Desbloquear Aplicativos',
    'block_application.title_text': 'Consulte Regras já criadas para bloquear ou liberar aplicativos. As ações geradas neste formulário se rederem ao acesso a internet. Os aplicativos poderão ser abertos normalmente. A exceção ocorre em modo Device Owner onde os ícones dos aplicativos ficam ocultos. ',
    'block_application.general': 'Geral',
    'block_application.groups': 'Grupos',
    'block_application.subgroups': 'Subgrupos',
    'block_application.users': 'Usuários',
    // Android Block Application
    'block_application.general.edit.title': 'Editar regra do aplicativo',
    'block_application.general.register.title': 'Cadastrar regra do aplicativo',
    'block_application.general.register.title_text': 'Nesta tela é possível configurar uma regra de bloqueio ou desbloqueio de sites por URL ou palavra-chave nos dispositivos.',
    'block_application.success': 'Bloquear / Desbloquear Aplicaciones',
    'block_application.apply_to': 'Aplicar regra em',
    'block_application.apply_rule': 'Aplicar regras',
    'block_application.apply': 'Aplicar',
    'block_application.applied': 'Aplicado',
    'block_application.not_applied': 'Não aplicado',
    'block_application.lock': 'Bloqueado',
    'block_application.unlock': 'Desbloqueado',
    'block_application.default_label': 'Padrão',
    'block_application.geofence_label': 'Geofence',
    'block_application.geofence.not_found': 'Nenhuma geofence adicionada a esta regra',
    'block_application.time_label': 'Horários',
    'block_application.add_rule': 'Cadastrar regra',
    'block_application.edit_config': 'Editar configuração',
    'block_application.modal.apply_config': 'Você deseja mesmo aplicar todas as regras de aplicativos?',
    'menu.app_action.geofences.register': 'Cadastrar nova geofence',
    'block_application.remove_config': 'Remover configuração',
    'block_application.modal.remove_config': 'Você deseja mesmo remover esta regra de aplicativos?',
    'menu.groups_and_subgroups.groups.register': 'Cadastrar Novo Grupo',
    'block_application.alert.irreversible': 'Esta ação não pode ser desfeita.',
    'block_application.url': 'URL/Palavra',
    'menu.groups_and_subgroups.subgroups.register': 'Cadastrar novo subgrupo',
    'block_application.config_rule': 'Configurar regra',
    'menu.users.admin_users.register': 'Cadastrar Novo Usuário Administrador',
    'block_application.select_action': 'Selecione a ação',
    'block_application.modal.confirm_rule': 'Regra cadastrada com sucesso!',
    'block_application.modal.new_rule_question': 'Deseja cadastrar uma nova regra para <b>mesma empresa</b>?',
    'block_application.modal.new_rule': 'Criar nova regra',
    'block_application.geofence.geofence': 'Geofence',
    'block_application.geofence.select': 'Selecione uma geofence',
    'block_application.schedule.time': 'Horários',
    'block_application.schedule.select_time': 'Selecione os horários que deseja efetuar a ação.',
    'block_application.schedule.monday': 'Seg',
    'block_application.schedule.tuesday': 'Ter',
    'block_application.schedule.wednesday': 'Qua',
    'block_application.schedule.thursday': 'Qui',
    'block_application.schedule.friday': 'Sex',
    'block_application.schedule.saturday': 'Sab',
    'block_application.schedule.sunday': 'Dom',
    'block_application.packageName': 'Nome do pacote',
    'block_application.application.placeholder': 'Selecione o aplicativo',
    // Sites
    'sites.block.radio_button.defined': 'Definido no nível acima',
    'sites.block.radio_button.disabled': 'Inativo',
    'sites.block.radio_button.active': 'Ativo',
    'sites.kiosk.checkbox.block': 'Bloqueado via regra',
    'sites.kiosk.checkbox.tipe_rule': 'Revisar regra de',
    'menu.documents': 'Compartilhar Documento',
    'menu.app_action.settings': 'Configurações',
    'menu.settings': 'Configurações',
    'menu.settings.general_config': 'Configurações Gerais',
    'sites.block.general.text': 'Nesta tela é possível configurar uma regra de perfil de bloqueio ou desbloqueio de sites por categorias nos dispositivos',
    'sites.block.general.success.register': 'Regra criada com sucesso!',
    'sites.block.general.success.edit': 'Regra atualizada com sucesso!',
    'sites.block.general.success.delete': 'Regra excluída com sucesso!',
    'sites.kiosk.title': 'Habilitar modo quiosque',
    'sites.kiosk.description': 'Transforma a tela inicial do dispositivo em um ambiente de trabalho controlado, exibindo uma lista de aplicativos previamente definidos.',
    'sites.kiosk.function': 'Funcionamento:',
    'sites.kiosk.general.permited_actions': 'Ações permitidas no modo quiosque',
    'sites.kiosk.general.success': 'Configurações do modo quiosque atualizadas com sucesso',
    'sites.kiosk.kiosk_state': 'Estado do modo Quiosque',
    'sites_category.radio_button.defined': 'Definido no nível acima',
    'sites_category.radio_button.active': 'Ativo',
    'sites_category.general.form_label': 'Regra aplicada à Empresa',
    'menu.company.consumption': 'Consumos',
    'sites_category.group.form_label': 'Regra aplicada ao grupo',
    'sites_category.subgroup.form_label': 'Regra aplicada ao subgrupo',
    'sites_category.user.form_label': 'Regra aplicada ao usuário',
    'sites_category.general.block_content': 'Bloqueio de conteúdos (ative os conteúdos que deseja bloquear)',
    'sites_category.general.notice': '* Ver linhas que estão no plano básico ou suspensas',

    // Application Manage
    'application_manage.title': 'Aplicativos',
    'application_manage.sub_title': 'Nesta tela é possível visualizar o relatório dos aplicativos dos dispositivos.',
    'application_manage.group': 'Grupos',
    'application_manage.app': 'Aplicativos',
    'application_manage.sub_group': 'Subgrupo',
    'menu.dashboard': 'Dashboard',
    'application_manage.users': 'Usuários',
    'application_manage.table_action.storage': 'Histórico de consumo',
    'application_manage.table_action.application': 'Usuários com aplicativos',
    'application_manage.toaster_success': 'Aplicação registrada com sucesso!',
    'application_manage.toaster_sent_success': 'Aplicação enviada com sucesso!',
    'application_manage.modal.send_title': 'Enviar mensagens',
    'application_manage.modal.send_text': 'Para instalar ou atualizar aplicativos pode selecionar por Empresa (será enviada para todas as equipes inscritas), por Grupo (será enviada apenas para membros do grupo) ou por Usuário (você pode escolher mais de 1 usuário para envio).',
    'application_manage.modal.destroy.title': 'Você deseja mesmo remover esta aplicação?',
    'application_manage.modal.destroy.text': 'Esta ação não pode ser desfeita.',

    // Application Register
    'application_register.title': 'Cadastrar e Remover Aplicativo para Instalar',
    'application_register.title_text': 'Nesta tela é possível cadastrar um aplicativo para ser instalado nos dispositivos remotamente.',
    'application_register.url': 'URL do Arquivo:',
    'application_register.packageName': 'Nome do Pacote',
    'application_register.action.history': 'Histórico',
    'application_register.title_tag': 'Habilitado apenas em modo device owner',
    'application_register.message_success': 'Aplicativo criado com sucesso!',

    // Application History
    'application_history.title': 'Histórico do aplicativo',
    'application_history.title_text': 'Nesta tela é possível visualizar o histórico de todos os envios realizados para esse app.',
    'application_history.title_package': 'Nome do pacote:',
    'application_history.sent_status': 'Status do envio',
    'application_history.empresa': 'Status do envio',
    'application_history.action.history': 'Status do envio',
    // Application history Status
    'application_status.title': 'Detalhes do envio da aplicação',
    'application_status.sent': 'Enviada',
    'application_status.sending': 'Em processo de envio',
    'application_status.url': 'URL',
    'application_status.recipients': 'Destinatários',
    // Application Send
    'application_send.title': 'Enviar os aplicativos',
    'application_send.sub_title': 'Aplicativos para Instalar/Desinstalar.',
    'application_send.send_application': 'Enviar Aplicativos',
    'application_send.message_success': 'Aplicativo enviado com sucesso!',
    'application_send.column.action': 'Ação',
    'application_send.table_title': 'Aplicativos selecionados',
    'application_send.action.install': 'Instalar',
    'application_send.action.uninstall': 'Desinstalar',

    // Application device users
    'application_device_users.title': 'Usuários com Aplicativo',
    'application_device_users.description': 'Nesta tela é possível visualizar todos os dispositivos que possuem o aplicativo instalado.',
    'application_device_users.filter.group': 'grupo',
    'application_device_users.filter.subgroup': 'subgrupo',
    'application_device_users.filter.device_user': 'usuário',
    // deviceuser
    'manage_deviceuser.entity': 'Usuário de Dispositivo',
    'manage_deviceuser.title': 'Gerenciar Usuários de Dispositivos',
    'manage_deviceuser.description': 'Nesta tela é possível gerenciar o cadastro dos usuários de dispositivos.',
    'menu.devices': 'Dispositivos',
    'menu.device.devices': 'Lista de Dispositivos',
    'edit_deviceuser.deviceuser': 'Editar usuario',
    'edit_deviceuser.title': 'Editar usuário de Dispositivo',
    'edit_deviceuser.description': 'Nesta tela é possível alterar as informações do usuário do dispositivo.',
    'edit_deviceuser.name': 'Nome do usuário',
    'edit_deviceuser.federalCode': 'N. de Identificação (Opcional)',
    'edit_deviceuser.groupId': 'Grupos',
    'edit_deviceuser.subgroup': 'Subgrupos',
    'edit_deviceuser.success': 'O usuário foi atualizado com sucesso!',
    'menu.help.documents': 'Documentos e Materiais de Apoio',
    // GEOLOCATION
    'geolocation.title': 'Geolocalização',
    'geolocation.title_text': 'Nesta tela é possível visualizar a geolocalização dos dispositivos.',
    'geolocation.filter': 'Filtrar',
    'geolocation.user': 'Usuário',
    'geolocation.findBy': 'Localizar',
    'geolocation.date': 'Data',
    'geolocation.timezone': 'Fuso horário',
    'geolocation.precision': 'Precisão',
    'geolocation.search': 'Buscar',
    'geolocation.label': 'Legenda',
    'geolocation.initial_position': 'Posição inicial de leitura do GPS no dia selecionado',
    'geolocation.current_position': 'Posição atual ou última registrada no dia',
    'geolocation.position_history': 'Lugar onde o usuário passou',
    'geolocation.moving': 'Em movimento',
    'geolocation.stopped': 'Parado',
    'geolocation.currentUser': 'Usuário atual',
    'geolocation.device': 'Dispositivo',
    'geolocation.byData': 'Por data',
    'geolocation.now': 'Agora',
    'geolocation.all': 'Todos',
    'geolocation.loading': 'Solicitando a localização atual do dispositivo. Por favor aguarde.',
    'geolocation.warning_exit': 'O sistema está aguardando a localização atual do dispositivo solicitada! Ao sair da tela você não receberá a localização solicitada.',
    'geolocalization.realtime.not_found': 'Nada foi encontrado!',
    'geolocalization.realtime.time_exceeded': 'Não foi possível obter a localização atual do dispositivo! Confirme que o dispositivo está ligado, o GPS está habilitado e está conectado a uma rede de internet para enviar a localização.',
    'menu.company': 'Empresas',
    // LOCATION HISTORY
    'location_history.title': 'Histórico de Localização',
    'location_history.subtitle': 'Nesta tela é possível visualizar o histórico das localizações registradas pelos dispositivos.',
    'location_history.month': 'Mês',
    'location_history.day': 'Dia',
    'location_history.initial_time': 'Hora início',
    'location_history.final_time': 'Hora fim',
    'location_history.precision': 'Precisão GPS (metros)',
    'location_history.user': 'Usuário',
    'location_history.select': 'Selecione',
    'location_history.select_option.all': 'Todos',
    // MESSAGE
    'message.title': 'Mensagens',
    'message.description': 'Nesta tela é possível gerenciar e enviar mensagens e comandos via push para os dispositivos.',
    'message.title_text': 'Nesta tela é possível gerenciar e enviar mensagens e comandos via push para os dispositivos.',
    'message.filter': 'Filtrar',
    'message.start_date': 'Data inicial',
    'message.end_date': 'Data final',
    'message.new': 'Nova mensagem',
    'message.toaster_success': 'Mensagem enviada com sucesso!',
    'message.entity': 'Mensagem',
    'message.filter_title': 'Enviar mensagens',
    // MESSAGE DETAILS
    'message_detail.title': 'Detalhes da mensagem',
    'message_detail.title_text': 'Nesta tela é possível visualizar os detalhes da mensagem.',
    'message_detail.subtitle': 'Destinatários',
    'message_detail.status.error_sending_message': 'Erro ao enviar mensagem',
    'message_detail.status.in_the_send_queue': 'Na fila de envio',
    'message_detail.status.sent': 'Enviado',
    'message_detail.status.received': 'Recebido',
    'message_detail.status.read': 'Lido',
    'message_detail.status.installed': 'Instalado',
    'message_detail.status.not_installed': 'Não Instalado',
    'message_detail.status.executed': 'Executado',
    'message_detail.status.not_executed': 'Não Executado',
    'message_detail.status.uninstalled': 'Desinstalado',
    'message_detail.status.not_uninstalled': 'Não Desinstalado',

    // MESSAGE TYPES
    'message_type.status.install_apk': 'Instalar / Atualizar APK',

    // MESSAGE REGISTER
    'message_register.title': 'Nova Mensagem',
    'message_register.title_text': 'Nesta tela é possível enviar mensagens para os dispositivos.',

    // REPOSTS
    // - GPS
    'reportsGps.title': 'GPS',
    'reportsGps.description': 'Nesta tela é possível gerar o relatório do status do GPS dos dispositivos',
    //DEVICE LOCATION
    'device_location.title': 'Localização de Dispositivos',
    'device_location.sub_title': 'Nesta tela é possível gerar um relatório da última localização registrada pelos dispositivos',
    'device_location.start_date': 'Data inicial',
    'menu.app_action.geofences': 'Geofences',
    'device_location.end_date': 'Data final',
    'menu.location.geolocation': 'Geolocalização',
    'device_location.toaster_success': 'Mensagem enviada com sucesso!',
    // DEVICES
    'devices.title': 'Lista de Dispositivos',
    'devices.sub_title': 'Nesta tela é possível gerenciar e executar ações nos dispositivos.',
    'devices.manufacturer': 'Fabricante',
    'devices.model': 'Modelo',
    'devices.phone': 'Telefone',
    'devices.inventory.title': 'Distribuição de dispositivos',
    'menu.app_action.geofences.manage': 'Gerenciar geofences',
    'devices.action.disconnected': 'Reestabeleça a comunicação com este dispositivo para habilitar estas ações.',
    'menu.groups_and_subgroups.groups.manage': 'Gerenciar Grupos',
    'devices.action.battery': 'Histórico de bateria',
    'devices.action.storage': 'Histórico de armazenamento',
    'menu.groups_and_subgroups.subgroups.manage': 'Gerenciar subgrupos',
    'devices.action.remove': 'Remover dispositivo',
    'devices.action.change': 'Troca de equipamento',
    'devices.action.disable': 'Desativar Dispositivo',
    'menu.users.admin_users.manage': 'Gerenciar Usuários Administradores',
    'devices.action.activate': 'Ativar Dispositivo',
    'devices.action.non_compliance': 'Relatório de Não Conformidade',
    'menu.users.device_users.manage': 'Gerenciar Usuários de Dispositivo',
    'devices.action.newpassword': 'Gerar nova senha do dispositivo',
    'devices.action.passwordHistoric': 'Últimas senhas do dispositivo',
    'devices.alert.irreversible': 'Esta ação não pode ser desfeita.',
    'devices.alert.cancel': 'Cancelar',
    'devices.alert.remove.button': 'Remover',
    'devices.alert.remove.text': 'Você deseja mesmo remover este dispositivo?',
    'devices.alert.disable.button': 'Desativar',
    'devices.alert.disable.text': 'Você deseja mesmo desativar este dispositivo?',
    'devices.alert.activate.button': 'Ativar',
    'devices.alert.activate.text': 'Você deseja mesmo ativar este dispositivo?',
    'devices.alert.newpassword.button': 'Gerar senha',
    'devices.alert.newpassword.text': 'Você deseja mesmo gerar uma nova senha do dispositivo?',
    'devices.passwordHistoric.title': 'Últimas senhas do dispositivo',
    'devices.passwordHistoric.newpassword': 'Gerar nova senha do dispositivo',
    'devices.block.title': 'Bloquear / Desbloquear Aplicativos',
    'devices.block.subtitle': 'Nesta tela é possível gerenciar as regras de bloqueio e desbloqueio dos aplicativos dos dispositivos.',
    //  BATTERY
    'battery.title': 'Histórico de Bateria',
    'battery.sub_title': 'Nesta tela é possível visualizar o gráfico de evolução da bateria em um determinado dia.',
    'battery.label.filter': 'Data',
    'menu.groups_and_subgroups': 'Grupos',
    // STORAGE
    'menu.groups_and_subgroups.groups': 'Grupos',
    'storage.title': 'Histórico de Armazenamento',
    'storage.sub_title': 'Nesta tela é possível visualizar o gráfico de evolução do armazenamento interno em um determinado dia.',
    'storage.label.filter': 'Data',
    // QR CODE to Device Owner
    'qrcode.title': 'Gerar QR code para Device Owner',
    'menu.app_action.settings.kioskMode': 'Habilitar modo Quiosque',
    'qrcode.description': 'Nesta tela é possível gerar QR Code para realizar a instalação do modo Device Owner nos dispositivos.',
    'qrcode.url': 'Endereço (URL) para download do app',
    'qrcode.packageName': 'Nome do pacote do app',
    'qrcode.language': 'Idioma',
    'qrcode.timezone': 'Fuso Horário',
    'qrcode.ssid': 'Nome da rede Wi-Fi (SSID) que irá conectar',
    'qrcode.ssidPassword': 'Senha da rede Wi-Fi que irá conectar',
    'qrcode.enableAllApps': 'Habilitar todos os aplicativos do sistema',
    'qrcode.button': 'Gerar',
    'qrcode.wifi_network_name_label': 'Definir o nome da rede Wi-Fi (SSID)',
    'qrcode.wifi_network_password_label': 'Defina sua senha Wi-Fi',
    'menu.location.location_history': 'Histórico de Localização',
    'qrcode.failure_generate': 'Sem dados',
    'qrcode.show.title': 'QR Code para o modo Device Owner',
    'qrcode.show.description': 'Esta tela exibe o QR Code para realizar a instalação do modo Device Owner. Após realizar a restauração de fábrica dos seus dispositivos, escaneie este código para iniciar o processo de instalação.',
    'qrcode.show.url': 'Endereço (URL) para download do app',
    'qrcode.show.packageName': 'Nome do pacote do app',
    'qrcode.show.language': 'Idioma',
    'qrcode.show.timezone': 'Fuso horário',
    'qrcode.show.ssid': 'Nome da rede Wi-Fi (SSID)',
    'qrcode.show.ssidPassword': 'Senha da rede Wi-Fi',
    'qrcode.show.enabledApplication': 'Habilitar todos os aplicativos do sistema',
    // GENERAL CONFIGURATION
    'general_config.title': 'Configurações Gerais',
    'general_config.description': 'Nesta tela é possível gerenciar as configurações gerais.',
    'general_config.toaster': 'As configurações foram atualizadas com sucesso!',
    'general_config.form.startCycleDay': 'Início do ciclo',
    'general_config.form.syncTime': 'Sincronizar a cada',
    'general_config.form.work_config': 'Configurações de trabalho',
    'general_config.form.startDayOfWeek': 'Dias da semana',
    'general_config.form.optional': '(opcional)',
    'general_config.form.endDayOfWeek': 'até',
    'general_config.form.work_hour': 'Horário de trabalho',
    'menu.company.info': 'Informações da Empresa',
    'general_config.form.lock_outside_work_hours': 'Bloqueio total fora do horário de trabalho',
    'general_config.form.gps_config': 'Configurações de GPS',
    'general_config.form.gps_enabled': 'Ativar monitoramento',
    'general_config.form.gps_hour': 'Horário de Monitoramento GPS',
    'general_config.form.gps_precision': 'Precisão do GPS (metros)',
    'general_config.form.locate_every': 'Localizar a cada',
    'general_config.form.wifi_config': 'Configurações de Wi-Fi',
    'general_config.form.block_apps': 'Bloqueio de Aplicativos',
    'general_config.form.block_sites': 'Bloqueio de Sites',
    'general_config.form.get_usage_time': 'Obter tempo de uso',
    'general_config.form.distinct_configs': 'Configurações Diversas',
    'menu.app_action.settings.manage_application_config': 'Instalar / Atualizar aplicação',
    'general_config.form.block_url': 'Bloqueio total de URL',
    'general_config.form.total_block_apps': 'Bloqueio total de aplicativos',
    'general_config.form.hotspot': 'Permitir roteamento Wi-Fi (Hotspot)',
    'general_config.form.warning_email': 'E-mail de Aviso',
    'menu.company.license': 'Licenças Contratadas',
    'general_config.form.device_owner_allowed': 'Configurações permitidas em Device Owner',
    'general_config.form.device_owner_allowed_description': 'Essas configurações serão aplicadas apenas se o dispositivo estiver em modo Device Owner',
    'menu.location': 'Localização',
    'general_config.form.allow_safe_start': 'Permitir inicialização em Modo de Segurança',
    'general_config.form.allow_add_user': 'Permitir adicionar usuário',
    'general_config.form.week_start': 'Início da semana',
    'general_config.form.week_end': 'Fim da semana',
    'menu.location.device_location': 'Localização de Dispositivos',
    'general_config.form.hour_start': 'Hora do início',
    'general_config.form.hour_end': 'Hora do fim',
    'general_config.form.total_block': 'Bloqueio total fora do horário',
    'general_config.form.gps_hour_start': 'GPS início',
    'general_config.form.gps_hour_end': 'GPS fim',
    'general_config.form.sd_card_install': 'Permitir instalação de SD Card Externo',
    'general_config.form.cycle_start.tooltip': 'Alterar a data do início do ciclo durante o ciclo pode gerar inconsistências dos dados, o que será corrigido somente quando iniciar um novo ciclo.',
    'general_config.form.week_days.tooltip': 'Definir os dias de trabalho da empresa. O sistema considera Segunda-feira como o primeiro dia da semana.',
    'general_config.form.lock_outside_work_hours.tooltip': 'Ativa o bloqueio total dos aplicativos e navegação em sites em dados móveis e Wi-Fi quando o dispositivo estiver fora do horário de trabalho configurado.',
    'general_config.form.gps_enabled.tooltip': 'Permite ativar o monitoramento do dispositivo via GPS de acordo com o horário configurado.',
    'general_config.form.wifi_config.tooltip': 'Ao ativar uma das configurações em Wi-Fi, a regra será aplicada também quando o dispositivo estiver conectado a uma rede Wi-Fi.',
    'general_config.form.block_url.tooltip': 'Ativa o bloqueio total de navegação em sites em dados móveis e Wi-Fi.',
    'general_config.form.total_block_apps.tooltip': 'Ativa o bloqueio total dos aplicativos do dispositivo, em dados móveis e Wi-Fi.',
    'general_config.form.hotspot.tooltip': 'Permite ativar o compartilhamento da rede de dados móveis do dispositivo com outros dispositivos.',
    'general_config.form.warning_email.tooltip': 'Ativa o envio de notificação via e-mail ao administrador quando o dispositivo atinge 80%, 90% e 100% do perfil de consumo configurado.',
    'general_config.form.allow_safe_start.tooltip': 'Permite iniciar o dispositivo em Modo de Segurança.',
    'general_config.form.allow_add_user.tooltip': 'Permite adicionar um novo usuário no sistema operacional do dispositivo.',
    'general_config.form.sd_card_install.tooltip': 'Permite instalar um cartão de memória (SD Card externo).',
    'general_config.register.title': 'Cadastrar configuração',
    'general_config.edit.title': 'Editar configuração',
    'general_config.edit.description': 'Nesta tela é possível gerenciar as configurações gerais e aplicá-las nos dispositivos.',
    'general_config.group.toaster_create': 'As configurações do grupo foram cadastradas com sucesso!',
    'general_config.group.toaster_edit': 'As configurações do grupo foram atualizadas com sucesso!',
    'menu.messages': 'Mensagens',
    'general_config.subgroup.toaster_create': 'As configurações do subgrupo foram cadastradas com sucesso!',
    'general_config.subgroup.toaster_edit': 'As configurações do subgrupo foram atualizadas com sucesso!',
    'general_config.user.toaster_create': 'As configurações do usuário foram cadastradas com sucesso!',
    'general_config.user.toaster_edit': 'As configurações do usuario foram atualizadas com sucesso!',
    'general_config.edit.group.track_gps': 'Monitorar GPS',
    'general_config.edit.group.title': 'Editar configuração do grupo',
    'general_config.register.group.title': 'Cadastrar configuração do grupo',
    'general_config.edit.subgroup.title': 'Editar configuração do subgrupo',
    'general_config.register.subgroup.title': 'Cadastrar configuração do subgrupo',
    'general_config.edit.user.title': 'Editar configuração do usuario',
    'general_config.register.user.title': 'Cadastrar configuração do usuario',
    // DOCUMENT
    'document.entity': 'Documento',
    'document.title': 'Compartilhar Documentos',
    'document.description': 'Nesta tela é possível gerenciar e compartilhar documentos via push para os dispositivos.',
    'document.start_date': 'Data Inicial',
    'document.end_date': 'Data Final',
    'document.new': 'Compartilhar novo',
    'document.view': 'Visualizar',
    'document.toaster_success': 'Documento compartilhado com sucesso!',
    'document.content': 'Link do documento',
    'document.send_label': 'Enviar',
    'document.filter_title': 'Enviar documentos',
    // DOCUMENT DETAILS
    'document_details.title': 'Detalhes do compartilhamento',
    'document_details.description': 'Nesta tela é possível visualizar os detalhes do documento compartilhado.',
    'document_details.doc': 'Documento',
    'document_details.subtitle': 'Destinatários',
    // DATE REPORT
    'date_report.title': 'Relatório de Sites por Data',
    'date_report.title_text': 'Nesta tela é possível visualizar uma linha do tempo dos sites acessados',
    'date_report.select_user': 'Selecione um usuário',
    // DATE REPORT
    'sites_url.title': 'Relatório de Sites por URL ou Palavra-Chave',
    'sites_url.description': 'Nesta tela é possível gerar o relatório dos sites acessados nos dispositivos por uma URL ou palavra-chave.',
    'sites_url.field_search': 'Url ou palavra-chave',
    'sites_url.field_user': 'Usuário',
    'sites_url.placeholder_search': 'Digite a URL ou palavra-chave',

    // Validations
    'form.field_default': 'Falha na validação',
    'form.field_required': 'Completar o campo',
    'form.field_email': 'Insira um e-mail válido',
    'form.field_alpha_num_dash_space': 'Campo deve ter letras, números, espaços ou traços',
    'form.integer': 'Deve ser um número inteiro',
    // Permissions / Roles
    'role.viewUsersGps': 'Visualizar GPS dos usuários',
    'role.editUserRole': 'Editar rol de usuário',
    'role.editConfig': 'Editar configuração',
    'role.editLimits': 'Editar limites',
    'role.editActions': 'Editar ações',
    // Map
    'map.state': 'Estado',
    'map.precision': 'Precisão GPS',
    'map.battery': 'Bateria',
    'map.selected.infos.phone': 'Telefone',
    'map.selected.infos.date_time': 'Data/Horário',
    'map.empty': 'Nenhum dispositivo localizado',

    // Card Help
    'faq.card_one.manuals.title': 'Manuais para download',
    'faq.card_one.manuals.description': 'Confira nossos documentos e materiais de apoio.',
    'faq.card_tow.tutorial_video_title': 'Vídeos tutoriais',
    'faq.card_tow.tutorial_video_description': 'Confira nossos vídeos explicativos.',
    'faq.card_three.suport.title': 'Ainda tem dúvida?',
    'faq.card_three.suport.description': 'Nossa equipe de suporte pode te ajudar.',
    'faq.card_three.suport.button.link': 'Solicitação de Suporte',
    'card_help.faq.title': 'Alguma dúvida?',
    'card_help.faq.description': 'Acesse nossa página de FAQ e pesquise por sua dúvida.',
    'card_help.faq.button.link': 'Perguntas Frequentes',

    // Guides
    'guides.title': 'Documentos e Materiais de Apoio',
    'guides.description': 'Nesta tela é possível realizar o download dos documentos e materias de apoio para utilizar a solução {portalName}.',
    // FAQ
    'faq.all': 'Todas',
    'faq.title': 'Perguntas Frequentes',
    'faq.description': 'Nesta tela é possível visualizar as perguntas frequentes realizadas pelos usuários',
    'faq.input.placeholder': 'Como podemos lhe ajudar?',
    'faq.input.instruction': 'Filtre sua dúvida nas categorias abaixo, ou pesquise no campo de busca acima.',
    'faq.list.empty': 'Nenhuma pergunta cadastrada',
    'faq.list.not_found': 'Nenhuma pergunta encontrada',

    // Uninstalled Apllication
    'uninstalled_application.title': 'Aplicativo Desinstalado',
    'uninstalled_application.title_description': 'Nesta tela é possível visualizar as informações de todos os dispositivos em que o aplicativo foi desinstalado',

    // Uninstall Attempts
    'uninstall_attempts.title': 'Tentativas de Desinstalar',
    'uninstall_attempts.title_description': 'Nesta tela é possível visualizar as informações de todas as tentativas de desinstalar o app ${process.env.REACT_APP_PORTAL_NAME} dos dispositivos.',
    // Devices without communication
    'device_without_communication.title': 'Dispositivos sem Comunicação',
    'device_without_communication.title_description': 'Nesta tela é possível visualizar os dispositivos que estão sem comunicação com o Portal',

    // Notification Menu
    'notification.title': 'Notificações',
    'notification.list.empty': 'Você não tem nenhuma notificação',
    'notification.list.title': 'Relatório:',
    'notification.list.button.url': 'Clique para baixar',
    'menu.settings.profile_consumption': 'Perfil de Consumo',
    'notification.list.error': 'Erro na geração do relatório',

    // Generate Report Alert
    'generate_report_alert.title': 'Você deseja exportar um relatório com as informações exibidas na primeira página ou completo, com o resultado de todas as páginas?',
    'generate_report_alert.sub_title': 'Caso selecione a opção Completo o relatório será gerado em background e poderá demorar alguns minutos para ser concluído.',
    'menu.help.faq': 'Perguntas Frequentes',
    'generate_report_alert.button.first_page': 'Primeira Página',
    'generate_report_alert.button.complete': 'Completo',
    'generate_report_excel.legend.report': 'Relatório',
    'generate_report_excel.legend.current_date': 'Data',
    'generate_report_excel.legend.page': 'Página',
    'generate_report_excel.legend.total_pages': 'Total de Páginas',

    // New Keys
    'consumption_profile.toaster_success': 'Regra cadastrada com sucesso!',
    'consumption_profile.general.description': 'A configuração dos Perfis de Consumo não refletem a natureza do serviço contratado com a operadora. As informações registradas nesse formulário refletem apenas as políticas de uso da sua organização.',
    'consumption_profile.toaster_update': 'Regra atualizada com sucesso!',
    'consumption_profile.toaster_level_up': 'Regra definida para nível acima com sucesso!',

    'manage_admin.entity': 'Usuário',
    'manage_admin.toaster.success.edit': 'Administrador editado com sucesso',
    'manage_admin.filter.placeholder': 'Selecione',
    'message.send_label': 'Enviar',

    'block_application.toaster.register': 'Regra cadastrada com sucesso!',
    'block_application.toaster.update': 'Regra atualizada com sucesso!',
    'block_application.toaster.delete': 'Regra excluída com sucesso!',
    'menu.settings.qrcode': 'QR Code para Device Owner',

    'block_application.toaster.apply': 'Regras aplicadas com sucesso!',
    'devices.message.remove_success': 'Removido com sucesso',
    'menu.websites.report_date': 'Relatório por Data',
    'menu.websites.report_url': 'Relatório por URL / Palavra Chave',
    'menu.websites': 'Sites',
    'menu.app_action.application_control.website_by_category': 'Sites por categoria',
    'menu.app_action.application_control.website_by_url': 'Sites por URL',
    'menu.help.suport': 'Solicitação de Suporte',
    'menu.groups_and_subgroups.subgroups': 'Subgrupos',
    'menu.users': 'Usuários',
    'menu.users.device_users': 'Usuários',
    // Date Report
    'date_report.header.user': 'Usuário',
    'date_report.header.phoneNumber': 'Telefone',
    'date_report.header.domain': 'Dominio',
    'date_report.header.accessedAt': 'Data',

    'edit_group.company_name': 'Empresa',
    //MENU
    //dashboard
    //company
    // groups and subgroups
    //users
    'menu.users.device_users.manage_profile_access': 'Perfis de acesso',
    //devices
    // GPS
    'reportsGps.column.phone': 'Telefone',
    //application
    'reportsGps.column.user': 'Usuário',
    //sites
    'reportsGps.column.gps': 'GPS',
    //messages
    'reportsGps.column.createdAt': 'Data',
    //shered docs
    //location
    // CONSUMPTION HISTORIC
    'company_consumption.column.tabs_date': 'Data',
    'company_consumption.column.user': 'Usuário',
    // adroid actions
    'company_consumption.column.package_consumption': 'Consumo',
    // settings
    'company_license.column.enrolled_device': 'Dispositivo registrado',
    // help
    'company_license.select_state': 'Selecione um status',
    'map.address': 'Endereço',
    'map.speed': 'Velocidade',
    'consumption_profile.goup_company': 'Definido no nível acima',
    'consumption_profile.edit.description': 'Nesta tela é possível cadastrar uma nova regra de perfil de consumo para usuários que não possuem uma regra. Caso o usuário não seja exibido no campo selecionado, o mesmo pode já ter uma regra cadastrada anteriormente e você pode alterar. Revise a tela anterior e localize o usuário que deseja alterar a regra de perfil de consumo.',
    'sites_category.block.general.text': 'Nesta tela é possível configurar uma regra de perfil de bloqueio ou desbloqueio de sites por categorias nos dispositivos.',
    'consumption_profile.data.limit': 'Dados (Quantidade)',
    'consumption_profile.dataRoaming.limit': 'Dados em roaming (Quantidade)',
    'consumption_profile.sms.limit': 'SMS (Quantidade)',
    'consumption_profile.smsRoaming.limit': 'SMS em roaming(Quantidade)',
    'card_help.discover_more': 'Saiba mais',
    'sites_category.block.title': 'Bloquear / Desbloquear sites web por categoria',
    'message.content': 'Mensagem',
    'consumption_profile.groupId': 'Grupo',
    'consumption_profile.userId': 'Usuário',
    'global.empty_results': 'Nenhum resultado encontrado.',
    'menu.open': 'Abrir Menu',
    'menu.close': 'Fechar Menu',
    'reportsGps.start_date': 'Data inicial',
    'reportsGps.end_date': 'Data final',

    'qrcode.valid_url': 'Deve ser uma URL válida',
    'qrcode.lading_qrCode': 'Carregando QRCode...',
    'qrcode.invalid_url': 'Url inválida para download',
    'global.generic_not_found': 'Nada para ser exibido.',
    'messages.max_characters': 'Este campo deve ter no máximo 255 caracteres.',
    'global.not_option_found': 'Nenhuma opção encontrada',
    'global.select_placeholder': 'Selecione uma opção',
    'block_application.rule_type': 'Tipo de regra',
    'block_application.application': 'Aplicativo',
    'sites.block.success_create': 'Criado com sucesso',
    'sites.block.success_update': 'Atualizado com sucesso',
    'company_license.select_plan': 'Selecione um plano',
    'global.action.set_level_up': 'Definir Nível Acima',
    'devices.alert.change.text': 'Você deseja realizar a troca do equipamento?',
    'devices.alert.change.button': 'Trocar',
    'battery.chart_battery': 'Bateria',
    'battery.chart_date': 'Horário',
    'consumption_profile.modal.apply_config': 'Você deseja mesmo definir nivel acima?',
    'storage.chart_storage': 'Armazenamento',
    'storage.chart_date': 'Horário',
    'generate_complete_report_alert.confirm_button': 'Ok',
    'generate_complete_report_alert.title': 'Por favor aguarde a geração do relatório!',
    'generate_complete_report_alert.text': 'O sistema exibirá uma notificação na parte superior da tela ao concluir, onde poderá realizar o download do relatório.',
    'global.copy.success': 'Copiado para a área de transferência.',
    'register_policies.title': 'Cadastrar Nova Política',
    'register_policies.description': 'Nesta tela é possível cadastrar uma nova política.',
    'register_policies.name': 'Nome da Política',
    'register_policies.policyType': 'Selecione o modo de gerenciamento da política',
    'register_policies.select_policy': 'Selecione',
    'register_policies.select.placeholder': 'Selecione um modo',
    'menu.policy': 'Políticas',
    'menu.policy.manage': 'Gerenciar Políticas',
    'manage_policies.title': 'Gerenciar Políticas',
    'manage_policies.description': 'Nesta página é possível gerenciar políticas',
    'manage_policies.success.create': 'Política criada com sucesso!',
    'manage_policies.new': 'Nova Política',
    'manage_policies.table_actions.enrollment_token': 'Token de Registro',
    'manage_policies.table_actions.edit': 'Editar Política',
    'manage_policies.table_actions.copy': 'Copiar Política',
    'manage_policies.table_actions.delete': 'Remover Política',
    'manage_policies.modal_enrollmentToken.title': 'Token de Registro',
    'manage_policies.modal_enrollmentToken.code': 'Codigo',
    'manage_policies.modal_enrollmentToken.confirm': 'Ok',
    'menu.enterprise_applications': 'Gerenciamento de Aplicativos',
    'menu.enterprise_applications.manage': 'Aplicativos Gerenciados',
    'enterprise_applications.title': 'Aplicativos Gerenciados',
    'enterprise_applications.description': 'Nesta página é possível gerenciar aplicativos enterprise',
    'enterprise_applications.table_actions.entity': 'Aplicativo',
    'enterprise_applications.column.icon': 'Ícone',
    'enterprise_applications.column.name_app': 'Nome do aplicativo',
    'enterprise_applications.column.origin': 'Origem',
    'enterprise_applications.column.name_package': 'Nome do pacote',
    'enterprise_applications.filter': 'Nome do aplicativo',
    'register_policies.android': 'Android',
    'register_policies.android.description': 'Para dispositivos de propriedade da empresa. Permite o gerenciamento de uma ampla variedade de configurações de dispositivos e controles de política.',
    'register_policies.dedicated_mode': 'Dispositivos Dedicados',
    'register_policies.dedicated_mode.description': 'Para dispositivos dedicados, onde os dispositivos são bloqueados para um conjunto de aplicativos (por meio da configuração de um aplicativo de quiosque). Podendo impedir que outros aplicativos sejam iniciados e outras ações sejam executadas no dispositivo.',
    'enterprise_applications.success.delete': 'Aplicativo removido com sucesso!',
    'manage_policies.success.delete': 'Política removida com sucesso!',
    'menu.enterprise_applications.google_play_managed': 'Google Play Gerenciada',
    'devices.groups': 'Grupos',
    'google_play_managed.toast.success.description': 'Aplicativo selecionado com sucesso!',
    'google_play_managed.toast.error.description': 'Ocorreu um erro ao selecionar o aplicativo.',
    'google_play_managed.toast.success.modal.text': 'Deseja selecionar esse aplicativo?',
    'register_company.button.next': 'Próximo',
    'manage_policies.confirm.text': 'Tem certeza que deseja remover esta política?',
    'register_policies.name.placeholder': 'Digite o nome da nova política',
    'global.report': 'Relatório',
    'message.recipient': 'Destinatário',
    'document.recipient': 'Destinatário',

    'devices.column.management_mode': 'Modelo',
    'devices.column.enterpriseId': 'Id no Android Enterprise',
    'devices.action.wipe_device': 'Remover Dispositivo (WIPE)',
    'company_register.title': 'Criar empresa',
    'company_register.subtitle': 'Nesta tela é possível cadastrar as informações da sua empresa.',
    'menu.policy.register': 'Cadastrar Nova Política',
    'company_callback.success': 'Parabéns! Sua empresa foi registrada no Android Enterprise com sucesso.',
    'company_callback.failure': 'Ocorreu um erro na criação, tente novamente.',
    'global.ok': 'Ok',
    'global.confirm_password': 'Confirmar nova senha',
    'register_company.info': 'Será enviado um código de confirmação para o e-mail informado acima.',
    'register_company.title1': 'Confirme os dados da empresa',
    'register_company.title2': 'para continuar',
    'register_company.create_new_password': 'Criar nova senha para o usuário',
    'register_company.email': 'E-mail',
    'register_company.corporateName': 'Nome da Empresa',
    'register_company.password': 'Senha',
    'register_company.confirm_password': 'Confirmação de Senha',
    'register_company.optional': '(Opcional)',
    'register_company.send_confirm': 'Enviar código de confirmação',
    'register_company.eu.representative': 'EU Representative',
    'register_company.data.protection.officer': 'Data Protection Officer',
    'register_company.password_alert': 'As senhas não conferem. É necessário preencher o campo de confirmação com a mesma senha para realizar a alteração da senha. Revise as senhas digitadas e tente novamente.',
    'register_validate.title1': 'Verifique o',
    'register_validate.title2': 'seu e-mail',
    'register_validate.info': 'Preencha o código de 6 dígitos que foi enviado para o seu e-mail',
    'register_validate.resend': 'Reenviar código',
    'register_validate.send_confirm': 'Confirmar',
    'enterprise_applications.table_actions.remove': 'Remover',
    'policy_copy.title': 'Copiar política',
    'policy_copy.subtitle': 'Copiando política {name} - {operationalSystem}',
    'manage_policies.policy': 'Política',
    'register_company.empty_password_alert': 'A senha deve ser informada',
    'update_policies.title': 'Editar Política',
    'update_policies.description': 'Nesta tela é possível atualizar uma política.',
    'update_policies.tab.configuration': 'Configurações',
    'update_policies.tab.application': 'Aplicativos',
    'update_policies.tab.kiosk': 'Modo Quiosque',
    'update_policies.tab.token': 'Token de Registro',
    'update_policies.policyType': 'Modo de Gerenciamento',
    'update_policies.name': 'Nome da nova Política',
    'devices.column.policy': 'Política',
    'devices.column.accordion.management_mode': 'Modo de Gerenciamento',
    'devices.column.accordion.management_mode.managed_android': 'Android',
    'devices.column.accordion.management_mode.block_sim_android': 'Android - Block Sim',
    'devices.column.accordion.management_mode.work_profile_android': 'Android - Work Profile',
    'devices.column.accordion.management_mode.managed_ios': 'IOS',
    'devices.column.accordion.management_mode.supervised_ios': 'IOS supervisionado',
    'devices.column.status': 'Status',
    'devices.column.appliedState': 'Status Aplicado',
    'devices.column.register_date': 'Data de Registro',
    'devices.column.accordeon.management_mode': 'Modo de gerenciamento',
    'devices.column.operation_system': 'Sistema operacional',
    'devices.column.last_sync': 'Última Sincronização de Política',
    'devices.column.serial_number': 'Número de Série',
    'devices.column.battery_power': 'Nível da Bateria',
    'devices.column.storage': 'Armazenamento Interno',
    'devices.value.state_active': 'Ativo',
    'devices.value.state_disabled': 'Desabilitado',
    'devices.value.state_deleted': 'Deletado',
    'devices.value.state_provisioning': 'Provisionando',
    'edit_policy.kiosk.navigate_button': 'Botões de Navegação',
    'edit_policy.kiosk.power_button': 'Botão "Power"',
    'edit_policy.kiosk.message_error_exibition': 'Exibir Mensagens de Erro',
    'edit_policy.kiosk.status_bar_exibition': 'Informações Exibidas na Barra de Status',
    'edit_policy.kiosk.config_access': 'Acesso à Configurações',
    'edit_policy.kiosk.available': 'Disponível',
    'edit_policy.kiosk.blocked': 'Bloqueado',
    'edit_policy.kiosk.active': 'Ativo',
    'edit_policy.kiosk.muted': 'Silenciado',
    'edit_policy.kiosk.notify_info_system': 'Notificações e Informações do Sistema',
    'edit_policy.kiosk.sytem_only': 'Apenas Informações do Sistema',
    'edit_policy.kiosk.none': 'Nenhuma',
    'edit_policy.kiosk.released': 'Liberado',
    'edit_policy.kiosk.home_only': 'Apenas botão “Home”',
    'edit_policy.kiosk.kiosk_mode.title': 'Modo Quiosque',
    'edit_policy.kiosk.kiosk_mode.subtitle': 'Você pode controlar as configurações do modo Quiosque. Após ativar o Modo Quiosque, acesse a aba "Aplicativos" para definir como eles serão exibidos nos dispositivos registrados nesta política.',
    'edit_policy.kiosk.modal.active': 'Ativar',
    'edit_policy.kiosk.modal.title': 'Você tem certeza que quer ativar o Modo Quiosque?',
    'update_policies.button.save': 'Salvar',
    'register_company.term.title': 'Política e Termos',
    'register_company.term.description': 'Para usar, {productName}, é necessário ler e concordar com a Política de Privacidade e os Termos de Uso do Usuário',
    'register_company.term.user_term': 'Concordo com os Termos de Uso do Usuário',
    'register_company.term.privacy_policy': 'Concordo com a Política de Privacidade',
    'update_policies.warning_exit': 'Você deseja sair da edição da política sem salva-la? Todas as alterações realizadas serão descartadas!',
    'update_policies.message_success': 'Política salva com sucesso!',
    'history_consumption.title': 'Histórico de Consumo',
    'history_consumption.description': 'Nesta tela é possível visualizar o gráfico de evolução do consumo de dados do aplicativo nos dispositivos.',
    'update_policies.applications.card_mode.title': 'Modo de seleção dos aplicativos na Google Play',
    'update_policies.applications.card_mode.description': 'Você pode controlar aqui como seus aplicativos serão exibidos nos dispositivos registrados neste perfil. Consulte a descrição do modo selecionado para obter detalhes. No modo "Restrito", os usuários só podem visualizar e instalar da Google Play Store os aplicativos listados abaixo. Os outros aplicativos serão removidos.',
    'update_policies.applications.card_mode.whitelist': 'Restrita',
    'update_policies.applications.card_mode.blacklist': 'Aberta',
    'update_policies.applications.card_mode.kiosk': 'Modo Quiosque',
    'update_policies.applications.table.column.name_app': 'Nome do Aplicativo',
    'update_policies.applications.table.column.origin': 'Origem',
    'update_policies.applications.table.column.install_type': 'Tipo de Instalação',
    'update_policies.applications.table.button_label': 'Adicionar aplicativos',
    'update_policies.applications.installation_type.preinstalled': 'Pré-instalado',
    'update_policies.applications.installation_type.force_installed': 'Instalação Forçada',
    'update_policies.applications.installation_type.blocked': 'Bloqueado',
    'update_policies.applications.installation_type.available': 'Disponível',
    'update_policies.applications.table_action.settings': 'Configurações Gerenciadas',
    'update_policies.applications.table_action.permissions': 'Permissões',
    'update_policies.applications.table_action.advance_settings': 'Configurações Avançadas',
    'update_policies.applications.table_action.remove_app': 'Remover Aplicativo',
    'update_policies.modal_enterprise_apps.title': 'Adicionar aplicativos',
    'update_policies.modal_enterprise_apps.button_label': 'Adicionar selecionados',
    'update_policies.settings.accordion.network': 'Rede',
    'update_policies.settings.accordion.network.vpn.title': 'Bloquear configurações de VPN',
    'update_policies.settings.accordion.network.vpn.description': 'Configuração da VPN está desabilitada',
    'update_policies.settings.accordion.network.wifi.title': 'Bloquear configurações de Wi-Fi',
    'update_policies.settings.accordion.network.wifi.description': 'Configuração de pontos de acesso Wi-Fi está desabilitada',
    'update_policies.settings.accordion.network.network_reset.title': 'Bloquear redefinição das configurações de rede',
    'update_policies.settings.accordion.network.network_reset.description': 'Redefinição das configurações de rede está desativada',
    'update_policies.settings.accordion.network.roaming_block.title': 'Bloquear dados em roaming',
    'update_policies.settings.accordion.network.roaming_block.description': 'Configurações de roaming de dados estão desativadas',
    'update_policies.settings.accordion.network.mobile_network_block.title': 'Bloquear configuração de rede móvel',
    'update_policies.settings.accordion.network.mobile_network_block.description': 'Configuração de redes móveis está desativada',

    'update_policies.settings.accordion.hardware': 'Hardware',
    'update_policies.settings.accordion.hardware.disable_camera.title': 'Desativar câmera',
    'update_policies.settings.accordion.hardware.disable_camera.description': 'Todas as câmeras do dispositivo estão desativadas',
    'update_policies.settings.accordion.hardware.lock_volume.title': 'Bloquear ajustes de volume',
    'update_policies.settings.accordion.hardware.lock_volume.description': 'Ajuste do volume principal está desativado. Também silencia o dispositivo.',
    'update_policies.settings.accordion.hardware.disable_bluetooth.title': 'Desativar bluetooth',
    'update_policies.settings.accordion.hardware.disable_bluetooth.description': 'Bluetooth está desativado',
    'update_policies.settings.accordion.hardware.bluetooth_settings_block.title': 'Bloquear configurações de bluetooth',
    'update_policies.settings.accordion.hardware.bluetooth_settings_block.description': 'Configuração do bluetooth está desabilitada',
    'update_policies.settings.accordion.hardware.disable_hotspot.title': 'Desativar configurações de hotspot',
    'update_policies.settings.accordion.hardware.disable_hotspot.description': 'Configuração de hotspots portáteis está desabilitada',
    'update_policies.settings.accordion.hardware.disable_screenshots.title': 'Desativar captura de telas',
    'update_policies.settings.accordion.hardware.disable_screenshots.description': 'Captura de tela está desativada',
    'update_policies.settings.accordion.hardware.microphone_access.title': 'Acesso ao microfone',
    'update_policies.settings.accordion.hardware.microphone_access.description': 'Microfone está mudo e o ajuste do volume do microfone está desabilitado',
    'update_policies.settings.accordion.hardware.microphone_access.select.user_choice': 'Definido pelo usuário',
    'update_policies.settings.accordion.hardware.microphone_access.select.access_disabled': 'Desativado',
    'update_policies.settings.accordion.hardware.microphone_access.select.access_enforced': 'Ativado',
    'update_policies.settings.accordion.hardware.outgoing_calls_disabled.title': 'Desabilitar realização de chamadas',
    'update_policies.settings.accordion.hardware.outgoing_calls_disabled.description': 'Todas as chamadas telefônicas de saída estão desativadas.',
    'update_policies.settings.accordion.hardware.bluetooth_contact_sharing_disabled.title': 'Bloquear o Compartilhamento de Contatos Via Bluetooth',
    'update_policies.settings.accordion.hardware.bluetooth_contact_sharing_disabled.description': 'Compartilhamento de contatos via Bluetooth está desativado.',

    'update_policies.settings.accordion.safety': 'Segurança',
    'update_policies.settings.accordion.safety.new_users_block.title': 'Bloquear adição de novos usuários',
    'update_policies.settings.accordion.safety.new_users_block.description': 'Adição de novos usuários e perfis está desabilitada',
    'update_policies.settings.accordion.safety.new_users_block_remove.title': 'Bloquear remoção de novos usuários',
    'update_policies.settings.accordion.safety.new_users_block_remove.description': 'Remoção de outros usuários está desabilitada',
    'update_policies.settings.accordion.safety.reset_block.title': 'Bloquear restauração de fábrica',
    'update_policies.settings.accordion.safety.reset_block.description': 'Redefinição das configurações de fábrica está desativada',
    'update_policies.settings.accordion.safety.sd_card_block.title': 'Bloquear SD Card',
    'update_policies.settings.accordion.safety.sd_card_block.description': 'Montagem de mídia física externa está desabilitada',
    'update_policies.settings.accordion.safety.usb_sharing_block.title': 'Bloquear compartilhamento de arquivos via USB',
    'update_policies.settings.accordion.safety.usb_sharing_block.description': 'Transferência de arquivos por USB está desabilitada',

    'update_policies.settings.accordion.localization': 'Localização',
    'update_policies.settings.accordion.localization.mode.title': 'Modo de localização',
    'update_policies.settings.accordion.localization.mode.description': 'Detecção de localização ativado',
    'update_policies.settings.accordion.localization.mode.select.user_choice': 'Definido pelo usuário',
    'update_policies.settings.accordion.localization.mode.select.location_disabled': 'Desativado',
    'update_policies.settings.accordion.localization.mode.select.location_enforced': 'Ativado',
    'update_policies.settings.accordion.localization.location_sharing_disabled.title': 'Desativar compartilhamento de localização',
    'update_policies.settings.accordion.localization.location_sharing_disabled.description': 'Compartilhamento de local está desativado',
    'update_policies.settings.accordion.localization.monitoring_hours.title': 'Horário de monitoramento',
    'update_policies.settings.accordion.localization.monitoring_hours.description': 'Período em que a detecção da localização está ativado',
    'update_policies.settings.accordion.localization.monitoring_hours.initial_time': 'Hora início',
    'update_policies.settings.accordion.localization.monitoring_hours.final_time': 'Hora fim',
    'update_policies.settings.accordion.localization.monitoring_hours.select': 'Selecione',
    'update_policies.settings.accordion.localization.monitoring_hours.gps_frequency.min3': '3 minutos',
    'update_policies.settings.accordion.localization.monitoring_hours.gps_frequency.min10': '10 minutos',
    'update_policies.settings.accordion.localization.monitoring_hours.gps_frequency.min30': '30 minutos',
    'update_policies.settings.accordion.localization.monitoring_hours.gps_frequency.h1': '1 hora',
    'update_policies.settings.accordion.localization.monitoring_hours.gps_frequency.h6': '6 horas',
    'update_policies.settings.accordion.localization.monitoring_hours.gps_frequency.h12': '12 horas',
    'update_policies.settings.accordion.localization.monitoring_hours.gps_frequency.h24': '24 horas',
    'update_policies.settings.accordion.localization.find_every.title': 'Localizar a cada',
    'update_policies.settings.accordion.localization.find_every.description': 'Frequencia de captura da localização',
    'update_policies.locationMode': 'Localização - Modo de localização',
    'update_policies.gpsCaptureStartTime': 'Localização - Hora início',
    'update_policies.gpsCaptureEndTime': 'Localização - Hora fim',
    'update_policies.gpsFrequency': 'Localização - Localizar a cada',
    'edit_policy.app_config.title': 'Configurações Gerenciadas',
    'edit_policy.app_config.list_application': 'Lista de Aplicativos',
    'update_policies.applications.app_advanced_config.title': 'Configurações avançadas',
    'update_policies.applications.app_advanced_config.auto_update_mode.title': 'Prioridade de Atualização',
    'update_policies.applications.app_advanced_config.auto_update_mode.description': 'Modo de atualização automática',
    'update_policies.applications.app_advanced_config.auto_update_mode.no_specify': 'Não Especificada',
    'update_policies.applications.app_advanced_config.auto_update_mode.default': 'Pré definida',
    'update_policies.applications.app_advanced_config.auto_update_mode.postponed': 'Postergada',
    'update_policies.applications.app_advanced_config.auto_update_mode.high_priority': 'Prioritária',
    'update_policies.applications.app_advanced_config.minimum_version_code.title': 'Versão Mínima',
    'update_policies.applications.app_advanced_config.minimum_version_code.description': 'Código da versão mínima',
    'edit_policy.app_permissions.title': 'Permissões',
    'edit_policy.app_permissions.select_label.unspecified': 'Não Especificado',
    'edit_policy.app_permissions.select_label.prompt': 'Solicitar ao Usuário',
    'edit_policy.app_permissions.select_label.grant': 'Ativada',
    'edit_policy.app_permissions.select_label.deny': 'Negada',
    'edit_policy.applications.managed_config.empty_config': 'Não existem configurações disponíveis para este aplicativo.',
    'edit_policy.applications.managed_config.empty_permission': 'Não existem configurações disponíveis para este aplicativo.',
    'devices.column.federalCode': 'Identificação',

    'history_consumption.header.filter_by': 'filtrado por:',
    'history_consumption.chart_legend.consumption': 'Consumo',
    'history_consumption.chart_legend.day': 'Dia',
    'history_consumption.chart_legend.empty': 'Nenhum histórico encontrado',
    'history_consumption_data.title': 'Histórico de Consumo de dados',
    'history_consumption_sms.title': 'Histórico de Consumo de SMS',
    'history_consumption.history_user.description': 'Nesta tela é possível visualizar o gráfico de evolução do consumo de dados do aplicativo.',
    'notification.not_found_key': 'Chave não encontrada',

    'update_policies.applications': 'Aplicativos',
    'update_policies.applications.minimumVersionCode': 'Versão Mínima do aplicativo',

    'global.blocked': 'Bloqueado',
    'global.released': 'Liberado',

    'edit_policy.app_config.key_existing_alert': 'Palavra chave já existente.',
    'consumption_limit_group.title': 'Perfil de Consumo - Grupo',
    'consumption_limit_user.title': 'Perfil de Consumo - Usuário',
    'devices.action.policy': 'Alterar Política',
    'devices.action.turn_off_screen': 'Desligar Tela',
    'devices.action.restart_device': 'Reiniciar Dispositivo',
    'devices.action.alert_reboot1': 'O dispositivo',
    'devices.action.alert_reboot2': 'foi reiniciado.',
    'devices.action.alert_reboot': 'O comando de reinicio do dispositivo foi enviado com sucesso!',

    'non_compliance.title': 'Não conformidades',
    'non_compliance.sub_title': 'Nesta tela é possível identificar a configuração da política que não foi aplicada pelo usuário no dispositivo e o motivo da não conformidade.',
    'non_compliance.card_header.title': 'Dispositivo',
    'non_compliance.form.user': 'Usuário',
    'non_compliance.form.phoneNumber': 'Telefone',
    'non_compliance.form.imei': 'IMEI',
    'non_compliance.form.manufacturer': 'Fabricante',
    'non_compliance.form.model': 'Modelo',
    'non_compliance.column.settingName': 'Configuração',
    'non_compliance.column.nonComplianceReason': 'Motivo de Não Conformidade',
    'non_compliance.nonComplianceReason.reason_unspecified': 'Este valor não é permitido',
    'non_compliance.nonComplianceReason.api_level': 'A configuração não é compatível com o nível de API da versão do Android em execução no dispositivo.',
    'non_compliance.nonComplianceReason.management_mode': 'O modo de gerenciamento (proprietário do perfil, proprietário do dispositivo etc.) não é compatível com a configuração.',
    'non_compliance.nonComplianceReason.user_action': 'O usuário não tomou as medidas necessárias para cumprir a configuração.',
    'non_compliance.nonComplianceReason.invalid_value': 'A configuração tem um valor inválido.',
    'non_compliance.nonComplianceReason.app_not_installed': 'O aplicativo necessário para implementar a política não está instalado.',
    'non_compliance.nonComplianceReason.unsupported': 'A política não é compatível com a versão do Android Device Policy no dispositivo.',
    'non_compliance.nonComplianceReason.app_installed': 'Um aplicativo bloqueado está instalado.',
    'non_compliance.nonComplianceReason.pending': 'A configuração não foi aplicada no momento do relatório, mas espera-se que seja aplicada em breve.',
    'non_compliance.nonComplianceReason.app_incompatible': 'A configuração não pode ser aplicada ao aplicativo porque o aplicativo não é compatível com ela, por exemplo, porque a versão do SDK de destino não é alta o suficiente.',
    'non_compliance.nonComplianceReason.app_not_updated': 'O aplicativo está instalado, mas não foi atualizado para o código de versão mínimo especificado pela política.',
    'non_compliance.column.specificNonComplianceReason': 'Detalhe da Não Conformidade',
    'non_compliance.specificNonComplianceReason.unspecified': 'O motivo específico da não conformidade não é especificado.',
    'non_compliance.specificNonComplianceReason.credentials_confirmation_required': 'O usuário precisa confirmar as credenciais digitando o bloqueio de tela.',
    'non_compliance.specificNonComplianceReason.password_expired': 'A senha do dispositivo ou perfil expirou.',
    'non_compliance.specificNonComplianceReason.password_not_sufficient': 'A senha do dispositivo não atende aos requisitos de senha.',
    'non_compliance.specificNonComplianceReason.wifi_invalid_value': 'Há um valor incorreto na configuração do ONC Wi-Fi.',
    'non_compliance.specificNonComplianceReason.wifi_api_level': 'A configuração ONC Wi-Fi não é compatível com o nível de API da versão do Android em execução no dispositivo.',
    'non_compliance.expander.installationFailureReason': 'Motivo da Falha na Instalação',
    'non_compliance.expander.installationFailureReason.unspecified': 'Este valor não é permitido.',
    'non_compliance.expander.installationFailureReason.unknown': 'Uma condição desconhecida está impedindo que o aplicativo seja instalado. Alguns possíveis motivos são que o dispositivo não tem armazenamento suficiente, a conexão de rede do dispositivo não é confiável ou a instalação está demorando mais do que o esperado. A instalação será repetida automaticamente.',
    'non_compliance.expander.installationFailureReason.in_progress': 'A instalação ainda está em andamento.',
    'non_compliance.expander.installationFailureReason.not_found': 'O aplicativo não foi encontrado no Play.',
    'non_compliance.expander.installationFailureReason.not_compatible': 'O aplicativo é incompatível com o dispositivo.',
    'non_compliance.expander.installationFailureReason.not_approved': 'O aplicativo não foi aprovado pelo administrador.',
    'non_compliance.expander.installationFailureReason.permissions_not_accepted': 'O aplicativo tem novas permissões que não foram aceitas pelo administrador.',
    'non_compliance.expander.installationFailureReason.not_available_in_country': 'O aplicativo não está disponível no país do usuário.',
    'non_compliance.expander.installationFailureReason.no_licenses_remaining': 'Não há licenças disponíveis para atribuir ao usuário.',
    'non_compliance.expander.installationFailureReason.not_enrolled': 'A empresa não está mais inscrita no Managed Google Play ou o administrador não aceitou os Termos de Serviço do Managed Google Play mais recentes.',
    'non_compliance.expander.installationFailureReason.user_invalid': 'O usuário não é mais válido. O usuário pode ter sido excluído ou desabilitado.',
    'non_compliance.expander.packageName': 'Nome do Pacote',
    'non_compliance.expander.fieldPath': 'Caminho do Campo',
    'non_compliance.expander.currentValue': 'Valor Atual',
    'non_compliance.expander.wifi_context': 'Contexto de Wi-Fi',
    'non_compliance.expander.password_context': 'Contexto de senha da política',
    'non_compliance.expander.password_context.scope_unspecified': 'Escopo não especificado',
    'non_compliance.expander.password_context.scope_device': 'Escopo de dispositivo',
    'non_compliance.expander.password_context.scope_profile': 'Escopo de perfil',
    'update_policies.settings.accordion.password': 'Restrições de Senha',
    'update_policies.settings.accordion.password.select.something': 'Alguma',
    'update_policies.settings.accordion.password.select.numeric': 'Numérica',
    'update_policies.settings.accordion.password.select.numeric_complex': 'Numérica Complexa',
    'update_policies.settings.accordion.password.select.alphabetic': 'Alfabética',
    'update_policies.settings.accordion.password.select.alphanumeric': 'Alfanumérica',
    'update_policies.settings.accordion.password.select.complex': 'Complexa',
    'update_policies.settings.accordion.password.select.biometric_weak': 'Biometria',
    'update_policies.settings.accordion.password.title': 'Qualidade da senha',
    'devices.action.new_device_password': 'Gerar Nova Senha do Dispositivo',
    'device.modal.new_device_password.description': 'Informe as configurações para redefinir a senha de bloqueio do dispositivo',
    'device.modal.new_device_password.newPassword': 'Nova senha',
    'device.modal.new_device_password.new_password_confirm': 'Confirmar nova senha',
    'device.modal.new_device_password.checkbox.not_allow_password_change': 'Não permitir que outros administradores alterem a senha novamente até que o usuário a insira no dispositivo.',
    'device.modal.new_device_password.checkbox.not_ask_credentials_user': 'Não pedir credenciais de usuário na inicialização do dispositivo.',
    'device.modal.new_device_password.checkbox.device_block': 'Bloquear o dispositivo após a redefinição da senha.',
    'update_policies.passwordPolicies.select.something': 'Alguma',
    'update_policies.passwordPolicies.select.numeric': 'Numérica',
    'update_policies.passwordPolicies.select.numeric_complex': 'Númerica Complexa',
    'update_policies.passwordPolicies.select.alphabetic': 'Alfabética',
    'update_policies.passwordPolicies.select.alphanumeric': 'Alfanumérica',
    'update_policies.passwordPolicies.select.complex': 'Complexa',
    'update_policies.passwordPolicies.select.biometric_weak': 'Biometria',
    'update_policies.passwordPolicies.title': 'Qualidade da senha',
    'update_policies.passwordPolicies.select.password_require.default': 'Padrão do Dispositivo',
    'update_policies.passwordPolicies.select.password_require.every_day': 'Todo dia',
    'update_policies.passwordPolicies.passwordHistoryLength': 'Comprimento de histórico de senhas',
    'update_policies.passwordPolicies.maximumFailedPasswordsForWipe': 'Máximo de senhas incorretas antes de executar wipe',
    'update_policies.passwordPolicies.passwordExpirationTimeout': 'Tempo limite de expiração da senha (dias)',
    'update_policies.passwordPolicies.requirePasswordUnlock': 'Exigir desbloqueio de senha',
    'update_policies.passwordPolicies.passwordMinimumLength': 'Comprimento mínimo da senha',
    'update_policies.passwordPolicies.passwordMinimumLetters': 'Número mínimo de letras exigidas na senha',
    'update_policies.passwordPolicies.passwordMinimumNonLetter': 'Número mínimo de caracteres que não sejam letras (dígitos numéricos ou símbolos) necessários na senha',
    'update_policies.passwordPolicies.passwordMinimumNumeric': 'Número mínimo de dígitos numéricos necessários na senha',
    'update_policies.passwordPolicies.passwordMinimumSymbols': 'Número mínimo de símbolos necessários na senha',
    'update_policies.passwordPolicies.passwordMinimumUpperCase': 'Número mínimo de letras maiúsculas exigidas na senha',
    'update_policies.passwordPolicies.passwordMinimumLowerCase': 'Número mínimo de letras minúsculas exigidas na senha',
    'update_policies.passwordPolicies.description.biometric_weak': 'O dispositivo deve ser protegido com no mínimo, uma tecnologia de reconhecimento biométrico de baixa segurança.',
    'update_policies.passwordPolicies.description.something': 'Uma senha é necessária, mas não há restrições sobre o que a senha deve conter.',
    'update_policies.passwordPolicies.description.complex': 'A senha deve conter ao menos uma letra, um caractere numérico e um símbolo.',
    'update_policies.passwordPolicies.description.numeric': 'A senha deve conter no mínimo caracteres numéricos.',
    'update_policies.passwordPolicies.description.numeric_complex': 'A senha deve conter, no mínimo, caracteres numéricos sem sequências repetidas (4444) ou ordenadas (1234, 4321, 2468).',
    'update_policies.passwordPolicies.description.alphabetic': 'A senha deve conter, no mínimo, caracteres alfabéticos (ou símbolos).',
    'update_policies.passwordPolicies.description.alphanumeric': 'A senha deve conter, no mínimo, caracteres numéricos e alfabéticos (ou símbolos).',
    'device.modal.new_device_password.success': 'O comando de redefinição de senha foi enviado com sucesso!',
    'update_policies.applications.installation_type.required_for_setup': 'Necessário no Registro',
    'update_policies.settings.accordion.safety.untrusted_app.title': 'Política de aplicativos não confiáveis.',
    // System Update
    'update_policies.settings.accordion.operational_system': 'Sistema Operacional',
    'update_policies.systemUpdate.select.type': 'Política de atualizações do sistema',
    'update_policies.systemUpdate.select.type.description': 'A política de atualização do sistema controla como as atualizações do sistema operacional são aplicadas. Se o tipo de atualização for "Automático dentro de uma janela", a janela de atualização também será aplicada automaticamente às atualizações de aplicativos do Google Play.',
    'update_policies.systemUpdate.select.unspecified': 'Definido pelo usuário',
    'update_policies.systemUpdate.select.automatic': 'Automático',
    'update_policies.systemUpdate.select.windowed': 'Automático dentro de uma janela',
    'update_policies.systemUpdate.select.postpone': 'Adiada',
    'update_policies.systemUpdate.time': 'Horário da janela de atualização',
    'update_policies.systemUpdate.time.description': 'Ao definir a política “Automático dentro de uma janela“, as atualizações do sistema serão executadas dentro do horário da janela especificado.',
    'update_policies.systemUpdate.time.select': 'Selecione',
    'update_policies.systemUpdate.startMinutes': 'Horário do Inicio',
    'update_policies.systemUpdate.endMinutes': 'Horário do Fim',
    'update_policies.freezePeriods': 'Períodos de freeze das atualizações',
    'update_policies.systemUpdate.freeze_period.description': 'Todas as atualizações de sistema recebidas (incluindo patches de segurança) são bloqueadas e não serão instaladas. Quando um dispositivo está fora do período de congelamento, aplica-se o comportamento normal de atualização.',
    'update_policies.systemUpdate.add_freeze_period': '+ Adicionar período de freeze',
    'update_policies.systemUpdate.freezePeriods.startDate': 'Mês e dia de início do período de freeze',
    'update_policies.systemUpdate.freezePeriods.endDate': 'Mês e dia de fim do período de freeze',
    'update_policies.systemUpdate.start': 'Mês/Dia inicial',
    'update_policies.systemUpdate.end': 'Mês/Dia final',
    'update_policies.systemUpdate.month': 'Mês',
    'update_policies.systemUpdate.day': 'Dia',
    'update_policies.settings.accordion.safety.untrusted_app.description': 'Instalação de aplicativos por fontes desconhecidas',
    'update_policies.settings.accordion.safety.developer_settings.title': 'Configurações do desenvolvedor.',
    'update_policies.settings.accordion.safety.developer_settings.description': 'Controle o acesso às configurações do desenvolvedor: opções de desenvolvimento e inicialização segura',
    'update_policies.settings.accordion.safety.untrusted_apps.select.disallow_install': 'Não permitir instalações de aplicativos não confiáveis',
    'update_policies.settings.accordion.safety.untrusted_apps.select.allow_install': 'Permitir instalações de aplicativos não confiáveis',
    'update_policies.settings.accordion.safety.developer_settings.select.developer_settings_disabled': 'Desativar configurações do desenvolvedor',
    'update_policies.settings.accordion.safety.developer_settings.select.developer_settings_allowed': 'Permitir configurações do desenvolvedor',
    'update_policies.applications.installation_type.kiosk': 'Kiosk',
    'password_recovery.modal.error': 'Erro ao enviar as instruções de desbloqueio para seu e-mail',
    'global.privacy_policy': 'Política de Privacidade',
    'global.terms_of_use': 'Termos de Uso do Usuário',
    'device.modal.update_policy': 'Selecione a nova política do dispositivo.',
    'menu.zero_touch': 'Zero Touch',
    'manage_policies.modal_enrollmentToken.policy': 'Política',
    'manage_policies.modal_enrollmentToken.register_token': 'Token de Registro',
    'manage_policies.modal_enrollmentToken.json_zero_touch': 'Configuração Zero Touch',
    'text_with_copy.success': '{value} foi copiado com sucesso!',
    'register_validate.token.error': 'Token inválido ou expirado',
    'edit_policy.applications.managed_config.block_categories': 'Bloqueio de Sites por Categoria',
    'edit_policy.applications.managed_config.block_categories.adult_content': 'Conteúdo Adulto',
    'edit_policy.applications.managed_config.block_categories.alcoholic': 'Conteúdo alcoólico',
    'edit_policy.applications.managed_config.block_categories.business': 'Negócio',
    'edit_policy.applications.managed_config.block_categories.chat': 'Chat',
    'edit_policy.applications.managed_config.block_categories.email': 'E-mail',
    'edit_policy.applications.managed_config.block_categories.educational': 'Educacional',
    'edit_policy.applications.managed_config.block_categories.entertainment': 'Entretenimento',
    'edit_policy.applications.managed_config.block_categories.file_shares': 'Arquivos compartilhados',
    'edit_policy.applications.managed_config.block_categories.forum': 'Fórum',
    'edit_policy.applications.managed_config.block_categories.gambling': 'Apostas',
    'edit_policy.applications.managed_config.block_categories.games': 'Jogos',
    'edit_policy.applications.managed_config.block_categories.news': 'Notícias',
    'edit_policy.applications.managed_config.block_categories.proxies': 'Procurações',
    'edit_policy.applications.managed_config.block_categories.search_engine': 'Meio de pesquisa',
    'edit_policy.applications.managed_config.block_categories.shopping': 'Compras',
    'edit_policy.applications.managed_config.block_categories.social_media': 'Redes Sociais',
    'edit_policy.applications.managed_config.block_categories.sports': 'Esportes',
    'edit_policy.applications.managed_config.block_categories.tabaco': 'Tabaco',
    'edit_policy.applications.managed_config.block_categories.technology': 'Tecnologia',
    'edit_policy.applications.managed_config.block_categories.travel': 'Viagens',
    'edit_policy.applications.managed_config.block_categories.violence': 'Violência',
    'edit_policy.applications.managed_config.block_categories.health': 'Saúde',
    'edit_policy.applications.managed_config.block_categories.jobs': 'Empregos',
    'company_info.subtitle.dataProtection': 'Encarregado pela Proteção de Dados',
    'company_info.subtitle.euRepresentative': 'Representante na União Europeia',
    'company_license.column.state': 'Status',
    'company_license.filter.state': 'Status',
    'global.version': 'Versão',
    'company_license.filter.enrolled_device': 'Dispositivo registrado',
    'edit_policy.kiosk.modal.disable': 'Desativar',
    'edit_policy.kiosk.modal.disable.title': 'Você tem certeza que quer desativar o Modo Quiosque?',
    'update_policies.passwordPolicies.select.unspecified': 'Nenhuma',
    'update_policies.applications.alert.text.add': 'Ao adicionar o {appName} na política, o sistema removerá as configurações de restrições de senha. Tem certeza que deseja adicionar o app?',
    'update_policies.applications.alert.text.remove': 'As configurações de restrições de senha foram habilitadas',
    'update_policies.passwordPolicies.description.app_block_sim': 'Não é possível alterar estas configurações enquanto a política possuir o App de Bloqueio de CHIP. Remova o app da política para poder alterar estas configurações.',
    'manage_policies.filter.policy_type.ios': 'IOS',
    'manage_policies.filter.policy_type.android_managed': 'Android',
    'manage_policies.filter.policy_type': 'Modo de Gerenciamento',
    'manage_policies.filter.kiosk.true': 'Sim',
    'manage_policies.filter.kiosk.false': 'Não',
    'manage_policies.filter.kiosk_custom_launcher_enabled': 'Modo Quiosque',
    'manage_policies.filter.play_store': 'Play Store',
    'manage_policies.filter.play_store.open': 'Aberta',
    'manage_policies.filter.play_store.restricted': 'Restrita',
    'update_policies.passwordPolicies.description.unspecified': 'Nenhuma senha é necessária.',
    'manage_policies.filter.placeholder': 'Selecione uma opção',
    'menu.audit': 'Auditoria',
    'audit.title': 'Auditoria',
    'audit.description': 'Nesta tela é possível visualizar e gerar relatórios das alterações realizadas no portal e no dispositivo.',
    'audit.start_date': 'Data Inicial',
    'audit.last_date': 'Data Final',
    'audit.users': 'Usuários Administradores',
    'audit.activity': 'Atividade',
    'audit.category': 'Categoria',
    'audit.fields_modified': 'Alterações',
    'devices.value.state_register': 'Aguardando Android Enterprise',
    'devices.value.state_android_enterprise': 'Android Enterprise',
    'company_info.modal.title': 'Verifique seu e-mail',
    'company_info.modal.text': 'Preencha o código de 6 dígitos que foi enviado para o seu e-mail',
    'company_info.modal.send_code': 'Reenviar Código',

    'consumption_profile.data.mode': 'Dados',
    'consumption_profile.dataRoaming.mode': 'Dados Roaming',
    'consumption_profile.dataRoaming.id': 'ID Dados Roaming',

    'consumption_profile.sms.mode': 'SMS',
    'consumption_profile.smsRoaming.id': 'ID SMS',
    'consumption_profile.smsRoaming.mode': 'SMS Roaming',
    'manage_groups.column.name': 'Grupo',
    'devices.infos.title': 'Gerenciar Dispositivo',
    'devices.infos.sub_title': 'Nessa tela é possível acessar as informações do dispositivo.',
    'devices.infos.card_header.title': 'Dispositivo',
    'devices.infos.imei': 'IMEI',
    'devices.infos.mode': 'Modo',
    'devices.infos.identification': 'Identificação',
    'devices.infos.serial_number': 'Número de Série',
    'devices.infos.management_mode': 'Modo de Gerenciamento',
    'devices.infos.appliedState': 'Status Aplicado',
    'devices.infos.in_accordance': 'Em Conformidade',
    'devices.infos.register_date': 'Data de Registro',
    'devices.infos.last_update': 'Data da Última Atualização',
    'devices.infos.last_communication': 'Data da Última Comunicação',
    'devices.infos.date_sync': 'Data de Sincronização da Política',
    'devices.infos.info_software': 'Informações de Software',
    'devices.infos.info_hardware': 'Informações de Hardware',
    'devices.infos.android_version': 'Versão do Android',
    'devices.infos.policy_version_applied': 'Versão da Política Aplicada',
    'devices.infos.policy_name_on_device': 'Nome da Política no Dispositivo',
    'devices.infos.policy_name_on_portal': 'Nome da Política no Portal',
    'devices.infos.intern_storage': 'Armazenamento Interno',
    'devices.infos.battery': 'Bateria',
    'devices.infos.label_dedicated': 'Dispositivo Dedicado',
    'devices.infos.label_managed': 'Totalmente Gerenciado',
    'devices.infos.label_ios': 'IOS',
    'devices.infos.label_android': 'Android',
    'devices.infos.status': 'Status',
    'devices.infos.compliance': 'Conforme',
    'devices.infos.incompliance': 'Inconforme',
    'devices.infos.status_active': 'Ativado',
    'devices.infos.status_non_active': 'Desativado',
    'devices.infos.last_update_tooltip': 'Esta data é referente a última vez em que as informações do dispositivo forma atualizadas.',
    'devices.infos.last_communication_tooltip': 'Esta data é referente a última vez em que o dispositivo se comunicou com o Portal',
    'login.modal.block_user': 'O seu acesso foi bloqueado por realizar muitas tentativas incorretas. Aguarde 15 minutos para realizar uma nova tentativa.',
    'devices.action.manage_infos': 'Gerenciar',
    'devices.infos.user': 'Usuário',
    'devices.infos.manufacturer': 'Fabricante',
    'devices.infos.phone': 'Telefone',
    'devices.infos.model': 'Modelo',
    'devices.infos.policy_sync': 'Data de Sincronização da Política',
    'devices.infos.non_accordance': 'Não Conforme',
    'audit.company.email': 'E-mail',
    'audit.company.authenticatedEmail': 'E-mail Autenticado',
    'audit.company.privacyPolicy': 'Política de Privacidade',
    'audit.company.userTerms': 'Termos de Uso do Usuário',
    'audit.company.corporateName': 'Nome',
    'audit.company.dataProtectionOfficerName': 'Proteção de Dados - Nome',
    'audit.company.dataProtectionOfficerEmail': 'Proteção de Dados - E-mail',
    'audit.company.dataProtectionOfficerPhone': 'Proteção de Dados - Telefone',
    'audit.company.euRepresentativeName': 'Representante na UE - Nome',
    'audit.company.euRepresentativeEmail': 'Representante na UE - E-mail',
    'audit.company.euRepresentativePhone': 'Representante na UE - Telefone',
    'audit.company.federalCode': 'Número de Identificação',
    'audit.company.phoneNumber': 'Telefone',
    'audit.company.contact': 'Contato',
    'audit.company.address': 'Endereço',
    'audit.company.zipCode': 'CEP',
    'audit.company.city': 'Cidade',
    'audit.company.state': 'Estado',
    'audit.company.timezone': 'Fuso Horário',
    'audit.company.createdAt': 'Data de Registro',
    'audit.company.startDayOfWeek': 'Dia de início da semana',
    'audit.company.endDayOfWeek': 'Dia de término da semana',
    'audit.company.startCycleDay': 'Início do ciclo',
    'audit.company.syncTime': 'Sincronizar a cada',
    'audit.company.notifyByEmail': 'E-mail de Aviso',
    'audit.company.emailToken': 'Token para registro de E-mail',
    'audit.company.enterpriseId': 'Código no Google',
    'audit.admin_user.remove': 'Remover',
    'audit.accordion_empty': 'Nenhuma alteração disponível para usuário',
    'register_policies.ios': 'IOS',
    'register_policies.ios.description': 'Para dispositivos de propriedade da empresa. Permite o gerenciamento de configurações e sites de dispositivos IOS e controles de política.',
    'manage_policies.data.android_managed': 'Android',
    'manage_policies.data.ios': 'IOS',
    'register_policies.operationalSystem': 'Selecione o modo de gerenciamento da política',
    'update_policies.tab.web_sites': 'Sites',
    'devices.infos.federalCode': 'Identificação',
    'devices.infos.group': 'Grupo',
    'devices.infos.name': 'Usuário',
    'application_manage.column.name': 'Nome da aplicação',
    'application_manage.column.quantity': 'Quantidade',
    'application_manage.column.consumption': 'Consumo',
    'application_manage.column.time': 'Tempo de uso',
    'devices.infos.udid': 'UDID',
    'global.operational_system': 'Sistema Operacional',
    'devices.infos.in_accordance.yes': 'Sim',
    'devices.infos.in_accordance.no': 'Não',
    'policy_copy.name': 'Nome da nova política',
    'policy_copy.name.placeholder': 'Digite o nome da nova política',
    'update_policies.settings.accordion.sync_backup': 'Sincronização e Backup',
    'update_policies.settings.accordion.sync_backup.backup.title': 'Permitir backup do iCloud',
    'update_policies.settings.accordion.sync_backup.backup.description': 'Esta configuração será retirada',
    'update_policies.settings.accordion.sync_backup.keychain.title': 'Permitir iCloud Keychain',
    'update_policies.settings.accordion.sync_backup.keychain.description': 'Esta configuração será retirada',
    'update_policies.settings.accordion.sync_backup.data.title': 'Permitir que aplicativos gerenciados armazenem dados no iCloud',
    'update_policies.settings.accordion.sync_backup.data.description': 'Os usuários podem armazenar dados de Apps Gerenciados no iCloud',
    'update_policies.settings.accordion.sync_backup.enterprise_books.title': 'Permitir backup de livros corporativos',
    'update_policies.settings.accordion.sync_backup.enterprise_books.description': 'Os usuários podem fazer o backup de livros distribuídos por suas organizações',
    'update_policies.settings.accordion.sync_backup.sync_books.title': 'Permitir sincronização de notas e destaques para livros corporativos',
    'update_policies.settings.accordion.sync_backup.sync_books.description': 'Os usuários podem sincronizar notas ou destaques com outros dispositivos por meio do iCloud',
    'update_policies.settings.accordion.sync_backup.photo.title': 'Permitir fotos do iCloud',
    'update_policies.settings.accordion.sync_backup.photo.description': 'Os usuários podem acessar as Fotos do iCloud.',
    'update_policies.settings.accordion.sync_backup.photo_menu.title': 'Permitir Meu Compartilhamento de fotos (não permitir pode causar perda de dados)',
    'update_policies.settings.accordion.sync_backup.photo_menu.description': 'As fotos do Rolo da Câmera são enviadas para o Meu Compartilhamento e as fotos e vídeos em álbuns compartilhados não podem mais ser visualizados no dispositivo.',
    'update_policies.settings.accordion.sync_backup.encrypted_backup.title': 'Forçar backups criptografados',
    'update_policies.settings.accordion.sync_backup.encrypted_backup.description': 'Os usuários podem escolher se os backups do dispositivo são armazenados em formato criptografado. Se um perfil estiver criptografado e essa opção estiver desativada, a criptografia dos backups é exigida e aplicada',
    'update_policies.settings.accordion.sharing': 'Câmera e Compartilhamento de Tela',
    'update_policies.settings.accordion.sharing.allow_remote_screen_observation.title': 'Permitir AirPlay, visualizar tela por sala de aula e compartilhamento de tela',
    'update_policies.settings.accordion.sharing.allow_remote_screen_observation.description': 'Os alunos em classes gerenciadas são avisados quando o professor usa AirPlay ou Ver Tela',
    'update_policies.settings.accordion.sharing.allow_screen_shot.title': 'Permitir captura e gravação de tela',
    'update_policies.settings.accordion.sharing.allow_screen_shot.description': 'Os usuários podem salvar uma captura ou gravação da tela',
    'update_policies.settings.accordion.sharing.allow_camera.title': 'Permitir uso de câmera',
    'update_policies.settings.accordion.sharing.allow_camera.description': 'Todas as câmeras do dispositivos estão habilitadas',
    'update_policies.settings.accordion.restriction': 'Restrições Diversas',
    'update_policies.settings.accordion.restriction.force_limit_ad_tracking.title': 'Impedir que aplicativos solicitem rastreamento',
    'update_policies.settings.accordion.restriction.force_limit_ad_tracking.description': 'Os serviços de localização com aplicativos está bloqueado',
    'update_policies.settings.accordion.restriction.allow_apple_personalized_advertising.title': 'Permitir anúncios personalizados fornecidos pela Apple',
    'update_policies.settings.accordion.restriction.allow_apple_personalized_advertising.description': 'A desativação dos anuncios personalizados limitará a capacidade da Apple em apresentar conteúdos relevantes para você. ',
    'update_policies.settings.accordion.restriction.allow_enterprise_app_trust.title': 'Permitir novos autores de aplicativos empresariais confiáveis',
    'update_policies.settings.accordion.restriction.allow_enterprise_app_trust.description': 'Esse método é seguro e o app é instalado através do portal de gerenciamento de dispositivos móveis',
    'update_policies.settings.accordion.restriction.allow_open_from_unmanaged_to_managed.title': 'Permitir documentos de origens gerenciadas em destinos não gerenciados',
    'update_policies.settings.accordion.restriction.allow_open_from_unmanaged_to_managed.description': 'Os documentos criados ou transferidos de fontes gerenciadas não podem ser abertos em destinos não gerenciados',
    'update_policies.settings.accordion.restriction.force_air_drop_unmanaged.title': 'Trate o AirDrop como destino não gerenciado',
    'update_policies.settings.accordion.restriction.force_air_drop_unmanaged.description': 'Os usuários veem o AirDrop como uma opção em um App Gerenciado.   Para que essa restrição funcione quando estiver ativada, também será desativada a opção “Permitir documentos de origens gerenciadas em destinos não gerenciados”',
    'update_policies.settings.accordion.restriction.allow_diagnostic_submission.title': 'Permitir o envio de dados de diagnóstico e uso para a Apple',
    'update_policies.settings.accordion.restriction.allow_diagnostic_submission.description': 'Ao ativar é permitido que a Apple compartilhe dados e estatísticas sobre o uso desses apps',
    'update_policies.settings.accordion.restriction.force_watch_wrist_detection.title': 'Forçar a detecção de pulso do Apple Watch',
    'update_policies.settings.accordion.restriction.force_watch_wrist_detection.description': 'Ao tirar o relógio do pulso, ele é automaticamente bloqueado para proteger os seus dados',
    'update_policies.settings.accordion.restriction.allow_in_app_purchases.title': 'Permitir compra nos aplicativos',
    'update_policies.settings.accordion.restriction.allow_in_app_purchases.description': '         Permitir que os usuários realizem compras dentro do app',
    'update_policies.settings.accordion.restriction.force_itunes_store_password_entry.title': 'Exigir senha da iTunes Store para todas as compras',
    'update_policies.settings.accordion.restriction.force_itunes_store_password_entry.description': 'As compras dentro do app e compras da iTunes Store pedem a senha da conta',
    'update_policies.settings.accordion.assistant': 'Assistente',
    'update_policies.settings.accordion.assistant.allow_assistant.title': 'Permitir Siri',
    'update_policies.settings.accordion.assistant.allow_assistant.description': 'Permite usar a Siri',
    'update_policies.settings.accordion.assistant.allow_assistant_while_locked.title': 'Permitir Siri enquanto o dispositivo estiver bloqueado',
    'update_policies.settings.accordion.assistant.allow_assistant_while_locked.description': 'A Siri responde mesmo quando o dispositivo está bloqueado',
    'update_policies.settings.accordion.assistant.allow_spotlight_internet_results.title': 'Permitir sugestões da Siri',
    'update_policies.settings.accordion.assistant.allow_spotlight_internet_results.description': 'Durante a busca, a Siri pode oferecer sugestões de apps, pessoas, localizações e outros',

    // Antigo Master
    'register_policies.managed_mode': 'Totalmente Gerenciado',
    'register_policies.managed_mode.description': 'Para dispositivos de propriedade da empresa. Permite o gerenciamento de uma ampla variedade de configurações de dispositivos Android e controles de política.',
    'manage_policies.filter.policy_type.dedicaded': 'Dispositivos Dedicados',
    'manage_policies.filter.policy_type.managed': 'Totalmente Gerenciado',
    'devices.action.more_infos': 'Gerenciar',

    // Novos
    'register_policies.android_block_sim': 'Android - Block SIM',
    'update_policies.settings.accordion.block_screen': 'Tela de Bloqueio',
    'register_policies.android_block_sim.description': 'Permite o gerenciamento de configurações dos dispositivos Android e controles de política. Adiciona automaticamente o aplicativo Block SIM, restringe as alterações de senha do dispositivo e garante o vínculo do chip ao dispositivo.',
    'update_policies.settings.accordion.show_control_center_while_locked.title': 'Mostrar central de controle na tela de bloqueio',
    'update_policies.settings.accordion.show_control_center_while_locked.description': 'Os usuários podem acessar a Central de Controle com a tela bloqueada',
    'update_policies.settings.accordion.show_notification_center_while_locked.title': 'Mostrar central de notificações na tela de bloqueio',
    'update_policies.settings.accordion.show_notification_center_while_locked.description': 'Os usuários podem visualizar o histórico de Notificações com a tela bloqueada',
    'update_policies.settings.accordion.allow_voice_dialing.title': 'Permitir discagem por voz enquanto o dispositivo estiver bloqueado',
    'update_policies.settings.accordion.allow_voice_dialing.description': '         Os usuários podem usar comandos de voz para fazer ligações quando o dispositivo estiver com a tela bloqueada',
    'update_policies.settings.accordion.show_today_view_while_locked.title': 'Mostrar visualização hoje na tela de bloqueio',
    'update_policies.settings.accordion.show_today_view_while_locked.description': 'Os usuários podem usar o gesto de passar o dedo para baixo para ver a Central de Notificações por meio da Visualização Hoje na Tela Bloqueada.',
    'update_policies.settings.accordion.allow_touch_id_face_id.title': 'Permitir que o Touch ID / Face ID desbloqueie o dispositivo',
    'update_policies.settings.accordion.allow_touch_id_face_id.description': 'Os usuários podem usar a autenticação biométrica para preenchimento automático de dados do app',
    'device.modal.label': 'Selecione a nova política - {managedMode}',
    'update_policies.tab.sites': 'Sites',
    'update_policies.sites.block_all_title': 'Bloquear Todos os Sites',
    'update_policies.sites.block_all_subtitle': 'Aqui é possível bloquear o acesso a todos os sites do seu IOS.',
    'update_policies.sites.block_keyword_title': 'URLs e Palavras-Chave Bloqueados e Liberados',
    'update_policies.sites.block_keyword_subtitle': 'Aqui é possível ver todos os sites e palavras-chave bloqueados',
    'update_policies.sites.block_category_title': 'Bloqueio de Sites por Categoria',
    'update_policies.sites.block_category_subtitle': 'Selecione as categorias de sites que o acesso será bloqueado',
    'devices.operational_system': 'Sistema Operacional',
    'devices.value.active': 'Ativo',
    'devices.value.disabled': 'Desabilitado',
    'devices.value.deleted': 'Deletado',
    'devices.value.provisioning': 'Provisionando',
    'devices.value.await_enterprise_enroll': 'Aguardando Android Enterprise',
    'devices.infos.iccid': 'ICCID',
    'menu.settings.register_policy': 'Política de Registro IOS',
    'entity.policy.defaultName': 'Política de Registro',
    'error.iosPolicy.isInitial.delete': 'Não é permitido remover política de registro.',
    'exception.setting.allowDeviceIOS': 'Configuração para iOS está desativada',
    'policy.block_sim': 'Block SIM',
    'devices.action.remove_device': 'Remover Dispositivo',
    'manage_policies.filter.management_mode': 'Modo de Gerenciamento',
    'manage_policies.filter.management_mode.android': 'Android',
    'manage_policies.filter.management_mode.ios': 'IOS',
    'manage_policies.filter.management_mode.block_sim': 'Android - Block SIM',
    'companies_list.title': 'Relação das Empresas',
    'companies_list.description': 'Loren xxx',
    'companies_list.filter.terms_of_usage': 'Aceite de Termos',
    'companies_list.filter.placeholder': 'Selecione uma opção',
    'companies_list.filter.start_at': 'Data inicial',
    'companies_list.filter.end_at': 'Data final',
    'companies_list.button.select_company': 'Acessar Portal',
    'companies_list.alert.confirm': 'Acessar',
    'companies_list.alert.text': 'Você deseja acessar {companyName} ?',
    'header.settings.help_desk.option_change_company': 'Trocar de Empresa',
    'global.unauthorized.message': 'Usuário Não Possui Privilégio Para Acessar Essa Informação',
    'devices.action.remove_screen_block': 'Remover Bloqueio de Tela',
    'devices.alert.remove_screen_block.text': 'Você deseja mesmo remover o bloqueio de tela deste dispositivo?',
    'companies_list.filter.title': 'Data de criação',
    'menu.zero_touch.manage_notes': 'Gerenciar Notas Fiscais',
    'menu.zero_touch.manage': 'Gerenciar Zero Touch',
    'manage_invoice.title': 'Gerenciar Notas Fiscais',
    'manage_invoice.description': 'Nesta tela é possível gerenciar notas fiscais e acessar detalhes para registro no Zero Touch.',
    'manage_invoice.filter': 'Data de importação da Nota Fiscal',
    'manage_invoice.start_date': 'Data inicial',
    'manage_invoice.end_date': 'Data final',
    'manage_invoice.import': 'Importar Nota Fiscal',
    'manage_invoice.menu_action.details': 'Detalhes da Nota Fiscal',
    'manage_invoice.toaster.customer_email.success': 'ID Customer cadastrado com sucesso. Acesse o e-mail informado para validar a solicitação',
    'invoice_details.warning_exit': 'Existem informações que não foram salvas e serão descartadas. Deseja continuar?.',
    'invoice_details.title': 'Detalhes da Nota Fiscal',
    'invoice_details.description': 'Nesta tela é possível visualizar os detalhes da nota fiscal',
    'invoice_details.access_key': 'Chave de Acesso',
    'invoice_details.filter.manufacturer': 'Fabricante',
    'invoice_details.filter.model': 'Modelo',
    'invoice_details.filter.imei': 'IMEI ',
    'invoice_details.filter.place_holder': 'Selecione uma opção',
    'invoice_details.button.save': 'Salvar',
    'invoice_details.button.register': 'Solicitar Registro',
    'invoice.device_status.registered': 'Registrado',
    'invoice.device_status.not_registered': 'Não registrado',
    'invoice_details.info_update.success': 'Informações salvas com sucesso',
    'manage_invoice.toaster.success': 'Nota Fiscal importada com sucesso.',
    'modal_customer_id.customerId_title': 'Vincular ID Customer',
    'modal_customer_id.customerId_description': 'Para registrar os dispositivos no Zero Touch é necessário informar o ID Custumer inscrito no revendedor {resellerName}.',
    'modal_customer_id.input.customerId.placeholder': 'Digite seu ID Customer',
    'modal_customer_id.customerId.button': 'Acessar',
    'modal_customer_id.customerId.link_button': 'Não tem um ID Customer?',
    'modal_customer_id.input.customerId': 'ID Customer',
    'modal_customer_id.customerEmail_title': 'Vincular ID Customer',
    'modal_customer_id.customerEmail_description': 'Para registrar os dispositivos no Zero Touch é necessário criar o ID Custumer. Indique o e-mail para receber as orientações de cadastro do seu ID Customer.',
    'modal_customer_id.input.customerEmail': 'E-mail',
    'modal_customer_id.input.customerEmail.placeholder': 'Digite seu E-mail',
    'modal_customer_id.customerEmail.button': 'Enviar E-mail',
    'modal_customer_id.customerEmail.link_button': 'Tem um ID Customer?',
    'modal_customer_id.button.do_later': 'Informar mais tarde',
    'invoice.register_zero_touch_success': 'Solicitação de Registro Zero Touch enviada',
    'modal_customer_id.error_message.bad_request': 'Informações inseridas estão incorretas.',
    'modal_customer_id.error_message.connection_error': 'Falha de comunicação o servidor Importa IMEI',
    'modal_customer_id.customerEmail.valid_gmail': 'Insira um Gmail válido',
    'global.copy.click_to_copy': 'Clique para copiar',
    'invoice_details.column.description': 'Descrição do produto',
    'company_info.accessCode': 'Código de Acesso',
    'dashboard.total_licenses': 'Total de licenças',
    'update_policies.settings.accordion.hardware.outgoing_beam_disabled.title': 'Bloquear envio de dados via NFC',
    'update_policies.settings.accordion.hardware.outgoing_beam_disabled.description': 'Envio de Dados Via NFC está desabilitado',
    'update_policies.settings.accordion.safety.frp_admin_emails_enable.title': 'Habilitar E-mail do Administrador para FRP',
    'update_policies.settings.accordion.safety.frp_admin_emails_enable.description': 'Exigirá o e-mail indicado para executar o factory reset',
    'update_policies.settings.accordion.safety.frp_admin_emails.title': 'E-mail do administrador para FRP',
    'update_policies.settings.accordion.safety.frp_admin_emails.description': 'Define o e-mail que será solicitado no dispositivo após a restauração de fábrica ',
    'global.valid_g_email': 'Insira um Gmail válido',
    'update_policies.settings.accordion.lockScreen.disable_all_features.title': 'Desativar todas as configurações',
    'update_policies.settings.accordion.lockScreen.disable_all_features.description': 'Todas as personalizações do bloqueio de tela estão desativadas.',
    'update_policies.settings.accordion.vpn': 'VPN',
    'update_policies.settings.accordion.vpn.vpn.title': 'Bloquear configurações de VPN',
    'update_policies.settings.accordion.vpn.vpn.description': 'Configuração da VPN está desabilitada',
    'update_policies.settings.accordion.vpn.lockdown_enabled.title': 'Bloquear conexão sem VPN',
    'update_policies.settings.accordion.vpn.lockdown_enabled.description': 'Define o tráfego de rede de acordo com a configuração da VPN.',
    'update_policies.settings.accordion.vpn.application.title': 'Aplicativo de VPN',
    'update_policies.settings.accordion.vpn.application.description': 'loren xx',
    'update_policies.frpAdminEmails': 'E-mail do Administrador para FRP',
    'update_policies.settings.accordion.safety.modify_accounts_manager.title': 'Bloquear alteração de contas',
    'update_policies.settings.accordion.safety.modify_accounts_manager.description': 'A opção de adicionar ou remover contas está desativada.',
    'update_policies.settings.accordion.user': 'Usuário',
    'update_policies.settings.accordion.user.skip_first_use_hints_enabled.title': 'Desabilitar dicas sobre primeiro uso',
    'update_policies.settings.accordion.user.skip_first_use_hints_enabled.description': 'Tutorial do usuário e outras dicas introdutórias na primeira inicialização apps está desativada.',
    'update_policies.settings.accordion.user.set_wallpaper_disabled.title': 'Desabilitar mudança de papel de parede',
    'update_policies.settings.accordion.user.set_wallpaper_disabled.description': 'Alteração do plano de fundo está desativada.',
    'update_policies.settings.accordion.user.set_user_icon_disabled.title': 'Desabilitar mudança de ícone de usuário',
    'update_policies.settings.accordion.user.set_user_icon_disabled.description': 'Alteração do ícone do usuário está desativada.',
    'update_policies.settings.accordion.user.credentials_config_disabled.title': 'Desabilitar configuração de credenciais de usuário',
    'update_policies.settings.accordion.user.credentials_config_disabled.description': 'Configuração de credenciais de usuário está desativada.',
    'update_policies.minimumApiLevel': 'Nível Mínimo da API Android',
    'update_policies.settings.accordion.network.sms_disabled.title': 'Bloquear aplicativo de mensagens SMS nativo',
    'update_policies.settings.accordion.network.sms_disabled.description': 'Aplicativo nativo para envio e recebimento de mensagens SMS está desativado. ',
    'update_policies.settings.accordion.network.cell_broadcasts_config_disabled.title': 'Desabilitar configurações de broadcasts de celular',
    'update_policies.settings.accordion.network.cell_broadcasts_config_disabled.description': 'A configuração de transmissão celular está desativada.',
    'update_policies.systemUpdate.minimum_api_level.title': 'Nível mínimo da API Android',
    'update_policies.systemUpdate.minimum_api_level.description': 'Nível mínimo da API Android permitido no dispositivo.',
    'update_policies.settings.accordion.safety.default_permission_policy.title': 'Política de permissão',
    'update_policies.settings.accordion.safety.default_permission_policy.description': 'Define política de permissão padrão para solicitações de permissão nos aplicativos.',
    'update_policies.settings.accordion.safety.default_permission_policy.select.prompt': 'Solicitar permissão',
    'update_policies.settings.accordion.safety.default_permission_policy.select.grant': 'Permitir automaticamente',
    'update_policies.settings.accordion.safety.default_permission_policy.select.deny': 'Negar automaticamente',
    'update_policies.systemUpdate.auto_date_and_time_zone.defined_by_user': 'Definido pelo Usuário',
    'update_policies.systemUpdate.auto_date_and_time_zone.automatic': 'Automático',
    'update_policies.systemUpdate.auto_date_and_time_zone.title': 'Configurar data e hora automáticas',
    'update_policies.systemUpdate.auto_date_and_time_zone.description': 'Indica se o horário automático é obrigatório, o que impede que o usuário defina manualmente a data e a hora.',
    'update_policies.settings.accordion.safety.encryption_policy.title': 'Política de criptografia',
    'update_policies.settings.accordion.safety.encryption_policy.description': 'Política de criptografia necessária.',
    'update_policies.settings.accordion.safety.encryption_policy.select.disabled': 'Desabilitado',
    'update_policies.settings.accordion.safety.encryption_policy.select.enabled_without_password': 'Ativa sem senha',
    'update_policies.settings.accordion.safety.encryption_policy.select.enabled_with_password': 'Ativa com senha',
    'menu.manage_zero_touch_register': 'Gerenciar Registro Zero Touch',
    'modal_import_invoice.title': 'Importar Nota Fiscal',
    'modal_import_invoice.import_button': 'Importar',
    'modal_import_invoice.input.label': 'Chave de Acesso',
    'modal_import_invoice.input.placeholder': 'Digite a Chave de Acesso',
    'modal_import_invoice.label.uploda_button': 'Importar XML',
    'manage_zero_touch_register.title': 'Gerenciar Registro Zero Touch',
    'manage_zero_touch_register.description': 'Nesta tela é possível gerenciar as solicitações dos registros no Zero Touch através da função "Importa IMEI"',
    'manage_zero_touch_register.error_message.bad_request': 'Não foi possível buscar os registros',
    'manage_zero_touch_register.column.company': 'Empresa',
    'manage_zero_touch_register.column.cnpj': 'CNPJ',
    'manage_zero_touch_register.column.invoice_number': 'Nota fiscal',
    'manage_zero_touch_register.column.pending_devices': 'Itens Pendentes',
    'manage_invoice.error_message.invalid_federal_code': 'Não foi possível cadastrar a empresa, CNPJ inválido',
    'manage_invoice.error_message.fail_create_company': 'Erro ao tentar cadastrar a empresa no Zero Touch',
    'manage_invoice.error_message.fail_create_invoice': 'Erro ao tentar importar a nota fiscal',
    'manage_invoice.error_message.fail_create_invoice_duplicate': 'Não foi possível cadastrar a empresa, Nota Fiscal duplicada',
    'manage_invoice.error_message.fail_create_invoice_cnpj': 'O CNPJ do remetente deve corresponder ao CNPJ informado no cadastro da empresa',
    'manage_invoice.error_message.fail_create_invoice_cnpj_unauthorized': 'O CNPJ do remetente deve corresponder ao CNPJ informado no cadastro da empresa',
    'manage_invoice.error_message.fail_create_invoice_not_found': 'Nota Fiscal não localizada, revise as informações e tente novamente',
    'manage_invoice.error_message.unauthorized': 'Usuário sem privilégio para acessar a funcionalidade Importa IMEI',

    'manage_zero_touch_register.start_date': 'Data inicial',
    'manage_zero_touch_register.end_date': 'Data final',
    'manage_zero_touch_register.zero_touch_pending': 'Pendência Zero Touch',
    'manage_zero_touch_register.zero_touch_pending.yes': 'Sim',
    'manage_zero_touch_register.zero_touch_pending.all': 'Todos',
    'invoice_details.device_status.waiting': 'Aguardando Aprovação',
    'invoice_details.device_status.reject': 'Recusado',
    'invoice_details.device_status.not_registered': 'Não registrado',
    'invoice_details.device_status.disapprove': 'Reprovado Zero Touch',
    'invoice_details.device_status.registered': 'Registrado',
    'invoice_details.device_status.approved': 'Aprovado',
    'manage_zero_touch_register.menu_action.details': 'Detalhes da solicitação',
    'zero_touch_register_details.title': 'Detalhes da Solicitação',
    'zero_touch_register_details.description': 'Nesta tela é possível gerenciar os detalhes das solicitações de registro no Zero Touch.',
    'zero_touch_register_details.error_message.bad_request': 'Não foi possível buscar os dados da nota',
    'zero_touch_register_details.company_name': 'Nome da empresa',
    'zero_touch_register_details.federal_code': 'CNPJ',
    'zero_touch_register_details.access_key': 'Chave de Acesso',
    'zero_touch_register_details.column.model': 'Modelo',
    'zero_touch_register_details.column.manufacturer': 'Fabricante',
    'zero_touch_register_details.column.imei': 'IMEI',
    'zero_touch_register_details.column.status': 'Status',
    'zero_touch_register_details.column.observation': 'Observação',
    'zero_touch_register_details.actions.approve': 'Aprovar',
    'zero_touch_register_details.actions.reject': 'Recusar',
    'invoice_details.tab.model_details': 'IMEI Modelo',
    'invoice_details.tab.devices_details': 'Todos os itens',
    'invoice_details.model_details.title': 'Informe o IMEI Modelo',
    'invoice_details.model_details.description': 'É necessário informar um IMEI correspondente a cada modelo para seguirmos com as validações e registro dos dispositivos identificados na nota fiscal',
    'invoice_details.model_details.button.submit': 'Concluir',
    'invoice_details.model_details.input.imei': 'IMEI Modelo',
    'invoice_details.model_details.input.imei_error': 'imei inválido',
    'invoice_details.model_details.input.description': 'Descriçao do item',
    'invoice_details.model_details.input.model_and_manufacturer': 'Modelo e Fabricante',
    'invoice_details.model_details.input.model_and_manufacturer.placeholder': 'informe um imei modelo',
    'invoice_details.info_update.error': 'Não foi possível atualizar as informações, por favor verifique os campos preenchidos e tente novamente',
    'invoice_details.devices_details.view_observation': 'Visualisar observação',
    'invoice_details.devices_details.observation_modal.title': 'Observação do produto',
    'invoice_details.devices_details.observation_modal.empty_observation': 'Nenhuma justificativa foi informada',
    'invoice.error_message.fail_update_item': 'Não foi possível atualizar os items, verifique as informações preenchidas e tente novamente.',
    'customer_id.error_message.fail_create_account': 'Erro ao tentar criar a conta, por favor verifique as informações e tente novamente.',
    'customer_id.error_message.invalid_email': 'Erro ao executar a ação, E-mail inválido.',
    'customer_id.error_message.invalid_customer_id': 'ID Customer informado não é valido, verifique o ID Customer informado e se está vinculado a este revendedor.',
    'customer_id.error_message.requested_email': 'requested_email',
    'customer_id.error_message.company_not_found': 'Erro ao executar a ação, empresa nao encontrada.',
    'zero_touch_register_details.alert.confirm_button.approve': 'Aprovar',
    'zero_touch_register_details.alert.message_alert.approve': 'Tem certeza que deseja aprovar todos os dispositivos selecionados?',
    'zero_touch_register_details.alert.reject_button': 'Recusar',
    'zero_touch_register_details.alert.message_alert.reject': 'Tem certeza que deseja recusar todos os dispositivos selecionados?',
    'zero_touch_register_details.filter.status_placeholder': 'Selecione um status',
    'update_policies.settings.accordion.lockScreen.maximum_time_to_lock.select.fifteen_seconds': '15 segundos',
    'update_policies.settings.accordion.lockScreen.maximum_time_to_lock.select.thirty_seconds': '30 segundos',
    'update_policies.settings.accordion.lockScreen.maximum_time_to_lock.select.one_minute': '1 minuto',
    'update_policies.settings.accordion.lockScreen.maximum_time_to_lock.select.two_minutes': '2 minutos',
    'update_policies.settings.accordion.lockScreen.maximum_time_to_lock.select.five_minutes': '5 minutos',
    'update_policies.settings.accordion.lockScreen.maximum_time_to_lock.select.ten_minutes': '10 minutos',
    'update_policies.settings.accordion.lockScreen.maximum_time_to_lock.select.thirty_minutes': '30 minutos',
    'update_policies.settings.accordion.lockScreen.maximum_time_to_lock.select.nothing': 'Nenhum',
    'update_policies.settings.accordion.lockScreen.maximum_time_to_lock.title': 'Tempo máximo para bloquear a tela',
    'update_policies.settings.accordion.lockScreen.maximum_time_to_lock.description': 'Define o tempo máximo para o usuário realizar uma atividade até o dispositivo ser bloqueado. ',
    'update_policies.settings.accordion.user.permitted_input_methods.title': 'Política para métodos de entrada',
    'update_policies.settings.accordion.user.permitted_input_methods.description': 'Se um pacote estiver definido, somente os métodos de entrada fornecidos pelos pacotes são permitidos. Se não definir um pacote, apenas métodos de entrada do sistema serão permitidos.',
    'update_policies.settings.accordion.lock_screen': 'Tela de Bloqueio',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_camera.title': 'Desativar câmera na tela de bloqueio',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_camera.description': 'A câmera é desativa na tela de bloqueio.',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_notifications.title': 'Desativar notificações',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_notifications.description': 'A exibição de todas as notificações em telas de proteção de teclado seguras são desativadas.',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_unredacted_notifications.title': 'Desativar notificações editadas',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_unredacted_notifications.description': 'As notificações não editadas em telas de proteção seguras são desativadas.',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_trust_agents.title': 'Desativar agente de confiança',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_trust_agents.description': 'Ignora o estado do agente de confiança em telas de proteção de teclado seguras.',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_finger_print.title': 'Desativar sensor de impressão digital',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_finger_print.description': 'O sensor de impressão digital em telas de proteção de teclado está desativado.',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_remote_input.title': 'Desativar edição de texto em notificações',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_remote_input.description': 'Em dispositivos com Android 6 ou versões anteriores, desativa a entrada de texto em notificações em telas de bloqueio de teclado. Não tem efeito no Android 7 e versões mais recentes.',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_face_auth.title': 'Desativar autenticação facial',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_face_auth.description': 'A autenticação facial em telas de proteção seguras é desativada.',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_iris_auth.title': 'Desativar autenticação da íris',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_iris_auth.description': 'A autenticação da íris em telas de proteção de teclado seguras é desativada.',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_biometric_auth.title': 'Desativar autenticação biométrica',
    'update_policies.settings.accordion.lockScreen.disable_lock_screen_biometric_auth.description': 'A autenticação biométrica nas telas de proteção de teclado seguras é desativada.',

    'global.show_all': 'Mostrar todos',
    'global.show_less': 'Mostrar menos',
    'invoice_details.device_status.approved_zero_touch': 'Registrado Zero Touch',
    'update_policies.settings.accordion.user.permitted_accessibility_services.title': 'Política para serviços de acessibilidade',
    'update_policies.settings.accordion.user.permitted_accessibility_services.description': 'Define os serviços de acessibilidade permitidos. Se um pacote estiver definido, somente os serviços de acessibilidade nessa lista e o serviço de acessibilidade integrado do sistema poderão ser usados. Se não definir um pacote, somente os serviços de acessibilidade integrados do sistema poderão ser usados.',
    'update_policies.settings.accordion.network.recommended_global_proxy.title': 'Configuração de Proxy ',
    'update_policies.settings.accordion.network.recommended_global_proxy.description': 'Selecione a opção de proxy que possui. Para proxy manual, inclua a informação de hosts e portas de acesso para internet.  Se possui uma solução de proxy automático (PAC), inclua a URL do servidor.',
    'update_policies.settings.accordion.network.recommended_global_proxy.manual': 'Configuração Manual',
    'update_policies.settings.accordion.network.recommended_global_proxy.automatic': 'Configuração Automática - PAC',
    'update_policies.settings.accordion.network.recommended_global_proxy.pac_uri': 'PAC URL',
    'update_policies.maximumTimeToLock': 'Tempo Máximo Para Desligar a Tela',
    'update_policies.settings.accordion.network.recommended_global_proxy.host': 'Host',
    'update_policies.settings.accordion.network.recommended_global_proxy.port': 'Porta',
    'update_policies.settings.accordion.network.recommended_global_proxy.ignored_hosts': 'Lista de Hosts Ignorados',
    'update_policies.settings.accordion.lockScreen.stay_on_plugged_modes.title': 'Manter dispositivo sempre ligado',
    'update_policies.settings.accordion.lockScreen.stay_on_plugged_modes.description': 'Define o modo da bateria conectada em que o dispositivo permanecerá ligado. Ao usar essa configuração, é recomendável que a configuração "Tempo máximo para bloquear a tela" esteja com a opção "Nenhum" selecionada para que o dispositivo não seja bloqueado enquanto permanecer ligado.',
    'update_policies.settings.accordion.lockScreen.stay_on_plugged_modes.select.ac': 'AC - Carregador CA',
    'update_policies.settings.accordion.lockScreen.stay_on_plugged_modes.select.usb': 'USB - Porta USB',
    'update_policies.settings.accordion.lockScreen.stay_on_plugged_modes.select.wireless': 'WIRELESS - Sem fio',
    'update_policies.settings.accordion.network.recommended_global_proxy.none': 'Nenhum',
    'update_policies.settings.accordion.user.account_types_with_management_disabled.title': 'Tipos de contas com gerenciamento desativado',
    'update_policies.settings.accordion.user.account_types_with_management_disabled.description': 'Tipos de conta que não podem ser gerenciados pelo usuário.',
    'update_policies.settings.accordion.user.account_types_with_management_disabled.placeholder': 'Selecione ou adicione uma opção',
    'global.creatable.add_new_option': 'Adicionar nova opção: {inputValue}',
    'update_policies.settings.accordion.safety.private_key_selection_enabled.title': 'Habilitar seleção de chave privada',
    'update_policies.settings.accordion.safety.private_key_selection_enabled.description': 'Permite mostrar a interface no dispositivo para que o usuário escolha um alias de chave privada se não houver regras de chaves privadas definidas. Em dispositivos abaixo do Android 9, a configuração pode deixar as chaves empresariais vulneráveis.',
    'update_policies.settings.accordion.vpn.lockdown_enabled.placeholder': 'Selecione um aplicativo',
    'update_policies.settings.accordion.vpn.package_name.title': 'Aplicativos de VPN',
    'update_policies.settings.accordion.vpn.package_name.description': 'Configuração para uma conexão VPN sempre ativa. Adicione o aplicativo VPN ao perfil para vê-lo listado.',
    'update_policies.alwaysOnVpnPackage.packageName': 'Aplicativos de VPN',
    'update_policies.permittedInputMethods': 'Política para métodos de entrada',
    'update_policies.permittedAccessibilityServices': 'Política para serviços de acessibilidade',
    'update_policies.recommendedGlobalProxy.pacUri': 'Pac URL',
    'update_policies.permittedAccessibilityServices.arr': 'Política para serviços de acessibilidade',
    'update_policies.permittedInputMethods.arr': 'Política para métodos de entrada',
    'input_keywords.error.key_existing_alert': 'Palavra chave já existente.',
    'input_keywords.error.key_not_valid_alert': 'Palavra chave inserida não é um valor válido.',
    'update_policies.keyword_error.start_only_with_letters': 'Palavra chave só pode iniciar com letras',
    'update_policies.settings.accordion.safety.choose_private_key_rule.title': 'Regras de escolha de chave privada',
    'update_policies.settings.accordion.safety.choose_private_key_rule.description': 'Identifique a regra que permite a leitura da chave privada.',
    'update_policies.settings.accordion.safety.choose_private_key_rule.add_button': '+ Adicionar',
    'update_policies.settings.accordion.safety.choose_private_key_rule.add_modal.title': 'Adicionar Regra de Escolha de Chave Privada',
    'update_policies.settings.accordion.safety.choose_private_key_rule.add_modal.private_key_alias': 'Nome (Alias)',
    'update_policies.settings.accordion.safety.choose_private_key_rule.add_modal.url_pattern': 'URLPattern',
    'update_policies.settings.accordion.safety.choose_private_key_rule.add_modal.save': 'Salvar',
    'update_policies.settings.accordion.safety.choose_private_key_rule.add_modal.package_names': 'Aplicativo',
    'update_policies.settings.accordion.safety.choose_private_key_rule.add_modal.add_package_name': 'Adicionar',
    'update_policies.settings.accordion.safety.choose_private_key_rule.add_modal.same_alias_error': 'Já existe uma regra com este nome',
    'update_policies.settings.accordion.safety.choose_private_key_rule.edit_button': 'Editar',
    'update_policies.settings.accordion.safety.choose_private_key_rule.delete_button': 'Remover',
    'update_policies.choosePrivateKeyRules.arr.urlPattern': 'URLPattern',
    'update_policies.choosePrivateKeyRules.arr.privateKeyAlias': 'Nome (Alias)',
    'update_policies.choosePrivateKeyRules.arr': 'Regras de Escolha de Chave Privada',
    'update_policies.recommendedGlobalProxy.port': 'Porta',
    'update_policies.recommendedGlobalProxy.host': 'Host',
    'modal_customer_id.customerId.subscribe_button_text': 'Clique aqui para se inscrever.',
    'customer_id.error_message.fail_create_invoice_without_device': 'Não foi identificado dispositivo móvel na nota fiscal. Verifique e tente novamente.',
    'update_policies.settings.accordion.network.recommended_global_proxy.empty_list': 'Nenhum Proxy Adicionado.',
    'update_policies.settings.accordion.user.permitted_input_methods.empty_list': 'Nenhum método de entrada adicionado.',
    'update_policies.settings.accordion.user.permitted_accessibility_services.empty_list': 'Nenhum serviço de acessibilidade adicionado.',
    'modal_customer_id.customerToken_title': 'Vincular ID Customer',
    'modal_customer_id.customerToken_description': 'Informe o Token enviado para o e-mail {email} ',
    'modal_customer_id.customerToken.button': 'Validar',
    'modal_customer_id.customerToken.link_button': 'Alterar e-mail',
    'modal_customer_id.customerToken.secondary_button': 'Enviar e-mail novamente',
    'modal_customer_id.input.customerToken': 'Token',
    'global.valid_email': 'Insira um E-mail válido',
    'manage_invoice.error_message.server_error': 'Não foi possível conectar ao serviço Importa IMEI',
    'manage_invoice.error_message.default_error': 'Não foi possível conectar ao serviço Importa IMEI',
    'invoice_details.approve_modal.title': 'Tem certeza que deseja aprovar todos os dispositivos selecionados?',
    'customer_id.error_message.invalid_token': 'Token informado é inválido, tente novamente',
    'invoice_details.model_details.input.manufacturer': 'Fabricante',
    'invoice_details.model_details.input.model': 'Modelo',
    'zero_touch_register_details.error_message.fail_device_invalid_imei': 'IMEI informado está inválido',
    'zero_touch_register_details.error_message.fail_device_invalid_imei_model': 'IMEI informado está diferente do IMEI Modelo',
    'zero_touch_register_details.error_message.fail_device_invalid_model': 'Modelo informado está inválido',
    'zero_touch_register_details.error_message.fail_device_duplicate_imei': 'IMEI informado já existe',
    'manage_zero_touch_register.release_date': 'Data de envio do IMEI',
    'modal_import_invoice.error_message.invalid_xml': 'Arquivo da nota fiscal inválido',
    'modal_import_invoice.error_message.invalid_format': 'É necessário importar um arquivo .XML',
    'modal_import_invoice.error_message.default': 'Ocorreu um erro ao tentar importar a nota fiscal',
    'register_policies.ios_supervised': 'IOS - Supervisionado',
    'register_policies.ios_supervised.description': 'Para dispositivos de propriedade da empresa. Permite o gerenciamento de configurações e a criações de restrições em dispositivos iOS provisionados no modo Supervisionado.',
    'register_policies.ios_supervised.profile': 'Perfil',
    'register_policies.ios_supervised.upload_button': 'Fazer upload',
    'register_policies.ios_supervised.profile_input.placeholder': 'Faça upload de um arquivo de configuração',
    'register_policies.ios_supervised.profile_input.error_message.invalid_format': 'É necessário importar um arquivo .mobileconfig',
    'register_policies.ios_supervised.profile_input.error_message.invalid_mobileconfig': 'Arquivo de configuração inválido',
    'register_policies.ios_supervised.profile_input.error_message.default': 'Ocorreu um erro ao tentar importar arquivo de configuração',
    'register_policies.ios_supervised.profile_input.success': 'Arquivo carregado com sucesso',
    'update_policies.tab.profile': 'Perfil',
    'update_policies.file': 'Arquivo de configuração do Perfil',
    'edit_policy.ios.profile.profile_file_input': 'Arquivo de configuração',
    'manage_policies.filter.management_mode.ios_supervised': 'IOS Supervisionado',
    'menu.settings.networks.list': 'Gerenciar Redes Wi-fi',
    'manage_networks.entity': 'Rede Wi-fi',
    'manage_networks.title': 'Gerenciar Redes Wi-fi',
    'manage_networks.description': 'Define as configurações avançadas da rede Wi-fi',
    'zero_touch_register_details.success.approved': 'Dispositivo Registrado no Zero Touch.',
    'zero_touch_register_details.success.refused': 'Registro de dispositivo recusado.',
    'network_settings_details.title': 'Detalhes da Configuração Wi-fi',
    'network_settings_details.description': 'Defina as configurações da rede Wi-fi que precisa ser gerenciada',
    'network_settings_details.name': 'Nome da configuração',
    'network_settings_details.ssid': 'SSID',
    'network_settings_details.passphrase': 'Senha',
    'network_settings_details.auto_connect': 'Conectar Automaticamente ',
    'network_settings_details.security': 'Tipo de Segurança',
    'network_settings_details.security.none': 'Nenhum',
    'network_settings_details.eap_inner': 'Autenticação Inner',
    'network_settings_details.eap_outer': 'Autenticação Outer',
    'network_settings_details.certificate_client.certificate': 'Certificado do Cliente',
    'network_settings_details.certificate_server.certificate': 'Certificado do Servidor',
    'network_settings_details.certificate_client.type': 'Tipo de Certificado do Cliente',
    'network_settings_details.select_placeholder': 'Selecione uma opção',
    'network_settings_details.certificate_server.type': 'Tipo de Certificado do Servidor',
    'network_settings_details.button.save': 'Salvar',
    'network_settings_details.error_message.invalid_format': 'O arquivo carregado não é do tipo {fileType}',
    'file_upload.message.success': 'Arquivo carregado com sucesso!',
    'file_upload.message.error': 'Erro ao tentar carregar o arquivo!',
    'file_upload.button': 'Carregar arquivo',
    'file_upload.placeholder': 'Faça upload de um arquivo',
    'network_settings_details.success_message': 'As configurações da rede wi-fi foram cadastradas com sucesso',
    'manage_networks.open_destroy_message.text_default': 'Tem certeza que deseja remover esse item?',
    'manage_networks.open_destroy_message.network_settings_bound_text': 'Esta configuração de rede Wi-fi está vinculada à política, tem certeza que deseja remover esse item? Importante! Ação não poderá ser desfeita.',
    'register_policies.operational_system.android': 'Android',
    'register_policies.operational_system.ios': 'IOS',
    'manage_zero_touch_register.zero_touch_pending.pendency': 'Pendências',
    'manage_zero_touch_register.zero_touch_status': 'Status',
    'update_policies.settings.accordion.wifi': 'Configuração Rede Wi-Fi',
    'update_policies.settings.accordion.wifi.description': 'Define a configuração da rede wi-fi permitida, viabilizando a conexão automática do dispositivo, respeitando sempre o cadastro da configuração. Se não houver configuração de rede wi-fi definida permitirá a conexão manual do dispositivo em outras redes wi-fi.',
    'update_policies.settings.accordion.wifi.empty_message': 'Nenhuma rede Wi-fi vinculada',
    'zero_touch_register_details.error_message.fail_approve_device': 'Falha ao aprovar dispositivos',
    'register_policies.android_work_profile': 'Android - Work Profile',
    'register_policies.android_work_profile.description': 'Para dispositivos de propriedade pessoal. Permite a criação e gerenciamento do perfil de trabalho, preservando a privacidade e segurança no perfil pessoal e garantindo o melhor desempenho.',
    'manage_policies.filter.management_mode.work_profile_android': 'Android - Work profile',
    'devices.infos.operational_system': 'Sistema Operacional',
    'register_policies.work_profile_android': 'Android - Work profile',
    'manage_admin.toaster.success.delete': 'Administrador removido com sucesso',
    'update_policies.settings.accordion.password_policy.device': 'Restrições de Senha - Dispositivo',
    'update_policies.settings.accordion.password_policy.work_profile': 'Restrições de Senha - Perfil de Trabalho',
    'update_policies.passwordPolicies.applied_device_settings.title': 'Permitir o usuário manter a mesma senha definida para o “Dispositivo” no “Perfil de Trabalho” ou escolher uma nova senha para o "Perfil de Trabalho".',
    'update_policies.passwordPolicy.deviceScope.passwordHistoryLength': 'Histórico máximo de senhas que o usuário não poderá utilizar novamente',
    'update_policies.passwordPolicy.deviceScope.maximumFailedPasswordsForWipe': 'Máximo de senhas incorretas antes de executar wipe',
    'update_policies.passwordPolicy.deviceScope.passwordExpirationTimeout': 'Tempo limite de expiração da senha (dias)',
    'update_policies.passwordPolicy.deviceScope.requirePasswordUnlock': 'Exigir desbloqueio de senha',
    'update_policies.passwordPolicy.deviceScope.passwordMinimumLength': 'Comprimento mínimo da senha',
    'update_policies.passwordPolicy.deviceScope.passwordMinimumLetters': 'Número mínimo de letras exigidas na senha',
    'update_policies.passwordPolicy.deviceScope.passwordMinimumNonLetter': 'Número mínimo de caracteres que não sejam letras (dígitos numéricos ou símbolos) necessários na senha',
    'update_policies.passwordPolicy.deviceScope.passwordMinimumNumeric': 'Número mínimo de dígitos numéricos necessários na senha',
    'update_policies.passwordPolicy.deviceScope.passwordMinimumSymbols': 'Número mínimo de símbolos necessários na senha',
    'update_policies.passwordPolicy.deviceScope.passwordMinimumUpperCase': 'Número mínimo de letras maiúsculas exigidas na senha',
    'update_policies.passwordPolicy.deviceScope.passwordMinimumLowerCase': 'Número mínimo de letras minúsculas exigidas na senha',
    'update_policies.passwordPolicy.profileScope.passwordHistoryLength': 'Comprimento de histórico de senhas',
    'update_policies.passwordPolicy.profileScope.maximumFailedPasswordsForWipe': 'Máximo de senhas incorretas antes de executar wipe',
    'update_policies.passwordPolicy.profileScope.passwordExpirationTimeout': 'Tempo limite de expiração da senha (dias)',
    'update_policies.passwordPolicy.profileScope.requirePasswordUnlock': 'Exigir desbloqueio de senha',
    'update_policies.passwordPolicy.profileScope.passwordMinimumLength': 'Comprimento mínimo da senha',
    'update_policies.passwordPolicy.profileScope.passwordMinimumLetters': 'Número mínimo de letras exigidas na senha',
    'update_policies.passwordPolicy.profileScope.passwordMinimumNonLetter': 'Número mínimo de caracteres que não sejam letras (dígitos numéricos ou símbolos) necessários na senha',
    'update_policies.passwordPolicy.profileScope.passwordMinimumNumeric': 'Número mínimo de dígitos numéricos necessários na senha',
    'update_policies.passwordPolicy.profileScope.passwordMinimumSymbols': 'Número mínimo de símbolos necessários na senha',
    'update_policies.passwordPolicy.profileScope.passwordMinimumUpperCase': 'Número mínimo de letras maiúsculas exigidas na senha',
    'update_policies.passwordPolicy.profileScope.passwordMinimumLowerCase': 'Número mínimo de letras minúsculas exigidas na senha',
    'edit_policy.kiosk.wallpaper.title': 'Configurações de papel de parede',
    'dropzone.drop_text': 'Arraste o arquivo aqui',
    'dropzone.or': 'ou',
    'dropzone.button': 'Carregar arquivos',
    'edit_policy.kiosk.manage_configuration': 'Configurações gerenciadas',
    'edit_policy.kiosk.kiosk_wallpaper': 'Endereço do papel de parede',
    'edit_policy.kiosk.kiosk_wallpaper.errors.default': 'Não foi possível fazer o upload da imagem selecionada',
    'edit_policy.kiosk.kiosk_wallpaper.errors.server_limit': 'O tamanho da imagem excedeu o limite permitido',
    'dropzone.rejected_file': 'Formato de arquivo ou quantidade não permitida',
    'update_policies.applications.installation_type.kiosk.system_app': 'Sistema',
    'update_policies.applications.table.column.show_in_kiosk_mode': 'Exibir no Quiosque',
    'update_policies.modal_enterprise_system_apps.title': 'Aplicativos do sistema',
    'update_policies.applications.menu_action_button.system': 'Sistema',
    'update_policies.applications.menu_action_button.store': 'Playstore',
    'company_info.token': 'Código de confirmação de e-mail',
    'update_policies.applications.table.origin.PLAYSTORE': 'PLAYSTORE',
    'update_policies.applications.table.origin.SYSTEM': 'SISTEMA',
    'update_policies.applications.table.origin.WEB': 'WEB',
    'update_policies.applications.table.origin.PARTICULAR': 'PARTICULAR',
    'error.generic_error': 'Erro ao fazer a solicitação, tente novamente.',
    'invoice_details.import_devices_imei.error': 'Há mais IMEIs do que dispositivos identificados na nota fiscal',
    'invoice_details.import_devices_imei.size.error': 'Tamanho de arquivo excedido!',
    'invoice_details.import_devices_imei.success': "IMEI's importados com sucesso!",
    'invoice_details.model_details.upload_button': 'Importar',
    'zero_touch.accept_terms.title': 'Termo de Responsabilidade',
    'zero_touch.accept_terms.accept': 'Aceitar',
    'zero_touch.accept_terms.decline': 'Recusar',
    'zero_touch.accept_terms.loading_message': 'Carregando termos',
    'zero_touch.accept_terms.errors.get_description': 'Não foi possível carregar os termos',
    'zero_touch.accept_terms.back': 'Voltar',
    'zero_touch.accept_terms.errors.get_is_accepted': 'Não foi possível verificar o aceite dos termos',
    'zero_touch.accept_terms.errors.reply': 'Não foi possível responder aos termos',
    'error_boundary.title': 'Algo deu errado',
    'error_boundary.description': 'Ocorreu algum erro ao processar a sua solicitação, tente novamente.',
    'error_boundary.action.back': 'Voltar para o início',
    'error_boundary.action.reload': 'Recarregar página',
    'register_policies.policy': 'Política',
    'general_config.values.policyBoolean.true': 'Aceito',
    'general_config.values.policyBoolean.false': 'Recusado',
    'device_location.address_error': 'Não foi possível obter a localização do dispositivo',
    'device_location.user': 'Usuário',
    'table.action.empty_export_message': 'Não há informações registradas para gerar relatório',
    'edit_group.report_title': 'Usuários do grupo',
    'general_config.form.cycle_start.title': 'Início do Ciclo',
    'invoice_details.import_devices_imei.divergent_information.error': 'Existem informações divergentes, necessário verificar',
    'invoice_details.import_devices_imei.invalid_format.error': 'Formato do arquivo inválido',
    'invoice_details.import_devices_imei.template_download': 'Baixar modelo',
    'general_config.form.sync_time.title': 'Sincronização das Informações',
    'manage_invoice.error_message.fail_invalid_cnpj_reseler': 'Emissor da Nota Fiscal não autorizado.',
    'edit_policy.kiosk.kiosk_wallpaper.errors.invalid_size': 'O tamanho da imagem excedeu o limite permitido',
    'devices.advanced_filters': 'Filtros Avançados',
    'edit_policy.kiosk.kiosk_icon_font_color': 'Selecionar cor',
    'company_info.modal.send_code_success': 'Código reenviado com sucesso.',
    'manage_admin.toaster.success.edit_with_email': 'Usuário administrador alterado com sucesso, um e-mail foi enviado para o usuário com as instruções de acesso.',
    'login.modal.title.not_validated': 'Verifique o seu e-mail!',
    'login.modal.not_validated': 'Para garantir a segurança do seu acesso, é necessário cadastrar uma nova senha. As instruções necessárias foram enviadas para o seu e-mail. Se o e-mail não estiver correto, entre em contato com o suporte.',
    'devices.policy': 'Política',
    'invoice_details.import_devices_imei.generic.error': 'Erro ao fazer a solicitação, tente novamente.',
    'update_policies.settings.accordion.remote_view': 'Acesso Remoto',
    'update_policies.settings.accordion.remote_view.allow_remote_view.title': 'Habilitar acesso remoto',
    'update_policies.settings.accordion.remote_view.allow_remote_view.description': 'Configuração de acesso remoto ao dispoitivo está habilitada',
    'update_policies.modal_enterprise_apps.device_filter': 'Dispositivo',
    'update_policies.modal_enterprise_apps.device_filter.placeholder': 'Selecione o dispositivo',
    'manual.open_manual': 'Manual de Apoio',
    'update_policies.modal_enterprise_apps.device_filter.tooltip': 'Os dispositivos relacionados correspondem a dispositivos vinculados a política',
    'devices.action.remote_view': 'Controle Remoto',
    'remote_view.start_connection': 'Iniciar conexão',
    'remote_view.stop_connection': 'Encerrar conexão',
    'remote_view.title': 'Acesso Remoto',
    'remote_view.description': 'Nesta tela é possível conectar com o dispositivo.',
    'remote_view.success': 'Conexão feita com sucesso!',
    'update_policies.settings.accordion.safety.modify_accounts_manager.select.none': '',
    'update_policies.settings.accordion.safety.modify_accounts_manager.select.google': '',
    'update_policies.settings.accordion.safety.modify_accounts_manager.select.all': '',
    'remote_view.error': 'Occoreu um erro ao tentar conectar com o device.',
    'update_policies.applications.table.column.package_name': 'Nome do Pacote',
    'enterprise_applications.column.package_name': 'Nome do Pacote',
    'import.file.error.size': 'Tamanho de arquivo excedido!',
    'import.file.error.invalid_format': 'Formato do arquivo inválido!',
    'import.file.error.fail_read_file': 'As informações do arquivo modelo são inválidas, revise o arquivo e tente novamente!',
    'import.file.error.fail_to_process_file': 'Ocorreu um problema ao processar o arquivo!',
    'manage_groups.success.import': 'Por favor aguarde a importação das informações! O sistema exibirá uma notificação na parte superior da tela ao concluir, onde poderá revisar o resultado da importação.',
    'manage_groups.error.import': 'As informações do arquivo modelo são inválidas, revise o arquivo e tente novamente!',
    'manage_groups.error.import.device_in_other_group': 'Dispositivo vinculado em outro grupo',
    'manage_groups.error.import.number_not_found': 'Número não existe na empresa',
    'manage_groups.import_groups.button_title': 'Importar',
    'manage_groups.template_model.button_title': 'Baixar Modelo',
    'manage_groups.menu.button_title': 'Cadastrar em lote',
    'batch_template_import.file_name': 'Importar {entity} em lote',
    'batch_template_import.groups': 'Grupo',
    'batch_template_import.groups.columns.name': 'GRUPO',
    'batch_template_import.groups.columns.device': 'DISPOSITIVO',
    'notification.batch_import.group.title': 'Cadastro de grupo em lote',
    'notification.list.import_button.url': 'Ver detalhes de erros',
    'notification.badge.not_read.title': 'Não lido',
    'notification.import_list.title': 'Importação:',
    'import_groups.column.group': 'Grupo',
    'import_groups.column.device': 'Dispositivo',
    'import_groups.column.error': 'Erro',
    'modal_notification_details.title': 'Detalhes da notificação',
    'update_policies.settings.accordion.safety.modify_accounts_disabled.title': 'Bloquear alteração de contas',
    'update_policies.settings.accordion.safety.modify_accounts_disabled.description': 'A opção de adicionar ou remover contas está desativada.',
    'update_policies.settings.accordion.safety.account_types_with_management_disabled.title': 'Tipos de contas com gerenciamento desativado',
    'update_policies.settings.accordion.safety.account_types_with_management_disabled.description': 'Tipos de conta que não podem ser gerenciados pelo usuário.',
    'update_policies.settings.accordion.safety.account_types_with_management_disabled.placeholder': 'Selecione ou adicione uma opção',
    'devices.filter.start_at': 'Data Inicial de Registro',
    'devices.filter.end_at': 'Data Final de Registro',
    'update_policies.applications.menu_action_button.manual': 'Manual',
    'update_policies.modal_add_manual_system_apps.title': 'Adicionar Aplicativos Manualmente',
    'update_policies.modal_add_manual_system_apps.add_button': 'Adicionar',
    'update_policies.modal_add_manual_system_apps.input_name': 'Nome',
    'update_policies.modal_add_manual_system_apps.input_name.placeholder': 'Informe um nome',
    'update_policies.modal_add_manual_system_apps.input_package_name': 'Nome do pacote',
    'update_policies.modal_add_manual_system_apps.input_package_name.placeholder': 'Informe um nome de pacote',
    'update_policies.modal_add_manual_system_apps.input_package_name.error': 'App já foi adicionado. Revise as informações!',
    'update_policies.applications.table.origin.MANUAL': 'MANUAL',
    'update_policies.manual_applications.arr.package_name': 'Nome do pacote',
    'general_config.inactivity_time': 'Tempo limite de inatividade',
    'general_config.inactivity_time.5min': '5 min',
    'general_config.inactivity_time.10min': '10 min',
    'general_config.inactivity_time.20min': '20 min',
    'general_config.inactivity_time.30min': '30 min',
    'inactivity_alert.after_redirect': 'Sua sessão expirou!',
    'inactivity_alert.confirm': 'Confirmar',
    'inactivity_alert.title': 'O seu acesso expirará em {seconds} segundos!',
    'inactivity_alert.description': 'Confirme se você deseja continuar conectado',
    'update_policies.modal_enterprise_apps.manufacturer_filter': 'Fabricante',
    'update_policies.modal_enterprise_apps.manufacturer_filter.placeholder': 'Selecione a fabricante',
    'import.file.error.fail_empty_file': 'Arquivo importado não possui informações!',
    'login_confirmation.info': 'Para garantir a segurança do seu acesso, é necessário confirmar as seguintes informações. Preencha o código de 6 dígitos que foi enviado para o seu e-mail',
    'login_confirmation.error.invalid_code': 'Código de confirmação informado é inválido, tente novamente',
    'login_confirmation.error.default': 'Ocorreu um erro ao tentar fazer a sua solicitação, tente novamente',
    'company_info.subtitle.abm': 'Apple Business Manager',
    'company_info.field.contentTokenABM': 'Token de Conteúdo',
    'devices.infos.emailAppleBusinessManager': 'E-mail do usuário na Apple Business Manager (ABM)',
    'devices.infos.ios_error.invalid_email': 'O e-mail informado ainda não possui um usuário cadastrado na Apple Business Manager. Acesse a conta da sua empresa no Apple Business Manager para realizar o cadastro e depois tente novamente vincular o e-mail nesta tela.',
    'company_info.abm.info': 'Para obter o "Token de Conteúdo" acesse a conta do Apple Business Manager (ABM) da sua empresa.',
    'devices.infos.ios_error.not_found_content_token_abm': 'Não foi encontrado Token Apple Business Manager (ABM) no cadastro da sua empresa',
    'devices.alert.restart_device.text': 'Você deseja mesmo reiniciar este dispositivo?',
    'devices.alert.turn_off_screen.text': 'Você deseja mesmo desligar a tela deste dispositivo?',
    'update_policies.manual_applications.arr.name': 'Nome do aplicativo',
    'global.about': 'Sobre',
    'modal_product_version.actual_version': 'Versão Atual: {productVersion} ({frontVersion}, {backVersion})',
    'devices.action.more_information': 'Mais Informações',
    'devices.action.device_information': 'Informações do dispositivo',
    'update_policies.settings.accordion.work': 'Trabalho',
    'update_policies.settings.accordion.work.lock_apps_outside_working_hours.title': 'Bloquear dispositivo fora do horário de trabalho',
    'update_policies.settings.accordion.work.lock_apps_outside_working_hours.description': 'O bloqueio total do dispositivo quando estiver fora da do horário de trabalho está ativado.',
    'update_policies.settings.accordion.work.days_of_work.title': 'Dias de trabalho',
    'update_policies.settings.accordion.work.days_of_work.description': 'Define os dias de trabalho da semana',
    'update_policies.settings.accordion.work.start_day_of_work': 'Dia início',
    'update_policies.settings.accordion.work.end_day_of_work': 'Dia fim',
    'update_policies.settings.accordion.work.work_time.title': 'Horário de trabalho',
    'update_policies.settings.accordion.work.work_time.description': 'Define o horário de trabalho',
    'update_policies.settings.accordion.work.work_time.tooltip': 'Ao deixar os campos em branco o sistema considera o período inteiro do dia.',
    'update_policies.settings.accordion.work.start_working_time': 'Hora início',
    'update_policies.settings.accordion.work.work_time.placeholder': 'Selecione',
    'update_policies.settings.accordion.work.end_working_time': 'Hora fim',
    'devices.infos.details': 'Detalhes',
    'devices.infos.install': 'Instalação',
    'devices.infos.hardware': 'Hardware',
    'menu.enterprise_applications.external_apps': 'Gerenciar Aplicativos Externos',
    'external_apps.title': 'Gerenciar Aplicativos Externos',
    'external_apps.description': 'Lorem Ipsum',
    'external_apps.start_date': 'Data de Criação Inicio',
    'external_apps.end_date': 'Data de Criação Fim',
    'external_apps.new': 'Novo Aplicativo',
    'external_apps.entity': 'Aplicativo',
    'external_app_edit.create_title': 'Novo Aplicativo',
    'external_app_edit.edit_title': 'Editar Aplicativo',
    'external_app_edit.create_description': 'Nesta tela é possível enviar um novo aplicativo para os dispositivos.',
    'external_app_edit.edit_description': 'Nesta tela é possível editar um aplicativo',
    'external_app_edit.create_send_label': 'Salvar',
    'external_app_edit.edit_send_label': 'Atualizar',
    'external_app_edit.input.url': 'URL para download',
    'external_app_edit.invalid_url': 'Insira uma url válida',
    'confirm_welcome_email.title': 'Bem vindo!',
    'confirm_welcome_email.description': 'Por favor confirme todas as informações da empresa e preencha o e-mail do administrador, para onde o e-mail de boas vindas será enviado. Após o envio essa ação não poderá ser realizada novamente.',
    'confirm_welcome_email.corporate_reason': 'Razão Social',
    'confirm_welcome_email.externalCode': 'CNPJ',
    'confirm_welcome_email.contact': 'Contato',
    'confirm_welcome_email.phone': 'Telefone do Contato',
    'confirm_welcome_email.admin_email': 'E-mail do administrador da empresa',
    'confirm_welcome_email.confirm': 'Enviar E-mail de Boas Vindas',
    'confirm_welcome_email.error.invalid_email': 'Insira um e-mail válido',
    'app_managed_configurations.add_bundle_array_button': 'Adicionar configuração',
    'app_managed_configurations.remove_bundle_array_button': 'Remover configuração',

    'edit_policy.kiosk.telephone_services': 'Serviços de Telefonia',

    'devices.batch_devices_actions.button': 'Ações em lote',
    'devices.modal_batch_devices_actions.title': 'Ações em lote',
    'devices.modal_batch_devices_actions.actions': 'Comandos',
    'devices.modal_batch_devices_actions.action.disable': 'Desativar dispositivo',
    'devices.modal_batch_devices_actions.action.enable': 'Ativar dispositivo',
    'devices.modal_batch_devices_actions.action.lock': 'Desligar tela',
    'devices.modal_batch_devices_actions.action.reboot': 'Reiniciar dispositivo',
    'devices.modal_batch_devices_actions.action.wipe': 'Remover dispositivo (Wipe)',

    'devices.modal_batch_devices_actions.alert.disable': 'Você deseja mesmo desativar os dispositivos selecionados?',
    'devices.modal_batch_devices_actions.alert.enable': 'Você deseja mesmo ativar os dispositivos selecionados?',
    'devices.modal_batch_devices_actions.alert.lock': 'Você deseja mesmo desligar a tela dos dispositivos selecionados?',
    'devices.modal_batch_devices_actions.alert.reboot': 'Você deseja mesmo reiniciar os dispositivos selecionados?',
    'devices.modal_batch_devices_actions.alert.wipe': 'Você deseja mesmo remover todos os dispositivos selecionados?',
    'devices.modal_batch_devices_actions.alert.default': 'Você deseja mesmo enviar esse comando para todos os dispositivos selecionados?',
    'devices.modal_batch_devices_actions.alert.irreversible': 'Esta ação não pode ser desfeita.',

    'devices.alert.batch_in_process': 'Você já possui uma Solicitação de ações em lote em andamento! Por favor, aguarde a conclusão do processo, para solicitar o envio de outra ação.',
    'devices.alert.default': 'Desculpe, ocorreu um erro ao processar sua solicitação. Por favor, tente novamente mais tarde.',

    'notification.command.title': 'Comando:',
    'notification.list.command.url': 'Comando enviado com sucesso!',
    'notification.list.command.error': 'Erro ao enviar comando',
    'update_policies.applications.table.blockTime': 'Restrições de Funcionamento',
    'update_policies.applications.blockTimeModal.title': 'Restrições de Funcionamento',
    'update_policies.applications.blockTimeModal.description': 'Defina o período de funcionamento do app durante os dias da semana, ou ative a opção “Restrito“ para bloquear o funcionamento o dia inteiro',
    'update_policies.applications.blockTimeModal.dayOfWeek.0': 'Segunda-feira',
    'update_policies.applications.blockTimeModal.dayOfWeek.1': 'Terça-feira',
    'update_policies.applications.blockTimeModal.dayOfWeek.2': 'Quarta-feira',
    'update_policies.applications.blockTimeModal.dayOfWeek.3': 'Quinta-feira',
    'update_policies.applications.blockTimeModal.dayOfWeek.4': 'Sexta-feira',
    'update_policies.applications.blockTimeModal.dayOfWeek.5': 'Sábado',
    'update_policies.applications.blockTimeModal.dayOfWeek.6': 'Domingo',
    'update_policies.applications.blockTimeModal.table.header.dayOfWeek': 'Dia da semana',
    'update_policies.applications.blockTimeModal.table.header.block': 'Restringir',
    'update_policies.applications.blockTimeModal.table.header.blockTime': 'Horário de funcionamento',
    'update_policies.applications.blockTimeModal.enableBlockTime': 'Ativar restrições',
    'update_policies.applications.blockTimeModal.confirm': 'Confirmar',
    'update_policies.applications.blockTimeModal.cancel': 'Cancelar',

    'general_config.two_factor_authentication': 'Autenticação de dois fatores',
    'general_config.two_factor_authentication.tooltip': 'Antes de ativar essa configuração certifique que todos os usuários estão cadastrados com um e-mail válido. Após ativar a configuração, para realizar a autenticação de dois fatores e conseguir acessar o Portal, o usuário precisará informar um código de confirmação que será enviado para o e-mail do usuário sempre que tentar realizar acesso ao Portal.',
    'edit_policy.kiosk.telephone_services.active': 'Ativo',
    'edit_policy.kiosk.telephone_services.user_defined': 'Definido pelo Dispositivo',

    'menu.companies': 'Empresas',
    'menu.companies.manage_companies': 'Gerenciar Empresas',
    'menu.companies.register': 'Cadastrar Nova Empresa',

    'manage_companies.title': 'Gerenciar Empresas',
    'manage_companies.company': 'Empresa',
    'manage_companies.description': 'Nesta tela é possível gerenciar empresas.',
    'manage_companies.button.new': 'Nova Empresa',
    'manage_companies.action_button.resend_email': 'Reenviar E-mail de Boas Vindas',
    'manage_companies.filter.created_at': 'Data de Criação',
    'manage_companies.filter.start_at': 'Data incial',
    'manage_companies.filter.end_at': 'Data final',
    'manage_companies.filter.status': 'Status',

    'edit_company.edit.title': 'Editar empresa',
    'edit_company.edit.description': 'Nesta tela é possível editar empresa.',
    'edit_company.register.title': 'Cadastrar Nova Empresa',
    'edit_company.register.description': 'Nesta tela é possível cadastrar empresa.',
    'edit_company.corporate_name': 'Razão Social',
    'edit_company.federal_code': 'Número de Identificação',
    'edit_company.email': 'E-mail',
    'edit_company.number_of_licenses': 'Licenças',
    'edit_company.plan': 'Plano',
    'edit_company.contact': 'Contato',
    'edit_company.phone_number': 'Telefone',
    'edit_company.zip_code': 'CEP',
    'edit_company.address': 'Endereço',
    'edit_company.city': 'Cidade',
    'edit_company.state': 'Estado',

    'edit_company.edit.alert_update.text': 'Tem certeza que deseja atualizar a empresa {name}?',
    'edit_company.edit.alert_email_update.text': 'Tem certeza que deseja atualizar a empresa {name} e enviar o e-mail de Boas Vindas para {email}?',
    'edit_company.register.alert.text': 'Tem certeza que deseja cadastrar a empresa {name} e enviar o e-mail de Boas Vindas para {email}?',

    'manage_companies.action_button.cancel_company': 'Cancelar Empresa',
    'manage_companies.alert.cancel_company_text': 'Tem certeza que deseja cancelar a empresa {name}? Ao confirmar todas as licenças serão excluídas!',
    'manage_companies.alert.resend_company_email_text': 'Confirma o reenvio do e-mail de boas vinda da empresa {name} para {email}?',
    'manage_companies.alert.resend_company_email.success_message': 'E-mail de boas vindas enviado com sucesso!',
    'edit_policy.app_config.integer_limit_error': 'Por favor, insira apenas números entre {min} e {max}',
    'edit_policy.app_config.max_characters': 'O tamanho máximo de caractéres permitido é {maxCharacters}.',
    'devices.infos.permissions': 'Permissões',
    'devices.infos.usage_data_access': 'Acesso ao Dados de Uso',
    'devices.infos.skip_battery_optimization': 'Ignorar Otimização de Bateria',
    'devices.infos.writing_system_settings': 'Escrita de Configurações do Sistema',
    'devices.infos.sms_reading': 'Leitura de SMS',
    'plan.plane_name': 'Plano',
    'enterprise_applications.error.there_are_policies_with_app': 'Não é possível remover o aplicativo {appName}, pois existem políticas vinculadas! Remova o vínculo das seguintes políticas para poder remover o aplicativo:',
    'update_policies.start_working_time': 'Hora início de trabalho',
    'update_policies.end_working_time': 'Horários de trabalho',
    'enterprise_applications.error.there_are_policies_with_app.confirm_button': 'Confirmar',
    'global.plan_alert_route': 'Não é possível utilizar esta funcionalidade, pois não faz parte do seu plano! Realize o upgrade do seu plano para aproveitar as vantagens dessa funcionalidade!',
    'dashboard.roaming_disabled': 'Não é possível utilizar esta funcionalidade, pois não faz parte do seu plano! Realize o upgrade do seu plano para aproveitar as vantagens dessa funcionalidade!',
    'register_policies.android_block_sim.tooltip': 'Não é possível utilizar esta funcionalidade, pois não faz parte do seu plano! Realize o upgrade do seu plano para aproveitar as vantagens dessa funcionalidade!',
    'edit_policy.app_config.plan_max_keywords': 'Você atingiu o limite para o seu plano! Realize o upgrade do seu plano para adicionar URLs e palavras chaves ilimitadas!',
    'edit_policy.app_config.plan_max_website_categories': 'Você atingiu o limite para o seu plano! Realize o upgrade do seu plano para ativar categorias ilimitadas!',
    'update_policies.max_block_website_categories': 'Bloqueio de Sites por Categoria',
    'update_policies.max_block_keywords': 'URLs e Palavras-chave Bloqueadas e Liberadas',
    'edit_policy.kiosk.manage_configuration.plan_alert': 'Não é possível utilizar esta funcionalidade, pois não faz parte do seu plano! Realize o upgrade do seu plano para aproveitar as vantagens dessa funcionalidade!',
    'manage_policies.android_block_sim.tooltip': 'Não é possível utilizar esta funcionalidade, pois não faz parte do seu plano! Realize o upgrade do seu plano para aproveitar as vantagens dessa funcionalidade!',
    'manage_policies.table_actions.edit_policy': 'Editar Política',
    'update_policies.allow_unlimited_kiosk': 'Configurações gerenciadas do Modo Quiosque',
    'manage_policies.modal_enrollmentToken.enable_system_apps': 'Habilitar Aplicativos de Sistema',
    'manage_policies.modal_enrollmentToken.success_enable_system_apps': 'QRCode atualizado com sucesso!',
    'devices.infos.temporary_access_password': 'Senha de Acesso Temporário',

    'register_policies.ios_supervised.tooltip': 'Não é possível utilizar esta funcionalidade, pois não faz parte do seu plano! Realize o upgrade do seu plano para aproveitar as vantagens dessa funcionalidade!',
    'global.plan_warning': 'Não é possível utilizar esta funcionalidade, pois não faz parte do seu plano! Realize o upgrade do seu plano para aproveitar as vantagens dessa funcionalidade!',
    'devices.infos.ios_error.email_already_in_use': 'O e-mail informado está vinculado a outro dispositivo! Altere o e-mail e tente novamente',
    'menu.enterprise_applications.remote_apps': 'Instalação Remota de Aplicativos',
    'remoteApp.title': 'Instalação Remota de Aplicativos',
    'remoteApp.description': 'Nesta tela é possível gerenciar e enviar a instalação remota de aplicativos para os dispositivos.',
    'remoteApp.start_date': 'Data inicial',
    'remoteApp.end_date': 'Data final',
    'remoteApp.new': 'Nova Instalação',
    'remoteApp.column.date': 'Data do Envio',
    'remoteApp.column.name': 'Nome do App',
    'remoteApp.column.packageName': 'Nome do pacote',
    'remoteApp.column.url': 'URL para download',
    'remoteApp.entity': 'Instalação',
    'remoteApp.toaster_success': 'Instalação remota criada com sucesso!',
    'remoteApps.title': 'Detalhes de Envio do Aplicativo',
    'remoteApps.description': 'Nesta tela é possível gerenciar e enviar a instalação remota de aplicativos para os dispositivos.',
    'remoteApps.subtitle': 'Destinatários',
    'remoteApps.name': 'Nome do App',
    'remoteApps.packageName': 'Pacote do App',
    'remoteApps.url': 'URL para Download',
    'remote_app_edit.title': 'Instalar Aplicativo',
    'remote_app_edit.description': 'Nesta tela é possível enviar a instalação remota de um aplicativo para os dispositivos.',
    'remote_app_edit.send_label': 'Enviar',
    'remote_app_edit.filter_title': 'Enviar Aplicativo',
    'remote_app_edit.input.name': 'Nome',
    'remote_app_edit.input.package_name': 'Nome do pacote',
    'remote_app_edit.input.url': 'URL para download',
    'remote_app_edit.input.recipient': 'Destinatário',

    'update_policies.settings.accordion.safety.usb_data_access.title': 'Configurações de transferencia via USB',
    'update_policies.settings.accordion.safety.usb_data_access.description': 'Define quais arquivos e/ou dados podem ser transferidos via USB. Não afeta as funções de carregamento. A opção “Não permitir transferir todos os tipos de dados" é compatível somente com dispositivos com Android 12 ou mais recente e com USB HAL 1.3 ou mais recente.',
    'update_policies.settings.accordion.safety.usb_data_access.select.allow_usb_data_transfer': 'Permitir transferir todos os tipos de dados',
    'update_policies.settings.accordion.safety.usb_data_access.select.disallow_usb_file_transfer': 'Não permitir transferir arquivos',
    'update_policies.settings.accordion.safety.usb_data_access.select.disallow_usb_data_transfer': 'Não permitir transferir todos os tipos de dados',

    'update_policies.settings.accordion.network.configure_wifi.title': 'Privilégios de Configuração de Wi-Fi',
    'update_policies.settings.accordion.network.configure_wifi.description': 'Define os privilégios de configuração de Wi-Fi. Com base na opção definida, o usuário terá controle total ou limitado ou nenhum controle na configuração de redes Wi-Fi. A opção "Não permitir adicionar configuração de Wi-Fi” é compatível somente com o Android 13 e versões mais recentes.',
    'update_policies.settings.accordion.network.configure_wifi.select.allow_configuring_wifi': 'Permitir configuração de Wi-Fi',
    'update_policies.settings.accordion.network.configure_wifi.select.disallow_add_wifi_config': 'Não permitir adicionar configuração de Wi-Fi',
    'update_policies.settings.accordion.network.configure_wifi.select.disallow_configuring_wifi': 'Não permitir configuração de Wi-Fi',

    'update_policies.settings.accordion.hardware.wifi_direct_settings.title': 'Configurações de Wi-Fi Direct',
    'update_policies.settings.accordion.hardware.wifi_direct_settings.description': 'Define a configuração e o uso das configurações do Wi-Fi Direct. A opção "Não permitir usar Wi-Fi Direct” é compatível somente com o Android 13 e versões mais recentes.',
    'update_policies.settings.accordion.hardware.wifi_direct_settings.select.allow_wifi_direct': 'Permitir usar Wi-Fi Direct',
    'update_policies.settings.accordion.hardware.wifi_direct_settings.select.disallow_wifi_direct': 'Não permitir usar Wi-Fi Direct',

    'update_policies.settings.accordion.hardware.tethering_settings.title': 'Configurações de Tethering',
    'update_policies.settings.accordion.hardware.tethering_settings.description': 'Define as configurações de tethering. A opção "Não permitir o tethering Wi-Fi” é compatível somente com o Android 13 e versões mais recentes.',
    'update_policies.settings.accordion.hardware.tethering_settings.select.allow_all_tethering': 'Permitir todas as formas de tethering',
    'update_policies.settings.accordion.hardware.tethering_settings.select.disallow_wifi_tethering': 'Não permitir o tethering Wi-Fi  ',
    'update_policies.settings.accordion.hardware.tethering_settings.select.disallow_all_tethering': 'Não permitir todas as formas de tethering',
  },
};

export default i18nConfig;
