import { Flex } from '@chakra-ui/react';
import React from 'react';

import Spinner from '../../Spinner';

const TermsLoading = () => {
  return (
    <Flex w="full" align="center" justifyContent="center" p="6">
      <Spinner size="xl" />
    </Flex>
  );
};

export default TermsLoading;
