import { Icon } from '@chakra-ui/react';
import React from 'react';

const StartIcon = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="success.500" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 256 256"
      >
        <path d="M232.4 114.49 88.32 26.35a16 16 0 0 0-16.2-.3A15.86 15.86 0 0 0 64 39.87v176.26A15.94 15.94 0 0 0 80 232a16.07 16.07 0 0 0 8.36-2.35l144.04-88.14a15.81 15.81 0 0 0 0-27ZM80 215.94V40l143.83 88Z" />
      </svg>
    </Icon>
  );
};

export default StartIcon;
