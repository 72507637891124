import { Box, Flex, Divider, Stack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import FormContainer from '../../../components/FormContainer';
import FormSubtitle from '../../../components/FormSubtitle';
import {
  getScreenByPath,
  Screens,
} from '../../../components/pages/Messages/utils';
import PageShowToaster from '../../../components/PageShowToaster';
import PageTitle from '../../../components/PageTitle';
import Skeleton from '../../../components/Skeleton';
import Table from '../../../components/Table';
import Text from '../../../components/Text';
import { MessageStatusToIntl } from '../../../helper/messageStatus';
import { featurePlanKeys } from '../../../helper/plan';
import { useSorting } from '../../../helper/sort';
import { useFormatDate } from '../../../hooks/useFormatDate';
import {
  useAppDispatch,
  useAppDispatchByPlan,
  useAppSelector,
} from '../../../hooks/useRedux';
import {
  getDocument,
  listDocumentDetails,
  Types as DocumentDetailsTypes,
} from '../../../store/document';
import { generateExcel } from '../../../store/event';
import {
  getMessage,
  listMessageDetails,
  messageSelectedClear,
  Types as MessageDetailsTypes,
} from '../../../store/message/index';
import {
  getRemoteApp,
  listRemoteAppDetails,
  remoteAppSelectedClear,
  Types as RemoteAppDetailsTypes,
} from '../../../store/remoteApps';
import { selectorLoading, selectorMetadata } from '../../../store/ui';
import { DocumentType } from '../../../types/document';
import { ListMetadata } from '../../../types/generic_list';
import { MessageType } from '../../../types/message';
import { RemoteAppType } from '../../../types/remoteApps';

const MessageDetails = () => {
  const history = useHistory();
  const currentScreen = getScreenByPath(history.location.pathname);
  const dispatchMessage = useAppDispatchByPlan(featurePlanKeys.allowMessage);
  const dispatchDocument = useAppDispatchByPlan(featurePlanKeys.allowDocument);
  const dispatchRemoteApps = useAppDispatch();
  const dispatch = {
    [Screens.MESSAGES]: dispatchMessage,
    [Screens.DOCUMENTS]: dispatchDocument,
    [Screens.REMOTE_APPS]: dispatchRemoteApps,
  }[currentScreen];
  const intl = useIntl();
  const { handleFormatDateByUserLogged } = useFormatDate();
  const { id } = useParams<{ id: string }>();
  const { messagesDetail, message } = useAppSelector((state) => state.message);
  const { documentsDetail, document } = useAppSelector(
    (state) => state.document
  );
  const { remoteAppsDetail, remoteApp } = useAppSelector(
    (state) => state.remoteApps
  );
  const [search, setSearch] = useState('');
  const Types = {
    [Screens.MESSAGES]: MessageDetailsTypes,
    [Screens.DOCUMENTS]: DocumentDetailsTypes,
    [Screens.REMOTE_APPS]: RemoteAppDetailsTypes,
  }[currentScreen];
  const metadata = useAppSelector(selectorMetadata(Types.LIST_DETAILS));
  const titleIsLoading = useAppSelector(selectorLoading(Types.GET));

  const entity = {
    [Screens.MESSAGES]: message,
    [Screens.DOCUMENTS]: document,
    [Screens.REMOTE_APPS]: remoteApp,
  }[currentScreen];

  const entityDetails = {
    [Screens.MESSAGES]: messagesDetail,
    [Screens.DOCUMENTS]: documentsDetail,
    [Screens.REMOTE_APPS]: remoteAppsDetail,
  }[currentScreen];

  const listDetails = {
    [Screens.MESSAGES]: listMessageDetails,
    [Screens.DOCUMENTS]: listDocumentDetails,
    [Screens.REMOTE_APPS]: listRemoteAppDetails,
  }[currentScreen];

  const getEntity = {
    [Screens.MESSAGES]: getMessage,
    [Screens.DOCUMENTS]: getDocument,
    [Screens.REMOTE_APPS]: getRemoteApp,
  }[currentScreen];

  const clearEntity = {
    [Screens.MESSAGES]: messageSelectedClear,
    [Screens.DOCUMENTS]: messageSelectedClear,
    [Screens.REMOTE_APPS]: remoteAppSelectedClear,
  }[currentScreen];

  const keysIntl = {
    [Screens.MESSAGES]: {
      title: 'message_detail.title',
      description: 'message_detail.title_text',
      subtitle: 'message_detail.subtitle',
      messageTitle: 'global.message',
    },
    [Screens.DOCUMENTS]: {
      title: 'document_details.title',
      description: 'document_details.description',
      subtitle: 'document_details.subtitle',
      messageTitle: 'document_details.doc',
    },
    [Screens.REMOTE_APPS]: {
      title: 'remoteApps.title',
      description: 'remoteApps.description',
      subtitle: 'remoteApps.subtitle',
    },
  }[currentScreen];

  const handleInputSearch = (value) => {
    setSearch(value);
  };

  const handlePagination = (metadataNew: ListMetadata) => {
    dispatch(
      listDetails(parseInt(id), { ...metadata, ...metadataNew }, { search })
    );
  };

  useEffect(() => {
    dispatch(listDetails(parseInt(id), metadata, { search }));
  }, [search]);

  useEffect(() => {
    if (id) {
      dispatch(getEntity(parseInt(id)));
    }
    return () => {
      dispatch(clearEntity());
    };
  }, [id]);

  const columns = useSorting(
    [
      {
        header: intl.formatMessage({
          id: 'message_details.column.data.recipient',
        }),
        accessor: 'recipient',
        canSort: false,
      },
      {
        header: intl.formatMessage({
          id: 'message_details.column.data.phone',
        }),
        accessor: 'phoneNumber',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: 'message_details.column.data.sent_at',
        }),
        accessor: 'sentAt',
        canSort: true,
      },
      {
        header: intl.formatMessage({
          id: 'message_details.column.data.status',
        }),
        accessor: 'status',
        canSort: true,
      },
    ],
    metadata
  );

  const columnData = entityDetails?.map((details) => {
    const staticColumns = [
      {
        field: 'recipient',
        value: `${intl.formatMessage({
          id: `message_details.values.recipient.${[details?.recipient]}`,
        })}: ${details?.name || ''}`,
      },
      {
        field: 'phoneNumber',
        value: details?.phoneNumber,
      },
      {
        field: 'sentAt',
        value: handleFormatDateByUserLogged(details.sentAt),
      },
      {
        field: 'status',
        value:
          intl.formatMessage({
            id: `${MessageStatusToIntl[details.status]}`,
          }) || '',
      },
    ];

    return { cells: staticColumns };
  });

  const handleExportAllToExcel = () => {
    dispatch(
      generateExcel({
        type: Types.LIST_DETAILS,
        metadata: metadata,
        filters: { search },
        id: id,
      })
    );
  };
  return (
    <>
      <PageTitle
        showManualIcon
        title={<FormattedMessage id={keysIntl.title} />}
        description={<FormattedMessage id={keysIntl.description} />}
      />

      <PageShowToaster toasterKeys={[Types.LIST_DETAILS]} disabledSuccess />

      <FormContainer
        labelSecondary={<FormattedMessage id="global.back" />}
        handleSecondary={() => history.goBack()}
      >
        {currentScreen !== Screens.REMOTE_APPS && (
          <>
            <Text
              margin="0 0 20px 0"
              color="gray.500"
              fontSize="md"
              fontWeight="600"
            >
              <FormattedMessage id={keysIntl.messageTitle} />
            </Text>

            <Box>
              <Divider orientation="horizontal" mb="1%" />
            </Box>
          </>
        )}

        <Flex flexDirection="row" alignItems="center" width="100%" pr="1.5rem">
          {titleIsLoading ? (
            <Skeleton h="6" w="200px" my="3" />
          ) : (
            <>
              {currentScreen !== Screens.REMOTE_APPS && (
                <Text m="0" width="100%">
                  <Text as="strong" fontSize="3xl" color="gray.600">
                    &quot;
                  </Text>

                  {(entity as MessageType | DocumentType).content}

                  <Text as="strong" m="0" fontSize="3xl" color="gray.600">
                    &quot;
                  </Text>
                </Text>
              )}

              {currentScreen === Screens.REMOTE_APPS &&
                (function () {
                  const remoteApp = entity as RemoteAppType;

                  return (
                    <Stack maxW="full">
                      <Flex>
                        <Text
                          margin={0}
                          color="gray.500"
                          fontSize="md"
                          fontWeight="600"
                        >
                          <FormattedMessage id="remoteApps.name" />:
                        </Text>
                        &nbsp;
                        <Text margin="0 0 20px 0" fontSize="md">
                          {remoteApp.name}
                        </Text>
                      </Flex>

                      <Flex>
                        <Text
                          margin={0}
                          color="gray.500"
                          fontSize="md"
                          fontWeight="600"
                        >
                          <FormattedMessage id="remoteApps.packageName" />:
                        </Text>
                        &nbsp;
                        <Text margin="0 0 20px 0" fontSize="md">
                          {remoteApp.packageName}
                        </Text>
                      </Flex>

                      <Flex maxW="full">
                        <Text
                          margin="0 0 20px 0"
                          color="gray.500"
                          fontSize="md"
                          fontWeight="600"
                          whiteSpace="nowrap"
                        >
                          <FormattedMessage id="remoteApps.url" />:
                        </Text>
                        &nbsp;
                        <Text
                          margin="0 0 20px 0"
                          fontSize="md"
                          w="full"
                          wordBreak="break-all"
                        >
                          {remoteApp.url}
                        </Text>
                      </Flex>
                    </Stack>
                  );
                })()}
            </>
          )}
        </Flex>
      </FormContainer>

      <Box mt="3%">
        <FormSubtitle subtitle={<FormattedMessage id={keysIntl.subtitle} />} />
      </Box>

      <Table
        headerColumns={columns}
        rows={columnData}
        handleSort={handlePagination}
        keyProp={Types.LIST_DETAILS}
        metadata={metadata}
        pageActionsProps={{
          initialSearch: search,
          onSearch: handleInputSearch,
          handleExportAllToExcel,
        }}
        nameTable={`${intl.formatMessage({ id: keysIntl.title })}`}
      />
    </>
  );
};

export default MessageDetails;
