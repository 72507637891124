import { TabList, TabPanels, Tabs } from '@chakra-ui/react';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import InvoiceTabPanel from '../../../components/pages/ZeroTouch/InvoiceDetails/components/InvoiceTabPanel';
import DevicesDetailsTab from '../../../components/pages/ZeroTouch/InvoiceDetails/DevicesDetailsTab';
import IMEIModelDetailsTab from '../../../components/pages/ZeroTouch/InvoiceDetails/IMEIModelDetailsTab';
import { apiErrorMessagesByResponse } from '../../../components/pages/ZeroTouch/zeroTouchErros';
import PageShowToaster from '../../../components/PageShowToaster';
import PageTitle from '../../../components/PageTitle';
import Tab from '../../../components/Tab';
import WarningIfExitRoute from '../../../components/WarningIfExitRoute';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import { clearInvoice, getInvoice, Types } from '../../../store/invoice';
import { hasError, hasSuccess } from '../../../store/ui';

const InvoiceDetails = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { id } = useParams<{ id: string }>();
  const { invoice, initialInvoice } = useAppSelector((state) => state.invoice);
  const { portalSettings } = useAppSelector((state) => state.portalSettings);

  const invoiceIsEqual = _.isEqual(invoice, initialInvoice);
  const isCustomerEmailSuccess = hasSuccess(Types.REGISTER_CUSTOMER_ID);
  const successRegisterZeroTouch = hasSuccess(Types.REGISTER_ZERO_TOUCH);
  const isImportDevicesIMEISuccess = hasSuccess(Types.IMPORT_DEVICES_IMEI);
  const updateError = hasError(Types.UPDATE);
  const registerZeroTouchError = hasError(Types.REGISTER_ZERO_TOUCH);

  const isItemsFilled = initialInvoice?.items?.every(
    (item) => !!item?.imei && !!item?.model && !!item?.manufacturer
  );

  useEffect(() => {
    dispatch(getInvoice(id));
    return () => {
      dispatch(clearInvoice());
    };
  }, [id]);

  function handleGetSuccessMessage() {
    if (isCustomerEmailSuccess)
      return 'manage_invoice.toaster.customer_email.success';
    if (successRegisterZeroTouch) return 'invoice.register_zero_touch_success';
    if (isImportDevicesIMEISuccess)
      return 'invoice_details.import_devices_imei.success';

    return 'invoice_details.info_update.success';
  }

  return (
    <>
      <PageTitle
        showManualIcon
        title={<FormattedMessage id="invoice_details.title" />}
        description={<FormattedMessage id="invoice_details.description" />}
        showOperationalSystems={portalSettings.allowDeviceIOS}
        hideIOSIcon
      />

      <PageShowToaster
        toasterKeys={[
          Types.REGISTER_ZERO_TOUCH,
          Types.REGISTER_ITEMS_IMEI,
          Types.REGISTER_CUSTOMER_ID,
          Types.IMPORT_DEVICES_IMEI,
          Types.UPDATE,
        ]}
        messageSuccess={<FormattedMessage id={handleGetSuccessMessage()} />}
        disabledError
      />

      <PageShowToaster
        toasterKeys={[Types.REGISTER_ITEMS_IMEI]}
        messageError={
          <FormattedMessage id="invoice_details.info_update.error" />
        }
        disabledSuccess
      />

      <PageShowToaster
        toasterKeys={[Types.UPDATE, Types.REGISTER_ZERO_TOUCH]}
        messageError={
          <FormattedMessage
            id={
              apiErrorMessagesByResponse[
                updateError?.message || registerZeroTouchError?.message
              ] || 'invoice_details.info_update.error'
            }
          />
        }
        disabledSuccess
      />

      <Tabs mt="16" isLazy>
        <TabList mb="10">
          {isItemsFilled && (
            <Tab>
              <FormattedMessage id="invoice_details.tab.devices_details" />
            </Tab>
          )}
          <Tab>
            <FormattedMessage id="invoice_details.tab.model_details" />
          </Tab>
        </TabList>
        <TabPanels>
          {isItemsFilled && (
            <InvoiceTabPanel>
              <DevicesDetailsTab />
            </InvoiceTabPanel>
          )}
          <InvoiceTabPanel>
            <IMEIModelDetailsTab />
          </InvoiceTabPanel>
        </TabPanels>
      </Tabs>

      <WarningIfExitRoute
        preventExit={!invoiceIsEqual}
        message={intl.formatMessage({ id: 'invoice_details.warning_exit' })}
      />
    </>
  );
};

export default InvoiceDetails;
