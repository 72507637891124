import { SimpleGrid } from '@chakra-ui/react';
import { formatBytesTo } from 'portal-lib';
import React from 'react';
import { useIntl } from 'react-intl';

import { managementModeToIntl } from '../../../../../pages/Policies/RegisterPolicy';
import { deviceStateToIntl } from '../../../../../types/device';
import ItemList from '../../../../ItemListDrill/ItemListDrill';
import { ModalSection } from './ModalMoreInformation';
import { ModalInformationProps } from './utils';

const ModalAndroidInformation = ({
  device,
  handleFormatDateByUserLogged,
  portalSettings,
}: ModalInformationProps) => {
  const intl = useIntl();

  return (
    <SimpleGrid my="5" w="full" columns={2} spacing={10}>
      <ModalSection title={intl.formatMessage({ id: 'devices.infos.details' })}>
        <ItemList
          label={intl.formatMessage({ id: 'devices.infos.name' })}
          value={device?.name}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'devices.infos.federalCode',
          })}
          value={device?.federalCode}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'global.group',
          })}
          value={device?.group?.name}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'devices.infos.imei',
          })}
          value={device?.imei}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'devices.infos.iccid',
          })}
          value={device?.iccid}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'devices.infos.serial_number',
          })}
          value={device?.serialNumber}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'devices.infos.phone',
          })}
          value={device?.phoneNumber}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'devices.infos.model',
          })}
          value={device?.model}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'devices.infos.manufacturer',
          })}
          value={device?.manufacturer}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'devices.infos.operational_system',
          })}
          value={device?.operationalSystem}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'devices.infos.android_version',
          })}
          value={device?.androidVersion}
        />

        {/* <TemporaryAccessPassword device={device} /> */}
      </ModalSection>

      <ModalSection title={intl.formatMessage({ id: 'devices.infos.install' })}>
        <ItemList
          label={intl.formatMessage({ id: 'devices.infos.appliedState' })}
          value={intl.formatMessage({ id: deviceStateToIntl[device?.state] })}
        />
        <ItemList
          label={intl.formatMessage({ id: 'devices.infos.register_date' })}
          value={handleFormatDateByUserLogged(device?.createdAt)}
        />
        <ItemList
          label={intl.formatMessage({ id: 'devices.infos.management_mode' })}
          value={
            device?.policy?.managementMode &&
            intl.formatMessage({
              id: managementModeToIntl[device?.policy?.managementMode],
            })
          }
        />
        <ItemList
          label={intl.formatMessage(
            { id: 'devices.column.companion_version' },
            { companionName: portalSettings.name }
          )}
          value={device?.companionVersion}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'devices.infos.policy_name_on_portal',
          })}
          value={device?.policy?.name}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'devices.infos.policy_name_on_device',
          })}
          value={device?.appliedPolicyName}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'devices.infos.policy_version_applied',
          })}
          value={device?.policy?.version}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'devices.infos.policy_sync',
          })}
          value={handleFormatDateByUserLogged(device?.lastPolicySyncTime)}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'devices.infos.in_accordance',
          })}
          value={intl.formatMessage({
            id: device?.policyCompliant
              ? 'devices.infos.in_accordance.yes'
              : 'devices.infos.in_accordance.no',
          })}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'devices.infos.last_communication',
          })}
          value={handleFormatDateByUserLogged(device?.lastCommunication)}
        />
      </ModalSection>

      <ModalSection
        title={intl.formatMessage({ id: 'devices.infos.hardware' })}
      >
        <ItemList
          label={intl.formatMessage({
            id: 'devices.infos.intern_storage',
          })}
          value={formatBytesTo({
            bytes: device?.freeMemory,
          })}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'devices.infos.battery',
          })}
          value={`${device.battery}%`}
        />
      </ModalSection>

      {/* <ModalSection
        title={intl.formatMessage({ id: 'devices.infos.permissions' })}
      >
        <ItemList
          label={intl.formatMessage({
            id: 'devices.infos.usage_data_access',
          })}
          value={intl.formatMessage({
            id: device?.permissions?.accessUsageData
              ? 'global.yes'
              : 'global.no',
          })}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'devices.infos.skip_battery_optimization',
          })}
          value={intl.formatMessage({
            id: device?.permissions?.ignoreBatteryOptimization
              ? 'global.yes'
              : 'global.no',
          })}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'devices.infos.writing_system_settings',
          })}
          value={intl.formatMessage({
            id: device?.permissions?.writeSystemSettings
              ? 'global.yes'
              : 'global.no',
          })}
        />
        <ItemList
          label={intl.formatMessage({
            id: 'devices.infos.sms_reading',
          })}
          value={intl.formatMessage({
            id: device?.permissions?.readSms ? 'global.yes' : 'global.no',
          })}
        />
      </ModalSection> */}
    </SimpleGrid>
  );
};

export default ModalAndroidInformation;
