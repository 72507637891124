import { Box, Flex, VisuallyHidden } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import SmartphoneImg from '../../../../assets/Images/smartphone.svg';
import Card from '../../../../components/Card';
import { useAppSelector } from '../../../../hooks/useRedux';
import routes from '../../../../routes';
import { Types } from '../../../../store/dashboard';
import Text from '../../../Text';
import OperationalSystemIcons from '../OperationalSystemIcons';
import DeviceStatusInformation from './DeviceStatusInformation';

interface DevicesCardProps {
  showOperationalSystems?: boolean;
}

function DevicesCard({ showOperationalSystems }: DevicesCardProps) {
  const { devicesStatus, devicesWithoutCommunication } = useAppSelector(
    (state) => state.dashboard
  );
  const loadings = useAppSelector((state) => state.ui.loading);
  const history = useHistory();

  const devicesModeKioskLoading = loadings[Types.LIST_DEVICES_MODE_KIOSK];
  const devicesActiveLoading = loadings[Types.LIST_DEVICES_ACTIVE];
  const devicesWithoutComplianceLoading =
    loadings[Types.LIST_DEVICES_WITHOUT_COMPLIANCE];
  const devicesWithoutCommunicationLoading =
    loadings[Types.LIST_DEVICE_WITHOUT_COMMUNICATION];

  const handleSendToAnotherPage = (route: string) => {
    return history.push(route);
  };

  return (
    <Card h="381px" w="full">
      <Box p="24px" borderBottomWidth="1px" borderColor="gray.600">
        <Flex mt="0" mb="30.5px" gridGap={1} alignItems={'center'}>
          <Text
            as="h3"
            fontSize="sm"
            lineHeight="1.36"
            letterSpacing="0.56px"
            color="gray.900"
            m="0"
          >
            <FormattedMessage id="dashboard.device_card.title" />
          </Text>
          {showOperationalSystems && <OperationalSystemIcons />}
        </Flex>
        <Flex
          backgroundImage={`url(${SmartphoneImg})`}
          backgroundRepeat="no-repeat"
          backgroundPosition="right 5px"
        >
          <DeviceStatusInformation
            text={<FormattedMessage id="dashboard.device_card.status.total" />}
            value={devicesStatus.active}
            isLoading={devicesActiveLoading}
            color="success.500"
            isColumn
            isUpperCase
            handleClick={() => handleSendToAnotherPage(routes.device.manage)}
          />
          <DeviceStatusInformation
            text={
              <FormattedMessage id="dashboard.device_card.status.without_communication" />
            }
            value={devicesWithoutCommunication}
            isLoading={devicesWithoutCommunicationLoading}
            color="warning.600"
            handleClick={() =>
              handleSendToAnotherPage(routes.device.withoutCommunication)
            }
            isColumn
            isUpperCase
          />
        </Flex>
      </Box>
      <Box p="24px">
        <VisuallyHidden>
          <DeviceStatusInformation
            text={
              <FormattedMessage id="dashboard.device_card.status.no_compilation" />
            }
            value={devicesStatus.noCompilation}
            isLoading={devicesWithoutComplianceLoading}
            color="warning.600"
            handleClick={() => handleSendToAnotherPage(routes.device.manage)}
          />
        </VisuallyHidden>

        <DeviceStatusInformation
          text={
            <FormattedMessage id="dashboard.device_card.status.mode_kiosk" />
          }
          value={devicesStatus.modeKiosk}
          isLoading={devicesModeKioskLoading}
          color="gray.900"
        />
      </Box>
    </Card>
  );
}

export default DevicesCard;
