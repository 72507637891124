import { Box, Flex } from '@chakra-ui/react';
import React from 'react';

import Skeleton from '../../../Skeleton';
interface ItemListProps {
  label: string;
  value?: string;
  isLoading?: boolean;
  errorMessage?: string;
}

const ItemList = ({ label, value, isLoading, errorMessage }: ItemListProps) => {
  const hasError = !!errorMessage;
  return (
    <Flex>
      <Box
        fontSize="14px"
        fontWeight="bold"
        lineHeight="2.86"
        letterSpacing="0.56px"
        textAlign="left"
        color="gray.500"
        mr="5px"
      >
        {label}:
      </Box>
      <Box
        fontSize="14px"
        lineHeight="2.86"
        letterSpacing="0.56px"
        textAlign="left"
        color={hasError ? 'warning.600' : 'gray.500'}
      >
        {!value && isLoading ? (
          <Flex display="flex" align="center" justify="center" h="40px">
            <Skeleton h="20px" w="250px" />
          </Flex>
        ) : hasError ? (
          errorMessage
        ) : (
          value
        )}
      </Box>
    </Flex>
  );
};

export default ItemList;
