import { Grid, GridItem, Flex, Box } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

import LayoutHeader from '../../components/LayoutHeader';

interface LayoutInternalCleanProps {
  children: ReactNode;
}

const LayoutInternalClean = ({ children }: LayoutInternalCleanProps) => {
  return (
    <Flex flexDirection="row" position="relative" overflow="hidden">
      <Grid
        w="100%"
        templateAreas="'appBar' 'content'"
        templateColumns="repeat(auto-fit, minmax(65px, 1fr))"
        zIndex={0}
        bg="gray.400"
      >
        <GridItem
          gridArea="appBar"
          bg="white"
          borderColor="gray.600"
          borderBottomWidth="1px"
          borderLeftWidth="1px"
          w="inherit"
          position="fixed"
          zIndex={5}
        >
          <LayoutHeader isPublic />
        </GridItem>
        <GridItem
          gridArea="content"
          mt="80px"
          overflowY="auto"
          height="calc(100vh - 80px)"
        >
          <Box m="3% 4% 2% 4%">{children}</Box>
        </GridItem>
      </Grid>
    </Flex>
  );
};

export default LayoutInternalClean;
