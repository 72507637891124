import {
  DayOfWeekCodeEnum,
  GpsFrequencyEnum,
  GpsPrecisionEnum,
  SyncTimeEnum,
} from '../../../../types/generalConfiguration';

export const syncTimeOptions = [
  {
    value: SyncTimeEnum.h1,
    label: 'general_config.values.syncTime.h1',
  },
  {
    value: SyncTimeEnum.h6,
    label: 'general_config.values.syncTime.h6',
  },
  {
    value: SyncTimeEnum.h12,
    label: 'general_config.values.syncTime.h12',
  },
  {
    value: SyncTimeEnum.h24,
    label: 'general_config.values.syncTime.h24',
  },
];

export const syncTimeToIntl = {
  [SyncTimeEnum.h1]: 'general_config.values.syncTime.h1',
  [SyncTimeEnum.h6]: 'general_config.values.syncTime.h6',
  [SyncTimeEnum.h12]: 'general_config.values.syncTime.h12',
  [SyncTimeEnum.h24]: 'general_config.values.syncTime.h24',
};

export const dayOfWeekToIntl = {
  [DayOfWeekCodeEnum.SUNDAY]: 'general_config.values.dayOfWeek.SUNDAY',
  [DayOfWeekCodeEnum.MONDAY]: 'general_config.values.dayOfWeek.MONDAY',
  [DayOfWeekCodeEnum.TUESDAY]: 'general_config.values.dayOfWeek.TUESDAY',
  [DayOfWeekCodeEnum.WEDNESDAY]: 'general_config.values.dayOfWeek.WEDNESDAY',
  [DayOfWeekCodeEnum.THURSDAY]: 'general_config.values.dayOfWeek.THURSDAY',
  [DayOfWeekCodeEnum.FRIDAY]: 'general_config.values.dayOfWeek.FRIDAY',
  [DayOfWeekCodeEnum.SATURDAY]: 'general_config.values.dayOfWeek.SATURDAY',
};

export const policyBooleanToIntl = {
  true: 'general_config.values.policyBoolean.true',
  false: 'general_config.values.policyBoolean.false',
};

export const dayOfWeekOptions = [
  {
    value: DayOfWeekCodeEnum.SUNDAY,
    label: 'general_config.values.dayOfWeek.SUNDAY',
  },
  {
    value: DayOfWeekCodeEnum.MONDAY,
    label: 'general_config.values.dayOfWeek.MONDAY',
  },
  {
    value: DayOfWeekCodeEnum.TUESDAY,
    label: 'general_config.values.dayOfWeek.TUESDAY',
  },
  {
    value: DayOfWeekCodeEnum.WEDNESDAY,
    label: 'general_config.values.dayOfWeek.WEDNESDAY',
  },
  {
    value: DayOfWeekCodeEnum.THURSDAY,
    label: 'general_config.values.dayOfWeek.THURSDAY',
  },
  {
    value: DayOfWeekCodeEnum.FRIDAY,
    label: 'general_config.values.dayOfWeek.FRIDAY',
  },
  {
    value: DayOfWeekCodeEnum.SATURDAY,
    label: 'general_config.values.dayOfWeek.SATURDAY',
  },
];

export const gpsPrecisionOptions = [
  {
    value: GpsPrecisionEnum.m200,
    label: 'general_config.values.gpsPrecision.m200',
  },
  {
    value: GpsPrecisionEnum.m300,
    label: 'general_config.values.gpsPrecision.m300',
  },
  {
    value: GpsPrecisionEnum.m400,
    label: 'general_config.values.gpsPrecision.m400',
  },
  {
    value: GpsPrecisionEnum.m500,
    label: 'general_config.values.gpsPrecision.m500',
  },
];

export const gpsFrequencyOptions = [
  {
    value: GpsFrequencyEnum.min3,
    label: 'general_config.values.gpsFrequency.min3',
  },
  {
    value: GpsFrequencyEnum.min10,
    label: 'general_config.values.gpsFrequency.min10',
  },
  {
    value: GpsFrequencyEnum.min30,
    label: 'general_config.values.gpsFrequency.min30',
  },
  {
    value: GpsFrequencyEnum.h1,
    label: 'general_config.values.gpsFrequency.h1',
  },
  {
    value: GpsFrequencyEnum.h6,
    label: 'general_config.values.gpsFrequency.h6',
  },
  {
    value: GpsFrequencyEnum.h12,
    label: 'general_config.values.gpsFrequency.h12',
  },
  {
    value: GpsFrequencyEnum.h24,
    label: 'general_config.values.gpsFrequency.h24',
  },
];

export const radioOptions = [
  {
    value: 'true',
    label: 'global.yes',
  },
  {
    value: 'false',
    label: 'global.no',
  },
];
