import { useTheme } from '@chakra-ui/react';

import { useAppSelector } from '../../../../hooks/useRedux';
import { Types } from '../../../../store/dashboard';
import { selectorLoading } from '../../../../store/ui';
import ConsumptionChart from './ConsumptionChart';

const DashboardSMSChart = () => {
  const { smsConsumption } = useAppSelector((state) => state.dashboard);

  const isLoading = useAppSelector(selectorLoading(Types.LIST_SMS_CONSUMPTION));

  const { colors } = useTheme();
  const colorsToUse = [colors['chart']['800'], colors['chart']['900']];

  return (
    <ConsumptionChart
      colorsToUse={colorsToUse}
      consumption={smsConsumption}
      isLoading={isLoading}
    />
  );
};

export default DashboardSMSChart;
