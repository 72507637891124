import { Box, Link } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import Card from '../../../Card';
import Heading from '../../../Heading';
import Text from '../../../Text';

interface InformationCardProps {
  title: string | ReactNode;
  description: string | ReactNode;
  urlImage: string;
  link?: string;
  textLink?: ReactNode;
}

const InformationCard = ({
  urlImage,
  description,
  title,
  link,
  textLink,
}: InformationCardProps) => {
  return (
    <Card w="100%" p="40px">
      <Box d="flex" flexDirection="row" justifyContent="space-between">
        <Box d="flex" flexDirection="column" justifyContent="space-between">
          <Heading as="h2" fontSize="4xl" color="gray.900">
            {title}
          </Heading>
          <Text m="2% 0% 0% 0%">{description}</Text>
          <Link href={link}>
            <Text m="2% 0% 0% 0%" color="primary.500">
              {textLink || <FormattedMessage id="card_help.discover_more" />}
            </Text>
          </Link>
        </Box>
        <Box d="contents">
          <Box
            w="207px"
            h="182px"
            backgroundImage={`url('${urlImage}')`}
            backgroundRepeat="no-repeat"
            backgroundSize="contain"
            backgroundPosition="100%"
          />
        </Box>
      </Box>
    </Card>
  );
};

export default InformationCard;
